<template>
  <div class="container" style="height:100%;overflow-y:auto;">
    <div class="page-wrap">
      <div style="color:var(--subtext3)">流程创建</div>
      <h2>{{ flow.name }}</h2>
      <Divider />      
     
      <DynamicForm 
        ref="form"
        v-model="formData"
        v-bind="flow"
        :loading="loading"
        @on-submit="$emit('event',{type:'submit',value:$event})"
      >
      <!-- extra action buttons -->
      <div slot="buttons">{{flow.actions}}</div>
      </DynamicForm>
    </div>
    
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DynamicForm from '@/components/form/dynamic'
export default {
  components:{DynamicForm},
  computed:{
    ...mapGetters('session',['session']),
  },  
  data(){
    return {
      formData:{},
      loading:false,
    }
  },
  props:{
    flow:{
      type:Object,
      default:()=>({})
    }
  },
  methods:{
    submit(){
      this.$refs.form.handleSubmit()
    }
  }
}
</script>

<style lang="less">
.page-wrap{
  width:962px;
  margin:20px auto;
  background: var(--bg3);
  padding:20px;
}
</style>