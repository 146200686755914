<template>
   <div class="flex-wrap block-btn" @click="$emit('on-click',$event)">
    <BaseIcon :icon="icon" color="var(--text3)" v-if="icon" style="margin-right:10px" /> 
    <slot></slot>
    </div>
</template>

<script>
export default {
    props:{
       icon:{
        type:String
       }
    }
}
</script>

<style lang="less" scoped>
.block-btn{
  margin-right:10px;border-radius:5px;padding:5px 10px;border:1px solid var(--border);font-weight:bold;
  
  
}

.block-btn:hover{
  background:var(--primary) !important;
  color:var(--hover-text);
  cursor: pointer;
  .icon{
    color:var(--hover-text);
   }
}

.block-btn:active{
  position: relative;
  right:-1px;
  bottom:-1px;
}

.block-btn-active{
  background:var(--bg1);
   color:var(--text1);
 
}
</style>