<template>
  <div>
    <Row class-name="l-bill">
      <Col :span="1" align="center">序号</Col>
      <Col :span="3"  align="center">开票日期</Col>
      <Col :span="6" align="center">开票内容</Col>
       <Col :span="4"  align="center" :pull="1">开票金额</Col>
       
      <Col :span="2"  align="center">到账情况</Col>
      <Col :span="3"  align="center">核验</Col>
      <Col :span="3"  align="center">
      操作</Col>
    </Row>
    <template v-for="(data,index) in items">
      <Row :key="data.id"  :class-name="`l-bill ${selected==data?'l-bill-selected':''}`" @click.native="$emit('event',{type:'select',data});selected=data">
      <Col :span="1" align="center">{{index+1}}</Col>
      <Col :span="3" align="center">{{moment(data.billed_at).format("YYYY-MM-DD")}}</Col>
      <Col :span="6">{{data.detail}}</Col>
       <Col :span="4"  :pull="1"><BaseAmount full :value="data.amount"></BaseAmount></Col>
      
      <Col :span="2"><Progress :percent="parseInt(data.transferred_amount * 1000 / data.amount)/10" text-inside :stroke-width="15" style="line-height:15px"></Progress></Col>
        <Col :span="3"  align="center" :style="`color:var(--${data.verified_at?'success':'subtext3'})`">
        <div class="flex-wrap flex-center">
        <template v-if="data.verified_by">
          <BaseNameBoard :uid="data.verified_by" :size="20" />
        </template><template v-else>
           {{'未核验'}}
          </template>
        </div>
        </Col>
      <Col :span="3"  align="center">
      <div >
        <a style="" @click="verify(data)"><BaseIcon icon='md-checkmark' v-if="!data.verified_at" /></a>
        <a style="" @click="unverify(data)"><BaseIcon icon='md-create' v-if="data.verified_at" /></a>
        <a style="margin-left:10px;"  v-if="!data.verified_at" @click="edit(data)"><BaseIcon icon='md-create' /></a>
        <a style="margin-left:10px;" v-if="!data.verified_at" @click="remove(data)"><BaseIcon icon='md-trash' /></a></div> </Col>
    </Row>
    </template>
    <Row class-name="l-bill l-bill-summary">
      <Col :span="2">合计</Col>
      <Col :span="12" pull="1">
      <BaseAmount :value="amount" full></BaseAmount>
      </Col>
      <Col :span="2">
      <Progress :percent="parseInt(amount * 1000 / value.amount)/10" text-inside :stroke-width="15" style="line-height:15px"></Progress>
      </Col>
    </Row>

    

    <Modal v-model="showEditor" :title="formData.id?'编辑':'新建'" footer-hide :width="600">
      <ProjectBillAnalysis :value="value" :tiny="true" />
      <BaseSegment title="票据信息" :disabled="true">
          <Form :label-width="80"  :rules="rules" :model="formData">
            <FormItem required label="开票日期" prop="billed_at">
              <BaseDate v-model="formData.billed_at" />
            </FormItem>
            <FormItem required label="开票依据" prop="condition_id">
              <Select v-model="formData.condition_id" transfer  placeholder="请选择" clearable>
                <Option v-for="(c,i) in conditions" :key="c.id" :value="c.id">
                  {{i+1}} - {{c.detail}} {{c.note}}
                </Option>
                </Select>
            </FormItem>
           <FormItem label="备注" prop="note">
              <Input v-model="formData.note" clearable placeholder="请输入" />
            </FormItem>
            
            <FormItem required label="开票金额" prop="billed_at">
              <div class="flex-wrap flex-between">
              <Input v-model="formData.amount" number style="width:200px" clearable>
                  <span slot="append">
                    
                  <Button icon="md-build" @click="fix" />
                  </span>
                
              </Input> <BaseAmount full :value="formData.amount" />
              <span style="color:var(--error)" v-if="formData.amount > value.unbill_amount"><BaseIcon icon="md-alert" color="var(--error)"></BaseIcon> 大于可开票金额</span>
              </div>
            </FormItem>
          </Form>
      </BaseSegment>
      <div class="flex-wrap flex-right">
        <Button type="primary" @click="submit" :loading="loading">提交</Button>
        <Button style="margin-left:15px" @click="showEditor=false">取消</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
// A Component to display/edit bills for project
import moment from "moment"
import ProjectBillAnalysis from "@/components/cm/project/ProjectBillAnalysis"
import UTIL from '@/utils'
export default {
  name: 'CmBills',
  data(){
    return {
      showEditor:false,
      selected:{},
      loading:false,
      items:[],
      current:{},
      formData:{}
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  components:{ProjectBillAnalysis},
  computed: {
    rules(){
      return {
        billed_at:[{required:true,message:"请选择开票日期"}],
        condition_id:[{required:true,message:"请选择开票依据"}],
        amount:[{required:true,message:"请输入开票金额"}]
      }
    },
    conditions(){
      const special = {id:10086,detail:"非节点票据"}
      if(this.value)
        return [...(this.value.conditions || []),special]
      else
        retrun [special]
    },
    amount() {
      return this.items.reduce((c,item) => {
        return c + item.amount
      }, 0)
    },
    transferAmount(){
      return 0
    }
  },
  watch:{
    'value.id':{
      handler(){
        this.items =this.value.bills || []
        this.init()
      },
      immediate:true,
      deep:true
    },

  },
  methods:{
    init(){
      this.formData = {
        billed_at:moment().format("YYYY-MM-DD HH:mm:ss"),
        amount:0
      }
    },
    edit(e){
      this.current = e
      this.formData = UTIL.clone(e)
      this.showEditor = true
    },
    add(){
      this.showEditor = true
      
    },
    verify(e){
      this.$api.patch("cm/bills/"+e.id+'?q=verify').then(res=>{
         let updateInfo = res.data.data
          updateInfo.id = e.id
          UTIL.updateItem(this.value.bills,updateInfo)
          this.showEditor = false
          this.init()
          this.$emit("update")
        })
    },
    unverify(e){
      this.Confirm("再此提交需重新核验，确定要撤销此票据的核验状态？",()=>{
        
       this.$api.patch("cm/bills/"+e.id+'?q=unverify').then(res=>{
         let updateInfo = res.data.data
         updateInfo.id = e.id
          UTIL.updateItem(this.value.bills,updateInfo)
          this.showEditor = false
          this.init()
          this.$emit("update")
        })
      })
    },
    remove(e){
      this.Confirm("确定删除此条记录?",()=>{
        this.$api.delete("cm/bills/"+e.id).then(res=>{
          UTIL.remove(this.value.bills,e)
          this.$emit("update")
        })
      })
    },
    fix(){
      this.formData.amount = this.value.unbill_amount
    },
    submit(){
      this.loading = true
      if(this.formData.id){
        let updateData = UTIL.compare_patch_object(this.current,this.formData)
        this.$api.patch("cm/bills/"+this.formData.id,updateData).then(res=>{
          let updateInfo = res.data.data
          let item = Object.assign({},this.formData,updateInfo)
          UTIL.updateItem(this.value.bills,item)
          this.showEditor = false
          this.init()
          this.$emit("update")
        }).catch(e=>this.Error(e)).finally(()=>this.loading=false)
      }else{
        this.formData.contract_id = this.value.id
        this.$api.post("cm/bills",this.formData).then(res=>{
          let updateInfo = res.data.data
          let item = Object.assign({},this.formData,updateInfo)
          this.value.bills.push(item)
          this.showEditor = false
          this.init()
          this.$emit("update")
        }).catch(e=>this.Error(e)).finally(()=>this.loading=false)
      }
    }
  }

}
</script>

<style lang="less">
.l-bill{
  padding:5px 10px;
  background:var(--bg3);
  color:var(--text3);

  border-bottom:1px dashed var(--border);
}


.l-bill-selected{
  background:#40bafc59;
}

.l-bill-summary{
  background:linear-gradient(to right,var(--bg2),var(--bg3));
}
</style>