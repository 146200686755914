<template>
  <div class="flex-wrap">
     <template v-for="m in menus">
      <div class="flex-col flex-center  menu-item" style=""  :class="{'menu-item-actived':isRouteActive('/core/self/'+m.path)}" :key="m.name" @click="RouteTo(m.path)">
        {{m.name}}
        <div class="slash"></div>
      </div>
      </template>
  </div>
</template>

<script>

import routeMixin from '@/mixins/route'
  export default {
    props:{
      menus:{
        type:Array,
        default:()=>([])
      }
    },
    mixins:[routeMixin],
  }
</script>

<style lang="less">
.menu-title{
  height:100%;padding:0 20px;font-size:14px;font-weight:bold;padding-right:40px;border-bottom-right-radius:40px;background:#33333333;margin-right:30px;
  color:var(--primary);
}
.menu-item{
  height:100%;padding:0 20px;flex-grow:0;
  padding-top:3px;
  cursor: pointer;
  .slash{
    width:40px;margin-top:3px;height:0px;border-bottom:2px solid transparent;
    transition:all 0.3s;
  }
}

.menu-item:hover{
  color:var(--primary);
  .slash{
    border-color:var(--text3);
  }
}
.menu-item-actived{
  color:var(--primary);
  .slash{
    
    border-color:var(--primary);
  }
}
</style>