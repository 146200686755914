<template>
  <div class="contianer flex-wrap;" style="padding:50px 20px;width:100%;">
    <div class="flex-col x-board" style="width:300px;height:200px;" @click="RouteTo('https://b.inbgz.com/cm?key=nbgz',{},true)">
      <img src="https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/1673927964765.jpg" style="width:240px;height:140px;margin-bottom:10px;" />
      运营数据看板
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less">
.x-board{
  img{
             transition: all 0.3s;
  }
}

.x-board:hover{
  cursor: pointer;
  img{
    filter: brightness(1.2);
    box-shadow: 1px 1px 2px 3px var(--border);
  }
}
</style>