<template>
  <Table 
    :columns="cols" 
    :data="tableData" 
    :loading="loading"
    border
    :width="cols.reduce((a,v)=>a+v.width,0)+5"
    >
    <BaseLoading v-if="loading" />
    <div class="flex-wrap flex-right" style="font-size:12px;color:var(--subtext3)">
                    
                </div>
  </Table>
</template>

<script>
import tableRender from '../../base/tableRender'
export default {
    name:'CmTableYearBill',
    data(){
        return {
            tableData:[{
                dep:'公司'
            },{
                dep:'建设管理事业部',
            },{
                dep:'房建监理事业部'
            },{
                dep:'市政监理事业部'
            }],
            loading:true
        }
    },
    props:{
        year:{
            type:String | Number
        }
    },
    computed:{
        cols(){
            return [{
                key:'dep',
                title:'部门',
                align:'center',
                width:200
            },{
                key:'predict_bill',
                title:'总预测开票金额(万元)',
                align:'center',
                width:200,
                render:(h,params)=>tableRender.renderAmountNoFormat(h,params.row.predict_bill)
            },{
                key:'current_bill',
                title:'已经开票金额(万元)',
                align:'center',
                render:(h,params)=>tableRender.renderAmountNoFormat(h,params.row.current_bill),
                width:200
            },{
                key:'remain_bill',
                title:'尚可开票金额(万元)',
                align:'center',
                width:200,
                render:(h,params)=>tableRender.renderAmountNoFormat(h,params.row.remain_bill)
            },{
                key:'in_building_predict_bill',
                title:'在建项目预测(万元)',
                width:200,
                align:'center',
                render:(h,params)=>tableRender.renderAmountNoFormat(h,params.row.in_building_predict_bill)
            },{
                key:'completed_predict_bill',
                title:'竣工项目预测(万元)',
                width:200,
                align:'center',
                render:(h,params)=>tableRender.renderAmountNoFormat(h,params.row.completed_predict_bill)
            },{
                key:'overdated_bill_plan_amount',
                title:'计划内未开票金额(万元)',
                width:200,
                align:'center',
                render:(h,params)=>tableRender.renderAmountNoFormat(h,params.row.overdated_bill_plan_amount)
            }]
        }
    },
    mounted(){
        this.getData()
    },  
    methods:{
        getData(){
            this.loading = true
            let url = "cm/tables/bill"
            url += "?year="+this.year
            this.$api.get(url).then(res=>{
                let result = res.data.data
                this.tableData = result.data
                this.created_at = result.created_at
            }).finally(()=>{
                this.loading = false
            })
        },
        exportExcel(){

        }
    }
}
</script>

<style>

</style>