<template>
  <div class="flex-wrap in-app-tabs">
    <template v-for="t in items">
       <Divider type="vertical" v-if="t.divider" :key="t.id" />
       <div class="flex-wrap block-btn" :key="t.key" :class="{'block-btn-active':actived==t.key}" @click="$emit('on-click',t.key)">
          <BaseIcon :icon="t.icon" style="margin-right:5px" v-if="t.icon" />{{t.name}} <span class="count" :class="{'count-none':!t.count}" v-if="t.count">{{t.count}}</span>
        </div>
    </template>
  </div>
</template>

<script>
  export default {
    props:{
      items:{
        type:Array,
        default:()=>([])
      },
      actived:{
        type:String
      }
    }
  }
</script>

<style lang="less" scoped>
.in-app-tabs{
  height:25px;
  position: relative;
}
.block-btn{
  height:100%;
 padding:0 10px;border-radius:40px;
 font-size:14px;
 margin:0 3px;
  
  .icon{
    padding:2px;background:var(--bg1);color:var(--text1);margin-right:5px;border-radius:5px;display:inline-block;width:25px;text-align:center;
   }

   .count{
    background:var(--border);color:var(--primary);font-family:impact;padding:1px 3px;min-width:25px;text-align:center;margin-left:10px;font-size:15px;
   }

   .count-none{
    background:var(--bg3);
    color:var(--border);
   }
}

.block-btn:hover{
  background:var(--bg1);
  color:var(--text1);
  cursor: pointer;
  .icon{
    background:#333333aa;color:var(--text1)
   }
}

.block-btn:active{
  position: relative;
  right:-1px;
  bottom:-1px;
}

.block-btn-active,.block-btn-active:hover{
  background:var(--primary);
  box-shadow: -1px -1px 1px 1px var(--border);
   color:var(--hover-text);
   .icon{
    padding:5px;background:#333333aa;color:var(--text1);margin-right:5px;border-radius:5px;display:inline-block;width:30px;text-align:center;
   }
   .count{
    color:var(--text3);
   }
}
</style>