<template>
  <FormItem :label="label" :required="required">
    <template v-if="editable">
    <CheckboxGroup 
      :value="value || []"
      @input="handleInput"
      >
      <div class="flex-wrap" style="flex-wrap:wrap;gap:5px 0px">
      <template v-for="o in options">
        <div :key="o.key">
          <Checkbox 
          :label="o.key" :key="o.key" :disabled="!editable"
          :border="config.border" style="margin-right:40px;">
            <Icon v-if="o.icon" :type="o.icon"></Icon>
            <span>{{o.name}} </span>
        </Checkbox>
        </div>
      </template>
    </div>
    </CheckboxGroup>
    </template>
    <template v-else>
      <div>{{val_objects}}</div>
    </template>
 </FormItem>
</template>

<script>
import composedMixin from '../mixins/composed'
import defaultValueMixin from '../mixins/default-value'
export default {
  cname:"多选框",
  corder:50,
  icon:'-duoxuan',
  properties:[{
    label:"选项",
    key:'options',
    control:"SelectOption"
  },{
    label:"简易选项",
    key:'simple',
    control:"Check"
  },{
    label:'边框',
    key:'border',
    control:'Check'
  },{
    label:'URL',
    key:'url',
    control:'Text'
  }],
  mixins:[composedMixin,defaultValueMixin],
 props:['fkey','config','value','editable','label','old','required'],
 initModel(e){
    if(!e.config)
      e.config = {
        border:false,
        simple:true,
        options:['选项1','选项2'],
        url:''
      }
    else{
      e.config = {
        options:e.config.options || ['选项1','选项2'],
        url:e.config.url,
        border:e.config.border,
        simple:e.config.simple,
        multiple:e.config.multiple
      }
    }
   },
 data(){
  return {
    items:[]
  }
 },
 watch:{
  'config':{
    handler(v){
      if(Array.isArray(this.value)){
        this.$emit('input',this.value.filter(v=>this.options.find(t=>t.key == v)))
      }else{
        this.$emit('input',[])
      }
    }
  }
 },

  computed:{
    val_objects(){
      return Array.isArray(this.value)?
        this.value.map(v=>this.options.find(t=>t.key == this.value).name).join(','):"-"
    },
    options(){
      let options = this.config.options || []
      if(Array.isArray(options)){
        if(options && typeof options[0] != 'object')
          options = options.map((v,i)=>({
            key:i,
            name:v
          }))
        
      }
      if(this.config.simple){
        options = options.map(v=>({key:v.name,name:v.name}))
      }
      return options
    }
  },
  methods:{
    handleInput(e){
      this.$emit('input',Array.isArray(e)?e.filter(v=>this.options.find(t=>t.key == v)):[])
    }, 
    
  }
}
</script>

<style lang="less">
.l-full{
  position: relative;
  height:100%;
  width:100%;
}
</style>