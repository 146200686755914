<template>
  <Select 
    v-bind="$attrs" 
    :value="value" 
    @input="$emit('input',$event)"
    transfer
    clearable
    placeholder="请选择"
    filterable
    >
    <template v-if="remote || valueType == 'object'">
        <Option v-for="(o) in computedOptions" :key="'vop_'+o.id" :label="o.name" :value="o.id">
      {{o.name}}
    </Option>
    </template>
    <template v-else>
    <Option v-for="(o,index) in options" :key="'vop_'+o" :label="o" :value="valueType=='index'?index:o">
      {{o}}
    </Option>
    </template>
  </Select>
</template>

<script>
  export default {
    props:{
      value:{
        type:String | Array
      },
      options:{
        type:Array
      },
      valueType:{
        type:String,
        default:"index"
      },
      remote:{
        type:String
      }
    },
    data(){
      return {
        items:[]
      }
    },
    computed:{
      computedOptions(){
        if(this.remote){
          return this.items
        }else{
          return this.options
        }
      }
    },
    mounted(){
      if(this.remote){
        this.$api.afterLogin(()=>{
          this.$api.get(this.remote+`?fields=id,name`).then(res=>{
            this.items = res.data.data
          })
        })
      }
      
    }
  }
</script>

<style lang="scss" scoped>

</style>