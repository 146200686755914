<template>
  <div class="container flex-wrap" style="flex-wrap:wrap;gap:10px;align-content:flex-start;padding:10px;">
    <template v-for="item in items">
        <div :key="item.key" class="hover-lighter" style="width:260px;height:60px;background:var(--border);border-radius:5px;padding:10px" @click="getInfo(item)">
            <div>{{item.name}}</div>
            <div style="color:var(--subtext2);font-size:12px;">{{item.tableName}}</div>
        </div>
    </template>

    <Drawer v-model="showModalInfo" :width="800">
        <h2>{{selected.name}}</h2>
        <BaseSegment title="FIELDS" v-if="selected.fields && selected.fields.length > 0">
            <template v-for="f in selected.fields">
                <div :key="f.key" class="flex-wrap">
                    <div style="font-weight:bold;color:var(--error)">{{f.key}}</div>
                    <div style="margin-left:10px;color:var(--primary)">{{f.type}}</div>
                </div>
            </template>
        </BaseSegment>
        <BaseSegment title="COMPOSED" v-if="selected.composed && selected.composed.length > 0">
            <template v-for="f in selected.composed">
                <div :key="f.key" class="flex-wrap">
                    <div style="font-weight:bold;color:var(--error)">{{f.key}}</div>
                    <div style="margin-left:10px;color:var(--primary);cursor:pointer;" @click="getInfo({key:f.type})">{{f.type}}</div>
                </div>
            </template>
        </BaseSegment>
        <BaseSegment title="COMPUTED" v-if="selected.computed && selected.computed.length > 0">
            <template v-for="f in selected.computed">
                <div :key="f.key" class="flex-wrap">
                    <div style="font-weight:bold;color:var(--error)">{{f.key}}</div>
                    <div style="margin-left:10px;color:var(--primary)" >{{f.type}}</div>
                </div>
            </template>
        </BaseSegment>
    </Drawer>
  </div>
</template>

<script>
export default {
    data(){
        return {
            showModalInfo:false,
            items:[],
            selected:{
                fields:[]
            }
        }
    },
    mounted(){
        this.getData()
    },
    methods:{
        getInfo(item){
            this.$api.get(`objects/${item.key}`).then(res=>{
                this.selected = res.data.data
                this.showModalInfo = true
            })
        },
        getData(){
            this.$api.get('objects').then(res=>{
                this.items = res.data.data
            })
        }
    }
}
</script>

<style>

</style>