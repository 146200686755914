<template>
  <div class="container l-bg"></div>
</template>

<script>
export default {

}
</script>

<style>
.l-bg{
  background:var(--bg1);
}
</style>