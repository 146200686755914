<template>
  <div class="container" style="padding:10px">
    <div class="tool-box">
      <Input search style="width:220px" v-model="filter.searchText" clearable placeholder="请输入项目/员工姓名" />
      <Select placeholder="所属事业部" style="margin-left:10px;width:140px"></Select>
      <Button style="margin-left:10px" @click="filter.history=!filter.history">{{filter.history?'隐藏历史数据':'显示历史数据'}}</Button>
      <Button style="margin-left:10px" @click="filter.unbind=!filter.unbind">{{filter.unbind?'显示全体':'显示未绑定员工'}}</Button>
    </div>
    <div style="height:calc(100% - 40px);position:relative;margin-top:10px;">
      <BaseTable :columns="columns" :loading="loading" :data="filteredItems" :option="{summary:true}" @event="handleTableEvent" />
    </div>


    <Modal v-model="showModalFocus" title="关注原因" width="600" footer-hide>
      <Input type="textarea" v-model="formData.focused_reason" clearable />
      <div class="flex-wrap flex-between" style="margin-top:10px">
        <div class="flex-wrap">
          级别
        <BaseMark v-model="formData.focused" style="margin-left:10px" editable></BaseMark>
        </div>
        
        <Button type="primary" @click="handlePatchFocused">提交</Button>
      </div>
    </Modal>

  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import moment from 'moment'

import { intersection } from 'lodash'
import UTIL from '@/utils'
export default {
  data() {
    return {
      items: [],
      loading: false,
      current: {},
      showModalFocus: false,
      filter:{
        unbind:false,
        history:false,
        searchText:""
      },
      zzl_search_text: "",
      showContract: false,
      showModalTmpl: false,
      showModalDeps: false,
      showModalAssistants: false,
      showModalCharger: false,
      modified: null,
      showModalAdjust: false,
      formData: {},
      business_types: ['全过程咨询', '项目管理', '房建监理', '市政监理', '造价咨询', 'BIM咨询', '招标代理', '对外合作', '其他'],
      business_type_colors: ['orange', '#369', 'green', 'darkred', 'purple', '#3af', 'grown', 'pink', '#333'],
    }
  },
  mounted() {
    this.getData()
    this.$store.dispatch("session/getUsers")
  },
  methods: {
    getData() {
      this.loading = true
      this.$api.get("cm/hrs").then(res => {
        let items = res.data.data
        

        this.items = items
      }).finally(() => {
        this.loading = false
      })
    },
    handleTableEvent(e) {
      if (e.type == 'open') {
        this.RouteTo('/core/projects/' + e.data.id + '/cm')
      }
    },
    handlePatchFocused() {
      this.$api.patch(`projects/${this.formData.id}`, {
        focused: this.formData.focused,
        focused_reason: this.formData.focused_reason
      }).then(res => {
        this.showModalFocus = false

        let updateInfo = res.data.data
        Object.assign(updateInfo,{
          id: this.formData.id,
          focused: this.formData.focused,
          focused_reason: this.formData.focused_reason
        })
        this.updateItem(updateInfo)
        this.formData = {}
        this.$Notice.success({ title: "修改成功" })
      })
    },
    updateItem(item) {
			let index = this.items.findIndex((v) => v.id == item.id);
			if (index != -1)
				this.items.splice(index, 1, Object.assign(this.items[index], item));
			else this.items.splice(0, 0, item);
		},
    handlePatchAdjustAmount() {
      this.$api.patch(`projects/${this.formData.id}`, {
        adjust_amount: this.formData.adjust_amount
      }).then(res => {
        this.showModalAdjust = false

        let updateInfo = {
          id: this.formData.id,
          adjust_amount: this.formData.adjust_amount,
          amount: this.formData.signed_amount + this.formData.adjust_amount
        }

        this.$store.commit('cm/update_project', updateInfo)
        this.formData = {}
        this.$Notice.success({ title: "修改成功" })
      })
    },
    handleSelectFilter(f, v) {
      if (Array.isArray(this.selected_filters[f])) {
        let index = this.selected_filters[f].findIndex(t => t == v)
        if (index != -1)
          this.selected_filters[f].splice(index, 1)
        else
          this.selected_filters[f].push(v)
      } else
        this.$set(this.selected_filters, f, [v])
    },
    inArray(array, sub) {
      if (Array.isArray(array) && Array.isArray(sub) && array.length > 0 && sub.length > 0 && sub.length <= array.length) {
        for (let i = 0; i < sub.length; i++)
          if (!array.includes(sub[i]))
            return
        return true
      }
    },
    outArray(array, sub) {
      if (Array.isArray(array) && Array.isArray(sub) && array.length > 0 && sub.length > 0) {
        for (let i = 0; i < sub.length; i++)
          if (array.includes(sub[i]))
            return false
        return true
      }
    },
    handleOpen(p) {
      this.$store.commit("session/push_project_cm",{id:p.project_id,
      name:p.project_name})
      this.RouteTo('/core/projects/' + p.project_id + '/cm')
    },
    handleClearGroup(g) {
      if (!g)
        this.selected_cols = []
      else
        this.selected_cols = this.selected_cols.filter(v => !g.items.map(t => t.key).includes(v))
    },
    handleSelectAllGroup(g) {
      g.items.forEach(v => {
        if (!this.selected_cols.includes(v.key))
          this.selected_cols.push(v.key)
      })
    },
    handleSelectColumn(key) {
      let index = this.selected_cols.findIndex(v => v == key)
      if (index == -1)
        this.selected_cols.push(key)
      else
        this.selected_cols.splice(index, 1)
    },
    handleTableEvent(e) {
      if (e && e.type == 'open') {
        let baseurl = '/core/projects/' + e.data.id
        // this.$store.commit('session/pushProcess', { name: e.data.name, custom: 'jianzhu', url: baseurl + '/cm', baseurl })
        this.RouteTo(baseurl + '/cm')

      }
    },
    EditCharger(item) {
      this.current = {
        id: item.id,
        charger_id: item.charger_id,
        assistants: item.assistants ? [...item.assistants] : []
      }
      this.showModalCharger = true
    },
    SaveCharger() {
      let item = this.current
      this.$api.patch('projects/' + item.id, { charger_id: item.charger_id, assistants: item.assistants }).then(res => {
        this.$Notice.success({
          title: "修改成功"
        })
        let updateInfo = res.data.data
        updateInfo.id = item.id
        updateInfo.charger_id = item.charger_id
        updateInfo.assistants = item.assistants
        let index = this.items.findIndex(v => v.id == item.id)
        if (index != -1)
          this.items.splice(index, 1, Object.assign(this.items[index], updateInfo))
        this.showModalCharger = false
        this.current = {}
      })
    },
    EditDeps(item) {
      this.showModalDeps = true
      this.current = {
        id: item.id,
        deps: item.deps
      }
    },
    SaveDeps() {
      let item = this.current
      this.$api.patch('projects/' + item.id, { deps: item.deps }).then(res => {
        this.$Notice.success({
          title: "修改成功"
        })
        let updateInfo = res.data.data
        Object.assign(item, updateInfo)
        this.$store.commit('cm/update_project', item)
        this.showModalDeps = false
        this.current = {}
      })
    },
  },
  computed: {
    ...mapGetters('session', ['session', 'users']),
    filteredItems(){
       return this.items.filter(v=>{
          let searchText = this.filter.searchText.trim()
          if(searchText && !v.user_id.includes(searchText) && (!v.name || !v.name.includes(searchText)) && (!v.project_name || !v.project_name.includes(searchText))){
            return false
          }

          if(this.filter.unbind && (v.user_id && v.user_id.includes("-")))
            return false

          if(!this.filter.history && v.finished_at)
            return false
          return true
       })

    },
    deps() {
      if (this.session.groups)
        return this.session.groups.filter(v => v.namespaced)
      else
        return []
    },
    columns() {
      var that = this
      return [ {
        type: 'index',
        key: "code",
        title: "编号",
        width: 70,
      }, {
        type: 'text',
        title: "名称",
        tree: true,
        key: "name",
        minWidth: 240,
        render: (h, param) => {
          let icon = h('BaseIcon', { props: { icon: 'Building-', color: 'var(--subtext3)', size: 20 }, style: "margin-right:5px;" })
          let pic = h('BaseIcon', { props: { icon: 'images', color: 'var(--primary)', size: 12 }, style: "margin-left:5px;" })
          let name = h('a', {            style: "font-size:14px;font-weight:bold;text-align:left", on: {
              click: (e) => {
                this.handleOpen(param.row)
              }
            }          }, param.row.project_name)

          let items = []
          items.push(icon)
          items.push(name)

          return h("div", { class: "flex-wrap", style: "padding-left:10px;" }, items)
        }
      },{
					key: "user_id",
					title: "员工",
					group: "管理",
					width: 120,
					type: "user",
					option: {
						getters: "session/users",
					},
				}, {
        type:"text",
        title:"岗位",
        key:"position",
        width:120,
      },{
       type: 'time',
        title: "开始时间",
        key: "started_at",
        width: 120,
        option:{
          type:"date"
        }
      },{
        type: 'time',
        title: "结束时间",
        key: "finished_at",
        width: 120,
        option:{
          type:"date"
        }
      },  {
        type:"date",
        title:"系数",
        key:"factor",
        width:120,
      },{
        type:"number",
        title:"人力(人月)",
        width:120,
        render:(h,{row})=>{
          let duration = moment.duration((row.finished_at?moment(row.finished_at):moment()) - moment(row.started_at)).as("days")
          return h("span",(duration * (row.factor || 0)).toFixed(1))
        }
      },  {
        type: 'text',
        title: "状态",
        key: "status",
        width: 80,
        option: {

          align: "center",
        },
        render: (h, { row }) => {
          return h('div', { class: "flex-wrap flex-center", style: { height: "40px" } }, [h('div', {            style: {              width: "60px",
              borderRadius: "50px",
              textAlign: "center",
              background: row.finished_at? 'var(--subtext3)' : 'var(--primary)',
              color: "#fff"
            }          }, row.finished_at?'已结束':'进行中')])
        }
      }, {
					key: "created_by",
					title: "操作人员",
					group: "管理",
					width: 120,
					type: "user",
					option: {
						getters: "session/users",
					},
				}, {
        key: "created_at",
        title: "录入时间",
        group: "管理",
        width: 80,
        type: 'time'
      },]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>