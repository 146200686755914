<template>
  <Container>
    <div class="flex-wrap flex-between">
    <Toolbar 
      :items="tools"
      v-model="filter"
      :counts="counts"
      @on-event="handleEvent"
    >
      
    </Toolbar>
    <Button style="margin-right:5px" icon="md-add" type="primary" @click="showCreateForm">新建项目</Button>
    </div>
    
    <div class="table-wrap" style="flex-grow:1;position;relative">
      <BaseTable 
        ref="table"
        :columns="columns"
        :data="items"
        :page="page"
        :page-size="pagesize"
        @event="handleEvent"
        :loading="loading"
        :count="total"
      ></BaseTable>
    </div>

    <Editor
      ref="editor" 
      v-model="showEditor"
      :data="current"
      @on-event="handleEvent"
    />
  </Container>
</template>

<script>
import Container from '@/components/app/container'
import Toolbar from '@/components/app/toolbar'
import Editor from '@/components/business/modal/BidInfoEditor'
import moment from 'moment'
export default {
  routerOption: {
    as_default: true
  },
  components:{Container,Toolbar,Editor},
  data(){
    return {
      filter:{
        status:"草稿"
      },
      showEditor:false,
      items:[],
      loading:false,
      current:{},
      formData:{},
      page:1,
      pagesize:20,
      total:10,
      counts:{
        "草稿":0,
        "审核中":0,
        "审核通过":0,
        "不通过":0
      },
      order:{}
    }
  },
  methods:{
    showCreateForm(){
      this.current = {}
      this.formData = {}
      this.showEditor = true
    },
    Edit(item){
      this.curent = item
      this.formData = {}
      this.showEditor = true
    },
    getStatusCounts(){
      this.$api.getCounts("/business/bid_projects",{
        count_by:'status'
      }).then(res=>{
        this.counts = Object.assign(this.counts,res.data.data)
      })
    },
    getData(){
      this.loading = true
      this.$api.getPaged("/business/bid_projects",{
        page:this.page,
        pagesize:this.pagesize,
        order:this.order,
        filter:this.filter,
        related:['services','building_categories']
      }).then(res=>{
        let {count,list} = res.data.data
        this.items = list
        this.total = count
        this.loading = false
      })
    },
    createItem(model){
      this.$api.post('/business/bid_projects',model).then(res=>{
        this.$Notice.success({title:"创建成功"})
        this.showEditor = false
        this.getData()
      }).finally(()=>{
        this.$refs.editor.stopLoading()
      })
    },
    handleDelete(item){
      this.Confirm(`即将删除此项目<span style="color:var(--error)">${item.name}</span>，是否继续?`,()=>{
        this.$api.delete("/business/bid_projects/"+item.id).then(res=>{
          this.$Notice.success({title:"删除成功"})
          this.getData()
        })
      })
    },
    handleEvent({type,key,value,data}){
      const eventMap = {
        add:this.showCreateForm,
        search:this.getData,
        create:this.createItem,
        refresh:()=>{
          this.getStatusCounts()
          this.getData()

        },
        update:this.updateItem,
        'tab-change':this.getData,
        'page-change':()=>{
          this.page = data
          this.getData()
        },
        'page-size-change':()=>{
           this.pagesize = data
          this.getData()
        },
        'sort':()=>{
           if(!data || !data.key)
            this.order = null
          else
            this.order = data
          this.getData()
        }
      }


      let eventHandler = eventMap[type]
      if(eventHandler){
        eventHandler(value)
      }
    },
  },
  computed:{
    tools(){
      return [{
        key:'search',
        placeholder:"输入项目名称/招标单位/搜索",
        type:"search",
        maxWidth:200
      },{
        key:"status",
        type:"tabs",
        subs:[{
          key:"草稿",
          icon:"file",
          countable:true,
          name:"草稿"
        },{
          key:"审核中",
          icon:"date",
          countable:true,
          name:"审核中"
        },{
          key:"审核通过",
          icon:"pm2",
          countable:true,
          name:"审核通过"
        },{
          key:"未通过",
          icon:"xiangmu2",
          countable:true,
          name:"未通过"
        }]
      },{
        key:'refresh',
        icon:'md-refresh',
        type:"icon-button"
      },]
    },
    columns(){
      var that = this
      return [
          {type:'index',width:80,title:"序号"},
          
          {key:'name',minWidth:100,title:"项目名称",sortable:"custom",
            render:(h,{row})=>{
              return h('div',{
                class:"flex-wrap",
                style:{
                  color:"var(--primary)",
                  textAlign:"left"
                }
              },row.name)
            }
          },
          {key:'bid_unit',width:240,title:"招标人",sortable:"custom",
            
          },
          
          {key:'published_at',width:100,title:"发布时间",sortable:"custom",
            render:(h,{row})=>{

              return h('span',moment(row.created_at).format("YYYY-MM-DD"))
          },},
          {key:"published_platform",width:100,title:"发布平台"},
          {key:'link',width:120,title:"访问链接",
            render:(h,{row})=>{
              if(row.link){
                return h('a',{attrs:{href:'link'}},'访问')
              }else{
                return h('span','-')
              }
          },},
          {key:'services',width:120,title:"服务范围",sortable:false,
            render:(h,{row})=>{
              let services = [{name:"全"},{name:"房"},{name:"BIM"},{name:"BIM"}]
             let limited = services
              if(limited.length > 3){
                limited = limited.slice(0,2)
              }
              let doms = limited.map(s=>h('div',{
                style:"padding:2px 5px;margin-right:1px;flex-shrink:0;background:var(--border);border-radius:2px;border:1px solid var(--border);min-width:25px;"
              },s.name))

              if(services.length > 3){
                let moreDom = h('div',{
                style:"padding:2px 5px;margin-right:1px;flex-shrink:0;background:var(--bg2);border-radius:2px;border:1px solid var(--border);min-width:25px;"
              },`+ ${services.length - 2}`)
                let moreDomTooltip = h('Tooltip',{props:{trigger:"click",content:services.slice(2).map(v=>v.name).join(",")}},[moreDom])
                doms.push(moreDomTooltip)
              }
              return h('div',{class:"flex-wrap flex-center"},doms)
            }
          },
          {key:'building_categories',width:120,title:"工程类型",sortable:false,
            render:(h,{row})=>{
              let building_categories = [{name:"土建"},{name:"安装"},{name:"BIM"},{name:"造价"}]
            
              let limited = building_categories
              if(limited.length > 3){
                limited = limited.slice(0,2)
              }
              let doms = limited.map(s=>h('div',{
                style:"padding:2px 5px;margin-right:1px;flex-shrink:0;background:var(--border);border-radius:2px;border:1px solid var(--border);min-width:25px;"
              },s.name))

              if(building_categories.length > 3){
                let moreDom = h('div',{
                style:"padding:2px 5px;margin-right:1px;flex-shrink:0;background:var(--bg2);border-radius:2px;border:1px solid var(--border);min-width:25px;"
              },`+ ${building_categories.length - 2}`)
                let moreDomTooltip = h('Tooltip',{props:{trigger:"click",content:building_categories.slice(2).map(v=>v.name).join(",")}},[moreDom])
                doms.push(moreDomTooltip)
              }

              return h('div',{class:"flex-wrap flex-center"},doms)
            }
          },
          {key:"controling_price",width:100,title:"招标控制价",sortable:"custom",
        render:(h,{row})=>{
          return h('div',{class:"flex-wrap flex-right"} ,[
            h("BaseAmount",{props:{value:row.controling_price,full:true}}),
            h("span",{style:"color:var(--subtext2);margin-left:5px;font-size:12px"},"万元")
          ])
        }},
          {key:"max_price",width:130,title:"报价区间",
        render:(h,{row})=>{
          return h('div',{class:"flex-wrap flex-center"} ,[
            h("span",{style:row.min_price?'color:var(--primary)':''},row.min_price || '待定'),
            h("span",{style:"color:var(--subtext2);margin:0 5px;font-size:12px"},"-"),
            h("span",{style:row.max_price?'color:var(--primary)':''},row.max_price || '待定'),
             h("span",{style:"color:var(--subtext2);margin:0 5px;font-size:12px"},"万元"),
          ])
        }},
          {key:"bid_eval_method",width:120,title:"评标办法",sortable:"custom",},
          {
            key:'status',width:100,title:"评审状态",sortable:"custom",
          },
          {key:"created_by",width:100,title:"录入人",sortable:"custom",
            render:(h,{row})=>{

              return h('BaseNameBoard',{class:"flex",props:{uid:row.created_by}})
          },},
          {key:"created_at",width:100,title:"录入时间",sortable:"custom",
            render:(h,{row})=>{

              return h('span',moment(row.created_at).fromNow())
          },},
          {key:"tools",width:140,title:"操作",sortable:false,
            render:(h,{row})=>{
              let buttons = [
                h('Button',{props:{size:"small"},on:{click:()=>{that.Edit(row)}}},"修改"),
                h('Button',{props:{size:"small"},on:{click:()=>{that.handleDelete(row)}}},"删除")
              ]
              return h('div',{class:"flex"},[buttons])
            }
          },
        ]
    }
  },
  mounted(){
    this.getData()
    this.getStatusCounts()
    this.$nextTick(()=>{
      if (this.$refs && this.$refs.table && this.$refs.table.calcTableHeight)
        this.$refs.table.calcTableHeight()
      
    })
  },
}
</script>

<style lange="less">
.filter-box{
  height:50px;
}

.table-wrap{
  height:calc(100% - 50px);
  position: relative;
}

</style>