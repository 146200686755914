<template>
  <div>
     <Input ref="user" v-model="value.account" style="margin-top:10px" long placeholder="用户名/手机号" clearable>
      <template #prefix>
        <BaseIcon icon="md-contact" size="22" />
      </template>
      </Input>

    <div class="flex-line" style="margin-top:10px;min-height:20px;">
        <Input v-model="value.code" placeholder="验证码" style="margin-right:10px" :maxlength="6"   @on-enter="login">
         <template #prefix>
            <BaseIcon icon="mail" size="20" />
          </template>
        </Input>
        <Button type="info" ghost style="min-width:120px" :disabled="cooldown > 0 || !value.account" @click="sendVcode">{{cooldown > 0?`已发送(${cooldown})`:(firstTime?'发送验证码':'重新发送')}}</Button>
    </div>
    <Button class="login-btn" style="margin-top:10px" :disabled="!value.code || !value.account || value.code.length !== 6" @click="login">登录</Button>
  
  </div>
</template>

<script>
import BaseIcon from '@components/base/Icon'
  export default {
    data(){
      return {
        cooldown:0,
        firstTime:true
      }
    },
    components:{BaseIcon},
     props:{
      value:{
        type:Object,
        default:()=>({})
      }
    },
    actived(){
       if (this.$refs.user)
        this.$refs.user.focus()
    },
    methods:{
      sendVcode(){
        this.$store.dispatch('session/sendVCode',this.value.account).then(()=>{
          this.firstTime = false
          this.cooldown = 60
          if(this.cooldownInt){
            clearInterval(this.cooldownInt)
            this.cooldownInt = null
          }
          this.cooldownInt = setInterval(()=>{
            this.cooldown -- 
            if(this.cooldown == 0)
              clearInterval(this.cooldownInt)
          },1000)
        })
      },
      login(){
        this.$store.dispatch('session/login_sms',this.value).then(res=>{
          this.$emit('on-login')
        }).catch(e=>{
          this.Error(e)
        })
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>