<template>
	<div
		class="container"
		style="background: var(--bg1); border-left: 1px solid var(--border)"
	>
		<template v-if="path == '/core/materials'">
			<div
				class="flex-wrap flex-line align-start"
				style="width: 1200px; margin: 10% auto"
			>
				<div
					class="flex-wrap"
					style="
						position: absolute;
						right: 20px;
						top: 20px;
						cursor: pointer;
						color: var(--hover-text);
						background: var(--warning);
						border-radius: 10px;
						padding: 0 10px;
						font-size: 15px;
						height: 30px;
					"
					@click="RouteTo('/core/materials/cart')"
				>
					<BaseIcon icon="contract" :size="15" style="margin-right: 5px" />收藏
					{{ cart_count || 0 }}
				</div>
				<div
					class="flex-wrap"
					style="
						position: absolute;
						right: 110px;
						top: 20px;
						cursor: pointer;
						color: var(--hover-text);
						background: var(--primary);
						border-radius: 10px;
						padding: 0 10px;
						font-size: 16px;
						height: 30px;
					"
					@click="showModalEditor = true"
				>
					<BaseIcon icon="md-add" :size="15" style="margin-right: 5px" /> 录入
				</div>
				<div
					style="
						width: 200px;
						background: #333333aa;
						color: var(--hover-text);
						flex-shrink: 0;
						height: 100%;
						margin-right: 20px;
						position: relative;
						border-radius: 10px;
					"
				>
					<template v-for="t in categories">
						<div :key="'cat_' + t.name" class="l-category">
							<BaseIcon
								:icon="t.icon"
								:size="25"
								style="margin-right: 10px"
							/>{{ t.name }}
							<div
								class="hover-panel"
								style="
									position: absolute;
									left: 200px;
									top: 0;
									width: 1100px;
									min-height: 800px;
									background: var(--bg3);
									color: var(--text3);
									z-index: 100;
								"
							>
								<template v-if="t.children">
									<template v-for="c in t.children">
										<div class="l-second" :key="'t_xc' + c.name">
											<div class="l-second-name">{{ c.name }}</div>
											<div class="flex-wrap l-second-list">
												<template v-for="b in c.children">
													<div
														class="l-item"
														:style="b.count?'':'opacity:0.5'"
														:key="'t_xb' + b.name"
														@click="RouteTo(`/core/materials/${b.id}/type`)"
													>
														{{ b.name }}<span v-if="b.count">({{ b.count }})</span>
													</div>
												</template>
											</div>
										</div>
									</template>
								</template>
							</div>
						</div>
					</template>
				</div>
				<div style="flex-grow: 1; margin-left: 100px">
					<h1 style="margin-bottom: 10px; color: var(--hover-text)">
						材料信息库
					</h1>
					<div class="flex-wrap flex-line" style="margin-top: 20px">
						<Input
							size="large"
							placeholder="输入材料名称/型号/品牌/厂家等关键字查询"
							v-model="searchText"
						/>
						<Button
							type="primary"
							style="margin-left: 10px"
							size="large"
							@click="RouteTo('/core/materials/search?search=' + searchText)"
						>
							<BaseIcon icon="md-search" />
							搜索
						</Button>
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			<div
				class="flex-wrap align-start"
				style="
					height: calc(100%);
					padding-top: 41px;
					position: relative;
					background: var(--bg3);
				"
			>
				<div
					class="flex-wrap"
					style="
						height: 42px !important;
						background: var(--bg1);
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
					"
				>
					<div class="flex-wrap">
						<div
							style="
								color: var(--hover-text);
								font-weight: bold;
								font-size: 16px;
							"
							@click="RouteTo('/core/materials')"
						>
							<div
								class="flex-wrap"
								style="
									border-right: 1px solid var(--border);
									width: 150px;
                  flex-shrink:0;
                  padding:0 10px;
									height: 42px;
                  cursor:pointer;
								"
							>
								<BaseIcon icon="huowudui" size="20" style="margin-right: 10px" />
								材料信息库
							</div>
						</div>
					</div>
					<div class="flex-wrap flex-between" style="width: 100%; flex-grow: 1;height:42px;background:var(--bg2);border-bottom:1px solid var(--border)">
						<div class="flex-wrap">
							<Input
								clearable
								style="margin-left: 20px; width: 200px"
								size="small"
								v-model="searchText"
							/>
							<Button
								size="small"
								type="primary"
								icon="md-search"
								style="margin-left: 10px"
								@click="RouteTo('/core/materials/search?search=' + searchText)"
							></Button>
						</div>
						<div class="flex-wrap">
							<ButtonGroup style="margin-right: 15px">
								<Button
									:type="level == 0 ? 'success' : 'default'"
									size="small"
									@click="$store.commit('mat/set_level', 0)"
									>LV1</Button
								>
								<Button
									:type="level == 1 ? 'success' : 'default'"
									size="small"
									@click="$store.commit('mat/set_level', 1)"
									>LV2</Button
								>
								<Button
									:type="level == 2 ? 'success' : 'default'"
									size="small"
									@click="$store.commit('mat/set_level', 2)"
									>LV3</Button
								>
							</ButtonGroup>
							<Button
								@click="showModalEditor = true"
								style="margin-right: 10px"
								size="small"
								icon="md-add"
								>录入</Button
							>
							<Button
								type="primary"
								size="small"
								style="margin-right: 10px"
								icon="md-eye"
								@click="showCart = !showCart"
								>收藏夹({{ cart_count }})</Button
							>
						</div>
					</div>
				</div>
				<Drawer v-model="showCart" inner :transfer="false">
					<div>收藏夹</div>
					<div style="height: calc(100% - 20px); overflow-y: auto; width: 100%">
						<template v-for="(c, i) in cat_items">
							<div
								:key="c.id"
								class="flex-wrap flex-between"
								style="
									padding: 5px 10px;
									margin: 5px 0;
									border-bottom: 1px dashed var(--border);
								"
							>
								<div
									style="max-width: 70%; cursor: pointer"
									@click="
										RouteTo(`/core/materials/${c.id}/info`);
										showCart = false;
									"
								>
									{{ c.name }}
									<div style="font-size: 12px; color: var(--subtext3)">
										{{ c.model }}
									</div>
								</div>
								<div style="text-align: right">
									<BaseIcon
										icon="md-trash"
										style="margin-left: 5px; color: var(--primary)"
										@click="$store.commit('mat/remove_cart', i)"
									></BaseIcon>
								</div>
							</div>
						</template>
					</div>
				</Drawer>

				<div
					style="
						width: 150px;
						background: var(--bg1);
						color: var(--hover-text);
						flex-shrink: 0;
						height: 100%;
						position: relative;
						border-top: 1px solid var(--border);
					"
				>
					<template v-for="t in categories">
						<div :key="'cat_' + t.name" class="l-category l-category-small" @click="selected_menu=t;showMenu=true;">
							<BaseIcon
								:icon="t.icon"
								:size="25"
								style="margin-right: 10px"
							/>{{ t.name }}
							
						</div>
					</template>
				</div>
				<div
					style="
						position: relative;
						height: 100%;
						overflow-y: auto;
						width: 100%;
					"
				>
				<Drawer v-model="showMenu" inner placement="left" :closable="false" :width="900" :transfer="false">
					<div
								style="
									width: 100%;
									min-height: 500px;
									background: #ffffffdd;
									backdrop-filter: blur(2px);
									color: var(--text3);
									padding-right:20px;
									z-index: 100;
								"
							>
								<template v-if="selected_menu && selected_menu.children">
									<template v-for="c in selected_menu.children">
										<div class="l-second" :key="'t_xc2' + c.name">
											<div class="l-second-name">{{ c.name }}</div>
											<div class="flex-wrap l-second-list">
												<template v-for="b in c.children">
													<div
														class="l-item"
														:style="b.count?'':'opacity:0.5'"
														:key="'t_xb2' + b.name"
														@click="showMenu=false;RouteTo(`/core/materials/${b.id}/type`)"
													>
														{{ b.name }} <span v-if="b.count">({{ b.count }})</span>
													</div>
												</template>
											</div>
										</div>
									</template>
								</template>
							</div>
					
				</Drawer>
					<div style="width: 1200px; margin: 0 auto">
						<router-view></router-view>
					</div>
				</div>
			</div>
		</template>

		<Modal
			v-model="showModalEditor"
			title="新增材料信息"
			footer-hide
			:width="1000"
		>
			<FormCreateProject
				style="margin: 0 20px"
				:value="formData"
				@on-cancel="showModalEditor = false"
				@on-submit="handleSubmit"
			/>
		</Modal>
	</div>
</template>

<script>
import FormCreateProject from "@/components/mat/CreateProductForm";
import UTIL from "@/utils";
import { mapGetters } from "vuex";
export default {
	components: { FormCreateProject },
	data() {
		return {
			formData: {},
			showCart: false,
			searchText: "",
			showMenu:false,
			selected_menu:{},
			showModalEditor: false,
			categories: [],
			loading: false,
		};
	},
	computed: {
		...mapGetters("mat", ["level"]),
		path() {
			return this.$route.path;
		},
		cart_count() {
			return this.cat_items.length;
		},
		cat_items() {
			if (
				this.$store.getters &&
				Array.isArray(this.$store.getters["mat/cart_items"])
			)
				return this.$store.getters["mat/cart_items"] || [];
			else return [];
		},
	},
	mounted() {
		setTimeout(() => {
			this.getData();
		}, 500);
	},

	methods: {
		handleClickMenu(e) {
			this.RouteTo(`/core/materials/${e}/type`);
		},
		getData() {
			this.loading = true;
			this.$api
				.get("mat/types")
				.then((res) => {
					let items = res.data.data;
					this.categories = UTIL.makeTree(items, { LIST_KEY: "children" });
				})
				.finally(() => {
					this.loading = false;
				});
		},
		addToCart(item) {
			this.$store.commit("mat/add_cart", item);
			this.$Notice.success({ title: "添加成功" });
		},
		handleSubmit(e) {
			this.$api
				.post("mat/products", e)
				.then((res) => {
					let item = { ...e };
					Object.assign(item, res.data.data);
					this.Success({ title: "添加成功" });
					this.showModalEditor = false;
				})
				.catch(this.Error);
		},
	},
};
</script>

<style lang="less" scoped>
.l-category {
	height: 60px;
	font-size: 18px;
	padding: 0px 20px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid var(--border);
	overflow: visible;
	.hover-panel {
		display: none;
	}
}

.l-category-small {
	height: 42px;
	font-size: 14px;
}

.l-category:hover {
	background: var(--primary);
	color: var(--hover-text);
	cursor: pointer;
	.hover-panel {
		display: block;
	}
}

.l-category-selected {
}

.l-second {
	font-size: 18px;
	display: flex;
	align-items: center;
	margin: 15px 10px;

	.l-second-name {
		width: 200px;
		padding: 0 20px;
		line-height: 27px;
		font-size: 16px;
		color: var(--active);
		display: flex;
		align-items: center;
	background:var(--bg2);
	margin-left:10px;
	margin-right:20px;
	height:60px;
	}

	.l-second-list {
		border-bottom: 1px dashed var(--border);
		padding: 10px 0;
		width: calc(100% - 200px);
		flex-wrap: wrap;
	}

	.l-item {
		cursor: pointer;
		font-size: 16px;
		margin-bottom: 5px;
	}

	.l-item:hover {
		color: var(--primary);
	}

	.l-item:after {
		content: "|";
		margin: 0 10px;
		color: var(--border);
	}
	.l-item:hover:after {
		color: var(--border);
	}

	.l-item:last-child:after {
		display: none;
	}
}

.l-menu-item:hover {
	color: var(--primary);
}
</style>