<template>
  <div class="container in-app">
    <AppBar>
      <div class="flex-wrap" style="font-size:14px;color:var(--bg1)">
      <div class="flex-wrap flex-center"  style="margin-right:5px;width:25px;height:25px;border-radius:5px;border:1px solid var(--border);background:var(--border);">
        
      <BaseIcon :icon="activeItem.icon" :size="16" color="var(--primary)" />
      </div>
      </div>
       <IconTabs :items="tabs" :current="activeTab" @on-select="handleOpen" />
      <div style="font-size:12px;">
        <div>v1.0.0 released at {{ moment().format("YYYY/MM/DD")}} </div></div>
    </AppBar>
    
      
      <div style="height:calc(100% - 46px);background:var(--bg3);">
       <router-view></router-view>
      </div>
    
  </div>
</template>

<script>
  import AppBar from '@/components/app/bar'
  import IconTabs from '@/components/app/IconTabs'
  export default {
    routerOption:{
      as_frame:true
    },
    components:{AppBar,IconTabs},
    methods:{
      handleOpen(e){
        let savedPath = localStorage.getItem('/core/profile/'+e)
        if(savedPath){
          this.RouteTo(savedPath)
        }else{
          this.RouteTo('/core/profile/'+e)
        }
      }
    },
    computed:{
      tabs(){
        return [{
          key: "employee",
          name: "人事管理",
          icon: "md-people",
        },
        //  {
        //   key: "vacation",
        //   name: "假期管理",
        //   icon: "leave2",
        // }, 
        {
          key: "jobs",
          name: "招聘管理",
          icon: "supervisor",
        }, 
        //{
        //   key: "training",
        //   name: "培训管理",
        //   icon: "huaban",
        // }, 
        {
          key: "appraisal",
          name: "考核管理",
          icon: "renzhengchenggong",
        },  
        // {
        //   key: "config",
        //   name: "系统配置",
        //   icon: "config",
        // }
        ]
      },
      activeTab(){
        return this.$route.path.slice(14,this.$route.path.slice(14).indexOf('/')+14)
      },
      activeItem(){
        return this.tabs.find(v=>v.key == this.activeTab) || {}
      }
    }
   
  }
</script>

<style lang="scss" scoped>

</style>