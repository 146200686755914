const moment = require('moment')
import {
  API
} from '@/vue-plugins/api'
const state = {
  deps:[],
  employees:[]
}

const getters = {
  deps(state){
    return state.deps
  },
  employees(state){
    return state.employees
  }
}

const actions = {
  getEmployees({commit}){
    return new Promise((resolve,reject)=>{
      API.get("employees").then(res => {
        commit("saveEmployees", res.data.data)
        resolve()
      }).catch(reject)
    })
  },
  getDeps({commit}){
     return new Promise((resolve, reject) => {
       API.get("groups?q=simple").then(res => {
         commit("saveDeps", res.data.data)
         resolve()
       }).catch(reject)
     })
  }
}

const mutations = {
  saveEmployees(state,items){
    state.employees = items
  },
  saveDeps(state,items){
    items.forEach(v=>{
      let parentIndex = items.findIndex(t => t.id == v.parent_id)
      if(parentIndex == -1)
        delete v.parent_id
    })
    state.deps = items
  }
}

export default {
  namespaced:true,
   state,
   getters,
   actions,
   mutations
}