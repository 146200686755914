<template>
   <div class="container l-report-panel" 
   @click="handleClick">
   <div class="flex-wrap flex-between l-report-panel-title">
      <BaseBoardSubTitle name="人均产值报表 " style="margin:0;padding:0;" />
      <a style="font-weight:normal;font-size:12px">查看详情  <BaseIcon icon="ios-arrow-forward" /></a>
      </div>
    <Row :gutter="20" class-name="panel-hoverable" class="l-report-panel-group">
       
      <Col :span="4"> <div class="container flex-col flex-center l-report-panel-group-title">
        <BaseIcon icon="ios-pie" :size='32' />
        
        计划人均产值
      </div>
      </Col>
      <Col :span="5">
        <BaseNumboard :name="'签约计划(总体)'" :value="t.total_origin_plan_ahp" :unit="t.total_origin_plan_ahp_unit" align="flex-start" reverse></BaseNumboard>
      </Col>
      <Col :span="5">
        <BaseNumboard :name="'调整计划(总体)'" :value="t.total_plan_ahp" :unit="t.total_plan_ahp_unit" align="flex-start" reverse></BaseNumboard>
      </Col>
     
     <Col :span="5">
        <BaseNumboard :name="'签约计划(当前)'" :value="t.current_plan_ahp" :unit="t.current_plan_ahp_unit" align="flex-start" reverse></BaseNumboard>
      </Col>
    </Row>
   <Row :gutter="20" class-name="panel-hoverable" class="l-report-panel-group">
       <Col :span="4"> <div class="container flex-col flex-center l-report-panel-group-title">
        <BaseIcon icon="ios-pie" :size='32' />
        
        实际人均产值
      </div>
      </Col>
      
      <Col :span="4">
        <BaseNumboard :name="'已完成(当前)'" :value="t.actual_ahp" :unit="t.actual_ahp_unit" align="flex-start" reverse></BaseNumboard>
      </Col>
      <Col :span="4">
        <BaseNumboard :name="'调整计划(预测)'" :value="t.predict_ahp" :unit="t.predict_ahp_unit" align="flex-start" reverse></BaseNumboard>
      </Col>
    </Row>
    <Row :gutter="20" class-name="panel-hoverable" class="l-report-panel-group">
      <Col :span="4"> <div class="container flex-col flex-center l-report-panel-group-title">
        <BaseIcon icon="ios-pie" :size='32' />
        各阶段
      </div>
      </Col>
      <template v-if="t.progresses_ahps">
        
      <Col :span="5" v-for="aph in t.progresses_ahps" :key="`_ahp_${aph.name}`">
        <BaseNumboard :name="aph.name" :value="aph.value" :unit="'人月'" align="flex-start" reverse></BaseNumboard>
      </Col>
      </template>
      
    </Row>
     <Row :gutter="20" class-name="panel-hoverable" class="l-report-panel-group">
      <Col :span="4"> <div class="container flex-col flex-center l-report-panel-group-title">
        <BaseIcon icon="ios-pie" :size='32' />
        项目规模
      </div>
      </Col>
      <template v-if="t.scales_ahp">
        
      <Col :span="5" v-for="aph in t.scales_ahp" :key="`_ahp_${aph.name}`">
        <BaseNumboard :name="aph.name" :value="aph.value" :unit="'人月'" align="flex-start" reverse></BaseNumboard>
      </Col>
      </template>
      
    </Row>
      <Row :gutter="20" class-name="panel-hoverable" class="l-report-panel-group">
       <Col :span="4"> <div class="container flex-col flex-center l-report-panel-group-title">
        <BaseIcon icon="ios-pie" :size='32' />
        
        工程类型
      </div>
      </Col>
      <template v-if="t.types_ahp">
        
      <Col :span="5" v-for="aph in t.types_aph" :key="`_ahp_${aph.name}`">
        <BaseNumboard :name="aph.name" :value="aph.value" :unit="'人月'" align="flex-start" reverse></BaseNumboard>
      </Col>
      </template>
      
    </Row>
     <Row :gutter="20" class-name="panel-hoverable" class="l-report-panel-group">
       <Col :span="4"> <div class="container flex-col flex-center l-report-panel-group-title">
        <BaseIcon icon="ios-pie" :size='32' />
        
        服务类型
      </div>
      </Col>
      <template v-if="t.business_types_ahp">
        
      <Col :span="5" v-for="aph in t.business_types_ahp" :key="`_ahp_${aph.name}`">
        <BaseNumboard :name="aph.name" :value="aph.value" :unit="'人月'" align="flex-start" reverse></BaseNumboard>
      </Col>
      </template>
      
    </Row>

    
    <div class="height:calc(100% - 100px);width:100%;background:#33aaff22;border-radius:5px;margin-top:10px;">
      
    </div>
  </div>
</template>

<script>
  export default {
    computed:{
      t(){
        return this.$store.getters["cm/totalData"]
      }
    },
    methods:{
      handleClick(){
        this.RouteTo("/core/cm/projects?q=人均产值报表")
      }
    }
  }
</script>

<style lang="less">
.l-billboard{
  background:var(--bg3);
  color:var(--text3);
  padding:20px;

  .l-billboard-name{
    font-size:14px;
  }

  .l-billboard-value{
    font-size:30px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  }
}
.panel-hoverable{
  background:var(--bg2);
  transition: all 0.5s;
 
}

.panel-hoverable:hover{
  background:var(--bg3);
  cursor: pointer;
}

.l-billboard-link{
  cursor: pointer;
}

.l-billboard-link:hover{
  border: 1px 1px var(--primary);
}

.l-billboard-unit{
  font-size:20px;
  font-family:黑体;
}
</style>