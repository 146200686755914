<template>
  <div>
    <Row :gutter="10" class-name="l-bill">
      <Col :span="2"  style="text-align:center">序号</Col>
       <Col :span="3"  style="text-align:center" v-if="splited">服务类型</Col>
      <Col :span="6">经营类型</Col>
       <Col :span="4" style="text-align:right" >总额</Col>
       <Col :span="4" style="text-align:right" >扣除额</Col>
      <Col :span="3"  style="text-align:right">扣除占比</Col>
    </Row>
    
    <BaseEmpty v-if="items.length == 0" style="padding:20px;" msg="暂无条目" />
    <template v-for="(data,index) in items">
      <Row :key="data.id" :gutter="10" class-name="l-bill">
      <Col :span="2"  style="text-align:center">{{index+1}}</Col>
      <Col :span="3" style="text-align:center"  v-if="splited">{{data.business_type}}</Col>
      <Col :span="6" >{{data.type}}</Col>
       <Col :span="4"  ><BaseAmount full :value="data.amount"></BaseAmount></Col>
       <Col :span="4"  ><BaseAmount full :value="data.deduct_amount"></BaseAmount></Col>
      <Col :span="3"  style="text-align:right;color:var(--success)">{{fix(data.deduct_amount * 100 / data.amount,2)}} %</Col>
     
    </Row>
    </template>
    <Row :gutter="10" class-name="l-bill   l-bill-summary" v-if="items.length > 0">
      <Col :span="4">合计</Col>
      <Col :span="3" v-if="splited"> </Col>
      <Col :span="8">
      <BaseAmount :value="amount" full></BaseAmount>
      </Col>
      <Col :span="4">
      <BaseAmount :value="deduct_amount" full></BaseAmount>
      </Col>
      <Col :span="3" align="right" style="color:var(--success)">
      {{percent}}%
      </Col>
    </Row>
  </div>
</template>
<script>
import {fix} from '@/utils/calc'
  export default {
    data(){
      return {
      }
    },
    props:{
      value:{
        type:Object,
        default:()=>({})
      }
    },
    methods:{
      fix
    },
     computed: {
      splited(){
        return this.value?this.value.splited:false
      },
      items(){
        return this.value.condition_groups || []
      },
    amount() {
      return this.items.reduce((c,item) => {
        return c + item.amount
      }, 0)
    },
    deduct_amount(){
        return this.items.reduce((c,item) => {
        return c + item.deduct_amount
      }, 0)
    },
    percent(){
      return this.fix(this.deduct_amount * 100 / this.amount,2)
    }
  }
  }
</script>

<style lang="scss" scoped>

</style>