<template>
  <div class="container" style="padding:10px;background:var(--bg3);">
    <div class="flex-wrap" style="height:30px">
      <div class="item" :class="{'item-actived':filter == 'total'}" @click="filter='total'" style="margin-right:20px">所有记录 ({{count.total || 0}})</div>
      <template v-for="t in types">
        <div :key="t" class="item"  :class="{'item-actived':t == filter}" @click="page=1;filter=t" style="margin-right:20px">{{t}} ({{count[t] || 0}})</div>
      </template>
    </div>
    <div style="height:calc(100% - 80px);margin-top:10px;border:1px solid var(--border);overflow-y:auto;position:relative;overflow-x:hidden;width:calc(100%);">
      <BaseEmpty v-if="items.length == 0"></BaseEmpty>
      <Spin fix v-if="loading"></Spin>
      <template v-for="item in filteredItems.slice((page-1)*pagesize,page*pagesize)">
        <div class="log-item flex-wrap flex-between" style="border-bottom:1px dashed var(--border);padding:5px;" :key="item.id">
          <div style="color:var(--subtext3);font-size:12px;" class="flex-wrap">
           [<span :style="`color:${mapColor(item.group)};font-weight:bold;`">{{item.group}}</span>] <Divider type="vertical" />  <BaseNameBoard :uid="item.created_by" /> <Divider type="vertical" />  {{fromNow(item.created_at)}} 
            <Divider type="vertical" /> 
           <BaseTpl :data="item.content" style="color:var(--subtext3);" />
          </div>
          <div style="font-size:12px;color:var(--primary);padding-right:5px;"> {{moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}} </div>
          
        </div>
      </template>
      
    </div>
    <div class="flex-wrap flex-right" style="padding: 10px">
      <Page :total="filteredItems.length" :page="page" :page-size="pagesize" @on-change="page=$event" /></div>
    <div style="color:var(--subtext3);margin-top:10px;">仅显示最近30天内的记录</div>
  </div>
</template>

<script>
  import moment from 'moment'
  import UTIL from '@/utils'
  export default {
    data(){
      return {
        loading:false,
        items:[],
        page:1,
        pagesize:100,
        filter:'total',
        count:{total:100}
      }
    },
    computed:{
      types(){
        return ['前期','事业部','项目部','财务','系统']
      },
      filteredItems(){
        return this.items.filter(v=>this.filter == 'total' || v.group == this.filter)
      }
    },
    mounted(){
      
      this.getCount()
      this.getData()
      this.$store.dispatch("session/getUsers")
    },
    methods:{
      mapColor(e){
        return UTIL.mapColor(e,20000)
      },
      fromNow(e){
        return moment(e).fromNow()
      },
      getCount(){
         this.$api.get("cm/logs?q=count").then(res=>{
          this.count = res.data.data
        })
      },
      getData(){
        this.loading = true
        this.$api.get("cm/logs").then(res=>{
          this.items = res.data.data
        }).finally(()=>{
          this.loading = false
        })
      }
    }
  }
</script>

<style lang="less" scoped>
.item{
  cursor: pointer;
}
.item-actived{
  color:var(--primary);
  font-weight: bold;
}

.log-item:hover{
  background:var(--bg3);
}
</style>