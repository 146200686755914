<template>
    <div>
        <div v-if="editable">
        <Select 
            :value="value"
            @on-change="$emit('change',$event)"
            @input="$emit('input',$event)"
            placeholder="选择"
        >
            <Option v-for="o in options" :value="o.value" :key="o.label">
                {{ o.label }}
            </Option>
        </Select>
        </div>
        <div v-else>
            <Static :value="readableValue" />
        </div>
    </div>
  </template>
  
  <script>
import Static from './Static'
  export default {
    components:{Static},
      props:{
          value:{
              type:String
          },
          option:{
              type:Object,
              default:()=>({})
          },
        editable:{
            type:Boolean,
            default:true
        }
      },
      computed:{
        readableValue(){
            return this.value
        },  
        options(){
            const choiceParser = (c)=>{
                return {
                    label:c,
                    value:c
                }
            }
            if(this.option.choices)
                return this.option.choices.map(choiceParser)
            else
                return []
        }
      }
  }
  </script>
  
  <style>
  
  </style>