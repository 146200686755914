<template>
  <div class="container flex-wrap flex-line align-stretch">
    <div
      style="
				width: 300px;
				flex-shrink: 0;
				border-right: 1px solid var(--border);
			"
    >
      <div class="flex-wrap" style="height: 50px; padding: 0 10px; background: var(--bg2)">
        <Input search style v-model="seachText" clearable />
      </div>
      <div style="height: calc(100% - 50px); position: relative; overflow: auto">
        <BaseTree :data="filteredItems" @event="handleTreeEvent" :nodeRenderer="renderNode" />
      </div>
    </div>
    <div style="flex-grow: 1; background: var(--bg2)">
      <template v-if="selected && selected.id">
        <div
          class="flex-wrap flex-between"
          style="
						height: 50px;
						width: 100%;
						border-bottom: 1px solid var(--border);
						padding: 0 10px;
						background: var(--bg2);
					"
        >
          <div>{{ selected.name }}</div>
          <div class="flex-wrap">
            <template v-if="!selected.link">
              <Button
                icon="md-add"
                style="margin-right:10px"
                v-if="parents.length < 2"
                @click="formData={parent_id:selected.id};modalEditor=true"
              >新增类别</Button>
              <Button
                icon="md-add"
                v-if="parents.length < 2"
                @click="showAddLink=true"
                style="margin-right:10px"
              >添加引用</Button>

              <Button style="margin-right: 10px;" icon="md-create" @click="handleEdit(selected)">修改</Button>
              <Button @click="handleDelete" icon="md-trash">删除</Button>
            </template>
            <Button v-else @click="handleDeleteLink" icon="md-link" style="margin-right:10px">删除引用</Button>
          </div>
        </div>
        <div>
          <div style="width: 800px; margin: 20px auto; background: var(--bg3)">
            <Cell>
              <div>分类级别</div>
              <div class="flex-wrap" slot="extra">
                <template v-if="parents">{{ parents.length + 1 }}</template>
                <template v-else>无</template>
              </div>
            </Cell>
            <Cell>
              <div>上级分类</div>
              <div class="flex-wrap" slot="extra">
                <template v-if="parents">
                  <template v-for="(p, i) in parents">
                    <div :key="'_p' + p.id">
                      {{ p.name
                      }}
                      <span
                        v-if="i != parents.length - 1"
                        style="margin: 0 3px; color: var(--subtext3)"
                      >/</span>
                    </div>
                  </template>
                </template>
                <template v-else>无</template>
              </div>
            </Cell>
            <Cell v-if="selected.link">
              <div>引用</div>
              <div slot="extra">是</div>
            </Cell>
			<Cell>
				<div>已关联</div>
				<div slot="extra">{{ selectedType.products ? selectedType.products.length : 0 }}</div>
			</Cell>
			<Cell>
				<div>类型说明</div>
				<div slot="extra" style="max-width:500px;white-space:pre-wrap;padding:10px;">{{ selectedType.desc || '-' }}</div>
			</Cell>
				<div style="margin:10px;padding:0 5px;padding-bottom:20px;" v-if="selectedType.files && selectedType.files.length > 0">
					<div style="font-weight:bold;">规格参数样例</div>
					<div v-for="f in selectedType.files" :key="f.id">
					<div style="margin:5px;"><a @click="DownloadWithName(f.url,f.name)"><BaseIcon icon="md-document" /> {{ f.name }}</a></div>
					</div>
				</div>
          </div>
        </div>
      </template>
    </div>
    <Modal v-model="showAddLink" title="添加引用" footer-hide>
      <div style="padding:10px">
        <Input search v-model="linkSearchText" clearable placeholder="请输入关键字筛选" />
        <div
          style="height:500px;overflow-y:auto;margin-top:10px;border:1px solid var(--border);padding:10px;"
        >
          <template
            v-for="t in items.filter(v=>v.parent_id && v.parent_id !== selected.id && v.id !== selected.id && !v.link && (!linkSearchText || v.name.includes(linkSearchText)))"
          >
            <div class="flex-wrap" :key="t.id">
              <Checkbox v-model="selected_links[t.id]" />
              <div :style="`margin-left:${t.level * 10}px`">{{t.name}}</div>
            </div>
          </template>
        </div>
        <div class="flex-wrap flex-right" style="margin-top:10px">
          <Button type="primary" @click="handleAddLink">提交</Button>
        </div>
      </div>
    </Modal>

    <Modal
      v-model="modalEditor"
      :title="formData && formData.id ? '编辑' : '新建'"
      footer-hide
      width="600"
    >
      <Form :model="formData" v-if="formData">
        <Row>
          <Col :span="20">
            <FormItem required label="名称" prop="name">
              <Input v-model="formData.name" clearable />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col :span="20">
            <FormItem label="上级目录" prop="parent_id">
              <Select v-model="formData.parent_id" clearable>
                <Option
                  v-for="item in items"
                  :key="'opt_' + item.id"
                  :value="item.id"
                  :label="item.name"
                >{{ item.name }}</Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
		<Row>
          <Col :span="24">
            <FormItem label="类型说明" prop="desc">
				<Input type="textarea" clerable v-model="formData.desc" placeholder="请输入相关类型说明" :rows="4"  />
            </FormItem>
          </Col>
        </Row>
		<Row>
          <Col :span="24">
            <FormItem label="附件(选用标准参考)" prop="desc">
				<BaseFlexTable v-model="formData.files" :columns="fileTableColumns">
                   <FileSelector slot="button" style="margin-left:10px"  @change="handleSelectFile" />
                </BaseFlexTable>
            </FormItem>
          </Col>
        </Row>
        <div class="flex-wrap flex-right">
          <Button type="primary" style="margin-right:10px" @click="handleSubmit">提交</Button>
          <Button @click="modalEditor = false">取消</Button>
        </div>
      </Form>
    </Modal>
  </div>
</template>

<script>
import UTIL from '@/utils'
import {cloneDeep} from 'lodash'
export default {
  data() {
    return {
      items: [
        {
          id: 1,
          name: "以及"
        }
      ],
      selectedType: {},
      linkSearchText: "",
      seachText: "",
      showAddLink: false,
      selected_links: {},
      formData: {},
      selected: {},
      parents: [],
      modalEditor: false
    };
  },
  mounted() {
    setTimeout(() => {
      this.getData();
    }, 500);
  },
  computed: {
	fileTableColumns(){
        return [{
          name:"序",
          key:'index',
          control:"index"
        },{
          name:"拖",
          key:'drag',
          control:"drag"
        },{
          key:"type",
          name:"附件类型",
          span:3,
          align:"left",
          control:"AutoComplete",  
          option:{
            size:"small",
            transfer:true,
            data:["规格参数","指导说明"],
            clearable:true,
          }
        },{
          key:"name",
          name:"名称",
          span:6,
          control:"Input",  
          option:{
            size:"small",
            number:true,
            clearable:true
          }
        },{
          key:"url",
          name:"文件",
         
          span:4,
          control:"BaseFileUpload"
        }]
      },
    filteredItems() {
      if (this.seachText) {
        let filtered = this.items.filter(
          v => v.name && v.name.includes(this.seachText)
        );
        let parents = filtered
          .filter(v => v.parent_id)
          .map(v => this.items.find(t => t.id == v.parent_id))
          .filter(v => v);
        while (parents.length != 0) {
          filtered = filtered.concat(parents);
          parents = parents
            .filter(v => v.parent_id)
            .map(v => this.items.find(t => t.id == v.parent_id))
            .filter(v => v);
        }

        return this.items.filter(v => filtered.find(t => t.id == v.id) || this.selected && this.selected.id == v.parent_id);
      } else return this.items;
    }
  },
  methods: {
	handleSelectFile(files){
      if(files && files.length > 0)
      {
         if(!this.formData.files){
          this.$set(this.formData,'files',files)
        }else{
          files.forEach(v=>{
            this.formData.files.push({...v})
          })
        }
      }
     
    },
    handleAddLink() {
      this.submitting = true;
      let link_id_list = Object.keys(this.selected_links).filter(
        key => this.selected_links[key]
      );
      this.$api
        .patch(`mat/types/${this.selected.id}?q=link`, link_id_list)
        .then(res => {
          this.showAddLink = false;
          this.selected_links = {};
          this.linkSearchText = "";
          this.getData();
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    renderNode(h, node, selected) {
      return h(
        "span",
        {
          style: {
            color: selected
              ? node.link
                ? "yellow"
                : "var(--hover-text)"
              : node.link
              ? "var(--primary)"
              : "var(--text3)"
          }
        },
        [node.name]
      );
    },
    getParents(id) {
      let parent = this.items.find(v => v.id == id);
      if (parent) return [...this.getParents(parent.parent_id), parent];
      else return [];
    },
    getData() {
      this.$api.get("mat/types?full=2").then(res => {
        this.items = res.data.data;
      });
    },
    handleTreeEvent(e) {
      if (e.type == "select") {
        this.selected = e.data;
        this.parents = this.getParents(e.data.parent_id);
        this.loadingTypeData();
      }
    },
    loadingTypeData() {
      this.$api.get(`mat/types/${this.selected.id}?related=products,files`).then(res => {
        this.selectedType = res.data.data;
      });
    },
    handleEdit(e) {
		this.old = e
      this.formData = cloneDeep(e)
      this.modalEditor = true;
    },
    handlePatch(e) {
      this.modalEditor = true;
    },
    handleSubmit() {
      if (this.formData.id) {
		let updateData = UTIL.compare_patch_object(this.old,this.formData)
        this.$api
          .patch(`mat/types/${this.formData.id}`, updateData)
          .then(res => {
            let item = { ...this.formData };
            let index = this.items.findIndex(v => v.id == this.formData.id);

            this.items.splice(index, 1, item);
            this.Success({ title: "修改成功" });
            this.modalEditor = false;
          })
          .catch(this.Error);
      } else {
        this.$api
          .post("mat/types", this.formData)
          .then(res => {
            let item = { ...this.formData };
            Object.assign(item, res.data.data);
            this.Success({ title: "添加成功" });
            this.items.push(item);
            this.modalEditor = false;
          })
          .catch(this.Error);
      }
    },
    handleDeleteLink(e) {
      this.Confirm("删除此链接类别，不会影响原始类别，是否继续?", () => {
        this.$api
          .delete(
            "mat/types/" +
              this.selected.id +
              "?q=link&parent_id=" +
              this.selected.parent_id
          )
          .then(() => {
            this.Success({ title: "删除成功" });
            let index = this.items.findIndex(
              v =>
                v.id == this.selected.id &&
                v.parent_id == this.selected.parent_id
            );
            if (index != -1) this.items.splice(index, 1);
          });
      });
    },
    handleDelete(e) {
      this.Confirm(
        "删除此类别，及其子类别,同时会导致所有链接失效，是否继续?",
        () => {
          this.$api.delete("mat/types/" + this.selected.id).then(() => {
            this.Success({ title: "删除成功" });
            let index = this.items.findIndex(v => v.id == this.selected.id);
            while (index != -1) {
              this.items.splice(index, 1);
              index = this.items.findIndex(v => v.id == this.selected.id);
            }
          });
        }
      );
    }
  }
};
</script>

<style>
</style>