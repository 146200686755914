<template>
  <div :style="`color:var(--${type});filter:grayscale(0.4)`">
    <BaseIcon icon="md-alert" size="15" style="margin-right:5px"></BaseIcon>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    props:['type']
  }
</script>

<style lang="scss" scoped>

</style>