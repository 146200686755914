<template>
  <App>
    <AppBar><AppTitle name="模板替换" icon="font-size" /></AppBar>
    <AppClient class="flex-wrap flex-line align-start" style="overflow:hidden">
      <div style="height:100%;flex-shrink:0;width:800px;overflow:hidden">
      <div class="flex-wrap" style="padding:10px">
        <template v-if="tmpls.length > 0">选择一个模板 
          <Select v-model="selected_tmpl" style="width:200px;margin:0 10px" clearable @input="select">
            <template v-for="t in tmpls">
              <Option :value="t.id" :key="t.id">{{t.name}}</Option>
            </template>
            </Select></template>
        <Button @click="showUpload=true;formData={}">上传模板</Button>
        <Button v-if="selected_tmpl" @click="removeTmpl(selected_tmpl)" type="error" style="margin-left:10px">删除</Button>
        <Button type="primary" @click="start" style="margin-left:10px" :disabled="!selected_tmpl" :loading="generating">生成文档</Button>
        
      </div>
      <div v-if="selected.description" style="padding:10px 20px;color:var(--primary);">{{selected.description}}</div>
      
      <div style="margin:0 10px;width:750px;height:calc(100% - 110px);background:var(--bg3);padding:20px;overflow-y:auto;">
       <Spin fix v-if="loading"><BaseLoading /> 处理中</Spin>
        <div class="flex-wrap flex-line"><Button style="margin-right:20px" @click="importExcel">从Excel导入<BaseIcon icon="ios-arrow-forward" style="margin-left:5px" /></Button> 合并文件 <i-switch style="margin-left:10px" v-model="merge"></i-switch></div>
        <div style="color:var(--primary);font-size:12px;padding:5px;">
          注意: EXCEL文件以每列为其替换的数据，标题行对应替换标签
        </div>
        <template v-for="(n,i) in notions">
          <div :key="i" style="margin-top:10px">
          <div class="flex-wrap"><Input placeholder="替换标记" style="width:200px" v-model="n.key" /> <Button style="margin-left:10px" @click="remove(i)">移除</Button></div>
          
          <Input type="textarea" v-model="n.data" placeholder="替代值,请用分号分隔" style="margin-top:10px;width:600px;" />
          </div>
        </template>
      </div>
      </div>
      <div style="padding:20px;flex-grow:1;overflow-y:auto;height:100%;">
        <!-- <h2>使用说明</h2>
        <Timeline>
          <TimelineItem>
            <h4> Step-1 按要求编写模板并上传</h4>
            <Alert v-html="notice"></Alert>
             
            <img style="width:750px" src="https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/docx1.png" /> 
          </TimelineItem>
           <TimelineItem>
            <h4> Step-2 录入数据</h4>
            <Alert style="margin-top:10px">
              自行录入：每个数据用分号；作为分隔符 <br />
              EXCEL导入:注意每组的数据数量要一致使用EXCEL保存数据，每一列标题为模板字符，每列对应内容<br />
              注意：每组数据的总数要一致！</Alert>
          </TimelineItem>
           <TimelineItem>
            <h4>   Step-3 点击生成文档下载文件</h4>
            <Alert>下载文件会按照初始顺序进行命名后缀为_序列号</Alert>
             
            <img style="width:600px" src="https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/docx3.png" /> 
          </TimelineItem>
        </Timeline> -->
        <BasePreview :url="selected.file" />
        <!-- file loader hiding stuff -->
			<input
				ref="fileLoader"
				v-show="false"
				type="file"
				accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,*.csv"
				@change="parse"
			/>
      </div>
    </AppClient>

    <Modal v-model="showUpload" title="上传模板" :width="400" footer-hide>
      <div>
        <Input placeholder="模板名称" v-model="formData.name" />
        <div class="flex-wrap" style="padding:10px;background:var(--bg3);border:1px solid var(--border);border-radius:5px;margin-top:10px;">
          
        <BaseFileUpload v-model="formData.file" label="请上传模板文件(.docx)" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
        
          
        </div>
        <Input placeholder="模板说明" v-model="formData.description" type="textarea" style="margin-top:10px" :rows="5" />
        <div class="flex-wrap flex-right" style="margin-top:10px">
          <Button type="primary" @click="upload">提交</Button>
        </div>
      </div>
    </Modal>
  </App>
</template>

<script>
  import App from '@/components/app/app'
  import AppBar from '@/components/app/bar'
  import AppClient from '@/components/app/client'
  import AppTitle from '@/components/app/title'
  import moment from 'moment'
  import UTIL from '@/utils'
  export default {
    components:{App,AppBar,AppClient,AppTitle},
    data(){
      return {
        tmpls:[],
        formData:{},
        show:"",
        loading:false,
        generating:false,
        showUpload:false,
        selected_tmpl:null,
        notice:'模板说明：每个需要替换的字符请用{A1},{A2}这样的数据代替,循环列表等复杂模式请参考文档:<a href="https://docxtemplater.com/docs/tag-types/">https://docxtemplater.com/docs/tag-types/</a><br />数据说明:按对应标记输入数据<br />- <b>可以直接从excel拷贝进来,换行和空格均可以做为分隔符</b><br />',
        notions:[{key:"",data:""}],
        // config,
        merge:true,
        namekey:""
      }
    },
    computed:{
      selected(){
        return this.tmpls.find(v=>v.id == this.selected_tmpl) || {}
      }
    },
    mounted(){
      this.restoreState()
      this.getData()
    },
    beforeDestroy(){
      this.saveState()
    },
    methods:{
      restoreState(){
        let notions = localStorage.getItem("tmpl")
        if(notions)
          this.notions = JSON.parse(notions)
      },
      processNotions(notions){
        let list = []
        let count = 0
        notions.forEach(v=>{
          
          if(!v.key || v.key=="" || v.key.trim() == "")
            return null
          v.key = v.key.replace(/\s+/g,' ')
          if(!v.data)
            return
          let items = v.data.split(";").map(v=>v || "")
          if(!count){
            count = items.length
            list = items.map(d=>({[v.key]:d}))
          }else if(count != items.length){
            throw new Error(`${v.key}:数量不符(${items.length},需要 ${count})`)
            
          }else{
            items.forEach((t,i)=>{
              list[i][v.key] = t
            })
          }
        })
        return list
      },
      start(){
        
        let notions = null
        try{
          notions = this.processNotions(this.notions)
        }catch(e){
          this.Error(e)
          return
        }
         
        if(!notions || notions.length == 0){
          this.Error("没有正确的数据可以上传,请检查<br /><span style='color:var(--primary)'>提示:是不是忘记填写标记名称了</span>")
          return
        }
        this.generating = true
        this.$api.post("docxt/transfer",{
          id:this.selected_tmpl,
          merge:this.merge,
          namekey:this.namekey,
          notions
          }).then(res=>{
          this.Download(res.data.data)
        }).catch(e=>{
          this.Error(e)
        }).finally(()=>{
          this.generating =false
        })
      },
      saveState(){
        localStorage.setItem("tmpl",JSON.stringify(this.notions))
      },
      add(){
        this.notions.push({key:"",data:""})
      },
      removeTmpl(id){
        this.Confirm("确定删除此模板?",()=>this.$api.delete("/docxt/"+id).then(()=>{
          this.$Notice.success({title:"删除成功"})
          this.tmpls.splice(this.tmpls.findIndex(v=>v.id == id),1)
          if(this.tmpls.length > 0)
            this.select(this.tmpls[0].id)
          else{
            this.selected_tmpl = null
            this.notices = [{}]
          }
        }))
      },
      remove(i){
        if(this.notions.length == 1)
          return
        this.notions.splice(i,1)
      },
      select(t){
        this.selected_tmpl = t
        let tmpl = this.tmpls.find(v=>v.id == t)
        if(tmpl && tmpl.keys){
          this.notions = Object.keys(tmpl.keys).map(v=>{
            return {
              key:v,
              data:""
            }
          })
        }
      },
      getData(){
        this.$api.get("docxt").then(res=>{
          this.tmpls = res.data.data
          if(this.tmpls.length > 0)
            this.select(this.tmpls[0].id)
        })
      },
      importExcel(){
        this.$refs.fileLoader.click();
      },
      parse(file) {
			file = this.$refs.fileLoader.files[0];
			var that = this;
			this.loading = true;
			UTIL.file2Xce(file,{header:1})
				.then((tabJson) => {
          let map = {}
          let columns = {}
          
          let keys = this.notions.map(v=>v.key).filter(v=>v)
          keys.forEach(key=>map[key]=[])
					if (tabJson && tabJson.length > 0) {
          
						let items = tabJson[0].sheet
            // 找到标题行和对应的列
            let title_line = -1
            for(let i=0;i<items.length;i++){
              let line = items[i]
              
              if(line.length == 0)
                break

              if(title_line != -1){
                // 填充数据
                keys.forEach(key=>{
                  if(columns[key] !== undefined){
                    let val = items[i][columns[key]]
                    if(isNaN){
                      val = val || ''
                    }if(!isNaN(val) && val > 30000 && val < 50000)
                      val = moment('1900/01/01').add(val-2,'days').format("YYYY/MM/DD")
                    
                    map[key].push(val)
                  }else{
                    map[key].push('')
                  }
                })
              }else{
                //匹配标题行
                 for(let j=0;j<line.length;j++){
                  let cell = line[j]
                  if(!isNaN(cell))  
                    cell = cell.toString()
                  if(keys.includes(cell)){
                    columns[line[j]] = j
                    if(title_line == -1)
                      title_line = i
                  }
                }
              }
             
            }

            this.notions = keys.map(key=>({key,data:map[key].join(";")}))

					}else{
            this.Error("excel表数据为空")
          }
				})
				.finally((e) => {
					that.$refs.fileLoader.value = "";
          this.loading = false
				});
		},
      upload(){
        if(this.formData.name && this.formData.file){
          this.$api.post("docxt",this.formData).then(res=>{
            let item = Object.assign({},this.formData,res.data.data)
            this.tmpls.splice(0,0,item)
            this.showUpload = false
            this.formData = {}
            this.select(item.id)
          })
        }else{
          this.Error("请输入完整再上传")
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>