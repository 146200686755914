<template>
  <div class="container flex-wrap flex-center">
    
    <div style="margin-bottom:50px">
      <div style="font-size:45px;font-weight:bold;">
      <BaseIcon icon="wechat" size="70" style="margin-right:5px" />
      登录成功！
      </div>
      <h1>您需要绑定或创建一个账户</h1>
      <div style="font-size:14px;margin-top:5px;text-align:right">
      <a @click="Binding">绑定已有账号</a>
      <Divider type="vertical" />
      <a @click="Register">创建新账号</a>
      </div>
    </div>
    <Spin fix v-if="loading">
      <BaseLoading></BaseLoading>
      正在注册中,请稍后
    </Spin>

    <Modal v-model="showBinding" title="绑定已有账号" footer-hide width="400">
      <PasswordForm class="login" :wechat="wechat" style="padding:20px;" @on-login="handleLogined" />
    </Modal>
  </div>
</template>

<script>
  import PasswordForm from "@/components/login/PasswordForm"
  import SMSForm from "@/components/login/SMS"
  export default {
    computed:{
      wechat(){
        return this.$route.query.wechat
      }
    },
    components:{PasswordForm},
    data(){
      return {
        loading:false,
        showBinding:false
      }
    },
    mounted(){
      
    },
    methods:{
      handleLogined(){
        this.$router.push(this.$store.getters['session/login-url'])
      },
      Binding(){
        this.showBinding = true
      },
      Register(){
        this.loading = true
        this.$store.dispatch("session/registerWithWechat",this.wechat).then(res=>{
          this.$router.push(this.$store.getters['session/login-url'])
        }).catch(e=>{
          this.Error(e)
        }).finally(()=>{
          this.loading=false
        })
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>