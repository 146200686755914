<style lang="less">
.l-drawer{
  .ivu-drawer-body{
    padding:0;
    overflow:hidden;
    padding-bottom:5px;
  }
}
</style>
<template>
  <div class="container" style="height:100%;">
    <div
      class="flex-wrap"
      style="padding:10px;font-size:15px;font-weight:bold;height:40px;background:var(--bg1);color:var(--text1)"
    ><BaseIcon icon="checkreport3" style="margin-right:5px;" /> 评分系统:{{ type=='dep'?'事业部评分':'负责人评分' }} </div>
    <div style="position:relative;height:100%;background:var(--bg1);">
      <!-- tool -->
      <div class="flex-wrap split5" style="padding:10px 0;color:#fff;">
        <span class="filter-label" style>搜索</span>
        <Input
          v-model="searchText"
          search
          clearable
          size="small"
          style="margin-left:10px;border-radius:0;width:200px;"
          placeholder="输入姓名搜索"
        />
        <div>
          <span class="filter-label" style>部门</span>
          <ButtonGroup style="margin-left:5px;">
            <template v-for="(d,i) in v_editDeps">
              <Button
                :key="i"
                size="small"
                :type="fdep == d?'warning':'default'"
                :disabled="deps_count[d] == 0"
                @click="fdep=d;fpos=-1;renderEdit()"
              >{{deps[d]}} {{deps_count[d]}}</Button>
            </template>
          </ButtonGroup>
        </div>
        <div class="POSITIONS">
          <span class="filter-label">岗位</span>
          <ButtonGroup style="margin-left:5px;">
            <template v-for="(d,i) in positions">
              <Button
                :key="i"
                size="small"
                :disabled="pos_count[i] == 0"
                :type="fpos == (i)?'warning':'default'"
                @click="fpos=i;renderEdit()"
              >{{d}} {{pos_count[i]}}</Button>
            </template>
          </ButtonGroup>

          <span style="color:#fff;margin-left:25px;color:yellow;">tips：各岗位分类评分，注意选择!</span>
        </div>
      </div>
      <!-- table -->
      <div style="position:relative;height:calc(100% - 80px);background:#ffffffaa;">
        <!-- header -->
        <div
          class="flex-wrap score-item score-item-focus"
          style="padding:10px;width:80px;background:#ffffffaa;display:flex;align-items:center;width:100%;font-size:12px;"
        >
          <div style="width:30px;text-align:center;">置顶</div>
          <div style="width:50px;text-align:center;">姓名</div>
          <template v-if="eQNSheet && eQNSheet.shorts">
            <template v-for="(q,qi) in ['道德',...eQNSheet.shorts]">
              <Tooltip transfer :content="['职业道德',...eQNSheet.cats][qi]" :key="'tooltip'+qi">
                <div style="width:40px;text-align:center;">{{q}}</div>
              </Tooltip>
            </template>
          </template>
          <div class="flex-wrap flex-center" style="width:60px;text-align:center;color:darkred;">总分
            <BaseSortableMarker :value="sort_key=='total' ? sort_order: ''" @input="handleSortChange('total',$event)" />
            </div>
          <template v-if="eQASheet && eQASheet.questions">
            <template v-for="(q,qi) in eQASheet.questions">
              <div style="width:70px;text-align:center;" :key="'title'+qi">{{q.title}}</div>
            </template>
          </template>
          <div class="flex-wrap flex-center" style="width:70px;color:darkred;text-align:center;">评优等级  <BaseSortableMarker :value="sort_key=='CommitLevel' ? sort_order: ''" @input="handleSortChange('CommitLevel',$event)" /></div>
          <div class="flex-wrap flex-center" style="width:70px;text-align:center;">推荐奖项<BaseSortableMarker :value="sort_key=='CommitPride' ? sort_order: ''" @input="handleSortChange('CommitPride',$event)" /></div>
          <div style="width:200px;text-align:center;">评语</div>
        </div>
        <!-- rows -->
        <div style="height:calc(100% - 40px);position:relative;overflow-y:auto;">
          <template v-for="u in [...filteredLocked,...filteredUnlocked]">
            <div
              class="flex-wrap score-item score-item-user"
              style="padding:10px;width:80px;background:#eee;border-bottom:1px solid #ccd;display:flex;align-items:center;width:100%;font-size:14px;"
              :key="'cf-'+u.id"
              :class='editingUser == u.id?"score-item-editing":""'
              @click="ConfirmChange(u)"
            >
              <div style="width:30px;text-align:center;">
                <Button
                  icon="md-lock"
                  @click.stop="lock(u)"
                  :type="u.locked?'warning':'default'"
                  size="small"
                />
              </div>
              <div style="width:50px;text-align:center;">{{u.name}}</div>
              <!-- QN sheet -->
              <template v-if="eQNSheet &&eQNSheet.shorts">
                <template v-for="(q,qi) in ['道德',...eQNSheet.shorts]">
                  <template v-if="editingUser != u.id">
                    <div
                      style="width:40px;"
                      class="l-field"
                      :class="CompareChange(cached[u.id],u,eQNSheet.key+(checkFirst?'n2':'n4'),qi)?'l-field-changed':''"
                      :key="'eqn'+qi"
                    >{{(cached[u.id] && cached[u.id][eQNSheet.key+(checkFirst?'n2':'n4')]? eQNSheet.options[qi][cached[u.id][eQNSheet.key+(checkFirst?'n2':'n4')][qi]] : (u[eQNSheet.key+(checkFirst?'n2':'n4')] ? eQNSheet.options[qi][u[eQNSheet.key+(checkFirst?'n2':'n4')][qi]]:"")) || "-"}}</div>
                  </template>
                  <template v-else>
                    <Dropdown
                      :key="'dropdown'+u.id+qi"
                      @on-click="EditQN(u,eQNSheet.key+(checkFirst?'n2':'n4'),qi,$event)"
                      transfer
                      trigger="click"
                    >
                      <div
                        style="width:40px;"
                        class="l-field l-field-select"
                        :class="CompareChange(model,u,eQNSheet.key+(checkFirst?'n2':'n4'),qi)?'l-field-changed':''"
                        :key="qi"
                      >{{(model[eQNSheet.key+(checkFirst?'n2':'n4')]?eQNSheet.options[qi][model[eQNSheet.key+(checkFirst?'n2':'n4')][qi]]:"") || (u[eQNSheet.key+(checkFirst?'n2':'n4')] ? eQNSheet.options[qi][u[eQNSheet.key+(checkFirst?'n2':'n4')][qi]]:"") || "-"}}</div>
                      <DropdownMenu slot="list">
                        <template v-for="(o,oi) in eQNSheet.options[qi]">
                          <DropdownItem
                            style="font-size:12px;"
                            :selected="oi===(model[eQNSheet.key+(checkFirst?'n2':'n4')]?model[eQNSheet.key+(checkFirst?'n2':'n4')][qi]:(u[eQNSheet.key+(checkFirst?'n2':'n4')]?u[eQNSheet.key+(checkFirst?'n2':'n4')][qi]:undefined))"
                            :key="oi"
                            :name="oi"
                          >{{o}}</DropdownItem>
                        </template>
                      </DropdownMenu>
                    </Dropdown>
                  </template>
                </template>
              </template>
              <!-- Total -->
              <div
                style="width:60px;color:darkred;"
                class="l-field"
              >{{(u.id == editingUser ? CalcScore(eQNSheet,Object.assign({},(model[eQNSheet.key+(checkFirst?'n2':'n4')]?model[eQNSheet.key+(checkFirst?'n2':'n4')]:u[eQNSheet.key+(checkFirst?'n2':'n4')]) || {})): CalcScore(eQNSheet,Object.assign({},(cached[u.id] && cached[u.id][eQNSheet.key+(checkFirst?'n2':'n4')]?cached[u.id][eQNSheet.key+(checkFirst?'n2':'n4')]:u[eQNSheet.key+(checkFirst?'n2':'n4')]) || {}))) || "-"}}</div>
              <!-- QA sheet -->
              <template v-if="eQASheet && eQASheet.questions">
                <template v-for="(q,qi) in eQASheet.questions">
                  <template v-if="editingUser != u.id">
                    <div
                      style="width:70px;"
                      class="l-field"
                      :key="qi"
                      :class='cached[u.id] && cached[u.id][eQASheet.key] && (!u[eQASheet.key] || cached[u.id][eQASheet.key][qi] !== u[eQASheet.key][qi])?"l-field-changed":""'
                    >{{(cached[u.id] && cached[u.id][eQASheet.key]?q.options[cached[u.id][eQASheet.key][qi]] : (u[eQASheet.key] ? q.options[u[eQASheet.key][qi]]:"-")) ||'-'}}</div>
                  </template>
                  <template v-else>
                    <Dropdown 
                      :key="qi" 
                      @on-click="EditQA(u,eQASheet.key,qi,$event)" transfer
                      
                      trigger="click"
                      >
                      <div
                        style="width:70px;"
                        class="l-field l-field-select"
                        :class='model[eQASheet.key] && (!u[eQASheet.key] || model[eQASheet.key][qi] !== u[eQASheet.key][qi])?"l-field-changed":""'
                        :key="qi"
                      >{{((model[eQASheet.key]? q.options[model[eQASheet.key][qi]]:"") || (u[eQASheet.key] ? q.options[u[eQASheet.key][qi]]:'-')) || '-'}}</div>
                      <DropdownMenu slot="list">
                        <template v-for="(o,oi) in q.options">
                          <DropdownItem
                            style="font-size:12px;"
                            :selected="oi===(model[eQASheet.key]?model[eQASheet.key][qi]:(u[eQASheet.key]?u[eQASheet.key][qi]:undefined))"
                            :key="oi"
                            :name="oi"
                          >{{o}}</DropdownItem>
                        </template>
                      </DropdownMenu>
                    </Dropdown>
                  </template>
                </template>
              </template>
              <!-- CommitLevel -->
              <template v-if="editingUser != u.id">
                <div
                  style="width:70px;"
                  class="l-field"
                  :class='cached[u.id] && cached[u.id]["CommitLevel"] !=undefined && cached[u.id]["CommitLevel"] !== u["CommitLevel"]?"l-field-changed":""'
                  :key="'edi-'+u.id"
                >{{(cached[u.id] && cached[u.id]["CommitLevel"] ? model["CommitLevel"] :u["CommitLevel"]) || "-"}}</div>
              </template>
              <template v-else>
                <Dropdown :key="'edr-'+u.id" @on-click="$set(model,('CommitLevel'),$event)" transfer 
                      trigger="click">
                  <div
                    style="width:70px;"
                    class="l-field l-field-select"
                    :class='model.CommitLevel !=undefined && model.CommitLevel !== u["CommitLevel"]?"l-field-changed":""'
                  >{{model.CommitLevel ? model.CommitLevel :u["CommitLevel"]}}</div>
                  <DropdownMenu slot="list">
                    <template v-for="(o) in ['优秀','称职','基本称职','不称职']">
                      <DropdownItem
                        style="font-size:12px;"
                        :key="o"
                        :name="o"
                        :selected="model && o===model.CommitLevel"
                      >{{o}}</DropdownItem>
                    </template>
                  </DropdownMenu>
                </Dropdown>
              </template>
              <!-- CommitPride_n4 -->
              <template v-if="editingUser != u.id">
                <div
                  style="width:70px"
                  class="l-field"
                  :key="u.id"
                  :class='cached[u.id] && cached[u.id]["CommitPride"] !=undefined && cached[u.id]["CommitPride"] !== u["CommitPride"]?"l-field-changed":""'
                >{{(cached[u.id] && cached[u.id]["CommitPride"]? cached[u.id]["CommitPride"] : u["CommitPride"] )|| "-"}}</div>
              </template>
              <template v-else>
                <Dropdown  :key="u.id" @on-click="$set(model,'CommitPride',$event)" transfer 
                      trigger="click">
                  <div
                    style="width:70px;"
                    class="l-field l-field-select"
                    :class='model.CommitPride !=undefined && model.CommitPride !== u["CommitPride"]?"l-field-changed":""'
                  >{{model.CommitPride ? model.CommitPride : u["CommitPride"]}}</div>
                  <DropdownMenu slot="list">
                    <template v-for="(o) in ['进步奖', '敬业奖', '贡献奖']">
                      <DropdownItem
                        style="font-size:12px;"
                        :selected="o===model.CommitPride"
                        :key="o"
                        :name="o"
                      >{{o}}</DropdownItem>
                    </template>
                    <DropdownItem
                      style="font-size:12px;"
                      :selected="undefined===model.CommitPride"
                      key="无"
                      name="无"
                    >无</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </template>
              <!-- Commit -->
              <Tooltip
                transfer
                maxWidth="300"
                :content="model.Commit?model.Commit:(u ? (u['Commit'] || '-') : '-')"
                style="height:24px;"
              >
                <div
                  style="width:200px;padding:0 10px;text-align:left;"
                  :style="`${editingUser == u.id?'border:1px solid #aaa;background:#ffd;box-shadow:1px 1px 1px 0px #aaa;cursor:pointer;':''}`"
                  class="l-field"
                  @click='editingUser == u.id?createEditCommit(u["Commit"]):""'
                  :class="(editingUser==u.id?( model.Commit != undefined && model.Commit != u['Commit']) : (cached[u.id] && cached[u.id].Commit !=undefined && cached[u.id]['Commit'] !== u['Commit']))?'l-field-changed':''"
                >
                  {{(editingUser === u.id?(model.Commit || u['Commit']):(u['Commit'])) || '-'}}
                  <Icon
                    type="md-create"
                    v-show="editingUser === u.id"
                    size="13"
                    style="margin-right:5px;float:right;line-height:24px;color:#aaa;"
                  />
                </div>
              </Tooltip>
              <!-- Buttons -->
              <Button size="small" @click="showReport(u)" icon="md-document">述职报告</Button>

              <Button
                size="small"
                type="success"
                icon="md-checkmark"
                v-if="editingUser==u.id"
                :loading="loadingSaveScore"
                @click="saveScore(u)"
              >保存并上传</Button>
              <Button v-if="editingUser!=u.id" icon="ios-chatboxes-outline" title="可将您的评语推送给评价人，也可以取消推送" :type="u.is_show?'success':'default'" size="small" @click.stop="toggleAnswerShow(u)">
                {{u.is_show?'已推送':'推送评语'}}
                </Button>
              <Button size="small" v-if="editingUser==u.id" @click.stop="cancelScore(u)">重置</Button>
            </div>
            <template v-if="editingUser == u.id">
              <template v-for='(node,idx) in u.answers.filter(v=>v.q4!="n4")'>
                <div
                  :key="'node'+idx+u.id"
                  class="flex-wrap score-item score-item-focus"
                  style="padding:5px;width:80px;background:#ddd;border-bottom:1px solid #ccd;display:flex;align-items:center;width:100%;font-size:12px;"
                >
                  <div
                    style="width:20px;color:#fff;padding:1px;font-size:12px;background:#333;border-radius:2px;text-align:center;"
                  >{{ansNames[node.q4] || node.q4}}</div>
                  <div
                    style="width:65px;text-align:center;display:flex;align-items:center;white-space:nowrap;"
                    v-if="users.find(e=>e.id == node.created_by)"
                  >
                    <BaseNameBoard
                      :uid="node.created_by"
                      style="margin-right:5px;"
                    />
                  </div>
                  <!-- QN sheet -->
                  <template v-if="eQNSheet &&eQNSheet.shorts">
                    <template v-for="(q,qi) in ['道德',...eQNSheet.shorts]">
                      <div
                        style="width:40px;"
                        class="l-field"
                        :key="'eqn'+qi"
                      >{{ (node.q1 ? eQNSheet.options[qi][node.q1[qi]]:"") || "-"}}</div>
                    </template>
                  </template>
                  <!-- Total -->
                  <div
                    style="width:60px;color:darkred;"
                    class="l-field"
                  >{{CalcScore(eQNSheet,Object.assign({},node.q1 || {})) || "-"}}</div>
                  <!-- QA sheet -->
                  <template v-if=" eQADownSheets[node.q4] && eQADownSheets[node.q4].questions">
                    <template v-for="(q,qi) in  eQADownSheets[node.q4].questions">
                      <div
                        style="width:70px;"
                        class="l-field"
                        :key="'qa'+qi"
                      >{{(node.q3 ? q.options[node.q3[qi]]:"-")||'-'}} {{ qi }}</div>
                    </template>
                  </template>
                  <!-- CommitLevel -->
                  <div
                    style="width:70px;"
                    v-if='node.q4 != "n1"'
                    class="l-field"
                    :key="'lcml-'+u.id"
                  >{{(node[`CommitLevel`] || "-")}}</div>

                  <!-- CommitPride_n4 -->

                  <div
                    style="width:70px"
                    v-if='node.q4 != "n1"'
                    class="l-field"
                    :key="'lcmp-'+u.id"
                  >{{(node[`CommitPride`] || "-")}}</div>

                  <!-- Commit -->
                  <Tooltip
                    transfer
                    maxWidth="300"
                    v-if='node.q4!= "n1"'
                    :content="(node[`Commit`] || '-')"
                    style="height:24px;"
                  >
                    <div
                      style="width:200px;background:#fff;padding:0 10px;text-align:left;"
                      class="l-field"
                    >{{ node[`Commit`] || '-'}}</div>
                  </Tooltip>
                  <!-- Buttons -->
                  <Button
                    style="margin-left:10px;"
                    size="small"
                    v-if="!checkFirst && node.q4!='n1'"
                    @click="copyScore(u.id,node)"
                  >复制</Button>
                </div>
              </template>
            </template>
            <!-- rows-->
          </template>
          <template v-if="filteredData.length == 0">
            <BaseEmpty>此岗位还无人提交记录，或选择其他岗位查看</BaseEmpty>
          </template>
        </div>
      </div>
      <!-- table-->
    </div>
    <!-- wrap -->
    <Modal title='编辑评语' v-model='showCommitEditor' footer-hide>
        <Input type='textarea' ref='comment' autofocus :rows='15' :value='model.Commit'  @on-change='$set(model,"Commit",$event.target.value)' />
      </Modal>
    <Drawer class='l-drawer' :title="`述职报告 ${current?' - '+current.name:''}`" :transfer="false" inner v-model="showPreview" width="800">
   
        <div slot='close' style='font-size:16px;line-height:33px;margin-right:10px;'>
          <a v-if="reportURL"  :href="reportURL" >
            <Icon type='md-download' style='margin-right:5px;' />下载文件
          </a>
          <span v-else>未上传文件</span>
        </div>
       
        <BasePreview :url="reportURL" style="margin:0;" />
      </Drawer>
  </div>
</template>

<script>
import Appraisal2023 from "@/components/appraisals/2023.js";
import {mapGetters} from 'vuex'
import {uniq,cloneDeep} from 'lodash'
export default {
    computed:{
        ...mapGetters('session',['users','session']),
        ...Appraisal2023.computed,
        v_editDeps(){
          if(this.checkFirst){
            return uniq(this.items.map(v=>v.dep))
          }else{
            return this.editDeps
          }
        }, 
        filteredLocked(){
        return this.filteredData.filter(v=>v.locked)
        },
        filteredUnlocked(){
        return this.filteredData.filter(v=>!v.locked)
        },
        canSubmitScore(){
        return Object.keys(this.model).length
        },
        pos_count(){
      return [0,1,2,3].map(d=>this.filteredDataByDep.filter(v=>v.position == d).length)
    },
    checkFirst(){
        return this.type !== 'dep'
    },
    deps_count(){
        let records = this.data
         if(this.type !== 'dep'){
            return [0,1,2,3,4,5,6,7].map(d=>records.filter(v=>v.dep == d).length)
        }else{
            return [0,1,2,3,4,5,6,7].map(d=>records.filter(v=>v.dep == d && this.v_editDeps.includes(v.dep)).length)
        }
    },
        filteredDataByDep(){
      let records = this.items
      
       return records.filter(v=>{
          if(this.searchText && (!v.name || !v.name.includes(this.searchText)))
            return false
          if(this.fdep !== v.dep){
            return false
          }
          return true
       })
    },filteredData(){
       let items = this.filteredDataByDep.filter(v=>{
         if(this.fpos != v.position){
          return false
        }
        return true
      })

      if(this.sort_key){
        items = items.sort((a,b)=>{
          let va = a[this.sort_key]
          let vb = b[this.sort_key]
          if(this.sort_key == 'total'){
            va = this.cached[a.id] ? this.cached[a.id][this.eQNSheet.key+(this.checkFirst?'n2':'n4')] : 0
            vb = this.cached[b.id] ? this.cached[b.id][this.eQNSheet.key+(this.checkFirst?'n2':'n4')]:0
          }

          if(va > vb){
            return this.sort_order?1:-1
          }
        })
      }


      return items
    },
    },
    data(){
        return {
            searchText:"",
            fpos:-1,
            fdep:-1,
            eQASheet:{},
            eQNSheet:{},
            editingUser:null,
            current:null,
            showPreview:false,
            sort_key:null,
            sort_order:'asc',
            showCommitEditor:false,
            model:{},
            show:true,
            readed:{},
            loadingSaveScore:false,
            eQADownSheets:{},
            report:"",
            reportURL:'',
            loadingReport:false,
            cached:{},
            items:[],
            changed:false
        }
    },
  props: {
    type: {
      type: String,
      default: "null"
    },
    editDeps:{
        type:Array,
        default:() => ([])
    },
    data: {
      type: Array,
      default: () => ([])
    }
  },
  watch:{
    'type':{
      handler(v){
        this.fdep = -1
        this.fpos = -1
      },
      immediate:true,
      deep:true
    },
    data:{
      handler(v){
        this.initData()
        this.renderEdit()
      },
      immediate:true,
      deep:true
    },
  },
  methods:{
    initData(){
      let lockState = this.restoreLockState() || []
      this.items = this.data.map(v=>{
        let item = {
          id:v.id,
          name:v.name,
          dep:v.dep,
          state:v.state,
          position:v.position,
          created_by:v.created_by,
          report:v.report,
          answers:v.answers || [],
          first_to:v.first_to,
          others_to:v.others_to,
          locked:lockState.includes(v.id)?true:false
        }
        let answer = item.answers.find(v=>this.checkFirst?v.created_by==this.session.id:v.q4 == 'n4')

        this.reflectAnswer(item,answer)
        return item
      })
    },
    reflectAnswer(item,answer){
      if(answer){
          item.answer_id = answer.id
          item[answer.q0] = answer.q1 || []
          item[answer.q2] = answer.q3 || []
          item['Commit'] = answer.Commit
          item['CommitLevel'] = answer.CommitLevel
          item['CommitPride'] = answer.CommitPride
          item.is_show = answer.is_show
        }
    },
    renderEdit(){
      this.$nextTick(e=>{
        console.log(this.fpos)
        if(this.v_editDeps && this.v_editDeps.length > 0)
        {
          if(this.fdep == -1){
            let fdep = this.v_editDeps.find((v)=>this.deps_count[v]>0)
            if(fdep !== null)
              this.fdep = fdep
          }
          if(this.fpos == -1){
            for(let i=0;i<this.positions.length;i++){

              if(!this.pos_count[i]){
                continue
              }

              this.fpos= i
              break
            }

          }
          console.log(this.fpos)
          this.eQADownSheets = {
             n1:Appraisal2023.getEVSheet(this.fpos,'n1'),
             n2:Appraisal2023.getEVSheet(this.fpos,'n2'),
          }
          this.eQASheet = Appraisal2023.getEVSheet(this.fpos,this.checkFirst?'n2':'n4')
          this.eQNSheet = Appraisal2023.getQASheet(this.fdep,this.fpos)
          this.model = {}
        }
      })

    },
    showReport(u){
      this.current=u
      this.reportURL = u.report.url
      this.showPreview=true;
    },
    ConfirmChange(u){
    if(!u || !u.id || this.loadingSaveScore)
        return
    if( u.id == this.editingUser)
     return

    if(this.editingUser)
      this.$set(this,'cached',Object.assign({},this.cached,{[this.editingUser]:{...this.model}}))
     this.$set(this,'model',this.cached[u.id] || {})
     this.editingUser = u.id

      this.$forceUpdate()
    },
    lock(u){
       if(this.canSubmitScore){
        this.Confirm('您的评分还未保存,是否继续?',()=>{
          this.model = {}
          this.editingUser = undefined
        })
      }else{
         this.model = {}
          this.editingUser = undefined
      }

      this.$set(u,'locked',!u.locked)
      this.saveLockState()
    },
    restoreLockState(){
      let lockedraw = localStorage.getItem('appraisal_edit_'+this.session.id+this.type)
      if(lockedraw){
        let locked = JSON.parse(lockedraw)
        return locked
      }
    },
    saveLockState(){
      let locked = this.items.filter(v=>v.locked).map(v=>v.id)
      localStorage.setItem('appraisal_edit_'+this.session.id+this.type,JSON.stringify(locked))
    },
    saveScore(u,q = ('n4')){
      console.log('save')
      if(!u || !u.id)
        return
      //validation
      if(this.checkFirst)
        q = 'n2'
      if(!this.model || Object.keys(this.model).length == 0){
        this.Error("请修改后再提交上传")
        return
      }
      console.log('save:',this.fpos)

      this.model.id =u.id
      this.loadingSaveScore = true
      let qaSheetKey = this.eQASheet.key
      let qnSheetKey =  this.eQNSheet.key + q
      let item = {
        id:u.answer_id,
        q0:qnSheetKey,
        q1:this.model[qnSheetKey],
        q2:qaSheetKey,
        q3:this.model[qaSheetKey],
        q4:q,
        ['Commit']:this.model['Commit'],
        ['CommitPride']:this.model['CommitPride'],
        ['CommitLevel']:this.model['CommitLevel']
      }

      this.$api.patch('oa/appraisal_instances/'+this.model.id,{answer:item}).then(res=>{
        this.Success({title:"保存成功",desc:"您已提交评分结果"})
        let updateInfo = res.data.data
        Object.assign(item,updateInfo)
        
        let index = u.answers.findIndex(v=>v.id == item.id)
        if(index == -1){
          u.answers.push(item)
        }else{
          for(let key in item){
            if(item[key] == undefined)
              delete item[key]
          }
          item = Object.assign({},u.answers[index],item)
          u.answers.splice(index,1,item)
        }
        this.reflectAnswer(u,item)
        let userIndex = this.items.findIndex(v=>v.id == u.id)
        if(userIndex != -1)
          this.items.splice(userIndex,1,u)
        this.changed = true
        console.log('fpos:',this.fpos)
        this.cancelScore()
      }).finally(e=>{
         this.loadingSaveScore = false
      })
    },
    toggleAnswerShow(u){
      console.log(u)
      this.$api.post('oa/appraisal_instances/show-answer',{id:u.answer_id,is_show:!u.is_show}).then(res=>{
        this.$set(u,'is_show',!u.is_show)
      })
    },
    cancelScore(u){
      this.editingUser=undefined
      delete this.cached[u]
      this.$set(this,'model',{})
      this.renderEdit()
    },
    getRowClassName(row,index){
      if(row.readed)
        return 'hs-row-readed'
      else
        return 'hs-row'
    },
    saveReadState(id,state){
      this.readed[id] = state
      this.records.find(v=>v.id == id).readed = state
     localStorage.setItem('appraisal_state',JSON.stringify(this.readed))
    },
    CalcScore(sheet,value = []){
      const values = [10,9.5,9,8.5,8,7.5,7,6.5,6,5.5,5]
      if(sheet && sheet.heavy){
      let s = 0

      if(value[0] == 1)
        s = -50
      sheet.heavy.forEach((v,i)=>{
        s += (values[value[i+1]] || 0) * v / 10
      })
      return s
      }
    },
    EditQA(u,key,index,value){
      console.log(u,key,index,value)
       if(!this.model[key])
       {
         if(u[key])
            this.model[key] = [...u[key]]
         else
          this.model[key] = []
       }
      this.model[key][index] = value
      this.$set(this,'model',Object.assign({},this.model))
    },
    EditQN(u,key,index,value){
    if(!this.model[key])
       {
         if(u[key])
            this.model[key] = [...u[key]]
         else
          this.model[key] = []
       }
       this.model[key][index] = value
      this.$set(this,'model',Object.assign({},this.model))
    },
    createEditCommit(commit){
      this.showCommitEditor = true
      this.model.Commit = commit || ""
      this.$nextTick(e=>{
        this.$refs.comment.focus()
      })

    },
    CompareChange(model,u = {},key,index){
      if(model)
        return model[key] && (!u[key] || model[key][index] !== u[key][index])
    },
    mapColor(ch){
      return this.hs.mapColor(ch)
    },
    handleSortChange(key,order){
      if(order == 'default'){
        this.sort_key = null
      }else{
        this.sort_key = key
        this.sort_order = order
      }
    },
    getReportDownload(name){
       if(!this.current || !this.current.id)
        return
         this.report = ""
          this.reportURL = ""
      this.loadingReport = true
      this.api.enterprise.GET_GZ_APPRAISALS({param:{id:this.current.id}}).then(res=>{
        let data = res.data.data
        if(data)
        {
          this.report = data.html
          this.reportURL = data.url
          this.DownloadWithName(data.url,name)
        }

      }).finally(e=>{
        this.loadingReport = false
      })
    },
    copyScore(uid,node){
   
      this.$set(this.model,this.eQASheet.key,cloneDeep(node.q3))
      this.$set(this.model,this.eQNSheet.key+'n4',cloneDeep(node.q1))
      if(node.CommitLevel)
        this.$set(this.model,'CommitLevel',node.CommitLevel)
       if(node.CommitPride)
        this.$set(this.model,'CommitPride',node.CommitPride)
    },
    
    Switch(func){
      this.show = false
      setTimeout(()=>{
        this.show = true
      },1000)
      if(typeof func == 'function')
        this.func()
    },
  }
};
</script>

<style lang="less" scoped>
.hs-left{
  width:300px;
  position: absolute;
  left:0;
  top:0;
  bottom:0;
  background:#082c49;
}
.caption{
  background:rgb(7, 86, 104);
  padding:10px 20px;
  color:#fff;
  text-align: center;
  filter:drop-shadow(1px 1px 2px #333);
}
.dep{
  height:60px;
  color:#fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
  border-bottom:1px solid rgb(4, 131, 145);
  padding:20px;

  .count{
    color:yellowgreen;
    font-size:12px;
  }
}

.dep:hover{
  background:rgb(2, 161, 164);
  cursor: pointer;
}

.hs-content{
  position: absolute;
  left:0;;
  right:0;
  bottom:0;
  top:0;
}

.filter-label{
  margin:0 5px;
  margin-left:13px;
}

.score-item{
  flex-wrap:wrap;
  >*{
    margin-right:5px;
    margin-bottom:10px;
    flex-shrink: 0;
  }
  padding-bottom:0 !important;
  cursor: pointer;
}

.score-item-user:hover{
  filter:brightness(1.1);
}

.l-field{
  text-align:center;background:#fff;line-height:24px;
  border:1px solid #ccc;
  text-overflow: ellipsis;
  overflow: hidden;
  height:24px;
  white-space: nowrap;
}

.l-field-select{
  border:1px solid #aaa;
  padding:0 5px;
  cursor:text !important;
}



.score-item-focus{
  filter:brightness(1);
}


.score-item-editing{

  filter:brightness(1);
  .l-field-select{
    background:#ffd;
    box-shadow:1px 1px 1px 0px #aaa;
    cursor:pointer;
  }
  .l-field-changed{
    background:#fdd;
  }

}

.l-field-changed{
    background:#fdd;
  }

</style>