<template>
  <div class="flex-wrap flex-between l-bar" :class="dark?'l-bar-dark':''">
    <div class="flex-wrap flex-between" style="flex-grow:1;padding:0 10px;">
      
    <slot></slot>
    </div>
    <Button 
      icon="md-close"
      v-if="closable"
      style="border:none;margin-left:5px;margin-right:5px;" @click="handleClose"></Button>
  </div>
</template>

<script>
  export default {
    props:{
      dark:{
        type:Boolean,
        default:false
      },
      closable:{
        type:Boolean,
        default:true
      }
    },
    methods:{
      handleClose(){
        this.$close()
      }
    }
  }
</script>

<style lang="less" scoped>
  .l-bar{
    height:42px;
    background:var(--bg2);
    color:var(--text2);
    border-top:1px solid var(--border);
    border-bottom:1px solid var(--border);
    
  }

  .l-bar-dark{
    background:var(--bg1);
    color:var(--text1);
  }

</style>