<template>
    <Container>
    <div class="flex-wrap flex-between" style="padding:0 10px">
    <Toolbar 
      :items="tools"
      v-model="filter"
      :counts="counts"
      @on-event="handleEvent"
    >
      
    </Toolbar>
    <div class="flex-wrap">
    <Button style="margin-right:5px" icon="md-add" type="primary" @click="showCreateForm">新建项目</Button>
    <Button style="margin-left:5px" icon="md-download" @click="exportExcel" /> 
    </div>
  </div>
    <div style="height:70px;border-bottom:1px solid var(--border);padding:0 10px">
      <Toolbar 
        :items="filterTool"
        v-model="filter"
        :counts="counts"
        :height="60"
        @on-event="handleEvent"
      />
    </div>
    
    <div class="table-wrap" style="flex-grow:1;position;relative">
      <BaseTable 
        ref="table"
        :columns="columns"
        :data="items"
        :page="page"
        :page-size="pagesize"
        border
        @event="handleEvent"
        :loading="loading"
        :count="total"
      ></BaseTable>
    </div>

    <Editor
      ref="editor" 
      v-model="showEditor"
      :data="current"
      :loading="loadingEditor"
      :width="900"
      model="BU_History"
      @on-event="handleEvent"
    />

    <!-- <Editor
      ref="flow_editor"
      v-model="showFlowEditor"
      :data="current"
      model="BFlowInstance"
      @on-event="handleFlowEditorEvent"
      /> -->
  </Container>
</template>
  
  <script>
  
  import Container from '@/components/app/container'
import Toolbar from '@/components/app/toolbar'
import Editor from '@/components/model/editor'
import moment from 'moment'
import {cloneDeep} from 'lodash'
export default {
      components:{Container,Toolbar,Editor},
      data(){
        return {
          filter:{
          },
          showEditor:false,
          loadingEditor:false,
          showFlowEditor:false,
          flowFormData:{},
          items:[],
          loading:false,
          current:{},
          formData:{},
          page:1,
          pagesize:20,
          total:10,
          counts:{
          },
          order:{
            key:'created_by',
            order:'desc'
          },
          deps:[]
        }
      },
      methods:{
        handleFlowEditorEvent(e){

        },
        showCreateForm(){
          this.current = {}
          this.formData = {}
          this.showEditor = true
        },
        Edit(item){
          this.loadingEditor = true
          this.showEditor = true
          this.$api.get(`business/bu_histories/${item.id}?edit=1`).then(res=>{
            this.current = res.data.data
            this.loadingEditor = false
            this.formData = cloneDeep(this.current)
            this.showEditor = true
          }).catch(e=>{
            this.loadingEditor = false
            this.loadingEditorError = true
          })
        
        },
        exportExcel(){
          this.$refs.table.exportExcel()
        },
        getData(){
          this.loading = true
          this.$api.afterLogin(()=>{
            this.$api.getPaged("/business/bu_histories",{
            page:this.page,
            pagesize:this.pagesize,
            order:this.order,
            filter:this.filter,
            related:['services','building_categories','images']
      }).then(res=>{
    
        let {count,list} = res.data.data
        if(!Array.isArray(list))
          return
        list.forEach(l=>{
          if(l.project_info){
          Object.assign(l,l.project_info)
        }
        if(l.bid_info){
          Object.assign(l,l.bid_info)
        }
        })
        
        this.items = list
        this.total = count
        
      }).catch((e)=>{
        this.Error(e)
      }).finally(()=>{
        this.loading = false
      })


          this.$api.get("/deps?q=subject").then(res=>{
            this.deps = res.data.data
          })
          })
          
        },
        createItem(model){
          this.$api.post('/business/bu_histories',model).then(res=>{
            this.$Notice.success({title:"创建成功"})
            this.showEditor = false
            this.getData()
          }).finally(()=>{
            this.$refs.editor.stopLoading()
          })
        },
        updateItem(model){
          let id =  this.current.id
          this.$api.patch(`/business/bu_histories/${id}`,model).then(res=>{
            this.$Notice.success({title:"修改成功"})
            this.showEditor = false
            this.getData()
          }).finally(()=>{
            this.$refs.editor.stopLoading()
          })
        },
        handleDelete(item){
          this.Confirm(`即将删除此项目<span style="color:var(--error)">${item.name}</span>，是否继续?`,()=>{
            this.$api.delete("/business/bu_histories/"+item.id).then(res=>{
              this.$Notice.success({title:"删除成功"})
              this.getData()
            })
          })
        },
        createFlow(task){
          this.flowFormData = {
            target_id:task.id,
            
          }
          this.showFlowEditor = true
        },
        CreateNext(item){
          this.Confirm(`将为此项目<span style='color:red;margin:0 3px'>${item.name}</span>创建投标工作, 是否继续?`,()=>{
            this.loading = true
            this.$api.post("/business/bu_histories/create-work",{id:item.id}).then(res=>{
              this.Confirm("投标工作已经创建成功,是否跳转",()=>{
                this.RouteTo('work')
              })
            }).catch(e=>{
              this.Error(e)
            }).finally(()=>{
              this.loading = false
            })
          })
          
        },
        Rollback(item) {
          this.$api.patch("/business/bu_histories/"+item.id,{status:'审核中'}).then(()=>{
            this.Success('撤回成功')
          }).catch(e=>{
            this.Error(e)
          })
        },
        handleEvent({type,key,value,data}){
        
          const eventMap = {
            add:this.showCreateForm,
            search:this.getData,
            create:this.createItem,
            refresh:()=>{
              this.getData()

            },
            update:this.updateItem,
            'tab-change':this.getData,
            'page-change':()=>{
              this.page = data
              this.getData()
            },
            'page-size-change':()=>{
              this.pagesize = data
              this.getData()
            },
            'sort':()=>{
              if(!data || !data.key)
                this.order = {
                  key:'created_at',
                  order:'desc'
                }
              else
                this.order = data
              this.getData()
            },
            'clearFilter':()=>{
              this.filter={status:this.filter.status}
              this.getData()
            }
          }


          let eventHandler = eventMap[type]
          if(eventHandler){
            eventHandler(value)
          }else{
            eventHandler = eventMap[key]
            if(eventHandler){
              eventHandler(value)
            }
          }
        },
      },
      computed:{
        tools(){
      return [{
        key:'search',
        placeholder:"输入项目名称/招标单位/搜索",
        type:"search",
        width:300
      },{
        key:"status",
        type:"tabs",
        showTotal:true,
        subs:[{
          key:"草稿",
          icon:"file",
          countable:true,
          name:"草稿"
        },{
          key:"审核中",
          icon:"date",
          countable:true,
          name:"审核中"
        },{
          key:"审核通过",
          icon:"pm2",
          countable:true,
          name:"审核通过"
        },{
          key:"已创建",
          icon:"md-checkmark",
          countable:true,
          name:"已创建"
        },{
          key:"未通过",
          icon:"xiangmu2",
          countable:true,
          name:"未通过"
        }]
      },{
        key:'refresh',
        icon:'md-refresh',
        type:"icon-button"
      },{
        key:'clearFilter',
        icon:'md-filter',
        type:"icon-button"
      },]
    },
    filterTool(){
      return [{
        key:"services",
        type:"tabs",
        name:'项目类型',
        subs:[{
                key:1,
                name:"全过程咨询",
                color:'#369',
                icon:'md-apps'
            },{
              key:2,
                name:"项目管理",
                color:'#369',
                icon:'md-apps'
            },{
              key:3,
                name:"市政监理",
                color:'#369',
                icon:'md-apps'
            },{
              key:4,
                name:"房建监理",
                color:'#369',
                icon:'md-apps'
            },{
              key:5,
                name:"其他",
                color:'#369',
                icon:'md-apps'
            }]
      },{
        key:"building_categories",
        type:"tabs",
        name:'建设类型',
        subs:[{
          key:1,
          icon:"file",
          name:"土建"
        },{
          key:2,
          icon:"date",
          name:"学校"
        },{
          key:3,
          icon:"pm2",
          name:"改造"
        },{
          key:0,
          icon:"xiangmu2",
          name:"其他"
        }]
      }]
    },
        columns(){
          let columns = [{
                key:'id',
                title:'序号',
                align:"center",
                type:'index',
                width:60,
                fixed:'left'
            }, {key:'code',width:120,title:"编号", fixed:'left'},
            {key:'name',width:230,title:"项目名称",sortable:"custom", fixed:'left',
              render:(h,{row})=>{
                let icon = h('BaseIcon' ,{props:{icon:'Building-',size:16,color:'var(--primary)'},style:'margin-left:10px;margin-right:5px;'})
                let tag = h('img',{attrs:{
                   src: "https://nbgz-pmis-1257839135.cos.ap-shanghai.myqcloud.com/beauty/new.gif"
                },style:'width:30px;height:15px;margin-left:5px;margin-right:10px;'})
                if(moment().subtract(3,'days').isAfter(moment(row.created_by)))
                  tag = ''
                let name = h('div',{attrs:{title:row.name},style:"white-space:nowrap;text-overflow:ellipsis;width:calc(100% - 50px;);overflow:hidden;"},row.name)
                return h('div',{
                  class:"flex-wrap",
                  style:{
                    color:"var(--primary)",
                    fontWeight:'bold',
                    textAlign:"left",
                    cursor:'pointer'
                  },
                  on:{
                    click:()=>{
                     this.Edit(row)
                    }
                  }
                },[icon,name,tag])
              }
            },{
                key:'partA',
                title:'业主',
                type:'text',
                width:140,
                sortable:'custom'
            },{
                key:'bid_by',
                title:'中标人',
                type:'text',
                align:"center",
                width:120,
                children:[{

                  key:'bid_charger_id',
                  title:'牵头人',
                  type:'user',
                  width:100,
                sortable:'custom'
                },{
                  key:'members_id',
                  title:'成员',
                  width:100,
                  type:'users',
                  sortable:false
                }]
            },{
              key:'services',
              title:'合同服务范围',
              sortable:'custom',
              width:120,
              render:(h,{row})=>{
              if(!Array.isArray(row.services)){
                return h('span','-')
              }
              let services = row.services.map(v=>v.ref).filter(v=>v)
              
             let limited = services
              if(!limited || limited.length == 0){
                return h('span','-')
              }

              if(limited.length > 3){
                limited = limited.slice(0,2)
              }
              let doms = limited.map(s=>h('div',{
                style:"padding:2px 5px;margin-right:1px;flex-shrink:0;background:var(--border);border-radius:2px;border:1px solid var(--border);min-width:25px;"
              },s && s.name?s.name[0]:''))

              if(services.length > 3){
                let moreDom = h('div',{
                style:"padding:2px 5px;margin-right:1px;flex-shrink:0;background:var(--bg2);border-radius:2px;border:1px solid var(--border);min-width:25px;"
              },`+ ${services.length - 2}`)
                let moreDomTooltip = h('Tooltip',{props:{trigger:"click",content:services.slice(2).map(v=>v.name).join(",")}},[moreDom])
                doms.push(moreDomTooltip)
              }
              return h('div',{class:"flex-wrap flex-center"},doms)
            }
          
            },{
              key:'subject',
              title:'专业',
              width:120
            },{
              key:'location',
              title:"项目所在地",
              width:120
            },{
              key:"charger_id",
              type:'user',
              title:"项目负责人",
              width:120
            },{
              key:"main_sup_id",
              title:"总监",
              type:'user',
              width:120
            },{
              key:'charge_change_note',
              title:"负责人或总监变更说明",
              width:120
            },{
              key:'amount',
                title:'项目投资(万元)',
                type:'text',
                width:180,
                align:"center",
                children:[{

                  key:'total_invest_amount',
                  title:'总投资',
                  type:'number',
                  option:{
                    type:'fullAmount',
                    unit:'万元'
                  },
                  sortable:'custom'
                },{
                  key:'construction_amount',
                  title:'工程费',
                  type:'number',
                  option:{
                    type:'fullAmount',
                    unit:'万元'
                  },
                  sortable:false
                }]
            },{
              key:'area',
                title:'总建筑面积',
                type:'text',
                width:180,
                align:"center",
                children:[{

                  key:'contract_area',
                  title:'合同面积',
                  sortable:'custom'
                },{
                  key:'finish_area',
                  title:'竣工验收资料面积',
                  sortable:false
                }]
            },{
              key:'node_time',
                title:'节点时间',
                type:'text',
                align:"center",
                width:180,
                children:[{

                  key:'signed_at',
                  title:'合同签订日期',
                  type:'time',
                  option:{
                    type:'date',
                  },
                  sortable:'custom'
                },{
                  key:'completed_at',
                  type:'time',
                  option:{
                    type:'date',
                  },
                  title:'竣工验收日期',
                  sortable:false
                }]
            },{
              key:'amount',
                title:'签约合同额(万元)',
                type:'text',
                align:"center",
                width:180,
                children:[{

                  key:'sign_amount',
                  title:'总金额',
                  type:'number',
                  option:{
                    type:'fullAmount',
                    unit:'万元'
                  },
                  sortable:'custom'
                },{
                  key:'other',
                  type:'number',
                  option:{
                    type:'fullAmount',
                    unit:'万元'
                  },
                  title:'合作方',
                  sortable:false
                },{
                  key:'income_amount',
                  title:'公司',
                  type:'number',
                  option:{
                    type:'fullAmount',
                    unit:'万元'
                  },
                  sortable:false
                }]
            },{
                key:'name',
                title:'项目获奖情况说明',
                type:'text',
                width:160,
                sortable:'custom'
            },{
              key:'amount',
                title:'资料原件存档',
                type:'text',
                align:"center",
                width:180,
                children:[{

                  key:'images',
                  type:'file',
                  title:'项目宣传效果图',
                  sortable:'custom'
                },{
                  key:'other',
                  title:'人员变更证明',
                  sortable:false
                },{
                  key:'company',
                  title:'竣工报告',
                  sortable:false
                },{
                  key:'company',
                  title:'竣工验收备案证明书',
                  sortable:false
                },{
                  key:'company',
                  title:'竣工验收备案表',
                  sortable:false
                },{
                  key:'company',
                  title:'竣工验收记录',
                  sortable:false
                },{
                  key:'company',
                  title:'获奖文件或证书',
                  sortable:false
                }]
            },{
                key:'note',
                title:'备注',
                type:'text',
                width:120,
                sortable:'custom'
            },{key:"tools",width:200,title:"操作",sortable:false,
          fixed:"right",
            render:(h,{row})=>{
              var that = this
              let buttons = [
                h('Button',{props:{size:"small"},on:{click:()=>{that.Edit(row)}}},"修改"),
                h('Button',{props:{size:"small"},on:{click:()=>{that.handleDelete(row)}}},"删除"),
             
              ]
              return h('div',{class:"flex-wrap flex-center",style:'gap:4px'},[buttons])
            }
          },]
          return columns
        }
      },
      mounted(){
    this.$api.afterLogin(()=>{
      this.getData()
      this.$nextTick(()=>{
        if (this.$refs && this.$refs.table && this.$refs.table.calcTableHeight)
          this.$refs.table.calcTableHeight()
        
      })
    })
   
  },
    }
  </script>
  
  <style lang="scss" scoped>
  
  </style>