<template>
    <div class="flex-wrap">
      <Dropdown style="display:block;white-space:nowrap;" placement="bottom-start" transfer trigger="click">
        <a style="font-size:0.9rem">我的工作</a>
        <DropdownMenu slot="list">
          <DropdownItem>123</DropdownItem>
          <Dropdown style="display:block" placement="right-start" transfer>
            <DropdownItem>
              团队工作
              <Icon type="ios-arrow-forward"></Icon>
            </DropdownItem>
            <DropdownMenu slot="list">
              <DropdownItem>奉化中学</DropdownItem>
              <DropdownItem>新星商业1#地块</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Dropdown  style="display:block" placement="right-start" transfer>
            <DropdownItem disabled>
              最近访问
              <Icon type="ios-arrow-forward" v-if="false"></Icon>
            </DropdownItem>
            <DropdownMenu slot="list">
              <DropdownItem>无</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </DropdownMenu>
      </Dropdown>
      <span style="margin:0 5px">/</span>
      <GhostInput style="font-size:0.9rem" v-model="value.name"></GhostInput>
    </div>
  </template>
  
  <script>
  import GhostInput from '@/components/app/GhostInput'
  export default {
    components:{GhostInput},
    props: {
      value: {
        type: Object,
        default: () => ({})
      }
    }
  }
  </script>
