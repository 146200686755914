<template>
  <div class="container">
    <h2 style="padding:10px 20px">客户征信管理</h2>
    <div class="flex-wrap" style="height:40px;width:100%;padding:0 5px;">
      <Button type="primary" icon="md-add" style="margin-right:5px" @click="handleCreate()">添加</Button>
      <Input search clearable v-model="searchText" placeholder="按名称搜索" style="width:200px" />
      <Button icon="md-refresh" @click="getData()" />
    </div>
    <div style="height:calc(100% - 90px);position:relative;margin:0 5px;border:1px solid var(--border);border-radius:5px;padding-bottom:5px;">
      <BaseTable :columns="cols" :data="filteredItems" />
    </div>

    <Modal v-model="showModalEditor" :title="formData.id?'修改':'新增'" footer-hide width="1000">
      <Form ref="form" :model="formData" :rules="rules"  label-position="top">
        <BaseSegment title="企业信息" disabled>
        <Row :gutter="20">
          <Col :span="4">
          <FormItem label="LOGO">
           <div  style="width:100%;height:130px;border:1px solid var(--border);border-radius:5px;">
              <GFormImageClip v-model="formData.avatar" :editable="true" 
            :option="{
              uploader:'session/upload'
            }"/>
              </div>
              </FormItem>
          </Col>
          <Col :span="8">
          <Row>
            <Col :span="24">
              <FormItem required label="企业名称" prop="name">
                <Input v-model="formData.name" clearable />
              </FormItem>
            </Col>
          </Row>
          
     
          <Row :gutter="20">
            <Col :span="12">
              <FormItem label="企业类型" prop="ent_type">
                <Input  v-model="formData.ent_type"  clearable />
              </FormItem>
            </Col>
            <Col :span="12">
              <FormItem label="注册资本(万元)" prop="registered_funds">
                <Input  v-model="formData.registered_funds"  number placeholder="请输入数字" clearable />
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col :span="24">
              <FormItem label="官网" prop="site">
                <Input  v-model="formData.site" clearable />
              </FormItem>
            </Col>
          </Row>
           <Row :gutter="20">
            <Col :span="8">
              <FormItem label="信用等级" prop="credit">
                <Select v-model="formData.credit" transfer>
                  <Option :key="'cre_'+c" v-for="(c,i) in credits" :value="i">
                    {{c}}
                  </Option>
                </Select>
              </FormItem>
            </Col>
            <Col :span="16">
              <FormItem label="备注" prop="note">
                <Input  v-model="formData.note" clearable />
              </FormItem>
            </Col>
          </Row>
         
          </Col>
          <Col :span="11" :push="1">
             <Row>
            <Col :span="24">
              <FormItem label="地址" prop="address">
                <Input v-model="formData.address" clearable />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col :span="24">
              <FormItem label="生产基地" prop="product_address">
                <Input v-model="formData.product_address" clearable />
              </FormItem>
            </Col>
          </Row>
             <Row>
            <Col :span="24">
              <FormItem label="简介" prop="description">
                <Input type="textarea" :rows="5" v-model="formData.description" clearable />
              </FormItem>
            </Col>
          </Row>
          </Col>
        </Row>
        	
          
          </BaseSegment>
          <BaseSegment title="联系人">
           <Row>
              <BaseFlexTable v-model="formData.contacts" :columns="contactColumns" />
            </Row>
          </BaseSegment>
           <BaseSegment title="附件" desc="相关的介绍手册、说明书、产品手册等">
              <BaseFlexTable v-model="formData.files" :columns="fileTableColumns">
                <FileSelector slot="button" style="margin-left:10px"  @change="handleSelectFile" />
              
              
              </BaseFlexTable>
           </BaseSegment>
          <div class="flex-wrap flex-right">
          <Button type="primary" style="margin-right:10px" @click="handleSubmit">提交</Button>
						<Button @click="showModalEditor = false">取消</Button>
				</div>
      </Form>
    </Modal>

    <Drawer v-model="showEnterprise" title="企业详情" :width="800">
      <h2 style="margin-bottom:20px">{{current.name}}</h2>
      <BaseSegment title="基本信息" :value="false">

        <div class="flex-wrap">
          <div class="value-field">
          <div class="label">企业类型</div>
          <div class="value" style="white-space:pre-wrap">{{current.企业类型}}</div>
        </div>
          <div class="value-field">
          <div class="label">注册资本</div>
          <div class="value" style="white-space:pre-wrap">{{current.注册资本}}</div>
        </div>
          <div class="value-field">
          <div class="label">法定代表人</div>
          <div class="value" style="white-space:pre-wrap">{{current.法定代表人}}</div>
        </div>
        <div class="value-field">
          <div class="label">成立日期</div>
          <div class="value" style="white-space:pre-wrap">{{current.成立日期}}</div>
        </div>
        </div>
        <div class="flex-wrap">
          <div class="value-field" style="width:50%">
          <div class="label" style="width:120px">统一社会信用代码</div>
          <div class="value" style="white-space:pre-wrap">{{current.统一社会信用代码}}</div>
        </div>
        <div class="value-field" >
          <div class="label" style="width:120px">纳税人识别号</div>
          <div class="value" style="white-space:pre-wrap">{{current.纳税人识别号}}</div>
        </div>
        </div>
        
        <div class="value-field">
          <div class="label">企业地址</div>
          <div class="value" style="white-space:pre-wrap">{{current.地址}}</div>
        </div>
        <div class="value-field">
          <div class="label">企业简介</div>
          <div class="value" style="white-space:pre-wrap">{{current.description}}</div>
        </div>
      </BaseSegment>
      <BaseSegment title="联系人信息" :value="false">
        
        <div class="flex-wrap">
          <div class="value-field" style="width:50%;;">
          <div class="label" style="width:120px">联系人</div>
          <div class="value" style="white-space:pre-wrap;text-align:center;width:120px;">{{current.contact_name}}</div>
        </div>
        <div class="value-field" >
          <div class="label">联系电话</div>
          <div class="value" style="white-space:pre-wrap;text-align:center;width:120px;">{{current.contact_phone}}</div>
        </div>
        </div>
      </BaseSegment>
      <BaseSegment title="企业信用" :value="false">
        <div class="flex-wrap">
          
          <div class="value-field" style="width:30%;;">
          <div class="label" style="width:120px">实地调查报告</div>
          <div class="value" style="white-space:pre-wrap;text-align:center;width:120px;"><a>查看</a></div>
          </div>
          <div class="value-field" style="width:30%;;">
          <div class="label" style="width:140px">第三方信用调查报告</div>
          <div class="value" style="white-space:pre-wrap;text-align:center;width:140px;"><a>查看</a></div>
          </div>
          <div class="value-field" style="width:30%;;">
          <div class="label" style="width:120px">天眼查评分</div>
          <div class="value" style="white-space:pre-wrap;text-align:center;width:120px;">{{current.天眼评分}}</div>
          </div>
          <div class="value-field" style="width:30%;;">
          <div class="label" style="width:120px">2023年资信评价</div>
          <div class="value" style="white-space:pre-wrap;text-align:center;width:120px;">好</div>
          </div>
        </div>
      </BaseSegment>
    </Drawer>

    

  </div>
</template>

<script>
  import GFormImageClip from '@/components/form/ImageClip'
  import FileSelector from '@/components/mat/FileSelector'
  import UTIL from '@/utils'
  import {cloneDeep} from 'lodash'
export default {
  components:{GFormImageClip,FileSelector},
  data(){
    return {
      searchText:"",
      credits:['好','良','差'],
      loadingCurrent:false,
      
      rawData:{},
      current:{
        name:"宁波萌恒绚宇企业管理有限公司",
        description:`基本信息
宁波萌恒绚宇企业管理有限公司，成立于2023年，位于浙江省宁波市，是一家以从事商务服务业为主的企业。企业注册资本5000万美元。
营运状况
通过天眼查大数据分析，宁波萌恒绚宇企业管理有限公司拥有行政许可5个。`,
        注册资本:'5000万美元',
        成立日期:'2023-09-27',
        地址:'浙江省宁波市海曙区段塘俞家启运路3幢1-3室',
        法定代表人:'严光耀',
        统一社会信用代码:'91330203MACYXPBB8X',
        企业类型:'有限责任公司',
        行业:'商业服务业',
        纳税人识别号:'91330203MACYXPBB8X',
        天眼评分:94,
        contact_name:'高晖',
        contact_phone:'13819887435'
      },
       formData:{
        credit:0,
        files:[]
       },
       showEnterprise:false,
       showModalEditor:false,
      items:[]
    }
  },
  computed:{
    rules(){
      return {
        name:[{required:true,message:"企业名称不可为空"}],
        registered_funds:[{type:'number',message:"请填入纯数值",trigger:"blur"}]
      }
    },
    filteredItems(){
      if(this.searchText){
        return this.items.filter(v=>v.name && v.name.includes(this.searchText))
      }
      else
        return this.items
    },
    fileTableColumns(){
        return [{
          name:"序",
          key:'index',
          control:"index"
        },{
          name:"拖",
          key:'drag',
          control:"drag"
        },{
          key:"type",
          name:"附件类型",
          span:3,
          align:"left",
          control:"AutoComplete",  
          option:{
            size:"small",
            transfer:true,
            data:["产品手册","使用说明","介绍视频"],
            clearable:true,
          }
        },{
          key:"name",
          name:"名称",
          span:6,
          control:"Input",  
          option:{
            size:"small",
            number:true,
            clearable:true
          }
        },{
          key:"url",
          name:"文件",
         
          span:4,
          control:"BaseFileUpload"
        }]
      },
    contactColumns(){
        return [{
          name:"序",
          control:"index"
        },{
          name:"拖",
          control:"drag"
        },{
          key:"name",
          name:"姓名",
          control:"Input",
          span:3,
          option:{
            
            size:"small",
            number:true,
            clearable:true
          }
        },{
          key:"phone",
          name:"电话1",
          control:"Input",
          span:3,
          option:{
            
            size:"small",
            clearable:true
          }
        },{
          key:"phone2",
          name:"电话2",
          control:"Input",
          span:3,
          option:{
            
            size:"small",
            clearable:true
          }
        },{
          key:"phone3",
          name:"电话3",
          control:"Input",
          span:3,
          option:{
            
            size:"small",
            clearable:true
          }
        },{
          key:"EMAIL",
          name:"email",
          control:"Input",
          span:3,
          option:{
            
            size:"small",
            clearable:true
          }
        },{
          key:"note",
          name:"备注",
          control:"Input",
          span:3,
          option:{
            
            size:"small",
            clearable:true
          }
        }]
    },
    cols(){
      return [{
        type:"index",
        title:"序号",
        width:80
      },{
        type:"text",
        
        key:"name",
        title:"名称",
        width:300,
        render:(h,{row})=>{
          let logo = h('img',{style:'width:50px;height:50px;border:1px solid var(--border);margin:10px;flex-shrink:0',domProps:{src:row.avatar || 'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/empty-img.png'}})
          let name = h('div',
          {style:"text-align:left"},
          [h('a',{style:'text-align:left',on:{
            click:()=>{
              this.getCurrent(row.id)
            }
          }},row.name),h('div',{style:'color:var(--subtext3);font-size:12px;'},row.address || '')])
          return h('div',{class:'flex-wrap'},[logo,name])
        }
      },{
        type:"text",
        key:"ent_type",
        title:"企业类型",
        align:"center",
        width:120,
        option:{
          align:"center"
        }
      },{
        type:"state",
        key:"credit",
        title:"合作情况",
        align:"center",
        width:140,
        option:{
          states:["好","良","差"],
          colors:['var(--success)','var(--warning)','var(--error)']
        }
      },{
        type:"text",
        key:"site",
        width:140,
        title:"合作项目",
        render:(h,{row})=>{
          let site = row.site
          return h("span",{style:'padding:2px 8px;border-radius:3px;background:var(--bg2);'},row.count)
        }
      },{
        type:"text",
        title:"联系人",
        
        key:"contact_name",
        width:160,
        render:(h,{row})=>{
          return h('span',row.contact_name)
          if(row.contacts && row.contacts.length > 0){
            let icon = h('BaseIcon',{props:{icon:'user'}, style:'margin-right:5px'})
            let name = h('div',{style:'width:50px;padding:1px 3px;background:var(--bg2);color:var(--text2);'},[icon,row.contacts.length])
            let dropdownItem = h('div',{class:'flex-wrap'},[name])
            let contactDoms = row.contacts.map(v=>{
              let items = []
              items.push(h('div',{},'联系人:'+v.contract_name))
              items.push(h('div',{},'电话:'+v.contract_phone))
              if(v.phone2)
                items.push(h('div',{},'电话2:'+v.phone2))
              if(v.phone3)
               items.push(h('div',{},'电话3:'+v.phone3))
              if(v.email)
                items.push(h('div',{},'EMAIL:'+v.email))
              if(v.note)
                items.push(h('div',{},'备注:'+row.note))
              return h('div',items)
            })
            let dropdownContent = h('DropdownMenu',{slot:'list',style:"text-align:left;padding:0 10px;"},contactDoms)
            return h('Dropdown',[dropdownItem,dropdownContent])
          }else
            return h('span','-')
        }
      },{
        type:"text",
        title:"联系电话",
        key:"contact_phone",
        width:160,
        render:(h,{row})=>{
          return h('span',row.contact_phone)
          if(row.contacts && row.contacts.length > 0){
            let icon = h('BaseIcon',{props:{icon:'user'}, style:'margin-right:5px'})
            let name = h('div',{style:'width:50px;padding:1px 3px;background:var(--bg2);color:var(--text2);'},[icon,row.contacts.length])
            let dropdownItem = h('div',{class:'flex-wrap'},[name])
            let contactDoms = row.contacts.map(v=>{
              let items = []
              items.push(h('div',{},'联系人:'+v.contract_name))
              items.push(h('div',{},'电话:'+v.contract_phone))
              if(v.phone2)
                items.push(h('div',{},'电话2:'+v.phone2))
              if(v.phone3)
               items.push(h('div',{},'电话3:'+v.phone3))
              if(v.email)
                items.push(h('div',{},'EMAIL:'+v.email))
              if(v.note)
                items.push(h('div',{},'备注:'+row.note))
              return h('div',items)
            })
            let dropdownContent = h('DropdownMenu',{slot:'list',style:"text-align:left;padding:0 10px;"},contactDoms)
            return h('Dropdown',[dropdownItem,dropdownContent])
          }else
            return h('span','-')
        }
      },{
        type:"text",
        key:"note",
        title:"备注",
        align:"center",
        minWidth:100
      },{
          key:"created_by",
          title:"录入人",
          width:120,
          type:"user"
        },{
          key:"created_at",
          title:"录入时间",
          width:100,
          type:"time"
        },{
        title:"操作",
        render:(h,{row})=>{
          let buttons = [h('Button',{props:{size:"small"},on:{
            click:()=>{
              this.handleEdit(row)
            }
          }},'修改'),
          h('Button',{props:{size:"small"},style:"margin-left:10px",on:{
            click:()=>{
              this.handleDelete(row)
            }
          }},'删除'),
          ]
          return h("div",{class:"flex-wrap flex-center"},buttons)
        }
      }]
    },
  },
  mounted() {
		setTimeout(() => {
			this.getData();
		}, 500);
	},
	methods: {
    handleSelectFile(files){
      if(files && files.length > 0)
      {
         if(!this.formData.files){
          this.$set(this.formData,'files',[files])
        }else{
          files.forEach(v=>{
            this.formData.files.push({...v})
          })
        }
      }
     
    },
    handleCreate(){
      this.formData = {
        registered_funds:0,
        state:0
      }
      this.showModalEditor=true;
    },  
    getCurrent(id){
      // this.loadingCurrent = true
      // this.$api.get(`mat/companies/${id}?full=2`).then(res=>{
      //   this.current = res.data.data
      //   this.showEnterprise = true
      // }).finally(()=>{
      //   this.loadingCurrent = false
      // })’
      this.showEnterprise = true
    },
		getData() {

			this.$api.get("contracts-m/customs").then((res) => {
				this.items = res.data.data;
			});
		},
    handleEdit(e){
      this.$api.get(`mat/companies/${e.id}?related=contacts,files`).then((res)=>{
        this.rawData = res.data.data
        this.formData = cloneDeep(res.data.data)
        this.showModalEditor = true
      })
    },
		handlePatch(e) {
			this.modalEditor = true;
		},
    handleSubmit(){
      if(this.formData.id){
        let updateData = UTIL.compare_patch_object(this.rawData,this.formData)
        this.$api.patch(`mat/companies/${this.formData.id}`,updateData).then(res=>{
          let item = {...this.formData}
          let index = this.items.findIndex(v=>v.id == this.formData.id)
          
          this.items.splice(index,1,item)
          this.Success({title:"修改成功"});
          this.showModalEditor = false
        }).catch(e=>{
          this.Error(e)
        })
      }else{
        this.$refs.form.validate(valid=>{
          if(valid){
            this.$api.post("mat/companies",this.formData).then(res=>{
              let item = {...this.formData}
              Object.assign(item,res.data.data)
              this.Success({title:"添加成功"});
              this.items.push(item)
              this.showModalEditor = false
            }).catch(e=>{
              this.Error(e)
            })
          }
          
        })
        
      }
    },
		handleDelete(e) {
			this.Confirm("删除此企业，已关联的项目会自动失效, 是否继续?", () => {
				this.$api.delete("mat/companies/"+e.id).then(() => {
					this.Success({title:"删除成功"});
          let index = this.items.findIndex(v=>v.id == e.id)
          if(index != -1)
            this.items.splice(index,1)
				});
			});
		},
	},
};
</script>

<style lang="less">
.value-field{
  min-width: 25%;
  margin:10px 0;
  .label{
    color:var(--subtext2);
    padding:2px 0;
    background:var(--bg2);
    width:80px;
    text-align: center;
    border-radius: 3px;
    margin:5px 0;
  }

  .value{
    padding:5px;
  }
}
</style>