<template>
  <div class="container flex-wrap flex-center split10" style="background:var(--bg3);color:var(--text3);">
    <Dropdown transfer @on-click="$store.commit('cm/modifyDep',$event)">
      <div class="cm-button" style="padding-left:15px">
        {{deps.find(v=>v.id == dep)?deps.find(v=>v.id == dep).name:'全公司'}}
        <BaseIcon icon="ios-arrow-down" size="20" style="margin-left:5px" />
      </div>
      <DropdownMenu slot="list">
        <template v-for="d in deps">
          <DropdownItem :name="d.id" :key="d.id">
            <div style="padding:5px">
              {{d.name}}
            </div>
          </DropdownItem>
        </template>
      </DropdownMenu>
    </Dropdown>
    <DatePicker :open="open" :value="timeValue" transfer @on-change="$store.commit('cm/modifyTime',$event);open=false" :type="mode">
      <a href="javascript:void(0)" @click="open=true">
        <div class="cm-button" style="padding-left:15px">
          {{timeValueFormatted}}
          <BaseIcon icon="ios-arrow-down" size="20" style="margin-left:5px" />
        </div>
      </a>
    </DatePicker>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      open:false
    }
  },
  computed: {
    ...mapGetters('cm', ['projects', 'filtered_projects', 'deps', 'mode', 'dep', 'started_at', 'finished_at', 'timeValue', 'timeValueFormatted', 'loading_permit']),
  }
}
</script>

<style lang="scss" scoped>
</style>