<template>
  <div class="container" style="background:var(--bg3);">
    <div class="flex-wrap flex-between" style="height:40px;padding:0 10px;border-bottom:1px solid var(--border)">  
      <div style="font-weight:bold;">数据库服务器状态</div>
      <div style="width:20px;height:8px;border-radius:5px;background:var(--success)"></div>
    </div>
    <div style="padding:10px;border-bottom:1px solid var(--border)">
     
      <div class="flex-wrap flex-between">
        <div>版本</div><div>{{data.version}}</div>
      </div>
      <div class="flex-wrap flex-between">
        <div>内存</div><div> {{parseInt(data.Total_server_memory_used)}} B</div>
      </div>
      <div class="flex-wrap flex-between">
        <div>存储</div><div> {{parseInt(data.Total_innodb_memory_used)}} B</div>
      </div>
      <div class="flex-wrap flex-between">
        <div>已传入数据</div><div>{{data.Bytes_received}} B</div>
      </div>
      <div class="flex-wrap flex-between">
        <div>已输出数据</div><div>{{data.Bytes_sent}} B</div>
      </div>
      <div class="flex-wrap flex-between">
        <div>系统运行时间</div><div>{{parseInt(data.Uptime)}} s</div>
      </div>
      <div class="flex-wrap flex-between">
        <div>cdb_working_mode</div><div>{{(data.cdb_working_mode)}}</div>
      </div>
      <div class="flex-wrap flex-between">
        <div>已连接</div><div>{{data.Connections}}</div>
      </div>
      
    </div>
   

  </div>
</template>

<script>
  export default {
    props:['data']
  }
</script>

<style lang="scss" scoped>

</style>