<template>
<div>
  <Dropdown trigger="custom" :visible="showDropdown" @on-select="handleSelect" placement="bottom-start" @on-clickoutside="handleClickOut" transfer>
    <div class="flex-wrap flex-center" style="width:35px;height:35px;border:1px solid var(--border);border-radius:2px;" @click="showDropdown=true;"><BaseIcon :icon="value" :size="25" v-if="value" />
    <span v-else style="color:var(--subtext2)">空</span></div>
    <DropdownMenu slot="list" style="width:300px;">
      <div  ref="panel" class="flex-wrap flex-center" style="flex-wrap:wrap;align-content: flex-start;">
        <template v-for="icon in icons">
          <div class="icon-wrap flex-wrap flex-center" style="width:25px;height:25px;background:var(--border);margin:2px;" @click="handleSelect(icon)">
            <BaseIcon :icon="icon" :size="20" />
          </div>
        </template>
      </div>
    </DropdownMenu>
  </Dropdown>
</div>
</template>
<script>
export default {
  data(){
    return {
      showDropdown:false,
      icons:[
  "tubiao15",
  "richangbaoji01",
  "lvyou",
  "xiangmu3",
  "jianzhuwu",
  "jianzhu",
  "lianjie",
  "tongzhi",
  "xiaoxi",
  "xiangmu4",
  "renwu",
  "renwu1",
  "xuexiao",
  "gongyuan",
  "qiaoliang",
  "huiyi",
  "point",
  "wenhao",
  "css",
  "font-size",
  "codelibrary-fill",
  "shop-car",
  "colors",
  "link",
  "font",
  "shouji",
  "xiangmu",
  "wechat1",
  "xiangmu1",
  "Menu",
  "xiangmu2",
  "qq",
  "dingding-o",
  "apps",
  "desktop",
  "bbs",
  "subtract",
  "message",
  "remindfill",
  "mail",
  "heart",
  "folder1",
  "share",
  "chart-pie",
  "close",
  "location",
  "share1",
  "bid",
  "controls-5",
  "jinxingzhong",
  "right",
  "del",
  "shipin",
  "clock",
  "jianbao",
  "meeting",
  "voiceon",
  "info",
  "jia",
  "tag",
  "key",
  "plus",
  "heart1",
  "gongzuobaogao",
  "quality",
  "history",
  "area-chart",
  "bar-chart",
  "play",
  "add",
  "brand",
  "wenjian",
  "renzhengchenggong",
  "task",
  "baogao",
  "money",
  "shibai",
  "checkreport3",
  "examine",
  "checkreport2",
  "pm2",
  "dairy",
  "leave2",
  "bim",
  "search",
  "images",
  "device",
  "refresh2",
  "jjian-",
  "tag1",
  "trigon_icon",
  "huaban",
  "jiludanzilishijilu",
  "tag2",
  "fenzuguanli",
  "Building-",
  "table",
  "renwujincheng",
  "switch",
  "liujisuan",
  "lianjieliu",
  "bug",
  "shenjing",
  "refresh",
  "overview",
  "checkreport",
  "huowudui",
  "star1",
  "wechat",
  "energy",
  "Filecheck",
  "Filelocked",
  "claim",
  "handle",
  "interface",
  "organization",
  "parameter",
  "config",
  "role",
  "contact",
  "nbgz",
  "QR-code",
  "QR-code1",
  "quanxianliebiao",
  "houtaishezhi",
  "jiayongdianqi",
  "depart",
  "download",
  "upload",
  "supervisor",
  "folder",
  "eventnote",
  "file",
  "date",
  "add1",
  "examinereport2",
  "phone",
  "jilu",
  "home",
  "filter",
  "chart",
  "user",
  "dot",
  "group",
  "star",
  "event",
  "backarrow",
  "voiceicon",
  "eventavailable",
  "date2",
  "stop",
  "delete",
  "iconset0118",
  "down",
  "photo",
  "rizhi",
  "group1",
  "iconfang7",
  "workflowdesign",
  "location1",
  "save",
  "chart3",
  "meetingrecord",
  "edit",
  "bumenguanli",
  "wancheng",
  "other",
  "app",
  "lock",
  "contract",
  "leave",
  "refresh1",
  "wendang"
        ]

    }
  },
  props:{
    value:{
      type:String
    }
  },
  methods:{
    handleSelect(e){
      this.$emit('input',e)
      this.showDropdown = false
    },
    handleClickOut(e){
      if(!this.$refs.panel.contains(e.target)){
        this.showDropdown = false
        if(Array.isArray(this.value)){
          this.selected = this.value
        }else if(this.value){
          this.selected = this.multiple?[...this.value]:(this.value?[this.value]:[])
        }else{
          this.selected = []
        }
      }
    },
  }
}
</script>
<style lang="less">

</style>