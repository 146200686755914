var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","position":"relative","background":"linear-gradient(to bottom right,var(--bg3),var(--bg2))"}},[_c('Form',{ref:"form",staticClass:"ugo-form",attrs:{"label-width":_vm.form?.config?.labelWidth || 100,"label-position":_vm.form?.config?.labelPosition || 'right',"rules":_vm.rules,"model":_vm.model}},[(_vm.form.id && _vm.fields.length == 0)?_c('BaseEmpty',{attrs:{"msg":"表单配置错误, 请联系管理员"}}):_vm._e(),(_vm.form.html)?[_c('BaseForm',{attrs:{"form":{
            layout:_vm.form.html,
            def:_vm.formDef,
            option:{
              hideTools:true
            }
          },"editable":_vm.editable},on:{"submit":function($event){_vm.formData=$event}},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})]:[_vm._l((_vm.fields),function(p){return [(_vm.acl[p.fullkey]!=2)?_c('div',{key:p.id,staticClass:"form-item"},[_c(p.control,{ref:p.key,refInFor:true,tag:"component",attrs:{"config":p.config || {},"editable":_vm.editable && (!p.config || !p.config.disabled) && !_vm.acl[p.fullkey],"label":p.name,"acl":_vm.acl,"fkey":p.key,"old":_vm.isContainer(p.control)?_vm.old:(_vm.old?_vm.old[p.key]:undefined),"model":_vm.model,"required":p.required,"fields":p.fields,"p_many":_vm.form.composed_many_keys,"p_one":_vm.form.composed_one_keys,"value":_vm.isContainer(p.control)?_vm.model:(_vm.model[p.key]!=undefined?_vm.value[p.key]:undefined)},on:{"input":function($event){return _vm.handleInput(p.key,$event)}}})],1):_vm._e()]})]],2),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }