<template>
    <App>
      <AppMask class="flex-wrap flex-center" style="background:var(--bg2);border-left:1px solid var(--border);overflow:hidden;z-index:1000;color:var(--subtext2);transition:all 0.3s ease-out" v-if="loading" :style="ready?'':'top:120%;'">
        <div class="flex-col">
        <h2 style="font-size:40px">经营管理 {{ selected_key }}</h2>
        <div class="flex-wrap" style="margin-top:10px">
        <BaseIcon icon="nbgz" color="var(--subtext2)" size="40" />
        <BaseLoading style="margin:0" />
        <BaseIcon icon="iconfang7" color="var(--subtext2)" size="40" />
        </div>
        
        </div>
      </AppMask>
      <AppMask class="flex-wrap flex-center" style="background:var(--bg1);border-left:1px solid var(--border);color:#fff;transition:all 0.3s ease-out;bottom:50%;" v-if="loading" :style="ready?'':'bottom:120%'">
        <div>
        </div>
      </AppMask>
      <AppBar class="flex-between" @click.self="open=false">
        <div class="flex-wrap">
          <AppTitle name="经营管理" icon="iconfang7" />
        </div>
        <AppMenu 
          :menus="menus" 
          :actived-key="selected_key" 
          base-route="/core/business/"
          :small="sm"
        ></AppMenu>
        <div></div>
      </AppBar>
      <AppClient>
        <Drawer v-model="showFlow" width="600" inner title="在途业务" :styles="{'padding':0}">
          <div class="container" style="background:var(--bg2)">
            
          工作流
          </div>
        </Drawer>
        <keep-alive>
          <router-view ref='sub' ></router-view>
        </keep-alive>
      </AppClient>
    </App>
  </template>
  
  <script>
  import App from '@/components/app/app'
  import AppBar from '@/components/app/bar'
  import AppTitle from '@/components/app/title'
  import AppMenu from '@/components/app/menu'
  import AppClient from '@/components/app/client'
  import AppMask from '@/components/app/mask'
  
  import responsableMixin from '@/mixins/responsable'
  import { mapGetters } from 'vuex'
  export default {
    components: { App, AppBar, AppTitle, AppMenu, AppClient,AppMask },
    routerOption: {
      as_frame: true
    },
    data() {
      return {
        open: false,
        selected_key:"",
        showFlow:false,
        ready:false,
        loading:false,
        loadingUserDetail:false,
        currentUser:{},
        showUserInfo:false,
      }
    },
    watch:{
      $route:{
        handler(v){
          let path = this.$route.path
            let cmIndex = path.indexOf('/business/') + 10
            let lastSlashIndex = path.lastIndexOf('/')
    
            this.selected_key = path.slice(cmIndex, lastSlashIndex <= cmIndex ? undefined : (lastSlashIndex))
        },
        immediate:true,
        deep:true
      }
    },
    
    mixins:[responsableMixin],
    computed: {
      ...mapGetters('cm', ['permission','my_role','projects', 'filtered_projects', 'deps', 'mode', 'dep', 'started_at', 'finished_at', 'timeValue', 'timeValueFormatted', 'loading_permit','flows','flow_todo_count','flow_in_count']),
      sm(){
        return this.w < 800
      },
      menus() {
        const menus = [{
          name: '总览',
          key: 'dashboard'
        },
        
        {
          name: '投标',
          key:"bid"
         
        }, 
        {
          name:'签约',
          key:'sign'
        },
        {
           name: '履约',
           key: 'performance'
         },{
           name: '结算',
           key: 'settlement'
         },{
          divider:true,
           name: '变更',
           key: 'change'
         },  {
           name: '业绩',
           key: 'achivement'
         }, {
           name: '客户',
           key: 'custom'
         }, {
           name: '配置',
           key: 'setting'
         }
      ]
            return menus
      }
    },
    methods:{
      changeRole(r){
        this.loading = true
        setTimeout(()=>{
          this.loading = false
        },1000)
        this.$store.commit('cm/change_role',r)
      },
      handleOpenUser(user){
        this.showUserInfo = true
        this.currentUser = {...user} 
        this.loadingUserDetail = true
        this.$api.get('/cm/employees/'+user.id).then(res=>{
          Object.assign(this.currentUser,res.data.data)
        }).finally(()=>{
          this.loadingUserDetail = false
        })
      },
    },
    mounted() {
        this.loading = true
      
      this.ready = true
    setTimeout(()=>{
      this.ready = false
      setTimeout(()=>{
        //this.loading = false
      },1000)
    },1000)
    }
  }
  </script>
  
  <style lang="less">
  .cm-button {
    padding: 0 5px;
    border: 1px solid #aaa;
    border-radius: 40px;
    height: 30px;
  
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .cm-button:hover {
    background: #eee;
    cursor: pointer;
  }
  </style>