<template>
  <div class="container">
    <template v-if="loading">
      <Spin fix>
      <BaseLoading style="margin:0;margin-bottom:10px;height:20px" />
      加载中
    </Spin>
    </template> 
    <template  v-else>
      <div class="flex-wrap flex-between flow-header" style="height:70px;border-bottom:1px solid var(--border);">
      <div class="flex-wrap">
      <div class="icon-wrap">
      <BaseIcon :icon="flow.icon" :size="30" />
      </div>
      <div>
        <div class="title">{{flow.name}} <BaseVersion :value="flow.version" /></div>
        <div class="sub">{{flow.description}}</div>
      </div>
      </div>
      <div class="flex-wrap split10">
      
      </div>
    </div>
      <div class="flex-wrap flex-between" style="align-items:stretch;height:calc(100% - 140px);">
        <div style="flex-grow:1;">
    <div style="overflow-y:auto;padding:5px;background:var(--border);border:1px solid var(--border);height:calc(100%);position:relative;">
      
    <UgoForm ref="form" :form="form" :acl="acl" style="background:none;height:100%;"
    :style="`width:${form?.option?.width || 900}px;margin:0 auto;`" v-model="formData" />
  </div>
    
        </div>
        <div style="margin-left:10px;position:relative;width:400px;border-left:1px solid var(--border);padding-left:10px;flex-shrink:0;height:100%;position:relative;">
          <BaseBoardSubTitle name="流程图"></BaseBoardSubTitle>
          <FlowGraph style="width:100%;height:200px;position:relative;" :data="graphData" />
          
          <BaseBoardSubTitle name="流程配置"></BaseBoardSubTitle>
          <div style="overflow-y:auto;height:calc(100% - 270px);background:var(--bg3);border-radius:5px;border:1px solid var(--border);padding:15px;"  v-if="version && version.nodes">
          <Steps :current="0" direction="vertical">
          <template v-for="l in nodes">
            <Step>
              <div  slot="content">
            <template v-for="n in l">
             
               
              <NodeCreate v-model="nodeData[n.id]" :node="n" :db="formData" :key="n.id" />
         
            </template>
          </div>
          </Step>
          
          </template>
        </Steps>
      </div>
        </div>

        
      </div>
      <div class="flex-wrap flex-right" style="height:70px;border-top:1px solid var(--border);">
    <Button long type="primary" size="large" style="width:200px" @click="handleSubmit">提交</Button>
    <Button long size="large" style="width:120px;margin-left:20px;" @click="handleSaveAsDraft">保存草稿</Button>
     <Button long size="large" style="width:120px;margin-left:20px;" @click="handleCancel">取消</Button>
    </div>
  </template>
  
  </div>
</template>

<script>
import UgoForm from  '../form/index'
import FlowGraph from './graph.vue'

import NodeCreate from '@/components/flow/node/create'
export default {
  data(){
    return {
      loading:false,
      flowData:{
        form:{}
      },
      formData:{},
      nodeData:{},
      form:{},
      acl:{},
      graphData:{},
      instData:{}
    }
  }, 
  watch:{
    flow:{
      handler(v){
        this.getStartView()
      },
      immediate:true,
      deep:true
    }
  },
  components:{UgoForm,FlowGraph,NodeCreate},
  mounted(){
  },
  computed:{
    version(){
      return this.flow.activeVersion || {}
    },
    nodes(){
      if(!this.version || !this.version.nodes || !this.version.actions)
        return []
      let cur = this.version.nodes.find(n=>n?.graph?.type=='start_node')
      let nodes = [[cur]]
      let next_actions = this.version.actions.filter(v=>v.from == cur.id)
      let levelNodes = []
      while(next_actions && next_actions.length > 0){
        levelNodes = []
        next_actions.forEach(a=>{
          if(a.to){
            let n = this.version.nodes.find(n=>n.id == a.to)
            if(n){
              levelNodes.push(n)
            }
          }
        })
        
        nodes.push(levelNodes)
        next_actions = this.version.actions.filter(v=>levelNodes.findIndex(t=>t.id == v.from) !==-1)
      }
      return nodes
    }
  },
  props:{
    'flow':{
      type:Object
    },
  },  
  methods:{
    ParseFlow(flow){
      return {
        id:flow.id,
        flow_id:flow.flow_id,
        version:flow.version,
        note:flow.note,
        created_by:flow.created_by,
        created_at:flow.created_at,
        nodes:flow.nodes.map(n=>{
          return {
            id:n.id,
            ...n.graph,
            properties:{
              users:n.users,
              ...n.option
            }
          }
        }),
        edges:flow.actions.map(e=>{
          return {
            id:e.id,
            ...e.graph,
            sourceNodeId:e.from,
            targetNodeId:e.to,
            properties:{
              

            }
          }
        })
      }
    },
    getStartView(){
      if(this.version && Array.isArray(this.version.nodes)){
        let node = this.version.nodes.find(n=>n?.graph?.type=='start_node')
        if(node?.option?.view_id){
          this.getForm(node.option.view_id)
          this.acl = node.option.acl
        }

        this.graphData = this.ParseFlow(this.version)
      }
    },  
    getForm(id){
      this.$api.get(`forms/${id}`).then(res=>{
        this.form = res.data.data

      })
    }, 
    handleSubmit(){
      let item = this.$refs.form.submit()
      console.log(item)
      if(!item){
        this.Error("表单未填写完整，请检查")
        return
      }
      for(let x in this.nodeData){
        if(!this.nodeData[x] || !this.nodeData[x].executors){
          this.Error("流程节点负责人配置缺失，请检查")
          return
        }
      }
      console.log('node:',this.nodeData)
      return
      this.$refs.form.submit(item=>{
        this.$api.post('/oa/flow-instances',item).then(res=>{

          this.$emit('cancel')
        }).catch(e=>{
          this.$emit('error',e)
        })
      })
    },
    handleSave(){
      let item = this.$refs.form.submit()
      console.log(item)
    },
    handleCancel(){
      this.$emit('cancel')
    },
    handleSaveAsDraft(){
      
    }
  }
}
</script>
<style lang="less">
.flow-header{
  padding:10px;
  background:var(--bg2);
  color:var(--text2);
  .icon-wrap{
    width:50px;
    height:50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background:var(--primary);
    color:var(--hover-text);
    border-radius: 10px;
    flex-shrink: 0;
    margin-right: 10px;
  }
  .title{
    font-size:16px;
    
  }
  .sub{
    font-size:12px;
    color:var(--subtext2);
  }
}
</style>