<template>
   <Carousel
      class="container"
      autoplay
      :autoplay-speed="15000"
      radius-dot
      
      loop
    >
      <CarouselItem
        v-for="p in carouselItems"
        :key='p'
      >
        <div
          class="carousel-item"
          :style="`background:no-repeat url(${p})`"
        ></div>
      </CarouselItem>
    </Carousel>
</template>

<script>
export default{
  data(){
      return {
        defaultItems:[
        'https://0.rc.xiniu.com/g2/M00/87/9F/CgAGfFrf94yATZxAAAWcnUu8LJs225.jpg',
        'https://0.rc.xiniu.com/g2/M00/87/A0/CgAGfFrf-W-AYeWwAAVOUrBNxC8882.jpg',
        'https://0.rc.xiniu.com/g2/M00/87/9F/CgAGfFrf94yAH2dYAAWRHTBYfnY576.jpg',
      ]
      }
  },
  props:['items'],
  computed:{
    carouselItems(){
      return this.items || this.defaultItems
    }
  }
}
</script>

<style lang="less" scoped>
.carousel-item {
  background-size: 100% 100% !important;
  filter:brightness(0.5);
  width: 100%;
  height: 680px;
  display: flex;
  align-items: flex-start;
  padding: 20px;
  font-size: 20px;
  color: #fff;
  text-shadow: 1px 1px 1px #aaa;
}

</style>