<template>
  <div class="container" style="padding:10px;background:#33333311;">
    <div class="tool-box">
      <Input search style="margin-left:10px;width:220px" v-model="filter.searchText" placeholder="请输入合同名称查询" clearable />
     
    </div>
    <div style="height:calc(100% - 40px);position:relative;margin-top:10px;">
      <BaseTable :columns="columns" :loading="loading" :data="filteredData" 
      :option="{summary:true}" 
      
      @event="handleTableEvent" />
    </div>

    <Modal 
      v-model="showEditor" 
      draggable 
      :class-name="className" 
      ref="modal" 
      :title="model.id?'编辑项目':'新建项目'" :width="800" footer-hide>
      <FormCreateContract 
        v-model="model"
        :submitting="submitting" 
        @on-submit="handleSubmit" 
        @on-error="handleModelError" 
        @on-cancel="showEditor=false" />
     
    </Modal>
  </div>
</template>

<script>

import moment from 'moment'
import UTIL from '@/utils'
import FormCreateContract from '@/components/forms/CreateContract'
import {cloneDeep} from 'lodash'
import CMR from '../render'
  export default {
    components:{FormCreateContract},
    routerOption:{
      as_default:true
    },
    data(){
      return {
        items:[],
        loading:false,
        deleting:false,
        submitting:false,
        showEditor:false,
        className:"",
        filter:{
          searchText:""
        },
        business_types:['全过程咨询','项目管理','房建监理','市政监理','造价咨询','BIM咨询','招标代理','对外合作','其他'],
        business_type_colors:['orange','#369','green','darkred','purple','#3af','grown','pink','#333'],
        contract_types:['独立合同','主合同','分解合同','附加合同'],
        contract_type_colors:['#369','green','orange'],
        model:{}
      }
    },
    mounted(){
      setTimeout(()=>{
        
      this.getData()
      },500)
    },
    methods:{
      getData(){
        this.loading =  true
        this.$api.get("contracts?sys=1").then(res=>{
          let items = res.data.data
          items.forEach(v=>{
            v.adjust_amount = v.adjust_amount || 0
            v.full_amount = v.amount + v.adjust_amount
            v.children = items.filter(v=>v.parent_id == v.id)
          })
          this.items = items
        }).finally(()=>{
          this.loading= false
        })
      },
      handleTableEvent(e){
        
      },
      handleOpen({id:e}){
        let project = this.items.find(v=>v.id == e)
        this.$store.commit("session/push_project_cm",{
          id:e,
          name:project.name
        })
        this.RouteTo('/core/projects/'+e+'/cm')
      },
      handleCreate(){
        this.model = {}
        this.showEditor = true
      },
      handleRestore(id){
        this.Confirm("恢复此项目?",()=>{
            this.deleting = id
            this.$api.patch(`cm/projects/${id}`,{sys_flag:0}).then(()=>{
              let index =this.items.findIndex(v=>v.id == id)
              this.items.splice(index,1)
              this.$Notice.success({title:"操作成功",content:"项目已恢复"})
            }).finally(()=>{
              this.deleting = false
            })
        })
      },
      handleDelete(id){
        this.Confirm("将删除此项目相关的所有信息，是否继续?",()=>{
          this.deleting = id
          this.$api.delete(`cm/projects/${id}`).then(()=>{
            let index =this.items.findIndex(v=>v.id == id)
            this.items.splice(index,1)
            this.$Notice.success({title:"操作成功",content:"项目已删除"})
          }).finally(()=>{
            this.deleting = false
          })
        })
      },
      handleSubmit(model){
        if(this.model.id){
            let shrinked_model = UTIL.compare_patch_object(this.model,model)
            this.submitting = true
            this.$api.patch("cm/projects/"+this.model.id,shrinked_model).then(res=>{
            let udpateInfo = res.data.data
            let index = this.items.findIndex(v=>v.id == this.model.id)
            this.items.splice(index,1,Object.assign({},this.model,model,udpateInfo))
            this.showEditor = false
            this.$Notice.success({title:"操作成功",content:"项目已修改"})
          }).catch(e=>{
            this.Error(e)
          }).finally(()=>{
            this.submitting = false
          })
        }else{
           this.submitting = true
          this.$api.post("cm/projects",model).then(res=>{
            let udpateInfo = res.data.data
            this.items.splice(0,0,Object.assign({},model,udpateInfo))
            this.showEditor = false
            this.$Notice.success({title:"操作成功",content:"项目已创建"})
          }).catch(e=>{
            this.Error(e)
          }).finally(()=>{
            this.submitting = false
          })
        }
      },
      handleModelError(){
        this.className = 'animate__animated animate__headShake'
        setTimeout(()=>{
          this.className = ''
        },500)
      }
    },
    computed:{
      status(){
        return ['准备中','进行中','已竣工','已结束','已中止']
      },
      amount_regions(){
        return [{
          name:"50万元以下",
          check:v=>v < 500000
        },{
          name:"50万以上",
          check:v=>v >= 500000
        },{
          name:"100万以上",
          check:v=>v >= 1000000
        },{
          name:"200万以上",
          check:v=>v >= 2000000
        },{
          name:"500万以上",
          check:v=>v >= 5000000
        }]
      },
      filteredData(){
        return this.items.filter(v=>{
          let searchText = this.filter.searchText.trim()
          if(searchText && !v.name.includes(searchText)){
            return false
          }

          if(this.filter.business_type && v.business_type != this.filter.business_type)
            return false

          if(this.filter.amount_region != undefined && !this.amount_regions[this.filter.amount_region].check(v.full_amount))
            return false
          
          if(this.filter.status && v.status != this.filter.status)
            return false


          return true
        })
      },
      columns(){
        return [ {
        type: 'text',
        key: "code",
        title: "编号",
        width: 70,
        group: '项目',
        option: {

          align: "center",
        }
      }, {
        type: 'text',
        title: "业务类型",
        key: "business_type",
        group: '项目',
        width: 100,
        option: {

          align: "center",
        },
        render: (h, { row }) => {
          return h('div', { class: "flex-wrap flex-center", style: { height: "40px" } }, [h('div', {            style: {              width: "70px",
              borderRadius: "5px",
              textAlign: "center",
              background: this.business_type_colors[this.business_types.findIndex(v=>v == row.business_type)],
              color: "#fff"
            }          }, row.business_type)])
        }
      },{
        type:'text',
        title: "名称",
        tree:true,
        key: "name",
        minWidth: 200,
        render:CMR.renderName(this.handleOpen)
      },{
        type: 'time',
        title: "中标时间",
        key: "bid_at",
        width: 120,
        option:{
          type:"date"
        }
      },{
        type: 'time',
        title: "签约时间",
        key: "signed_at",
        width: 120,
        option:{
          type:"date"
        }
      }, {
        type: 'text',
        title: "拆分",
        key: "splited",
        width: 60,
        render:(h,{row})=>{
          return h("span",{style:{color:row.splited?'var(--primary)':''}},row.splited?'已拆分':'未拆分')
        }
      },{
        type: 'number',
        title: "签约金额",
        key: "amount",
        width: 120,
        group: '合约',
        option: {
          sumable: true,
          formatter: e => '¥ ' + UTIL.formatSalary(e),
          type: "fullAmount"
        },
      }, {
        type: 'number',
        title: "调整金额",
        key: "adjust_amount",
        width: 120,
        group: '合约',
        option: {
          sumable: true,
          formatter: e => '¥ ' + UTIL.formatSalary(e),
          type: "fullAmount"
        },
      }, {
        type: 'number',
        title: "总金额",
        key: "full_amount",
        width: 120,
        group: '合约',
        option: {
          sumable: true,
          formatter: e => '¥ ' + UTIL.formatSalary(e),
          type: "fullAmount"
        },
      },{
        type: 'text',
        title: "业主单位",
        key: "partA",
        width: 200,
      },{
					key: "created_at",
					title: "创建时间",
					group: "管理",
					width: 80,
					type: "time",
				},
				{
					key: "created_by",
					title: "操作人员",
					group: "管理",
					width: 120,
					type: "user",
					option: {
						getters: "session/users",
					},
				},{
        type: 'number',
        title: "操作",
        key: "partA",
        render:(h,{row})=>{
          let buttons = [
            h('Button',{props:{icon:"md-create",size:"small"},on:{click:()=>{
              this.handleRestore(row.id)
            }}},'恢复'),
            h('Button',{props:{icon:"md-trash",size:"small",loading:this.deleting == row.id},on:{click:()=>{
              this.handleDelete(row.id)
            }}},'删除')
          ]
          return h('div',{class:"flex-wrap split5 flex-center"},buttons)
        }
      },	]
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>