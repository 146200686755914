<template>
    <div style="position:relative;height:100%;">
     <div class="flex-wrap" style="width:100%;height:30px;font-weight:bold;">
       <BaseIcon icon="md-apps" style="margin-right:5px" />采购类合同  <div style="font-weight:normal;margin-left:10px;">2024 <BaseIcon icon="ios-arrow-down" /></div> </div>
       <div class="flex-wrap align-start" style="height:calc(100% - 30px);width:100%;flex-wrap:wrap;gap:5px 5px;align-content:flex-start;">
       <div  class="flex-wrap flex-center align-center" style="background:var(--bg3);padding:5px 10px;border-radius:3px;width:calc(30% - 5px);height:70px;">
          
          <div style="text-align:center">  
                  <span style="color:var(--text3);font-size:20px;font-weight:bold">24</span>
              <div style="font-size:12px;text-align:center">新签合同</div>
              </div>
  
           </div>
       
        <div  class="flex-wrap flex-end" style="background:var(--bg3);padding:5px 10px;border-radius:3px;width:calc(70%);height:70px;text-align:right;">
            <div>
            <div><span style="color:var(--text3);font-size:20px;font-weight:bold">32.6</span> <span style="font-size:12px">万元</span></div>
            <div style="font-size:12px;">合同额</div>
        </div>
         </div>

         <div class="flex-wrap flex-between" style="font-size:25px;background:var(--bg3);padding:5px 15px;border-radius:3px;width:calc(100%);overflow:hidden;height:70px;">
            <div>
                <span style="color:var(--text3);font-size:20px;font-weight:bold">7.7</span> <span style="font-size:12px">万元</span>
            <div style="font-size:12px;">履约中合同总额</div>
        </div>
        <div class="flex-wrap">
            <i-circle :percent="100" style="width:45px;height:45px;" 
            :trail-width="10" :stroke-width="8" stroke-color="var(--success)">
                <div style="font-size:12px;color:var(--success)">100%</div>
            </i-circle>
        </div>
         </div>

        

        
        <div  class="flex-wrap flex-center align-start" style="font-size:25px;background:var(--bg3);border-radius:3px;width:calc(100%);height:300px">
           <PieChart v-bind="chart1"  />
        </div>
       </div>

      </div>
    
 </template>
 
 <script>
    import PieChart from '@/components/charts/piecharts'
 export default {
    components:{PieChart},
     data(){
         return {
             chart1:{
                name:'采购合同 - 类型分布',
                data:[
                { value: 148, name: "办公用品" },
                { value: 335, name: "装修服务" },
                { value: 280, name: "信息化" },
                { value: 184, name: "食堂" },
                { value: 300, name: "其他" }
                ]
             },
             items:[{
                 name:'xxx全过程咨询合同',
                 node:"事业部审核",
                 state:"草稿"
             },{
                 name:"xxx全过程咨询合同",
                 state:"履约中"
             },{
                 name:"钉钉采购合同",
                 state:"付款中"
             }]
         }
     }
 }
 </script>
 
 <style>
 
 </style>