
<template>
  <div class="pd-embed-control flex-wrap" style="padding:0 10px;font-size:14px;" :style="`background:${colors[value || 0]}`">

    <span>{{states[value || 0]}}</span>
  </div>
</template>
<script>
  export default {
    props:['value','editable','option'],
    computed:{
      states(){
        return ['未分配','进行中','已完成','已中止']
      },
      colors(){
        return ['#ffffff22','#33669933','#66993333','#99333333']
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>