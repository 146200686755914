<template>
  <div class="container" style="background:var(--bg3);color:var(--text3);">
    <div style="padding:5px;border-bottom:1px solid var(--border);">最近访问</div>
    <div class="l-recent-filter">
      <Input v-model="searchText" placeholder="查询项目" search clearable />
    </div>
    <div style="height:calc(100% - 50px);overflow-y:auto;">
      <BaseEmpty v-if="receent_projects.length == 0"></BaseEmpty>
    <template v-for="p in projects">
      <div :key="p.id" class="l-project-item">
        <div style="margin-right:10px;">
        <div class="l-project-item-name">{{p.name}}</div>
        <div style="color:var(--subtext1)">{{p.typeText}}</div>
        </div>
        <div style="background:var(--success);padding:8px;flex-shrink:0;border-radius:50%;"></div>
      </div>
    </template>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        searchText:"",
        receent_projects:[]
      }
    },
    computed:{
      projects(){
        let searchText = this.searchText.trim()
        if(searchText){
          return this.$store.getters['cm/projects'].filter(v=>v.name && v.name.includes(searchText))
        }else{
          return this.receent_projects
        }
      }
    }
  }
</script>

<style lang="less">
.l-project-item{
  height:80px;
  border-bottom:1px solid var(--border);
  background:var(--bg3);
  color:var(--text3);
  background-blend-mode:multiply;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    padding:5px 10px;

  .l-project-item-name{
    font-size:16px;
    text-overflow: ellipsis;
    max-height:50px;
    max-width: 100%;
    overflow: hidden;
  }

  .l-project-item-type{
  }
}

.l-project-item:hover{
  filter:brightness(1.2);
  cursor: pointer;
}

.l-project-item:active{
  position: relative;
  bottom:-1px;
  right:-1px;
}


.l-recent-filter{
  border-bottom:1px solid var(--border);
  .ivu-input{
    border-radius: 0;
    border:none;
    background:none;
    color:var(--text3);
  }

  .ivu-input:focus{
    outline:none;
    box-shadow:none;
  }
}
</style>