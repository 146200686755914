<template>
    <div class="container l-downloads " style="border:1px solid var(--border)">
      <div class="m-light flex-wrap flex-between" style="position:absolute;top:0;left:0;right:0;font-size:14px;padding:5px;background:var(--bg2);color:var(--text2);text-align:center;border-bottom:1px solid var(--border)">
        <BaseIcon icon="md-archive" size="20" color="var(--text3)" style=";padding:3px;background:var(--border);border-radius:5px;"/> 
      <BaseIcon icon="md-archive" size="50" color="var(--border)" style="position:absolute;left:10px;z-index:100;top:-3px;" /> 
        常用软件 <BaseIcon icon="ios-arrow-forward" size="20" color="var(--subtext3)" @click="RouteTo('/core/download')" style="cursor:pointer;" /></div>
        <Input search style="width:200px;margin:0 2px;margin-bottom:10px;" placeholder="输入关键字查找"  />
    <div class="flex-wrap align-start">
       <template v-for="l in links">
        <div :key="l.name" class="l-link-item flex-wrap flex-col align-start" @click="handleClick(l)">
          <div class="flex-wrap">
           <img style="width:18px;height:18px;margin-right:5px;" :src="l.avatar" v-if="l.avatar" />
           <BaseIcon v-else :icon="l.icon || 'link'" style="margin-right:5px;" :size="18" :color="l.color || 'var(--primary)'" />
           <span>{{l.name}}</span>
          </div>
          <div style="color:var(--subtext3);margin-top:5px;">
            {{ l.discription }}
          </div>
        </div>
       </template>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    computed:{
      session(){
  
        return this.$store.getters['session/session']
      },
      links(){
        return [{
          id:1,
          name:"Google Chrome 浏览器 v13.0.4",
          avatar:'https://nbgz-pmis-1257839135.cos.ap-shanghai.myqcloud.com/system/chrome.jpg',
          url:'http://zzlatm.gicp.net:10000/public/upload/20240614/20240614_666bc7a1cafca.rar',
          icon:'nbgz',
          color:"#1E9FFF"
        },{ id:2,
          name:"打印机驱动MX503",
          avatar:'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/upload/print.jpg',
          url:'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/upload/MX-Mono3_PCL_PS_1302a_ChineseS_Win8Server2012.rar'
        },{
          id:11,
          name:"15楼新打印机驱动",
          url:"http://zzlatm.gicp.net:10000/public/upload/20230222/20230222_63f57a1aaeaa1.zip",
          avatar:'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/upload/print.jpg',
        },{
          id:3,
          name:"WinRAR",
          avatar:"https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/upload/winrar.jpg",
          url:"http://zzlatm.gicp.net:10000/public/upload/20210622/20210622_60d1957244fcd.exe"
        },{
          id:4,
          name:"everything",
          discription:"文件全局搜索工具，强烈推荐",
          avatar:"https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/download/60b8779fed7d0d08fbc62275e58916d.png",
          url:"https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/download/everything.zip"
        },{
          id:5,
          name:"registryFinder",
          discription:"注册表快速查找工具",
          avatar:"https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/download/aaf6156c3bb00bc15dca5d01a15d7cd.png",
          url:"https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/download/registryFinder.rar"
        },{
          id:4,
          name:"浩辰CAD2024",
          avatar:"https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240614122250.png",
          url:"https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/download/%E6%B5%A9%E8%BE%B0CAD2024Sp2.rar"
        }]
      }
    },
    methods:{
      handleClick(e){
        if(e.self){
          
          this.$store.commit('session/push',{icon:'md-download',size:20,base_url:e.url,name:e.name})
        
        }
        this.RouteTo(e.url,e.self!=true)
      }
    }
  }
  </script>
  
  <style lang="less">
  .l-downloads{
    background:var(--bg2);
    padding:5px 5px;
    border-radius:10px;
    flex-wrap:wrap;
    align-content: flex-start;
    align-items: stretch;
    padding-top:50px;
    overflow: hidden;
    position: relative;
  
    .l-link-item{
      min-width:140px;
      padding:0 10px;
      border-radius: 5px;
      height:60px;
      flex-shrink: 0;
      flex-grow:1;
      display: flex;
      justify-content: center;
      
      border:2px solid var(--border);
      background:var(--bg3);
      margin:2px;
      span{
        white-space: nowrap;
      }
    }
  
    .l-link-item:hover{
      border-color:var(--primary);
      color:var(--primary);
      cursor: pointer;
    }
  }
  </style>