<script>
import Draggable from 'vuedraggable'
import UTIL from '@/utils'
export default {
    components:{Draggable},
    methods:{
        /** _traversal tree
         *  callback(node,traversal(subs))
         */
        _traversal(treeArray,callback,depth=1){
            if(Array.isArray(treeArray)){
                let doms = []
                treeArray.forEach((v,i)=>{
                    let domItem = callback(v, this._traversal(v[this.subname],callback,depth+1),i)
                    if(domItem)
                        doms.push(domItem)
                })

                return doms
            }
        },
        
        makeTreeDom(h,treeArray){

            let that = this
            return this._traversal(treeArray,(node,doms)=>{
                let iconClass = 'l-tree-mark'
                if(node.fold)
                    iconClass +=' l-tree-mark-fold'
                let domIcon = h('Icon',{class:iconClass,props:{type:node.fold?'md-add':'md-remove',size:10},on:{
                    click:e=>{
                      //if(!this.manu)
                        node.fold = !node.fold
                      this.$emit('event',{type:node.fold?'fold':'unfold',data:node})
                        that.$forceUpdate()
                    }
                }})
                if(!node[this.subname] || node[this.subname].length == 0)
                    domIcon = h('span',{class:'l-tree-mark l-tree-mark-leaf'})
                    
                let domName = h('span',{class:'l-node-text'},[that.nodeRenderer?that.nodeRenderer(h,node,this.selected == node):node.name])
                let nodeClass = 'l-node'
                if(that.selected && that.selected[this.idname] == node[this.idname]){
                    nodeClass += ' l-node-selected'
                }

                let domTool = null
                if(that.selected == node && this.nodeTool){
                    domTool = h('span',{class:'l-node-tool',style:{marginLeft:'0.5rem'}},[
                        h('Button',{props:{icon:'md-add',size:'small'},on:{click:e=>{
                            that.$emit('on-node-add',that.selected[this.idname])
                        }}}),
                        h('Button',{props:{icon:'md-create',size:'small'},on:{click:e=>{
                            that.$emit('on-node-update',that.selected[this.idname])
                        }}}),
                        h('Button',{props:{icon:'md-remove',size:'small'},on:{click:e=>{
                            that.$emit('on-node-delete',that.selected[this.idname])
                        }}})
                    ])
                }

                let domNodeAnchor = h('div',{class:'l-node-anchor'})

                let domCount = h('span',{style:{marginLeft:'5px'}},`(${node.count || 0})`)

                let domNode = h('div',{attrs:{id:'node'+node[this.idname]},class:nodeClass},[h('div',{class:'l-node-inner',on:{
                    click:e=>{
                        
                        that.selected = node
                        that.$emit('event',{type:'select',data:node})
                        that.$forceUpdate()
                    }
                }},domTool?[domIcon,domName,domTool]:node.showCount?[domIcon,domName,domCount]:[domIcon,domName])])

                
                let domFinal = domNode
                if(!node.fold && doms && doms.length){
                    let subsNode = h('Draggable',{
                        class:'l-node-sub-list',
                        props:{
                            value:node[that.subname],
                            draggable:".l-node-anchor",
                            group:'treenodes',
                            animation:200,
                            disabled:that.draggable,
                        

                        },
                        on:{
                            input:e=>{
                                node[that.subname] = e
                                that.$emit('event',{
                                    type:"drag",
                                    data:node[that.subname]
                                })
                            }
                        }
                    },doms)
                    domFinal = [domNode,subsNode]
                }
                
                return domFinal
            })
        },

        setClearable(enabled){
            this.clearable = enabled
        },

        setDraggable(enabled){
            this.draggable = enabled
            this.$forceUpdate()
        },

        /** interface */
        foldAll(){
           this._traversal(this.data,v=>{
             // if(!this.manu) 
                v.fold = true
              this.$emit('event',{type:'fold',data:v})
            })
           this.$forceUpdate()
        },
        unfoldAll(){
            this._traversal(this.data,v=>{
              //if(!this.manu) 
                v.fold = false
              this.$emit('event',{type:'unfold',data:v})
            })
             this.$forceUpdate()
        },
        select(node){
           let n = this.all_nodes.find(n=>n[this.idname] == node[this.idname])
           
          this.$nextTick(e=>{
            if(n){
                
                    this.$set(this,'selected',n)
                    }
          })
          return n
        },
        handleClickOutside(e){
            if(e.target == this.$el && this.clearable){
                this.select(null)
                this.$emit('event',{type:'select',data:null})
            }
        },
    },
    mounted(){
        this.$nextTick(()=>{
            this.$el.addEventListener('click',this.handleClickOutside)
        })
    },
    beforeDestroy(){
        this.$el.removeEventListener('click',this.handleClickOutside)
    },
    render(h){
        return h('Draggable',{
            class:this.classname,
            style:{overflow:"hidden",background:"var(--bg3)",padding:"10px"},
            props:{
                            value:this.nodes,
                               animation:200,
                            disabled:!this.draggable,
                            group:'treenodes'
                        },
                        on:{
                            input:e=>{
                                this.nodes = e
                            },
                        }},this.makeTreeDom(h,this.nodes))
    },
    watch:{
        data:{
            handler(v){
                this.$forceUpdate()
            },
            immediate:true,
            deep:true
        }
    },
    computed:{
        nodes(){
          return this.manu?this.data:UTIL.makeTree(this.data,{KEY:this.idname,LIST_KEY:this.subname})
        },
        all_nodes(){
            return this.manu?UTIL.plat(this.data,{LIST_KEY:this.subname || 'fields'}):this.data
        }
    },
    props:{
        
        data:{
            type:Array
        },
        classname:{
            type:String
        },
        idname:{
            type:String,
            default:"id"
        },
        subname:{
            type:String,
            default:"subs"
        },
        filter:{
            type:Object
        },
        nodeTool:{
            type:Boolean,
            default:false
        },
        nodeRenderer:{
            type:Function
        },
        manu:{
          type:Boolean
        }
    },
    data(){
        return {
            selected:null,
            draggable:true,
            clearable:true
        }
    }
}
</script>

<style lang='less' scoped>
.l-node{
    color:var(--text3);
    cursor:pointer;
    font-size:13px;
    position: relative;
    display: flex;
    align-items: center;
    background:var(--bg3);
    overflow:visible;
    z-index:2;
    margin-bottom:5px;
    
    .l-node-inner{
        width:100%;
            position: relative;
            z-index:2;
            background:var(--bg3);

            color:var(--text3);
            cursor:pointer;
            font-size:13px;
            position: relative;
            display: flex;
            align-items: center;
            z-index:2;

            
    .l-node-text{
        padding:0 2px;
        margin-left:5px;
        width:100%;
    }

    .l-node-tool{
        >*{
            margin-left:0.25rem;
        }

        .ivu-btn{
            border-radius: 0;
        }
    }
        }


}


.l-node-selected{
    
    .l-node-inner{
        background:var(--primary);
        color:#fff;
    }
}

.l-node-sub-list{
    padding-left:20px;
    color:var(--primary);
    position: relative;
    z-index:1;
    
}

.l-node-sub-list::before{
     content:' ';
        position: absolute;
        top:-11px;
        left:-12px;
        width:100%;
        border-left:1px dashed var(--border);
        height:100%;
        z-index:1;
        pointer-events: none;
}

.l-node-sub-list:last-child::before{
    content:none;
}

.l-node-avatar{
    font-size:12px;
    color:var(--primary);
}


.l-node-sub-list{
   position: relative;
   z-index:0;
   
    .l-node{
        position: relative;
        .l-node-inner{
            z-index:2;
        }
        
    }

    .l-node:before{
        content:' ';
        position: absolute;
        left:-12px;
        top:12px;
        width:80%;
        height:26px;
        z-index:0;
        border-top:1px dashed var(--border);
        pointer-events: none;
        
    }

    .l-node:after{
       content:' ';
        position: absolute;
        top:-16px;
        left:-12px;
        width:100%;
        border-left:1px dashed var(--border);
        height:calc(100% + 4px);
        z-index:0;
        pointer-events: none;
    }

    
}

.l-tree-mark{
    transition:all 0.3s;
    cursor: pointer;
    color:var(--text3);
    width:16px;
    height:16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border:1px dashed var(--border);
    background:#ffffffaa;
    
    margin-left:2px;
}

.l-tree-mark-leaf{
    border-color:var(--border);
    width:8px;
    height:8px;
    margin:5px;
    background:#ffffffaa;
    margin-right:0px;
}

.l-tree-mark-fold{
    transform: rotateZ(-90deg);
    transition:all 0.3s;
     display: flex;
    align-items: center;
    justify-content: center;
    background:var(--bg3);
}


.l-node-anchor{
    width:5px;
    height:5px;
    background:var(--bg1);
    margin-left:5px;
    margin-right:5px;
    margin-bottom:3px;
}
</style>