<template>
  <div class="flex-wrap">
    <template v-if="search">
      <Input search placeholder="输入关键字查询" v-model="filter.search" @on-change="handleChange" />
      <template v-for="c in controls">
        <component :is="c.control" v-model="filter[c.key]" :option="c.option" :key="'fc_'+c.key" style="margin-left:10px"></component>
      </template>
    </template>
    <Button icon="md-trash" style="margin-left:10px" @click="filter={};handleSearch()">清除筛选条件</Button>
    <Button v-if="!local" type="primary" icon="search" style="margin-left:10px" @click="handleSearch">搜索</Button>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        filter:{}
      }
    },
    props:{
      value:{
        type:Object,
        default:()=>({})
      },
      option:{
        type:Object,
        default:()=>({
          search:true,
          business_type:{
            control:"Select",
            placeholder:"服务类型",
            option:{
              options:['1','2','3']
            }
          }
        })
      },
      local:{
        type:Boolean
      }
    },
    mounted(){
      if(this.local){
        this.filter = this.value
      }
    },
    computed:{
      isEmpty(){
        return !this.filter || Object.keys(this.filter).filter(key=>{
          if(key=='search')
            return this.filter[key]
          else 
            return filter[ley]!==undefined
        }).length == 0
      },
      search(){
        return this.option && this.option.search
      },
      controls(){
        let controls = []
        for(let key in this.option){
          if(key !== 'search' && typeof this.option[key] == 'object'){
            controls.push({key,...this.option[key]})
          }
        }
        return controls
      },
      queryString(){
        let filterStr = Object.keys(this.filter).filter(key=>{
          if(key=='search')
            return this.filter[key]
          else 
            return filter[ley]!==undefined
        }).map(key=>`${key}=${this.filter[key]}`).join('&')
        return filterStr
      }
    },
    methods:{
      handleChange(){
        if(this.local){
          this.$emit('input',filter)
        }
      },
      handleSearch(){
        this.$emit('on-search',this.queryString)
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>