<template>
  <Container>
    <div class="flex-wrap flex-between" style="padding:0 10px">
    <Toolbar 
      :items="tools"
      v-model="filter"
      :counts="counts"
      @on-event="handleEvent"
    >
      
    </Toolbar>
    <div class="flex-wrap">
    <Button style="margin-right:5px" icon="md-add" type="primary" @click="showCreateForm">新增证书类型</Button>
    <Button style="margin-left:5px" icon="md-download" @click="exportExcel" /> 
    </div>
  </div>
  
    
    <div class="table-wrap" style="flex-grow:1;position;relative">
      <BaseTable 
        ref="table"
        :columns="columns"
        :data="items"
        :page="page"
        :page-size="pagesize"
        @event="handleEvent"
        :loading="loading"
        
        border
        :count="total"
      ></BaseTable>
    </div>

    <Editor
      ref="editor" 
      v-model="showEditor"
      :data="current"
      :loading="loadingEditor"
      
      model="OA_JobTitleType"
      @on-event="handleEvent"
    />

  </Container>
</template>

<script>
import Container from '@/components/app/container'
import Toolbar from '@/components/app/toolbar'
import Editor from '@/components/model/editor'
import moment from 'moment'
import {cloneDeep} from 'lodash'
export default {
  routerOption: {
    as_default: true
  },
  components:{Container,Toolbar,Editor},
  data(){
    return {
      filter:{

      },
      showEditor:false,
      loadingEditor:false,
      showFlowEditor:false,
      flowFormData:{},
      items:[],
      loading:false,
      current:{},
      formData:{},
      page:1,
      pagesize:20,
      total:10,
      counts:{
        
      },
      order:{
        key:'created_by',
        order:'desc'
      },
      deps:[]
    }
  },
  methods:{
    showCreateForm(){
      this.current = {}
      this.formData = {}
      this.showEditor = true
    },
    Edit(item){
      this.loadingEditor = true
      this.showEditor = true
      this.$api.get(`oa/jobtitle-types/${item.id}?edit=1`).then(res=>{
        this.current = res.data.data
        this.loadingEditor = false
        this.formData = cloneDeep(this.current)
        this.showEditor = true
      }).catch(e=>{
        this.loadingEditor = false
        this.loadingEditorError = true
      })
     
    },
    exportExcel(){
      this.$refs.table.exportExcel()
    },
    getStatusCounts(){
      this.$api.getCounts("/oa/jobtitle-types",{
        count_by:'level'
      }).then(res=>{
        this.counts = Object.assign(this.counts,res.data.data)
      })
    },
    getData(){
      this.loading = true
      this.$api.afterLogin(()=>{
        this.$api.getPaged(
          "/oa/jobtitle-types",{
          page:this.page,
          pagesize:this.pagesize,
          order:this.order,
          filter:this.filter,
      }).then(res=>{
        let {count,list} = res.data.data
        this.items = list
        this.total = count
        this.loading = false
      })

      this.$api.get("/deps?q=subject").then(res=>{
        this.deps = res.data.data
      })
      })
      
    },
    createItem(model){
      this.$api.post('/oa/jobtitle-types',model).then(res=>{
        this.$Notice.success({title:"创建成功"})
        this.showEditor = false
        this.getData()
      }).finally(()=>{
        this.$refs.editor.stopLoading()
      })
    },
    updateItem(model){
      let id =  this.current.id
      this.$api.patch(`/oa/jobtitle-types/${id}`,model).then(res=>{
        this.$Notice.success({title:"修改成功"})
        this.showEditor = false
        this.getData()
      }).finally(()=>{
        this.$refs.editor.stopLoading()
      })
    },
    handleDelete(item){
      this.Confirm(`即将删除此项目<span style="color:var(--error)">${item.type}</span>，是否继续?`,()=>{
        this.$api.delete("/oa/jobtitle-types/"+item.id).then(res=>{
          this.$Notice.success({title:"删除成功"})
          this.getData()
        })
      })
    },
    createFlow(task){
      this.flowFormData = {
        target_id:task.id,
        
      }
      this.showFlowEditor = true
    },
    handleEvent({type,key,value,data}){
      const eventMap = {
        add:this.showCreateForm,
        search:this.getData,
        create:this.createItem,
        refresh:()=>{
          this.getStatusCounts()
          this.getData()

        },
        update:this.updateItem,
        'tab-change':this.getData,
        'page-change':()=>{
          this.page = data
          this.getData()
        },
        'page-size-change':()=>{
           this.pagesize = data
          this.getData()
        },
        'sort':()=>{
           if(!data || !data.key)
            this.order = {
              key:'created_at',
              order:'desc'
            }
          else
            this.order = data
          this.getData()
        },
        'clearFilter':()=>{
          this.filter={status:this.filter.status}
          this.getData()
        }
      }


      let eventHandler = eventMap[type]
      if(eventHandler){
        eventHandler(value)
      }
    },
  },
  computed:{
    tools(){
      return [{
        key:'search',
        placeholder:"输入人员/证书名称",
        type:"search",
        width:300
      },{
        key:'clearFilter',
        type:"clear-button"
      },{
        key:'refresh',
        icon:'md-refresh',
        type:"icon-button"
      },]
    },
    columns(){
      var that = this
      return [
          {type:'index',width:80,title:"序号"},
          
          {key:'name',minWidth:300,title:"证书名称",sortable:"custom",
            render:(h,{row})=>{

              let tag = h('img',{attrs:{
                 src: "https://nbgz-pmis-1257839135.cos.ap-shanghai.myqcloud.com/beauty/new.gif"
              },style:'width:30px;height:15px;margin-left:10px;'})

              if(moment(row.created_by).isBefore(moment().subtract(3,'days'))){
                  tag = ''
              }

              let name = h('div',{style:"margin-left:10px;white-space:nowrap;text-overflow:ellipsis;width:calc(100% - 30px;);overflow:hidden;"},row.name)
              return h('div',{
                class:"flex-wrap",
                style:{
                  color:"var(--primary)",
                  fontWeight:'bold',
                  textAlign:"left"
                },
              },[name,tag])
            }
          },
          {
              key:'shortname',
              title:"证书简称",
              align:'center',
              width:120,
          },
          {
              key:'type',
              title:"证书类别",
              align:'center',
              width:120,
          },
          {
            key:'level',width:100,title:"证书级别",sortable:false,
            
          },
          {
            key:'subjects',
            width:200,title:"涉及专业",sortable:false,
            render:(h,{row})=>{
              if(Array.isArray(row.subjects) && row.subjects.length > 0)
                return h('span',row.subjects.join(','))
              else
                return h('span','-')
            }
          },
          {
            
            key:'link',width:120,title:"相关链接",sortable:false,
            render:(h,{row})=>{
              if(row.link){
                return h('a',{attrs:{href:row.link,target:'_blank'}},'点击访问')
              }else{
                return h('span','-')
              }
            }
          },
          {key:"created_by",type:'user',width:100,title:"录入人",sortable:"custom"},
          {key:"created_at",width:100,title:"录入时间",sortable:"custom",
            render:(h,{row})=>{

              return h('span',moment(row.created_at).fromNow())
          },},
          {key:"tools",minWidth:140,title:"操作",sortable:false,
            render:(h,{row})=>{
              let buttons = [
                h('Button',{props:{size:"small"},on:{click:()=>{that.Edit(row)}}},"修改"),
                h('Button',{props:{size:"small"},on:{click:()=>{that.handleDelete(row)}}},"删除")
              ]
              return h('div',{class:"flex"},[buttons])
            }
          },
        ]
    }
  },
  mounted(){
    this.$api.afterLogin(()=>{
      this.getData()
      this.getStatusCounts()
      this.$nextTick(()=>{
        if (this.$refs && this.$refs.table && this.$refs.table.calcTableHeight)
          this.$refs.table.calcTableHeight()
      })
    })
   
  },
}
</script>

<style lange="less">
.filter-box{
  height:50px;
}

.table-wrap{
  height:calc(100% - 70px);
  position: relative;
}

</style>