<template>
  <App>
    <AppMask class="flex-wrap flex-center" style="background:var(--bg2);border-left:1px solid var(--border);overflow:hidden;z-index:1000;color:var(--subtext2);transition:all 0.3s ease-out" v-if="loading" :style="ready?'':'top:120%;'">
      <div class="flex-col">
      <h2 style="font-size:40px">文章发布</h2>
      <div class="flex-wrap" style="margin-top:10px">
      <BaseIcon icon="nbgz" color="var(--subtext2)" size="40" />
      <BaseLoading style="margin:0" />
      <BaseIcon icon="md-document" color="var(--subtext2)" size="40" />
      </div>
      
      </div>
    </AppMask>
    <AppMask class="flex-wrap flex-center" style="background:var(--bg1);border-left:1px solid var(--border);color:#fff;transition:all 0.3s ease-out;bottom:50%;" v-if="loading" :style="ready?'':'bottom:120%'">
      <div>
      </div>
    </AppMask>
    <AppBar class="flex-between">
      <AppTitle name="文章发布" icon="md-bookmarks" />
      <div class="flex-wrap" style="flex-shrink:0;"> 
        <ButtonGroup style="margin-right:20px;flex-shrink:0" size="small"> 
            <Button :type="mode=='all'?'primary':''" @click="mode='all';getData()">全部文章</Button>
            <Button :type="mode=='mine'?'primary':''" @click="mode='mine';getData()">我发布的({{count.mine || 0}})</Button>
            <Button :type="mode=='draft'?'primary':''" @click="mode='draft';getData()">我的草稿({{count.draft || 0}})</Button>
          </ButtonGroup>
          <!-- <Input search placeholder="全局搜索" /> -->
        </div>
    </AppBar>
     <AppLeft style="top:42px;border-right:1px solid var(--border)" :collapse="!showLeft">
      <BaseTree ref="tree" :data="menus" manu @event="handleTreeEvent" />
      </AppLeft>
    <AppClient :collapse-left="showLeft">
      <div class="flex-wrap flex-between" style="padding:10px;height:30px;border-bottom:1px solid var(--border);">
        <div class="flex-wrap"><BaseIcon icon="md-list" style="margin-right:5px" /> {{selected.name}}</div>
      
      <div class="flex-wrap">
        专栏负责人 <BaseNameBoard style="margin:0 10px" />
      </div>
      </div>
      <div class="flex-wrap flex-between" style="height:80px;background:var(--bg3);border-bottom:1px solid var(--border)">
        <div class="flex-wrap flex-center hover-lighter" @click="handleCreate" style="background:var(--primary);height:60px;margin:10px;width:120px;border-radius:10px;border:1px solid;font-weight:bold;box-shadow:1px 1px 3px 1px var(--border);font-size:16px;color:var(--hover-text)"><BaseIcon icon="md-add" :size="25" style="margin-right:10px" />写文章</div>
      
        <div class="flex-wrap">
          
           <div style="">
          <div>关键字查找</div>
          <Input  np clearable style="width:300px;margin-top:5px;" v-model="filter.search" placeholder="标题/文号/发布机关等"/>
        </div>
        
        <!-- <div style="margin-left:10px">
          <div>起始日期</div>
          <BaseDate style="margin-top:5px;width:140px;" v-model="filter.started_at">
          </BaseDate>
        </div>
         <div style="margin-left:10px;width:140px;">
          <div>结束日期</div>
          <BaseDate style="margin-top:5px" v-model="filter.finished_at">
          </BaseDate>
        </div> -->
        <div class="flex-wrap flex-center hover-lighter" style="background:var(--bg2);height:60px;margin:10px;width:120px;border-radius:10px;border:1px solid var(--border);font-weight:bold;box-shadow:1px 1px 3px 1px var(--border);font-size:16px;color:var(--text2)" @click="getData()"><BaseIcon icon="md-search" :size="25" style="margin-right:10px" />查找</div>
        
        </div>
       
        </div>
      </div>
      <div style="position:relative;height:calc(100% - 120px);background:var(--bg3);">

      <BaseTable ref="table" :columns="columns" :data="items" :loading="loading" @event="handleTableEvent" />
      </div>
    </AppClient>
    <Drawer :width="800" v-model="showEditor" footer-hide  fullscreen style="background:linear-gradient(to bottom right,var(--bg2),var(--bg3))">
      <div style="padding:100px 50px;width:100%;position:realtive;height:calc(100% - 100px);">
        <div class="flex-wrap flex-right" style="position:fixed;top:40px;right:60px;text-align:right;font-size:20px;color:var(--subtext3);">
          <div class="text-button" style="margin-right:20px" @click="preview=!preview">{{preview?'编辑模式':'预览'}}</div>
          <div class="text-button" style="margin-right:20px" @click="handleSave">保存为草稿</div>
          <div class="text-button" style="margin-right:20px" @click="handleUpload">上传文章</div>
          <div class="text-button" @click="showEditor=false">退出编辑</div>
        </div>
        <template v-if="preview">
        <h1 style="padding-bottom:20px;border-bottom:1px solid var(--border);margin-bottom:20px;">{{formData.name}}</h1>
        <article v-html="formData.content" style="min-height:500px">
        </article> 
        </template>
        <span v-show="!preview">
        <div class="flex-wrap flex-line" style="margin-bottom:20px;width:100%;position:relative;">
          <div style="width:160px;flex-shrink:0">
            <div style="color:var(--subtext3)">文章类型 <span style="color:red">*</span></div>
         <Select v-model="formData.type" clearable style="width:160px;flex-shrink:0;border:1px solid #ccc;">
          <OptionGroup v-for="m in menus" :key="m.id" :label="m.name">
             <Option v-for="t in m.subs" :value="t.id" :key="t.id"> 
            {{t.name}}
          </Option>
          </OptionGroup>
         
         </Select>
          </div>
          <div style="flex-gorw:1;margin-left:20px;position:relative;">
           <div  style="color:var(--subtext3)">文章标题 <span style="color:red">*</span></div>
        <Input placeholder="请输入标题" v-model="formData.name" autofocus clearable  style="font-size:20px;border:1px solid #ccc;minWidth:500px;width:100%"></Input>
        </div>
        </div>
        <div class="flex-wrap" style="margin-bottom:20px;width:100%;position:relative;" v-if="formData.type && formData.type.includes('public-file')">
          <div style="flex-gorw:1;position:relative;">
           <div  style="color:var(--subtext3)">文号</div>
        <Input placeholder="请输入" v-model="formData.code" autofocus clearable  style="font-size:20px;border:1px solid #ccc;width:160px;"></Input>
        </div>
        <div style="flex-gorw:1;position:relative;margin-left:20px;">
           <div  style="color:var(--subtext3)">发文机关</div>
        <Input placeholder="请输入" v-model="formData.unit" autofocus clearable  style="font-size:20px;border:1px solid #ccc;width:500px;"></Input>
        </div>
        </div>
        <div style="flex-gorw:1;position:relative;margin-bottom:15px;" v-if="formData.type && formData.type.includes('image')">
           <div  style="color:var(--subtext3)">封面图片 <span style="color:red">*</span></div>
        <BaseImageUpload  v-model="formData.files" autofocus clearable :option="{max:1}"  style="font-size:20px;border:1px solid #ccc;width:500px;" />
        </div>
        <div style="height:calc(100% - 100px);width:100%;position:relative;">
        <div  style="color:var(--subtext3)">正文</div>
        <BaseTextEditor ref="editor" v-model="formData.content"  />
        
        </div>
        
        </span>
        <div class="flex-wrap flex-right" style="margin-top:40px">
        <Button  @click="handleSave">保存为草稿</Button>
        <Button type="primary"  @click="handleUpload"  style="margin-left:20px">上传文章</Button>
        <Button @click="showEditor=false" style="margin-left:20px">退出</Button>
        </div>
      </div>
    </Drawer>
  </App>
</template>

<script>
import App from '@/components/app/app'
import AppBar from '@/components/app/bar'
import AppClient from '@/components/app/client'
import AppTitle from '@/components/app/title'
import AppMask from '@/components/app/mask'
import AppLeft from '@/components/app/left'
import {pick} from 'lodash'
import { mapGetters } from 'vuex'
import UTIL from '@/utils'
export default {
  components:{App,AppBar,AppClient,AppTitle,AppMask,AppLeft},
    routerOption:{
        as_index:true
    },
    data(){
      return {
        ready:false,
        loading:true,
        checkFirst:false,
        editDeps:[],
        showEvaluateEditor:false,
        count:{
          all:0,
          mine:0,
          draft:0
        },
        showLeft:true,
        formData:{},
        current:{},
        showEditor:false,
        page:1,
        filter:{

        },
        loadingItem:false,
        mode:'all',
        items:[],
        preview:false,
        selected:{
          id:'notice',
          name:'通知公告'
        }
      }
    },
    methods:{
      select(key){
        setTimeout(()=>{
            this.menus.forEach(v=>{
          v.subs.forEach(s=>{
            if(s.id == key){
              this.$refs.tree.selected = this.selected = s
            }
          })
        })
        if(!this.selected || !this.selected.id)
          this.$refs.tree.selected = this.menus[0].subs[0]
        },300)
      
      },
      handleCreate(){
        this.formData = {
          type:this.selected.id
        }
        this.showEditor = true
      },
      handleTreeEvent(e){
        if(e.type == 'select'){
          this.selected = e.data
          this.getData()
        }
      },
      handlePublish(e){
        this.$api.patch(`articles/${e.id}?q=publish`).then(()=>{
           this.$Notice.success({title:"操作成功",desc:"文章已发布"})
            this.$set(e,'state',2)
        })
      },
      handleCancel(e){
        this.$api.patch(`articles/${e.id}?q=cancel`).then(()=>{
           this.$Notice.success({title:"操作成功",desc:"文章已撤回,请前往草稿箱查看"})
            this.$set(e,'state',0)
        })
      },
      handleUnPublish(e){
        this.$api.patch(`articles/${e.id}?q=unpublish`).then(()=>{
           this.$Notice.success({title:"操作成功",desc:"文章已撤发"})
            this.$set(e,'state',1)
        })
      },
      handleTableEvent(e){
        if(e.type == 'open'){
          let item = e.data
          	let url = "/core/articles/" + item.id;
            this.$store.commit("session/push", {
              id: item.id,
              name: item.name,
              icon: "md-document",
              url,
              base_url: url,
            });
            this.RouteTo(url, false);
        }
      },
      handleDelete(item){
        if(item && item.id){
          this.Confirm("确定删除这篇文章?",()=>{
            this.$api.delete(`articles/${item.id}`).then(()=>{
              let index = this.items.findIndex(v=>v.id == item.id)
              this.items.splice(index,1)
              this.$Notice.success({title:"操作成功",desc:"文章已删除"})
            })
        })
        }
        
      },
      getCountAll(){
        this.$api.get('articles?q=count').then(res=>{
          this.count = res.data.data
        })
      },
      getData(){
        this.loading = true
        let url = `articles?t=${this.selected.id}`

        let filter = this.filter
        for(let key in filter){
          if(filter[key])
            url+=`&${key}=${filter[key]}`
        }

        url+=`&page=${this.page}`

        if(this.mode == 'mine')
          url += '&q=mine'
        else if(this.mode == 'draft')
          url += '&q=draft'
        this.$api.get(url).then(res=>{
          this.items = res.data.data
        }).finally(()=>{
          this.loading = false
        })
        
      },
      handleSave(){
        let data = this.formData
        data.content = this.$refs.editor.submit()
        data.state = 0
        this.$api.post('articles?q=draft',data).then(res=>{
          this.$Notice.success({title:"操作成功",desc:"文章已保存为草稿"})
          this.showEditor = false
          this.getData()
        })
      },
      handleEdit(item){
        this.showEditor = true
        this.loadingItem = true
        this.$api.get('articles/'+item.id).then(res=>{
          this.current = res.data.data
          this.formData = pick(res.data.data,['name','content','type','dep_id','code','unit'])
        }).finally(()=>{
          
          this.loadingItem = false
        })
      },
      handlePush(e){
         this.$api.patch(`articles/${e.id}?q=push`).then(()=>{
           this.$Notice.success({title:"操作成功",desc:"文章已发表"})
           this.$set(e,'state',1)
           this.getData()
        })
      },
      handleUpload(){
        if(this.current.id){
          let updateInfo = UTIL.compare_patch_object(this.current,this.formData)
          if(Object.keys(updateInfo).length > 0){
            this.$api.patch(`articles/${this.current.id}`,updateInfo).then(res=>{
              this.$Notice.success({title:"操作成功",desc:"文章已修改完成"})
              this.showEditor = false
              this.current = {}
              this.formData = {}
              this.getData()
            })
          }
          return
        }

        if(!this.formData.name){
          this.$Notice.error({title:"错误",desc:"请输入文章标题"})
          return
        }
        let data = this.formData
        data.content = this.$refs.editor.submit()
        data.state = 1
        this.$api.post('articles',data).then(res=>{
          this.$Notice.success({title:"操作成功",desc:"文章已上传"})
          this.showEditor = false
          this.getData()
        })
      }
    },
    computed:{
      ...mapGetters('session',['session']),
      columns(){
        const cols = [{
          type:'index',
          title:"序号",
          width:80
        },
        {
          type:"text",
          title:"文号",
          width:120,
          formal:true,
          key:'code'
        },
        
        {
          type:'text',
          title:'文章标题',
          key:'name',
          linkEvent:'open',
          align:'left',
          minWidth:220

        },{
          type:"text",
          title:"发文机关",
          
          formal:true,
          width:200,
          key:'unit'
        },{
          type:'state',
          key:'state',
          title:"状态",
          width:100,
          option:{
            states:['草稿','内网','外网'],
            colors:['var(--subtext3)','var(--primary)','var(--success)']
          }
        },{
          type:"bool",
          width:200,
          key:"published",
          title:"发布",
          render:(h,{row})=>{
            let btnPublish = h('Button',{props:{size:"small"},on:{
              click:()=>{
                this.handlePublish(row)
              }
            }},'发布外网')
            let btnUnpublish = h('Button',{props:{size:"small"},on:{
              click:()=>{
                this.handleUnPublish(row)
              }
            }},'撤回内网')
            let btnUpload = h('Button',{props:{size:"small"},on:{
              click:()=>{
                this.handlePush(row)
              }
            }},'发布内网')
            let btnCancel = h('Button',{props:{size:"small"},on:{
              click:()=>{
                this.handleCancel(row)
              }
            }},'撤回')
            let statemap = [btnUpload,[btnCancel,btnPublish],btnUnpublish]
            let button = statemap[row.state]
            if(!Array.isArray(button))
              button = [button]
            return h('div',{class:'flex-wrap flex-center split5'},button)
           
          }
        },{
          type:'user',
          key:'created_by',
          title:"撰写人",
          width:120
        },{
          type:'time',
          key:'created_at',
          title:"上传时间",
          width:120
        },{
          type:'time',
          key:'updated_at',
          title:"最近更新",
          width:120
        },{
          minWidth:120,
          maxWidth:200,
          title:'编辑',
          render:(h,{row})=>{
            let btnDelete = h('Button',{props:{size:'small'},on:{click:()=>{
              this.handleDelete(row)
            }}},'删除')
            let btnEdit = h('Button',{props:{size:"small"},on:{click:()=>{
              this.handleEdit(row)
            }}},'编辑')
            
            let buttons = []
            if(this.session.id == row.created_by)
              buttons = [btnEdit,btnDelete]
            return h('div',{class:"flex-wrap flex-center split5"},buttons)
          }
        }]
        if(this.selected && this.selected.formal)
          return cols
        else
          return cols.filter(v=>!v.formal)
      },
      
      menus(){
        return [{
        id:1,
        name:"专栏文章",
        subs:[{
          id:'notice',
          name:"通知公告"
        },{
          id:'public-file',
          name:"公司文件",
          formal:true
        },{
          id:'official-public-file',
          name:"政府文件",
          formal:true
        },{
          id:'activity',
          name:"企业动态"
        },{
          id:'image',
          name:"图片动态",
          img:'true'
        },{
          id:'policy',
          name:"政策解读"
        },{
          id:'recommend',
          name:"推荐文章"
        },{
          id:'exp',
          name:'经验分享'
        },{
          id:'books',
          name:'读书会'
        }]
      }]
      }
    },
    watch:{
      $route:{
        handler(to,from){
        
        setTimeout(()=>{
          if(to.query && to.query.t){
          this.select(to.query.t)
        }else{
          this.$refs.tree.selected = this.selected = this.menus[0].subs[0]
        }
        },500)
       
        },
        deep:true,
        immediate:true
      },
    },
    mounted(){
      this.ready = true
      setTimeout(()=>{
        setTimeout(()=>{
          this.$refs.table.calcTableHeight()
          this.getData()
          this.getCountAll()
          this.ready = false
        },1000)
      },1000)
    }
}
</script>

<style lang="less" scoped>
.text-button{
  cursor:pointer;
  font-size:14px;
}

.text-button:hover{
  color:var(--primary);
}

.text-button:active{
  position: relative;
  right:-1px;
  bottom:-1px;
}

</style>