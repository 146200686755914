<template>
   <div :style="`position:absolute;left:${!collapse?0:-width}px;top:43px;bottom:0;width:${width}px;background:none;transition:all 0.8s;`">
      <slot></slot>
    </div>
</template>

<script>
  export default {
    name:"Explore",
    props:{
      collapse:{
        type:Boolean
      },
      width:{
        type:Number,
        default:260
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>