<template>
	<a style="color: fff">
		<span @click="onClickSlot"
			>{{ value ? "下载文件" : (label || "上传文件") }}</span
		>
		<input
			type="file"
			ref="file"
			style="visibility: hidden; position: absolute"
			@change="handleUpload"
			:accept="accept"
			:multiple="multiple"
		/>
		<a
			v-if="value"
			style="margin-left: 10px; color: var(--primary)"
			@click.prevent="onSelectFile"
			>重传</a
		>
		<br />
		<span style="color: #aaa">{{ uploading ? `(${percent})` : "" }}</span>
	</a>
</template>

<script>
export default {
	data() {
		return {
			file: {},
			uploading: false,
			percent: 0,
		};
	},
	props: ["value","label", "accept", "multiple", "editable"],
	methods: {
		onClickSlot(e) {
			if (this.value) {
				this.DownloadWithName(this.value, "合同");
			} else this.onSelectFile(e);
		},
		onSelectFile(e) {
			this.$refs.file.click();
		},
		getFileExt(url) {
			if (url) {
				let ext = url.substring(url.lastIndexOf(".") + 1);
				return ext;
			}
		},
		onUploadProgressList(o) {
			this.percent = o.percent;
		},
		onSuccess(files) {},
		handleUpload() {
			let file = this.$refs.file.files[0];
			this.uploading = true;
			this.percent = 0;
			this.$cos.upload(
				[file],
				{
					onFail: () => {
						this.uploading = false;
					},
					onProgress: this.onUploadProgressList,
					onSuccess: (files) => {
						if (!Array.isArray(files)) throw "文件上传失败";
						this.value = files[0].url;
						this.$emit("input", this.value);
						this.uploading = false;
					},
				},
				{ coskey: this.$store.getters["session/session"].coskey }
			);
		},
	},
};
</script>

<style>
</style>