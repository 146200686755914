<template>
  <div>
    module
  </div>
</template>

<script>
  export default {
    routerOption:{
      as_modcon:true
    }
  }
</script>

<style lang="scss" scoped>

</style>