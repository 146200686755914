<style lang="less">
.pd-weather {
  background:none;
  overflow:hidden;
  border:1px solid var(--border);
  border-radius:3px;
  .pd-today {
    width: 100%;
    padding:5px 10px;
    max-height:60px;
    height:100%;
  background:none;
  color:var(--text2);
  border-top-left-radius:3px;
  border-top-right-radius:3px;
  border-right:1px solid var(--border);
  border-bottom:1px dashed var(--border);
  }
  .pd-future {
    height:calc(100% - 60px);
    width:100%;
    padding:0 5px;
    position: relative;
    background:#ffffff55;border-bottom-left-radius:3px;border-bottom-right-radius:3px;overflow:hidden;border-bottom:1px solid var(--border);border-right:1px solid var(--border);padding-top:10px;color:var(--text2);
    .l-future-day {
      margin: 0 2px;
      margin-left:10px;
      width: calc(20% - 4px);
      height: 100%;
      text-align: center;
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    .l-future-day:last-child{
      border:none;
    }
  .absolute-day{
    position: absolute;
    left:-5px;
    top:5px;
    font-weight: bold;
    font-size:15px;
    color:var(--text3);

  }
  }
}

.m-dark{
  .pd-weather{
     color:var(--text1);
  }
}
</style>

<template>
  <div class="container flex-col pd-weather">
    <!-- <img src="@/assets/bg.png" style="width:100%;height:100%" /> -->
    <div class="container flex-wrap m-light" v-if="h<=30" :style="`font-size:${h<20?10:20}px;background:#dddddd33;border-radius:10px;padding:0 5px;`">
      {{weatherData.city}} {{weatherData.realtime.temperature}}℃  {{weatherData.realtime.info}} {{weatherData.realtime.direct}} {{weatherData.realtime.power}} 湿度 {{weatherData.realtime.aqi}}
    </div>
    <div class="pd-today flex-wrap flex-between align-start m-light" v-if="h>30">
      <div>
        <div class="pd-ip-sub" v-if="h > 50">{{moment().format("LL")}}</div>
        <span style="font-size:1.3rem;">{{weatherData.city}}</span>
      </div>
      <span style="font-size:1.4rem;text-align:right;flex-shrink:0;z-index:10;position:relative;">
        <span style="">{{weatherData.realtime.temperature}}℃</span> <span style="font-size:0.8rem">{{weatherData.realtime.info}}</span>
        <div style="font-size:0.7rem;text-align:right;" v-if="h > 50"> {{weatherData.realtime.direct}}
          {{weatherData.realtime.power}} 湿度 {{weatherData.realtime.aqi}}</div>
          
      </span>
      <Icon :custom="`ugoicon ugi${weatherData.realtime.icon || 'qing'}`" :size="60" color="var(--border)" style="position:absolute;right:80px;;top:-3px;filter:blur(1px)"></Icon>
      <Icon v-if="weatherData.realtime.icon2" :custom="`ugoicon ugi${weatherData.realtime.icon2 || 'qing'}`" :size="60" color="var(--border)" style="position:absolute;right:72px;;top:-4px;filter:blur(1px);opacity:0.5"></Icon>
    </div>
    <div class="flex-wrap pd-future" v-if="h > 130">
      <template v-for="f in weatherData.future">
        <div class="flex-col l-future-day " :key="f.date" style="padding-top:15px;">
          <span  :class="{'absolute-day':h<220}">{{moment(f.date).format('DD')}}</span>
          <div style="margin-top:5px;">
            <div  v-if="h > 170">
              <template v-if="w < 350">
                {{weeknames[moment(f.date).format('E')]}}
              </template>
              <template v-else>
                星期{{weeknames[moment(f.date).format('E')]}}
              </template>
            </div>
            <div style="font-size:0.9rem;margin:10px 0;color:var(--primary);position:relative;">
              <Tooltip :content="f.weather" transfer placement="right" style="position:relative;bottom:-10px;">
                <Icon :custom="`ugoicon ugi${f.weather_icon || 'qing'}`" color="var(--subtext3)" :size="h>170?55:(25)"></Icon>
                <Icon :custom="`ugoicon ugi${f.weather_icon2 || 'qing'}`" color="var(--subtext3)" style="position:absolute;right:-2px;top:-2px;opacity:0.3;" v-if="f.weather_icon2" size="20"></Icon>
              </Tooltip>
            </div>
            <div style="height:60px">
            <!-- <div style="font-size:0.7rem;">{{f.weather}}</div> -->
            <div style="font-size:0.7rem;"> {{f.temperature}}</div>
            </div>

          </div>

        </div>
      </template>
    </div>
  </div>

  </div>
</template>

<script>
import mixinResponsable from '@/mixins/responsable'
import moment from 'moment'
moment.locale('zh-cn')
export default {
  name: 'Weather',
  panelInfo: {
    name: '天气',
    minWidthSpan: 2
  },
  data() {
    return {
      time: moment().format("HH:mm:ss"),
      week: "",
      weeknames: ['日', '一', '二', '三', '四', '五', '六', '日']
    }
  },
  mixins: [mixinResponsable],
  mounted() {
    this.week = moment().week()
  if(this.$store.getters['session/session'].id){
       this.$store.dispatch('panel/queryWeather')
    }else{
      
      this.$bus.$on('login',()=>{
        this.$store.dispatch('panel/queryWeather')
      })
    }
   
   
  },
  computed: {
    weatherData() {
      return this.$store.getters['panel/weather']
    }
  }
}
</script>

<style lang="less" scoped>
</style>