<template>
  <App>
    <AppBar>
      <div class="flex-wrap" style="font-size:16px;color:var(--primary);">
      <div class="flex-wrap flex-center"  style="margin-right:5px;width:30px;height:30px;border-radius:5px;border:2px solid var(--primary);">
        
      <BaseIcon :icon="activeItem.icon" :size="20" color="var(--primary)" />
      </div>
      {{activeItem.name}}
      </div>
       <IconTabs :items="tabs" :current="activeTab" @on-select="handleOpen" />
      <div>exit</div>
    </AppBar>
    <AppClient>
      <router-view></router-view>
    </AppClient>
  </App>
</template>

<script>
  import App from '@/components/app/app'
  import AppBar from '@/components/app/bar'
  import IconTabs from '@/components/app/IconTabs'
  import AppClient from '@/components/app/client'
  export default {
     routerOption:{
      as_frame:true
    },
     methods:{
      handleOpen(e){
        if(e == 'mine'){
          this.RouteTo("/core/self/traning")
        }else if(e == 'admin'){
          this.RouteTo("/core/profile/traning")
        }else{
          this.RouteTo("/core/course/"+e)
        }
      }
    },
    components:{App,AppBar,IconTabs,AppClient},
    computed:{
       tabs(){
        return [{
          key: "home",
          name: "培训课程",
          icon: "md-home",
        },{
          key: "training",
          name: "培训",
          icon: "xiangmu1",
        }, {
          key: "user",
          name: "我的培训",
          icon: "user",
        },{
          key:"admin",
          name:"后台管理",
          icon:"edit"
        }]
       },
       activeTab(){
        return this.$route.path.slice(13)
      },
      activeItem(){
        return this.tabs.find(v=>v.key == this.activeTab) || {}
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>