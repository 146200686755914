<template>
  <BaseSegment title="收费总览" desc="项目收费情况总览">
  
    <Row :gutter="20">
      <Col :span="tiny?12:8">
      <CellGroup>
        <Cell>
          签约金额
          <BaseAmount slot="extra" :value="value.signed_amount"></BaseAmount>
        </Cell>
        <Cell>
          调整金额
          <BaseAmount slot="extra" :value="value.adjust_amount"></BaseAmount>
        </Cell>
        
        <Cell>
          合同金额(调整后)
          <BaseAmount slot="extra" :value="value.amount"></BaseAmount>
        </Cell>
      </CellGroup>
      </Col>
      
      <Col :span="tiny?12:8">
      <CellGroup>
        
        <Cell>
          开票占比
          <Progress slot="extra" text-inside  :percent="value.bill_percent" status="active" style="width:100px" :stroke-width="15"  />
        </Cell>
        <Cell>
          开票金额
          <BaseAmount slot="extra" :value="value.bill_amount"></BaseAmount>
        </Cell>
        <Cell>
          尚可开
         <BaseAmount slot="extra" :value="value.unbill_amount"></BaseAmount>
        </Cell>
      </CellGroup>
      </Col>
      <Col :span="8" v-if="!tiny">
      <CellGroup>
        
        <Cell>
          到账占比
          <Progress slot="extra" text-inside  :stroke-width="15" :percent="value.trans_percent" status="active" style="width:100px"  />
        </Cell>
        <Cell>
          已到账
          <BaseAmount slot="extra" :value="value.trans_amount"></BaseAmount>
        </Cell>
        <Cell>
          未到账
          <BaseAmount slot="extra" :value="value.untrans_amount"></BaseAmount>
        </Cell>
      </CellGroup>
      </Col>
    </Row>
  </BaseSegment>
</template>

<script>
  export default {
    props:{
      value:{
        type:Object,
        default:()=>({})
      },
      tiny:{
        value:Boolean
      }
    },
    
  }
</script>

<style lang="scss" scoped>

</style>