<template>
  <Select :value="value" @input="$emit('input',$event)" filterable multiple :size="size">
    <OptionGroup v-for="gg in groups" :label="gg.name" :key="gg.id">
        <Option v-for="g in gg.groups" :value="g.id" :key="g.id">
            {{g.name}}
        </Option>
    </OptionGroup>
    
  </Select>
</template>

<script>
export default {
    props:{
        value:{
            type:Array,
            default:()=>([])
        },
        included:{
            type:Array,
            default:()=>([])
        },
        excluded:{
            type:Array,
            default:()=>([])
        },
        size:{
            type:String,
            default:'default'
        }
    },
    mounted(){
        setTimeout(()=>{
            this.$store.dispatch('session/getDeps')
            this.$store.dispatch('session/getProjects')
        },500)
    },
    computed:{
        groups(){
            let deps = this.$store.getters['session/deps']
            let projects = this.$store.getters['session/projects']
            return [{
                id:'g1',
                name:'部门',
                groups:deps,
            },{
                id:'g2',
                name:'项目',
                groups:projects
            }]
        }
    }
}
</script>

<style>

</style>