<template>
  <div class="l-full flex-wrap split5" style="color:#aaa;align-items:flex-start;">
    <div>
    {{config?config.label:''}} 
    </div>
      <div class="flex-col" style="align-items:flex-start">
      <template v-for="o in options">
        <div :key="o.key">
          <Checkbox :label="o.name" :key="o.key" :disabled="!editable">
            <span>{{o.name}}</span>
          </Checkbox>
        </div>
      </template>
      </div>
  </div>
</template>

<script>
import defaultValueMixin from '../mixins/default-value'
  export default {
    mixins:[defaultValueMixin],
  cname:"配置项",
  icon:'xuanxiang',
  corder:92,
 props:['fkey','config','data','editable'],
  computed:{
    options(){
      let options = Object.keys(this.config.options || {}).map(key=>{
        return {
          key,
          name:this.config.options[key].name
        }
      })
      return options
    },
    value:{
      get(){
        return this.data
      },
      set(v){
        this.$emit('change',v)
      }
    }
  }
}
</script>

<style lang="less">
.l-full{
  position: relative;
  height:100%;
  width:100%;
}
</style>