<template>
  <div>
    服务器状态:
    <Button @click="chat">发送请求</Button>
  </div>
</template>

<script>
import axios from 'axios'
let ai = axios.create({
  baseURL:"https://api.openai.com/v1/",
  timeout:300000,
  crossDomain:true
})
ai.defaults.headers.Authentication = 'Bearer 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'
ai.defaults.headers["Content-Type"] = "application/json"
    ai.defaults.headers["OpenAI-Beta"] = "assistants=v1"
export default {
  data(){
    return {
      assistants:[],
      run:{},
      steps:[],
      messages:{}
    }
  },
  mounted(){
    setTimeout(()=>{
      this.getGPTs()
    },500)
  },
  methods:{
    getGPTs(){
      this.$ai.get("assistants").then(res=>{
        this.assistants = res.data.data
      })
      // ai.get("assistants?limit=10").then(res=>{
      //   this.assistants = res.data.data
      // })
    },
    chat(){
       this.$ai.post("assistants/asst_7zEHmB2fxAgcP67FLe1M5R29/threads",{
        messages:[{role:'user',content:'如何创建一个30层的直跑楼梯'}]
       }).then(res=>{
        this.run = res.data.data
        if(this.run.status !== 'complete'){
          setTimeout(()=>{
            this.Refresh()
          },1000)
        }
      })
    },
    RefreshSteps(){
      for(let i=0;i<this.steps.length;i++){
        let step = this.steps[i]
        if(step.status == 'in_progress'){
          step.content = "Answer:"
          this.RefreshStep(i)
        }
      }
    },
    RefreshStep(i){
      let step = this.steps[i]
      this.$ai.get(`runs/${this.run.id}/steps/${step.id}?thread_id=${this.run.thread_id}`, { responseType: 'stream'  }).then(response=>{
     
        if (response && typeof response.pipe === 'function') {
            response.on('end', () => {
              console.log('end');
            });

            response.on('data', (chunk) => {
              console.log("data:", chunk.toString('utf-8'));
            });
          }
      })
    },
    RefreshMessage(message_id){
      this.messages[message_id] = {content:""}
      this.$ai.get(`threads/${this.run.thread_id}/message/${message_id}`, { responseType: 'stream',stream:true }).then(stream=>{
         
        if(stream && stream.data){
        stream.data.on('end',()=>{
          console.log('end')
        })
        stream.data.on('data',(chunk)=>{
          this.messages[message_id].content += chunk.toString('utf-8')
          console.log("text:",chunk.toString('utf-8'))
        })
        }
      })
    },
    Refresh(){
      if(this.run && this.run.id)
        this.$ai.get(`runs/${this.run.id}?thread_id=${this.run.thread_id}`).then(res=>{
          let run = res.data.data
          this.run.status = run.status
          if(run.steps)
            this.steps = run.steps
          if(this.run.status == 'queue'){
            setTimeout(()=>{
              this.Refresh()
            },1000)
          }else if(this.run.status == 'in_progress'){
            this.RefreshSteps()
          }
        })
    }
  }
}
</script>

<style>

</style>