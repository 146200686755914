export default {
  computed:{
    routeKey(){
      return this.$route.path.slice(this.$route.path.lastIndexOf('/')+1)
    }
  },
  methods:{
    isRouteActive ( path , basepath){
      if (this.$route){
        if(path && path.includes('/:id')){
          path = path.slice(0,path.indexOf('/:id')-1)
          return this.$route.path.indexOf(path) != -1
        }
        if (basepath) {
          return this.$route.path.indexOf(basepath) == 0
        }
        return this.$route.path === path
      }
    }
  }
}