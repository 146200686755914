<template>
  <div>
    <Row :gutter="10" class-name="l-bill">
      <Col :span="1"  style="text-align:center">序号</Col>
      <Col :span="3"  style="text-align:center" v-if="value.splited">服务类型</Col>
      <Col :span="10">内容</Col>
      <Col :span="6"  style="text-align:center">备注</Col>
      <Col :span="4"  style="text-align:center">附件</Col>
    </Row>
   
     <BaseEmpty v-if="items.length == 0" style="padding:20px;margin:0 -5px;width:calc(100% + 10px);background:var(--bg3);" msg="暂无条目" />
    <template v-for="(data,index) in items">
      <Row :key="data.id" :gutter="10" class-name="l-bill">
      <Col :span="1"  style="text-align:center">{{index+1}}</Col>
      
      <Col :span="3"  align="center"  v-if="value.splited">{{data.business_type}}</Col>
      <Col :span="10" style="color:var(--primary)">{{data.detail}}</Col>
       <Col :span="6"  align="center"  >{{data.note}}</Col>
      <Col :span="4" align="center" >
        <a v-if="data.file" @click="Download(data.file)">下载</a><span v-else>-</span>
      </Col>
    </Row>
    </template>
  </div>
</template>
<script>
  export default {
    props:{
      value:{
        type:Object,
        default:()=>({})
      }
    },
     computed: {
      items(){
        return this.value.memos || []
      },
    amount() {
      return this.value.reduce((c,item) => {
        return c + item.amount
      }, 0)
    }
  }
  }
</script>

<style lang="scss" scoped>

</style>