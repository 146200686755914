<template>
  <Container >
      <h2 style="padding:10px 20px">履约过程管理</h2>
   
    <div class="flex-wrap" style="margin:0 20px">
      <Input search placeholder="输入项目关键字搜索" style="width:200px" />
      <Button icon="md-refresh" style="margin-left:10px" @click="getData()"></Button>
    </div>
    <div style="padding:0 20px;height:calc(100% - 80px);width:100%;position:relative;flex-shrink:0;flex-grow:1;">
      <BaseTable ref="table" :columns="cols" :data="filteredItems" full />

    </div>

  </Container>
</template>
<script>
  import Container from '@/components/app/container'
  import Toolbar from '@/components/app/toolbar'
  import moment from 'moment'
export default {
  routerOption: {
    as_default:true
  },
  components:{Container,Toolbar},
  data(){
    return {
      filter_mode:0,
      filter_type:null,
      regions:[{
        name:'全部规模',
        new_count:3,
        count:14
      },{
        name:"重大项目-A",
        color:"var(--error)",
        count:6
      },{
        name:'中等项目-B',
        count:4,
        color:'var(--warning)'
      },{
        name:'一般项目-C',
        color:'var(--text3)',
        count:4,
      },],types:[{
        name:'全部类型',
        new_count:3,
        count:14
      },{
        name:"全过程咨询",
        color:"var(--error)",
        count:6
      },{
        name:'房建监理',
        count:4,
        color:'var(--warning)'
      },{
        name:'市政监理',
        color:'var(--text3)',
        count:4,
      },{
        name:'其他',
        color:'var(--text3)',
        count:4,
      },],
      states:[{
        name:'全部阶段',
        new_count:3,
        count:14
      },{
        name:'项目准备',
        new_count:3,
        color:'var(--error)',
        count:0
      },{
        name:"桩基阶段",
        count:13,
        new_count:2
      },{
        name:"地下室阶段",
        count:1
      },{
        name:"结顶阶段",
        count:1
      },{
        name:"竣工验收",
        count:0,
        new_count:2
      },{
        name:"审计阶段",
        count:34
      }],
      items:[]
    }
  },
  mounted(){
  setTimeout(()=>{
      this.$refs.table.calcTableHeight()
  },500)
  },
  computed: {
    filteredItems(){
      if(this.filter_type !== null)
        return this.items.filter(v=>v.type == this.filter_type)
      else
        return this.items
    },
    cols(){
      return [{
        title:"序号",
        width:60,
        type:"index"
      },{
        title:"项目类型",
        width:120,
        key:"type",
        render:(h,{row})=>{
          const colormap = {
              '全过程咨询':'var(--text3)',
              '市政监理':'var(--warning)',
              '房建监理':'var(--success)'
            }
          return h('div',{
            style:`background:var(--border);border-left:5px solid var(--border);border-color:${colormap[row.business_type] || 'var(--text3)'};width:80px;margin:0 auto;text-align:center;padding:2px;padding-right:7px;border-radius:5px;`},row.business_type)
        }
      },{
        title:"项目名称",
        minWidth:140,
        key:"name",
        render:(h,{row})=>{
          let icon = h("BaseIcon",{props:{icon:'lianjieliu'},style:"margin-right:3px;color:var(--primary)"})
          let name = h('a',row.name)
          return h('div',{class:"flex-wrap",style:"text-align:left"},[icon,name])
        }
      },{
        title:"项目规模",
        width:120,
        key:"type",
        render:(h,{row})=>{
          let type = 'C类'
          if(row.amount > 1000000)
            type = 'B类'
          else if(row.amount > 10000000)
            type = 'A类'
          const colormap = {
            'C类':'var(--primary)',
            'B类':'var(--warning)',
            'A类':'var(--error)'
          }
          return h('div',{
            style:`background:var(--border);border-left:5px solid var(--border);border-color:${colormap[type] || 'var(--text3)'};width:80px;margin:0 auto;text-align:center;padding:2px;padding-right:7px;border-radius:5px;`},type)
        }
      },{
        title:"合同金额",
        width:140,
        key:"name",
        render:(h,{row})=>{
          return h('BaseAmount',{props:{value:row.amount}})
        }
      },{
        title:"项目阶段",
        width:120,
        key:"项目阶段",
        render:(h,{row})=>{
          return h('div',{
            style:`background:var(--border);border-left:5px solid var(--border);width:80px;margin:0 auto;text-align:center;padding:2px;padding-right:7px;border-radius:5px;`},row['process'] || '-')
        }
      },{
        title:"项目描述",
        minWidth:140,
        key:"项目描述",
        render:(h,{row})=>{
          let icon = h("BaseIcon",{props:{icon:'md-alert'},style:"margin-right:3px;color:var(--primary)"})
          let name = h('a',row.workpoint)
          return h('div',{class:"flex-wrap",style:"text-align:left"},(row.workpoint?[icon,name]:'-'))
        }
      },{
        type:"number",
        title:"项目产值",
        width:120,
        key:"percent",
        option:{
          type:'percent',
          percentToValue:100
        }
      },
      {
        title:"项目负责人",
        width:120,
        type:'user',
        key:"charger_id"
      },{
        title:"最近更新",
        width:120,
        render:(h,{row})=>{
          return h('div',{style:"color:var(--success)"},row.updated_at?moment(row.updated_at).fromNow():'-')
        }
      },{
        title:"操作",
        width:120,
        render:(h,{row})=>{
          let buttons = [h('Button',{props:{size:"small",icon:"md-add"}},"履约记录")]
          return h('div',{class:"flex-wrap flex-center"},buttons)
        }
      }]
    }
      
  },
  mounted(){
      this.getData()
    },
  methods:{
    
    getData(){
        if(this.timer){
          clearTimeout(this.timer)
          this.loading = true
          
        }
        this.timer = setTimeout(()=>{
          const base_url = `contracts-m/performance_projects`
          let url = base_url
        
          this.$api.get(url).then(res=>{
            this.items = res.data.data
          }).catch(e=>{
            this.Error(e)
          }).finally(()=>{
            setTimeout(()=>{
              
            this.loading= false
            },300)
          })
        },100)
       
       
      },
  }
}
</script>

<style lang="less">
  .tab-card{
    padding:5px 10px;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background:var(--bg3);
    border-right:none;
    cursor: pointer;
    border-radius: 5px;
    .count{
      font-size:30px;
      font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    }
    
  }


  .tab-card:hover{
    background:var(--primary);
    color:var(--hover-text);
    .count,.name{
      color:var(--hover-text) !important;
    }
  }

  .tab-card-active{
    background:var(--primary);
    color:var(--hover-text);
    .count,.name{
      color:var(--hover-text) !important;
    }
  }
</style>