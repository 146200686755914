<template>
  <div class="ghost-input">
    <Input 
      :value="value" 
      @input="$emit('input', $event)" 
      @on-focus="$emit('focus',$event)"  
      @on-blur="$emit('blur',$event)" 
      @pointerdown.stop.native 
      :placeholder="placeholder || '请输入'"
    
    />
    <div 
      class="hidden-text" 
      :style="`max-width:${maxWidth}px`"
    >
      {{value}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value:{
      type:String | Number,
    },
    maxWidth:{
      type:Number,
      default:200
    },
    placeholder:{
      type:String,
      default:''
    }
  }
}
</script>

<style lang="less">
.ghost-input {
  height: 30px;
  position: relative;
  min-width: 30px;
  .ivu-input-wrapper{
    position: relative;
     font-size: inherit !important;
     background:none;
    padding:0 2px;
    input {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      z-index: 1;
        border: none;
      padding: 0 2px;
      margin: 0;
       background:none;
      font-size: inherit;
      border-radius: 0;
      min-width: 30px;
      text-overflow: ellipsis;
    }

     input:focus {
      border: none;

      outline: none;
      box-shadow: none;
      border-bottom: 1px dashed var(--border);
    }
    }
.hidden-text {
  height:0;
  padding:0 2px;
 font-size: inherit !important;
    overflow: hidden;
    white-space: nowrap;
  }

    }
// .ghost-input {
//   height: 30px;
//   position: relative;
//   min-width: 30px;
//   padding:0 2px;
//   .ivu-input-wrapper{
//     height: 30px;
//     position: relative;
//     padding:0 2px;
//     input {
//       position: absolute;
//       top: 0;
//       left: 0;
//       height: 100%;
//       z-index: 1;
//         border: none;
//       padding: 0 2px;
//       margin: 0;
//       font-size: inherit;
//       border-radius: 0;
//       min-width: 30px;
//     }
//     input:hover {
//       border: none;
//     }
//     input:focus {
//       border: none;

//       outline: none;
//       box-shadow: none;
//       border-bottom: 1px dashed var(--border);
//     }

//   }
  
//   .hidden-text {
//     height:0;
//     overflow: hidden;
//     white-space: nowrap;
//     max-width: 100px;
//   }
// }
</style>