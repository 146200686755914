<template>
    <div class="container">
        <div class="flex-wrap tabs">
            <div 
                class="tab" 
                :class="{
                    'tab-active':work_mode == t.id
                }" 
                v-for="t in work_modes"
                :key="t.id"
                @click="work_mode = t.id"
            >
                <BaseIcon :icon="t.icon || 'md-calendar'" :size="16" style="margin-right:2px" />
                {{ t.name }}
            </div>
            <div 
                class="tab" 
                :class="{
                    'tab-active':time_mode == t.id
                }" 
                v-for="t in time_modes"
                :key="t.id"
                @click="time_mode = t.id"
            >
                <BaseIcon :icon="t.icon || 'md-calendar'" :size="16" style="margin-right:2px" />
                {{ t.name }}
            </div>
        </div>
        <MaskLoading :loading="loading" title="高专工作协同系统">
                简洁而不简单
        </MaskLoading>
        <router-view></router-view>
    </div>
</template>

<script>
    import MaskLoading from '@/components/app/mask-loading'
  export default {
    name:"task",
    components:{MaskLoading},
    data(){
        return {
            work_mode:1,
            time_mode:0,
            loading:true
        }
    }, 
    mounted(){
        setTimeout(()=>{
            this.loading =false
        },1000)
    },
    computed:{
        work_modes(){
            return [{
                id:1,
                name:'个人'
            },{
                id:2,
                name:'协同'
            },{
                id:3,
                name:'管理'
            }]
        },
        time_modes(){
            return [{
                id:0,
                name:'当前',
                icon:'md-clock'
            },{
                id:1,
                name:'日',
                icon:'md-clock'
            },{
                id:2,
                name:'周',
                icon:'md-clock'
            },{
                id:3,
                name:'月',
                icon:'md-clock'
            },{
                id:4,
                name:'年',
                icon:'md-clock'
            },{
                id:5,
                name:'日历'
            }]
        },
        
    },  
    routerOption:{
      as_frame:true,
    }
  }
</script>

<style lang="less" scoped>
.tabs{
    position:absolute;
    z-index:999;
    top:0;
    height:25px;
    left:calc(50%);
    
    background:#111111;
    border:1px solid var(--border);
    box-shadow:0 0 5px 5px var(--border);
    border-top:none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;

    
    .tab{
        border-right:1px solid var(--border);
        background:linear-gradient(to bottom,var(--bg1),#11111111);
        flex-grow: 1;
        height:100%;
        color:var(--text1);
        transition:all 0.3s;
        text-align: center;
        cursor: pointer;
        padding:0 10px;
    }

    span{
        background:#11111155;
        color:var(--subtext2);
        border-radius: 2px;
        font-size:12px;
        margin-left:3px;
        padding:0 5px;
    }

    .tab:first-child{
        border-bottom-left-radius: 5px;
    }

    .tab:hover{
        filter:brightness(1.2);
        color:var(--active);
    }

    .tab-active,.tab-active:hover{
        background:var(--bg1);
        filter:brightness(1.3);
        color:var(--active);
        text-shadow: 0 0 2px var(--primary);
    }
    
}
</style>