<template>
  <Container class="flex-col align-start">
    <h2 style="margin:10px 20px;margin-bottom:0;">印章保管登记
        <div style="font-size:12px;color:var(--subtext3);font-weight:bold;">
            印章状态监控，请及时更新印章保管人信息
        </div>
      </h2>
    <div class="flex-wrap" 
        style="padding:0px 20px;height:100px;gap:10px;width:100%;">
        <div class="flex-wrap" style="padding:5px;gap:5px;background:var(--border);border-radius:5px;">
        
          <div 
            class="tab-card m-light"
           >
            <div class="name">印章状态</div>
            <div class="value">
                可用
            </div>
            
          </div>
          
          <div 
            class="tab-card m-light"
           >
            <div class="name">保管人</div>
            <BaseNameBoard uid="ed49e6a9-3b83-11eb-8e1e-c15d5c7db744" />
            
          </div>
          
        </div>
        </div>
        <div class="flex-wrap" style="margin:10px 20px">
        <Input search placeholder="输入名称搜索" /> <Button icon="md-refresh" style="margin-left:10px" @click="getData()"></Button>
      </div>
    <div style="padding:0 20px;height:calc(100% - 40px);width:100%;">
      <BaseTable ref="table" :columns="cols" :data="items" :count="321" :loading="loading"  />

    </div>
    
  </Container>
</template>
<script>
  import Container from '@/components/app/container'
  import Toolbar from '@/components/app/toolbar'
  import moment from 'moment'
export default {
  routerOption: {
    as_default: true,
  },
  components:{Container,Toolbar},
  data(){
    return {
      filter_mode:0,
      filter_type:null,
      loading:false,
      types:[{
        name:'合同审阅',
        color:'var(--text3)',
        count:2,
      },{
        name:'合同变更',
        count:1,
        color:'var(--warning)'
      },{
        name:'合同结算',
        count:1,
        color:'var(--success)'
      },{
        name:"用印申请",
        color:"var(--error)",
        count:4
      }],
      states:[{
        name:'全部',
        new_count:3,
        color:'var(--error)',
        count:7
      },{
        name:'待处理',
        new_count:3,
        color:'var(--error)',
        count:4
      },{
        name:"已处理",
        count:123,
        new_count:2
      },{
        name:"与我相关",
        count:243
      }],
      items:[]
    }
  },
  mounted(){
      this.getData()
    },
  computed: {
    filteredItems(){
      if(this.filter_type !== null)
        return this.items.filter(v=>v.type == this.filter_type)
      else
        return this.items
    },
    cols(){
      return [{
        title:"序号",
        width:60,
        type:"index"
      },{
          title:"类型",
          width:120,
          key:"type",
          render:(h,{row})=>{
            const colormap = {
              '合同审阅':'var(--text3)',
              '合同变更':'var(--warning)',
              '合同结算':'var(--success)'
            }
            return h('div',{
              style:`background:var(--border);border-left:5px solid var(--border);border-color:${colormap[row.type] || 'var(--text3)'};width:80px;margin:0 auto;text-align:center;padding:2px;padding-right:7px;border-radius:5px;`},row.type)
          }
        },{
        title:"日期",
        width:120,
        render:(h,{row})=>{
          return h('div',{style:"color:var(--success)"},row.updated_at?moment(row.date).format("L"):'-')
        }
      },{
        title:"备注",
        minWidth:140,
        key:"name",
        render:(h,{row})=>{
          let icon = h("BaseIcon",{props:{icon:''},style:"margin-right:3px;color:var(--primary)"})
          let name = h('a',row.note)
          return h('div',{class:"flex-wrap"},[icon,name])
        }
      },{
        title:"负责人",
        width:120,
        type:'user',
        key:"charged_by"
      },{
        title:"保管人",
        width:120,
        type:'user',
        key:"custody_by"
      },{
        title:"状态",
        width:120,
        render:(h,{row})=>{
          let buttons = [h('Button',{props:{size:"small"}},"归还")]
          return h('div',{class:"flex-wrap flex-center"},"已归还")
        }
      },{
        title:"操作",
        width:120,
        render:(h,{row})=>{
          let buttons = []
          return h('div',{class:"flex-wrap flex-center"},buttons)
        }
      }]
    }
      
  },
  methods:{
        getData(){
        if(this.timer){
          clearTimeout(this.timer)
          this.loading = true
          
        }
        this.timer = setTimeout(()=>{
          const base_url = `contracts-m/seal_custody_records`
          let url = base_url
        
          this.$api.get(url).then(res=>{
            this.items = res.data.data
          }).catch(e=>{
            this.Error(e)
          }).finally(()=>{
            setTimeout(()=>{
              
            this.loading= false
            },300)
          })
        },100)
       
       
      },
    }
}
</script>

<style lang="less">
  .tab-card{
    padding:5px 10px;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background:var(--bg3);
    border-right:none;
    cursor: pointer;
    border-radius: 5px;
    .value{
      font-size:20px;
      font-weight:bold;
    }
    
  }


  .tab-card:hover{
    background:var(--primary);
    color:var(--hover-text);
    .value,.name{
      color:var(--hover-text) !important;
    }
  }

  .tab-card-active{
    background:var(--primary);
    color:var(--hover-text);
    .value,.name{
      color:var(--hover-text) !important;
    }
  }
</style>