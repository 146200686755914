<template>
  <div>
    <Row :gutter="10" class-name="l-bill">
      <Col :span="1"  style="text-align:center">序</Col>
       <Col :span="2">计划类型</Col>
       <Col :span="2" align="center">收费日期</Col>
       <Col :span="3" align="center" v-if="value.splited">服务类型</Col>
      <Col :span="6" align="center">依据</Col>
      <Col :span="3" align="center">备注</Col>
       <Col :span="3" style="text-align:right" >收费金额</Col>
      <Col :span="1"  style="text-align:right"></Col>
      <Col :span="2"  align="center">状态</Col>
    </Row>
    
    <BaseEmpty v-if="items.length == 0" style="padding:20px;margin:0 -5px;width:calc(100% + 10px);background:var(--bg3);" msg="暂无条目" />
    <template v-for="(data,index) in items">
      <Row :key="data.id" :gutter="10" :class-name="`l-bill ${data.finished_at?'l-bill-success':(data.plan_finished_at && moment().isAfter(moment(data.plan_finished_at))?'l-bill-error':'')}`">
      <Col :span="1"  style="text-align:center">{{index+1}}</Col>
      <Col :span="2">{{data.type}}</Col>
      <Col :span="2"><span :style="`color:var(--${data.finished_at?'success':(data.plan_finished_at && moment().isAfter(moment(data.plan_finished_at))?'error':'text3')})`">{{moment(data.finished_at || data.plan_finished_at).format("YYYY/MM/DD")}}</span></Col>
       <Col :span="3"  v-if="value.splited">{{data.business_type}}</Col>
      <Col :span="6" style="padding-left:15px;">{{data.name}}</Col>
      <Col :span="3" style="text-align:left"  align="center">{{data.note}}</Col>
       <Col :span="3"  ><BaseAmount full :value="data.amount"></BaseAmount></Col>
      <Col :span="1"  style="text-align:right">{{data.percent}}%</Col>
      <Col :span="2"  align="center" :style="`color:${statesColors[data.status || 0]}`">{{states[data.status || 0]}}</Col>
     
    </Row>
    </template>
    <Row :gutter="10" class-name="l-bill l-bill-summary" v-if="items.length>0">
      <Col :span="4">合计</Col>
      <Col :span="13">
      <BaseAmount full :value="amount"></BaseAmount>
      </Col>
      <Col :span="2" align="left">
      {{percent}} %
      </Col>
    </Row>
  </div>
</template>
<script>
  export default {
    data(){
      return {
        states:['未完成','部分完成','已完成'],
        statesColors:['var(--subtext3)','var(--primary)','var(--success)']
      }
    },
    props:{
      value:{
        type:Object,
        default:()=>({})
      }
    },
     computed: {
      items(){
        return this.value.bill_plans || []
      },  
    amount() {
      return this.items.reduce((c,item) => {
        return c + item.amount
      }, 0)
    },percent(){
        return this.items.reduce((c,item) => {
        return c + item.percent
      }, 0)
    }
  }
  }
</script>

<style lang="less">
.l-bill-success{
  background:linear-gradient(to right,#eeffee,var(--bg3));
}
.l-bill-error{
  background:linear-gradient(to right,#ffeeee,var(--bg3));
}
</style>