import {
  API
} from '@/vue-plugins/api'
import {cloneDeep} from 'lodash'
const state = {
  cart_items:[],
  types:[],
  companies:[],
  products:[],
  level:0
}

const getters = {
  cart_items(state) {
    return state.cart_items
  },
  level(state){
    return state.level
  },
  types(state){
    return state.types
  },
  companies(state){
    return state.companies
  },
  grouped_types(state){
    let groups = state.types.filter(v=>v.parent_id).filter((v,i,a)=>a.findIndex(t=>t.id == v.parent_id) == -1).map(v=>({name:v.name,id:v.id}))
    groups.forEach(element => {
      element.children = state.types.filter(v=>v.parent_id == element.id)
    })
    return groups
  }
}


const actions = {
  getTypes({commit}){
    return new Promise((resolve,reject)=>{
      API.get("mat/types").then(res=>{
        commit("save_types", res.data.data)
        resolve(res.data.data)
      }).catch(reject)
    })
  },
  getCompanies({commit}){
    return new Promise((resolve,reject)=>{
      API.get("mat/companies").then(res=>{
        commit("save_companies",res.data.data)
        resolve(res.data.data)
      }).catch(reject)
    })
  },
  addType({commit},data){
    return new Promise((resolve,reject)=>{
      API.post("mat/types",data).then(res=>{
        let updateInfo = res.data.data
        commit("save_type",Object.assign({},data,updateInfo))
        resolve()
      }).catch(reject)
    })
  },
  addCompany({commit},data){
  return new Promise((resolve, reject) => {
    API.post("mat/companies", data).then(res => {
      let item = Object.assign({}, data, res.data.data)
      commit("save_company", item)
      resolve(item)
    }).catch(reject)
  })
  },
  addProduct({commit},data){
    return new Promise((resolve,reject)=>{
       API.post("mat/products", data).then(res => {
         let updateInfo = res.data.data
         commit("save_product", Object.assign({}, data, updateInfo))
         resolve()
       }).catch(reject)
    })
  }
}


const mutations = {
  set_level(state,level){
    state.level = level
  },
  save_type(state,item){
    let index = state.types.findIndex(v=>v.id == item.id)
    if(index == -1)
      state.types.push(item)
    else
      state.types.splice(index,1,item)
  },
  save_company(state,item){
     let index = state.companies.findIndex(v => v.id == item.id)
     if (index == -1)
       state.companies.push(item)
     else
       state.companies.splice(index, 1, item)
  },
  remove_type(state,id){
    let index = state.types.findIndex(v=>v.id == id)
    if(index !== -1)
      state.types.splice(index,1)
  },
  save_products(state,items){
    state.products = items
  },
  save_product(state,item){
    let index = state.products.findIndex(v => v.id == item.id)
    if (index == -1)
      state.products.push(item)
    else
      state.products.splice(index, 1, item)
  },
  remove_product(state,id){
     let index = state.products.findIndex(v => v.id == id)
     if (index !== -1)
       state.products.splice(index, 1)
  },
  save_types(state,items){
    state.types = items
  },
  save_companies(state,items){
    state.companies = items
  },
  add_cart(state,item){
    let index = state.cart_items.findIndex(v=>v.id == item.id)
    if(index != -1)
      state.cart_items.splice(index,1)
    let cloned = cloneDeep(item)
    cloned.count = 1
    state.cart_items.push(cloned)
  },
  set_count(state,{index,count}){
    let item = state.cart_items[index]
    item.count = count

  },
  remove_cart(state,index){
    state.cart_items.splice(index,1)
  }
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}