<template>
  <div style="position:absolute;left:0;right:0;top:0px;bottom:0;z-index:999;background:#fff;backdrop-filter:blur(3px)" class="flex-wrap flex-center">
      <BaseLoading v-if="!$slots" />
      <slot></slot>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>