<template>
  <Tooltip style="display: inline" :content="tip" :disabled="!tip">
    <a
      class="b-tag"
      :class="{'b-tag-selected':selected}"
      :style="`color:${!selected?'var(--subtext2)':(color || 'var(--primary)')}`"
      @click="handleSelect"
    >
      <slot></slot>
      <div class="b-count" v-if="count" style="margin-left:5px;">{{ count || '' }}</div>
      <BaseIcon icon="md-close" :size="12" v-if="closable" style="margin-left:5px;line-height:18px" @click="$emit('on-close')"></BaseIcon>
    </a>
  </Tooltip>
</template>
  
  <script>
export default {
  data() {
    return {
      selected: false
    };
  },
  props: ["tip", "color","value", "text-color","count","closable","selectable"],
  mounted(){
    this.selected = this.value
  },
  watch:{
    value:{
      handler(v){
        if(v !== this.selected)
          this.selected = v
      }
    }
  },
  methods:{
    handleSelect(){
      if(this.selectable){
        
      this.selected = !this.selected
      this.$emit('input',this.selected)
      }else{
        this.$emit('on-click')
      }
    }
  }
};
</script>
  
<style lang="less" scoped>
.b-tag {
  display: inline-block;
  height: 18px;
  line-height: 18px;
  position: relative;
  background:var(--bg3);
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  filter:drop-shadow(-1px 1px 1px #33333333);
  color: #fff;
  font-size: 12px;
  padding-left:8px;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Verdana, sans-serif;
  text-decoration: none;
  margin-left: 8px;
  display: flex;
  border:1px solid var(--border);
  border-left:none;
  white-space: nowrap;
  padding-right:5px;


  .b-count{
    height:18px;
    position: relative;
    line-height: 18px;
    top:-1px;
    right:-1px;
    border-left:1px solid var(--border);
    color:var(--subtext2);
    padding:0 5px;
  }
}
.b-tag:before {
  content: "";
  position: absolute;
  top: -1px;
  left: -4px;
  width: 0;
  height: 0;
  border-color: transparent var(--bg3) transparent transparent;
  border-style: solid;
  border-width: 9px 9px 9px 0;
}

.b-tag-selected{
    filter:brightness(1.05);
    color:var(--hover-text) !important;
    background:var(--primary);
    .b-count{
      color:var(--hover-text);
      border-color:var(--subtext2);
    }
}

.b-tag-selected:before{
  border-color:transparent var(--primary) transparent transparent;
}

.b-tag:active{
    position: relative;
    right:-1px;
    bottom:-1px;
}
</style>