<template>
  <div class="l-node">
        <div class="l-node-title">
            <template v-if="nameControl">
                <component :is="nameControl" :value="n.option?.name || n.graph?.text?.value" />
            </template>
            <template v-else>
            {{ n.option?.name || n.graph?.text?.value }}  <span v-if="multiple" style="margin-left:3px">(或签)</span> <span v-if="n.option.clonedBy" style="margin-left:3px">(关联:{{n.option.clonedType}}[{{n.option.clonedBy}}])</span>
            </template>
        </div>
        <div class="l-node-content">
        <template v-if="n.option && n.option.clonedBy">
            <template v-if="clonedNodes">
                <template v-for="cn in clonedNodes">
                <FlowNodeCreate 
                    :key="cn.id"
                    v-model="localValue.cloned[cn.id]"
                    :db="db"
                    :node="cn"
                    :name-control="clonedType"
                />
                </template>
            </template>
            <template v-else>
                <div style="color:var(--error);padding:10px;">此节点需要选择{{ n.option.cloneBy }}字段</div>
            </template>
        </template>
        <template v-else>
            <div class="flex-wrap">
                <div style="width:50px;flex-shrink:0;color:var(--primary);text-align:center;">负责人</div>
        
        <BaseUserSelectGrouped style="width:200px;border-radius:6px;border:1px solid #336699aa;" v-model="localValue.executors" :editable="executorType !== '固定'" />
            </div>
       
        </template>
        </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';

export default {
    name:'FlowNodeCreate',
    props:['value','db','node','name-control'],
    computed:{
        n(){
            return this.node || {}
        },
        option(){
            return this.n.option || {}
        },
        multiple(){
            return this.option.multiple
        },
        executorType(){
            return this.option['executor-type']
        },
        defaultUsers(){
            if(this.executorType === '固定' && this.n.graph.type === 'start_node')
                return this.$store.getters["session/session"].id
            return this.option['default-users']
        },
        region(){
            return this.option["approval-region"]
        },
        d(){
            return this.db || {}
        },
        localValue:{
            get(){
                return this.value || this.initNodeData()
            },
            set(v){
                console.log('set',v)
                this.$emit('input',v)
            }   
        },
        clonedType(){
            let clonedTypeMap = {
                '部门':'BaseDepBoard',
                '员工':'BaseNameBoard'
            }
            return clonedTypeMap[this.n.option.clonedType]
        },
        clonedBy(){
            let clonedBy = this.n.option?.clonedBy
            if(clonedBy){
                let paths = clonedBy.split('.')
                let relatedValues = this.d
                for(let i=0;i<paths.length;i++){
                    if(!relatedValues)
                        break
                    relatedValues = relatedValues[paths[i]]
                }

                return relatedValues
            }
        },
        clonedNodes(){
            if(Array.isArray(this.clonedBy) && this.clonedBy.length > 0){
                return this.clonedBy.map(v=>{
                    let cn = cloneDeep(this.n)
                    delete cn.option.clonedBy
                    delete cn.option.cloneKey
                    cn.id = this.n.id + '_' + v
                    cn.option.name = v
                    return cn
                })
            }else{
                return null
            }
        }
    },
    methods:{
        initNodeData(){
            let defaultValue = {}
            if(this.clonedBy){
               defaultValue.cloned = {}
            }
            if(this.defaultUsers){
                defaultValue.executors = this.multiple?[...this.defaultUsers]:this.defaultUsers
            }
            this.$emit('input',defaultValue)
            return defaultValue
        }
    }
}
</script>

<style lang="less" scoped>
.l-node{
    background:#33669933;
    border:1px solid #336699aa;
    border-radius:5px;
    overflow:visible;
    position: relative;
    margin-left:5px;
    .l-node-title{
        display: flex;
        align-items: center;
        padding:0 5px;
        height:30px;
        border-bottom: 1px solid #336699aa;
        color:var(--primary);
    }
    .l-node-content{
        padding:5px;
    }
}

.l-node:before{
    content:'';
    border:6px solid transparent;
    border-color: transparent #336699aa transparent transparent ;
    position: absolute;
    left:-13px;
    top:4px;
}

.l-node{
    .l-node{
        background:none;
        border-color:#33669955;
        margin:0;
        margin-bottom:5px
    }
    .l-node-title{
        border-color:#33669955;
    }
    .l-node:before{
        display: none;
    }

    .l-node:last-child{
        margin-bottom:0;
    }
}
</style>