<template>
  <App>
    <AppBar>
      <div class="flex-wrap">
        <BaseIcon icon="lianjieliu" size="18" style="margin-right:5px" />
        <FlowFileName v-model="flow" /> <span style="font-size:12px;color:var(--subtext2);margin-left:5px;">({{ id }})</span>
        <Button icon="md-refresh" @click="getData()" size="small" style="margin-left:10px" />
      </div>
      <div class="flex-wrap">
        <IconTabs :items="views" :current="view" @on-select="view=$event" />
       
      </div>
      <div class="flex-wrap split5">
        <AppAvatar />
        <Divider type="vertical" />
        <Button type="primary" @click="handleSubmit">提交数据</Button>
      </div>
    </AppBar>
    
    <AppMask title="工作流程" icon-right="lianjieliu"  :loading="loading" />
    <AppClient>
       <FlowInstance 
        :flow="flow"
        :version="version" 
        />
    </AppClient>

  </App>
</template>

<script>
import responsableMixin from '@/mixins/responsable'
import { mapGetters } from 'vuex'
import App from '@/components/app/app'
import AppBar from '@/components/app/bar'
import AppMask from '@/components/app/mask-loading'
import AppLeft from '@/components/app/left'
import AppRight from '@/components/app/right'
import AppClient from '@/components/app/client'
import AppAvatar from '@/components/app/avatar'
import IconButton from '@/components/app/IconButton'
import IconTabs from '@/components/app/IconTabs'
import FlowMenu from '@/components/flow/app/menu'
import FlowFileName from '@/components/flow/app/filename'
import FlowInstance from '@/components/flow/instance'
import FlowGraph from '@/components/flow/app/views/graph'
import UTIL from '@/utils'
export default {
  components: { App, AppBar,AppLeft,AppRight,AppClient,AppAvatar, IconButton,IconTabs, FlowMenu, FlowFileName,FlowInstance,FlowGraph,AppMask },
  routerOption:{
    as_default:true
  },
  data() {
    return {
      loading: false,
      selected: {},
      ready: false,
      showMenu: true,
      view: 'instance',
      showHelper: true,

      flow: {
       
      },
    }
  },
  mixins: [responsableMixin],
  props: {
    'panels': {
      type: Array,
      default: () => []
    }, 'padding': {
      type: Number,
      default: 0
    }, 'gutter': {
      type: Number,
      default: 10
    }, 'env': {
      type: Object,
      default: () => ({})
    }, 'flex': {
      type: Boolean,
      default: false
    }, 'panel-styles': {
      type: String
    }
  },
  watch:{
    $route:{
        handler(r,o){
                this.getData()
        },
        deep:true,
        immediately:true
    }
  },
  mounted(){
    this.getData()
  },
  computed: {
    ...mapGetters("session", ['session']),
    id(){
        return this.$route.params.id
    },
    nodes() {
      return this.panels
    },
    views() {
      const _views = [{
        key: 'instance',
        name: "填写",
        icon: "edit",
      }, {
        key: "graph",
        name: "图示",
        icon: "lianjieliu",
      }]
      let o = _views
      return o
    },
   
    admin() {
      return this.role == 'admin'
    },
    permissions() {
      if (this.role == 'admin') {
        return true
      } else return {

      }
    }
  },
  methods: {
    handleSubmit(){
      let instanceView = this.$refs.instance
      if(instanceView){
        if(this.view !== 'instance'){
          this.view = instance
        }
        instanceView.submit()
      }
    },
    getData(){
        this.loading = true
        this.$api.get(`/oa/flow-instances/${this.id}`).then(res=>{
            this.flow = res.data.data
        }).catch(e=>{
            this.Error(e)
        }).finally(()=>{
            this.loading = false
        })   
    },
    handleEvent({type,value}) {
      if(type == 'submit'){
        let updateParams = UTIL.compare_patch_object(this.flow,value)
        this.$api.patch(`/oa/flow-instances/${this.id}`,updateParams).then(res=>{
          this.getData()
        })
      }      
    },
    
  }
}
</script>

<style lang="less">
.pm-panel-flex {
  display: flex;
}
.pm-panel-editing-wrap:hover {
  filter: brightness(1.2);
  cursor: pointer;
}

.pm-panel-selected {
  background: #fbbd2162 !important;
}
</style>