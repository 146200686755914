<template>
  <BaseLayout
    :panels="panels"
    :gutter="20" 
    :padding="20" 
>
</BaseLayout>
</template>

<script>
export default {
    computed:{
        panels(){
            return [{
                x:0,y:0,w:18,h:5,control:'PanelEmpty'
            },{
                x:18,y:0,w:6,h:5,
                control:'PanelAccelerators'
            }]
        }
    }
}
</script>

<style>

</style>