<template>
  <div class="u-toolbar" 
    :style="`
        height:${height}px;
        padding-left:${gap}px;
        gap:${gap}px
        `
        ">
    <template v-for="(item,index) in validItems">
        <ToolbarItem 
            v-bind="item" 
            :key="item.key || 'key'+index" 
            :item-id="item.key"
            :height="height - 2*gap" 
            :counts="counts"
            :gap="gap" 
            v-model="localValue"
            @on-event="handleEvent"
            @input="$emit('input',$event)"
        />
    </template>
    <slot></slot>
  </div>
</template>

<script>
import ToolbarItem from "./toolbar-item"
import {cloneDeep} from 'lodash'
export default {
    components:{ToolbarItem},
    data(){
        return {
            localValue:cloneDeep(this.value)
        }
    },
    props:{
        height:{
            type:Number,
            default:40
        },
        items:{
            type:Array,
            default:()=>([])
        },
        counts:{
            type:Object,
            default:()=>({})
        },
        gap:{
            type:Number,
            default:5
        },
        value:{
            type:Object,
            default:()=>({})
        }
    },
    watch:{
        value:{
            handler(v){
                if(v)
                    this.localValue = cloneDeep(v)
            },
            deep:true,
            immediate:true
        }
    },
    computed:{
        validItems(){
            let items = cloneDeep(this.items)
            let id = 'Field-'+parseInt(Math.random()*10000);
            this.validateItems(items,id)
            return items
        }
    },
    methods:{
        validateItems(items,serial_id){
            if(Array.isArray(items)){
                items.forEach(s=>{
                    this.validateItem(s,serial_id)
                    serial_id++
                })
            }
            return serial_id
        },
        validateItem(item,serial_id){
            if(!item.key)
                item.key = serial_id
            serial_id++
            if(this.type == 'list')
                serial_id = this.validateItems(item.subs,serial_id)
            
            return serial_id
        },
        handleEvent(e){
            this.$emit('on-event',e)
        }
    }
}
</script>

<style lang="less">
.u-toolbar{
    
    position:relative;
    border-radius:2px;
    align-items: flex-stretch;
    position: relative;
    padding:0 5px;
    display: flex;
    align-items: center;
}
</style>