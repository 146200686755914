<template>
  <div class="container" style="background:var(--bg3);">
    <div class="flex-wrap" style="height:40px;padding:0 10px;border-bottom:1px solid var(--border)">  
      <div style="font-weight:bold;">平台数据管理</div>
      
    </div>
    <div style="padding:10px;border-bottom:1px solid var(--border)">
      
      <div class="flex-wrap flex-between">
        <div class="flex-wrap flex-col flex-center" style="width:100px">
          <div>用户</div>
          <div style="font-size:25px;font-weight:bold;color:var(--primary)">{{ data.user_count || 0 }} <span style="font-size:14px;padding:0 5px;background:var(--primary);color:var(--hover-text);border-radius:10px">{{data.user_logined_at_today || 0}}</span></div>
         
        </div>
        <div class="flex-wrap flex-col flex-center" style="width:100px">
          <div>企业</div>
          <div style="font-size:25px;font-weight:bold;color:var(--primary)">{{ data.ent_count || 0 }}</div>
         
        </div>
        <div class="flex-wrap flex-col flex-center" style="width:100px">
          <div>模块</div>
          <div style="font-size:25px;font-weight:bold;color:var(--primary)">{{ data.module_count ||0 }}</div>
         
        </div>
        <div class="flex-wrap flex-col flex-center" style="width:100px">
          <div>数据对象</div>
          <div style="font-size:25px;font-weight:bold;color:var(--primary)">{{ data.model_count || 0 }}</div>
         
        </div>
        <div class="flex-wrap flex-col flex-center" style="width:100px">
          <div>流程</div>
          <div style="font-size:25px;font-weight:bold;color:var(--primary)">{{ data.flow_count || 0}}</div>
         
        </div>
        <div class="flex-wrap flex-col flex-center" style="width:100px">
          <div>表单</div>
          <div style="font-size:25px;font-weight:bold;color:var(--primary)">{{ data.form_count || 0 }}</div>
         
        </div>
        
      </div>
      
      
    </div>
   

  </div>
</template>

<script>
  export default {
    props:['data']
  }
</script>

<style lang="scss" scoped>

</style>