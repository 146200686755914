<template>
  <div class="container" style="overflow-y:auto">
    <template v-if="small">
      <template v-for="m in menus">
      <div 
        :key="m.key" 
        class="menu-item menu-item-small" 
        :class="{
          'menu-item-actived':actived==m.key
        }" 
        @click="$emit('on-click',m.key)"
        >
        <Tooltip :content="m.name" position="right" transfer>
          <Badge :count="m.count">
            <BaseIcon :icon="m.icon || 'dot'" />
          </Badge>
        </Tooltip>
      </div>
    </template>
    </template>
    <template v-else>
      <template v-for="m in menus">
      <div 
        :key="m.key" 
        class="menu-item" 
        :class="{
          'menu-item-actived':actived==m.key
        }" 
        @click="$emit('on-click',m.key)"
        >
        <BaseIcon :icon="m.icon || 'dot'" style="margin-right:8px" />
        {{m.name}} <span v-if="m.count" style="margin-left:5px;color:var(--primary)">({{m.count}})</span>
      </div>
    </template>
    </template>
    
  </div>
</template>

<script>
export default {
  props: {
    menus: {
      type: Array,
      default: () => []
    },
    actived: {
      type: String,
    },
    small: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" scoped>
.menu-item {
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-bottom: 1px solid var(--border);
}

.menu-item-small{
  width:35px;
  padding:0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-item-actived {
  background: linear-gradient(to right,var(--primary),var(--active));

   color: var(--hover-text) !important;
   i{
     color:var(--hover-text);
   }
}
</style>