<style lang="less">
.l-projects-panel {
  color: var(--text2);
  height: 100%;
  width: 100%;
}

.l-name {
  color: var(--hover-text);
  font-weight: normal;
}
</style>
<template>
  <div style class="l-projects-panel">
    <div style="width:100%;height:30px;font-weight:bold;color:var(--subtext1)">
      <BaseIcon icon="md-apps" style="margin-right:5px" />我的项目</div>
    <template v-if="loading">
      <BaseLoading />
    </template>
    <template v-else-if="error">
      <BaseEmpty  style="background:#ffffff11;color:var(--subtext1)">
        加载失败:{{error}}
        <a @click="getData" style="color:var(--primary)">
          <Icon type="md-refresh"></Icon>重试
        </a>
      </BaseEmpty>
    </template>
    <template v-else>
      <BaseEmpty v-if="items.length == 0" style="background:#ffffff11;color:var(--subtext1)">
        您还没有关联项目
      </BaseEmpty>

      <template v-else>
        <div class="filter-secondary">
          <div
            class="fs-item"
            :class="{
            'fs-item-active':filter_group=='project-executing'}"
            @click="filter_group= (filter_group!='project-executing'?'project-executing':'')"
          >
            <div class="label">
              在建项目
              <div class="count">{{items.filter(v=>v.status=='准备中' || v.status == '进行中').length}}</div>
            </div>
          </div>
          <div
            class="fs-item"
            :class="{
            'fs-item-active':filter_group=='project-completed'}"
            @click="filter_group= (filter_group!='project-completed'?'project-completed':'')"
          >
            <div class="label">
              竣工项目
              <div class="count">{{items.filter(v=>v.status=='已竣工').length}}</div>
            </div>
          </div>
          <div
            class="fs-item"
            :class="{
            'fs-item-active':filter_group=='project-end'}"
            @click="filter_group= (filter_group!='project-end'?'project-end':'')"
          >
            <div class="label">
              历史项目
              <div class="count">{{items.filter(v=>v.status=='已结束' || v.status=='已中止').length}}</div>
            </div>
          </div>

        

          <div
            class="fs-item"
            :class="{
            'fs-item-active':filter_group=='watch'}"
            @click="filter_group= (filter_group!='watch'?'watch':'')"
          >
            <div class="label">
              项目关注
              <div class="count">{{session.focused.length}}</div>
            </div>
          </div>
        </div>
        <div
          class="l-panel-project-list"
          style="width:100%;height:calc(100% - 60px);background:#ffffff11;overflow-y:auto;align-content:flex-start;justify-content:flex-start;"
        >
          <template v-if="!filter_group || filter_group.indexOf('project') == 0">
            <template v-for="p in filteredItems">
              <div class="l-panel l-panel-project" :key="p.id" @click="openProject(p)">
                <div
                  class="l-panel-project-title flex-wrap"
                  :style="`${p.avatar?'color:#eee;':''}`"
                >
                  <div
                    class="flex-wrap flex-col align-start"
                    style="justify-content:flex-start;height:100%;width:100%;"
                  >
                  
                    <div
                      class="l-name"
                      style="height:30px;overflow:hidden;text-overflow:ellipsis;color:#fff;white-space:nowrap;font-size:16px;max-width:80%;"
                      :title="p.name"
                    >{{p.shortname || p.name}}</div>
                  </div>
                </div>

                <div
                  class="flex-wrap flex-between"
                  :style="`position:absolute;bottom:0;color:#333;z-index:10;width:100%;font-size:12px;margin-top:10px;padding:3px 5px;background:linear-gradient(to right,#33333366,#ffffff22) !important`"
                >
                <div
                      class="flex-wrap"
                      v-if="p.type"
                      style="font-size:12px;font-weght:bold;color:var(--hover-text);opacity:0.8"
                    >{{p.type}}  <div style="color:var(--active);margin-left:5px;font-size:10px;">{{p.position || '项目成员'}}</div>
</div>
                 
                  <div class="flex-wrap" :style="`color:var(--hover-text);;`">
                    <div
                      :style="`background:${statusColors[p.status]};box-shadow: 0 0 15px 5px ${statusColors[p.status]}55`"
                      :title="p.status"
                      style="width:20px;height:5px;border-radius:5px;margin-right:5px;"
                    ></div>
                  </div>
                </div>

                <img
                  class="l-panel-project-image"
                  width="100%"
                  :src="p.avatar || 'https://nbgz-pmis-1257839135.cos.ap-shanghai.myqcloud.com/timg.jpg'"
                />
              </div>
            </template>
          </template>

          <template v-if="!filter_group || filter_group == 'watch'">
            <template v-for="p in focused_items">
              <div class="l-panel l-panel-project" :key="p.id" @click="openProject(p)">
                <div
                  class="l-panel-project-title flex-wrap"
                  :style="`${p.avatar?'color:#eee;':''}`"
                >
                  <div
                    class="flex-wrap flex-col align-start"
                    style="justify-content:flex-start;height:100%;width:100%;"
                  >
                  <div
                      class="l-name"
                      style="height:30px;overflow:hidden;text-overflow:ellipsis;color:#fff;white-space:nowrap;font-size:14px;max-width:80%;"
                      :title="p.name"
                    >{{p.shortname || p.name}}</div>
                 
                   
                  </div>
                </div>

                
                <div
                  class="flex-wrap flex-between"
                  :style="`position:absolute;bottom:0;color:#333;z-index:10;width:100%;font-size:10px;height:25px;padding:0px 5px;background:linear-gradient(to right,#33333366,#ffffff22) !important`"
                >
                  <div class="flex-wrap" style="color:var(--primary)">
                    <div
                      v-if="p.type"
                      style="font-size:12px;font-weght:bold;color:var(--hover-text);opacity:0.8;margin-right:5px;"
                    >
                      {{p.type}}
                      
                    </div>
                    <BaseNameBoard :uid="p.charger_id" hide-avatar styles="color:var(--active);font-weight:normal;margin-bottom:3px" />
                  </div>

                 
                  <div class="flex-wrap" :style="`color:var(--hover-text);;`">
                    <div
                      :style="`background:${statusColors[p.status]};box-shadow: 0 0 15px 5px ${statusColors[p.status]}55`"
                      :title="p.status"
                      style="width:20px;height:5px;border-radius:5px;margin-right:5px;"
                    ></div>
                  </div>
                </div>

                <img
                  class="l-panel-project-image"
                  width="100%"
                  :src="p.avatar || 'https://nbgz-pmis-1257839135.cos.ap-shanghai.myqcloud.com/timg.jpg'"
                />
              </div>
            </template>
          </template>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import loadingMixin from "@/mixins/loading";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      items: [],
      focused_items: [],
      filter_group: "project-executing",
      statusColors: {
        准备中: "#66ddff",
        进行中: "#66ddff",
        已竣工: "#33ff33",
        已结束: "#aaaaaa",
        已终止: "#ff3333"
      }
    };
  },
  mixins: [loadingMixin],
  mounted() {
    this.getData();
    this.getFocused();
  },
  computed: {
    ...mapGetters("session", ["session"]),
    filteredItems() {
      if (this.filter_group == "project-executing") {
        return this.items.filter(
          v => v.status == "准备中" || v.status == "进行中"
        );
      } else if (this.filter_group == "project-completed") {
        return this.items.filter(v => v.status == "已竣工");
      } else if (this.filter_group == "project-end") {
        return this.items.filter(
          v => v.status == "已结束" || v.status == "已中止"
        );
      } else {
        return this.items;
      }
    },
    groups() {
      return this.session.groups
        ? this.session.groups.filter(v => v.namespaced)
        : [];
    },
    itemHeight() {
      return 38; //((this.$el.clientHeight - 40) % 35) / 35 + 35
    }
  },
  methods: {
    getData() {
      if (!this.startLoading()) return;
      this.$api
        .get("/projects?q=panel")
        .then(res => {
          let projects = res.data.data;
          const status_map = {
            准备中: 0,
            进行中: 1,
            已竣工: 2,
            已结束: 3,
            已中止: 4
          };
          projects.sort((a, b) => {
            return status_map[a.status] > status_map[b.status] ? 1 : -1;
          });
          this.items = projects;
        })
        .catch(this.setLoadingError)
        .finally(this.endLoading);
    },
    getFocused() {
      if (!this.startLoading()) return;
      this.$api
        .get("/projects?q=focused")
        .then(res => {
          this.focused_items = res.data.data;
        })
        .catch(this.setLoadingError)
        .finally(this.endLoading);
    },
    openProject(p) {
      let url = "/core/projects/" + p.id + "/dashboard";
      if (p.position && p.position.includes("合约")) {
        url = "/core/projects/" + p.id + "/cm";
      }
      this.$store.commit("session/push_project", { id: p.id, name: p.name });
      this.RouteTo(url, false);
    },
    openGroup(p) {
      let url = "/core/groups/" + p.id;
      this.$store.commit("session/push_group", { id: p.id, name: p.name });
      this.RouteTo(url, false);
    }
  },
  panelInfo: {
    name: "项目列表",
    minWidthSpan: 10,
    minHeightSpan: 5
  }
};
</script>
<style lang="less">
.l-panel-project-list {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: stretch;
  align-content: flex-start;

  .l-panel-project {
    height: 75px !important;
    align-items: flex-start;
    width: 100%;
    flex-shrink: 0;
    border-bottom:5px solid var(--text2);
  }

  .l-panel {
    height: 50px;
    width:100%;
    min-width: 200px;
    overflow: hidden;
    color: var(--hover-text);
    position: relative;
    display: flex;
    align-items: center;

    .l-panel-project-tag {
      background: var(--primary);
      opacity: 0.8;
      color: #fff;
      padding: 0 3px;
      margin-left: 5px;
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      border-radius: 7px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .l-panel-project-image {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      filter: brightness(0.5);
      transition: filter 0.3s ease;
    }
  }
}
.l-projects-panel {
  .filter-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
    height: 100px;
    border-bottom: 1px solid var(--border);
    .count {
      font-size: 20px;
      color: var(--primary);
    }
  }

  .filter-item-active {
    background: var(--active);
    color: var(--hover-text);
    .count {
      color: var(--hover-text);
    }
  }

  .filter-secondary {
    display: flex;
    flex-wrap: nowrap !important;
    width: 100%;

    .fs-item {
      font-size: 12px;
      text-align: center;
      color: var(--subtext1);
      min-width: 80px;
      border: 1px solid var(--border);
      background: #00000022;
      text-align: center;
      padding-top: 5px;
      padding-bottom: 5px;
      display: flex;
      justify-content: center;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      filter: brightness(0.95);
      .label {
        display: flex;
        align-items: center;
      }
      .count {
        margin-left: 5px;
      }
    }
    .fs-item:hover {
      filter: brightness(1);
      cursor: pointer;
      background:#ffffff11;
    }

    .fs-item-active,
    .fs-item-active:hover {
      color: var(--text1);
      background:#ffffff11;
      filter: none;
      box-shadow: 1px 1px 2px 1px var(--bg1);
      border: 1px solid var(--border);
      border-bottom: none;
    }
  }
}

.l-panel-group {
  width: calc(33% - 10px) !important;
}

.l-panel-project-title {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 65px;
  z-index: 5;
  font-size: 14px;
  color: var(--text1);
  flex-wrap: pre-wrap;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.l-panel-group-title {
  position: relative;
  z-index: 5;
  font-size: 16px;
  height: 40px;
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
}

.l-panel-project:hover {
  border-color: var(--primary);
  .l-panel-group-title {
    color: var(--primary);
  }

  .l-panel-project-image {
    filter: brightness(0.8);
  }
  cursor: pointer;
}

.l-panel-project-progress {
  position: absolute;
  width: 100%;
  text-align: right;
  padding-right: 10px;
  font-size: 14px;
  bottom: 0px;
  z-index: 10;
  background: linear-gradient(to right, #00000000, #dddddddd);
  top: 30px;
  align-items: flex-end;
  flex-wrap: nowrap;
  display: flex;
  align-content: flex-end;
  padding: 5px 10px;
}

.l-panel:hover {
  cursor: pointer;
  .l-panel-project-image {
    opacity: 0.5;
  }
}

.l-panel-count-selected {
  border-bottom: 3px solid var(--primary);
  color: var(--bg1) !important;
  i {
    color: var(--bg1) !important;
  }
  cursor: pointer;
}

.l-panel-count {
  height: 40px;
  width: calc(33.3% - 1px);
  margin: 0.5px;
  flex-grow: 1;
  flex-shrink: 1;
  margin-top: 0;
  color: var(--bg1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.l-panel-count:hover {
  cursor: pointer;
  filter: brightness(0.95);
}
</style>
