var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Row',[_c('Col',{attrs:{"span":12}},[_c('div',{staticStyle:{"background":"#fff","padding":"20px","min-height":"500px"}},[_c('BaseForm',{attrs:{"form":{
            layout:_vm.form.html,
            def:_vm.formDef,
           
            option:{
              hideTools:true
            }
          },"editable":"true"}})],1)]),_c('Col',{attrs:{"span":12}},[_c('div',{staticStyle:{"padding":"10px"}},[_vm._v(" HTML视图 "),_c('Button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$set(_vm.form,'html',_vm.html)}}},[_vm._v("渲染")]),_c('Input',{staticStyle:{"margin":"10px 0"},attrs:{"type":"textarea","rows":12},model:{value:(_vm.html),callback:function ($$v) {_vm.html=$$v},expression:"html"}}),_vm._v(" 可用数据域 "),_c('p',[_vm._v(" "+_vm._s(_vm.form.fields.map(v=>v.key || v.name).join(','))+" ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }