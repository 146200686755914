<template>
 <div
          style="padding:10px;"
        >
          <div class="flex-wrap flex-between">
            <div :style="`background:${task.finished?'var(--success)':'var(--primary)'};color:var(--hover-text);padding:1px 5px;border-radius:5px;`">任务{{ task.finished?'已完成':'进行中' }}</div>
            <div class="flex-wrap">
              <div
                class="flex-wrap flex-center"
                v-if="task.plan_started_at || task.created_at"
                :style="`height:24px;padding: 10px;color:var(--primary);font-size:13px;`"
              >计划开始 {{moment(task.plan_started_at || task.created_at).format("L")}}</div>
              <span v-if="(task.plan_started_at || task.created_at) && task.deadline"><BaseIcon icon="md-arrow-forward" /></span>
              <div
                class="flex-wrap flex-center"
                v-if="task.deadline"
                :style="`height:24px;padding: 10px;margin-right:10px;color:var(--primary);font-size:12px;`"
              >计划结束 {{moment(task.deadline).format("L")}}</div>
              <div
                class="flex-wrap flex-center"
                v-if="task.deadline"
                :style="`height:24px;padding: 10px 0;width:140px;border-radius:3px;border:1px solid ${moment(task.deadline).isAfter(moment())?'var(--primary)':'var(--error)'};background:linear-gradient(to right,#ffffff00,${moment(task.deadline).isAfter(moment())?'var(--primary)':'var(--error)'});margin-right:10px;font-size:12px;`"
              >
                <BaseIcon icon="clock" style="margin-right:5px" :color="moment(task.deadline).isAfter(moment())?'var(--primary)':'var(--error)'" />
                <span :style="`color:${moment(task.deadline).isAfter(moment())?'var(--primary)':'var(--error)'}`">
                {{moment(task.deadline).fromNow()}}
                </span>
                <span
                  :style="`color:var(--hover-text);margin-left:15px;font-size:12px;`"
                >{{ moment(task.deadline).isAfter(moment())?'截止':'已逾期' }}</span>
              </div>
            </div>
          </div>
          <div class="flex-wrap" style="margin-top:15px">
            负责人
            <BaseNameBoard :uid="task.charger_id || task.created_by" style="margin-left:15px" />

          </div>
          <div style="margin-top:15px;">
            任务详情描述
            <div style="max-height:400px;overflow-y:auto;border:1px solid var(--border);margin:10px 0;">
            <div
              style="padding:10px;background:var(--bg2);border:1px solid var(--border);border-radius:5px;min-height:100px;margin-top:5px;box-shadow:0 0 2px 1px var(--border);"
              v-html="task.note || '无'"
            ></div>
         </div>
            <div
              style="border-top:1px solid var(--border);border-radius:5px;min-height:100px;margin-top:10px;font-size:12px;padding:10px;"
            ><div class="flex-wrap">
                <div style="margin-right:10px;width:80px;">创建人</div>
                <BaseNameBoard :uid="task.created_by" />
              </div>
              <div class="flex-wrap" style="margin-top:2px;">
                <div style="margin-right:10px;width:80px;">创建时间</div>
                <div>{{ moment(task.created_at).format('YYYY-MM-DD HH:mm:ss') }}</div>
              </div>
              
              <div class="flex-wrap" style="margin-top:5px;">
                <div style="margin-right:10px;width:80px;">短信提醒</div>
                {{ task.sms?'开':'关' }}
              </div>
            </div>
          </div>
        </div>
</template>

<script>
export default {
    props:{
        task:{
            type:Object,
            default:()=>({})
        }
    }
}
</script>

<style>

</style>