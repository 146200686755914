<template>
 <FormItem :label="label" :required="required">
  <template v-if="editable">
      <DatePicker format="yyyy/MM/dd" v-model="formattedValue" transfer :placeholder="config?.placeholder || '请选择'" />
  </template>
  <template v-else>
    <div style="color:#aaa;min-width:140px;text-align:center;"> 
      {{this.value?moment(this.value).format("YYYY/MM/DD"):"未填写"}}
    </div>
    
  </template>
  
  </FormItem>
</template>

<script>
 import moment from 'moment'
 import defaultValueMixin from '../mixins/default-value'
  export default {
    mixins:[defaultValueMixin],
    cname:'日期',
    corder:31,
    icon:'xuanzeriqi',
    props:['value','label','config','editable','required'],
    properties:[{
        label:'提示文字',
        key:'placeholder',
        control:'Text',
      },],
    computed:{
      formattedValue:{
        get(){
          return this.value?new Date(moment(this.value).valueOf()):''
        },
        set(v){
          this.$emit('input',v?moment(new Date(v)).format("YYYY-MM-DD"):'')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>