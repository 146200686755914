<template>
    <div class="container" style="padding:10px">
      <div class="tool-box">
        <Input search style="width:220px" v-model="filter.searchText" clearable placeholder="请输入项目名称查询" />
        <Select placeholder="业务类型" v-model="filter.type" style="margin-left:10px;width:140px"  clearable >
          <template v-for="(r,i) in business_types">
            <Option :value="r" :key="i">{{r}}</Option>
          </template>
        </Select>
         <Select placeholder="项目状态" v-model="filter.status"  style="margin-left:10px;width:auto"  clearable multiple>
          <template v-for="(r,i) in status">
            <Option :value="r" :key="i">{{r}}</Option>
          </template>
        </Select>
        <Select placeholder="合同金额" v-model="filter.amount_region"  style="margin-left:10px;width:140px"  clearable >
          <template v-for="(r,i) in amount_regions">
            <Option :value="i" :key="i">{{r.name}}</Option>
          </template>
        </Select>
      </div>
      <div style="height:calc(100% - 40px);position:relative;margin-top:10px;">
        <BaseTable :columns="columns" :loading="loading" :data="items"  @event="handleTableEvent" />
      </div>
  
  
          <Modal
              v-model="showModalCharger"
              title="修改负责人"
              width="300"
              footer-hide
              :mask-closable="false"
          >
              <div>
                  <Form>
                      <FormItem label="负责人">
                          <BaseUserSelectGrouped
                              v-model="current.charger_id"
                              editable
                              :option="{ users }"
                          />
                      </FormItem>
                      <FormItem label="协助人">
                          <BaseUserSelectGrouped
                              v-model="current.assistants"
                              editable
                              :option="{ users, multiple: true }"
                          />
                      </FormItem>
                  </Form>
  
                  <div class="flex-wrap flex-right split10" style="margin-top: 10px">
                      <Button type="primary" @click="SaveCharger">提交</Button>
                      <Button @click="showModalCharger = false">取消</Button>
                  </div>
              </div>
          </Modal>
  
  
      <Modal v-model="showModalProgress" title="签约计划" width="1200" footer-hide>
        <BaseLoading v-if="loadingProgress"></BaseLoading>
        <div v-else class="flex-col align-start" >
          <h2>123{{current.name}}</h2>
          <ProjectProgress  :value="current" style="width:100%"  />
        </div>
      </Modal>
  
  
      <Modal v-model="showModalFocus" title="关注原因" width="600" footer-hide>
        <Input type="textarea" v-model="formData.focused_reason" clearable />
        <div class="flex-wrap flex-between" style="margin-top:10px">
          <div class="flex-wrap">
            级别
          <BaseMark v-model="formData.focused" style="margin-left:10px" editable></BaseMark>
          </div>
          
          <Button type="primary" @click="handlePatchFocused">提交</Button>
        </div>
      </Modal>
  
    </div>
  </template>
  
  <script>
  
  import { mapGetters } from 'vuex'
  import moment from 'moment'
  import ProjectProgress from "@/components/cm/Progress"
  import { intersection } from 'lodash'
  import UTIL from '@/utils'
  
  import CMR from '../render'
  export default {
    data() {
      return {
        items: [],
        loading: false,
        loadingProgress:false,
        current: {},
        filter:{
          searchText:"",
          status:["准备中","进行中"],
          amount_region:2
        },
        status: ["准备中", "进行中", "已竣工", "已结束", "已中止"],
        showModalProgress:false,
        showModalFocus: false,
        zzl_search_text: "",
        showContract: false,
        showModalTmpl: false,
        showModalDeps: false,
        page:1,
        pagesize:50,
        showModalAssistants: false,
        showModalCharger: false,
        status_colors:[],
        modified: null,
        showModalAdjust: false,
        total:{},
        order:{},
        formData: {},
        business_types: ['全过程咨询', '项目管理', '房建监理', '市政监理', '造价咨询', 'BIM咨询', '招标代理', '对外合作', '其他'],
        business_type_colors: ['orange', '#369', 'green', 'darkred', 'purple', '#3af', 'grown', 'pink', '#333'],
      }
    },
    mounted() {
      this.getData()
      this.$store.dispatch("session/getUsers")
      this.$store.dispatch("session/getDeps")
    },
    components:{ProjectProgress},
    methods: {
      getData() {
        this.loading = true
          this.$api.post('cm/projects/check',{
            
            page:this.page,
            search:this.searchText,
            pagesize:this.pagesize,
            order:this.order,
            filter:this.filter,
            role:this.my_role.id,
            fields:['id','type','charger_id','assistants','updated_at','origin_plan','current_plan','bill_plans','hr','code','name','amount','status','started_at','completed_at','errors'],
          }).then(res=>{
            let result = res.data.data
            this.items = result.items
            this.total = result.total
  
  
  
          }).catch(e=>{
            this.Error(e)
          }).finally(()=>{
            setTimeout(()=>{
              this.loading = false
            },200)
          })
      },
      handleTableEvent(e){
          if(e.type == 'page-change'){
            this.page = e.data
            this.getData()
          }else  if (e.type == 'open') {
        this.$store.commit("push_project_cm",e.data)
          this.RouteTo('/core/projects/' + e.data.id + '/cm')
        }else if(e.type == 'edit-name'){
            this.model = e.data
            this.formData = {shortname:e.data.shortname || ''}
            this.showShortNameEditor = true
          }else if(e.type == 'page-size-change'){
            this.pagesize = e.data
            this.getData()
          }else if(e.type == 'sort'){
            if(e.data && e.data.key){
              
              this.order = e.data
              this.page = 1
              this.getData()
            }else{
              this.order = {}
              this.getData()
            }
          }
       
      },
      handlePatchFocused() {
        this.$api.patch(`projects/${this.formData.id}`, {
          focused: this.formData.focused,
          focused_reason: this.formData.focused_reason
        }).then(res => {
          this.showModalFocus = false
  
          let updateInfo = res.data.data
          Object.assign(updateInfo,{
            id: this.formData.id,
            focused: this.formData.focused,
            focused_reason: this.formData.focused_reason
          })
          this.updateItem(updateInfo)
          this.formData = {}
          this.$Notice.success({ title: "修改成功" })
        })
      },
      updateItem(item) {
              let index = this.items.findIndex((v) => v.id == item.id);
              if (index != -1)
                  this.items.splice(index, 1, Object.assign(this.items[index], item));
              else this.items.splice(0, 0, item);
          },
      handlePatchAdjustAmount() {
        this.$api.patch(`projects/${this.formData.id}`, {
          adjust_amount: this.formData.adjust_amount
        }).then(res => {
          this.showModalAdjust = false
  
          let updateInfo = {
            id: this.formData.id,
            adjust_amount: this.formData.adjust_amount,
            amount: this.formData.signed_amount + this.formData.adjust_amount
          }
  
          this.$store.commit('cm/update_project', updateInfo)
          this.formData = {}
          this.$Notice.success({ title: "修改成功" })
        })
      },
      handleSelectFilter(f, v) {
        if (Array.isArray(this.selected_filters[f])) {
          let index = this.selected_filters[f].findIndex(t => t == v)
          if (index != -1)
            this.selected_filters[f].splice(index, 1)
          else
            this.selected_filters[f].push(v)
        } else
          this.$set(this.selected_filters, f, [v])
      },
      inArray(array, sub) {
        if (Array.isArray(array) && Array.isArray(sub) && array.length > 0 && sub.length > 0 && sub.length <= array.length) {
          for (let i = 0; i < sub.length; i++)
            if (!array.includes(sub[i]))
              return
          return true
        }
      },
      outArray(array, sub) {
        if (Array.isArray(array) && Array.isArray(sub) && array.length > 0 && sub.length > 0) {
          for (let i = 0; i < sub.length; i++)
            if (array.includes(sub[i]))
              return false
          return true
        }
      },
      handleOpen(e) {
        let project = this.items.find(v=>v.id == e.id)
        this.$store.commit("session/push_project_cm",project)
        this.RouteTo('/core/projects/' + e.id + '/cm')
      },
      handleClearGroup(g) {
        if (!g)
          this.selected_cols = []
        else
          this.selected_cols = this.selected_cols.filter(v => !g.items.map(t => t.key).includes(v))
      },
      handleSelectAllGroup(g) {
        g.items.forEach(v => {
          if (!this.selected_cols.includes(v.key))
            this.selected_cols.push(v.key)
        })
      },
      handleSelectColumn(key) {
        let index = this.selected_cols.findIndex(v => v == key)
        if (index == -1)
          this.selected_cols.push(key)
        else
          this.selected_cols.splice(index, 1)
      },
      handleTableEvent(e) {
        if (e && e.type == 'open') {
          let baseurl = '/core/projects/' + e.data.id
          // this.$store.commit('session/pushProcess', { name: e.data.name, custom: 'jianzhu', url: baseurl + '/cm', baseurl })
          this.RouteTo(baseurl + '/cm')
  
        }
      },
      EditCharger(item) {
        this.current = {
          id: item.id,
          charger_id: item.charger_id,
          assistants: item.assistants ? [...item.assistants] : []
        }
        this.showModalCharger = true
      },
      SaveCharger() {
        let item = this.current
        this.$api.patch('projects/' + item.id, { charger_id: item.charger_id, assistants: item.assistants }).then(res => {
          this.$Notice.success({
            title: "修改成功"
          })
          let updateInfo = res.data.data
          updateInfo.id = item.id
          updateInfo.charger_id = item.charger_id
          updateInfo.assistants = item.assistants
          let index = this.items.findIndex(v => v.id == item.id)
          if (index != -1)
            this.items.splice(index, 1, Object.assign(this.items[index], updateInfo))
          this.showModalCharger = false
          this.current = {}
        })
      },
      EditDeps(item) {
        this.showModalDeps = true
        this.current = {
          id: item.id,
          deps: item.deps
        }
      },
      SaveDeps() {
        let item = this.current
        this.$api.patch('projects/' + item.id, { deps: item.deps }).then(res => {
          this.$Notice.success({
            title: "修改成功"
          })
          let updateInfo = res.data.data
          Object.assign(item, updateInfo)
          this.$store.commit('cm/update_project', item)
          this.showModalDeps = false
          this.current = {}
        })
      },
    },
    computed: {
      ...mapGetters('session', ['session', 'users']),
      ...mapGetters('cm',['my_role']),
      amount_regions(){
          return [{
            name:"50万元以下",
            check:v=>v < 500000
          },{
            name:"50万以上",
            check:v=>v >= 500000
          },{
            name:"100万以上",
            check:v=>v >= 1000000
          },{
            name:"200万以上",
            check:v=>v >= 2000000
          },{
            name:"500万以上",
            check:v=>v >= 5000000
          }]
        },
      filteredData(){
          return this.items.filter(v=>{
            let searchText = this.filter.searchText.trim()
            if(searchText && !v.name.includes(searchText)){
              return false
            }
  
            if(this.filter.type && v.type != this.filter.type)
              return false
  
            if(this.filter.amount_region != undefined && !this.amount_regions[this.filter.amount_region].check(v.full_amount))
              return false
            
            if(this.filter.status.length > 0 && !this.filter.status.includes(v.status))
              return false
  
  
            return true
          })
        },
      deps() {
        if (this.session.groups)
          return this.session.groups.filter(v => v.namespaced)
        else
          return []
      },
      columns() {
        var that = this
        return [ {
          type: 'text',
          key: "code",
          title: "编号",
          width: 70,
          group: '项目',
          option: {
  
            align: "center",
          }
        }, {
          type: 'text',
          title: "业务类型",
          key: "type",
          group: '项目',
          width: 100,
          option: {
  
            align: "center",
          },
          render: (h, { row }) => {
            return h('div', { class: "flex-wrap flex-center", style: { height: "40px" } }, [h('div', {            style: {              width: "70px",
                borderRadius: "5px",
                textAlign: "center",
                background: this.business_type_colors[this.business_types.findIndex(v => v == row.type)],
                color: "#fff"
              }          }, row.type)])
          }
        }, {
          type: 'text',
          title: "名称",
          tree: true,
          key: "name",
          minWidth: 240,
          render:CMR.renderName(this.handleOpen)
        },  {
          type: 'number',
          title: "总金额",
          key: "full_amount",
          width: 120,
          group: '合约',
          option: {
            sumable: true,
            formatter: e => UTIL.formatSalary(e),
            type: "fullAmount"
          },
        }, {
                      type: "text",
                      title: "状态",
                      key: "status",
                      width: 80,
                      option: {
                          align: "center",
                      },
                      render: (h, { row }) => {
              
              const states = ["准备中", "进行中", "已竣工", "已结束", "已中止"]
              const status_colors = {
                "准备中":"#aaa",
                "进行中":"var(--primary)",
                "已竣工":"var(--active)",
                "已结束":"var(--success)",
                "已中止":"var(--error)"
              }
                let iconArrow = h("BaseIcon",{props:{icon:"ios-arrow-down"},style:"margin-left:3px;"})
                          let domContent = h(
                              "div",
                              { class: "flex-wrap flex-center", style: { height: "40px" } },
                              [
                                  h(
                                      "div",
                                      {
                                          style: {
                                              width: "65px",
                                              borderRadius: "50px",
                                              textAlign: "center",
                                              background:status_colors[row.status],
                                              color: "#fff",
                                          },
                                      },
                                      [row.status,iconArrow]
                                  ),
                              ]
                          );
  
                          let domListItems = states.map((v, i) =>
                              h(
                                  "DropdownItem",
                                  { props: { name: v, selected: row.status == v } },
                                  v
                              )
                          );
                          let domList = h("DropdownMenu", { slot: "list" }, domListItems);
                          let domDropDown = h(
                              "Dropdown",
                              {
                                  props: { trigger: "click", size: "small" },
                                  on: {
                                      "on-click": (status) => {
                                          this.$api
                                              .patch("/projects/" + row.id, { status })
                                              .then((res) => {
                                                  let updateInfo = res.data.data;
                                                  updateInfo.status = status;
                                                  updateInfo.id = row.id;
                                                  this.updateItem(updateInfo);
                                                  this.$Notice.success({
                                                      title: "修改成功",
                                                      content: status,
                                                  });
                                              });
                                      },
                                  },
                              },
                              [domContent, domList]
                          );
                          return domDropDown;
                      },
                  },	{
          type:"time",
          title:"服务起始",
           key: "started_at",
          width:100,
          render:(h,{row})=>{
            if(row.started_at)
              return h("a",moment(row.started_at).format("YYYY-MM-DD"))
            else
              return h("span",{style:"color:var(--subtext3)"},'无')
          }
        },{
          type:"time",
          title:"竣工时间",
           key: "completed_at",
          width:100,
          render:(h,{row})=>{
            if(row.completed_at)
              return h("a",moment(row.completed_at).format("YYYY-MM-DD"))
            else
              return h("span",{style:"color:var(--subtext3)"},'无')
          }
        },{
          type: 'time',
          group: "管理",
          title: "总控计划",
          key: "progress_updated_at",
          width: 100,
          render:(h,{row})=>{
            if(row.progress_updated_at)
              return h("a",{on:{click:e=>{
                this.loadingProgress = true
                this.showModalProgress = true
                this.$api.get(`/cm/projects/${row.id}?q=origin-plan`).then(res=>{
                  this.current = res.data.data
                }).finally(()=>{
                  this.loadingProgress = false
                })
                
              }}},moment(row.progress_updated_at).format("YYYY-MM-DD"))
            else
              return h("span",{style:"color:var(--subtext3)"},'无')
          }
        },{
          type: 'time',
          group: "管理",
          title: "收费计划",
          key: "billplan_updated_at",
          width: 100,
          render:(h,{row})=>{
            if(row.billplan_updated_at)
              return h("a",moment(row.billplan_updated_at).format("YYYY-MM-DD"))
            else
              return h("span",{style:"color:var(--subtext3)"},'无')
          }
        },{
          type:"time",
          title:"人员记录",
           key: "hr_updated_at",
          width:100,
          render:(h,{row})=>{
            if(row.hr_updated_at)
              return h("a",moment(row.hr_updated_at).format("YYYY-MM-DD"))
            else
              return h("span",{style:"color:var(--subtext3)"},'无')
          }
        },{
          type:"time",
          title:"建安工程量",
           key: "bp_updated_at",
          width:100,
          render:(h,{row})=>{
            if(row.bp_updated_at)
              return h("a",moment(row.bp_updated_at).format("YYYY-MM-DD"))
            else
              return h("span",{style:"color:var(--subtext3)"},'无')
          }
        },{
          type:"number",
          title:"检查",
          key:"error_count",
          width:100,
          render:(h,{row})=>{
            let errors = row.errors
            if(errors.length > 0){
              let dropDownItem = h("span",{style:"padding:1px 5px;background:darkred;color:var(--hover-text);border-radius:15px"},[h("BaseIcon",{props:{icon:'md-close'},style:"margin-right:2px"}),errors.length])
              let dropDownContent = h("DropdownMenu",{slot:"list",style:"text-align:left"},errors.map((e,i)=>h('div',{style:'border-bottom:1px dashed var(--border);padding:2px 5px;'},(i+1)+' - '+e)))
              return h("Dropdown",[dropDownItem,dropDownContent])
              return 
            }else{
              return h('span',{style:"background:var(--success);padding:2px 3px;border-radius:50%;color:var(--hover-text)"},[h("BaseIcon",{props:{icon:"md-checkmark",size:11}})])
            }
          }
        }, {
                      key: "charger_id",
            type:'text',
                      group: "管理",
                      title: "负责/协助人",
                      width: 200,
                      render(h, { row }) {
                          let domEdit = h("BaseIcon", {
                              props: {
                                  icon: "md-create",
                              },
                              class: "edit-icon",
                              on: {
                                  click() {
                                      that.EditCharger(row);
                                  },
                              },
                              style: {
                                  marginLeft: "5px",
                              },
                          });
                          let domName = h("BaseNameBoard", {
                              props: {
                                  uid: row.charger_id,
                              },
                style:'flex-shrink:0'
                          });
                          let domAssistants = h("BaseNameBoards", {
                              props: { uids: row.assistants, maxShow: 2 },
                          });
                          let Divider = h("Divider", {
                              props: { type: "vertical" },
                              style: "",
                          });
                          let domEmpty = h(
                              "div",
                              { style: "color:var(--subtext3);width:30px;text-align:center;" },
                              "无"
                          );
                          return h(
                              "div",
                              { class: "flex-wrap flex-left", style: { padding: "0 10%" } },
                              [
                                  row.charger_id ? domName : domEmpty,
                                  Divider,
                                  row.assistants && row.assistants.length > 0
                                      ? domAssistants
                                      : domEmpty,
                                  domEdit,
                              ]
                          );
                      },
                  }, {
          key: "mod_adjust",
          title: "操作",
          group: "管理",
          width: 100,
          render: (h, { row }) => {
            return h("Button",{props:{size:"small",disabled:row.last_sms_at && moment(row.last_sms_at).isAfter(moment().startOf("day"))},on:{click:()=>{
              this.$api.post("cm/projects/sms-oa",{
                projects:[row.id],
                mods:['hugou']
              }).then(res=>{
                let updateInfo = res.data.data
                updateInfo.id = row.id
                this.updateItem(updateInfo)
              })
            }}},row.last_sms_at && moment(row.last_sms_at).isAfter(moment().startOf("day"))?'已通知':'手动通知')
  
          }
        }, {
          key: "updated_at",
          title: "最近更新",
          group: "管理",
          width: 80,
          type: 'time'
        },]
      }
    }
  }
  </script>
  
  <style lang="less">
  </style>