import cloneDeep from 'lodash/cloneDeep'
const o = {}
let globalIndex = 0
o.init_model = (selected)=>{
  let id = globalIndex
  globalIndex ++
  let item = {
    id: "local" + id,
      name: "新计划" + (id || ""),
  }
  if(selected && selected.id)
    item.parent_id = selected.id
  return item
}


o.actions = {
  add:{
  
    tool:{
      icon: "md-add",
      name: "创建新计划",
      hotkey: "VK_INSERT",
    },
    name:"创建子计划",
    do(state) {
      let tasks = state.tasks
      let selected = tasks.find(v=>v.id == state.selected_id)
      if(!selected)
        throw new Error("no selected can not create task")
      let data = o.init_model(selected)
      tasks.push(data)
      return data
    },
    undo(state, data) {
      let index = state.tasks.findIndex(v => v.id == data.id)
      state.tasks.splice(index, 1)
      if(state.selected_id == data.id){
        state.selected_id = null
      }
    }
  },
  patch:{
    name:"修改计划",
    do({
      tasks,
      selected_id,
    },data){
      let selectedIndex = tasks.findIndex(v=>v.id == selected_id)
      if (!selectedIndex == -1)
        throw new Error("no selected can not create task")
      let oldItem = tasks[selectedIndex]
      let replacedData = {}
      for(let key in data){
        replacedData[key] = cloneDeep(oldItem[key])
      }
      let newItem = Object.assign({}, tasks[selectedIndex], data)
      tasks.splice(selectedIndex,1,newItem)
      replacedData.id = oldItem.id
      return replacedData
    },
    undo({
      tasks,
    }, data) {
      let selectedIndex = tasks.findIndex(v => v.id == data.id)
      if (!selectedIndex == -1)
        throw new Error("no selected can not create task")
      let oldItem = tasks[selectedIndex]
      let replacedData = {}
      for (let key in data) {
        replacedData[key] = cloneDeep(oldItem[key])
      }
      let newItem = Object.assign({}, tasks[selectedIndex], data)
      tasks.splice(selectedIndex,1,newItem)
      replacedData.id = oldItem.id
      return replacedData
    }
  },
  remove:{
    name:"删除计划",
    tool: {
      icon: "md-remove",
        name: "删除",
      hotkey: "VK_DELETE",
    },
    do(state){
      let tasks = state.tasks
      let index = tasks.findIndex(v=>v.id == state.selected_id)
      if(index == -1)
        throw new Error("you can not remove an unexist one")
      
      
      let removed = [tasks[index]]
      let children = tasks.filter(t=>removed.map(v=>v.id).includes(t.parent_id))
      while(children.length > 0){
        removed = removed.concat(children)
        children = tasks.filter(t=>children.map(v=>v.id).includes(t.parent_id))
      }
      state.tasks = tasks.filter(t=>removed.map(v=>v.id).includes(t.id) == false)
      state.selected_id = null
      return removed

    },
    undo(state,data){
      state.tasks = state.tasks.concat(data)
      state.selected_id = data[0].id
    }
  }
}

o.compute = (tasks)=>{
  let computedTasks=  cloneDeep(tasks)
  computedTasks.sort((a,b)=>a.name > b.name?1:-1)
  computedTasks.forEach(v=>{
    v.subs = computedTasks.filter(t=>t.parent_id == v.id)
  })
  return computedTasks
}

export default o