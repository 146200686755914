

<template>
<App class="task-editor">
   <AppBar>
      <div class="flex-wrap">
        <TaskMenu />
        <TaskFileName v-model="root" />
      </div>
      <div class="flex-wrap">
        
        <IconButton icon="md-undo" :disabled="!can_undo" @on-click="undo" />
        <IconButton icon="md-redo" :disabled="!can_redo" @on-click="redo" />

        <template v-if="actions.length > 0">
          <Divider type="vertical" />
          <template v-for="a in actions">
            <IconButton :key="a.name" :icon="a.icon" @on-click="$store.commit('task/do',{action:a.action})" />
          </template>
        </template>
        <Divider type="vertical" />
        <IconTabs :items="views" :current="view" @on-select="view=$event" />
        
      </div>
      <div class="flex-wrap split5">
        <AppAvatar />
        <Divider type="vertical" />
        <Button type="primary" @click="RouteTo('dashboard')">保存并上传</Button>
      </div>
    </AppBar>
   <AppMask v-if="!is_ready" />
    <TaskLeft />
    <AppRight :collapse="!is_ready || !showHelper">
      <div style="height:230px">
      <div style="height:30px;background:var(--bg2);border-bottom:1px solid var(--border);padding:2px;font-weight:bold;color:var(--subtext2);" class="flex-wrap">
        <div style="width:22px;height:22px;background:var(--subtext3);margin:0 5px;border-radius:3px;" class="flex-wrap flex-center">
          
        <BaseIcon icon="ios-apps" color="#fff" :size="20" />
        
        </div>
        常用操作
      </div>
      <div class="flex-wrap flex-line" style="height:200px;align-content:flex-start;align-items:flex-start;padding:3px;">
        <div class="flex-col flex-center align-center itask-action-button" @click="showDrawerInput=true">
          <BaseIcon icon="md-create" :size="20" style="margin-bottom:5px;"></BaseIcon>
          记录
        </div>
        <div class="flex-col flex-center align-center itask-action-button">
          <BaseIcon icon="edit" :size="20" style="margin-bottom:5px;"></BaseIcon>
          配置
        </div>
      </div>
      </div>
      <div style="height:calc(100% - 560px)">
      <div style="height:30px;background:var(--bg2);border-bottom:1px solid var(--border);padding:2px;font-weight:bold;color:var(--subtext2);" class="flex-wrap">
        <div style="width:22px;height:22px;background:var(--subtext3);margin:0 5px;border-radius:3px;" class="flex-wrap flex-center">
          
        <BaseIcon icon="ios-help" color="#fff" :size="20" />
        
        </div>
        任务说明
      </div>
      <BaseEmpty v-if="!selected.help" />
      <div class="pm-help" v-html="selected.help" style="padding:10px;white-space:pre-wrap;"></div>
      <div style="height:30px;background:var(--bg2);border-bottom:1px solid var(--border);padding:2px;font-weight:bold;color:var(--subtext2);" class="flex-wrap">
        <div style="width:22px;height:22px;background:var(--subtext3);margin:0 5px;border-radius:3px;" class="flex-wrap flex-center">
          
        <BaseIcon icon="ios-clock"  color="#fff" :size="18" /> 
        </div>
        操作历史
      </div>
      <div styel="height:200px;overflow-y:auto;">
        
        <template v-for="(a,i) in history">
          <Row class="flex-wrap" :key="a.id" :style="historyIndex >= i?'':'color:var(--subtext2);filter:grayscale(1)'" style="font-size:10px;border-bottom:1px solid var(--border);background:linear-gradient(to right,var(--bg2),var(--bg3))">
            <Col :span="6" pull="1" align="right" style="padding:0 5px;color:var(--success);">{{ moment(a.created_at).fromNow() }}</Col>
            
            <Col :span="18">{{ a.name }}</Col>
          </Row>
        </template>
        
      </div>
      
      </div>
    </AppRight>
    
    <AppClient :collapse-left="is_ready && showMenu" :collapse-right="is_ready &&showHelper" v-if="is_ready">
      <Drawer placement="bottom" inner :transfer="false" v-model="showDrawerInput" :closable=false styles="border:1px solid var(--border);">
        <Input type="textarea" :rows="4" />
        <div class="flex-wrap flex-right" style="margin-top:10px;">
          <Button type="primary">提交</Button>
          <Button style="margin-left:10px;">取消</Button>
        </div>
      </Drawer>
      <transition name="fade">
        <template v-if="view == 'task'">
          <div class="container" style="background:var(--bg3);border-left:1px solid var(--border);border-right:1px solid var(--border)">
            
            <TaskEntity :task="selected" @event="handleTaskEvent" />
          </div>
        </template>
        <template v-else-if="view == 'flow'">
          Flow
        </template>
        <template v-else-if="view == 'gantte'">
          <div>
            Gannte
          </div>
        </template>
        <template v-else-if="view == 'file'">
          <div>
            File
          </div>
        </template>
        <template v-else-if="view == 'share'">
          
          <TaskShare v-model="selected" />
          
        </template>
      </transition>

    </AppClient>

  </App>
</template>

<script>
import responsableMixin from '@/mixins/responsable'
import {mapGetters} from 'vuex'
import App from '@/components/app/app'
import AppBar from '@/components/app/bar'
import AppMask from '@/components/app/mask'
import TaskLeft from '@/components/task/editor/left'
import AppRight from '@/components/app/right'
import AppAvatar from '@/components/app/avatar'
import AppClient from '@/components/app/client'
import IconButton from '@/components/app/IconButton'
import IconTabs from '@/components/app/IconTabs'
import TaskMenu from '@/components/task/app/bar/TaskMenu'
import TaskFileName from '@/components/task/app/bar/TaskFileName'
import TaskEntity from '@/components/task/Entity'
export default {
  components: { App, AppBar,AppMask,TaskLeft,AppRight,AppClient,AppAvatar, IconButton,IconTabs, TaskMenu, TaskFileName,TaskEntity},
  data(){
    
    return {
      loading: false,
      showMenu: true,
      showDrawerInput:false,
      view: 'task',
      task:{
        name:"新任务"
      },
    }
  },
  mixins:[responsableMixin],
  watch:{
    $route:{
      handler(v){
        this.$store.dispatch("task/load",this.$route.params.id)
      },
      immediate:true,
      deep:true
    }
  },
  activated(){
     this.$nextTick(e=>{
       this.calcLayout()
    })
  },
  computed:{
    ...mapGetters("task",['selected','tasks','root','actions','is_ready','root','can_undo','can_redo','historyIndex','history']),
    showHelper(){
      return this.selected && this.selected.id
    },
     views() {
      const _views = [{
        key: 'task',
        name: "执行",
        icon: "md-create",
      }, {
        key: "flow",
        name: "流程",
        icon: "lianjieliu",
      }, {
        key: "gantte",
        name: "管理",
        icon: "xiangmu",
      }, {
        key: "file",
        name: "资料",
        icon: "folder",
      }, {
        key: "share",
        name: "分享",
        icon: "share1",
      }]
      let o = _views
      if (!this.selected.flow) {
        o = o.filter(v => v.key != 'flow')
        if (!this.subs || this.subs.length == 0)
          o = o.filter(v => v.key != 'gantte')
      }

      return o
    },
   
    admin() {
      return this.role == 'admin'
    },
    permissions() {
      if (this.role == 'admin') {
        return true
      } else return {

      }
    }
  },
  methods:{
    undo(){
      this.$store.commit('task/undo')
    },
    redo(){
      this.$store.commit('task/redo')
    },
    handleTaskEvent(e){

    }
  }
}
</script>

<style lang="less">
.pm-panel-flex{
  display: flex;
}
.pm-panel-editing-wrap:hover{
  filter:brightness(1.2);
  cursor: pointer;
}

.pm-panel-selected{
  background:#fbbd2162 !important;
}

.itask-action-button{
  width:60px;height:60px;background:var(--bg2);margin:5px;border-radius:10px;box-shadow:1px 1px 2px 1px var(--border);padding-top:5px;
  font-size:12px;
  font-weight: bold;
  color:var(--subtext2);
  cursor: pointer;
  transition:all 0.3s;
  border:1px solid var(--border);
}

.itask-action-button:hover{
  filter:brightness(1.05);
}

.itask-action-button:active{
  position: relative;
  right:-1px;
  bottom:-1px;
}
</style>