<template>
  <div class="flex-col align-center num-board" 
    :class="{
      'num-board-hoverable':hoverable,
      'num-board-reverse':reverse
    }"
    :style="{
      alignItems:align
    }"
    >
   
    <div class="l-value" :class="empty || !value?'l-value-empty':''" :style="`${valueStyle}`" v-if="!empty && value !== undefined">
      {{fixed !== undefined && value?value.toFixed(fixed):value}}<span class="l-unit" v-if="unit">{{unit}}</span>
    </div>
     <div class="l-name" :style="`${labelStyle}`">{{name}} </div>
  </div>
</template>

<script>
  export default {
    props:{
      name:{
        type:String,
      },
      value:{
        type:Number | String
      },
      fixed:{
        type:Number
      },
      unit:{
        type:String
      },
      empty:{
        type:Boolean
      },
      hoverable:{
        type:Boolean
      },
      labelStyle:{
        type:String
      },
      valueStyle:{
        type:String
      },
      reverse:{
        type:Boolean,
        default:false
      },
      align:{
        type:String,
        default:"center"
      }
    }
  }
</script>

<style lang="less">
.num-board{
  .l-name{
    font-size:14px;
    flex-wrap:nowrap;
    
    color:var(--subtext3);
  }

  .l-value{
    font-size:28px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    
    color:var(--text3);
  }

  .l-unit{
    font-size:12px;
    margin-left:5px;
    color:var(--subtext3);
  }
}

.num-board-reverse{
  flex-direction: column-reverse !important;
  align-items: center;
  .l-name{
    font-size:14px;
    line-height:15px;
    color:var(--subtext3);
  }

  .l-value{
    margin-top:2px;
    font-size:22px;
    color:var(--text3);
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif,'黑体';
   
  }

  .l-value-empty{
    color:var(--text3);
    opacity: 0.5;
  }

}

.num-board-hoverable:hover{
  color:var(--primary);
  cursor: pointer;
  .l-name{
    color:var(--primary);
  }
}

.num-board-hoverable:active{
  position: relative;
  right:-1px;
  bottom:-1px;
}
</style>