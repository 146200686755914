<style lang="less">
.pm-editor {
  .l-node-selected {
    .l-node-inner {
       background: var(--primary);
      .l-node-text {
        color: var(--hover-text) !important;
      }

      .l-node-sub-list {
        background: var(--bg2);
      }
    }

    .l-node-text {
      background: none;
    }
  }

  .l-node-selected + .l-node-sub-list {
    .l-node-inner {
      background: #e7f5fc;
      .l-node-text {
        background: none !important;
      }
    }
  }
}
</style>
<template>
  <AppLeft :collapse="!is_ready">
    <div class="flex-wrap" style="height: 40px; padding: 0 10px">
      <Input search size="small" clearable v-model="search_text" />
    </div>
    <div style="height: calc(50% - 40px); border-top: 1px solid #eee;">
      <BaseTree
        ref="tree"
        :data="filteredTasks"
        style="height: 100%;overflow-y:auto;"
        :nodeRenderer="renderTreeNode"
        @event="handleTreeEvent"
      />
    </div>
    <div style="height:calc(50%);border-top: 1px solid #eee;">
        <Toolbox :items="tools" />
    </div>
  </AppLeft>
</template>

<script>
import AppLeft from "@/components/app/left";
import GhostInput from "@/components/app/GhostInput";
import Toolbox from './components/toolbox'
import { pick } from "lodash";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_text: "",
      edit_text: "",
      editing: false,
      nodes: [],
      tools:[
        {key:'node',name:'业务节点',icon:'md-list'},
        {key:'select',name:'选择节点',icon:'lianjieliu'},
        {key:'condition',name:'条件节点',icon:'md-add'},
        {key:'auto',name:'自动节点',icon:'md-apps'},
      ]
    };
  },
  components: { AppLeft, GhostInput,Toolbox },
  computed: {
    ...mapGetters("flow", [
      "selected",
      "is_ready",
      "current_view",
      "computedTasks"
    ]),
    filteredTasks() {
      return this.nodes.filter(v => {
        let search_text = this.search_text ? this.search_text.trim() : "";
        if (search_text && (!v.name || !v.name.includes(search_text)))
          return false;
        return true;
      });
    }
  },
  watch: {
    computedTasks: {
      handler(v) {
        this.nodes = this.computedTasks.map(v =>
          pick(v, ["id", "name", "parent_id", "state"])
        );
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    renderTreeNode(h, node) {
      var that = this;
      let value =
        that.editing && node.id == that.selected.id
          ? that.edit_text
          : node.name;
      let tools = node.control
        ? []
        : [
            h("BaseIcon", {
              props: { icon: "dot", size: "15", color: "#aaa" },
              style: "position:absolute;right:0;"
            })
          ];
      let nodeName = h("BaseGhostInput", {
        props: {
          value,
          maxWidth: 120
        },
        on: {
          input: e => {
            that.edit_text = e;
          },
          focus: e => {
            this.timerFocues = setTimeout(() => {
              that.$refs.tree.draggable = false;
              that.$refs.tree.clearable = false;
              that.edit_text = e.target.value;
              that.editing = true;
            }, 200);
          },
          blur: e => {
            if(this.timerFocues)
              clearTimeout(this.timerFocues)
            if(false == this.editing)
              return;
            that.$refs.tree.draggable = true;
            that.$refs.tree.clearable = true;
            let value = e.target.value
            that.editing = false;
            if (node.name !== value) {
              node.name = value;
              that.$store.commit("task/do", {
                action: "patch",
                data: { name: value }
              });
            }
            that.edit_text = "";
          }
        }
      });
      return h(
        "div",
        { class: "flex-wrap flex-between", style: "height:25px;" },
        [nodeName, tools]
      );
    },
    handleTreeEvent(e) {
      if (e.type == "select") {
        if (e.data) this.$store.commit("task/select", e.data.id);
        else this.$store.commit("task/select", null);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>