// pageStateMixin.js
export default {
    data() {
      return {
        pageStateKey: null,
        pageStateFields: [], // 在组件中定义需要保存的字段
      };
    },
    mounted() {
      this.__initPageState();
    },
    beforeRouteLeave(to, from, next) {
      this.__savePageState();
      next();
    },
    beforeDestroy() {
      window.removeEventListener('beforeunload', this.__savePageState);
    },
    methods: {
      __initPageState() {
       
        this.pageStateKey = `pageState_${this.$store.getters['session/session'].id}_${this.$route.path}`;
        const savedState = localStorage.getItem(this.pageStateKey);
        if (savedState) {
          const state = JSON.parse(savedState);
          this.pageStateFields.forEach(field => {
            if (state[field] !== undefined) {
              this[field] = state[field];
            }
          });
        }
        this.__afterRestorePageState()
        window.addEventListener('beforeunload', this.__savePageState);
      },
      __savePageState() {
        
        this.__beforeSavePageState()
        const state = this.pageStateFields.reduce((acc, field) => {
          acc[field] = this[field];
          return acc;
        }, {});
        localStorage.setItem(this.pageStateKey, JSON.stringify(state));
      },
      __beforeSavePageState(){

      },
      __afterRestorePageState(){

      }
    },
  };
  