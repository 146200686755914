<template>
  <div>
    <template>

      <Row :gutter="10" class-name="l-bill">
        <Col :span="1" style="text-align:center">拖</Col>
        <Col :span="1" style="text-align:center">序</Col>
        <Col :span="4" style="text-align:center" v-if="value.splited">服务类型</Col>
        <Col :span="7" style="text-align:center">调整内容</Col>
        <Col :span="5" style="text-align:center">调整金额</Col>
        <Col :span="4" style="text-align:center">附件</Col>
        <Col :span="2" style="text-align:center">操作</Col>
      </Row>
      <BaseEmpty v-if="items.length == 0" style="padding:20px;margin:0 -5px;width:calc(100% + 10px);background:var(--bg3);" msg="暂无条目" />
      <Draggable v-model="items" :options="{animation:500}" handle=".draggable" @input="emitChange">
        <template v-for="(data,index) in items">
          <Row :key="data.id" :gutter="10" class-name="l-bill">
            <Col :span="1" style="text-align:center">
            <div class="draggable" style="cursor:grab">
              <BaseIcon icon="md-menu" />
            </div>
            </Col>
            <Col :span="1" style="text-align:center">{{index+1}}</Col>
            <Col :span="4" style="color:var(--primary)" v-if="value.splited"><Select v-model="data.business_type" @input="last_type=$event" size="small" transfer> <template v-for="(r,i) in business_types">
                <Option :value="r" :key="i"><span class="marker" :style="`background:${business_type_colors[i]};margin-right:5px;`">{{r[0]}}</span>{{r}}</Option>
              </template></Select></Col>
            <Col :span="7" style="color:var(--primary)">
            <Input v-model="data.detail" transfer clearable size="small" @on-change="emitChange" />
            </Col>
            <Col :span="5">
            <div class="flex-wrap"><Button icon="md-build" size="small" style="margin-right:5px;flex-shrink:0;" v-if="data.amount > value.amount || amount != value.amount" @click="fix(index)" /><Input number v-model="data.amount" @on-blur="onAmountInput(index)" size="small" /></div>
            </Col>
            <Col :span="4" align="center">
            <BaseFileUpload v-model="data.file" />
            </Col>

            <Col :span="2" style="text-align:right">
            <div class="flex-wrap split5"><Button size="small" style="flex-shrink:0" @click="copy(index)" icon="md-copy"></Button><Button style="flex-shrink:0" size="small" @click="remove(index)" icon="md-remove"></Button></div>
            </Col>

          </Row>
        </template>
      </Draggable>
      <div class="flex-wrap flex-between" style="margin:5px 10px">
        <div>
          <Alert :type="check_result.state" size="small" style="padding:3px 5px" v-if="items.length > 0">{{check_result.message}}</Alert>
        </div>
        <div class="flex-wrap">

          <Button icon="md-add" style="margin-bottom:20px" size="small" @click="add">新增一行</Button>
        </div>
      </div>
      <Row :gutter="10" class-name="l-bill" v-if="items.length > 0">
        <Col :span="4">
        </Col>
        <Col :span="20">
        <div class="flex-wrap flex-right">
          合计 <BaseAmount :value="amount" full signed style="font-weight:bold;margin-left:10px;"></BaseAmount>
        </div>

        </Col>
      </Row>
    </template>
  </div>
</template>
<script>

import Draggable from 'vuedraggable'

let timer = null
export default {
  components: { Draggable },
  data() {
    return {
      items: [],
      last_type: "",
      full_business_types: ['全过程咨询', '项目管理', '房建监理', '市政监理', '造价咨询', 'BIM咨询', '招标代理', '对外合作', '其他'],
      full_business_type_colors: ['orange', '#369', 'green', 'darkred', 'purple', '#3af', 'grown', 'pink', '#333'],
      auto_complete_texts: ['合同签订', '施工许可证完成', '工程开工', '桩基工程完成', '主体结顶', '中间验收完成', '工程施工验收完成', '决算审计完成', '质量缺陷保证金', '工程量完成 %']
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    this.init(this.value)
  },
   watch:{
    value:{
      handler(v){
        this.init(v)
      },
    }
  },

  computed: {
    business_types() {
      if (Array.isArray(this.value.condition_groups) && this.value.condition_groups.length > 0) {
        return this.value.condition_groups.map(v => v.business_type).filter(v => v)
      } else if (this.value.business_type) {
        return [this.value.business_type]
      } else {
        return this.full_business_types
      }
    },
    business_type_colors() {
      return this.business_types.map((v, i) => this.full_business_type_colors[i])
    },
    amount() {
      return this.items.reduce((c, item) => {
        return c + item.amount
      }, 0)
    }, check_result() {
      if (this.items.filter(v => !v.amount || !v.detail).length > 0)
        return { state: "error", message: "数据未填写完整,请检查是否存在空行" }
      return { state: 'success', message: '数据已验证通过' }
    }
  },
  methods: {
     init(v){
      if(this.timer){
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(()=>{
        if (Array.isArray( v.contract_adjusts))
          this.items = v.contract_adjusts
        else{
          this.items = []
          this.emitChange()
        }
      },500)
    },
    validate() {
      if (this.check_result.state == "success")
        return true
    },
    getError() {
      return this.check_result.message
    },
    emitChange() {
      this.items.forEach((v, i) => v.order = (i + 1))
      this.$set(this.value, 'contract_adjusts', this.items)
    },
    add() {

      let business_type = this.last_type || this.value.business_type
      if (!this.last_type && this.value.splited && this.value.condition_groups && this.value.condition_groups.length > 0) {
        business_type = this.value.condition_groups[0].business_type
      }
      this.items.push({ amount: 0, business_type })
    },
    copy(i) {
      this.items.splice(i, 0, { ...this.items[i], id: undefined })
    },
    remove(i) {
      this.items.splice(i, 1)
    },
    onAmountInput(i) {
      let data = this.items[i]
      let total_amount = this.value.amount
      if (this.value.splited && Array.isArray(this.value.condition_groups)) {
        let type = this.value.condition_groups.find(v => v.business_type == data.business_type)
        if (type)
          total_amount = type.amount
      }
      if (total_amount == 0 || isNaN(data.amount))
        return
      this.items.splice(i, 1, data)
    },
    calc(i) {
      let data = this.items[i]
      this.$set(data, 'amount', this.calcAmount(i))
      this.items.splice(i, 1, data)
    },
    fix(i) {
      let data = this.items[i]
      this.$set(data, 'amount', parseInt(100 * (this.value.amount - this.amount + data.amount)) / 100)
      this.items.splice(i, 1, data)
      this.onAmountInput(i)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>