<template>
  <Dropdown style="display:inline-block" trigger="click" :disabled="menus.length == 0">
    <div class="version-tag"><slot></slot><span class="version-number">{{formattedVersion}}</span> <BaseIcon icon="down" size="20" v-if="menus.length>0" /></div>
    <DropdownMenu slot="list">
      <DropdownItem :name="item.key" v-for="item in menus" :key="item.key">
        {{item.name}}
      </DropdownItem>
    </DropdownMenu>
  </Dropdown>
</template>

<script>
export default {
  props:{
    value:{
      type:String | Number,
    },
    menus:{
      type:Array,
      default:()=>([])
    }
  },
  computed:{
    formattedVersion(){
      if(typeof this.value == 'string'){
        return this.value
      }else if(typeof this.value == 'number'){
        let a = parseInt(this.value / 10000)
        let b = parseInt((this.value - parseInt(this.value/10000))/100)
        let c = this.value % 100
        return `v ${a}.${b}.${c}`
      }
    }
  }
}
</script>

<style lang="less">
.version-tag{
  padding: 0 5px;
  display: flex;
  align-items: center;
  background:var(--bg2);
  color:var(--text2);
  height:20px;
  font-size:12px;
  font-weight: normal;
}

.version-number{
  
}
</style>