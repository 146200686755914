<style lang="less">
.pd-weather-status {
  background:none;
  overflow:hidden;
  text-align: right;
  font-size:12px;
  font-weight: bold;
  color:var(--primary);
  padding:0 3px;
  span{
    color:var(--subtext2);
    margin:0 5px;
  }
}

.search-box{
  position: absolute;
  left:0;
  top:0;
  bottom:0px;
  width:68.2%;
  text-align: left;
  padding:0 10px;
}
</style>

<template>
  <div class="container flex-wrap flex-right pd-weather-status">
    <div class="search-box flex-wrap flex-between">
      <div class="flex-wrap"><BaseIcon icon="md-search" :size="18" style="margin-right:3px;" /> 搜索</div>
    <div class="flex-wrap" style="margin-left:50px;">
      <span style="color:var(--hover-text);background:var(--primary);padding:1px 5px;font-weight:normal;">个人视图</span> </div>
    </div>

      {{weatherData.city}} 
      <span>/</span>
      {{weatherData.realtime.temperature}}℃
      <span>/</span>
      {{weatherData.realtime.info}}
      <span>/</span>
      {{weatherData.realtime.direct}} {{weatherData.realtime.power}}
      <span>/</span>
      湿度 {{weatherData.realtime.aqi}}

      <BaseIcon icon="config" style="margin-left:10px;color:#aaa;" />
  </div>
</template>

<script>
import mixinResponsable from '@/mixins/responsable'
import moment from 'moment'
moment.locale('zh-cn')
export default {
  name: 'Weather',
  panelInfo: {
    name: '天气',
    minWidthSpan: 2
  },
  data() {
    return {
      time: moment().format("HH:mm:ss"),
      week: "",
      weeknames: ['日', '一', '二', '三', '四', '五', '六', '日']
    }
  },
  mixins: [mixinResponsable],
  mounted() {
    this.week = moment().week()
  if(this.$store.getters['session/session'].id){
       this.$store.dispatch('panel/queryWeather')
    }else{
      
      this.$bus.$on('login',()=>{
        this.$store.dispatch('panel/queryWeather')
      })
    }
   
   
  },
  computed: {
    weatherData() {
      return this.$store.getters['panel/weather']
    }
  }
}
</script>

<style lang="less" scoped>
</style>