import { render, staticRenderFns } from "./MultiForm.vue?vue&type=template&id=5aa6d8d9&scoped=true"
import script from "./MultiForm.vue?vue&type=script&lang=js"
export * from "./MultiForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aa6d8d9",
  null
  
)

export default component.exports