<template>
  <div>
    
     <BaseEmpty v-if="items.length == 0" style="padding:20px;">
      <Button icon="md-add" size="small" @click="add">新增一行</Button>
     </BaseEmpty>
     
     <template v-else>
   
    <Row :gutter="10" class-name="l-bill">
       <Col :span="1"  style="text-align:center">拖</Col>
      <Col :span="1"  style="text-align:center">序</Col>
      <Col :span="4" style="text-align:center" v-if="value.splited">服务类型</Col>
       <Col :span="8" style="text-align:center" >条款内容</Col>
      <Col :span="4"  style="text-align:center">备注</Col>
      <Col :span="4"  style="text-align:center">附件</Col>
      <Col :span="2"  style="text-align:center">操作</Col>
    </Row>
   
    <Draggable v-model="items" :options="{animation:500}" handle=".draggable" @end="emitChange()" >
    <template v-for="(data,index) in items">
      <Row :key="data.id" :gutter="10" class-name="l-bill" >
         <Col :span="1"  style="text-align:center"><div class="draggable" style="cursor:grab">
          <BaseIcon icon="md-menu" /></div></Col>
      <Col :span="1"  style="text-align:center">{{index+1}}</Col>
       <Col :span="4" style="color:var(--primary)"  v-if="value.splited"><Select v-model="data.business_type" @input="last_type=$event" size="small" transfer> <template v-for="(r,i) in business_types">
                <Option :value="r" :key="i"><span class="marker" :style="`background:${business_type_colors[i]};margin-right:5px;`">{{r[0]}}</span>{{r}}</Option>
              </template></Select></Col>
        <Col :span="8"  ><Input v-model="data.detail"  size="small"  /></Col>
        <Col :span="4"  style="text-align:right"><Input v-model="data.note"  size="small"  /></Col>
         <Col :span="4" align="center"><BaseFileUpload v-model="data.file"  /></Col>
         <Col :span="2"  style="text-align:right"><Button  size="small" @click="remove(index)">删除</Button></Col>
     
    </Row>
    </template>
    </Draggable>
     <div class="flex-wrap flex-right" style="margin:5px 10px">
    
    <Button icon="md-add" size="small" @click="add">新增一行</Button>
    </div>
    <Row :gutter="10" class-name="l-bill" v-if="value.length > 0">
      <Col :span="4">合计</Col>
      
      <Col :span="7">
      <BaseAmount :value="amount"></BaseAmount>
      </Col>
      <Col :span="8">
      <div class="flex-wrap flex-right"> 合同总额 <BaseAmount style="margin-left:10px" :value="contract.signed_amount"></BaseAmount></div>
      
      </Col>
    </Row>
    </template>
  </div>
</template>
<script>

import Draggable from 'vuedraggable'
  export default {
    components:{Draggable},
    data(){
      return {
        items:[],
        last_type:"",
        full_business_types:['全过程咨询','项目管理','房建监理','市政监理','造价咨询','BIM咨询','招标代理','对外合作','其他'],
        full_business_type_colors:['orange','#369','green','darkred','purple','#3af','#551','pink','#333'],
        auto_complete_texts:['合同签订','施工许可证完成','工程开工','桩基工程完成','主体结顶','中间验收完成','工程施工验收完成','决算审计完成','质量缺陷保证金','工程量完成 %']
      }
    },
    props:{
      value:{
        type:Object,
        default:()=>({})
      },
    },
    mounted(){
      if(Array.isArray(this.value.memos))
        this.items = this.value.memos || []
      else{
        this.items = []
        this.emitChange()
      }
    },
     watch:{
    value:{
      handler(v){
        this.items = this.value.memos || []
      },
      deep:true
    }
  },
     computed: {
    business_types(){
        return this.full_business_types
        
      },
      business_type_colors(){
        return this.business_types.map((v,i)=>this.full_business_type_colors[i])
      },
  },
  methods:{
    validate(){
      if(this.items.filter(v=>!v.detail).length > 0)
        return false
      return true
    },
    getError(){
      return "有条款内容未填写"
    },
    emitChange(){
      this.items.forEach((v,i)=>{
        v.order = i+1
      })
      this.$set(this.value,'memos',this.items)
    },
    add(){
      this.items.push({business_type:this.last_type || this.business_types[0]})
      
      this.emitChange()
    },
    remove(i){
      this.items.splice(i,1)
      this.emitChange()
    }
  }
  }
</script>

<style lang="scss" scoped>

</style>