<template>
  <div class="container" style="margin:0 10px;">
     <div style="height:40px;margin:5px 0">
      <Input search style="width:200px" /> 
      <Button type="primary" icon="md-add" style="margin-left:10px">新建部门</Button>
      <Button style="margin-left:10px" icon="md-alert" @click="AsyncFromDing">同步钉钉部门</Button>
      <Button style="margin-left:10px" icon="md-refresh" @click="getData"></Button>
     </div>
     <div style="height:calc(100% - 110px);width:100%;position:relative;overflow-y:auto;">
      <Spin fix v-if="loading">
        <BaseLoading />
      </Spin>
      <div class="flex-wrap flex-line align-stretch" style="height:100%;">
        <BaseTree :data="items" :nodeRenderer="renderNode" style="border:1px solid #33333333;min-width:200px;" @event="handleTreeEvent" />
        <div class="container" style="flex-grow:1;padding:0 20px;">
          <BaseEmpty v-if="!selectedDep.id"></BaseEmpty>
          <template v-else >
          
            <h3 style="font-size:16px">{{selectedDep.name}} {{ selectedDep.id }}</h3>
            <div class="flex-wrap" style="margin:10px 0">
               <Input search style="width:200px" v-model="searchEmployeeText" clearable placeholder="搜索员工" /> 
      <Button style="margin-left:10px" v-if="selectedDep.id">修改</Button>
      <Button style="margin-left:10px" v-if="selectedDep.id">删除</Button>
      <Button style="margin-left:10px" icon="md-alert" @click="AsyncEmployeeFromDing">同步部门员工</Button>
      <Button style="margin-left:10px" icon="md-alert" @click="calcDep">测算数量</Button>
            </div>
            <div style="height:calc(100% - 80px);width:100%;">
              <BaseTable :columns="columns" :data="filteredEmployees" />
            </div>
          </template>
        </div>
        
      </div>
     
     </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  export default {
    computed:{
      ...mapGetters("oa",['deps','employees']),
      columns(){
        return [{
          key:"id",
          type:"index",
          width:50
        },{
          key:"realname",
          title:"姓名",
          align:"left",
          width:100,
          type:"text"
        },{
          key:"gender",
          title:"姓别",
          align:"center",
          width:100,
        },{
          key:"employeeType",
          title:"聘用类型",
          align:"center",
          width:100,
        },{
          key:"phone",
          title:"电话",
          align:"center",
          align:"center",
          width:120,
        },{
          title:"岗位",
          key:'position',
          align:"center",
          width:140,
        },{
          key:"tool",
          title:"操作",
          align:"center",
          type:"text",
          render:(h,{row})=>{
            let buttons = []
            buttons.push(h('a',{on:{
              click:()=>{
                this.hanldeSetManager(row)
              }
            }},'设为主管'))
            buttons.push(h('a',{on:{
              click:()=>{
                this.hanldeSetManager(row)
              }
            }},'设为副主管'))
            buttons.push(h('a',{on:{
              click:()=>{
                this.handleRemove(row)
              }
            }},'调整部门'))
            return h('div',{class:'flex-wrap flex-center split5'},buttons)
          }
        }]
      },
      filteredEmployees(){
        if(this.searchEmployeeText && this.selectedDep && this.selectedDep.employees){
          return this.selectedDep.employees.filter(v=>{
            return v.name && v.name.includes(this.searchEmployeeText)
          })
        }
        return this?.selectedDep.employees || []
      }
    },
     data(){
        return {
          loading:true,
          items:[],
          selectedDep:{},
          selectedUsers:[],
          searchEmployeeText:''
        }
      },
      mounted(){
       
        this.$api.afterLogin(()=>{
          
          this.$store.dispatch("oa/getEmployees")
          this.getData()
        })
      },
      methods:{
        getData(){
          this.loading = true
          this.$api.get('oa/deps').then(res=>{
             this.items = res.data.data
            
          }).catch((e)=>{
            this.Error(e)
          }).finally(()=>{
            this.loading = false
          })
        },
        getGroupData(){
          this.loadingDep = true
          this.$api.get(`oa/deps/${this.selectedDep.id}?related=employees`).then(res=>{
            let dep = res.data.data
            dep.employees.forEach(e=>{
              Object.assign(e,e.ref || {})
            })
            this.selectedDep =dep

          }).finally(()=>{
            this.loadingDep = false
          })
        },
        AsyncEmployeeFromDing(){
          this.$api.post('oa/deps/async-ding-employees',{dep_id:this.selectedDep.id}).then(res=>{
            this.Success('同步成功')
            this.getGroupData(this.selectedDep.id)
            this.getData()
          })
        },
        AsyncFromDing(){
          this.$api.post('oa/deps/async-ding').then(res=>{
            this.Success('同步成功')
            this.getData()
          })
        },
        calcDep(){
          this.$api.post('oa/deps/calc',{dep_id:this.selectedDep.id}).then(res=>{
            this.Success('同步成功')
            this.getData()
          })
        },
        handleRemove(){

        },
        hanldeSetManager(){

        },
        renderNode(h,node){
          return h('span',[node.name,` (${node.count || 0})`])
        },
        handleTreeEvent({type,data}){
          if(type == 'select'){
            this.selectedDep =data
            this.getGroupData()
          }
        }
      }
  }
</script>

<style lang="less">
.dep-item{
  padding:0 10px;
}
</style>