<template>
    <div>
      <span v-if="localFiles.length==0">-</span>
      <BaseFile v-else-if="localFiles.length==1" :file="localFiles[0]" />
      <Dropdown v-else trigger="click">
         <div>{{ localFiles.length }}个文件</div>
         <DropdownMenu slot="list">
            <div class="flex-wrap flex-between hover-lighter"  style="background:linear-gradient(to bottom right,var(--bg2),var(--border));border:1px solid var(--border);border-radius:3px;height:25px;font-size:12px;padding:0 5px;margin:0 10px;" v-for="f in localFiles" :key="f.id">
              <BaseFile :file="f" />
            </div>
         </DropdownMenu>
      </Dropdown>
    </div>
  </template>
  
  <script>
  export default {
    props:{
      'files':{
        type:Object | Array
      }
    },
    computed:{
        localFiles(){
            if(Array.isArray(this.files)){
                return this.files
            }else if(this.files){
                return [this.files]
            }else{
                return []
            }
        }
    }
  }
  </script>
  