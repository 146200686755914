<template>
  <div class="training-group">
    <img src="https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/misc/px.jpg" />
    <div class="tg-mask"></div>
    <div class="tg-name">
    {{value.name}}
    </div>
    <div class="tg-count">
    {{value.count || 0}} 个课程
    </div>
  </div>
</template>

<script>
  export default {
    props:{
      value:{
        type:Object,
        default:()=>({})
      }
    }
  }
</script>

<style lang="less">
.training-group{
  width:300px;
  height:180px;
  background:var(--bg2);
  border:1px solid var(--border);
  padding:20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:20px;
  flex-direction: column;
  border-radius: 10px;
  position: relative;
  transition: all 0.3s;
  overflow: hidden;
  color:var(--hover-text);
  cursor: pointer;
  .tg-name{
    position:relative;
    z-index:10;
  }

  .tg-count{
    font-size:14px;
    position:relative;
    z-index:10;
  }

  img{
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    width:100%;
    height:100%;
  }

  .tg-mask{
    background:var(--bg1);
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    opacity: 0.5;
    z-index:5;
  }
}

.training-group:hover{
  background:var(--primary);
  color:var(--hover-text);
}
</style>