<template>
    <Table 
      :columns="cols" 
      :data="tableData" 
      :loading="loading"
      border
      :width="cols.reduce((a,v)=>a+v.width,0)+5"
      >
      <BaseLoading v-if="loading" />
      <div class="flex-wrap flex-right" style="font-size:12px;color:var(--subtext3)">
                      
                  </div>
    </Table>
  </template>
  
  <script>
    
  import tableRender from '../../base/tableRender'
  import moment from 'moment'
  export default {
      name:'CmTableYearHistoryHrv',
      data(){
          return {
              tableData:[],
              loading:true
          }
      },
      computed:{
          cols(){
              return [{
                  key:'year',
                  title:'年份',
                  align:'center',
                  width:100
              },{
                  key:'amount',
                  title:'合同总额',
                  align:'center',
                  width:180,
                  render:(h,params)=>tableRender.renderAmountNoFormat(h,params.row.amount)
              },{
                  key:'remain_amount',
                  title:'合同余额',
                  align:'center',
                  width:180,
                  render:(h,params)=>tableRender.renderAmountNoFormat(h,params.row.remain_amount)
              },{
                  key:'percent',
                  title:'完成比例',
                  align:'center',
                  width:180,
                  render:(h,params)=>tableRender.renderProgress(h,params.row.percent)
              },{
                  key:'production',
                  title:'产值总额',
                  align:'center',
                  width:180,
                  render:(h,params)=>tableRender.renderAmountNoFormat(h,params.row.production)
              },{
                  key:'remain_production',
                  title:'产值余额',
                  align:'center',
                  width:180,
                  render:(h,params)=>tableRender.renderAmountNoFormat(h,params.row.remain_production)
              },{
                  key:'percent',
                  title:'完成比例',
                  align:'center',
                  width:180,
                  render:(h,params)=>tableRender.renderProgress(h,params.row.production_percent)
              },{
                  key:'actual_hra',
                  title:'当前人均产值',
                  align:'center',
                  width:180,
                  render:(h,params)=>tableRender.renderAmountNoFormat(h,params.row.actual_hra)
              },{
                  key:'predict_hra',
                  title:'预测人均产值',
                  align:'center',
                  width:180,
                  render:(h,params)=>tableRender.renderAmountNoFormat(h,params.row.predict_hra)
              },]
          }
      },
      mounted(){
          this.getData()
      },  
      props:{
        year:{
            type:String | Number
        }
    },
      methods:{
          getData(){
              this.loading = true
              
              this.$api.get(`cm/tables/history-hra?year=${this.year}`).then(res=>{
                  let result = res.data.data
                  this.tableData = result.data
                  this.created_at = result.created_at
              }).finally(()=>{
                  this.loading = false
              })
          },
          exportExcel(){
  
          }
      }
  }
  </script>
  
  <style>
  
  </style>