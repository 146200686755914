<template>
  <Container style="padding:20px">
    <div style="width:960px;margin:0 auto">
      <BaseSegment title="企业信息" desc="企业经营相关基础信息配置" :disabled="true">
      <div>
        企业名称: 宁波高专建设监理有限公司 <a style="font-size:14px">修改</a>
        <br />税号：91330212546737
        <br />经营地址:
        <br />联系电话:
      </div>
    </BaseSegment>
    <BaseSegment :title="`主营业务 (${services.length})`" desc="企业主营业务信息" :collapse="false" :disabled="true">
        <div class="flex-wrap" style="gap:10px;flex-wrap:wrap;">
          <div class="u-info-card" v-for="s in services" :key="s.id">
              <div class="u-icon-wrap" :style="`background:${s.color}`">
                <BaseIcon :icon="s.icon || 'Buidling-'" /> 
              </div>
              <div class="u-title">
                {{s.name}}
              </div>
          </div>
          <div class="u-info-card" style="border-style:dashed">
              <div class="u-icon-wrap" :style="`background:var(--subtext2)`">
                <BaseIcon icon="md-add" /> 
              </div>
              <div class="u-title" style="color:var(--subtext2)">
                新增类型
              </div>
          </div>
        </div>
    </BaseSegment>
     <BaseSegment title="工程类型" :collapse="false" :disabled="true">

    </BaseSegment>

    </div>
  </Container>
</template>

<script>
import Container from '@/components/app/container'
import Toolbar from '@/components/app/toolbar'
export default {
  components:{Container,Toolbar},
  data(){
    return {
      services:[{
        id:1,
        name:"全过程咨询",
        icon:"Building-",
        desc:"全过程咨询是。。。"
      }]
    }
  },
  computed:{
    tools_services(){
      return [{
        key:'add-service',
        type:"icon-button",
        icon:"md-add"
      }]
    },
    columns_services(){
      return [{
        name:"序号",
        control:'index',
        span:2,
      },{
        name:"业务名称",
        control:"Input",
          span:4,
          option:{
            
            size:"small",
            number:true,
            clearable:true
          },
        key:"name",
      
      },{
        name:"标识色",
        control:"Input",
          span:4,
          option:{
            
            size:"small",
            number:true,
            clearable:true
          },
        key:"color",
      }]
    }
  },
  mounted(){
    this.getData()
  },
  methods:{
    getData(){
      this.$api.get('/business/services').then(res=>{
        this.services = res.data.data
      })
    },
    handleEvent({type,key,value}){
      if(type == 'add-service'){

      }
    }
  }
}
</script>

<style lang="less">
.u-info-card{
  width:100px;
  height:120px;
  border:1px solid var(--border);
  border-radius:5px;
  position: relative;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;


  .u-icon-wrap{
    padding:0 10px;
    height:50px;
    width:50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--primary);
    color:var(--hover-text);
    i{
      font-size:25px;
    }
  }

  .u-title{
    margin-top:10px;
  }

  .u-desc{
    
  font-size:12px;
  color:var(--subtext2);
  }
}
</style>