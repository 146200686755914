<template>
  <div style="flex-grow:1;width:100%;padding-left:80px;padding-bottom:30px;padding-top:80px;">
          <div class="flex-wrap flex-left" style="margin-bottom:30px;">
          
          <Input size="large" search style="width:380px;" v-model="searchText" placeholder="快速查找" />
          </div>
          <template v-for="g in groups">
            <div :key="g.id" >
              <div style="margin:10px 0;font-weight:bold;color:var(--subtext3)">{{g.name}}</div>
              <div class="flex-wrap">
                <template v-for="m in g.mods">
                  <div class="d-acc-item"  :key="m.id" style="width:80px;margin-left:10px;" @click="open(m)">
                      <BaseIcon :icon="m.icon" :size="w<300?(300-w)/300*30:30" style="height:35px" color="var(--primary)" />
                      <div class="aci-name">{{m.name}}</div>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import responsableMixin from '@/mixins/responsable'
  export default {
    data(){
      return {
        searchText:""
      }
    },
    mixins:[responsableMixin],
     methods:{
       open(a){
        if(a.url){
          this.$store.commit("session/push",{...a,path:a.url,base_url:a.base_url || a.url})
          this.RouteTo(a.url)
        }
        else if(a.flow){
          this.modalFlow = true
          this.currentAction = a
        }else if(a.action){
          this.action(a.action)
        }
      },
    },
    computed:{
       ...mapGetters('session',['session','modules','accelerators']),
       types(){
        return {
          action:"快捷操作",
          mod:"模块",
          create:"新建"
        }
      },
      actions(){
        if(!this.accelerators)
          return []
        return this.accelerators.map(id=>{
          let a = this.modules.find(m=>m.id == id)
          return a
        }).filter(v=>v)
      },
      groups(){
        let g = {}
        
        this.modules.forEach(m=>{
          if(this.searchText && m.name && !m.name.includes(this.searchText))
            return
          if(m.group){
            if( g[m.group])
              g[m.group].mods.push(m)
            else
              g[m.group] = {id:m.group,name:m.group,mods:[m]}
           
          }
        })
        return Object.values(g)
      },
    },
   
  }
</script>

<style lang="less">
.d-acc-item{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  padding:5px;
  border-radius:5px;
  transition:all 0.3s;
  color:var(--text3);

  .aci-name{
    
  }
}

.d-acc-item:hover{
  background:var(--primary);
  color:#fff;
  i{
    color:var(--hover-text) !important;
  }
}

.d-acc-item:active{
  position: relative;
  bottom:-1px;
  right:-1px;
}
</style>