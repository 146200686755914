<template>
  <App class="flex-wrap">
      <div style="height:100%;flex-shrink:0;width:400px;overflow:hidden;background:var(--bg3);">
      <div class="flex-wrap" style="padding:10px">
        <template v-if="tmpls.length > 0">
          <BaseIcon icon="ios-document" :size="25" />
          <Select v-model="selected_tmpl" style="width:200px;margin:0 10px" clearable @input="select">
            <template v-for="t in tmpls">
              <Option :value="t.id" :key="t.id">{{t.name}} <span v-if="form.docx_tmpl_id == t.id">(默认)</span></Option>
            </template>
            </Select></template>
           
        <Button @click="showUpload=true;formData={}" type="primary">上传模板</Button>
        <Button icon="md-refresh" @click="getData()" style="margin-left:10px;"></Button>
       
        
      </div>
      <div v-if="selected.description" style="padding:10px 20px;color:var(--primary);">{{selected.description}}</div>
      <div style="padding:0 10px;margin-bottom:10px;" v-if="form.docx_tmpl_id == selected_tmpl">默认模板</div>
      <div class="flex-wrap flex-center">
      <Button v-if="selected_tmpl" @click="updateTmpl(selected_tmpl)" type="info" style="" size="small">更新绑定数据</Button>
        <Button v-if="selected_tmpl" @click="removeTmpl(selected_tmpl)" type="error" style="margin-left:10px;" size="small">删除此模板</Button>
        <Button type="success" @click="start"  size="small" style="margin-left:10px" v-if="selected_tmpl" :loading="generating">生成测试文档</Button>
      </div>
        <BaseBoardSubTitle name="数据绑定" style="margin:10px;" />
      <div style="margin:0 10px;height:calc(100% - 110px);overflow-y:auto;border-top:1px solid var(--border);">
       
        
       <Spin fix v-if="loading"><BaseLoading /> 处理中</Spin>
       <BaseTree ref="tree" :data="placeholders"  :nodeRenderer="renderNode" v-else-if="selected.keys" subname="subparsed" manu />
      </div>
      </div>
      <div style="padding:20px;flex-grow:1;overflow-y:auto;height:100%;position:relative;">
        <BaseEmpty v-if="!selected || !selected.file">
        </BaseEmpty>
        <BasePreview :url="(selected.file)" v-else-if="!$dev" style="width:100%;height:100%;" />
        <div v-else>
          <BaseEmpty style="height:800px"></BaseEmpty>
        </div>
        <!-- file loader hiding stuff -->
			
      </div>

    <Modal v-model="showUpload" title="上传模板" :width="400" footer-hide>
      <div>
        <Input placeholder="模板名称" v-model="formData.name" />
        <div class="flex-wrap" style="padding:10px;background:var(--bg3);border:1px solid var(--border);border-radius:5px;margin-top:10px;">
          
        <BaseFileUpload v-model="formData.file" label="请上传模板文件(.docx)" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
        
          
        </div>
        <Input placeholder="模板说明" v-model="formData.description" type="textarea" style="margin-top:10px" :rows="5" />
        <div class="flex-wrap flex-right" style="margin-top:10px">
          <Button type="primary" @click="upload">提交</Button>
        </div>
      </div>
    </Modal>
  </App>
</template>

<script>
  import App from '@/components/app/app'
  import AppBar from '@/components/app/bar'
  import AppClient from '@/components/app/client'
  import AppTitle from '@/components/app/title'
  import { cloneDeep } from 'lodash'
  import moment from 'moment'
  import UTIL from '@/utils'
  let idGlobal = 1
  export default {
    components:{App,AppBar,AppClient,AppTitle},
    props:{
      fields:{
            type:Array,
            default:()=>([])
        },
        form:{
            type:Object,
            default:()=>({})
        }
    },
    data(){
      return {
        tmpls:[],
        formData:{},
        show:"",
        loading:false,
        generating:false,
        showUpload:false,
        selected_tmpl:null,
        notice:'模板说明：每个需要替换的字符请用{A1},{A2}这样的数据代替,循环列表等复杂模式请参考文档:<a href="https://docxtemplater.com/docs/tag-types/">https://docxtemplater.com/docs/tag-types/</a><br />数据说明:按对应标记输入数据<br />- <b>可以直接从excel拷贝进来,换行和空格均可以做为分隔符</b><br />',
        notions:[{key:"",data:""}],
        // config,
        merge:true,
        namekey:"",
        placeholders:[],
        bind_keys:[]
      }
    },
    computed:{
      selected(){
        return this.tmpls.find(v=>v.id == this.selected_tmpl) || {}
      }
    },
    mounted(){
      this.getData()
    },
    methods:{
      isContainer(control){
        return this.$store.getters['forms/containers'].includes(control)
      },
      getBindField(fullkey){
        let bind_key = this.bind_keys[fullkey]
        if(bind_key){
          return this.fields.find(t=>t.key == bind_key)
        }
      },
      generateTestData() {
    let data = {};
    const LoopCount = 3;

    // 遍历字段，生成测试数据
    this.traversalFeilds(this.placeholders, (field, parent) => {
        const bindKey = this.bind_keys[field.fullKey];
        if (!bindKey) return;

        const bindField = this.fields.find(t => t.key === bindKey);
        if (!bindField) return;

        if (bindField.control === 'UgoTable') {
            // 对于数组类控件，生成子项测试数据
            data[bindField.key] = Array.from({ length: LoopCount }, (_, index) => {
                const subFields = this.getSubFieldsOptions(bindField);
                const subData = {};
                if (subFields) {
                    subFields.forEach(subField => {
                        subData[subField.key] = `${subField.name}${index + 1}`;
                    });
                }
                return subData;
            });
        } else {
            // 普通控件，直接赋值为控件名
            data[bindField.key] = bindField.name;
        }

        // 处理父级为数组控件的情况
        if (parent) {
            const parentField = this.getBindField(parent.fullKey);
            if (parentField && parentField.control === 'UgoTable') {
                const parentSubFields = this.getSubFieldsOptions(parentField);
                if (parentSubFields) {
                    const parentBindField = parentSubFields.find(v => v.key === bindKey);
                    if (parentBindField) {
                        for (let i = 0; i < LoopCount; i++) {
                            data[parentField.key][i][parentBindField.key] = parentBindField.name;
                        }
                    }
                }
            }
        }
    });

    return data;
},

      updateTmpl(tmpl_id){
        
        this.$api.patch(`forms/${this.form.id}/docxtmpls/${tmpl_id}`,{bind_keys:this.bind_keys}).then(res=>{
          this.Success("保存成功")
        })
      },
      start(){
        
        let notions = null
        try{
          notions = this.generateTestData()
        }catch(e){
          this.Error(e)
          return
        }
         
        if(!notions){
          this.Error("没有正确的数据可以上传,请检查<br /><span style='color:var(--primary)'>提示:是不是忘记填写标记名称了</span>")
          return
        }
        this.generating = true
        this.$api.post("forms/gen-docx",{
          tmpl_id:this.selected_tmpl,
          data:notions
          }).then((res)=>{
            let {url,name} = res.data.data
            this.DownloadWithName(url,name)
        }).catch(e=>{
          this.Error(e)
        }).finally(()=>{
          this.generating =false
        })
      },
      add(){
        this.notions.push({key:"",data:""})
      },
      removeTmpl(id){
        this.Confirm("确定删除此模板?",()=>this.$api.delete(`/forms/${this.form.id}/docxtmpls/${id}`).then(()=>{
          this.$Notice.success({title:"删除成功"})
          this.tmpls.splice(this.tmpls.findIndex(v=>v.id == id),1)
          if(this.tmpls.length > 0)
            this.select(this.tmpls[0].id)
          else{
            this.selected_tmpl = null
            this.notices = [{}]
          }
        }))
      },
      remove(i){
        if(this.notions.length == 1)
          return
        this.notions.splice(i,1)
      },
      traversalFeilds(fields,cb,parent = null){
      if(Array.isArray(fields) && cb){
        fields.forEach(f=>{
          cb(f,parent)
          this.traversalFeilds(f['subparsed'],cb,f)
        })
      }
    },
    createID(){
      let time = Date.now()
      return time.toString(16) + idGlobal++
    },
      select(t){
        this.selected_tmpl = t
        let tmpl = this.tmpls.find(v=>v.id == t)
        if(tmpl.keys){
          let placeholders = cloneDeep(tmpl.keys)
          this.traversalFeilds(placeholders,(f,p)=>{
            f.id = 'node-'+this.createID()
            f.fullKey = p?(`${p.value}.`+f.value):f.value
          })
          this.placeholders = placeholders
        }
        if(tmpl.bind_keys){
          this.bind_keys = cloneDeep(tmpl.bind_keys)
        }else{
          this.bind_keys = {}
        }
      },
      getData(){
        this.$api.get(`forms/${this.form.id}/docxtmpls`).then(res=>{
          this.tmpls = res.data.data
          if(this.tmpls.length > 0)
            this.select(this.tmpls[0].id)
        })
      },
      getSubFieldsOptions(f){
        if(f.control == 'UgoTable' && f?.config?.cols){
          return f.config.cols.filter(v=>v.key).map(v=>({...v,key:v.key}))
        }
      },
      renderNode(h,node,is_selected,){
        let name = h('div',{},node.value)
       
        let groups = [{name:'表單數據',options:this.fields.filter(f=>!this.isContainer(f.control))}]
        let fullkey = node.fullKey
        let disabled = false
        if(fullkey.includes('.')){
          
          let paths = fullkey.split('.')
          let path = null
          for(let i=0;i<paths.length-1;i++){
            path = (!path)?paths[i]:(path+'.'+paths[i])
            let bindkey = this.bind_keys[path]
            
            if(bindkey){
              let field = this.fields.find(f=>f.key == bindkey)
              if(!field){
                this.Error('綁定數據錯誤')
                return
              }
              let options = this.getSubFieldsOptions(field)
              if(options){
                groups.push({name:field.name,options})
              }
            }else{
              disabled = true
              break
            }
          }
        }
        let optionGroups = groups.map(g=>h('OptionGroup',{props:{
          label:g.name
        }},g.options.map(o=>h('Option',{props:{value:o.key}},o.name))))
        let binder = h('Select',{props:{
          size:'small',
          transfer:true,
          disabled,
          value:this.bind_keys[node.fullKey]
        },
        on:{
          'input':e=>{
            this.$set(this.bind_keys,node.fullKey,e)
          }
        },
        
        style:'width:140px'},optionGroups)
        return h('div',{class:'flex-wrap flex-between',style:'width:100%;'},[name,binder])
      },
      upload(){
        if(this.formData.name && this.formData.file){
          this.$api.post(`forms/${this.form.id}/docxtmpls`,this.formData).then(res=>{
            let item = Object.assign({},this.formData,res.data.data)
            this.tmpls.splice(0,0,item)
            this.showUpload = false
            this.formData = {}
            this.select(item.id)
          }).catch(e=>{
            this.Error(e)
          })
        }else{
          this.Error("请输入完整再上传")
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>