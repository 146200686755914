<style lang="less">

</style>
<template>
  <div>
    
    <Drawer 
      :value="value"
      @input="$emit('input',$event)"
      footer-hide 
      :mask-closable="false"
      transfer
      :title="task.id?'修改计划':'新建计划'"
      width="712">
      <div>
        <Input
          size="large"
          ref="taskContent"
          :type="task_mode?'text':'textarea'"
          :rows="task_mode?1:5"
          :maxlength="512"
          v-model="task.content"
          show-word-limit
          placeholder="输入计划名称"
          @on-enter="handleEnter"
        />
        <BaseTagsInput 
          v-model="task.tags" 
          style="margin-top:10px" 
          :tags="cur_tags.map(v=>v.name)" 
        />
        <div 
          style="height:300px;position:relative;margin-top:10px;" 
          v-if="task_mode"
        >
          <BaseTextEditor
            size="large"
            ref="taskContent"
            type="textarea"
            :rows="5"
            v-model="task.note"
            :maxlength="1024"
            show-word-limit
            style
            placeholder="输入计划的详细描述"
            @on-enter="handleEnter"
          />
        </div>
        <div style="margin:10px">
          
          <div class="flex-wrap">
          切换{{ task_mode == 0 ?'详细模式':'精简模式' }}
          <i-switch v-model="task_mode" style="margin-left:10px"></i-switch>
        </div>
        <div style="color:var(--primary);font-size:10px;margin-top:5px;">说明：{{ task_mode == 0?'[详细模式] 可以填写任务的具体内容，计划时间等补充内容':'[精简模式] 精简的填写内容,适用于快速创建' }}</div>
        </div>
        <div
          class="memo-task-form"
          style="padding:20px;border:1px solid var(--border);margin-top:10px;border-radius:5px"
        >
          <div class="flex-wrap flex-left" style="margin-bottom:15px">
            <div class=flex-wrap  style="margin-right:20px"  v-if="task_mode" >
            <div class="l-field-name"><BaseIcon icon="md-calendar" size="17" /> 计划开始</div>
            <BaseDate
              style="margin-left:10px;width:140px;margin-right:40px"
              v-model="task.plan_started_at"
            />
          </div>
            <div class="flex-wrap">
              <div class="l-field-name"><BaseIcon icon="md-calendar" size="17" /> 计划完成</div>
            <BaseDate
              style="margin-left:10px;width:140px;margin-right:20px"
              v-model="task.deadline"
            />
          </div>
          
          </div>
          <div class="flex-wrap align-start">
            <div class="flex-wrap">
            <div class="l-field-name"><BaseIcon icon="xiangmu" size="17" /> 优先级别 </div>
            <RadioGroup v-model="task.priority" style="width:100px;margin-left:10px;">
              <div class="flex-wrap">
                <Radio v-for="(p,i) in priorities" :label="i" :key="p.name">
                  <span :style="`color:${p.color}`">{{ p.name }}</span>
                </Radio>
              </div>
            </RadioGroup>
           
          </div>
            <div  style="margin-left:100px">
            <div class="flex-wrap" >
              <div class="l-field-name"><BaseIcon icon="md-mail" size="17" /> 短信提醒</div> <i-switch v-model="task.sms" style="margin-left:10px"></i-switch></div>
            <div style="color:var(--primary);font-size:10px;margin-top:5px;">系统会在截止日期前一周发送短信</div>
          </div>
            
          </div>
          <div class="flex-wrap" style="margin-top:15px" v-if="!groupId">
            <div class="l-field-name"><BaseIcon icon="organization" size="17" /> 归属部门</div>
            <Select
              placeholder="选择部门"
              v-model="task.group_id"
              style="width:180px;margin-left:10px;margin-right:20px;overflow:hidden;"
              clearable
            >
              <BaseIcon slot="prefix" icon="organization" />
              <template v-for="g in groups">
                <Option :key="g.id" :value="g.id" :label="g.name"  style="white-space:nowrap">{{g.name}}</Option>
              </template>
            </Select>
          </div>
          <div class="flex-wrap" style="margin-top:15px" v-if="!projectId">
            <div class="l-field-name"><BaseIcon icon="pm2" size="17" /> 归属项目</div>
            <Select
              :placeholder="`选择项目(${my_projects.length})`"
              v-model="task.project_id"
              style="width:380px;margin-left:10px;overflow:hidden;"
              clearable
            >
              <BaseIcon slot="prefix" icon="pm2" />
              <template v-for="g in my_projects">
                <Option :key="g.id" :value="g.id" :label="g.name">{{g.name}}</Option>
              </template>
            </Select>
          </div>
          
          <div class="flex-wrap align-start" style="margin-top:20px" >
           <div class="flex-wrap"  style="margin-right:80px;" v-if="task_mode && (task.project_id || task.group_id)">
            <div class="l-field-name"><BaseIcon icon="md-person" size="17" /> 负责人员</div>
            <BaseUserSelectGrouped v-model="task.charger_id" style="width:120px;margin-left:10px" />
          </div>
          <div>
          <div class="flex-wrap">
            <div class="l-field-name"><BaseIcon icon="huiyi" size="17" /> 开启协同</div>
            <i-switch v-model="task.public" style="margin-left:10px"></i-switch>
           
          </div>
          <div style="color:var(--primary);font-size:10px;margin-top:5px;">开启协同功能后，此任务对其他成员可见</div>
        </div>
          </div>
          
        </div>

        <div class="flex-wrap flex-right" style="margin-top:10px">
          <Button type="primary" @click="saveTask">提交 Ctrl+Enter</Button>
          <Button style="margin-left:10px" @click="close">取消</Button>
        </div>
      </div>
    </Drawer>

  </div>
</template>

<script>
export default {
    props:{
        value:{
            type:Boolean,
        },
        data:{
          type:Object,
          default:()=>({})
        }
    },
    data(){
        return {
            task_mode:0,
            projectId:null,
            showEditTask:false,
            task:{},
            my_projects:[],
            my_deps:[],
            priorities:[],
            cur_tags:[]
        }
    },
    methods:{
        close(){
            this.$emit('input',false)
        },
        saveTask(){

        }
    }
}
</script>

<style>

</style>