
<style lang="less" >


</style>
<template>
  <div class="pd-embed-control flex-wrap" :class="{'pd-embed-control-editable':editable}">
    <template v-if="options">
      <div class="flex-wrap split5" >
      <template v-for="o in options">
        
        <Checkbox :key="o" v-model="value[o]" :disabled="!editable" style="font-size:12px;" @input="$emit('input',value)">{{o}}</Checkbox>
      </template>
      </div>
    </template>
    <Checkbox  style="margin-left:10px;font-size:12px;" v-model="_value" v-else :disabled="!editable" @input="$emit('input',$event)">{{value?'启用':'未启用'}}</Checkbox>
  </div>
</template>

<script>
  export default {
    props:['value','editable','option'],
    mounted(){
      if(this.options){
        this.$emit('input',{})
      }
    },
    controlInfo:{
      
    },
    computed:{
      _value:{
        get(){
          return this.value?true:false
        },
        set(v){
          this.$emit('input',v)
        }
      },
      options(){
        if(!this.option || !this.option.options)
          return null
        else
          return this.option.options
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>