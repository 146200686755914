<template>
  <div>
    <BaseEmpty v-if="!value" message="未选择任何工作任务">
      
    </BaseEmpty>
    <BaseEmpty v-else-if="items.length == 0" message="">
    </BaseEmpty>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        items:[]
      }
    },
    props:{
      value:{
        type:Object
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>