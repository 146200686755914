<template>
  <div style="display:inline-block;width:100%;">
    <div v-if="value == undefined" style="color:var(--subtext2)" >
        未填写
    </div>
    <div v-else style="font-weight:bold;color:var(--primary);font-size:16px;">
        {{ value }}
    </div>
</div>
</template>

<script>
export default {
    name:'DynamicStatic',
    props:{
        value:{
            type:String
        }
    }
}
</script>

<style>

</style>