<template>
    <Container>
        <div class="flex-wrap">
            <div class="flex-wrap flex-center" style="margin-right:10px;background:var(--primary);width:40px;height:40px;">
            <BaseIcon icon="user" color="var(--hover-text)" :size="25"></BaseIcon>
            </div>
            <div>
            <div style="font-size:20px;font-weight:bold;">员工信息表 ({{formData &&  formData.id?'编辑':'新增' }})</div>
            <div style="font-size:14px;color:var(--subtext2)">A01 - Employee Profile Sheet</div>
            </div>
        </div>
       <BaseTabs :items="employeeTabs" :actived="activeTab" @on-click="activeTab=$event" style="height:30px;margin-top:20px;" />
        <div style="height:calc(100% - 40px);overflow-y:auto;background:linear-gradient(to bottom right,var(--bg3),var(--bg2));border:1px solid var(--border);">
    <Form ref="form" :model="formData" :rules="rules" style="padding:20px;" >
        <template v-if="activeTab == 'base'">
        <BaseSegment title="基础信息">
            <div class="flex-wrap flex-between align-start">
                <div style="flex-grow:1">
                    <Row :gutter="40">
                        <Col :span="5">
                        <FormItem label="档案编号"  prop="profile_code">
                            <Input v-model="formData.profile_code" placeholder="请输入" clearable />
                        </FormItem></Col>
                        <Col :span="5">
                        <FormItem label="档案编号2"  prop="profile_code2">
                            <Input v-model="formData.profile_code2" placeholder="请输入" clearable />
                        </FormItem></Col>
                      </Row>
                      <Row :gutter="40">
                        <Col :span="6">
                        <FormItem label="员工姓名"  prop="realname">
                            <Input v-model="formData.realname" placeholder="请输入" clearable />
                        </FormItem></Col>
                        <Col :span="4">
                        <FormItem label="性别"  prop="gender">
                            <Select v-model="formData.gender" placeholder="请选择" clearable>
                                <Option value="男">男</Option>
                                <Option value="女">女</Option>
                            </Select>
                        </FormItem></Col>
                        <Col :span="6">
                        <FormItem label="出生年月"  prop="birthday">
                            <BaseDate v-model="formData.birthday" />
                        </FormItem></Col>
                    </Row>
                    <Row :gutter="40">
                        <Col :span="4">
                        <FormItem label="民族" prop="nationType">
                            <Input 
                                v-model="formData.nationType"
                                placeholder="请输入"
                                clearable 
                                />
                        </FormItem></Col>
                        <Col :span="6">
                        <FormItem label="籍贯" prop="native_place">
                            <Input v-model="formData.native_place" placeholder="请输入" clearable />
                        </FormItem></Col>
                        <Col :span="6">
                        <FormItem label="政治面貌"  prop="political_status">
                            <Select v-model="formData.political_status" placeholder="请选择" clearable>
                                <Option value="党员">党员</Option>
                                <Option value="群众">群众</Option>
                            </Select>
                        </FormItem></Col>
                        <Col :span="6">
                        <FormItem label="婚姻状况"  prop="marital_status">
                            <BaseSelect 
                                v-model="formData.marital_status"
                                :filterable="false"
                                :options="['已婚','未婚']"
                            />
                        </FormItem></Col>
                    </Row>
                </div>
                <div style="width:120px;flex-shrink:0;">
                    <FormItem label="档案照片">
                    <PhotoEditor v-model="formData.profile_photo" 
                    style="border:1px solid var(--border);border-radius:3px;height:140px;"
                        :editable="true"
                        :option="{
                        uploader:'session/upload'
                        }"
                        />
                    </FormItem>
                </div>
            </div>
            <Row :gutter="20">
                <Col :span="4">
                    <FormItem label="学历"  prop="education">
                        <BaseSelect 
                                v-model="formData.education"
                                :filterable="false"
                                :options="['博士','研究生','本科','大专','中专','高中及以下']"
                            />
                        </FormItem>
                </Col>
                <Col :span="4">
                    <FormItem label="学位"  prop="degree">
                        <BaseSelect 
                                v-model="formData.degree"
                                :filterable="false"
                                :options="['博士','硕士','学士','无']"
                            />
                        </FormItem>
                </Col>
                <Col :span="10">
                    <FormItem label="毕业院校"  prop="graduate_institution">
                            <Input v-model="formData.graduate_institution" placeholder="请输入" clearable />
                        </FormItem>
                </Col>
                <Col :span="6">
                    <FormItem label="专业"  prop="major">
                            <Input v-model="formData.major" placeholder="请输入" clearable />
                        </FormItem>
                </Col>
            </Row>
            
        </BaseSegment>
       
        <BaseSegment title="联系方式">
            <Row :gutter="20">
                <Col :span="6">
                    <FormItem label="联系电话"  prop="phone">
                            <Input v-model="formData.phone" placeholder="请输入" clearable />
                        </FormItem>
                </Col>
                
                <Col :span="12">
                    <FormItem label="家庭住址"  prop="address">
                            <Input v-model="formData.address" placeholder="请输入" clearable />
                        </FormItem>
                </Col>
            </Row>
            <Row :gutter="20">
                <Col :span="6">
                    <FormItem label="QQ"  prop="qq">
                            <Input v-model="formData.qq" placeholder="请输入" clearable />
                        </FormItem>
                </Col>
                
                <Col :span="10">
                    <FormItem label="Email"  prop="email">
                            <Input v-model="formData.email" placeholder="请输入" clearable />
                        </FormItem>
                </Col>
            </Row>
            <Row :gutter="20">
                <Col :span="4">
                    <FormItem label="紧急联系人"  prop="emergency_contact">
                            <Input v-model="formData.emergency_contact" placeholder="请输入" clearable />
                        </FormItem>
                </Col>
                
                <Col :span="6">
                    <FormItem label="紧急联系电话"  prop="emergency_phone">
                            <Input v-model="formData.emergency_phone" placeholder="请输入" clearable />
                        </FormItem>
                </Col>
            </Row>
    </BaseSegment>
        <BaseSegment title="家庭信息">
            <BaseFlexTable v-model="formData.family_members" style="width:100%" :columns="familyMemberColumns" />
        </BaseSegment>
        <BaseSegment title="教育经历" desc="请按时间顺序填写">
            <BaseFlexTable v-model="formData.education_careers" style="width:100%" :columns="educationColumns" />
        </BaseSegment>
        <BaseSegment title="工作经历" desc="请按时间顺序填写">
            <BaseMultiForm v-model="formData.careers" style="width:100%" :columns="careerColumns" />
        </BaseSegment>
        <BaseSegment title="项目经历" desc="请按时间顺序填写">
            <BaseMultiForm v-model="formData.project_careers" style="width:100%" :columns="projectColumns" />
        </BaseSegment>
        <BaseSegment title="获奖信息">
            <BaseFlexTable v-model="formData.awards" style="width:100%" :columns="awardsColumns" />
        </BaseSegment>
        <BaseSegment title="何时何地受到何种惩罚">
            <BaseFlexTable v-model="formData.punishments" style="width:100%" :columns="punishColumns" />
        </BaseSegment>
    </template>
    <template v-else-if="activeTab == 'employee'">
        <BaseSegment title="工作信息">
            <Row :gutter="20">
                <Col :span="6">
                    <FormItem label="参加工作"  prop="address">
                        <BaseDate v-model="formData.joinwork_time" placeholder="请输入" clearable />
                    </FormItem>
                </Col>
                <Col :span="6">
                    <FormItem label="入职时间"  prop="address">
                        <BaseDate v-model="formData.employee_date" placeholder="请输入" clearable />
                    </FormItem>
                </Col>
            </Row>
            <Row :gutter="20">
                <Col :span="6">
                    <FormItem label="隶属部门"  prop="phone">
                        <BaseDepSelect v-model="formData.dep.dep_id" placeholder="请输入" clearable />
                    </FormItem>
                </Col>
                
                <Col :span="6">
                    <FormItem label="岗位"  prop="address">
                        <Select v-model="formData.dep.position_id" transfer placeholder="请输入" clearable>
                            <Option :value="-1">领导</Option>
                            <Option :value="0">经理/总监(含副)</Option>
                            <Option :value="1">经理助理/总代</Option>
                            <Option :value="2">工程师级</Option>
                            <Option :value="3">助理级/员级</Option>
                            <Option :value="9">后勤</Option>
                        </Select>
                    </FormItem>后勤
                </Col>
            </Row>
    </BaseSegment>
    <BaseSegment title="劳动合同">
        <Row :gutter="20">
                <Col :span="6">
                    <FormItem label="雇佣类型"  prop="contract_type">
                        <Select v-model="formData.contract_type" placeholder="请输入" clearable />
                    </FormItem>
                </Col>
                <Col :span="6">
                    <FormItem label="合同类型"  prop="contract_type">
                        <Select v-model="formData.contract_type" placeholder="请输入" clearable />
                    </FormItem>
                </Col>
            </Row>
            <Row :gutter="20">
                
                <Col :span="6">
                    <FormItem label="生效时间"  prop="now_contract_effected_at">
                        <BaseDate v-model="formData.now_contract_effected_at" placeholder="请输入" clearable />
                    </FormItem>
                </Col>
                <Col :span="6">
                    <FormItem label="到期时间"  prop="now_contract_expired_at">
                        <BaseDate v-model="formData.now_contract_expired_at" placeholder="请输入" clearable />
                    </FormItem>
                </Col>
                <Col :span="6">
                    <FormItem label="签订次数"  prop="address">
                        <BaseDate v-model="formData.address" placeholder="请输入" clearable />
                    </FormItem>
                </Col>
                
            </Row>
    </BaseSegment>


    </template>
    <template v-else-if="activeTab == 'dismissions'">
      <BaseSegment title="离职信息">
            <BaseMultiForm v-model="formData.dismissions" style="width:100%" :columns="dismissionColumns" />
        </BaseSegment>
    </template>
    <template v-else-if="activeTab=='certification'">
        <BaseSegment title="证书信息">
            <BaseMultiForm v-model="formData.certifications" style="width:100%" :columns="certColumns" />
        </BaseSegment>
        <BaseSegment title="职称信息">
            <BaseMultiForm v-model="formData.jobtitles" style="width:100%" :columns="rankColumns" />
        </BaseSegment>
    </template>
    </Form>
    
</div>
<div class="flex-wrap flex-right" style="margin:10px 0;">
            <Button type="primary" icon="md-checkmark" @click="handleSubmit">提交</Button>
            <Button style="margin-left:10px;" @click=handleCancel>取消</Button>
        </div>
    </Container>
</template>

<script>
import Container from '@/components/app/container'
import PhotoEditor from '@/components/self/photo'
import {cloneDeep}  from 'lodash'
import BaseTabs from '@/components/app/tabs2'
import UTIL from '@/utils'
export default {
    components:{Container,PhotoEditor,BaseTabs},
    props:{
        value:{
            type:Object
        }
    },
    data(){
        return {
            formData:{},
            activeTab:'base',
        }
    },
    watch:{
        value:{
              handler(v){
                  if(v && v.id)
                       this.formData = cloneDeep(v)
                  else
                      this.initFormData()
                this.activeTab ='base'
              },
              deep:true,
              immediate:true
          }
    },
    methods:{
        initFormData(){
            this.formData = {
                gender:'男'
            }
        },  
        handleSubmit(){
              this.$refs.form.validate((valid)=>{
                  if(valid){
                     this.submitting = true
                      if(this.value && this.value.id){
                          let updateData = UTIL.                   gpt_compare_patch_object(this.value,this.formData,)
                      
                          if(!updateData){
                              this.handleCancel()
                              return
                          }
                          this.$emit('on-update',updateData)
                      }else{
                          this.$emit('on-create',this.formData)
                      }
                      
                  }
              })
          },
          handleCancel(){
            this.$emit('on-cancel')
          }
    },
    computed:{
        employeeTabs(){
            return [{
                icon:'user',
            key:'base',
            name:'个人信息'
            },{
                icon:'pm2',
            key:'certification',
            name:'证书及职称'
            },{
                icon:'organization',
            key:'employee',
            name:'入职信息'
            },{
                icon:'organization',
            key:'dismissions',
            name:'离职信息'
            },]
        },
        localValue(){
            return this.value
        },
        rules(){
            return {
                realname:[{required:true,message:'请输入员工姓名'}],
                profile_code:[{required:true,message:'请输入员工档案编号'}]
            }
        },
        educationColumns(){
            return [{
          name:"序",
          key:'index',
          control:"index",
          span:1
        },{
          name:"拖",
          key:'drag',
          control:"drag",
          span:1
        },{
          key:"started_at",
          name:"起始时间",
         
          span:4,
          control:"BaseDate",  
          option:{
            size:"small",
            clearable:true
          }
        },{
          key:"finished_at",
          name:"结束时间",
         
          span:4,
          control:"BaseDate",  
          option:{
            size:"small",
            clearable:true
          }
        },{
          key:"position",
          name:"类型",
         
          span:3,
          control:"AutoComplete",  
          option:{
            transfer:true,
            data:['中专','高中','大专','专升本','大学','研究生'],
            size:"small",
            clearable:true
          }
        },{
          key:"school_name",
          name:"毕业院校",
         
          span:6,
          control:"Input",  
          option:{
            size:"small",
            clearable:true
          }
        }]
        },
        awardsColumns(){
            return [{
          name:"序",
          key:'index',
          control:"index",
          span:1
        },{
          name:"拖",
          key:'drag',
          control:"drag",
          span:1
        },{
          key:"aquired_at",
          name:"时间",
         
          span:4,
          control:"BaseDate",  
          option:{
            size:"small",
            clearable:true
          }
        },{
          key:"content",
          name:"获奖内容",
         
          span:10,
          control:"Input",  
          option:{
            size:"small",
            clearable:true
          }
        }]
        },
        punishColumns(){
            return [{
          name:"序",
          key:'index',
          control:"index",
          span:1
        },{
          name:"拖",
          key:'drag',
          control:"drag",
          span:1
        },{
          key:"aquired_at",
          name:"时间",
         
          span:4,
          control:"BaseDate",  
          option:{
            size:"small",
            clearable:true
          }
        },{
          key:"content",
          name:"惩罚内容",
         
          span:10,
          control:"Input",  
          option:{
            size:"small",
            clearable:true
          }
        }]
        },
        rankColumns(){
            return [{
          key:"type",
          name:"职称类型",
          span:4,
          control:"BaseSelect",  
          option:{
            size:"small",
            options:["工程类","非工程类"],
            clearable:true
          }
        },{
          key:"level",
          name:"职称等级",
         
          span:4,
          control:"Input",  
          option:{
            size:"small",
            options:["初级","中级","高级","正高级"],
            clearable:true
          }
        },{
          key:"cert_id",
          name:"职称编号",
         
          span:4,
          control:"Input",  
          option:{
            size:"small",
            clearable:true
          }
        },{
          key:"subject",
          name:"专业",
         
          span:4,
          control:"Input",  
          option:{
            size:"small",
            clearable:true
          }
        },{
          key:"accquired_at",
          name:"取得时间",
         
          span:3,
          control:"BaseDate",  
          option:{
            size:"small",
            clearable:true
          }
        },{
          key:"is_first",
          name:"第一",
          span:2,
          control:"Checkbox",  
          option:{
            size:"small",
            clearable:true
          }
        },]
        },
        certColumns(){
            return [{
          key:"cert_type_id",
          name:"证书类型",
          span:4,
          control:"BaseSelect",  
          option:{
            remote:'/oa/certification-types',
            size:"small",
            clearable:true
          }
        },{
          key:"cert_id",
          name:"证书编号",
         
          span:3,
          control:"Input",  
          option:{
            size:"small",
            clearable:true
          }
        },{
          key:"subject",
          name:"专业",
         
          span:3,
          control:"Select",  
          option:{
            size:"small",
            clearable:true
          }
        },{
          key:"company_type",
          name:"所属",
         
          span:2,
          control:"Select",  
          option:{
            size:"small",
            valueType:'name',
            options:["监理","咨询","挂靠"],
            clearable:true
          }
        },{
          key:"started_at",
          name:"取得时间",
         
          span:3,
          control:"BaseDate",  
          option:{
            size:"small",
            clearable:true
          }
        },{
          key:"started_at",
          name:"到期时间",
         
          span:3,
          control:"BaseDate",  
          option:{
            size:"small",
            clearable:true
          }
        },]
        },
        careerColumns(){
            return [{
          key:"started_at",
          name:"起始时间",
         
          span:4,
          control:"BaseDate",  
          option:{
            size:"small",
            clearable:true
          }
        },{
          key:"finished_at",
          name:"结束时间",
         
          span:4,
          control:"BaseDate",  
          option:{
            size:"small",
            clearable:true
          }
        },{
          key:"company_name",
          name:"工作单位",
         
          span:6,
          control:"Input",  
          option:{
            size:"small",
            clearable:true
          }
        },{
          key:"position",
          name:"职务",
         
          span:4,
          control:"Input",  
          option:{
            size:"small",
            clearable:true
          }
        },{
          key:'note',
          name:'描述',
          control:"Input",
          width:'100%',
          option:{
            size:'small',
            type:'textarea',
            rows:2,
            maxlen:255
          }
        }]
        },
        projectColumns(){
            return [{
          key:"started_at",
          name:"起始时间",
         
          span:4,
          control:"BaseDate",  
          option:{
            size:"small",
            clearable:true
          }
        },{
          key:"finished_at",
          name:"结束时间",
         
          span:4,
          control:"BaseDate",  
          option:{
            size:"small",
            clearable:true
          }
        },{
          key:"project_name",
          name:"项目名称",
         
          span:6,
          control:"Input",  
          option:{
            size:"small",
            clearable:true
          }
        },{
          key:"position",
          name:"职务",
         
          span:4,
          control:"Input",  
          option:{
            size:"small",
            clearable:true
          }
        },{
          key:'note',
          name:'描述',
          control:"Input",
          width:'100%',
          option:{
            size:'small',
            type:'textarea',
            rows:2,
            maxlen:255
          }
        }]
        },
        dismissionColumns(){
            return [{
          key:"lastWorkDay",
          name:"离职时间",
         
          span:4,
          control:"BaseDate",  
          option:{
            size:"small",
            clearable:true
          }
        },{
          key:"top_dep",
          name:"离职时事业部",
         
          span:6,
          control:"Input",  
          option:{
            size:"small",
            clearable:true
          }
        },{
          key:"main_dep_id",
          name:"离职时部门",
         
          span:6,
          control:"Input",  
          option:{
            size:"small",
            clearable:true
          }
        },{
          key:"position",
          name:"离职时职位",
         
          span:6,
          control:"Input",  
          option:{
            size:"small",
            clearable:true
          }
        },{
          key:'reason',
          name:'离职原因',
          control:"Input",
          width:'100%',
          option:{
            size:'small',
            type:'textarea',
            rows:2,
            maxlen:255
          }
        }]
        },
        familyMemberColumns(){
            return [{
          name:"序",
          key:'index',
          control:"index",
          span:1
        },{
          name:"拖",
          key:'drag',
          control:"drag",
          span:1
        },{
          key:"relation",
          name:"关系",
         
          span:3,
          control:"AutoComplete",  
          option:{
            transfer:true,
            data:['父亲','母亲','兄妹'],
            size:"small",
            clearable:true
          }
        },{
          key:"name",
          name:"姓名",
         
          span:3,
          control:"Input",  
          option:{
            size:"small",
            clearable:true
          }
        },{
          key:"work_place",
          name:"工作单位",
          span:8,
          control:"Input",  
          option:{
            size:"small",
            clearable:true
          }
        }]
        }
    }
}
</script>

<style>

</style>