<template>
    <Container>
      <div class="flex-wrap flex-between" style="padding:0 10px">
      <Toolbar 
        :items="tools"
        v-model="filter"
        :counts="counts"
        @on-event="handleEvent"
      >
        
      </Toolbar>
      <div class="flex-wrap">
      <Button style="margin-right:5px" icon="md-add" type="primary" @click="showCreateForm">新增锁定信息</Button>
      <Button style="margin-left:5px" icon="md-download" @click="exportExcel" /> 
      </div>
    </div>
    
      
      <div class="table-wrap" style="flex-grow:1;position;relative">
        <BaseTable 
          ref="table"
          :columns="columns"
          :data="items"
          :page="page"
          border
          :page-size="pagesize"
          @event="handleEvent"
          :loading="loading"
          :count="total"
        ></BaseTable>
      </div>
  
      <Editor
        ref="editor" 
        v-model="showEditor"
        :data="current"
        :loading="loadingEditor"
        
        model="OA_CertificationLock"
        @on-event="handleEvent"
      />
  
    </Container>
  </template>
  
  <script>
  import Container from '@/components/app/container'
  import Toolbar from '@/components/app/toolbar'
  import Editor from '@/components/model/editor'
  import moment from 'moment'
  import {cloneDeep} from 'lodash'
  export default {
    routerOption: {
      as_default: true
    },
    components:{Container,Toolbar,Editor},
    data(){
      return {
        filter:{ },
        showEditor:false,
        loadingEditor:false,
        showFlowEditor:false,
        flowFormData:{},
        items:[],
        loading:false,
        current:{},
        formData:{},
        page:1,
        pagesize:20,
        total:10,
        counts:{
          '0':0,
          '1':0
        },
        order:{
          key:'created_by',
          order:'desc'
        },
        deps:[]
      }
    },
    methods:{
      showCreateForm(){
        this.current = {}
        this.formData = {}
        this.showEditor = true
      },
      Edit(item){
        this.loadingEditor = true
        this.showEditor = true
        this.$api.get(`oa/certification-locks/${item.id}?edit=1`).then(res=>{
          this.current = res.data.data
          this.loadingEditor = false
          this.formData = cloneDeep(this.current)
          this.showEditor = true
        }).catch(e=>{
          this.loadingEditor = false
          this.loadingEditorError = true
        })
       
      },
      exportExcel(){
        this.$refs.table.exportExcel()
      },
      getStatusCounts(){
        this.$api.getCounts("/oa/certification-locks",{
          count_by:'locked'
        }).then(res=>{
          let counts= res.data.data
          let transCounts = {}
          if(counts['false'])
            transCounts['0'] = counts['false']
          if(counts['true'])
            transCounts['1'] = counts['true']
          this.counts = Object.assign(this.counts,transCounts)
        })
      },
      getData(){
        this.loading = true
        this.$api.afterLogin(()=>{
          this.$api.getPaged(
            "/oa/certification-locks",{
            page:this.page,
            pagesize:this.pagesize,
            order:this.order,
            filter:this.filter,
            related:['project','cert','certType']
        }).then(res=>{
          let {count,list} = res.data.data
          list.forEach(v=>{
            if(v.cert && v.cert.certType && v.cert.certType.name){
              v.cert_name = v.cert.certType.name
            }
  
            if(v.cert && v.cert.cert_id){
              v.cert_code = v.cert.cert_id
              v.cert_subject = v.cert.subject
            }
          })
          this.items = list
          this.total = count
          this.loading = false
        })
  
        this.$api.get("/deps?q=subject").then(res=>{
          this.deps = res.data.data
        })
        })
        
      },
      createItem(model){
        this.$api.post('/oa/certification-locks',model).then(res=>{
          this.$Notice.success({title:"创建成功"})
          this.showEditor = false
          this.getData()
        }).finally(()=>{
          this.$refs.editor.stopLoading()
        })
      },
      updateItem(model){
        let id =  this.current.id
        this.$api.patch(`/oa/certification-locks/${id}`,model).then(res=>{
          this.$Notice.success({title:"修改成功"})
          this.showEditor = false
          this.getData()
        }).finally(()=>{
          this.$refs.editor.stopLoading()
        })
      },
      handleDelete(item){
        this.Confirm(`即将删除此项目<span style="color:var(--error)">${item.type}</span>，是否继续?`,()=>{
          this.$api.delete("/oa/certification-locks/"+item.id).then(res=>{
            this.$Notice.success({title:"删除成功"})
            this.getData()
          })
        })
      },
      createFlow(task){
        this.flowFormData = {
          target_id:task.id,
          
        }
        this.showFlowEditor = true
      },
      handleEvent({type,key,value,data}){
        const eventMap = {
          add:this.showCreateForm,
          search:this.getData,
          create:this.createItem,
          refresh:()=>{
            this.getStatusCounts()
            this.getData()
  
          },
          update:this.updateItem,
          'tab-change':this.getData,
          'page-change':()=>{
            this.page = data
            this.getData()
          },
          'page-size-change':()=>{
             this.pagesize = data
            this.getData()
          },
          'sort':()=>{
             if(!data || !data.key)
              this.order = {
                key:'created_at',
                order:'desc'
              }
            else
              this.order = data
            this.getData()
          },
          'clearFilter':()=>{
            this.filter={status:this.filter.status}
            this.getData()
          }
        }
  
  
        let eventHandler = eventMap[type]
        if(eventHandler){
          eventHandler(value)
        }
      },
    },
    computed:{
      tools(){
        return [{
          key:'search',
          placeholder:"输入人员/证书名称",
          type:"search",
          width:300
        },{
          key:"locked",
          type:"tabs",
          countable:true,
          subs:[
            {
                    key:'1',
                    name:'锁定中'
                  }, {
                    key:'0',
                    name:'已解除'
                  }]
        
        },{
          key:'clearFilter',
          type:"clear-button"
        },{
          key:'refresh',
          icon:'md-refresh',
          type:"icon-button"
        },]
      },
      columns(){
        var that = this
        return [
            {type:'index',width:80,title:"序号"},
            {type:'user',key:"user_id",width:120,title:"锁定人员",sortable:"custom"},
            {key:'name',minWidth:300,title:"项目名称",sortable:"custom",
              render:(h,{row})=>{
                if(!row.project_id)
                  return h('span','-')
                let tag = h('img',{attrs:{
                   src: "https://nbgz-pmis-1257839135.cos.ap-shanghai.myqcloud.com/beauty/new.gif"
                },style:'width:30px;height:15px;margin-left:10px;'})
  
                if(moment(row.created_by).isBefore(moment().subtract(3,'days'))){
                    tag = ''
                }
  
                let name = h('div',{style:"white-space:nowrap;text-overflow:ellipsis;margin-left:5px;width:calc(100% - 30px;);overflow:hidden;"},row.project.name)
                return h('div',{
                  class:"flex-wrap",
                  style:{
                    color:"var(--primary)",
                    fontWeight:'bold',
                    textAlign:"left"
                  },
                },[name,tag])
              }
            },
            {
                key:'cert_name',
                title:"证书名称",
                align:'center',
                width:160,
            },
            {
                key:'cert_code',
                title:"证书编号",
                align:'center',
                width:160,
            },
            {
                key:'cert_subject',
                title:"专业",
                align:'center',
                width:160,
            },
            {
              type:'state',key:'locked',width:120,
              title:'证书状态',
              option:{
                states:['已解除','已锁定'],
                colors:['var(--subtext2)','var(--error)']
              }
            },
            {type:'time',key:"started_at",width:120,title:"开始时间",sortable:"custom",option:{
              type:'date'
            }},
            {type:'time',key:"finished_at",width:120,title:"结束时间",sortable:"custom",option:{
              type:'date'
            },render:(h,{row})=>{
              if(!row.finished_at)
                return h('span','-')
              let color = 'var(--primary)'
              if(moment().isAfter(row.finished_at)){
                color = 'var(--subtext2)'
                if(row.locked){
                   color = 'var(--warning)'
                }
              }else if(!row.locked){
                color = 'var(--error)'
              }
              
              return h('span',{style:{color}},moment(row.finished_at).format("YYYY-MM-DD"))
            }},
            {type:'user',key:"created_by",width:100,title:"录入人",sortable:"custom"},
            {type:'time',key:"created_at",width:100,title:"录入时间",sortable:"custom",option:{
              type:'date'
            }},
            {key:"tools",minWidth:140,title:"操作",sortable:false,
              render:(h,{row})=>{
                let buttons = [
                  h('Button',{props:{size:"small"},on:{click:()=>{that.Edit(row)}}},"修改"),
                  h('Button',{props:{size:"small"},on:{click:()=>{that.handleDelete(row)}}},"删除")
                ]
                return h('div',{class:"flex"},[buttons])
              }
            },
          ]
      }
    },
    mounted(){
      
      this.$api.afterLogin(()=>{
        this.getData()
        this.getStatusCounts()
        this.$nextTick(()=>{
          if (this.$refs && this.$refs.table && this.$refs.table.calcTableHeight)
            this.$refs.table.calcTableHeight()
        })
      })
     
    },
  }
  </script>
  
  <style lange="less">
  .filter-box{
    height:50px;
  }
  
  .table-wrap{
    height:calc(100% - 70px);
    position: relative;
  }
  
  </style>