<template>
  <div class="flex-wrap flex-line container align-stretch">
    <div style="width:200px;min-width:200px;border-right:1px solid var(--border);">
      <BaseMenu :menus="menus" :actived="actived" @on-click="handleOpen"></BaseMenu>
    </div>
    <div style="flex-grow:1;padding:0 5px;">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  export default {
    routerOption:{
      as_frame:true,
      as_default:true,
      redirect:'positions'
    },
    computed:{
      menus(){
        return [{
          name:"总体配置",
          key:"positions",
          icon:"organization"
        },{
          name:'权限管理',
          key:"list",
          icon:"contact"
        }]
      },
      actived(){
        return this.$route.path.slice(this.$route.path.lastIndexOf('/')+1)
      }
    },
    methods:{
      handleOpen(e){
        this.RouteTo(e)
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>