<template>
    <Avatar :src="localSrc" v-bind="$attrs">
      <slot></slot>
    </Avatar>
  </template>
  
  <script>
  export default {
    inheritAttrs: false, // 禁用默认行为，避免将 $attrs 绑定到根元素上
    props: {
      src: {
        type: String | null,
      },
    },
    data() {
      return {
        // 初始显示的占位图
        localSrc: 'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/loading.gif',
        observer: null, // 用于懒加载的观察器
      };
    },
    watch: {
      // 监听 src 变化，重新进行懒加载
      src: {
        immediate: true,
        handler(val) {
          // 如果 src 发生变化，重新执行懒加载逻辑
          if(val)
            this.initLazyLoad();
        },
      },
    },
    mounted() {
      // 在组件挂载时初始化懒加载逻辑
      this.initLazyLoad();
    },
    beforeDestroy() {
      // 在组件销毁时，停止观察
      if (this.observer) {
        this.observer.disconnect();
      }
    },
    methods: {
      initLazyLoad() {
        // 确保每次都停止上一次的观察
        if (this.observer) {
          this.observer.disconnect();
        }
  
        // 将图片设置为占位图
        this.localSrc = 'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/loading.gif';
  
        // 获取实际的 img 元素
        this.$nextTick(() => {
          const imgElement = this.$el.querySelector('img'); // 假设 Avatar 包含 img 标签
          if (!imgElement) {
            console.error('没有找到 img 元素');
            return;
          }
  
          // 创建一个新的 IntersectionObserver
          this.observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            if (entry.isIntersecting) {
              // 进入视口，加载真实的图片
              this.localSrc = this.src;
              this.observer.unobserve(imgElement); // 加载完成后停止观察
            }
          });
  
          // 开始观察 img 元素
          this.observer.observe(imgElement);
        });
      },
    },
  };
  </script>
  
  <style>
  /* 你可以根据需要在这里添加样式 */
  </style>
  