// directives/droppable.js
export default {
    bind(el, binding) {
      // 处理 dragover 事件，确保能够放置
      el.addEventListener('dragover', (event) => {
        el.classList.add('ugo-droppable')
        event.preventDefault(); // 必须调用 preventDefault 以允许放置
      });

      el.addEventListener('dragleave',(event)=>{
        el.classList.remove('ugo-droppable')
        event.preventDefault(); // 必须调用 preventDefault 以允许放置
      })
  
      // 处理 drop 事件，触发提供的函数
      el.addEventListener('drop', (event) => {
        el.classList.remove('ugo-droppable')
        event.preventDefault();
        const draggedData = event.dataTransfer.getData('text'); // 获取拖拽的数据
        binding.value(draggedData, el.dataset); // 调用传入的回调函数
      });
    }
  };
  