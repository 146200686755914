<template>
    <div class="full-container" style="border:1px solid var(--border);position:relative;overflow:hidden;">
       
        <div class="full-container" ref="canvas" style="position:absolute;left:0;right:0;top:0;bottom:0;"></div>
        <Button size="small" style="position:absolute;right:65px;bottom:5px;" @click="showFullModal">
            查看
        </Button>
        <Button size="small" style="position:absolute;right:5px;bottom:5px;" @click="fitView">
            居中
        </Button>

        <Modal v-model="showFull" title="流程图" footer-hide width="900">
            <div ref="modal-canvas" style="width:100%;height:600px;position:relative;">

            </div>
        </Modal>
    </div>
</template>

<script>
import LogicFlow from '@logicflow/core';
import '@logicflow/core/dist/index.css';
import '@logicflow/extension/dist/index.css';

import nodes from './editor/components/nodes/index'
export default {
data() {
    return {
    lf: null,
    lf_data:null,
    showFull:false,
    }
},
props:['data'],
watch:{
    data:{
        handler(v){
            if(v){
                this.lf_data = {
                    nodes:v.nodes,
                    edges:v.edges
                }
                this.Init()
            }
        },
        deep:true,
        immediate:true
    }
},
mounted(){
    this.Init()
},
methods:{
    showFullModal(){
        this.showFull = true
        this.InitModal()
    },
    fitView(){
        this.lf.fitView(100,100)
    },
    InitModal(){
        if (this.mlf) {
            this.mlf.render(this.lf_data);
            this.mlf.translateCenter();
            this.mlf.fitView(100,100)
            return
        }
        
        this.$nextTick(() => {
            let container = this.$refs['modal-canvas']
            if(!container)
                return
            this.mlf = new LogicFlow({
            container,
            width: container.clientWidth,
            height: container.clientHeight,
            background: { color: '#F0F0F0' },
            grid: { type: 'dot', size: 20 },
            textEdit: false,
            isSilentMode: true,
            edgeType: 'polyline',
            stopScrollGraph:true,
            animation:true,
            snapline: true,
            stopMoveGraph: false,
            adjustEdgeStartAndEnd:true,
            history:true,
            outline:true,
            style: { rect: { radius: 6 } },
            
            });

            nodes.forEach(n=>this.mlf.register(n))

            this.mlf.render(this.lf_data);
            this.mlf.translateCenter();
            this.mlf.fitView(100,100)
            this.mlf.on('blank:click',e=>{
            this.selected = null
            })
            this.mlf.on('element:click', (event) => {
            const { data } = event;
            this.selected = data
        });

            this.$emit('on-refresh')
        });
    },
    Init() {
        if (this.lf) {
            delete this.lf;
        }
        
        this.$nextTick(() => {
            let container = this.$refs.canvas
            if(!container || container.clientHeight == 0 || container.clientWidth == 0)
                return
            this.lf = new LogicFlow({
            container,
            width: container.clientWidth,
            height: container.clientHeight,
            background: { color: '#F0F0F0' },
            grid: { type: 'dot', size: 20 },
            textEdit: false,
            isSilentMode: true,
            edgeType: 'polyline',
            stopScrollGraph:true,
            animation:true,
            snapline: true,
            stopMoveGraph: true,
            adjustEdgeStartAndEnd:false,
            style: { rect: { radius: 6 } },
            
            });

            nodes.forEach(n=>this.lf.register(n))

            this.Render();
            this.lf.translateCenter();
            this.lf.fitView(100,100)
            this.lf.on('blank:click',e=>{
            this.selected = null
            })
            this.lf.on('element:click', (event) => {
            const { data } = event;
            this.selected = data
        });

            this.$emit('on-refresh')
        });
    },
    Render(data) {
        if(data){
            this.lf.render(data)
        }else if(this.lf_data){
            this.lf.render(this.lf_data)
        }else{
            this.lf.render();
        }
    
    },
}
}
</script>

<style>

</style>