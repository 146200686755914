<template>
  <FormItem :label="label" :required="required">
    <BaseFileSelectFull
        :value="value"
        v-bind="option"
        @input="$emit('input',$event)"
    />
  </FormItem>
</template>

<script>
import composedMixin from '../mixins/composed'
import defaultValueMixin from '../mixins/default-value'
  export default {
    mixins:[defaultValueMixin,composedMixin],
    cname:"文件",
    corder:61,
    icon:'fujian',
    properties:[],
    props:['fkey','label','value','option','old','required'],
  }
</script>

<style lang="scss" scoped>

</style>