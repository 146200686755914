<style lang="less">
.pd-embed-control{
  .ivu-select{
    background:none;
    width:100%;
    border:none;
    height:100%;
    margin:0;
    background:rgb(239, 239, 201);
    border-radius: 0;
    .ivu-select-selection{
       background:none;
    width:100%;
    border:none;
    height:100%;
    margin:0;
    
    border-radius: 0;
    }

   
  }

  
}

.pm-embed-control{
  .ivu-dropdown-rel{
    background:none;
  }
}

.ivu-select-dropdown,.ivu-dropdown-menu{
  background:#fff !important;
  
}

.ivu-dropdown-item{
  padding:0 !important;
}
</style>

<template>
  <div class="pd-embed-control">
    <Dropdown trigger="click" :disabled="!editable" placement="bottom-start" transfer style="width:100%;height:100%;position:relative;"  @on-click="handleChange">
      <div class="flex-wrap" style="width:100%;height:60px;padding:10px;cursor:pointer;">
        <template v-if="!value">
          请选择
        </template>

        <template v-else-if="selected.label">
          <div class="flex-wrap flex-center" style="background:#39f;width:40px;height:40px;margin-right:10px">
          <Icon :custom="`gzicon gzi-${selected.icon}`" color="#fff" style="" size="25"></Icon>
          </div>
          <div>
            <div style="font-size:16px;line-height:20px;">{{selected.label}}</div>
            <div style="font-size:10px;color:#aaa">{{selected.desc}}</div>
          </div>
          
        </template>
        <template v-else>
          {{value}}
        </template>
      </div>
      <template #list>
      <DropdownMenu>
        <template v-for="o in options">
        <DropdownItem :name="o.value" :key="o">
          <div class="flex-wrap" style="width:200px;height:40px;border-bottom:1px solid #ddd;padding:10px;" :style="value==o.value?'background:#39f;color:#fff':''">
          <Icon :custom="`gzicon gzi-${o.icon}`" style="margin-right:10px" size="25"></Icon>
          <div>
            <div>{{o.label}}</div>
          </div>
          </div>
        </DropdownItem>
        </template>
      </DropdownMenu>
      </template>
    </Dropdown>
  </div>
</template>

<script>
  export default {
    props:['value','editable','option'],
    mounted(){
      if(this.option.defaultTo  && this.value == undefined){
        this.value = this.option.defaultTo 
        this.$emit('input',this.option.defaultTo )
      }
    },
    computed:{
      options(){
        let option = this.option || {}
        if(option.getters)
        {
          let data = this.$store.getters[this.option.getters]
          if(Array.isArray(data)){
            return data.map(v=>{
              return {
                label:v[this.option.labelKey || 'name'],
                value:v[this.option.valueKey || 'id']
              }
            })
          }
        }else if(option.options){
          return option.options.map(v=>({
            label:v.name,
            icon:v.icon,
            value:v.value || v.name,
            desc:v.desc
          }))
        }
        return []
      },
      selected(){
        return this.options.find(v=>v.value == this.value) || {}
      }
    },
    methods:{
      handleChange(e){
        this.value = e
        this.$emit('input',e)
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>