<template>
  <div class="flex-wrap app-title" :class="dark?'app-title-dark':''">
    <BaseIcon :icon="icon" v-if="icon" size="20" style=""></BaseIcon>
    <div style="margin-left:8px">{{name}}</div>
  </div>
</template>

<script>
  export default {
    props:['icon','name','dark']
  }
</script>

<style lang="less">
.app-title{
  font-size:16px;
  color:var(--text3);
  i{
     color:var(--text3);
  }
}

.app-title-dark{
  font-size:16px;
  color:var(--text);
  i{
     color:var(--text);
  }
}
</style>