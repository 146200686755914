<template>
<div class="tags-input" style="margin-bottom:3px;">
    <BaseTag2 style="margin-right:5px;" v-for="(t,i) in list" :key="i" :closable="true" @on-close="remove(i)">{{ t }}</BaseTag2>
    <AutoComplete type="text" 
        style="width:200px;"
        :placeholder="placeholder || '请输入标签(按回车创建)'" 
        v-model="inputValue" 
        :filter-method="filterMethod"
        @keyup.enter.native="add()" 
        @on-select="add($event)"
        :data="tags"
    />
</div>
</template>

<script>
export default {
    names: "TagsInput",
    props: {
        value: Array,
        placeholder: null,
        tags: Array
    },
    data() {
        return {
            inputValue: "",
            list: []
        };
    },
    watch: {
        value: {
            handler(newVal) {
                if (Array.isArray(newVal)) 
                    this.list = [...newVal]
                else 
                    this.list = []
            }
        }
    },
    methods: {
        filterMethod(value,option){
            return option.indexOf(value.toUpperCase()) !== -1;
        },
        add(val) {
            let value = (val || this.inputValue)
            if(!value)
                return
            
            if (value && !this.list.includes(value)) {
                this.list.push(value)
                this.$emit("input", [...this.list])
            }
            this.inputValue = ""
        },
        remove(i) {
            this.list.splice(i, 1);
        }
    }
};
</script>

<style lang="less">
.tags-input {
    display: flex;
    align-items: center;
    flex-wrap:wrap;
    background:var(--bg3);
    border:1px solid var(--border);
    border-radius:3px;

    .ivu-input {
        border: none;
        background: none;
        flex-grow: 1;
        min-width: 100px;
    }
}
</style>
