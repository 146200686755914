<template>
    <div style="width:1000px;margin:20px auto;">
    <div class="flex-wrap flex-between" style="padding-right:10px">
    <h1>{{item.name}}</h1>
      </div>
    <div style="padding:20px" v-if="item"> 
     <BaseSegment title="厂商信息">
            <Row>
              <Col :span="12">
                
              <div class="l-cell">
                <div class="l-label">企业性质</div>
                <div class="l-value-wrap">
                  {{item.ent_type}}
                </div>
              </div>

               <div class="l-cell">
                <div class="l-label">注册资金</div>
                <div class="l-value-wrap">
                  {{item.registered_funds_unit || '-'}} 万元
                </div>
              </div>

               <div class="l-cell">
                <div class="l-label">地址</div>
                <div class="l-value-wrap">
                  <div class="l-value">{{item.address}}</div>
                </div>
              </div>

              <div class="l-cell">
                <div class="l-label">联系人</div>
                <div class="l-value-wrap" v-if="item.contacts">
                  <template v-for="c in item.contacts">
                    
                  <div class="l-value" style="margin-right:10px;" :key="c.name">{{c.name}} (<span style="color:var(--primary)">{{c.phone || '-'}})</span></div>
                  </template>
                </div>
              </div>
              </Col>
              </Row>
              <Row>
                <Col :span="12">
                 <div class="l-cell">
                <div class="l-label">公司类型</div>
                <div class="l-value-wrap">
                  <div class="l-value">{{item.type}}</div>
                </div>
              </div>

              
                </Col>
              </Row>
        </BaseSegment>

<BaseSegment :title="`该厂商的品牌列表(${item.brands.length})`">
<div>
      <Row style="margin:5px 0;padding-bottom:5px;border-bottom:1px solid var(--border)">
        <Col :span="3" align="center">
        图示</Col>
        <Col :span="9">
        品牌名称</Col>
        <Col :span="2"  align="left">
        产地</Col>
        <Col :span="3" align="right">
        参考价</Col>
        <Col :span="3"  align="center">
        询价时间</Col>
        <Col :span="3"  align="center">
        询价方式</Col>
        <Col :span="1"  align="center">
        收藏</Col>
      </Row>
      <div style="position:relative">
        <BaseEmpty v-if="item.brands.length == 0" />
      <template v-for="item in item.brands">
         <Row :key="item.id" style="margin:5px 0;padding:5px 0;border-bottom:1px dashed var(--border)" align="middle">
          <Col :span="3" align="center">
        <img style="width:60px;height:60px;border-radius:5px;border:1px solid var(--border)" :src="item.avatar || 'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/empty-img.png'" /></Col>
        <Col :span="9">
        <div>
        <div style="font-weight:bold"><a @click="RouteTo(`/core/materials/${item.id}/brand`)">{{item.name}}</a></div>
        <div style="color:var(--subtext3)">{{item.model}}</div>
        </div>
        </Col>
        
        <Col :span="2"  align="center">
        {{item.product_area}}</Col>
        <Col :span="3"  align="">
        <BaseAmount full :value="item.last_price?item.last_price.amount:0" />
        </Col>
        <Col :span="3"  align="center">
        {{item.last_price?moment(item.last_price.accquired_at).fromNow():'-'}}</Col>
        <Col :span="3" align="center">
        {{item.last_price?ACC_TYPES[item.last_price.type]:'-'}}</Col>
          <Col :span="1"  align="center">
        <Button icon="md-add" @click="addToCart(item)"></Button></Col>
      </Row>
      </template>
     </div>
     </div>
</BaseSegment>


          <BaseSegment :title="`该厂商的产品列表(${item.products.length})`">
    <div>
      <Row style="margin:5px 0;padding-bottom:5px;border-bottom:1px solid var(--border)">
        <Col :span="3" align="center">
        图示</Col>
        <Col :span="9">
        名称/型号</Col>
        <Col :span="2"  align="left">
        产地</Col>
        <Col :span="3" align="right">
        参考价</Col>
        <Col :span="3"  align="center">
        询价时间</Col>
        <Col :span="3"  align="center">
        询价方式</Col>
        <Col :span="1"  align="center">
        收藏</Col>
      </Row>
      <div style="position:relative">
        <BaseEmpty v-if="item.products.length == 0" />
      <template v-for="item in item.products">
         <Row :key="item.id" style="margin:5px 0;padding:5px 0;border-bottom:1px dashed var(--border)" align="middle">
          <Col :span="3" align="center">
        <img style="width:60px;height:60px;border-radius:5px;border:1px solid var(--border)" :src="item.avatar || 'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/empty-img.png'" /></Col>
        <Col :span="9">
        <div>
        <div style="font-weight:bold"><a @click="RouteTo(`/core/materials/${item.id}/info`)">{{item.name}}</a></div>
        <div style="color:var(--subtext3)">{{item.model}}</div>
        </div>
        </Col>
        
        <Col :span="2"  align="center">
        {{item.product_area}}</Col>
        <Col :span="3"  align="">
        <BaseAmount full :value="item.last_price?item.last_price.amount:0" />
        </Col>
        <Col :span="3"  align="center">
        {{item.last_price?moment(item.last_price.accquired_at).fromNow():'-'}}</Col>
        <Col :span="3" align="center">
        {{item.last_price?ACC_TYPES[item.last_price.type]:'-'}}</Col>
          <Col :span="1"  align="center">
        <Button icon="md-add" @click="addToCart(item)"></Button></Col>
      </Row>
      </template>
     </div>
     </div>
     </BaseSegment>
      
     <BaseSegment title="产品手册等其他资料">
      <div class="flex-wrap">
        <template v-for="f in item.files">
          <div class="lf-item" :key='f.id'>
            <img style="width:140px;height:180px;border:1px solid var(--border);border-radius:5px" :src="f.avatar ||  'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/empty-img.png'" />
            <a class="f-name" @click="Download(f.url)">
              {{f.name}}
            </a>
          </div>
        </template>
      </div>
     </BaseSegment>
    </div>
    
    <Drawer v-model="preview" :width="800">
      <BasePreview :url="previewURL" />
    </Drawer>
    </div>
    </div>
</template>

<script>

  export default {
    data(){
      return {
        ACC_TYPES:["电话","聊天","询价单","其他"],
        preview:false,
        previewURL:"",
        showCart:false,
        LEVELS:['L1','L2','L3'],
        item:{}
      }
    },
    routerOption:{
      as_object:true
    },
    computed:{
      id(){
        return this.$route.params.id
      },
    },
    mounted(){
      setTimeout(()=>{
        this.getData()
      },500)
    },
    methods:{
      add_cart(){
        this.$store.commit('mat/add_cart',this.item)
        this.$Notice.success({title:"添加成功"})
      },
      getData(){
        this.$api.get(`mat/companies/${this.id}?full=2`).then(res=>{
          this.item = res.data.data
        })
      }
    }
  }
</script>

<style lang="less" scoped>
.lf-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width:180px;
  img{
    margin-bottom:10px;
  }
}

.l-cell{
  display: flex;
  align-items: flex-start;
  padding:5px 0;
  .l-label{
    font-weight: bold;
    margin-right:15px;
    min-width:80px;
    text-align: right;
  }
  .l-value-wrap{
    display: flex;

    .l-unit{
      color:var(--subtext3);
    }
  }
}
</style>