<style lang="less">
.flow-panel-card{
    padding:0 10px;background:var(--border);color:var(--text3);height:50px;width:calc(50% - 10px);border-radius:5px;
    border:2px solid var(--border);
    cursor: pointer;
    .l-title{
        display: flex;
        align-items: center;
        i{
            font-size:25px;
            margin-right:10px;
        }
        font-size:15px;
        font-weight:bold;
    }

    .l-count{
        font-size:18px;
        white-space:nowrap;
    }

    .l-border{
        width:30px;
        height:30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background:var(--border);
        border-radius:5px;
        color:var(--text2);
    }
}

.flow-panel-card-empty{
    background:var(--border) !important;
    color:var(--subtext2) !important;
    .l-count{
        color:var(--subtext2);
    }
}

.flow-panel-card:hover{
    border-color:var(--primary);
    transition:border-color 0.5s;
}

.flow-panel-card:active{
    position: relative;
    right:-1px;
    bottom:-1px;
    filter:brightness(0.9);
}
</style>
<template>
  <div class="container">
    <div class="flex-wrap flex-between" style="width:100%;height:30px;font-weight:bold;">
        <div class="flex-wrap">
      <BaseIcon icon="md-apps" style="margin-right:5px" />
      我的工作
    </div>
    <div class="flex-wrap">
      <span style="margin-right:10px;"><BaseIcon icon="ios-more" :size="20" /></span>
    </div>
    </div>
    <div class="flex-wrap" style="gap:5px;margin-bottom:10px;flex-wrap:wrap;">
        <div class="flex-wrap flex-between flow-panel-card" style="background:var(--success);color:var(--hover-text);" @click="openTasks('day',moment().format('YYYYMMDD'))"
        :class="{
                'flow-panel-card-empty':!counts.finished
            }"
        >
            <div class="l-title"><BaseIcon icon="md-checkmark"  />今日已完 </div>
            <div class="l-count">{{counts.finished || 0}}</div>
        </div>
        
        <div class="flex-wrap flex-between flow-panel-card" style="background:var(--secondary);color:var(--hover-text);">
            <div class="l-title"><BaseIcon icon="md-list" />待处理 </div>
            <div class="l-count">15</div>
        </div>
        
        <div class="flex-wrap flex-between flow-panel-card"
         style=""
            :class="{
                'flow-panel-card-empty':!counts.important
            }"
            :style="`${counts.important?'background:var(--warning);color:var(--hover-text);':''}`"
        >
            <div class="l-title"><BaseIcon icon="info" />重要/紧急 </div>
            <div class="l-count">0</div>
        </div>
        <div class="flex-wrap flex-between flow-panel-card"
            :class="{
                'flow-panel-card-empty':true
            }"
        >
            <div class="l-title"><BaseIcon icon="liujisuan" />我发起的 </div>
            <div class="l-count">0</div>
        </div>
        <div class="flex-wrap flex-between flow-panel-card" style="background:var(--error);color:var(--hover-text);" @click="openTasks('outdate',1)">
            <div class="l-title"><BaseIcon icon="md-time"   />逾期计划 </div>
            <div class="l-count">{{counts.outdate}}</div>
        </div>
        
        <div class="flex-wrap flex-between flow-panel-card" :class="{
                'flow-panel-card-empty':!counts.joined
            }">
            <div class="l-title"><BaseIcon icon="lianjieliu" />我参与的 </div>
            <div class="l-count">{{counts.joined || 0}}</div>
        </div>
        <div class="flex-wrap flex-between flow-panel-card" style="width:calc(20% - 5px);background:var(--border);"  @click="openTasks('total')">
            <div class="l-title l-border">总 </div>
            <div class="l-count">{{counts.total || 0}}</div>
        </div>
        <div class="flex-wrap flex-between flow-panel-card" style="width:calc(20% - 5px);background:var(--border);" @click="openTasks('year',moment().format('YYYY'))">
            <div class="l-title l-border">年 </div>
            <div class="l-count">{{counts.year}}</div>
        </div>
        <div class="flex-wrap flex-between flow-panel-card" style="width:calc(20% - 10px);background:var(--border);"  @click="openTasks('month',moment().format('YYYYMM'))">
            <div class="l-title l-border">月 </div>
            <div class="l-count">{{counts.month}}</div>
        </div>
        <div class="flex-wrap flex-between flow-panel-card" style="width:calc(20% - 5px);background:var(--border);"  @click="openTasks('week',moment().format('YYYYMMDD'))">
            <div class="l-title l-border">周 </div>
            <div class="l-count">{{counts.week}}</div>
        </div>
        <div class="flex-wrap flex-between flow-panel-card" style="width:calc(20% - 10px);background:var(--border);"  @click="openTasks('day',moment().format('YYYYMMDD'))">
            <div class="l-title l-border">今 </div>
            <div class="l-count">{{counts.day}}</div>
        </div>
        
    </div>
    <div style="height:calc(100% - 30px);position:relative;width:calc(100% - 10px);background:var(--bg3);border:2px solid var(--border);">
        <BaseEmpty v-if="items.length == 0" msg="您目前没有待办事项"></BaseEmpty>
        <template v-for="item in items">
            <div class="list-item" style="height:50px"  :key="item.id" @click="openTask(item)">
             <div class="flex-wrap flex-between " style="height:100%;width:100%;">
                 <div style="max-width:calc(100% - 80px);"><div class="title" style="font-weight:bold;">{{item.node }}</div>
                 <div style="font-size:12px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">{{ item.name  }}</div></div>
                 <div class="state" :style="`font-size:12px;border-radius:5px;background:${item.color};color:var(--hover-text);`">{{ item.state }}</div>    
             </div>
             
            </div>
         </template>
         
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            items:[],
            counts:{}
        }
    },
    mounted(){
        this.$api.afterLogin(()=>{
            this.getCounts()
        })
    },
    methods:{
        getCounts(){
            let url = `memos?q=count`
            if(this.projectId)
                url += '&project=' + this.projectId
            this.$api.get(url).then(res => {
                this.counts = res.data.data;
            });
        },
        openTasks(mode,key){
            let path = `/core/tasks?${mode}=${key}`
            this.$store.commit("session/push",{
            icon:"md-calendar",
            name:"计划管理",
            size:22,
            base_url:"/core/tasks",
            path
            })
       this.RouteTo(path)
        },
        openTask(item){
            let url = `/core/flows/${item.id}/instance`
            this.$store.commit('session/push_flow',{
                id:item.id,
                name:item.name
            })
            this.RouteTo(url)
        }
    }
}
</script>

<style lang="less">
.list-item{
    .title{
        color:var(--primary);
        font-weight: bold;
    }
}
.list-item:hover{
    .title{
        color:var(--hover-text);
    }
}
</style>
<style>

</style>