<template>
  <div class="container flex-wrap flex-line align-stretch relative">
    <div style="width:220px;">
     <VerticleMenu :menus="menus" :actived-key="activedMenuKey" />
      
    </div>
    <div class="relative" style="flex-grow:1">
      <router-view></router-view>
   </div>
  </div>
  
</template>

<script>
  import {mapGetters} from 'vuex'
  import VerticleMenu from '@/components/app/vmenu'
  export default {
    components:{VerticleMenu},
    routerOption:{
      as_frame:true
    },
    data(){
      return {
        menus:[{
          id:1,
          name:"项目",
          icon:'Building-',
          key:"projects"
        },{
          id:2,
          name:"角色/权限",
          icon:"supervisor",
          key:"roles"
        },{
          id:3,
          name:"看板",
          icon:"md-podium",
          key:"billboards"
        },{
          id:4,
          name:"报表",
          icon:"file",
          key:"reports"
        }]
      }
    },
    computed:{
      activedMenuKey(){
        return this.$route.path.slice(this.$route.path.lastIndexOf('/')+1)
      }
    }
  }
</script>

<style lang="less">


</style>