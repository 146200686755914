<template>
    <div style="position:relative;height:100%;">
     <div style="width:100%;height:30px;font-weight:bold;">
       <BaseIcon icon="md-apps" style="margin-right:5px" />我的合同 </div>
 
 
       <div class="flex-wrap flex-col" style="height:calc(100% - 30px);background:var(--border);gap:8px 8px;align-items:stretch;padding:8px;">
         <template v-for="item in items">
            <div class="list-item flex-col flex-center align-strench"  :key="item.id" @click="handleOpen(item)">
             <div class="flex-wrap flex-between" style="width:100%">
                 <div class="title">{{ item.name }}</div>
                 <BaseAmount class="amount" :value="item.amount" full />   
             </div>
             <div class="flex-wrap flex-between " style="width:100%;">
                 <div class="desc">{{ item.partA }}</div>
                 <div class="state">{{ item.state }}</div>    
             </div>
            </div>
         </template>
     </div>
     </div>
    
 </template>
 
 <script>
 export default {
     data(){
         return {
             items:[{
                id:"CT000000000011G5",
                type:"房建监理",
                 name:"宁大宿舍",
                 partA:"宁波市公共工程建设中心有限公司",
                 amount:'2743786',
                 state:"履约中"
             },{
                id:"CT00000000000UJQ",
                type:"全过程咨询",
                 name:"北仑区文化中心项目",
                 partA:"宁波经济技术开发区大港开发有限公司",
                 amount:'18620454',
                 state:"履约中"
             },{
                id:"CT00000000000P72",
                type:"全过程咨询",
                 name:"映荷佳苑二期",
                 partA:"宁波新材料科技城开发投资有限公司",
                 amount:'21700000',
                 state:"审计中"
             },{
                id:"CT00000000000FFI",
                type:"全过程咨询",
                 name:"宁波广电融媒体科技创新大厦",
                 partA:"宁波广电新视听科技有限公司",
                 amount:'19846400',
                 state:"已结束"
             },{
                id:"CT00000000000HF6",
                type:"全过程咨询",
                 name:"工业互联网研究院",
                 partA:"宁波工业互联网研究院有限公司",
                 amount:'8600000',
                 state:"已结束"
             }]
         }
     },methods:{
        handleOpen(item){
            let url = `/core/projects/${item.id}/cm`
            this.$store.commit('session/push_project_cm',{id:item.id,name:item.name})
            this.RouteTo(url)
        }
     }
 }
 </script>
 
 <style lang="less">
 .list-item{
    background:var(--bg2);
    height:80px;
    padding:5px;
    border-radius: 2px;
    cursor: pointer;
    padding-left:10px;
    padding-right:10px;

    .desc{
        font-size:12px;
        color:var(--subtext2);
    }
    .state{
        background:var(--primary);
        color:var(--hover-text);
        padding:0px 5px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size:12px;
        border:1px solid var(--hover-text);

    }
 }

 .list-item:hover{
    background:var(--primary);
    color:var(--hover-text);
    .amount{
        span{
        color:var(--hover-text) !important;
        }
    }
 }
 </style>