<template>
    <Container class="flex-col align-start">
        <h2 style="padding:10px 20px">客户问题跟踪</h2>
      <div style="margin:0 20px">
        <Input search placeholder="输入名称搜索" />
      </div>
      <div style="padding:0 20px;height:calc(100% - 100px);width:100%;">
        <BaseTable ref="table" :columns="cols" :data="filteredItems"  />

      </div>

    </Container>
  </template>
  <script>
    import Container from '@/components/app/container'
    import Toolbar from '@/components/app/toolbar'
    import moment from 'moment'
  export default {
    routerOption: {
    },
    components:{Container,Toolbar},
    data(){
      return {
        filter_mode:0,
        filter_type:null,
        types:[{
          name:'合同审阅',
          color:'var(--text3)',
          count:2,
        },{
          name:'合同变更',
          count:1,
          color:'var(--warning)'
        },{
          name:'合同结算',
          count:1,
          color:'var(--success)'
        },{
          name:"用印申请",
          color:"var(--error)",
          count:4
        }],
        states:[{
          name:'待处理',
          new_count:3,
          color:'var(--error)',
          count:8
        },{
          name:"已处理",
          count:6,
          new_count:2
        },{
          name:"与我相关",
          count:243
        }],
        items:[{
          id:1,
          type:"合同审阅",
          name:"奉化中学全过程咨询合同",
          created_by:'ugo',
          charged_by:['ugo','ugo','ugo','ugo','ugo','ugo'],
          updated_at:"2024-04-12 12:00:00",
          created_at:"2024-03-22 12:00:00",
          current:'专业部门审核'
        },{
          id:2,
          type:"合同审阅",
          name:"奉化南山庙监理咨询合同",
          created_by:'ugo',
          charged_by:'ugo',
          updated_at:"2024-04-12 12:00:00",
          created_at:"2024-03-22 12:00:00",
          current:'事业部审核'
        },{
          id:3,
          type:"合同变更",
          name:"奉化中学全过程咨询合同",
          created_by:'ugo',
          charged_by:'ugo',
          updated_at:"2024-04-12 12:00:00",
          created_at:"2024-03-22 12:00:00",
          current:'事业部审核'
        },{
          id:4,
          type:"合同结算",
          name:"奉化中学全过程咨询合同",
          created_by:'ugo',
          charged_by:'ugo',
          updated_at:"2024-04-12 12:00:00",
          created_at:"2024-03-22 12:00:00",
          current:'经营部审核'
        },{
          id:4,
          type:"用印申请",
          name:"钟公庙中学全过程咨询合同",
          created_by:'ugo',
          charged_by:'ugo',
          updated_at:"2024-04-12 12:00:00",
          created_at:"2024-03-22 12:00:00",
          current:'综合办审核'
        },{
          id:5,
          type:"用印申请",
          name:"钟公庙中学全过程咨询合同",
          created_by:'ugo',
          charged_by:'ugo',
          updated_at:"2024-04-12 12:00:00",
          created_at:"2024-03-22 12:00:00",
          current:'综合办审核'
        },{
          id:6,
          type:"用印申请",
          name:"钟公庙中学全过程咨询合同",
          created_by:'ugo',
          charged_by:'ugo',
          updated_at:"2024-04-12 12:00:00",
          created_at:"2024-03-22 12:00:00",
          current:'综合办审核'
        },{
          id:7,
          type:"用印申请",
          name:"钟公庙中学全过程咨询合同",
          created_by:'ugo',
          charged_by:'ugo',
          updated_at:"2024-04-12 12:00:00",
          created_at:"2024-03-22 12:00:00",
          current:'综合办审核'
        },]
      }
    },
    mounted(){
      
    },
    computed: {
      filteredItems(){
        if(this.filter_type !== null)
          return this.items.filter(v=>v.type == this.filter_type)
        else
          return this.items
      },
      cols(){
        return [{
          title:"序号",
          width:60,
          type:"index"
        },{
          title:"类型",
          width:120,
          key:"type",
          render:(h,{row})=>{
            const colormap = {
              '合同审阅':'var(--text3)',
              '合同变更':'var(--warning)',
              '合同结算':'var(--success)'
            }
            return h('div',{
              style:`background:var(--border);border-left:5px solid var(--border);border-color:${colormap[row.type] || 'var(--text3)'};width:80px;margin:0 auto;text-align:center;padding:2px;padding-right:7px;border-radius:5px;`},'客户问题')
          }
        },{
          title:"流程名称",
          minWidth:140,
          key:"name",
          render:(h,{row})=>{
            let icon = h("BaseIcon",{props:{icon:'lianjieliu'},style:"margin-right:3px;color:var(--primary)"})
            let name = h('a',row.name)
            return h('div',{class:"flex-wrap"},[icon,name])
          }
        },{
          title:"状态",
          width:120,
          key:"status",
          render:(h,{row})=>{
            return h('div',{
              style:`background:var(--border);border-left:5px solid var(--border);width:80px;margin:0 auto;text-align:center;padding:2px;padding-right:7px;border-radius:5px;`},row.status || '进行中')
          }
        },{
          title:"当前阶段",
          width:140,
          sortable:false,
          render:(h,{row})=>{
            let icon = h("BaseIcon",{props:{icon:"liujisuan"},style:"margin-right:5px;color:var(--subtext3)"})
              let text = row.current
            return h('div',{
              
              style:`background:var(--border);margin:0 auto;text-align:left;padding:2px 10px;border-radius:5px;color:var(--success)`},'事业部审核')
          }
        },
        {
          title:"阶段负责人",
          width:120,
          type:'users',
          key:"charged_by"
        },{
          title:"审核用时",
          width:120,
          render:(h,{row})=>{
            let durationText = moment.duration(moment() - moment(row.created_at)).humanize(); 
            return h('div',{style:"color:var(--success)"},durationText)
          }
        },{
          title:"发起人",
          width:120,
          type:'user',
          key:"created_by"
        },{
          title:"最近更新",
          width:120,
          render:(h,{row})=>{
            return h('div',{style:"color:var(--success)"},row.updated_at?moment(row.updated_at).fromNow():'-')
          }
        },{
          title:"操作",
          width:120,
          render:(h,{row})=>{
            let buttons = [h('Button',{props:{size:"small"}},"处理")]
            return h('div',{class:"flex-wrap flex-center"},buttons)
          }
        }]
      }
        
    },
    methods:{
     
    }
  }
  </script>
  
  <style lang="less">
    .tab-card{
      padding:5px 10px;
      height:100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background:var(--bg3);
      border-right:none;
      cursor: pointer;
      border-radius: 5px;
      .count{
        font-size:30px;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      }
      
    }


    .tab-card:hover{
      background:var(--primary);
      color:var(--hover-text);
      .count,.name{
        color:var(--hover-text) !important;
      }
    }

    .tab-card-active{
      background:var(--primary);
      color:var(--hover-text);
      .count,.name{
        color:var(--hover-text) !important;
      }
    }
  </style>