<template>
  <Container class="flex-col align-start">
    <h2 style="padding:10px 20px">合同起草<div style="font-size:12px;color:var(--subtext3);font-weight:bold;">
            建议采用预设合同模板起草合同
        </div></h2>
    <div class="flex-wrap" style="margin:10px 20px">
      <Button icon="md-create" style="margin-right:10px">新增合同</Button>
      <Input search placeholder="输入名称搜索" />
    </div>
    <div style="padding:0 20px;height:calc(100% - 100px);width:100%;">
      <BaseTable ref="table" :columns="cols" :data="filteredItems"  />

    </div>

  </Container>
</template>
<script>
  import Container from '@/components/app/container'
  import Toolbar from '@/components/app/toolbar'
  import moment from 'moment'
export default {
  
  components:{Container,Toolbar},
  data(){
    return {
      filter_mode:0,
      filter_type:null,
      types:[{
        name:'合同审阅',
        color:'var(--text3)',
        count:2,
      },{
        name:'合同变更',
        count:1,
        color:'var(--warning)'
      },{
        name:'合同结算',
        count:1,
        color:'var(--success)'
      },{
        name:"用印申请",
        color:"var(--error)",
        count:4
      }],
      states:[{
        name:'待处理',
        new_count:3,
        color:'var(--error)',
        count:8
      },{
        name:"已处理",
        count:6,
        new_count:2
      },{
        name:"与我相关",
        count:243
      }],
      items:[{
        id:1,
        type:"全过程咨询",
        name:"奉化中学全过程咨询合同",
        created_by:'ugo',
        charged_by:['ugo','ugo','ugo','ugo','ugo','ugo'],
        updated_at:"2024-04-12 12:00:00",
        created_at:"2024-03-22 12:00:00",
        deps:['建','房','造'],
        current:'专业部门审核'
      },{
        id:2,
        type:"房建监理",
        name:"奉化南山庙监理咨询合同",
        created_by:'ugo',
        charged_by:'ugo',
        updated_at:"2024-04-12 12:00:00",
        deps:['房','BIM'],
        created_at:"2024-03-22 12:00:00",
        current:'事业部审核'
      }]
    }
  },
  mounted(){
    
  },
  computed: {
    filteredItems(){
      if(this.filter_type !== null)
        return this.items.filter(v=>v.type == this.filter_type)
      else
        return this.items
    },
    cols(){
      return [{
        title:"序号",
        width:60,
        type:"index"
      },{
        title:"类型",
        width:120,
        key:"type",
        render:(h,{row})=>{
          const colormap = {
            '合同审阅':'var(--text3)',
            '合同变更':'var(--warning)',
            '合同结算':'var(--success)'
          }
          return h('div',{
            style:`background:var(--border);border-left:5px solid var(--border);border-color:${colormap[row.type] || 'var(--text3)'};width:80px;margin:0 auto;text-align:center;padding:2px;padding-right:7px;border-radius:5px;`},row.type)
        }
      },{
        title:"合同模板",
        width:140,
        key:"type",
        render:(h,{row})=>{
          const colormap = {
            '合同审阅':'var(--text3)',
            '合同变更':'var(--warning)',
            '合同结算':'var(--success)'
          }
          return h('div',{
            style:`background:var(--border);border-left:5px solid var(--border);border-color:${colormap[row.type] || 'var(--text3)'};width:120px;margin:0 auto;text-align:center;padding:2px;padding-right:7px;border-radius:5px;`},row.type + "-模板02")
        }
      },{
        title:"合同名称",
        minWidth:140,
        key:"name",
        render:(h,{row})=>{
          let icon = h("BaseIcon",{props:{icon:'lianjieliu'},style:"margin-right:3px;color:var(--primary)"})
          let name = h('a',row.name)
          return h('div',{class:"flex-wrap"},[icon,name])
        }
      },{
        title:"涉及部门",
        width:120,
        key:"deps",
        type:'users'
      },{
        title:"创建人",
        width:120,
        type:'user',
        key:"created_by"
      },{
        title:"最后编辑",
        width:120,
        render:(h,{row})=>{
          return h('div',{style:"color:var(--success)"},row.updated_at?moment(row.updated_at).fromNow():'-')
        }
      },{
        title:"操作",
        width:170,
        render:(h,{row})=>{
          let buttons = [h('Button',{props:{size:"small"}},"编辑"),h('Button',{props:{size:"small"},style:'margin-left:10px'},"审定")]
          return h('div',{class:"flex-wrap flex-center"},buttons)
        }
      }]
    }
      
  },
  methods:{
   
  }
}
</script>

<style lang="less">
  .tab-card{
    padding:5px 10px;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background:var(--bg3);
    border-right:none;
    cursor: pointer;
    border-radius: 5px;
    .count{
      font-size:30px;
      font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    }
    
  }


  .tab-card:hover{
    background:var(--primary);
    color:var(--hover-text);
    .count,.name{
      color:var(--hover-text) !important;
    }
  }

  .tab-card-active{
    background:var(--primary);
    color:var(--hover-text);
    .count,.name{
      color:var(--hover-text) !important;
    }
  }
</style>