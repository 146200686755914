<template>
  <div class="container">
    <template v-if="loading">
      <Spin fix>
      <BaseLoading style="margin:0;margin-bottom:10px;height:20px" />
      加载中
    </Spin>
    </template> 
    <template  v-else>
      <div class="flex-wrap flex-between flow-header">
      <div class="flex-wrap">
      <div class="icon-wrap">
      <BaseIcon :icon="flow.icon" :size="30" />
      </div>
      <div>
        <div class="title">{{flow.name}}</div>
        <div class="sub">{{flow.description}}</div>
      </div>
      </div>
      <div class="flex-wrap split10">
       <div class="flex-wrap flex-center" style="height:50px;font-size:15px;width:120px;border-radius:10px;background:var(--warning);color:var(--hover-text);">{{ version.status || '创建中'}}</div>
      </div>
    </div>
      <div class="flex-wrap flex-between align-start" style="margin-top:10px;">
        <div style="flex-grow:1;">
         
   
    <div style="max-height:550px;overflow-y:auto;margin:10px 0;padding:5px;background:var(--border);border:1px solid var(--border);">
    <UgoForm ref="form" :form="form" :acl="acl" style="background:none;" />
  </div>
    <div class="flex-wrap flex-right">
    <Button long type="primary" size="large" style="width:200px" @click="handleSubmit">提交</Button>
    <Button long size="large" style="width:120px;margin-left:20px;" @click="handleSaveAsDraft">保存草稿</Button>
     <Button long size="large" style="width:120px;margin-left:20px;" @click="handleCancel">取消</Button>
    </div>
        </div>
        <div style="margin-left:10px;position:relative;width:300px;border-left:1px solid var(--border);padding-left:10px;flex-shrink:0;">
          <FlowGraph style="width:100%;height:200px;position:relative;" :data="FlowData" />
          <div style="margin-top:5px;">
          <BaseBoardSubTitle name="历史记录"></BaseBoardSubTitle>
          <Timeline style="height:350px;overflow-y:auto;border:1px solid var(--border);padding:10px;">
            <template v-for="h in historyNodes">
              <TimelineItem :key="h.id">
                <div><BaseNameBoard :uid="h.created_by" :size="30" />
                  <div style="color:var(--success);">{{ moment(h.created_at).format('YYYY-MM-DD HH:mm:ss') }}</div></div>
              
              <div style="border:1px solid var(--border);margin:5px;border-radius:5px;padding:5px;">
                
                <div>{{ h.content }}</div>
              </div>
            </TimelineItem>
            </template>
          </Timeline>
        </div>
        </div>
        
      </div>
     
    
  </template>
  </div>
</template>

<script>
import UgoForm from  '../form/index'
import FlowGraph from './graph.vue'
export default {
  data(){
    return {
      loading:false,
      flowData:{
        form:{}
      },
      form:{},
      acl:{},
      historyNodes:[{
        created_by:'ugo',
        created_at:'2024/12/18 12:00:00',
        content:'提交了数据'
      },{
        created_by:'ugo',
        created_at:'2024/12/18 12:00:00',
        content:'审核通过'
      },{
        created_by:'ugo',
        created_at:'2024/12/18 12:00:00',
        content:'审核通过'
      },{
        created_by:'ugo',
        created_at:'2024/12/18 12:00:00',
        content:'提交了数据'
      },{
        created_by:'ugo',
        created_at:'2024/12/18 12:00:00',
        content:'审核通过'
      },{
        created_by:'ugo',
        created_at:'2024/12/18 12:00:00',
        content:'审核通过'
      },],
      instData:{}
    }
  }, 
  components:{UgoForm,FlowGraph},
  mounted(){
    
  },
  watch:{
    flowId:{
      handler(val){
        this.loadingFlow(val)
      },
      immediate:true
    },
  },
  computed:{
    FlowData(){
      if(this.version)
        return this.version
      else
        return this.flowData
    }
  },
  props:{
    'flow-id':{
      type:String
    },
    'flow':{
      type:Object
    },
    'version':{
      type:Object,
      default:()=>({})
    }
  },  
  methods:{
    getCurrentView(){
        if(!Array.isArray(this.version))
          return
        let node = this.version.nodes.find(n=>n.type=='start_node')
        if(node?.properties?.view_id){
          this.getForm(node.properties.view_id)
          this.acl = node.properties.acl
        }
    },  
    getForm(id){
      this.$api.get(`forms/${id}`).then(res=>{
        this.form = res.data.data
      })
    },
    loadingFlow(val){
      this.loading = true
      setTimeout(()=>{
        this.getCurrentView()
        this.loading = false
      },500)
    },  
    handleSubmit(){
      this.$refs.form.submit(item=>{
        this.$api.post('/oa/flow-instances',item).then(res=>{

          this.$emit('cancel')
        }).catch(e=>{
          this.$emit('error',e)
        })
      })
    },
    handleSave(){
      this.$refs.form.submit(item=>{
        if(!item.id){
          item.draft = true
          this.$api.post('/articles',item).then(res=>{
            let updateInfo = res.data.data
            item.id = updateInfo.id
            this.$Notice.success({title:"操作成功",desc:"草稿已保存，你可以在我的草稿中找到它"})
          }).catch(e=>{
            this.$emit('error',e)
          })
        }else{
          this.$api.patch('/articles/'+item.id).then(res=>{
          }).catch(e=>{
            this.$emit('error',e)
          })
        }
        
      })
    },
    handleCancel(){
      this.$emit('cancel')
    },
    handleSaveAsDraft(){
      
    }
  }
}
</script>
<style lang="less">
.flow-header{
  padding:10px;
  background:var(--bg2);
  color:var(--text2);
  .icon-wrap{
    width:50px;
    height:50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background:var(--primary);
    color:var(--hover-text);
    border-radius: 10px;
    flex-shrink: 0;
    margin-right: 10px;
  }
  .title{
    font-size:16px;
    
  }
  .sub{
    font-size:12px;
    color:var(--subtext2);
  }
}
</style>