<template>
  <div class="flex-wrap flex-line container align-stretch">
    <div style="width:160px;min-width:160px;background:var(--bg2);border-right:1px solid var(--border);">
      <BaseMenu :menus="menus" :actived="actived" @on-click="handleOpen" />
    </div>
    <div style="flex-grow:1;padding:10px;background:var(--bg2);">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  export default {
    routerOption:{
      as_frame:true,
      as_default:true,
      redirect:'list'
    },
    computed:{
      menus(){
        return [{
          name:"首页",
          key:"dashboard",
          icon:"desktop"
        },{
          name:"组织架构",
          key:"organization",
          icon:"organization"
        },{
          name:'员工花名册',
          key:"list",
          icon:"contact"
        },{
          name:"入职管理",
          key:"entrance",
          icon:"contact"
        },{
          name:"离职管理",
          key:"leave",
          icon:"contact"
        },{
          name:"人才盘点",
          key:"leave",
          icon:"area-chart"
        },{
          name:"账号管理",
          key:"account",
          icon:"key"
        }]
      },
      actived(){
        return this.$route.path.slice(this.$route.path.lastIndexOf('/')+1)
      }
    },
    methods:{
      handleOpen(e){
        let baseUrl = this.$route.path.slice(0,this.$route.path.lastIndexOf('/'))
        localStorage.setItem(baseUrl, baseUrl+'/'+e)
        this.RouteTo(e)
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>