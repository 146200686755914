import moment from "moment"

const o = {}

o.render_text = (h, col, value, editable, handleChange) => {
  if (!editable) {
    let val = value
    if (col && col.config && col.config.formatter)
      val = col.config.formatter(value)
    return h('span', {
      style: "padding:2px 5px;text-align:left;"
    }, val)
  } else
    return h('Input', {
      props: {
        value,
        clearable: true,
        autofocus: true
      },
      on: {
        'on-blur': (e) => {
          if (handleChange)
            handleChange(e.target.value)
        },

      }
    })
}

o.render_select = (h, col, value, editable, handleChange, rowkey) => {
  if (!editable) {
    let val = value
    if (col && col.config && !col.config.text && col.config.options){
      if(col.config.index){
        val = col.config.options[value]
      }else{
        let o = col.config.options.find(v => v.id == value)
        val = o?o.name:null
      }
    }else{
      val = value
    }
    return h('span', {
      style: "padding:2px 5px;text-align:left;"
    }, val)
  } else {
    let options = []  
 
    if (col && col.config && col.config.options)
     options = col.config.options
    let domOptions = options.map((o,i)=>{
      let value = null
      let name = null
      if(col.config.text){
        value = name = o
      }else if(col.config.index){
        value = i
        name = o
      }else{
        value = o.id
        name = o.name
      }
      return h("Option", {
         key: 'select' + rowkey + value,
        props: {
          value,
         
        }
      },[name])
    })

    return h('Select', {
      props: {
        value,
        clearable:true,
        transfer:true,
        autofocus: true
      },
      on: {
        'on-change': (e) => {
          if (handleChange)
            handleChange(e)
        },

      }
    }, domOptions)
  }
}

o.render_date = (h, col, value, editable, handleChange) => {
  if (!editable) {
    let formatter = v => v ? moment(v).format("YYYY/MM/DD") : "-"
    if (col.config && col.config.formatter)
      formatter = col.config.formatter
    return formatter(value)
  } else
    return h('DatePicker', {
      props: {
        value: value && moment(value).isValid() ? new Date(moment(value).valueOf()) : '',
        transfer: true
      },
      on: {
        'input': (e) => {
          if (handleChange)
            handleChange(e ? moment(new Date(e)).format("YYYY-MM-DD HH:mm:ss") : null)
        },

      }
    })
}

o.render_mark = (h, col, value, editable, handleChange) => {
  return h('UgoMark', {
    props: {
      value,
      editable
    },
    on: {
      'input': (e) => {
        if (handleChange)
          handleChange(e)
      },
    }
  })
}

function sumAll(items, cb) {
  let c = 0
  items.forEach(v => {
    c += cb(v, items) || 0
  })
  return c
}

const methods = {
  cross(item, keys = []) {
    let c = 1
    keys.forEach(key => {
      let value = parseFloat(item[key])
      c *= (value || 0)
    })

    return c
  }
}

// [sumAll,[plus,count,key]]
function createCaculator(computedSetting) {
  if (!Array.isArray(computedSetting)) {
    return () => 0
  }
  let method = computedSetting[0]
  if (method == 'sumAll') {
    return (item, items) => sumAll(items, createCaculator(computedSetting[1]))
  } else if (typeof methods[method] == 'function') {
    return (item, items) => methods[method](item, computedSetting.slice(1))
  }
}
o.computeValue = (computed, item, items) => {
  let caculator = createCaculator(computed)
  return caculator(item, items)
}

o.renderContent = (h, col, value, editable, handleChange,rowkey) => {
  let domContent = null
  if (col && col.type && o[`render_${col.type}`]) {
    domContent = o[`render_${col.type}`](h, col, value, editable, handleChange, rowkey)

  } else {
    domContent = o.render_text(h, col, value, editable, handleChange)
  }

  return domContent
}

export default o