<template>
  <div style="width:100%;min-height:500px;background:#fff;position:relative;padding-top:5px;">
    <div class="flex-wrap flex-center" style="padding:25px 0;margin:0 5px;background:var(--bg2);border-bottom:1px solid var(--border)">
        <div class="flex-col">
            <div>当前等级</div>
        <BaseIcon icon="nbgz" :size="60"></BaseIcon>
        <div style="font-size:25px;font-weight:bold;">1,932</div>
        <div>排名: 342名</div>
        </div>
    </div>
    
    <div style="background:var(--bg2);margin:5px;padding:5px;">
        即将取得成就
    </div>
    <div class="flex-wrap flex-line" style="box-shadow:1px 1px 3px 1px var(--border);padding:0 5px;">
        <div class="flex-col flex-center" style="margin:3px 0;background:var(--bg2);margin-right:8px;height:50px;width:50px;">
            <BaseIcon icon="nbgz" size="25"/> 
            <div style="font-size:10px;background:var(--bg1);color:var(--hover-text);padding:0 5px;border-radius:10px;">15</div>
        </div>
        <div class="flex-col align-start" style="flex-grow:1;">
            <div style="font-size:16px;">上传照片   </div>
            <div style="font-size:12px;color:var(--subtext3)">上传一张工作照</div>
            </div>
        <div style="margin-right:5px"><Button type="primary" size="small">立即前往</Button></div> 
    </div>
    <div class="flex-wrap flex-line" style="box-shadow:1px 1px 3px 1px var(--border);padding:0 5px;">
        <div class="flex-col flex-center" style="margin:3px 0;background:var(--bg2);margin-right:8px;height:50px;width:50px;">
            <BaseIcon icon="nbgz" size="25"/> 
            <div style="font-size:10px;background:var(--bg1);color:var(--hover-text);padding:0 5px;border-radius:10px;">10</div>
        </div>
        <div class="flex-col align-start" style="flex-grow:1;">
            <div style="font-size:16px;">年终考核   </div>
            <div style="font-size:12px;color:var(--subtext3)">完成2023年员工考核自评部分</div>
            </div>
        <div style="margin-right:5px"><Button type="primary" size="small">立即前往</Button></div> 
    </div>
    <div style="background:var(--bg2);margin:5px;padding:5px;"> 
        最近完成成就
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>