<template>
<div class="container flex-wrap flex-line align-stretch relative">
<div style="width:300px;">
    <Button icon="md-add">新建角色</Button>
    <div style="margin-top:10px;background:var(--bg2);padding:10px;">
      <template v-for="r in roles">
        <div :key="r.id" class="role-item" :class="{'role-item-selected':selected == r.id}" @click="selected=r.id">
          <div class="flex-wrap">
            <div class="role-item-icon-wrap">
             <BaseIcon :icon="r.icon || 'md-person'" :size="20"></BaseIcon>
             </div>
             <div  style="margin-left:14px">
            <div class="role-item-title">{{r.name}}</div>
              <div class="role-item-sub">{{r.desc}}</div>
              </div>
          </div>
        
        </div>
      </template>
    </div>
  </div>
  <Card  style="flex-grow:1;margin-left:20px;border-radius:10px;background:var(--bg2);height:100%;overflow:hidden;padding:10px;">
    <div class="flex-wrap flex-between">
      <div class="flex-wrap">
        <BaseIcon :icon="selected_item.icon" :size="22" style="margin-right:10px" />
        <div  style="font-size:16px;font-weight:bold">{{selected_item.name}}</div>
      </div>
      <div class="split5">
         <a style="color:blue">修改</a>
        <a style="color:darkred">删除此角色</a>
      </div>
      </div>
    <Divider style="background:#33333333"></Divider>
    <BaseSegment title="操作权限">
      
      操作权限neirong
    </BaseSegment>
    <BaseSegment :title="`项目权限 (${projects.length})`">
      
      操作权限neirong
    </BaseSegment>
    <BaseSegment title="消息过滤">
      
      操作权限neirong
    </BaseSegment>
    <BaseSegment title="用户管理">
      
      操作权限neirong
    </BaseSegment>
  </Card>
  </div>
  
</template>

<script>
  import {mapGetters} from 'vuex'
  export default {
    routerOption:{
      as_default:true
    },
    data(){
      return {
        selected:1,
       
      }
    },
    computed:{
      ...mapGetters('cm',['projects','roles']),
      selected_item(){
        return this.roles.find(v=>v.id == this.selected) || {}
      }
    }
  }
</script>

<style lang="less">
.role-item{
  margin-bottom:10px;
  cursor: pointer;
  padding:5px;
  color:var(--text2);
  padding-left:10px;
  .role-item-icon-wrap{
    background:var(--bg1);
    flex-shrink: 0;
    color:var(--hover-text);
    width:30px;
    height:30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .role-item-title{
    font-size:16px;
    font-weight: bold;
  }

  .role-item-sub{
    font-size:12px;color:#333333aa
  }
}

.role-item:hover{
  background:var(--primary);
  color:var(--hover-text);
}

.role-item-selected,.role-item:active,.role-item-selected:hover{
  background:var(--bg1);
  color:var(--text1);
  .role-item-icon-wrap{
    background:#333333aa;
  }
  .role-item-sub{
    color:#ffffff33;
  }
}


</style>