<style lang="less">
.uac-modal-wrap{
  .ivu-modal-close,.ivu-modal-close:hover{
    top:4px;
    i{
      color:var(--text1);
    }
  }

  .ivu-modal-close:hover{
    filter:brightness(1.1);
  }
}
</style>
<template>
    <Modal :value="value" footer-hide  :width="580" @on-visible-change="$emit('input',$event)" class-name="uac-modal-wrap">
     
      <div style="margin-bottom:5px;font-weight:bold;position:absolute;top:0;left:0;right:0;padding:10px;background:var(--bg1);color:var(--text1);">选择人员范围</div>
      <Tabs :value="mode" style="margin-top:30px;">
        <TabPane label="部门" name="deps">
          <div class="flex-wrap" style="width:100%;margin-bottom:10px;"><Input v-model="filterDepText" search clearable style="width:220px;" />
          </div>
          <div class="flex-wrap" style="overflow-y:auto;align-content:flex-start;flex-wrap:wrap;overflow-x:hidden;gap:2px;flex-wrap:wrap;justify-content:flex-start;">
         <template v-for="o in filteredDeps">
           <div class="flex-wrap ugo-item-user" :class="{
             'ugo-item-user-selected':selected && selected.includes(o.id)
           }" :key="o.id" style="" @click="handleClick(o)">
             <BaseAvatar :src="o.avatar" style="flex-shrink:0">{{o.name[0]}}</BaseAvatar>
             <div style="margin-left:5px">
               <div>{{o.name}}</div>
               <div class="ugo-item-user-sub" style="">{{o.phone}}</div>
               </div>
           </div>
         
         </template>
         </div>
        </TabPane>
        <TabPane label="角色" name="roles">标签二的内容</TabPane>
        <TabPane label="人员" name="employees">
          <div class="flex-wrap" style="width:100%;margin-bottom:10px;"><Input v-model="filterText" search clearable style="width:220px;" />
     </div>
      <div  style="width:calc(100%);height:300px;overflow-y:auto;">
       <template v-for="g in filteredGroups">
       <div :key="g.id" v-if="filterUsers(g.users).length > 0">
         <div class="flex-wrap flex-between" :style="`background:linear-gradient(to right,${g.color || 'var(--bg1)'},var(--border));color:var(--hover-text);padding:2px 5px;`">
          <div class="flex-wrap"><BaseIcon icon="organization" style="margin-right:5px;" />
          {{g.name}} ({{filterUsers(g.users).length}})
        </div>
  
          <div><a @click="selectGroupAll(g)" style="margin-right:5px;">全选</a> <a @click="clearGroupAll(g)">清除</a></div>
         </div>
      <div class="flex-wrap" style="overflow-y:auto;align-content:flex-start;flex-wrap:wrap;overflow-x:hidden;gap:2px;flex-wrap:wrap;justify-content:flex-start;">
         <template v-for="o in filterUsers(g.users)">
           <div class="flex-wrap ugo-item-user" :class="{
             'ugo-item-user-selected':selected && selected.includes(o.id)
           }" :key="o.id" style="" @click="handleClick(o)">
             <BaseAvatar :src="o.avatar" style="flex-shrink:0">{{o.name[0]}}</BaseAvatar>
             <div style="margin-left:5px">
               <div>{{o.name}}</div>
               <div class="ugo-item-user-sub" style="">{{o.phone}}</div>
               </div>
           </div>
         
         </template>
         </div>
          </div>
         </template>
        
         </div>

        </TabPane>
    </Tabs>
     
  
    <div class="flex-wrap" style="padding:10px;width:100%;background:var(--bg3);border-top:1px solid #ddd;align-content:flex-start;">
      <template v-for="item in selected">
        <Tag closable @on-close="handleClick({id:item})">{{getObject(item).type }}<Divider type="vertical" style="margin:0 4px;" />{{ getObject(item).name }}</Tag>
      </template>
</div>
    <div  style="width:100%;background:var(--bg3);border-top:1px solid #ddd;" @click.stop>
    
      <div class="flex-wrap flex-between" v-if="selected.length > 0" style="height:40px;padding:0 10px;">
        <div class="flex-wrap split5">
       <div>已选择 {{selected.length}}</div>
       <Button size="small" @click.stop="handleSelectAll">全选</Button>
       <Button size="small" @click.stop="handleClear">取消</Button>
       </div>
        <Button icon="md-checkmark" @click="handleConfirm" type="primary" size="small">确认</Button>
   </div>
</div>
     
    </Modal>
  </template>
  
  <script>
  import {mapGetters} from 'vuex'
  export default {
    name:'BaseUACModel',
      data(){
          return {
              prev:false,
        current:{},
        filterText:"",
        filterDepText:"",
        mode:'deps',
        selected:[]
          }
      },
      props:{
          value:{
              type:Boolean,
              default:false
          },
          groups:{
            type:Array,
              default:()=>([])
          },
          extraGroups:{
            type:Array,
            default:()=>([])
          },
          included:{
            type:Array,
            default:()=>([])
          },  
          excluded:{
              type:Array,
              default:()=>([])
          }
      },
      mounted(){
          this.$api.afterLogin(()=>{
            this.$store.dispatch("session/getGroupedEmployees")
            this.$store.dispatch("session/getDeps")
          })
      },
      computed:{
      ...mapGetters("session",["grouped_employees","users","deps","roles"]),
      extraGroupObjects(){
        return this.extraGroups.map(v=>{
          return {
            name:v.name,
            color:v.color,
            id:v.id,
            users:this.users.filter(u=>v.users.includes(u.id))
          }
        })
      },
      filteredDeps(){
        if(this.filterDepText)
          return this.deps.filter(v=>v.name && v.name.includes(this.filterDepText))
        else
          return this.deps
      },
      filteredGroups(){
        let groups = this.groups
        let grouped_employees = this.grouped_employees
        if(this.extraGroupObjects.length > 0)
          grouped_employees = [...this.extraGroupObjects,...this.grouped_employees]
        if(groups && groups.length > 0){
          return grouped_employees.filter(v=>groups.includes(v.id) == true)
        }else{
          return grouped_employees
        }
      },
    },
    methods:{
      getObject(id){
        let user = this.users.find(u=>u.id == id)
        if(user){
          return {
            ...user,
            type:'员工'
          }
        }
        let dep = this.deps.find(u=>u.id == id)
        if(dep){
          return {
            ...dep,
            type:"部门"
          }
        }
        return {}
      },
      selectGroupAll(g){
        let users = this.filterUsers(g.users)
        users.forEach(v=>{
          if(!this.selected.includes(v.id))
            this.selected.push(v.id)
        })
      },
      clearGroupAll(g){
        let users = this.filterUsers(g.users)
        this.selected = this.selected.filter(id=>users.find(u=>u.id == id) == null)
      },
      filterUsers(users){
        
        if(this.filterText){
                return users.filter(v=>v.name && v.name.includes(this.filterText)).filter(v=>(!Array.isArray(this.included) || this.included.length == 0 || this.included.includes(v.id)) && this.excluded.includes(v.id) == false)
        }else{
          return users.filter(v=>v.name).filter(v=>(!Array.isArray(this.included) || this.included.length == 0 || this.included.includes(v.id)) && this.excluded.includes(v.id) == false)
        }
      },
      handleClear(e){
        this.selected = []
      },
  
      handleSelectAll(){
        this.selected = this.filteredUsers.map(v=>v.id)
      },
      handleClick(o){
          let index = this.selected.findIndex(v=>v == o.id)
          if(index == -1)
            this.selected.push(o.id)
          else
            this.selected.splice(index,1)
        
      },
      handleConfirm(){
        this.$emit('on-select',[...this.selected])
        this.selected = []
        this.$emit('input',false)
      }
      }
  }
  </script>
  
  <style>
  
  </style>