const moment = require('moment')
import cm_utils from "@/utils/cm"
import {
  API
} from '@/vue-plugins/api'
import { makeWorker } from "../../utils/worker"
const CM_Load_Worker = "cm_load_worker"
const CM_Worker = "cm_worker"
import {
  pick
} from 'lodash'

const isBetween = (a, b, c) => {
  if (a && b && c)
    return moment(a).isBetween(moment(b), moment(c))
}

const state = {
  dep: null,
  loading_permit: true,
  timeValue: moment().format(),
  mode: 'year',
  started_at: moment().startOf('year').format(),
  finished_at: moment().endOf('year').format(),
  projects: [],
  contracts: [],
  s_projects: [],
  permission: {
    roles:[]
  },
  totalData: {
    count: 82
  },
  loading_status: "",
  loading_percent: 0,
  flow_todo_count:0,
  flow_in_count:0,
  computingTotal: false,
  bills: [],
  permit_deps: null,
  hr: [],
  my_role: 1,
  my_roles: [],
  roles: [],
  deps: [{
    id: null,
    name: '全公司'
  }, {
    id: 67324391,
    name: '房建监理'
  }, {
    id: 68827000,
    name: '市政监理'
  }, {
    id: 67324392,
    name: '建设管理'
  }, {
    id: 69119063,
    name: '装修工程'
  }, {
    id: 67324393,
    name: '造价咨询'
  }, {
    id: 69172002,
    name: "BIM"
  }]
}
const types = ['全过程咨询', '项目管理', '市政监理', '房建监理', '造价咨询', 'BIM咨询', '装修', '挂靠', '其他']
const getters = {
  permission(state) {
    return state.permission
  },
  flow_todo_count(state){
    return state.flow_todo_count
  },
  flow_in_count(state){
    return state.flow_in_count
  },
  dep(state) {
    return state.dep
  },
  loading(state) {
    if (state.loading_status)
      return {
        status: state.loading_status,
        percent: state.loading_percent
      }
  },
  computingTotal(state) {
    return state.computingTotal
  },
  project_list(state) {
    return state.s_projects
  },
  loading_permit(state) {
    return state.loading_permit
  },
  totalData(state) {
    return state.totalData
  },
  roles(state) {
    return state.roles
  },
  my_roles(state) {
    return state.permission.roles
  },
  my_role(state) {
    return state.permission.roles.find(v=>v.id == state.my_role) || {}
  },
  mode(state) {
    return state.mode
  },
  deps(state) {
    if (Array.isArray(state.permit_deps))
      return state.deps.filter(v => state.permit_deps.includes(v.id))
    return state.deps
  },
  filtered_dep_projects(state) {
    return state.projects.filter(v => (!state.dep || Array.isArray(v.deps) && v.deps.includes(state.dep)))
  },
  filtered_projects(state) {
    return state.projects.filter(v => (!state.dep || Array.isArray(v.deps) && v.deps.includes(state.dep))).filter(v => v.signed_at && moment(v.signed_at).isBetween(moment(state.started_at), moment(state.finished_at)))
  },
  production_projects(state) {
    return getters.filtered_dep_projects(state).filter(v => moment(v.completed_at).isAfter(state.started_at))

  },
  projects(state) {
    return state.projects
  },
  contracts(state) {
    return state.contracts
  },
  conditions(state) {
    return state.conditions
  },
  bills(state) {
    return state.bills
  },
  filtered_bills(state) {
    return state.bills.filter(v => getters.filtered_dep_projects(state).find(t => t.id == v.contract_id) && isBetween(v.billed_at, state.started_at, state.finished_at))
  },
  hr(state) {
    return state.hr
  },
  filtered_hr(state) {
    return state.hr.filter(v =>
      getters.filtered_dep_projects(state).find(t => t.id == v.project_id) &&
      (
        isBetween(v.started_at, state.started_at, state.finished_at) ||
        isBetween(v.finished_at, state.started_at, state.finished_at)
      )
    )

  },
  getProject(state) {
    return id => state.projects.find(v => v.id == id) || {}
  },
  started_at(state) {
    return state.started_at
  },
  finished_at(state) {
    return state.finished_at
  },
  timeValue(state) {
    return state.timeValue
  },
  timeValueFormatted(state) {
    if (state.mode == 'year')
      return moment(state.started_at).format('YYYY') + '年'
    else if (state.mode == 'season')
      return '第' + moment(state.started_at).quarter() + '季度'
    else if (state.mode == 'month')
      return moment(state.started_at).format('M月')
    else if (state.mode == 'duration')
      return moment(state.started_at).format('YYYY/MM/DD') + ' ~ ' + moment(state.started_end).format('YYYY/MM/DD')
  }
}

// const compute = ({
//   projects,
//   contracts,
//   bills,
//   transfers,
//   conditions,
//   bill_plans,
//   progress_plans,
//   progress_hrplans,
//   hr
// }) => {
//   let thisYearStart = moment().startOf('year')
//   let thisYearEnd = moment().endOf('year')
//   let nextYearStart = moment().startOf('year').add(1, 'years')
//   let nextYearEnd = nextYearStart.clone().endOf('year')

//   bill_plans.forEach(v => {
//     if (v.finished_at && !v.plan_finished_at)
//       v.plan_finished_at = v.finished_at
//   })
//   contracts.forEach(v => {
//     v.conditions = conditions.filter(c => c.contract_id == v.id)
//     v.bills = bills.filter(b => b.contract_id == v.id)
//     v.transfers = transfers.filter(b => b.contract_id == v.id)
//   })

//   projects.forEach((v, i) => {
//     v.contracts = contracts.filter(c => c.project_id == v.id)
//     v.conditons = contracts.map
//     v.conditions = []
//     v.amount_adjust = 0
//     v.current_bp = 0
//     if (Array.isArray(v.pb)) {
//       v.pb.forEach(b => {
//         v.current_bp += b.amount || 0
//       })
//     }

//     v.bills = v.contracts.reduce((acc, item) => {
//       return acc.concat(item.bills)
//     }, [])

//     v.transfers = v.contracts.reduce((acc, item) => {
//       return acc.concat(item.transfers)
//     }, [])

//     v.bp = v.pb

//     if (v.building_production) {
//       v.pb_percent = parseInt(v.current_bp * 100 / v.building_production) / 100
//     }

//     v.billed_amount = 0
//     v.transfer_amount = 0
//     v.plan_billed_amount_thisyear = 0
//     v.billed_amount_thisyear = 0

//     v.bill_plans = bill_plans.filter(c => c.project_id == v.id)

//     v.bill_plans.forEach(b => {
//       if (b.plan_finished_at && moment(b.plan_finished_at).isBetween(thisYearStart, thisYearEnd))
//         v.plan_billed_amount_thisyear += (b.amount || 0)
//     })

//     v.nodes = progress_plans.filter(b => b.project_id == v.id)
//     if (Array.isArray(progress_hrplans)) {
//       v.nodes.forEach(n => {
//         n.hrplans = progress_hrplans.filter(v => v.plan_id === n.id).map(f => ({
//           factor: f.factor,
//           position: f.position,
//         }))
//       })
//     }

//     v.contracts.forEach(c => {
//       c.bills.forEach(b => {
//         v.billed_amount += (b.amount || 0)
//         if (b.billed_at && moment(b.billed_at).isBetween(thisYearStart, thisYearEnd))
//           v.billed_amount_thisyear += (b.amount || 0)
//       })
//       c.transfers.forEach(b => {
//         v.transfer_amount += (b.amount || 0)
//       })
//       v.conditions = v.conditions.concat(c.conditions)
//       v.amount_adjust = v.amount_adjust + (c.amount_adjust || 0)
//       if (c.contract_type == '主合同') {
//         v.partA = c.partA
//         v.partB = c.partB
//       }
//     })

//     v.remianed_billed_amount_thisyear = v.plan_billed_amount_thisyear - v.billed_amount_thisyear

//     v.billed_percent = v.billed_amount / v.amount
//     v.transfer_percent = v.transfer_amount / v.amount
//     v.remained_amount = v.amount - v.billed_amount


//     v.contractCount = v.contracts.length
//     v.hr = hr.filter(c => c.project_id == v.id)

//     // 人力投入
//     v.hr_users = v.hr.filter(h => !h.finished_at)
//     v.hr_count = v.hr_users.length

//   })

//   bills.forEach(v => {
//     v.transfers = transfers.filter(b => b.bill_id == v.id)
//   })

//   return {
//     projects,
//     contracts,
//     bills,
//     transfers,
//     conditions,
//     bill_plans,
//     progress_plans,
//     progress_hrplans,
//     hr
//   }
// }


const actions = {
  auth({
    commit
  }) {
    return new Promise((resolve, reject) => {
      return API.get('/cm/permissions').then(res => {
        commit('savePermission', res.data.data)
        resolve(res.data.data)
      }).catch(reject)
    })
  },
  getPermissionDeps({
    commit
  }) {
    commit('startPermit')
    return new Promise((resolve, reject) => {
      API.get('/projects?q=dep').then(res => {
        commit('savePermitDep', res.data.data)
        resolve()
      }).catch(reject).finally(() => {
        commit('savePermitDep')
      })
    })
  },
  patchRole({
    commit
  }, data) {
    return new Promise((resolve, reject) => {
      return API.patch("/cm/roles/" + data.id, data).then(res => {
        commit("saveRole", data)
        resolve()
      }).catch(reject)
    })
  },
  removeRole({
    commit
  }, id) {
    return new Promise((resolve, reject) => {
      return API.delete("/cm/roles/" + id).then(res => {
        commit("removeRole", id)
        resolve()
      }).catch(reject)
    })
  },
  // get system data / roles
  getSystemData({
    commit
  }) {
    return Promise.all([
      API.get("/cm/roles").then(res => {
        commit('saveRoles', res.data.data)
      }),
      API.get("projects?q=simple").then(res => {
        commit('saveProjectsList', res.data.data)
      })
    ])
  },
  addRole({
    commit
  }, data) {
    return new Promise((resolve, reject) => {
      return API.post("/cm/roles", data).then(res => {
        let updateData = res.data.data
        commit("saveRole", Object.assign({}, data, updateData))
        resolve()
      }).catch(reject)
    })
  }
}


const mutations = {
  startComputing(state) {
    state.computingTotal = true
  },
  calcTotal(state) {
    let projects = getters.filtered_dep_projects(state)
    state.totalData = cm_utils.computeTotal(projects)
  },
  endComputing(state) {
    state.computeTotal = false
  },
  savePermission(state, permission) {
    state.permission = permission
    state.my_roles = permission.roles
    let role = localStorage.getItem("cm-role")
    if(permission.roles.length == 0)
      throw Error(403)
    if(permission.roles.findIndex(r=>r.id == role) == -1)
      role = null
    state.my_role = role || permission.roles[0].id
    state.loading_permit = false
    state.flow_todo_count = permission.flow_todo_count || 0
    state.flow_in_count = permission.flow_in_count || 0
  },
  saveProjectsList(state, items) {
    state.s_projects = items
  },
  saveRoles(state, roles) {
    state.roles = roles
  },
  saveRole(state, role) {
    let index = state.roles.findIndex(v => v.id == role.id)
    if (index == -1)
      state.roles.push(role)
    else
      state.roles.splice(index, 1, Object.assign({}, state.roles[index], role))
  },
  removeRole(state, id) {
    let index = state.roles.findIndex(v => v.id == id)
    if (index != -1)
      state.roles.splice(index, 1)
  },
  change_role(state, id) {
    if (state.my_roles.find(r=>r.id == id)){
      state.my_role = id
      localStorage.setItem("cm-role",id)
    }
  },
  startPermit(state) {
    state.loading_permit = true
  },
  startLoading(state) {
    state.loading_status = '数据下载中'
  },
  startComputing(state) {
    state.loading_status = '正在为您准备数据'
    state.loading_percent = 0
  },
  updateLoading(state, percent) {
    if (percent == 0 || percent - state.loading_percent > 5)
      state.loading_percent = percent
  },
  updateStatus(state, status) {
    state.loading_status = status
  },
  endLoading(state) {
    state.loading_status = ''
  },
  modifyDep(state, dep) {
    state.dep = dep
  },
  modifyMode(state, mode) {
    state.mode = mode
  },
  update_project(state, data) {
    let index = state.projects.findIndex(v => v.id == data.id)
    if (index != -1) {
      state.projects.splice(index, 1, Object.assign({}, state.projects[index], data))
    }
  },
  modifyTime(state, timeValue) {
    state.timeValue = timeValue
    if (state.mode == 'year') {
      let t = moment(timeValue)
      state.started_at = t.clone().startOf('year')
      state.finished_at = t.clone().endOf('year')
    } else if (state.mode == 'season') {
      let t = moment(timeValue)
      state.started_at = t.clone().startOf('quarter')
      state.finished_at = t.clone().endOf('quarter')
    } else if (state.mode == 'month') {
      let t = moment(timeValue)
      state.started_at = t.clone().startOf('month')
      state.finished_at = t.clone().endOf('month')
    } else if (state.mode == 'duration') {
      state.started_at = moment(timeValue[0]).startOf('month')
      state.finished_at = moment(timeValue[1]).endOf('month')
    }
  },
  save(state, {
    projects,
    contracts,
    conditions,
    bills,
    hr
  }) {


    state.loading_percent = 100
    state.loading_status = "数据计算完毕"


    state.projects = projects
    state.contracts = contracts
    state.conditions = conditions
    state.bills = bills
    state.hr = hr


  },
  saveDep(state, deps) {
    state.deps = deps
  },
  savePermitDep(state, permit_deps) {
    if (Array.isArray(permit_deps)) {
      state.permit_deps = permit_deps
      if (!permit_deps.includes(state.dep))
        state.dep = permit_deps[0]
    }
    state.loading_permit = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
