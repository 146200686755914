<style lang="less">
.l-report-panel{
  position:relative;background:#eeeeeeaa; padding:0;border-left:5px solid var(--border);
  border-right:1px solid var(--border);

  .l-report-panel-title{
    font-size:14px;font-weight:bold;border-bottom:1px solid var(--border);color:var(--text1);padding:5px;
    background:var(--bg1);
  }

  .l-report-panel-group{
    padding:10px;border-bottom:1px dashed #33333322;
    background:var(--bg3);
    .l-report-panel-group-title{
      color:var(--text3);font-weight:bold;text-align:left;padding-left:10px;
    }

    .num-board{
      padding:5px;
      border-radius: 5px;
      .l-value{
        color:var(--text3);
      }
    }
  }
}

.l-report-panel:hover{
  border-color:var(--primary);
}
</style>

<template>
  <div class="container l-report-panel" 
   @click="handleClick">
    <div class="flex-wrap flex-between l-report-panel-title">
      <BaseBoardSubTitle name="合同收费报表 " style="margin:0;padding:0;" />
      <a style="font-weight:normal;font-size:12px">查看详情  <BaseIcon icon="ios-arrow-forward" /></a>
      </div>
    <Row :gutter="20" class-name="panel-hoverable" class="l-report-panel-group">
      <Col :span="4"> <div class="container flex-col flex-center l-report-panel-group-title">
        <BaseIcon icon="contract" :size='35' />
        合同金额部分
      </div>
      </Col>
      <Col :span="4">
        <BaseNumboard :name="'合同总额'" :value="t.signed_amount" :unit="t.signed_amount_unit" align="flex-start" reverse></BaseNumboard>
      </Col>
      <Col :span="4">
        <BaseNumboard :name="'调整金额'" :value="t.adjust_amount" :unit="t.adjust_amount_unit" align="flex-start" reverse></BaseNumboard>
      </Col>
     
     <Col :span="4">
        <BaseNumboard :name="'调整后总额'" :value="t.amount" :unit="t.amount_unit" align="flex-start" reverse></BaseNumboard>
      </Col>
    </Row>
   
   <Row :gutter="20" class-name="panel-hoverable" class="l-report-panel-group">
      <Col :span="4"> <div class="container flex-col flex-center l-report-panel-group-title">
        <BaseIcon icon="ios-pie" :size='32' />
        合同余额
      </div>
      </Col>
      
      <Col :span="4">
        <BaseNumboard :name="'当前余额'" :value="t.remain_amount" :unit="t.remain_amount_unit" align="flex-start" reverse></BaseNumboard>
      </Col>
      <Col :span="4">
        <BaseNumboard :name="'次年初合同余额'" :value="t.next_year_remain_amount" :unit="t.next_year_remain_amount_unit" align="flex-start" reverse></BaseNumboard>
      </Col>
    </Row>
   <Row :gutter="20" class-name="panel-hoverable" class="l-report-panel-group">
      <Col :span="4"> <div class="container flex-col flex-center l-report-panel-group-title">
        <BaseIcon icon="money" :size='32' />
        收费到账情况
      </div>
      </Col>
      <Col :span="4">
        <BaseNumboard :name="'已开票金额'" :value="t.billed_amount" :unit="t.billed_amount_unit" align="flex-start" reverse></BaseNumboard>
      </Col>
      <Col :span="4">
        <BaseNumboard :name="'已开票比例'" :value="t.billed_percent" unit="%" align="flex-start" reverse></BaseNumboard>
      </Col>
      <Col :span="5">
        <BaseNumboard :name="'未到账金额'" :value="t.untrans_amount" :unit="t.untrans_amount_unit" align="flex-start" reverse></BaseNumboard>
      </Col>
      
    </Row>
     <Row :gutter="20" class-name="panel-hoverable" class="l-report-panel-group">
        <Col :span="4"> <div class="container flex-col flex-center l-report-panel-group-title">
        <BaseIcon icon="event" :size='30' />
        收费计划
      </div>
      </Col>
      <Col :span="4">
        <BaseNumboard :name="'年度收费计划'" :value="t.year_plan_bill" :unit="t.year_plan_bill_unit" align="flex-start" reverse></BaseNumboard>
      </Col>
      <Col :span="4">
        <BaseNumboard :name="'年度已开票'" :value="t.year_actual_bill" :unit="t.year_actual_bill_unit" align="flex-start" reverse></BaseNumboard>
      </Col>
      <Col :span="4">
        <BaseNumboard :name="'年度尚可开票'" :value="t.year_plan_bill_remain" :unit="t.year_plan_bill_remain_unit" align="flex-start" reverse></BaseNumboard>
      </Col>
       <Col :span="5">
        <BaseNumboard :name="'过期计划'" :value="t.year_overdate_plan_bill_amount" :unit="t.year_overdate_plan_bill_amount_unit" align="flex-start" reverse></BaseNumboard>
      </Col>
       
      </Row>
      
      <Row :gutter="20" class-name="panel-hoverable" class="l-report-panel-group">
       <Col :span="4"> <div class="container flex-col flex-center l-report-panel-group-title">
        <BaseIcon icon="jinxingzhong" :size='30' />
        近期开票计划
      </div>
      </Col>
      <Col :span="4">
        <BaseNumboard :name="'季度开票'" :value="t.season_actual_bill" :unit="t.season_actual_bill_unit" align="flex-start" reverse></BaseNumboard>
      </Col>
      <template v-if="t.future_months">
          <Col :span="5" :key="'_fm'+fm.name" v-for="fm in t.future_months">
          <BaseNumboard :name="fm.name" :value="fm.value" :unit="fm.unit" align="flex-start" reverse></BaseNumboard>
        </Col>
      </template>
    </Row>

    <div class="height:calc(100% - 100px);width:100%;background:#33aaff22;border-radius:5px;margin-top:10px;">
      
    </div>
  </div>
</template>

<script>
  export default {
    computed:{
      t(){
        return this.$store.getters["cm/totalData"]
      }
    },
    methods:{
      handleClick(){
        this.RouteTo("/core/cm/projects?q=合同收费报表")
      }
    }
  }
</script>

<style lang="less">
.l-billboard{
  background:var(--bg3);
  color:var(--text3);
  padding:20px;

  .l-billboard-name{
    font-size:14px;
  }

  .l-billboard-value{
    font-size:30px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  }
}
.panel-hoverable{
  background:var(--bg2);
  transition: all 0.5s;
 
}

.panel-hoverable:hover{
  background:var(--bg3);
  cursor: pointer;
}

.l-billboard-link{
  cursor: pointer;
}

.l-billboard-link:hover{
  border: 1px 1px var(--primary);
}

.l-billboard-unit{
  font-size:20px;
  font-family:黑体;
}
</style>