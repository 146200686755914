<template>
  <FormItem :label="label" :required="required" :prop="fkey">
    <BaseTagsInput 
      v-model="_value"
      :disabled="!editable"
      clearable
      placeholder="请输入(回车创建标签)"
      v-bind="config || {}" 
      :style="`width:${config?.width || 200}px`"
      />
  </FormItem>
</template>

<script>
  import defaultValueMixin from '../mixins/default-value'
  export default {
    mixins:[defaultValueMixin],
    cname:"标签输入",
    corder:13,
    props:['value','label','fkey','required','config','editable','old'],
    computed:{
      _value:{
        get(){
          return this.value
        },
        set(v){
          this.$emit('input',v)
        }
      }
    },
    properties:[
      {
        label:'字数限制',
        key:'maxlen',
        control:'Text',
      },
      {
        label:'宽度',
        key:'width',
        control:'Text'
      }

    ]
  }
</script>

<style lang="scss" scoped>

</style>