<template>
  <div class="pd-embed-control"  style="width:100%;height:100%;position:relative;">
     <div class="flex-wrap value-wrap" style="padding-right:32px;padding-left:8px;line-height:30px;cursor:pointer;" :style="{color:value?'#39f':''}"  @click="open">
      {{disabled?'请先创建数据表式':(value?'编辑流程' : '创建流程')}}
    </div>
    <div class="flex-wrap flex-center" style="position:absolute;right:0;top:0;bottom:0;width:30px;color:#aaa;" @click="$emit('input',null)" v-if="value">
      <Icon type="md-trash" size="16" />
    </div>

    <Modal v-model="modalSelector" width="1200" title="流程编辑器" footer-hide>
      <div>
        <div>新建 打开</div>
        <div style="width:100%;height:600px;background:#333;position:relative;" v-if="modalSelector">
          <keep-alive>
        <FlowEditor :flow="{edges:actions,nodes}" />
        </keep-alive>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
 import FlowEditor from '@/components/flow/editor/content'
  export default {
    data(){
      return {
        modalSelector:false,
        flow:{},
        actions:[{type:'edge',id:'a1',from:1,to:2},{type:'edge',id:'a2',from:2,to:3,label:'通过'}],
        nodes:[{type:'node',id:1,name:'发起'},{type:'node',id:2,name:"审核"},{type:'node',id:3,name:"结束"}],
        view:'editor'
      }
    },
    components:{FlowEditor},
    props:['value','option'],
    methods:{
      disabled(){
        return !this.option.form
      },
      open(){
        if(this.disabled)
          return
        if(this.option && this.option.flow)
          this.flow = cloneDeep(this.option.flow)
        else
          this.flow = {
          id:'create',
          name:this.option.name || '新建流程',
          nodes:[],
          actios:[]
        }
        this.modalSelector=true;
        this.view='editor'
      },
    }
  }
</script>

<style lang="scss" scoped>

</style>