export default {
  data(){
    return {
      loading:false,
      error:""
    }
  },
  methods:{
    startLoading(){
      if(this.loading)
        return false
      //this.loading = true
      this.error = ""
      return true
    },
    setLoadingError(e){
      this.error = e
    },
    endLoading(){
      var that = this
      that.loading = false
    }
  }
}