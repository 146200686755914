

<template>
    <App class="pm-editor">
       <AppBar>
          <div class="flex-wrap">
            表单设计器
          </div>
          <div class="flex-wrap">
            <IconTabs :items="menus" :current="formView" @on-select="formView=$event" />
          </div>
          <div class="flex-wrap split5">
            <AppAvatar />
            <Divider type="vertical" />
            <Button type="primary" @click="handleSave"><BaseIcon icon="save" style="margin-right:10px;" /> 保存并上传</Button>
          </div>
        </AppBar>
        <AppMask v-if="!is_ready" />
        
        <AppClient v-if="is_ready">
          
          <keep-alive>
                <template v-if="formView=='editor'">
                  <FormEditor 
                    ref="editor"
                    v-model="form"
                    style="height:100%" 
                  />
                </template>
                 <template v-else-if="formView=='test'">
                  <FormTest :form="form"  style="height:100%" />
                </template>
                <!-- <template v-else-if="formView=='flow'">
                  <flow-editor :form="form" :flow="flow" />
                </template> -->
                <template v-else-if="formView=='model'" >
                  <FormModel :model="model" :fields="fields" :form="form"  style="height:100%" />
                </template>
                <template v-else-if="formView=='print'" >
                  <FormPrint :form="form"  style="height:100%" />
                </template>
                 <template v-else-if="formView=='view'"  >
                 <FormHtml :form="form" style="height:100%" />
                </template>
                 <template v-else-if="formView=='config'" >
                   <FormOption :form="form"  style="height:100%" />
                </template>
             </keep-alive>
           
        </AppClient>
        <BaseLoadingModal v-model="loading" />
    
      </App>
    </template>
    
    <script>
    import responsableMixin from '@/mixins/responsable'
    import App from '@/components/app/app'
    import AppBar from '@/components/app/bar'
    import AppMask from '@/components/app/mask'
    import AppRight from '@/components/app/right'
    import AppAvatar from '@/components/app/avatar'
    import AppClient from '@/components/app/client'
    import IconButton from '@/components/app/IconButton'
    import IconTabs from '@/components/app/IconTabs'
    import TaskMenu from '@/components/task/app/bar/TaskMenu'
    import TaskFileName from '@/components/task/app/bar/TaskFileName'
    import FormEditor from '@/components/form/editor'
    import FormTest from '@/components/form/test'
      //import FlowEditor from '@/components/flow/editor'
    import FormHtml from '@/components/form/html'
    import FormOption from '@/components/form/option'
    import FormPrint from '@/components/form/print'
    import FormModel from '@/components/form/model'
    import moment from 'moment'
    import {cloneDeep} from 'lodash'
    
    function Plat(items){
      if(!items || items.length == 0)
        return []
      let plat_items = [...items]
      items.forEach(v=>{
        if(v.fields){
          plat_items = plat_items.concat(Plat(v.fields))
        }
      })
      return plat_items
    }
    
    export default {
      components: { App, AppBar,AppMask,FormEditor,AppClient,AppAvatar, IconButton,IconTabs, TaskMenu, TaskFileName,FormHtml,FormOption,FormPrint,FormTest,FormModel},
      data(){
        
        return {
          loading: false,
          showMenu: true,
          is_ready:false,
          model:{},
          formView:'editor',
          view: 'task',
          task:{
            name:"新表单"
          },
          showHelper: true,
          form:{
            id:'create',
            name:'新建表单',
            fields:[]
          },
          
        }
      },
      mixins:[responsableMixin],
      routerOption:{
        as_object:true
      },
      watch:{
        $route:{
          handler(v){
            let id = this.$route.params.id
            if(id == 'create'){
              this.form = {
                id:'create',
                name:"新建表单",
                type:"临时表单",
                config:{},
                fields:[]
              }
              this.is_ready = true
            }else{
              this.getData(id)
            }
          },
          immediate:true,
          deep:true
        },
        "form.model":{
          handler(v){
            if(v)
              this.getModel(v)
          }
        }
      },
      activated(){
         this.$nextTick(e=>{
           this.calcLayout()
        })
      },
      computed:{
        
        menus(){
            const def_items = [{
              icon:'edit',
              name:'表单设计',
              key:'editor'
            },
            // {
            //   name:'数据展示',
            //   key:'view'
            // },{
            //   name:'打印模板',
            //   key:'print'
            // },{
            //   name:'高级选项',
            //   key:'config'
            // },
            {
              icon:'richangbaoji01',
              name:'表单测试',
              key:'test'
            }]
            const items = [...def_items]
            if(this.form && this.form.model){
              items.push({name:'数据模型',key:'model',icon:'md-cube'})
            }
            return items
          },
        admin() {
          return this.role == 'admin'
        },
        fields(){
          return Plat(this.form.fields)
        },
        permissions() {
          if (this.role == 'admin') {
            return true
          } else return {
    
          }
        }
      },
      mounted(){
        this.$api.afterLogin(()=>{
          this.$store.dispatch("session/getModels")
        })
      },
      methods:{
        handleClose(){
          console.log('close')
          this.$close()
        },
        getModel(){
          this.$api.afterLogin(()=>{
            this.$api.get(`models/${this.form.model}`).then(res=>{
              this.model = res.data.data
            })
          })
        },
        handleSave(){
          if(this.form.id && this.form.id !== 'create'){
            this.loading = true
            this.$api.patch(`forms/${this.form.id}`,this.form).then(res=>{
              this.Success("保存成功")
              this.saved_at = moment().format()
            }).catch(e=>{
              this.Error(e)
            }).finally(()=>{
              this.loading = false
            })
          }else{
            delete this.form.id
            this.loading = true
            this.$api.post(`forms`,this.form).then(res=>{
              let {id} = res.data.data
              this.$router.replace(`/core/forms/${id}/editor`)
              this.$store.commit("session/removeByPath",`/core/forms/create`)
              this.$store.commit("session/push",{
                base_url:`/core/forms/${id}`,
                url:`/core/forms/${id}/editor`,
                icon:'edit',
                name:`编辑表单:${this.form.name}`
              })
            }).catch(e=>{
              this.Error(e)
            }).finally(()=>{
              this.loading = false
            })
          }
          
        },
       getData(id){
          this.$api.afterLogin(()=>{
            this.loading = true
            this.$api.get(`forms/${id}`).then(res=>{
              this.form = res.data.data
            }).catch(e=>{
              this.Error(e)
            }).finally(()=>{
              this.loading = false
              this.is_ready = true
            })
          })
       }
      }
    }
    </script>
    
    <style lang="less">
    .pm-panel-flex{
      display: flex;
    }
    .pm-panel-editing-wrap:hover{
      filter:brightness(1.2);
      cursor: pointer;
    }
    
    .pm-panel-selected{
      background:#fbbd2162 !important;
    }
    </style>