export default {
  props:{
    old:{},
    p_many:{
      type:Array,
      default:()=>([])
    },
    p_one:{
      type:Array,
      default:()=>([])
    },
  },
  methods:{
    getUpdateData(){
      if(this.p_many.includes(this.fkey)){
        let updateData = this.$util.gpt_compare_patched(this.old,this.value)
        if(!updateData.empty){
          return updateData
        }
      }else{
        // 判断两个数组是否相等
        return this.value
      }
    }
  }
 
}