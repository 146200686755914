<template>
  <div style="width:1200px;margin:0 auto">
            <Row :gutter="10">
              <Col :span="16">
              <Card style="width:100%" padding="40">
                <div style="font-size:20px;margin-bottom:20px">{{value.name}}</div>
                <Divider style="font-size:12px;color:#369">办理步骤</Divider>
                <pre style="color:#369">{{value.content}}</pre>
              </Card>

              <Card style="width:100%;margin-top:10px;">
                Form1
              </Card>
              <Card style="width:100%;margin-top:10px;">
                Form2
              </Card>
              </Col>
              <Col :span="8">
              <Card style="width:100%;">
                <div class="flex-wrap flex-center split5 flex-line">
                  <div class="flex-wrap flex-center split5" style="width:100%;padding:10px;text-align:center;border-radius:10px;background:var(--primary);color:#fff">

                    办理中
                  </div>
                  <div class="flex-wrap flex-center split5" style="width:100%;padding:10px;text-align:center;border-radius:10px;background:var(--warning);color:#fff">
                    <BaseIcon icon="clock" :size="19" />
                    截止 2022-12-5
                  </div>

                </div>
                <div class="flex-wrap flex-between" style="margin-top:20px" v-if="value.positions">
                  <div>
                    <BaseAvatar>{{value.positions[0]}}</BaseAvatar>
                  </div>
                  <div>
                    <div v-if="value.positions.length <= 1"></div>
                    <template v-for="p in value.positions.slice(1)">
                      <BaseAvatar :key="p">{{p}}</BaseAvatar>
                    </template>
                  </div>
                </div>

              </Card>
              <Card style="width:100%;margin-top:10px;" v-if="value.results" title="成果清单">
                <template v-for="r in value.results">
                  <div class="flex-wrap flex-between" style="width:100%" :key="r.id">
                    <div class="flex-wrap">{{r.name}}</div>
                    <div><a>未上传</a></div>
                  </div>
                </template>
              </Card>
              <Card style="width:100%;margin-top:10px;" title="跟踪记录">
                <Input type="textarea" long :rows="4" />
                <div class="flex-wrap flex-between" style="margin-top:10px">
                  <div></div>
                  <div><Button type="primary" icon="md-create" /></div>
                </div>
              </Card>
              <Card style="width:100%;margin-top:10px;" title="工作动态">
                
              </Card>
              </Col>
            </Row>
            <Row :gutter="10">
            </Row>
          </div>
</template>

<script>
  export default {
    props:{
      value:{
        type:Object,
        default:()=>({})
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>