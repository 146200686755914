<template>
  <BaseResult state="403" desc="您无权访问此页">
    <div style="margin-top:5px;font-size:12px">您可以 <a href="#" @click="$router.go(-1)">后退</a> 或 <a href="#" @click="RouteTo('/core/dashboard')">返回首页</a>
    </div>
  </BaseResult>
</template>
<script>
import BaseResult from '@components/base/Result'
export default {
  components: { BaseResult }
}
</script>
