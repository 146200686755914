<template>
  <div class="container" style="background:var(--bg3);">
    <div class="flex-wrap flex-between" style="height:40px;padding:0 10px;border-bottom:1px solid var(--border)">  
      <div style="font-weight:bold;">REDIS服务器状态</div>
      <div style="width:20px;height:8px;border-radius:5px;background:var(--success)"></div>
    </div>
    <div style="padding:10px;border-bottom:1px solid var(--border)">
      <div class="flex-wrap flex-between">
        <div style="white-space:nowrap">端口号</div><div>{{data.tcp_port}}</div>
      </div>
      <div class="flex-wrap flex-between">
        <div style="white-space:nowrap">平台</div><div>{{data.os}}</div>
      </div>
      <div class="flex-wrap flex-between">
        <div style="white-space:nowrap">版本</div><div>{{data.redis_version}}</div>
      </div>
      
      <div class="flex-wrap flex-between">
        <div style="white-space:nowrap">内存</div><div> {{parseInt(data.used_memory)}} B</div>
      </div>
      <div class="flex-wrap flex-between">
        <div style="white-space:nowrap">已传入数据</div><div>{{data.total_net_input_bytes}} B</div>
      </div>
      <div class="flex-wrap flex-between">
        <div style="white-space:nowrap">已输出数据</div><div>{{data.total_net_output_bytes}} B</div>
      </div>
      <div class="flex-wrap flex-between">
        <div style="white-space:nowrap">系统运行时间</div><div>{{parseInt(data.uptime_in_days)}} 天</div>
      </div>
      <div class="flex-wrap flex-between">
        <div style="white-space:nowrap">redis_mode</div><div>{{(data.redis_mode)}}</div>
      </div>
      <div class="flex-wrap flex-between">
        <div style="white-space:nowrap">已连接</div><div>{{data.connected_clients}}</div>
      </div>
      
    </div>
   

  </div>
</template>

<script>
  export default {
    props:['data']
  }
</script>

<style lang="scss" scoped>

</style>