
import _omitBy from 'lodash/omitBy'
import router from '../router'
const o = {
  pages:[],
  routers:[],
  page_modules:[]
}

/** Automatically Create page routes in '@/pages' (recusively)
  * Functional: 
  * 1 - generate routers from page directory path   
  *     parent/dashboard
  *     parent/other
  * 
  * 2 - defined: (defined @path {path: 'some_path' }), it will generator a router 
  *     with the given path in top router list    
  * 
  * 3 - index: would promoted for the directory's page,
  *     which means:
  *       /core/index  ->  /core
  *     doesn't affact other pages
  *       /core/dashboard -> /core/dashboard
  * 
  * 4 - frame: (defined @as_frame {as_frame}) compatite with <router-view> which need a children list
  *     4.1 if you defined a page-router as a frame, it will search it's sibling page 
  *         - *.vue
  *       and children tree nodes
  *         - [directory]
  *           - *.vue 
  *       and add them to frame 's children (do not appared in top routers)
  *       [note] defined @as_child {as_child:false}) or @frame ({frame:'frame_name'}
  * and frame_name != given_frame) will be excluded. this only affected on same level
  * routers, when you need to upgrade the page to the top router, you should set *
  * @as_root : true
  *       [note] 
  *     4.2 index-frame will auto add all children in children list (without excluded)
  *     4.3 other-frame should mark children page's {frame:'frame_name} to make it work.
  *     4.4 (default route) defined @as_default (as_default:true) to set the page as the frame's default page
  *       which means add a redirect router in the children list to redirect '/' to default page.
  *         if you didn't set a default page, it will use the children list's first page as default (maybe in alphabet order)
  * 
  * 5 - object: @as_object (defined {as_object:true})
  *     if will add an object param between parent-path and children-path,
  *       - /core/projects/:id
  *       - /core/projects/:id/dashboard
  *       - /core/projects/:id/config
  * 
  *     and you can use this.$route.params.id to get the object-id
  *     you can both use @as_frame and @as_object to create an object-frame
  * 
  * 6 - app: @as_app (defined {as_app:true})
  *     you can defined as_app to add a new router with prefix 'apps/' to the top routers list
  *     you can use '/apps/{this_module}' in an app mode
  * 
*/

const files = require.context('@/pages', true, /\.vue$/)
let raw_routers = []

function traversalLevel(list, func, arr) {
  if (!Array.isArray(list))
    return
  list.forEach(item => {
    func(item)
    if (item.children) {
      traversalLevel(item.children, func, arr)
    }
  })
}

for (const rawfilePath of files.keys()) {
  if(!rawfilePath){
    console.error(rawfilePath)
    continue
  }
  const fileContent = files(rawfilePath)
  let clipedPath = rawfilePath.replace(/index.js/, '').replace(/^\.\//, '').replace(/\.\w+$/, '').toLowerCase()
  let paths = clipedPath.split('/')
  let file_name = paths[paths.length - 1]
  let file = fileContent.default || fileContent
  let router =null
  router = {
    fullpath:'/'+paths.join('/'),
    file_name,
    name:file.name || '',
    paths,
    parent_path:paths.length > 1?('/'+paths.slice(0,-1).join('/')):undefined,
    rawfilePath,
    ...(file.routerOption || {}),
    component: resolve => require(['@pages/' + rawfilePath.slice(2)], resolve)
  }

  if (file_name == 'index' || router.as_index) {
    router.fullpath = '/'+paths.slice(0,-1).join('/')
    router.file_name = router.paths[router.paths.length - 2]
    if(paths.length > 2)
      router.parent_path = '/'+paths.slice(0,-2).join('/')
    else
      delete router.parent_path
    router.as_index = true
  }

  raw_routers.push(router)
}


const router_map = {}
let routers = []
raw_routers.forEach((r,i,a) => {
  r.parent = a.find(v=>v.fullpath == r.parent_path)
  if(r.parent){
    if(r.parent.children){
      r.parent.children.push(r)
    }else{
      r.parent.children = [r]
    }
  }
})

traversalLevel(raw_routers.filter(v=>!v.parent),item=>{
  if(item.as_index){
 
    if (item.as_frame && item.children) {
      item.children = item.children.filter(v => v.as_child !== false)
      if (item.children.length > 0){
        item.default = item.children.find(v => v.as_default) || item.children[0]
        item.children.forEach(v=>{
          v.as_child = true
        })
      }
    }
  }
},raw_routers)


raw_routers.forEach(r => {
  if(r.as_object){
     if(r.as_index){
      r.path = r.fullpath+'/:id'
     }else{
      r.path = r.parent_path+'/:id/'+r.file_name
     }
     if(r.children){
      r.children.forEach(c=>{
        c.path = c.file_name
      })
     }
  }else if(r.as_child){
    r.path = r.file_name
  }

  if(r.as_frame){
    if (r.default) {
      r.children.splice(0, 0, {
        path:'',
        redirect:r.default.file_name || r.default.fullpath
      })
    }
  }

  if(!r.path)
    r.path = r.fullpath
})

raw_routers.forEach(r=>{
  if(r.as_modcon){
    r.path = r.path.replace('/mod','/mod-:mod')
  }
})

function clearRouter(router){
  if(!router)
    return
  Object.keys(router).forEach(key => {
     if (key != 'path' && key != 'redirect' && key != 'component' && key != 'children')
       delete router[key]
  })
  return router
}

/** 
 * traversal each level route and pick up root routers
 */


export let PageRouters = raw_routers.filter(v => !v.parent).map(clearRouter)
traversalLevel(raw_routers.filter(v => !v.parent), clearRouter, raw_routers)

console.log("Routes:",PageRouters)

export default {}