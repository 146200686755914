<template>
  <div style="width:400px;">
    <div class="flex-wrap">
    <Button icon="md-locate"  @click="showSelect=true"  />
    <div style="line-height:18px;margin-left:10px;font-size:12px;">
      <div style="font-size:14px;">{{location.poiname}}</div>
    <div>{{location.address}}</div>
    </div>
    </div>


      <Modal v-model="showSelect" title="选择位置" transfer footer-hide width="400">
        <iframe id="mapPage" v-if="showSelect" width="100%" height="400px" frameborder=0
            src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=6J2BZ-NJQK4-IN4UV-XO7GJ-3NFD7-AWBMA&referer=myapp">
        </iframe>

      </Modal>

  </div>

</template>

<script>
export default {
  data(){
    return {
      showSelect:false,
      location:{}
    }
  },
  props:{
    value:{
      type:Object,
      default:()=>{}
    }
  },
  watch:{
    value:{
      handler(v){
        this.location = v || {}
      },
      deep:true,
      immediate:true
    }
  },
  mounted(){
    this.location = this.value || {}
    window.addEventListener('message',e=>{
       var loc = e.data;
        if (loc && loc.module == 'locationPicker') {//防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
          this.location = {
            city:loc.city,
            lat:loc.latlng.lat,
            lng:loc.latlng.lng,
            address:loc.poiaddress,
            poiname:loc.poiname
          }
          this.showSelect = false
          this.$emit('input',this.location)

        }
    },false)

  },
  methods:{
    savePosition(){
      this.showSelect = false
    },
    startSelectLocation(){
      this.showSelect = true
    },
    changeMap(lat,lng){
      if(this.map){
      this.map.setCenter(new TMap.LatLng(lat,lng));
      this.markerLayer.remove(['1'])
      this.markerLayer.add({
                "id": "1",   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
                "styleId": 'myStyle',  //指定样式id
                "position": new TMap.LatLng(lat, lng),  //点标记坐标位置

            })

      }

    },
    startSelectLocation(){

    }
  }
}
</script>

<style>

</style>
