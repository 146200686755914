var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-wrap flex-between",staticStyle:{"height":"100%","position":"relative","overflow":"hidden"}},[_c('div',{staticStyle:{"height":"100%","width":"280px","border-right":"1px solid var(--border)","overflow":"hidden"}},[_c('div',{staticClass:"flex-wrap",staticStyle:{"height":"40px","padding":"0 5px","font-weight":"bold","border-bottom":"1px solid var(--border)"}},[_vm._v("表单模型:"+_vm._s(_vm.form.name))]),_c('div',{staticStyle:{"height":"calc(100% - 40px)","overflow-y":"auto"}},[_vm._l((_vm.controls),function(c){return [_c('div',{key:c.id,staticClass:"flex-wrap flex-between model-field",class:{
                  'model-field-binded':_vm.model.fields.find(f=>f.key == c.key)?true:false
              }},[_c('div',[_vm._v(_vm._s(c.name))]),_c('div',{staticStyle:{"text-align":"right"}},[_c('div',[_vm._v(_vm._s(c.control))]),_c('div',[_vm._v(_vm._s(c.key))])])])]})],2)]),_c('div',{staticStyle:{"height":"100%","position":"relative","flex-grow":"1"}},[_c('div',{staticClass:"flex-wrap",staticStyle:{"height":"40px","padding":"0 25px","font-weight":"bold"}},[_vm._v("数据模型:"+_vm._s(_vm.model.name))]),_c('div',{staticClass:"flex-col align-start",staticStyle:{"padding":"0 20px","flex-wrap":"wrap","height":"calc(100% - 30px)","align-content":"flex-start"}},[_vm._l((_vm.model.fields),function(f){return [_c('div',{key:f.key,staticClass:"flex-wrap flex-between model-field",class:{
                  'model-field-readonly':!f.editable,
                  'model-field-binded':_vm.controls.find(c=>c.key == f.key)?true:false
              },on:{"click":function($event){_vm.selected = f}}},[_c('div',[_vm._v(" "+_vm._s(f.key)+" "),_c('div',{staticStyle:{"color":"var(--subtext2)"}},[_vm._v(_vm._s(f.type))])]),_c('div',{staticStyle:{"text-align":"right"}},[_c('div',{style:(`color:var(--primary)`)},[_vm._v(_vm._s(f.editable?' ':'只读'))]),(f.editable)?[(_vm.controls.find(c=>c.key == f.key) == null)?_c('div',{style:(`curosr:pointer;color:${f.binded?'var(--success)':'var(--primary)'}`),on:{"click":function($event){_vm.selected=f;_vm.showBindModal=true}}},[_c('BaseIcon',{attrs:{"icon":"edit"}}),_vm._v(" 编辑")],1):_c('div',{style:(`curosr:pointer;color:var(--success)`),on:{"click":function($event){_vm.selected=f;_vm.showBindModal=true}}},[_c('BaseIcon',{attrs:{"icon":"edit"}}),_vm._v(" "+_vm._s(_vm.controls.find(c=>c.key == f.key).name))],1)]:_vm._e()],2)])]})],2)]),_c('Modal',{attrs:{"title":`绑定数据 - ${_vm.selected?_vm.selected.key:''}`,"footer-hide":"","width":580},model:{value:(_vm.showBindModal),callback:function ($$v) {_vm.showBindModal=$$v},expression:"showBindModal"}},[_c('div',{staticClass:"flex-wrap",staticStyle:{"flex-wrap":"wrap"}},[_vm._l((_vm.need_controls),function(c){return [_c('div',{key:c.id,staticClass:"flex-wrap flex-between model-field",class:{
                  'model-field-binded':_vm.model.fields.find(f=>f.key == c.key)?true:false
              },on:{"click":function($event){return _vm.bind(c)}}},[_c('div',[_vm._v(_vm._s(c.name))]),_c('div',{staticStyle:{"text-align":"right"}},[_c('div',[_vm._v(_vm._s(c.control))]),_c('div',[_vm._v(_vm._s(c.key))])])])]})],2)]),_c('Modal',{attrs:{"title":`绑定数据 - ${_vm.selected?_vm.selected.key:''}`,"footer-hide":"","width":580},model:{value:(_vm.showBindForm),callback:function ($$v) {_vm.showBindForm=$$v},expression:"showBindForm"}},[_c('div',{staticClass:"flex-wrap",staticStyle:{"flex-wrap":"wrap"}},[_vm._v(" "+_vm._s(_vm.need_controls)+" "),(_vm.need_controls.length == 0)?_c('BaseEmpty',{attrs:{"message":"没有可绑定的组件"}}):_vm._e(),_vm._l((_vm.need_controls),function(c){return [_c('div',{key:c.id,staticClass:"flex-wrap flex-between model-field",on:{"click":function($event){return _vm.bind(c)}}},[_c('div',[_vm._v(_vm._s(c.name))]),_c('div',{staticStyle:{"text-align":"right"}},[_c('div',[_vm._v(_vm._s(c.control))]),_c('div',[_vm._v(_vm._s(c.key))])])])]})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }