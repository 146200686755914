<template>
   <div class="flex-wrap menu-title"><BaseIcon :icon="icon" size="16" style="margin-right:5px" />{{name}}</div>
</template>

<script>
  export default {
    props:['name','icon']
  }
</script>

<style lang="less">
.menu-title{
  font-weight: normal;
}
</style>