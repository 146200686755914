<template>
  <div class="container">
    <div class="flex-wrap" style="height:40px;width:100%;padding:0 5px;">
      <Input search clearable v-model="searchText" placeholder="材料名称/型号" style="width:200px;margin-right:10px;" />
       信息级别 <ButtonGroup style="margin-left:10px">
        <template v-for="(l,i) in levels">
          
        <Button :key="`levl_${l}`" :type="selected_level == i?'primary':'default'" @click="selected_level=i;getData()">{{l}}</Button>
        </template>
      </ButtonGroup>
    </div>
    <div style="height:calc(100% - 50px);position:relative;margin:0 5px;border:1px solid var(--border);border-radius:5px;padding-bottom:5px;">
      <BaseTable :columns="cols" :data="filteredItems" />
    </div>

    <Modal v-model="showModalEditor" :title="formData.id?'修改':'新增'" footer-hide :width="1000">
      <FormCreateProject style="margin:0 20px" :value="formData" @on-cancel="showModalEditor = false" @on-submit="handleSubmit" />
    </Modal>

 <Drawer v-model="preview" :width="800">
      <BasePreview :url="previewURL" />
    </Drawer>

  </div>
</template>

<script>
import FormCreateProject from '@/components/mat/CreateProductForm'
export default {
  data(){
    return {
      searchText:"",
       formData:{},
         levels:['L1','L2','L3'],
          selected_level:0,
       previewURL:"",
       preview:false,
       showModalEditor:false,
      items:[]
    }
  },
  components:{FormCreateProject},
  computed:{
    filteredItems(){
      let items = this.items
      if(this.searchText){
       
        items = items.filter(v=>{
          if(v.name && v.name.includes(this.searchText.trim()))
            return true
          if(v.model && v.model.includes(this.searchText.trim()))
            return true
          
          return false
        })

      }
    
      if(this.selected_level)
        items = items.filter(v=>v.level >= this.selected_level)
      return items
    },
    cols(){
      return [{
        type:"index",
        title:"序号",
        width:80
      },{
        type:"type",
        
        key:"type_id",
        title:"类型",
        width:80,
        option:{
          getters:"mat/types"
        }
      },{
        type:"text",
        
        key:"name",
        title:"名称/型号",
        width:200,
        render:(h,{row})=>{
          let logo = h('img',{style:'width:70px;height:70px;border:1px solid var(--border);margin:10px;flex-shrink:0',domProps:{src:row.avatar || 'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/empty-img.png'}})
          let name = h('div',[h('div',{style:'text-align:left'},row.name),h('div',{style:'color:var(--subtext3);font-size:12px;text-align:left'},row.model || '')])
          return h('div',{class:'flex-wrap'},[logo,name])
        }
      },{
        type:"type",
        title:"生产商",
        key:"producer_id",
        width:180,
         render:(h,{row})=>{
          let supplier = this.$store.getters['mat/companies'].find(v=>v.id == row.producer_id)
          if(!supplier)
            return h('span','-')

          let logo = h('img',{style:'width:50px;height:50px;border:1px solid var(--border);margin:10px;flex-shrink:0',domProps:{src:supplier.avatar || 'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/empty-img.png'}})
          let name = h('div',[h('div',{style:'text-align:left'},supplier.name),h('div',{style:'color:var(--subtext3);font-size:12px;text-align:left'},supplier.address || '')])
          return h('div',{class:'flex-wrap'},[name])
        }
      },{
        type:"time",
        title:"询价日期",
        key:"accquired_at",
        width:90,
        option:{
          type:"date"
        }
      },{
        type:"number",
        title:"无税价格",
        key:"notax_amount",
        width:100,
       
        option:{
          type:"fullAmount"
        },
        render:(h,{row})=>{
          let amount = h('BaseAmount',{props:{value:row.notax_amount}})
          let unit = h('span',{style:"color:var(--subtext3);margin-left:5px;"},'元/'+row.unit)
          return h('div',{class:"flex-wrap flex-right"},[amount,unit])
        }
      },{
        type:"number",
        title:"含税价格",
        key:"amount",
        width:100,
        option:{
          type:"fullAmount"
        },
        render:(h,{row})=>{
          let amount = h('BaseAmount',{props:{value:row.amount}})
          let unit = h('span',{style:"color:var(--subtext3);margin-left:5px;"},'元/'+row.unit)
          return h('div',{class:"flex-wrap flex-right"},[amount,unit])
        }
      },{
         type:"text",
        title:"税率",
        key:"taxrate",
        width:80,
        render:(h,{row})=>{
          let percent = h('span',{style:"color:var(--subtext3);margin-left:2px;"},'%')
          return row.taxrate?h('span',[row.taxrate,percent]):h('span','-')
        }
        },{
        type:"bool",
        title:"含运费",
        key:"with_tran",
        width:80
      },{
        type:"string",
        key:'type',
        width:100,
        title:"询价方式",
      },{
        type:"file",
        title:"询价依据",
        width:80,
        key:'file',
        render:(h,{row})=>{
          if(!row.file)
            return h('span','-')
          return h('Button',{props:{size:"small"},on:{
            click:()=>{
              this.previewURL=row.file;this.preview=true;
            }
          }},'查看')
        }
      },{
         type:"type",
        title:"供货商",
        key:"supplier_id",
        width:180,
         render:(h,{row})=>{
          let supplier = this.$store.getters['mat/companies'].find(v=>v.id == row.supplier_id)
          if(!supplier)
            return h('span','-')

          let logo = h('img',{style:'width:50px;height:50px;border:1px solid var(--border);margin:10px;flex-shrink:0',domProps:{src:supplier.avatar || 'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/empty-img.png'}})
          let name = h('div',[h('div',{style:'text-align:left'},supplier.name),h('div',{style:'color:var(--subtext3);font-size:12px;text-align:left'},supplier.address || '')])
          return h('div',{class:'flex-wrap'},[name])
        }
        },{
        type:"state",
        key:"level",
        title:"级别",
        width:80,
         render: (h, { row }) => {
          
          const levels = ["L1","L2","L3"]
          const status_colors = ["#aaa","var(--primary)","var(--active)"]
          let iconArrow = h("BaseIcon", { props: { icon: "ios-arrow-down" }, style: "margin-left:3px;" })
          let domContent = h(
            "div",
            { class: "flex-wrap flex-center", style: { height: "40px" } },
            [
              h(
                "div",
                {
                  style: {
                    width: "45px",
                    borderRadius: "50px",
                    textAlign: "center",
                    background: status_colors[row.level],
                    color: "#fff",
                  },
                },
                [levels[row.level], iconArrow]
              ),
            ]
          );
          let domListItems = levels.map((v, i) =>
            h(
              "DropdownItem",
              { props: { name: i, selected: row.level == v, value: i } },
              v
            )
          );
          let domList = h("DropdownMenu", { slot: "list" }, domListItems);
          let domDropDown = h(
            "Dropdown",
            {
              props: { trigger: "click", size: "small" },
              on: {
                "on-click": (level) => {
                  this.$api
                    .patch("/mat/prices/" + row.id, { level })
                    .then((res) => {
                      this.$set(this.items.find(v=>v.id == row.id),'level',level)
                      this.$Notice.success({
                        title: "修改成功"
                      });
                    });
                },
              },
            },
            [domContent, domList]
          );
          return domDropDown;
        },
      },{
        title:"操作",
        render:(h,{row})=>{
          let buttons = [
          h('Button',{props:{size:"small"},style:"margin-left:10px",on:{
            click:()=>{
              this.handleDelete(row)
            }
          }},'删除'),
          ]
          return h("div",{class:"flex-wrap flex-center"},buttons)
        }
      }]
    },
  },
  mounted() {
		setTimeout(() => {
			this.getData();
		}, 500);
	},
	methods: {
		getData() {
			this.$api.get("mat/prices").then((res) => {
				let items = res.data.data;
        items.forEach(p=>{
           if(p.amount && p.taxrate && !p.notax_amount){
                p.notax_amount = parseInt(100*(p.amount * (1-p.taxrate/100)))/100
              }else{
                if(p.notax_amount && p.taxrate && !p.amount){
                p.amount = parseInt(100*(p.notax_amount * (1+p.taxrate/100)))/100
              }
              }
        })
        this.items = items
			});
		},
    handleEdit(e){
      this.formData = {
        id:e.id,
        name:e.name,
        description:e.description,
        address:e.address,
        ent_type:e.ent_type,
        registered_funds:e.registered_funds
      }
      this.showModalEditor = true
    },
		handlePatch(e) {
			this.modalEditor = true;
		},
    handleSubmit(e){
      if(e.id){
        this.$api.patch(`mat/products/${e.id}`,e).then(res=>{
          let item = {...e}
          let index = this.items.findIndex(v=>v.id == e.id)
          
          this.items.splice(index,1,item)
          this.Success({title:"修改成功"});
          this.showModalEditor = false
        }).catch(this.Error)
      }else{
        this.$api.post("mat/products",e).then(res=>{
          let item = {...e}
          Object.assign(item,res.data.data)
          this.Success({title:"添加成功"});
          this.items.push(item)
          this.showModalEditor = false
        }).catch(this.Error)
      }
    },
		handleDelete(e) {
			this.Confirm(`将删除此产品(${e.name}/${e.model})，是否继续?`, () => {
				this.$api.delete("mat/prices/"+e.id).then(() => {
					this.Success({title:"删除成功"});
          let index = this.items.findIndex(v=>v.id == e.id)
          if(index != -1)
            this.items.splice(index,1)
				});
			});
		},
	},
};
</script>

<style>

</style>