<template>
  <div class="container p-text-list" style="z-index:999">
    <div class="l-title flex-wrap flex-between">
      <div class="flex-wrap"><BaseIcon :icon="data.icon" style="margin-right:5px" /> {{data.name}}</div>
      <div style="font-weight:normal;font-size:14px;cursor:pointer;color:var(--primary);cursor:pointer;" v-if="data.link" @click="open(data)">查看更多 <BaseIcon icon="ios-arrow-forward" /></div></div>
    <Divider style="margin:10px 0;max-width:200px;" color="var(--border)" />
    <div class="l-content">
      <template v-for="d in items">
        <div class="l-item flex-wrap flex-between" :key="d.id" @click="open(d)">
          <div>{{d.content}} <img src="https://nbgz-pmis-1257839135.cos.ap-shanghai.myqcloud.com/beauty/new.gif" style="display:inline;width:25px;height:12px;margin-left:5px;" /></div>
          <div style="color:var(--success)">{{moment(d.time).format('YYYY/MM/DD')}}</div></div>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    props:{
      data:{
        type:Object,
        default:()=>({})
      }
    },
    computed:{
      items(){
        if(this.data.raw)
          return this.data.raw
        else
          return []
      }
    },
    methods:{
      open(d){
        this.RouteTo(d.link,{},true)
      }
    }
  }
</script>

<style lang="less">
.p-text-list{
  padding:20px;
 
  
  background:var(--bg2);
  .l-title{
  font-size:16px;
  font-weight: bold;
  color:var(--text2);

}

.l-content{
  .l-item{
    color:var(--primary);
    cursor: pointer;
    font-size:14px;
    padding:3px;
    transition: all 0.3s;
  }

  .l-item:hover{
    filter:brightness(1.1);

  }
}
}

</style>