<template>
  <div class="container" style="height:100%;">
      
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  routerOption:{
    as_frame:true
  }
};
</script>

<style lang="less" scoped>
.wrap {
  width: 1200px;
  margin: 0 auto;
  padding: 40px 0;
  position: relative;
}

.group-item {
  cursor: pointer;
}

.group-item-count {
  padding: 1px 5px;
  font-size: 12px;
  margin-left: 5px;
  border-radius: 50%;
  background: var(--bg2);
  color: var(--text2);
}

.group-actived {
  color: var(--primary);
}
</style>