<template>
    <Table 
      :columns="cols" 
      :data="tableData" 
      :loading="loading"
      border
      :width="cols.reduce((a,v)=>a+v.width,0)+5"
      >
      <BaseLoading v-if="loading" />
      <div class="flex-wrap flex-right" style="font-size:12px;color:var(--subtext3)">
                      
                  </div>
    </Table>
  </template>
  
  <script>
    
  import tableRender from '../../base/tableRender'
  export default {
      name:'CmTableYearSmallProjects',
      props:{
        year:{
            type:String | Number
        }
    },
      data(){
          return {
              tableData:[{
                  dep:'公司'
              },{
                  dep:'建设管理事业部',
              },{
                  dep:'房建监理事业部'
              },{
                  dep:'市政监理事业部'
              }],
              loading:true
          }
      },
      computed:{
          cols(){
              return [{
                  key:'dep',
                  title:'部门',
                  align:'center',
                  width:200
              },{
                  key:'amount',
                  title:'新签合同总额',
                  align:'center',
                  width:200,
                  render:(h,params)=>tableRender.renderAmountNoFormat(h,params.row.amount)
              },{
                  key:'count',
                  title:'新签项目数量（项）',
                  align:'center',
                  width:200,
                 
              }]
          }
      },
      mounted(){
          this.getData()
      },  
      methods:{
          getData(){
              this.loading = true
              this.$api.get(`cm/tables/small_projects?year=${this.year}`).then(res=>{
                  let result = res.data.data
                  this.tableData = result.data
                  this.created_at = result.created_at
              }).finally(()=>{
                  this.loading = false
              })
          },
          exportExcel(){
  
          }
      }
  }
  </script>
  
  <style>
  
  </style>