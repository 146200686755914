import { RectNode, RectNodeModel, h } from '@logicflow/core';

// 自定义事件节点（方形，带闪电图标）
class EventNode extends RectNode {
  getShape() {
    const { model } = this.props;
    const { x, y, width, height } = model; // 方形的宽和高

    // 定义左上角的闪电 SVG 图标
    const lightningIcon = h('svg', {
      x: x - width / 2 + 5, // 左上角的偏移位置
      y: y - height / 2 + 5,
      width: 20,
      height: 20,
      viewBox: '0 0 1024 1024',
      fill: '#FFD700' // 黄色闪电图标
    }, [
      h('path', {
        d: 'M512 64L320 576h192l-64 384L704 448H512z'
      })
    ]);

    const text = h('text', {
        x: x - width / 2 + 25, // 在节点左上角
        y: y - height / 2 + 18,
        fill: '#1890FF',
        fontSize: 12,
        textAnchor: 'start'
      }, "事件");

    return h('g', {}, [
      // 绘制方形背景
      h('rect', {
        x: x - width / 2,
        y: y - height / 2,
        width,
        height,
        fill: '#E6F7FF', // 背景颜色
        stroke: '#1890FF', // 边框颜色
        strokeWidth: 2,
        rx: 10, // 设置水平圆角半径
        ry: 10  // 设置垂直圆角半径
      }),
      // 添加闪电图标
      lightningIcon,
      // 添加文本（居中）
      text
    ]);
  }
}

// 自定义事件节点模型
class EventNodeModel extends RectNodeModel {
  initNodeData(data) {
    super.initNodeData(data); // 确保传递默认参数
    this.width = 160;
    this.height = 80;
    this.text = data?.text || ''; // 节点的文本
    this.properties = data?.properties || {}; // 节点的额外属性
  }

  getNodeStyle() {
    const style = super.getNodeStyle();
    return {
      ...style,
      stroke: '#1890FF', // 节点的边框颜色
      fill: '#E6F7FF',   // 节点的填充颜色
    };
  }

  // 输出对象 {type, view, model}
  getData() {
    return {
      type: 'event_node',
      view: 'EventNode',
      model: this
    };
  }
}

export default {type:'event_node',view:EventNode, model:EventNodeModel };
