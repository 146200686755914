<template>
  <Row :gutter="config.gutter" style="width:100%">
    <template v-for="(p, i) in localList">
    <Col
      
      :key="p.key"
      :span="p?.config?.span || span"
       v-if="acl[p.fullkey]!=2"
      :align="p?.config?.align"
      :class="selected === p ? 'form-item-selected' : ''"
      @click.stop="$emit('select', p)"
    >
      <draggable
        tag="div"
        v-model="p.fields"
        group="control"
        :animation="500"
        @input="emitChange"
        :disabled="!design"
        filter=".drag-tip"
        :style="design ? 'background:#ffffffee;min-height:20px;width:100%;display: block;border:1px dashed #aaa;position:relative;' : 'min-height:20px;width:100%;display: block;position:relative;'"
      >
        <!-- 设计模式 -->
        <template v-if="design">
          <div
            class="flex-wrap flex-center"
            style="height:56px;width:100%;color:var(--primary)"
            v-if="!p || !p.fields || p.fields.length === 0"
          >
            拖拽至此处
          </div>
          <template v-else>
            <div
              v-for="(c, idx) in p.fields"
              :key="c.id"
              class="form-item flex-wrap flex-between"
              :class="selected && selected.id === c.id ? 'form-item-selected' : ''"
              @click.stop="handleClick(c)"
              style="border:1px solid var(--primary);margin:5px;position:relative;overflow:hidden;padding:5px;"
            >
              <div class="l-mask" v-if="!isContainer(c.control) && design"></div>
              <div class="l-remove-btn" @click.stop="removeField(p.fields, idx)">
                <Icon type="md-close" />
              </div>
              <component
                :is="c.control"
                :required="c.required?true:false"
                :selected="selected"
                :label="c.name"
                :id="c.id"
                :fields="c.fields"
                :fkey="c.key"
                  :value="c?.config?.defaultValue"
                :config="c.config"
                :editable="editable"
                :design="design"
                @select="$emit('select', $event)"
                @change="$set(c,'fields',$event);emitChange()"
              />
            </div>
          </template>
        </template>
        
        <!-- 非设计模式 -->
        <template v-else>
          <template  v-for="c in p.fields" >
          <div
           
            :key="c.id"
             v-if="acl[c.fullkey]!=2"
            class="form-item flex-wrap flex-between"
            @click="handleClick(c)"
            style="position:relative;overflow:hidden;"
          >
            <component
              :ref="c.key"
              :is="c.control"
              :label="c.name"
              :fkey="c.key"
              :required="c.required?true:false"
              :fields="c.fields"
              :model="model"
              :id="c.id"
              
              :config="c.config"
              :editable="editable && (!c.config || !c.config.disabled) && !acl[c.fullkey]" 
              :value="isContainer(c.control) ? value : value[c.key]"
              :old="isContainer(c.control)?old:(old?old[c.key]:undefined)"
              @input="handleEmitInput(c, $event)"
            />
          </div>
        </template>
        </template>
      </draggable>
    </Col>
  </template>
  </Row>

</template>

<script>
import draggable from 'vuedraggable';
import { cloneDeep } from 'lodash';

export default {
  cname: "分栏", // 保留 cname 属性
  icon: "biaoge",
  corder:78,
  components: {
    draggable
  },
  props: {
    id: {
      type: String
    },
    fkey: {
      type: String
    },
    design: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Object
    },
    fields: {
      type: Array,
      default: () => ([])
    },
    old:{},
    value: {
      type: Object | Array,
      default: () => ([])
    },
    model: {
      type: Object,
      default: () => ({})
    },
    old: {
      type: Object,
      default: () => ({})
    },
    editable: {
      type: Boolean
    },
    acl:{
      type:Object,
      default:()=>({})
    },
    config: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      localList: cloneDeep(this.fields) || []
    };
  },
  computed: {
    colNum() {
      return this.config.cols || 2;
    },
    span() {
      return 24 / this.colNum;
    }
  },
  watch:{
      fields:{
        handler(v){
          this.localList = cloneDeep(v) || []
        },
        deep:true
      }
    },
  properties: [ // 保留 properties 属性
    {
      label: '分栏',
      key: 'cols',
      group: 'config',
      control: 'Text',
      option: {
        number: true
      }
    },
    {
      label: '间距',
      key: 'gutter',
      group: 'config',
      control: 'Text'
    }
  ],
  methods: {
    getUpdateData(e){
      let updateData ={}
      this.localList.forEach(l=>{
        l.fields.forEach(f=>{
          if(!Array.isArray(this.$refs[f.key]) || !this.$refs[f.key][0])
            return
          let component = this.$refs[f.key][0]
          let getUpdate = component.getUpdateData
          if(getUpdate){
            let val = getUpdate()
            if(this.isContainer(f.control)){
              if(val && Object.keys(val).length > 0)
                Object.assign(updateData,val)
            }else{
              if(val !== undefined)
                updateData[f.key] = val
            } 
            
          }else{
            if((this.old[f.key] !== this.value[f.key])){
              updateData[f.key] = this.value[f.key]
            }
          }
        })
        
      })
      if(Object.keys(updateData).length > 0){
        return updateData      
      }     
    },
    handleClick(c){
      this.$emit('select',{item:c,ctx:this})
    },
    handleEmitInput(c, e) {
      if (this.isContainer(c.control)) {
        this.$emit('input', e);
      } else {
        this.$emit('input', { key: c.key, value: e });
      }
    },
    removeField(fields, index) {
      fields.splice(index, 1);
      this.emitChange()
      
    },
    initCol(i) {
      return {
        key: `${this.id}-col${i}`,
        id: `${this.id}-col${i}`,
        order: i,
        name: `组${i + 1}`,
        control: 'UgoCol',
        config:{},
        fields: []
      };
    },
    emitChange() {
      this.$emit('change',cloneDeep(this.localList))
    },
    isContainer(control){
      return this.$store.getters['forms/containers'].includes(control)
    },
  },
  watch: {
    fields:{
      handler(v) {
        const initialFields = [];
        for (let i = 0; i < this.colNum; i++) {
          if(v[i]){
            initialFields.push(v[i])
          }else{
            initialFields.push(this.initCol(i));
          }
          
        }
        this.localList = initialFields;
      },
      immediate: true,
      deep: true
    },
    colNum: {
      handler(v) {
        if (v !== this.fields.length) {
          const initialFields = [];
          for (let i = 0; i < this.colNum; i++) {
            if(this.fields[i]){
              initialFields.push(this.value[i])
            }else{
              initialFields.push(this.initCol(i));
            }
            
          }
          this.localList = initialFields;
          
        }
      },
      immediate: true,
      deep: true
    }
  },
  initModel(obj) { // 保留 initModel 方法
    let arr = [];
    let cols = 2;
    obj.config = {
      cols,
      gutter: 10
    };
    for (let i = 0; i < cols; i++) {
      arr.push({
        id: `${obj.id}-col${i}`,
        key: `${obj.id}-col${i}`,
        order: i,
        name: `组${i + 1}`,
        control: 'UgoCol',
        fields: []
      });
    }
    obj.fields = arr;
    return obj;
  }
};
</script>

<style lang="less" scoped>

</style>