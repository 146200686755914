<template>
  <Container>
    <div class="flex-wrap flex-between">
    <Toolbar 
      :items="tools"
      v-model="filter"
      :counts="counts"
      @on-event="handleEvent"
    >
      
    </Toolbar>
    </div>
    <div style="height:calc(100% - 40px);padding:0 5px;">
        <BaseTable
            ref="table"
            :columns="columns"
            :data="items"
            :page="page"
            :page-size="pagesize"
            @event="handleEvent"
            :loading="loading"
            :count="total"
            border
        />
    </div>
  </Container>
</template>

<script>
import Container from '@/components/app/container'
import Toolbar from '@/components/app/toolbar'
export default {
    components:{Container,Toolbar},
    routerOption:{
      as_default:true
    },
    data(){
        return {
            items:[],
            loading:false,
            filter:{
        status:"草稿"
      }, counts:{
        "准备中":0,
        "已开工":0,
        "已竣工":0,
        "结算完成":0
      },order:{
        key:'created_by',
        order:'desc'
      }, page:1,
      pagesize:20,
      total:10,
        }
    },
    computed:{
        tools(){
      return [{
        key:'search',
        placeholder:"输入项目名称/招标单位/搜索",
        type:"search",
        width:300
      },{
        key:"status",
        type:"tabs",
        subs:[{
          key:"草稿",
          icon:"file",
          countable:true,
          name:"草稿"
        },{
          key:"审核中",
          icon:"date",
          countable:true,
          name:"审核中"
        },{
          key:"审核通过",
          icon:"pm2",
          countable:true,
          name:"审核通过"
        },{
          key:"未通过",
          icon:"xiangmu2",
          countable:true,
          name:"未通过"
        }]
      },{
        key:'refresh',
        icon:'md-refresh',
        type:"icon-button"
      },{
        key:'clearFilter',
        icon:'md-filter',
        type:"icon-button"
      },]
    },
    columns(){
            return [{
                key:'id',
                title:'序号',
                align:"center",
                type:'index',
                fixed:'left',
                width:60
            },{
                key:'name',
                title:'项目名称',
                type:'name',
                fixed:'left',
                minWidth:300,
                sortable:'custom'
            },{
              key:'1',
              title:'补充协议申报',
              align:'center',
              width:180,
            },{
              key:'1',
              title:'补充协议商签',
              align:'center',
              width:180,
            },{
              key:'1',
              title:'结算申报',
              align:'center',
              width:180,
            },{
              key:'1',
              title:'结算送审价',
              align:'center',
              width:180,
            },{
              key:'contract_amount',
              title:"结算审定金额(万元)",
              width:240,
              align:"center",
              children:[{
                key:'total',
                title:"总金额"
              },{
                key:'total',
                title:"公司"
              },{
                key:'total',
                title:"合作方"
              },]
            },{
              key:'1',
              title:'争议金额',
              align:'center',
              width:180,
            },{
              key:'1',
              title:'情况说明',
              align:'center',
              width:180,
            },{
              key:'1',
              title:'事态客观分析',
              align:'center',
              width:180,
            },{
              key:'1',
              title:'实施计划',
              align:'center',
              width:180,
            },{
              key:'1',
              title:'注意事项',
              align:'center',
              width:180,
            },{
              key:'1',
              title:'结算管理负责人',
              align:'center',
              width:180,
            },{
              key:'1',
              title:'备注',
              align:'center',
              width:180,
            },]
        }
    },
    methods:{
        getData(){
            this.loading = true
            this.$api.get('/business/contracts').then(res=>{
                this.items = res.data.data
            }).finally(()=>{
                this.loading = false
            })
        },
        handleEvent({type,key,value,data}){
      const eventMap = {
        add:this.showCreateForm,
        search:this.getData,
        create:this.createItem,
        refresh:()=>{
          this.getStatusCounts()
          this.getData()

        },
        update:this.updateItem,
        'tab-change':this.getData,
        'page-change':()=>{
          this.page = data
          this.getData()
        },
        'page-size-change':()=>{
           this.pagesize = data
          this.getData()
        },
        'sort':()=>{
           if(!data || !data.key)
            this.order = {
              key:'created_at',
              order:'desc'
            }
          else
            this.order = data
          this.getData()
        },
        'clearFilter':()=>{
          this.filter={status:this.filter.status}
          this.getData()
        }
      }


      let eventHandler = eventMap[type]
      if(eventHandler){
        eventHandler(value)
      }
    },
    }
}
</script>

<style>

</style>