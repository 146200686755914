<template>
  <div class="flex-col align-center progress-board" 
    :class="{
      'progress-board-hoverable':hoverable
    }"
    :style="{
      alignItems:align
    }"
    >
    <div class="l-name" :style="`${labelStyle}`">{{name}} </div>
    <div class="l-value" :style="`${valueStyle};margin:7px 0;`">
      <Progress style="width:120px;font-size:14px;"  :percent="value<0?0:value"  status="active" />
    </div>
    
  </div>
</template>

<script>
  export default {
    props:{
      name:{
        type:String,
      },
      value:{
        type:Number
      },
      unit:{
        type:String
      },
      hoverable:{
        type:Boolean
      },
      labelStyle:{
        type:String
      },
      valueStyle:{
        type:String
      },
      align:{
        type:String,
        default:"center"
      }
    }
  }
</script>

<style lang="less">
.progress-board{
  flex-direction: column;
  align-items: center;
  .l-name{
    font-size:14px;
    line-height:15px;
    color:var(--subtext3);
  }

  .l-value{
    font-size:14px;
    color:var(--primary);
    font-family: PingFang-SC;
    font-weight: bold;
  }

}

.progress-board-hoverable:hover{
  color:var(--primary);
  cursor: pointer;
  .l-name{
    color:var(--primary);
  }
}

.progress-board-hoverable:active{
  position: relative;
  right:-1px;
  bottom:-1px;
}
</style>