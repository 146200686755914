<style lang="less">
.v-note-wrapper{
	min-height: 100px;
}
.v-show-content {
	background: #fff !important;
	padding:5px !important;
	.hljs{
		background:none;
	}
}
</style>
<template>
	<mavon-editor
		:value="value"
		:placeholder="placeholder"
		ref="md"
		@imgAdd="$imgAdd"
		@change="change"
		@input="change"
		:defaultOpen="preview ? 'preview' : 'edit'"
		:editable="!preview"
		:toolbarsFlag="!preview"
		:subfield="!preview"
		style="height: 100%;border:1px solid var(--border)"
		:ishljs="true"
		language="json"
		codeStyle="github"
		:tabSize="2"
	/>
</template>

<script>
import { mavonEditor } from "mavon-editor";
import "mavon-editor/dist/css/index.css";

export default {
	name: "markdown",
	data: function () {
		return {
			content: "",
			html: "",
			configs: {},
		};
	},
	props: {
		value: {
			type: String,
			default: "",
		},
		option: {
			type: Object,
			default:()=>({}),
		},
		preview: {
			type: Boolean,
		},
		placeholder:{
			type:String
		}
	},
	components: {
		mavonEditor,
	},
	methods: {
		// 将图片上传到服务器，返回地址替换到md中
		$imgAdd(pos, $file) {
			this.$cos.upload([$file],{onSuccess:(files)=>{
				if(files && files.length > 0)
					this.$refs.md.$img2Url(pos, files[0].url)
			}},{coskey:this.$store.getters['session/session'].coskey})
		},
		change(value, render) {
			// render 为 markdown 解析后的结果
			this.html = render;
			this.$emit("change", value);
			this.$emit("input",value)
		},

	},
};
</script>
<style lang="less" scoped>
.editor-btn {
	margin-top: 20px;
}

body {
	margin: 0 auto;
	font-family: "Microsoft YaHei", arial, sans-serif;
	color: #444444;
	line-height: 1;
	padding: 30px;
}
@media screen and (min-width: 768px) {
	body {
		width: 748px;
		margin: 10px auto;
	}
}
h1,
h2,
h3,
h4 {
	color: #111111;
	font-weight: 400;
	margin-top: 1em;
}

h1,
h2,
h3,
h4,
h5 {
	font-family: "微软雅黑", Georgia, Palatino, serif;
}
h1,
h2,
h3,
h4,
h5,
p,
dl {
	margin-bottom: 16px;
	padding: 0;
}
h1 {
	font-size: 48px;
	line-height: 54px;
}
h2 {
	font-size: 36px;
	line-height: 42px;
}
h1,
h2 {
	border-bottom: 1px solid #efeaea;
	padding-bottom: 10px;
}
h3 {
	font-size: 24px;
	line-height: 30px;
}
h4 {
	font-size: 21px;
	line-height: 26px;
}
h5 {
	font-size: 18px;
	list-style: 23px;
}
a {
	color: #0099ff;
	margin: 0;
	padding: 0;
	vertical-align: baseline;
}
a:hover {
	text-decoration: none;
	color: #ff6600;
}
a:visited {
	/*color: purple;*/
}
ul,
ol {
	padding: 0;
	padding-left: 24px;
	margin: 0;
}
li {
	line-height: 24px;
}
p,
ul,
ol {
	font-size: 16px;
	line-height: 24px;
}

ol ol,
ul ol {
	list-style-type: lower-roman;
}

/*pre {
    padding: 0px 24px;
    max-width: 800px;
    white-space: pre-wrap;
}
code {
    font-family: Consolas, Monaco, Andale Mono, monospace;
    line-height: 1.5;
    font-size: 13px;
}*/

code,
pre {
	border-radius: 3px;
	background-color: #f7f7f7;
	color: inherit;
}

code {
	font-family: Consolas, Monaco, Andale Mono, monospace;
	margin: 0 2px;
}

pre {
	line-height: 1.7em;
	overflow: auto;
	padding: 6px 10px;
	border-left: 5px solid #6ce26c;
}

pre > code {
	border: 0;
	display: inline;
	max-width: initial;
	padding: 0;
	margin: 0;
	overflow: initial;
	line-height: inherit;
	font-size: 0.85em;
	white-space: pre;
	background: 0 0;
}

code {
	color: #666555;
}

/** markdown preview plus 对于代码块的处理有些问题, 所以使用统一的颜色 */
/*code .keyword {
  color: #8959a8;
}

code .number {
  color: #f5871f;
}

code .comment {
  color: #998
}*/

aside {
	display: block;
	float: right;
	width: 390px;
}
blockquote {
	border-left: 0.5em solid #eee;
	padding: 0 0 0 2em;
	margin-left: 0;
}
blockquote cite {
	font-size: 14px;
	line-height: 20px;
	color: #bfbfbf;
}
blockquote cite:before {
	content: "\2014 \00A0";
}

blockquote p {
	color: #666;
}
hr {
	text-align: left;
	color: #999;
	height: 2px;
	padding: 0;
	margin: 16px 0;
	background-color: #e7e7e7;
	border: 0 none;
}

dl {
	padding: 0;
}

dl dt {
	padding: 10px 0;
	margin-top: 16px;
	font-size: 1em;
	font-style: italic;
	font-weight: bold;
}

dl dd {
	padding: 0 16px;
	margin-bottom: 16px;
}

dd {
	margin-left: 0;
}

table {
	*border-collapse: collapse; /* IE7 and lower */
	border-spacing: 0;
	width: 100%;
}
table {
	border: solid #ccc 1px;
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	border-radius: 6px;
	/*-webkit-box-shadow: 0 1px 1px #ccc;
    -moz-box-shadow: 0 1px 1px #ccc;
    box-shadow: 0 1px 1px #ccc;   */
}
table tr:hover {
	background: #fbf8e9;
	-o-transition: all 0.1s ease-in-out;
	-webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-ms-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
}
table td,
.table th {
	border-left: 1px solid #ccc;
	border-top: 1px solid #ccc;
	padding: 10px;
	text-align: left;
}

table th {
	background-color: #dce9f9;
	background-image: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(#ebf3fc),
		to(#dce9f9)
	);
	background-image: -webkit-linear-gradient(to top, #ebf3fc, #dce9f9);
	background-image: -moz-linear-gradient(to top, #ebf3fc, #dce9f9);
	background-image: -ms-linear-gradient(to top, #ebf3fc, #dce9f9);
	background-image: -o-linear-gradient(to top, #ebf3fc, #dce9f9);
	background-image: linear-gradient(to top, #ebf3fc, #dce9f9);
	/*-webkit-box-shadow: 0 1px 0 rgba(255,255,255,.8) inset;
    -moz-box-shadow:0 1px 0 rgba(255,255,255,.8) inset;
    box-shadow: 0 1px 0 rgba(255,255,255,.8) inset;*/
	border-top: none;
	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
	padding: 5px;
}

table td:first-child,
table th:first-child {
	border-left: none;
}

table th:first-child {
	-moz-border-radius: 6px 0 0 0;
	-webkit-border-radius: 6px 0 0 0;
	border-radius: 6px 0 0 0;
}
table th:last-child {
	-moz-border-radius: 0 6px 0 0;
	-webkit-border-radius: 0 6px 0 0;
	border-radius: 0 6px 0 0;
}
table th:only-child {
	-moz-border-radius: 6px 6px 0 0;
	-webkit-border-radius: 6px 6px 0 0;
	border-radius: 6px 6px 0 0;
}
table tr:last-child td:first-child {
	-moz-border-radius: 0 0 0 6px;
	-webkit-border-radius: 0 0 0 6px;
	border-radius: 0 0 0 6px;
}
table tr:last-child td:last-child {
	-moz-border-radius: 0 0 6px 0;
	-webkit-border-radius: 0 0 6px 0;
	border-radius: 0 0 6px 0;
}

.markdown-body {
	box-shadow: none !important;
}
</style>