<template>
  <iframe ref="iframe" class="container" style="border:none">

  </iframe>
</template>

<script>
  import {mapGetters} from 'vuex'
  export default {
    computed:{
      ...mapGetters("session",["session"])
    },
    mounted(){
      this.$refs.iframe.src = `http://zzlatm.gicp.net:10000/acl/crossLogin.html?uid=${this.session.zzl_id}&phone=${this.session.phone}&href=/bidding/index.html`
    }
  }
</script>

<style lang="scss" scoped>

</style>