<template>
  <div class="container" style="padding:10px;">
    <div class="flex-wrap flex-between">
    <div class="flex-wrap">
      <Button type="primary" icon="md-add" @click="handleCreate">新建项目</Button>
      <BaseTableFilter style="margin-left:10px" @on-search="getData" />
    </div>
    <div>  
      <Button style="margin-left:10px" icon="md-refresh" @click=getData() :loading="loading">刷新</Button>
    </div>
    </div>
    <div style="height:calc(100% - 40px);position:relative;margin-top:10px;">
      <BaseTable :columns="columns" 
      :loading="loading" 
      :data="items" 
      :page-size="pageSize" :count="count"
      @event="handleTableEvent" />
    </div>

    <Modal v-model="showEditor" draggable :class-name="className" ref="modal" :title="model.id?'编辑项目':'新建项目'" :width="900" footer-hide>
      <BaseLoading v-if="loadingProject"></BaseLoading>
      <FormCreateContract
        v-else 
        ref="form"
        v-model="model"
        :submitting="submitting" 
        @on-submit="handleSubmit" 
        @on-error="handleModelError" 
        @on-cancel="showEditor=false" />
    </Modal>
  </div>
</template>

<script>

import moment from 'moment'
import UTIL from '@/utils'
import FormCreateContract from '@/components/forms/CreateContract'
import {cloneDeep} from 'lodash'
import CMR from '../render'
  export default {
    components:{FormCreateContract},
    data(){
      return {
        items:[],
        count:0,
        def_columns:[],
        loading:false,
        loadingProject:false,
        deleting:false,
        submitting:false,
        showEditor:false,
        queryString:"",
        page:1,
        pageSize:50,
        className:"",
        business_types:['全过程咨询','项目管理','房建监理','市政监理','造价咨询','BIM咨询','招标代理','对外合作','其他'],
        business_type_colors:['orange','#369','green','darkred','purple','#3af','grown','pink','#333'],
        contract_types:['独立合同','主合同','分解合同','附加合同'],
        contract_type_colors:['#369','green','orange'],
        model:{
          contracts:[{}]
        }
      }
    },
    mounted(){
      setTimeout(()=>{
        this.getColumns()
        this.getData()
      },500)
    },
    methods:{
      getColumns(){
         this.$api.get("CostContract/contracts?q=columns").then(res=>{
          this.def_columns = res.data.data
        }).finally(()=>{
          this.loading = false
        })
      },
      getCount(queryString = ""){
        this.$api.get("CostContract/contracts?q=count"+queryString).then(res=>{
          this.count = res.data.data
        })
      },
      getData(queryStirng = ""){
        this.queryString = queryStirng
        this.loading =  true
        this.getCount(queryStirng)
        this.$api.get("CostContract/contracts?"+queryStirng).then(res=>{
          this.items = res.data.data
        }).finally(()=>{
          this.loading = false
        })
      },
      handleTableEvent(e){
        if(e.type == 'page-change'){
          this.getData()
        }else if(e.type == 'page-size-change'){
          this.page_size = e.data
          this.getData()
        }
      },
      handleOpen(e){
        let project = this.items.find(v=>v.id == e)
        this.$store.commit("session/push_project_cm",{
          id:e,
          name:project.name
        })
        this.RouteTo('/core/projects/'+e+'/cm')
      },
      handleCreate(){
        this.model = {
          contracts:[{}],
          withFlow:true
        }
        this.showEditor = true
      },
      handleEdit(project_id){
        this.loadingProject = true
        this.$api.get(`CostContract/projects/${project_id}?full=2`).then(res=>{
          this.model = res.data.data
          this.showEditor = true
        }).finally(()=>{
          setTimeout(()=>{
            
          this.loadingProject = false
          },500)
        })
      },
      handleDelete(id){
        this.Confirm("将删除此项目相关的所有信息，是否继续?",()=>{
          
          this.deleting = id
          this.$api.delete(`CostContract/contracts/${id}`).then(()=>{
            let index =this.items.findIndex(v=>v.id == id)
            this.items.splice(index,1)
            this.$Notice.success({title:"操作成功",content:"项目已删除"})
          }).finally(()=>{
            this.deleting = false
          })
        })
      },
      handleSubmit(model){
        
        if(this.model.id){
          this.submitting = true
          this.$api.patch("CostContracts/contracts/"+id,model).then(res=>{
             let udpateInfo = res.data.data
              this.items.splice(0,0,Object.assign({},model,udpateInfo))
              this.showEditor = false
              this.$Notice.success({title:"操作成功",content:"项目已更新"})
              this.$refs.form.reset()
              this.step = 0
          }).catch(e=>{
            this.Error(e)
          }).finally(()=>{
            this.submitting = false
          })
        }else{
          this.submitting = true
          this.$api.post("CostContract/contracts",model).then(res=>{
            let udpateInfo = res.data.data
            this.items.splice(0,0,Object.assign({},model,udpateInfo))
            this.showEditor = false
            this.$Notice.success({title:"操作成功",content:"项目已创建"})
            this.$refs.form.reset()
            this.step = 0
          }).catch(e=>{
            this.Error(e)
          }).finally(()=>{
            this.submitting = false
          })
        }
       
      },
      handleModelError(){
        this.className = 'animate__animated animate__headShake'
        setTimeout(()=>{
          
        this.className = ''
        },500)
      }
    },
    computed:{
      columns(){
        const fields = ['code','name','type2','state','flow_id']
        let columns = [...this.def_columns.filter(v=>fields.includes(v.key))]
        if(columns.length > 0){
        
        columns.push({
					key: "created_at",
					title: "创建时间",
					group: "管理",
					width: 80,
					type: "time",
				})
        columns.push({
					key: "created_by",
					title: "操作人员",
					group: "管理",
					width: 120,
					type: "user",
					option: {
						getters: "session/users",
					},
        })
        columns.push({
          type: 'number',
          title: "操作",
          key: "partA",
          render:(h,{row})=>{
            let buttons = [
              h('Button',{props:{icon:"md-create",size:"small"},on:{click:()=>{
                this.handleEdit(row.id)
              }}},'修改'),
              h('Button',{props:{icon:"md-trash",size:"small",loading:this.deleting == row.id},on:{click:()=>{
                this.handleDelete(row.id)
              }}},'删除')
            ]
            return h('div',{class:"flex-wrap split5 flex-center"},buttons)
          }
        })
        }
        return columns
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>