<template>
    <Dropdown @on-click="RouteTo('/core')"  placement="bottom-start" transfer trigger="click" style="margin-bottom:2px">
      <a><BaseIcon icon="md-menu" size="22" style="margin-right:10px" /></a>
      <DropdownMenu slot="list">
        <DropdownItem name="goback"><BaseIcon icon="md-arrow-back" style="margin-right:10px" /> 返回工作台</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  </template>
  
  <script>
    export default {
      
    }
  </script>
  
  <style lang="scss" scoped>
  
  </style>