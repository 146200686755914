<template>
  <div style="padding:0 20px;position:relative;height:100%;">
    <div style="color:var(--border)">个人动态</div>
    <div  style="height:calc(100% - 40px);border-radius:5px;margin-top:10px;padding:10px;">
      <BaseEmpty v-if="items.length == 0" />
      <template v-for="t in items">
        <div :key="t.id" class="pm-log flex-wrap">
          <div class="l-time flex-wrap"><BaseIcon icon="clock" style="margin-right:5px" /> {{t.formatted_time}}</div>
          <div class="l-content"><BaseTpl :data="t.content" /></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        items:[{
          formatted_time:'3分钟前',
          content:"我上传了${link:2020年终总结.docx,/core/files/123124.docx,true}到${link:个人文件,/core/self/files}"
        },]
      }
    }
  }
</script>

<style lang="less">
.pm-log{
  padding:5px;
  width:100%;
  border-bottom:1px dashed #ccc;
  .l-time{
    width:100px;
    color:var(--success);
  }
}


.pm-log:last-child{
  border:none;
}
</style>