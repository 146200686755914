import moment from 'moment'
import UTIL from './index.js'
export var fix = (a, n) => UTIL.toFixed(a, n)
export var percent = (a, b) => fix(a * 100 / b)
// -- [a,b] -->
export var time_duration = (a, b, t = 'days') => a && b?fix(moment.duration(moment(b) - moment(a)).as(t)):0
// --[[a,b],[c,d]]-->
export var time_int_duration = (a, b, a2, b2, t = 'days') => {
  let [x, y] = UTIL.timeIntersection(a, b, a2, b2)
  if (!x || !y) return 0
  else
    return fix(time_duration(x, y, t))
}

export var time_in_duration3 = (a,b,a2,b2,a3,c3,t='days')=>{
  let [p, q] = UTIL.timeIntersection(a, b, a2, b2)
  let [x, y] = UTIL.timeIntersection(p,q,a3,b3)
  if (!x || !y) return 0
  else
    return fix(time_duration(x, y, t))
}

export var time_unit = (v,t2='months',t1='days')=>{
  return moment.duration(v,t1).as(t2)
}