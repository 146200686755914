<template>
  <span>
  <Button slot="button" size="small" icon="md-folder" @click="openFileSelector">选择已有附件</Button>
  <Modal v-model="showFileSelector" title="选择已有文件" footer-hide :width="600" transfer>
      <div style="padding:10px;">
        <Input v-model="search" @on-enter="getFiles" @on-search="getFiles" search placeholder="输入文件名查找" />
        <Row :gutter="10" style="border-bottom:1px solid var(--border);margin-top:10px;background:var(--bg3);">
            <Col align="center" span="2">选择</Col>
             <Col align="center" span="4">
            文件类型
            </Col>
            <Col align="center" span="14">
            文件名
            </Col>
           
            <Col align="center" span="4">
            查看
            </Col>
          </Row>
        <div style="height:500px;overflow-y:scroll;background:var(--bg3);border-bottom:1px solid var(--border)">
          <Spin fix v-if="loadingFiles">
            <BaseLoading />
          </Spin>
          <template v-for="f in files">
            <div style="color:#aaa;padding-left:115px;font-size:10px;padding-top:5px;" :key="f.id"><BaseIcon icon="md-link" style="margin-right:3px;" /> {{ f.product && ('产品 / '+f.product.name) || f.brand && ('品牌 / '+f.brand.name) || f.producer && ('公司 / '+f.producer.name) }}</div>
          <Row  style="border-bottom:1px dashed var(--border);padding:5px 0;background:var(--bg3);"  :key="f.id">
            <Col align="center" span="2">
              <Checkbox v-model="selected_files[f.id]" />
            </Col>
            <Col align="center" span="4">
            {{f.type || '-'}}
            </Col>
            <Col align="left" span="14">
            <a @click="select(f)">{{f.name}}</a>
            </Col>
            
            <Col align="center" span="4">
             <a :href="f.url" target="_blank">打开</a>
            </Col>
          </Row>
          
        </template>
        </div>
        <div class="flex-wrap flex-right" style="margin-top:10px">
         <Button type="primary" @click="submitFiles">提交</Button>
         <Button style="margin-left:10px" @click="cancelSelectFiles">取消</Button>
        </div>
      </div>
    </Modal>
  </span>
</template>

<script>
export default {
  data(){
    return {
      search:"",
      loadingFiles:false,
      showFileSelector:false,
      files:[],
      selected_files:{},
    }
  },
  mounted(){
    
  },
  methods:{
    select(f){
      this.$set(this.selected_files,f.id,this.selected_files[f.id]?false:true)
    },
    openFileSelector(){
      this.showFileSelector = true
      this.getFiles()
    },
    getFiles(){
      let url = 'mat/files?link=0&related=product,producer,brand&page=1&pagesize=20'
      if(this.search && this.search.trim()){
        url += '&search='+this.search.trim()
      }
      this.loadingFiles =true
      this.$api.get(url).then(res=>{
        this.files = res.data.data
        this.selected_files = {}
      }).finally(()=>{
        this.loadingFiles = false
      })
    },
    cancelSelectFiles(){
      this.files = []
      this.selected_files = {}
      this.showFileSelector = false
    },
    submitFiles(){
      let files = this.files.filter(v=>this.selected_files[v.id])
     
      files.forEach(f=>{
        delete f.id
        f.link = true
      })
      this.selected_files = {}
      this.files = []
      this.showFileSelector = false
      this.$emit('change',files)
    }
  }
}
</script>

<style>

</style>