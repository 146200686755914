const out = {}

const business_types = [
  '全过程咨询',
  '项目管理', 
  '市政监理',
  '房建监理',
  '造价咨询',
  '装修工程',  
  'BIM咨询', 
  '对外合作', 
  '招标代理',  
  '其他',
]
const business_type_colors = [	
  "#fa3",
  "#369",
  "#383",
  "#533",
  "#388",
  "#993",
  "#3af",
  "#9af",
  "#9a3",
  "#333",
  '#346']
const status =  ['准备中', '进行中', '已竣工', '已结束', '已中止']
const status_colors = ['var(--subtext3)', 'var(--primary)', 'var(--success)', 'var(--error)']
const states =  ['待处理', '处理中', '已办结', '已关闭']

out.renderCode = (h,{row})=>{
  return h("div",{
    class:"flex-wrap flex-center",
    
  }, [h('div', {
    style: "width:60px;background:var(--bg2);border:1px solid var(--border);border-radius:4px;"
  }, row.code || '-')])
}

out.renderName = (handleOpen,handleEvent)=> (h, param) => {
  let icon = h("BaseIcon", {
    props: {
      icon: "Building-",
      color: "var(--subtext3)",
      size: 20,
    },
    style: "margin-right:5px;padding:5px;background:var(--border);",
  });
  let pic = h("BaseIcon", {
    props: { icon: "images", color: "var(--text3)", size: 12 },
    style: "margin-left:3px;padding:2px;background:var(--bg2);",
  });
  let shortname = param.row.shortname
  let name = h(
    "a",
    {
      style: "font-size:14px;text-align:left;margin-left:5px;line-height:20px;",
      on: {
        click: (e) => {
          if(handleOpen)
            handleOpen(param.row);
        },
      },
    },
    [shortname || param.row.name]
  );
  let shortIcon = h("span",{style:"font-size:10px;padding:1px 3px;background:var(--bg2);color:var(--text3);margin-left:3px;"},'简')

  let editable = h("BaseIcon",{
    props:{icon:"md-create",size:10},
    class:'small-icon-button',
    style:"margin-left:3px",
    on:{
    click:()=>{
      if(handleEvent)
        handleEvent({type:'edit-name',data:param.row})
    }
  }})

  let verified = h("BaseIcon",{props:{icon:"nbgz",size:8,color:"#fff"},style:"background:var(--primary);margin-left:5px;padding:3px;border-radius:3px;curosr:pointer;"})

  let items = [];
  // items.push(icon);
  items.push(name);
  if(param.row.verified_at)
    items.push(verified)
  if(shortname)
    items.push(shortIcon)
  if(param.row.images)
    items.push(pic)
    
  if(handleEvent)
    items.push(editable)

  return h(
    "div",
    { class: "flex-wrap", style: "padding:5px;" },
    items
  );
}


out.renderBusinessType = (handleChange)=>
  (h, { row }) => {
    let iconArrow = h("BaseIcon",{props:{icon:"ios-arrow-down"},style:"margin-left:3px;margin-right:5px;"})
    let domState = h(
      "div",
      {
        style: {
          width: "86px",
          borderRadius: "5px",
          textAlign: "right",
          
          background:
            business_type_colors[
              business_types.findIndex((v) => v == row.type)
            ],
          color: "#fff",
        },
      },
      [row.type, handleChange?iconArrow:'']
    )
    let domContent =  h(
      "div",
      { class: "flex-wrap flex-right flex-line hover-lighter", style: { height: "40px;padding:0 3px;" } },
      [
        domState
      ]
    )
    if(!handleChange){
      return domState
    }
    
    let domListItems = business_types.map((v, i) =>
      h(
        "DropdownItem",
        { props: { name: v, selected: row.status == v } },
        v
      )
    );
    let domList = h("DropdownMenu", { slot: "list" }, domListItems);
    let domDropDown = h(
      "Dropdown",
      {
        props: { trigger: "click", size: "small" },
        on: {
          "on-click": (type) => {
            handleChange(type)
          },
        },
      },
      [domContent, domList]
    );
    return domDropDown;
  
  }


export default out