<template>
  <div>
    <Dropdown trigger="custom" :visible="showDropdown" @on-select="handleSelect" placement="bottom-start" @on-clickoutside="handleClickOut" transfer>
      <div class="flex-wrap flex-center" style="width:35px;height:35px;border:1px solid var(--border);border-radius:2px;" @click="showDropdown=true;" >
        <div style="width:20px;height:20px;" :style="`background:${value || 'var(--border)'};border-radius:3px;`" ></div></div>
      <DropdownMenu slot="list" style="width:300px;">
        <div  ref="panel" class="flex-wrap flex-center" style="flex-wrap:wrap;align-content: flex-start;">
          <template v-for="c in colors">
            <div class="icon-wrap flex-wrap flex-center" style="width:25px;height:25px;background:var(--border);margin:2px;" :style="`background:${c || 'var(--border)'}`" @click="handleSelect(c)">
              
            </div>
          </template>
        </div>
      </DropdownMenu>
    </Dropdown>
  </div>
  </template>
  <script>
  export default {
    data(){
      return {
        showDropdown:false,
        colors:["var(--primary)","var(--bg1)","var(--success)","var(--warning)",'var(--error)',"var(--active)"]
  
      }
    },
    props:{
      value:{
        type:String
      }
    },
    methods:{
      handleSelect(e){
        this.$emit('input',e)
        this.showDropdown = false
      },
      handleClickOut(e){
        if(!this.$refs.panel.contains(e.target)){
          this.showDropdown = false
          if(Array.isArray(this.value)){
            this.selected = this.value
          }else if(this.value){
            this.selected = this.multiple?[...this.value]:(this.value?[this.value]:[])
          }else{
            this.selected = []
          }
        }
      },
    }
  }
  </script>
  <style lang="less">
  
  </style>