<template>
  <div class="flex-wrap flex-line align-stretch" style="padding:10px">
    <div style="width:300px;flex-shrink:0;">
      <UserProfile />

       <UserCompany />
    </div>
    <div style="flex-shrink:1;flex-grow:1;margin-left:10px;position:relative">
     <PanelGroups />
    </div>
    <div style="flex-shrink:0;width:400px;margin-left:10px">
      <MileStonePanel />
    </div>

  </div>
</template>

<script>
  import UserProfile from '@/components/self/profile'
  import UserCompany from '@/components/self/company'
  import MileStonePanel from '@/components/milestone/panel'
  import {mapGetters} from 'vuex'
  export default {
    routerOption:{
      as_default:true
    },
    components:{UserProfile,UserCompany,MileStonePanel},
    computed:{
      ...mapGetters('session',['session']),
      panels(){
        return [{
          x:0,y:0,w:4,h:11,control:"PanelUserInfo",data:"个人信息"
        },{
          x:4,y:0,w:19,h:1.5,control:"PanelCounts",data:[{
            name:"当前",
            counts:[{
              name:'在建项目',
              count:3,
            
              path:"/core/self/projects?state=1"
            },{
              name:'待办工作',
              count:15,
               path:"/core/self/flows?q=todo"
            },{
              name:'未读消息',
              count:17,
              path:"/core/self/messages?q=todo"
            }]
          },{
            name:"本月完成",
            counts:[{
              name:'工作流',
              count:32
            },{
              name:'部门工作',
              count:15
            },{
              name:'个人工作',
              count:17
            }]
          },{
            name:"年度完成",
            counts:[{
              name:'工作流',
              count:32
            },{
              name:'部门工作',
              count:15
            },{
              name:'个人工作',
              count:17
            }]
          },{
            name:"历史完成",
            counts:[{
              name:'工作流',
              count:32
            },{
              name:'部门工作',
              count:15
            },{
              name:'个人工作',
              count:17
            }]
          }]
        },{
          x:4,y:1.5,w:10,h:4,control:"PanelProjects",data:{
            name:"当前项目",
            items:[{
              id:1,
              name:'中河中学',
                state:1,
              position:'项目经理'
            },{
              id:2,
              name:'新星商业1#地块新星商业1#地块新星商业1#地块',
              state:1,
              position:'项目经理'
            },{
              id:3,
              name:'轨道交通4号线',
              state:1,
              position:'项目经理'
            }]
          }
        },{
          x:14,y:1.5,w:9,h:4,control:"PanelProjects",data:{
            name:"历史项目",
            items:[{
              id:1,
              name:'中河中学',
              state:3,
              position:'项目经理'
            },{
              id:2,
              name:'钟公庙',
              state:3,
              position:'项目经理'
            },{
              id:3,
              name:'轨道交通4号线',
              state:3,
              position:'项目经理'
            },{
              id:4,
              name:'钟公庙',
              state:3,
              position:'项目经理'
            },{
              id:5,
              name:'轨道交通4号线',
              state:3,
              position:'项目经理'
            }]
          }
        },{
          x:4,y:5.5,w:10,h:5,control:"PanelComments",data:"活动记录"
        },{
          x:14,y:5.5,w:9,h:5,control:"PanelActivityLog",data:"讨论"
        }]
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>