
<style lang="less" >
.pd-embed-control{
  .ivu-input-wrapper{
    height:100% !important;
    display: block !important;
    position: relative;
    border-radius:0;
  }
  .ivu-input{
    background:none;
    width:100%;
    border:none;
    height:100% !important;
    margin:0;
    background:inherit;
    border-radius: 0;
    font-size:12px;
    
  }


.unit{
  display: flex;
 width:30px;
  text-align: center;
  display: flex;;
  align-items: cener;
  justify-content: center;
  color:#888;
  cursor: position;
}

  .ivu-input-group-append{
    background:#33333333;
    font-size:12px;
    height:100%;
  }

  .ivu-input-disabled{
    background:#ddd !important;
    
  }
}

.pd-embed-control-disabled{
  background:var(--border) !important;
}
</style>
<template>
  <div class="pd-embed-control flex-wrap"
  :class="{
    'pd-embed-control-disabled':!editable || disabled
  }"
  >
  <template v-if="editable">
   
    <Input 
      :value="value"
      @input="$emit('input',$event)"
      style="height:100%;"
      :disabled="!editable"
      :placeholder="option && option.placeholder?option.placeholder: '请输入内容'"
      :number="(option&& option.number)?true:false"  
    >
     
    </Input>
    
  </template>
  <template v-else>
    <div class="flex-wrap pd-embed-value">{{value}}</div>
  </template>
    <div class="unit" v-if="option && option.unit">
      {{option.unit}}
    </div>
    <div class="unit" v-if="option && option.copyable" @click="copy">
      <BaseIcon icon="md-copy" />
    </div>
  </div>
</template>

<script>
  export default {
    props:['value','editable','option','disabled'],
    methods:{
      copy(){
        navigator.clipboard.writeText(this.value).then(()=>{
          this.Success('已复制到剪切板')
        })
        
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>