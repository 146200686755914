<template>
  <div class="container" style="background:var(--bg1);border-left:1px solid var(--border);">
    <div class="flex-wrap flex-center" style="height:60px" v-if="false">
      <div class="flex-wrap split10">
        <a>最近上传</a>
        <a>2013 - 2023 (234)</a>
        <a>2003 - 2013 (15)</a>
        <a>1993 - 2003 (3)</a>
        <a>上传照片</a>
        <a>我的照片</a>
      </div>
    </div>
    <div style="position:relative;height:calc(100%);overflow-y:auto;" ref="container">
      
      <waterfall  :line-gap="300" :watch="items" align="center" :auto-resize="true">
        <!-- each component is wrapped by a waterfall slot -->
        <waterfall-slot
          v-for="(item, index) in items"
          :width="item.width || 400"
          :height="item.height || 200"
          :order="index"
          :key="'item_'+item.id"
        >
          <div class="container photo" @click="current=item;showPhoto=true" style="background:var(--border);border:5px solid var(--bg1)">
            <img ref='img' :src="item.url" style="width:100%;height:100%;"  />
            <div style="position:absolute;left:10px;bottom:10px;"><BaseNameBoard :uid="item.created_by" styles="color:var(--hover-text);text-shadow:1px 1px 1px #000" /></div>
             <div style="position:absolute;right:10px;top:10px;font-size:10px;color:#fff;text-shadow:1px 1px 1px #000;max-width:80%;text-align:right;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;" v-if="item.name">{{item.name}} {{item.w}} {{item.height}}</div>
          </div>
        </waterfall-slot>
       
      </waterfall>
       <BaseLoading  v-if="loading" />
    </div>

    <Modal v-model="showPhoto" footer-hide width="1200">
      <img :src="current.url" style="width:100%" />
      <div style="position:absolute;left:40px;bottom:40px;color:var(--hover-text);text-shadow:1px 1px 1px #000" class="flex-wrap"><BaseNameBoard :uid="current.created_by" :size="30" styles="color:var(--hover-text);text-shadow:1px 1px 1px #000;margin-right:10px;" /> 投稿</div>
             <div style="position:absolute;max-width:50%;text-align:right;right:40px;top:40px;font-size:20px;color:#fff;text-shadow:1px 1px 1px #000;font-weight:bold;" v-if="current.name">{{current.name}}</div>
    </Modal>
  </div>
</template>

<script>
import Waterfall from 'vue-waterfall/lib/waterfall'
import WaterfallSlot from 'vue-waterfall/lib/waterfall-slot'
export default {
  data(){
    return {
      showPhoto:false,
      loading:true,
      current:{},
      nextToken:0,
      items:[]
    }
  },
  components: {
    Waterfall,
    WaterfallSlot
  },
  mounted(){
    this.getData()
    this.$refs.container.addEventListener('scroll',this.handleScroll)
  },
  beforeDestroy(){
    this.$refs.container.removeEventListener('scroll',this.handleScroll)
  },
  methods:{
    handleScroll(e){
      if(this.timer)
        clearTimeout(this.timer)
      this.timer = setTimeout(()=>{ 
        if(e.target.scrollTop +e.target.clientHeight> e.target.scrollHeight -200 && this.nextToken){
         
          this.getData(this.nextToken)
        }
      },300)
    },
    getData(next){
      this.loading = true
      if(next){
        this.nextToken = 0
      }
      this.$api.post("tools/migrate-photo-wall"+(next?`?next=${next}`:'')).then(res=>{
        let {items,nextToken} = res.data.data
        this.nextToken = nextToken
         items.forEach(v=>{
          let [_,w,h] = v.url.match(/_(\d+)_(\d+)\./)
          v.width = parseInt(w)
          v.height = parseInt(h)
         })
         this.items = this.items.concat(items)
        this.loading =false
      })
    },
    getData2(){
      this.$api.get("oa/photo_records").then(res=>{
        let {items} = res.data.data
        this.items = items
      })
    }
  }
}
</script>
<style lang="less">
.photo{
  filter:brightness(0.9);
}
.photo:hover{
  transition:all 0.5s;
  filter:brightness(1.3);
}
</style>