<template>
  <App >
    <AppBar style="flex-grow:1;position:fixed;top:0;right:0;width:50%;;z-index:101;background:none;" :closable="false">
        <div class="flex-wrap">
        <IconTabs :items="menus" :current="formView" @on-select="formView=$event" />
      </div>
        <div>
          <AppAvatar v-if="uid" />
        </div>
    </AppBar>
    <AppMask title="任吾行 — 表单设计器" iconRight="table" :loading="!is_ready" >
      <div v-if="uid" style="font-size:16px;font-weight:bold;color:var(--primary)">系统登录中,请稍后</div>
    </AppMask>
    <BaseLoadingModal v-if="is_ready" :value="loading" title="正在加载表单" />
        
    <AppClient  class="flex-wrap" style="align-items:stretch;top:0;">
      <keep-alive>
        <template v-if="formView=='editor'">
          <FormEditor 
            ref="editor"
            v-model="form"
            style="height:100%" 
            @on-event="handleEvent"
          />
        </template>
        <template v-else-if="formView=='test'">
          <FormTest :form="form"  style="height:100%" />
        </template>
      </keep-alive>
    </AppClient>

  </App>
</template>

<script>
  import responsableMixin from '@/mixins/responsable'
  import App from '@/components/app/app'
  import AppBar from '@/components/app/bar'
  import AppAvatar from '@/components/app/avatar'
  import AppMask from '@/components/app/mask-loading'
  import AppClient from '@/components/app/client'
  import IconButton from '@/components/app/IconButton'
  import IconTabs from '@/components/app/IconTabs'
  import FormEditor from '@/components/form/zzl/editor'
  import FormTest from '@/components/form/zzl/test'
  export default {
    components:{App, AppBar,AppMask,AppClient, IconButton,IconTabs,FormEditor,FormTest,AppAvatar},
    data(){
        
        return {
          formView:'editor',
          loading: false,
          is_ready:false,
          uid:null,
          form:{
            id:'create',
            name:'新建表单',
            config:{
              labelPosition:'top',
                labelWidth:400
            },
            fields:[]
          },
          
        }
      },
      mixins:[responsableMixin],
    mounted(){
        if(this.$route.query.fID){
          this.getFormData(this.$route.query.fID)
        }
      
    },
    watch:{
      $route:{
        handler(v){
          let {uid,fID} = v.query
          if(uid){
            this.uid = uid
            this.Login(uid)
            if(fID){
              this.getFormData(fID)
            }else{
              this.initEmptyForm()
            }
          }else{
            this.is_ready = true
            this.initEmptyForm()
          }
          
        },
        deep:true,
        immediate:true
      }
    },
    methods:{
      handleEvent(e){
        if(e.type == 'refresh'){
          this.getFormData(this.$route.query.fID)
        }
      },
      initEmptyForm(){
        this.form = {
          id:'create',
          name:"新建表单",
          type:"临时表单",
          config:{
             labelPosition:'top',
              labelWidth:400
          },
          fields:[]
        }
      },
      Login(uid){
        this.is_ready = false
        this.$store.dispatch('session/login_zzl',{uid}).then(res=>{
          
        }).catch(e=>{
          this.Error("登录失败,启用离线模式")
        }).finally(()=>{
          this.is_ready = true
        })
      },
      getFormData(id){
       this.$nextTick(()=>{
     
        this.$api.afterLogin(()=>{
          this.loading =  true
          this.$api.get('logined_zzl/form?id='+id).then(res=>{
            let formData = res.data.data
              if(this.$refs.editor){
                if(!formData.jsd){
                  formData.jsd = {}
                }
                formData.jsd.fID = formData.fID
                formData.jsd.formCode = formData.formCode
                formData.jsd.formName = formData.formName
                let form = this.$refs.editor.transform(formData.jsd)
                this.form = form
              }
          }).catch(e=>{
            this.Error(e)
          }).finally(()=>{
            setTimeout(()=>{
              
            this.loading = false
            },500)
          })
        })
       })
        
      }
    },
    computed:{
      menus(){
        const def_items = [{
          icon:'edit',
          name:'表单设计',
          key:'editor'
        },
        // {
        //   name:'数据展示',
        //   key:'view'
        // },{
        //   name:'打印模板',
        //   key:'print'
        // },{
        //   name:'高级选项',
        //   key:'config'
        // },
        {
          icon:'richangbaoji01',
          name:'表单测试',
          key:'test'
        }]
        const items = [...def_items]
        return items
      },
    }
  }
</script>

<style>

</style>