import { RectNode, RectNodeModel,h } from '@logicflow/core';

class CarbonNode extends RectNode {
  // 自定义节点渲染
  getShape() {
    const { model } = this.props;
    const { x, y, width, height, properties } = model;
    let approvalName = '抄送人';
    if(Array.isArray(properties.users)){
      approvalName = ' ' + properties.users.map(v=>v.name)
    }
  
    // 定义 SVG 图标
    const userIcon = h('svg', {
      x: x - width / 2 + 5,
      y: y - height / 2 + 5,
      width: 16,
      height: 16,
      viewBox: '0 0 1024 1024',
      fill: '#FFD700'
    }, [
      h('path', {
        d: 'M512 512a256 256 0 1 0 0-512 256 256 0 0 0 0 512z m0 85.333333c-170.666667 0-512 85.333333-512 256v85.333334h1024v-85.333334c0-170.666667-341.333333-256-512-256z'
      })
    ]);
  
    // 定义文本标签
    const text = h('text', {
      x: x - width / 2 + 25, // 在节点左上角
      y: y - height / 2 + 18,
      fill: '#1890FF',
      fontSize: 12,
      textAnchor: 'start'
    }, approvalName);
  
    return h('g', {}, [
      // 圆角矩形背景
      h('rect', {
        x: x - width / 2,
        y: y - height / 2,
        width,
        height,
        fill: '#E6F7FF',
        stroke: '#1890FF',
        strokeWidth: 2,
        rx: 10, // 设置水平圆角半径
        ry: 10  // 设置垂直圆角半径
      }),
      // 添加用户图标和名字
      userIcon,
      text
    ]);
  }
  
}

class CarbonNodeModel extends RectNodeModel {
  // 设置节点的默认属性
  initNodeData(e) {
    super.initNodeData(e);
    this.width = 160;
    this.height = 80;
    // 自定义属性，用于存储审批人员姓名
  }

  setAllowLinkOut() {
    return false; // 禁止输出的连线
  }
}

export default {
    type:'carbon_node',
    view:CarbonNode,
    model:CarbonNodeModel
}
