<template>
  <Checkbox :value="value" @input="$emit('input',$event)" size="size">
    {{label}}
  </Checkbox>
</template>

<script>
  export default {
    props:['value','label','size']
  }
</script>

<style lang="scss" scoped>

</style>