<template>
    <div style="position:relative;height:100%;">
     <div style="width:100%;height:30px;font-weight:bold;">
       <BaseIcon icon="md-apps" style="margin-right:5px" />合同纠纷处理 </div>
 
 
       
       <div class="flex-wrap flex-col" style="height:calc(100% - 30px);align-items:stretch;">
        <div class="flex-wrap flex-around" style="background:var(--bg3);height:70px;">
            <BaseNumboard name="已上报" value="0" value-style="color:var(--subtext3)" />
        
            <BaseNumboard name="已处理" value="0" value-style="color:var(--subtext3)" />
            <BaseNumboard name="协商解决" value="0" value-style="color:var(--subtext3)" />
            <BaseNumboard name="诉讼案件" value="0" value-style="color:var(--subtext3)" />
        </div>
        <div style="margin-top:10px;height:calc(100% - 70px);overflow:hidden;background:var(--bg3);padding:10px;border-radius:5px;">
            <template v-for="item in items">
                <div class="flex-wrap flex-between" style="padding:5px;" :key="item.id">
                   <div> {{ item.name }}</div>
                   <div>已受控</div>
                </div>
            </template>
            <BaseEmpty v-if="items.length == 0" msg="暂无记录" />
        </div>
      </div>
     </div>
    
 </template>
 
 <script>
 export default {
     data(){
         return {
             items:[]
         }
     }
 }
 </script>
 
 <style>
 
 </style>