const Vuex = require('vuex')
const debug = process.env.NODE_ENV !== 'production'
import config from '@/config'

let state = {
  version: config.version.toString(),
}

let getters = {
  version(state) {
    if (state.version)
      return `${state.version[0]}.${state.version[1]}.${parseInt(state.version.slice(-2))}`
  },
  device(){
    return {
      href: document.location.href,
      width: window.innerWidth,
      height: window.innerHeight,
      pixelRatio: window.devicePixelRatio,
      agent: navigator.userAgent,
      orientation: window.innerWidth < window.innerHeight
    }
  }
}

let actions = {}

let mutations = {}


let moduleFiles = require.context('./modules', true, /\.js$/)
let modules = {}
moduleFiles.keys().forEach(f => {
  let [, key] = f.match(/\.\/(.+)\.js$/)
  let file = moduleFiles(f)
  if (file && file.default)
    modules[key] = file.default
})

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules,
  strict: debug
})