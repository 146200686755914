<template>
  <FormItem :label="label" :required="required">
    <Input 
      type="textarea"
      v-model="_value"
      :disabled="!editable"
       :placeholder="config?.placeholder || '请输入'"
      style="width:100%"
      :rows="_config.rows || 4" />
  </FormItem>
</template>

<script>
 import defaultValueMixin from '../mixins/default-value'
  export default {
    mixins:[defaultValueMixin],
    cname:"长文本输入",
    corder:20,
    icon:'changwenbenxiangsi',
        props:['value','label','required','config','editable'],
    computed:{
      _value:{
        get(){
          return this.value
        },
        set(v){
          this.$emit('input',v)
        }
      },
      _config(){
        return this.config || {}
      }
    },
    properties:[
      {
        label:'字数限制',
        key:'maxlen',
        control:'Text',
      },
      {
        label:'宽度',
        key:'width',
        control:'Text'
      },
      {
        label:'行数',
        key:'rows',
        control:'Text',
        option:{
          number:true
        }
      },
      {
        label:'提示文字',
        key:'placeholder',
        control:'Text',
      },


    ]
  }
</script>

<style lang="scss" scoped>

</style>