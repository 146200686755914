<template>
  <div class="flex-wrap">
    <Input v-model="val" @input="handleChange" number placeholder="请输入" style="flex-shrink:1;width:200px;" />
    <BaseAmount full :value="val" style="margin-left:5px;width:200px;" />
  </div>
</template>

<script>
  export default {
    data(){
      return {
        val:""
      }
    }, 
    props:['value'],
    mounted(){
      if(!isNaN(this.value))
        this.val = this.value
      else if(this.val && typeof this.val == 'string')
        this.val = parseFloat(this.value)
      else
        this.val = ""
    },
    methods:{
      handleChange(e){
        this.$emit('input',e)
      }
    }
  }
</script>
