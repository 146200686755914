<style lang="less">
.task-container{
  scrollbar-color: var(--text3) var(--bg3);
  scrollbar-width: thin;
}
</style>
<template>
  <div class="pm-project-activity" style="position:relative;height:100%;">
  
    <div class="flex-wrap" style="width:100%;height:30px;font-weight:bold;">
      <BaseIcon icon="md-apps" style="margin-right:5px" />我的工作
    </div>
    <Toolbar 
      :items="tools"
      v-model="toolData"
      :height="80"
      :counts="counts"
      @on-event="handleToolEvent" 
    />
    <div class="flex-wrap" style="height:25px;align-items:stretch;gap:5px;padding:0 5px;">
        <div class="u-group u-group-active">
          <div><BaseIcon icon="user" :size="15" /> 个人计划 (32)</div>
        </div>
        <div class="u-group" style="width:160px">
          <div><BaseIcon icon="Building-" :size="15" /> 项目一 (32)</div>
        </div>
        <div class="u-group" style="width:120px">
          <div><BaseIcon icon="organization" :size="15" /> 总师办 (23)</div>
        </div>
        <div class="u-group">
          <div><BaseIcon icon="md-list" :size="15" /> 年会工作组（3）</div>
        </div>
        <div class="u-group" style="min-width:25px">
          <BaseIcon icon="md-more" />
        </div>
        
      </div>
    <div class="flex-wrap" :style="`height:calc(100% - 140px);background:var(--bg3);margin:0 5px;width:100%;position:relative;padding:5px;align-items:flex-start;`">
      
      <BaseEmpty v-if="filteredTasks.length == 0" style="border:1px solid var(--border);">
        <div class="flex-col">
          <img src="https://inbgz-1257839135.cos.ap-shanghai.myqcloud.com/assets/undraw_team_up_re_84ok.svg" style="
							width: 150px;
							height: 150px;
							margin-bottom: 10px;
							filter: brightness(0.8);
							opacity: 0.89;
							margin-top: 50px;
						" />
          <div style="color:var(--subtext3);">您没有任何关联的工作计划</div>
          <a class="flex-wrap" style="font-weight:bold;line-height:16px;" @click="handleOpenTasks">
            <BaseIcon icon="md-add" :size="20" style="margin-right:5px" /> 创建一个工作计划
          </a>
        </div>
      </BaseEmpty>
      <div class="flex-col" style="width:50px;flex-shrink:0;margin-right:5px;">
        <div class="flex-wrap flex-center" style="height:50px;width:100%;border:1px solid var(--border);cursor:pointer;">
        <BaseAvatar style="background:var(--success);">我</BaseAvatar>
        </div>
        <div class="flex-wrap flex-center" style="height:50px;width:100%;border:1px solid var(--border);margin-top:5px;">
        <BaseAvatar>张</BaseAvatar>
        </div>
        <div class="flex-wrap flex-center" style="height:50px;width:100%;border:1px solid var(--border);margin-top:5px;">
        <BaseAvatar>张2</BaseAvatar>
        </div>
        <div class="flex-wrap flex-center" style="height:50px;width:100%;border:1px solid var(--border);margin-top:5px;">
        <BaseAvatar>张3</BaseAvatar>
        </div>
        <div class="flex-wrap flex-center" style="height:50px;width:100%;border:1px solid var(--border);margin-top:5px;">
        <BaseAvatar icon="md-add"></BaseAvatar>
        </div>
      </div>
      <div class="flex-wrap task-container" style="height:calc(100%);width:100%;overflow-y:auto;flex-wrap:wrap;align-items:flex-start;align-content:flex-start;border-top:none;border-top:none;background:var(--bg3);
      ">
        <template v-for="a in filteredTasks">
          <div class="pm-task-item flex-wrap" :key="a.id" @click="openTask(a)" :style="`${w < 500 ? 'width:100%' : ''}`">
            <div class="flex-wrap" style="flex-shrink:1;flex-wrap:nowrap !important;width:calc(100% - 90px);flex-grow:1;">

              <div class='u-icon flex-wrap flex-center'
                style="height:30px;width:30px;display:inline-flex;flex-shrink:0;margin:0 5px;color:#fff;border-radius:5px;position:relative;padding:3px;background:var(--border);border-radius:5px;
      color:var(--primary);filter:brightness(0.8) contrast(0.8);"
                :style="`color:${getStatus(a).color}`">
                <Icon :custom="`gzicon gzi-${a.icon}`" size="20"></Icon>
              </div>
              <div style="height:100%;width:calc(100% - 30px);">
                <div class="pm-ti-name" :class="{ 'hover-scroll-left:': a.name && a.name.length > 10 }">
                  <span>{{ a.name }}</span></div>
                <div class="pm-ti-desc">{{ a.namespace_name }}</div>
              </div>
            </div>
            <template v-if="a.type == '今日事项'">
              <div class="split5">
                <Button type="success" ghost @click="finishTask(a)" icon="md-checkmark" size="small" style="padding-right:0"></Button>
                <Button type="error" ghost @click="removeTask(a)" icon="md-trash" size="small" style="padding-right:0"></Button>
              </div>
            </template>
            <template v-else-if="a.type == '逾期事项'">
              <div class="split5">
                <Button  icon="md-more" size="small" style="padding-right:0"></Button>
              </div>
            </template>
            <div class="flex-col split5" v-else style="flex-grow:0;flex-shrink:0;width:90px;">

              <div class="pm-pa-time" :style="`color:${getStatus(a).color}`">{{ getStatus(a).text }}</div>
              <div class="pm-pa-time" v-if="a.progressText" style="font-size:12px">{{ a.progressText }}</div>

            </div>

          </div>
        </template>
      </div>
    </div>

    <Modal v-model="showTemplate" :width="800" transfer footer-hide>
      <div></div>
    </Modal>

    <Drawer v-model="showCreateModal" :width="400" transfer>
      <Form>
        <div>

        </div>
      </Form>
    </Drawer>
    <!-- 
    <Modal v-model="modalTask"  width="1200" fullscreen footer-hide>
      <div style="height:100%;overflow-y:auto;position:relative;">
      <BaseLoading v-if="loading_task"></BaseLoading>
      <div v-else-if="loading_error" class="flex-wrap flex-center" style="min-height:300px">
        读取任务失败:{{loading_error}} <a style="color:var(--primary);margin-left:10px;" @click="getTask(current)"><Icon type="md-refresh"></Icon> 重试</a>
      </div>
      <task-entity v-else :data="current" :tasks="current_tasks" editable @close="modalTask = false" /> 
      </div>
    </Modal> -->

    <Drawer v-model="showEntity" :width="500">
      <template v-if="current.type=='memo'">
         <div>
          <div>
            {{task.content}}
          </div>
        <div>
          <Input auto-focus type="textarea" placeholder="输入跟踪记录" @on-enter="handleEnterComment" v-model="comment.content"  :rows="4" />
          
          <div class="flex-wrap flex-right" style="margin-top:10px">
            <Button @click="addComment">提交  Ctrl+Enter</Button>
          </div>
        </div>
        <div class="flex-wrap flex-center" style="height:30px" v-if="loading_comments">
        <BaseLoading  />
        </div>
        <div v-if="current.comments && current.comments.length > 0">
          
          <Divider style="margin:10px 0"></Divider>
          <template v-for="c in current.comments">
            <div  :key="c.id">
            <div class="flex-wrap flex-between">
              <BaseNameBoard :uid="c.created_by" />
              <div><a @click="removeComment(c.id)" v-if="c.created_by == session.id">删除</a><span style="color:var(--success)" ></span> {{moment(c.created_at).fromNow()}}</div>
            </div>
            <div style="padding:10px;margin:10px 0;border-radius:5px;white-space:pre-wrap;">{{c.content}}</div>
            </div>
          </template>

          
        </div>
      </div>
      </template>

      <MemoEntity v-else :task="current" />
    </Drawer>

    <CreateTask 
      v-model="showEditTask"
    />


  </div>
</template>

<script>
import responseMixin from '@/mixins/responsable'
import TaskEntity from '@/components/task/Entity'
import MemoForm from '@/components/task/Memo'
import Toolbar from '@/components/app/toolbar'
import MemoEntity from '@/components/task/MemoEntity'
import CreateTask from '@/components/task/modal/CreateTask'
import { mapGetters } from 'vuex'
import { setTimeout } from 'timers';
import moment from 'moment'
const icons = {
  '分解': 'organization',
  '流程': 'lianjieliu',
  '计划': 'eventnote',
  '跟踪': 'meetingrecord',
  '其他': 'history'
}
export default {
  panelInfo: {
    name: '待办任务',
    minWidthSpan: 10,
    minHeightSpan: 5
  },
  mixins: [responseMixin],
  components: { TaskEntity, MemoForm, MemoEntity,Toolbar,CreateTask },
  data() {
    return {
      filter:{
        timeRegion:'all',
      },
      showCreateModal:false,
      formData:{},
      toolData:{},
      appraisalState:'点击进入',
      appraisalStateColor:'var(--primary)',
      loading_task: false,
      loading_error: '',
      selected_group: '',
      modalTask: false,
      showEntity:false,
      current: {},
      current_tasks: [],
      tasks: [],
      timeregions:[{name:"全部"},{name:"年"},{name:"月"},{name:"周"},{name:"日"}],
      showEditTask:false,
      showTemplate:false,
      counts:{
        finished:23,
        overdated:10
      }
    }
  },

  methods: {
    handleToolEvent({key,value}){
      const grouped_keys = ['overdated','finished']

      if(value == true && grouped_keys.includes(key)){
        grouped_keys.forEach(v=>{
          if(key != v){
            this.$set(this.toolData,v,false)
          }
        })
      }

      if(key == 'add'){
        this.handleCreate()
      }else if(key == 'template'){
        this.showTemplate = true
      }
    },
    getStatus(t) {
      this.map = {}
      if (this.map[t.id])
        return this.map[t.id]

      let s = {}
      if (t.spec_status == 1) {
        s = {
          vicon: 'md-pause',
          text: '暂停中',
          color: '#3399ff'
        }
      } else if (t.spec_status == 2) {
        s = {
          vicon: 'md-square',
          text: '已停止',
          color: '#993333'
        }
      } else if (t.spec_status == 3) {
        s = {
          icon: 'richangbaoji01',
          text: '已作废',
          color: '#333333'
        }
      } else {
        if (t.status < 2) {
          s.color = "var(--primary);"
          if (t.started_at && t.duration) {
            let deadline = moment(t.started_at).add(t.duration, 'days')
            let offset = moment.duration(moment() - deadline).as('days').toFixed(0)
            s.text = offset > 1 ? `还剩${offset}天` : (offset < -1 ? `超时${offset}天` : '即将截止')
            s.icon = "clock"

          } else {
            if (t.status == 0) {
              s.text = "未启动"
              s.vicon = "md-log-in"
            } else {
              s.text = "执行中"
              s.icon = "clock"
              s.size = "15"
            }
          }
        } else if (t.status == 2) {
          s.color = "#55aa33"
          if (t.started_at && t.duration) {
            let deadline = moment(t.started_at).add(t.duration, 'days')
            let offset = moment.duration(moment(t.finished_at) - deadline).as('days').toFixed(0)
            s.text = (offset < -1 ? `超期${offset}天完成` : '按期完成')
            s.icon = "clock"
          } else {
            s.text = "已完成"
            s.vicon = 'md-checkmark'
          }
        } else if (t.status == 3) {
          s.color = "#ff3333"
          s.text = "失败"
          s.vicon = 'md-close'
        }
      }
      this.map[t.id] = s


      return s
    },
    getData() {
      let url = '/tasks?q=mine' + (this.namespace ? ('&namespace=' + this.namespace) : '')

      this.$api.get(url).then(res => {
        let tasks = res.data.data
        tasks.forEach(v => {
          if (v.type == '跟踪任务') {
            v.icon = icons.跟踪
          } else if (v.type == '流程') {
            v.icon = icons.流程
          } else {
            if (v.progressText)
              v.icon = icons.分解
            else
              v.icon = icons['计划']
          }

          if (v.root_id)
            v.group = '子计划'
          else
            v.group = v.type || '任务'
        })

        tasks.sort((a, b) => a.status == 1 ? 1 : ((a.status > b.status) ? 1 : -1))

        this.tasks = tasks
        if(this.groups[0])
          this.selected_group = this.groups[0].key
      })
    },
    getTask(task) {
      this.loading_task = true
      this.loading_error = ""
      this.$api.get('/tasks/' + task.id).then(res => {
        let tasks = res.data.data

        tasks.forEach(v => {
          v.subs = tasks.filter(t => t.parent_id == v.id)
          if (v.relations)
            v.prev = tasks.filter(t => v.relations.includes(t.id))
          if (v.relationsNext)
            v.next = tasks.filter(t => v.relationsNext.includes(t.id))

          v.parent = tasks.find(t => t.id == v.parent_id)
          v.root = tasks.find(t => t.id == v.root_id)

          if (v.type == '跟踪任务') {
            v.icon = icons.跟踪
          } else if (v.type == '流程') {
            v.icon = icons.流程
          } else {
            if (v.subs && v.subs.length > 0)
              v.icon = icons.分解
            else
              v.icon = icons['计划']
          }
        })
        this.current = tasks[0];
        this.current_tasks = tasks.slice(1)

      }).catch(e => {
        setTimeout(() => {
          this.loading_error = e
        }, 500)

      }).finally(() => {
        setTimeout(() => {

          this.loading_task = false
        }, 500)
      })
    },
    openTask(t) {
      if (t.type == '今日事项' || t.type == '逾期事项') {

        this.current = t
        this.showEntity = true
      } else {

        this.$store.commit("session/push_task", { id: t.id, name: t.name })
        this.RouteTo('/core/itasks/' + t.id + '/dashboard', false)
      }
    },
    handleOpenTasks() {
      let url = '/core/tasks?day=' + moment().format("YYYYMMDD")
      this.$store.commit("session/push", { url, base_url: '/core/tasks', icon: 'date', name: '计划管理' })
      this.RouteTo(url)
    },
    checkAppraisal(){
      this.$api.get("/oa/appraisal_instances/self?appraisal_id=2023&simple=1").then(res=>{
        let appraisal = res.data.data
        if(appraisal && appraisal.id){
          if(appraisal.state < 2){
            this.appraisalStateColor = `var(--warning)`
            this.appraisalState = '继续填写'
          }
          else{
            this.appraisalState = '已完成'
            this.appraisalStateColor = `var(--success)`
          }
        }else{
          this.appraisalStateColor = `var(--primary)`
          this.appraisalState = '点击进入'
        }
      })
    },
     unfinishTask(t){
        this.Confirm("撤销此任务会接触任务的完成状态，并将此任务作为当前新增任务添加，是否继续？",()=>{
          this.$api.patch("memos/"+t.id,{
            finished:false,
            finished_at:null,
            datekey:moment().format(this.datePattern)
          }).then(res=>{
            let updateInfo = res.data.data
            Object.assign(t,updateInfo)
            if(index = this.finished.findIndex(v=>v.id == t.id))
            if(index != -1)
              this.finished.splice(index,1)
             this.getCount()
          })
        })
      },
      removeTask(t){
        this.$api.delete(`memos/`+t.id).then(res=>{
          let index = this.tasks.findIndex(v=>v.id == t.id)
          if(index != -1)
            this.tasks.splice(index,1)
          
          this.$Notice.success({title:"删除完成"})
          setTimeout(()=>{
            
          this.selected_group = this.groups[0].key
          },300)
        })
      },
      resetTask(t){
        this.$api.patch('memos/'+t.id+'?q=reset').then(res=>{
          let index = this.tasks.findIndex(v=>v.id == t.id)
          if(index != -1)
            this.tasks.splice(index,1)
          this.$Notice.success({title:"操作成功"})
          this.getCount()
        })
      },
       finishTask(t){
        this.$api.patch('memos/'+t.id,{finished:1}).then(res=>{
          this.getData()
        })
      },
      addComment(){
        let comment = this.comment
        if(!comment.content){
          this.Error("请输入内容再提交")
          return
        }
        this.$api.post(`memos/${this.current.id}/comments`,comment).then(res=>{
          let updateInfo = res.data.data
          Object.assign(comment,updateInfo)
          this.current.comments.splice(0,0,comment)
          this.comment = {}
        })
      },
      removeComment(cmt_id){
        this.$api.delete(`memos/${this.current.id}/comments/${cmt_id}`).then(res=>{
          let index = this.current.comments.findIndex(v=>v.id == cmt_id)
          if(index != -1)
           this.current.comments.splice(index,1)
        })
      },
       openTask(t){
        if(t.task_id){
          this.RouteTo('/core/itasks/'+t.id+'/dashboard')
        }else{
          this.loading_comments = true
          this.$api.get('/memos/'+t.id+'/comments').then(res=>{
            this.$set(this.task,'comments',res.data.data)
          }).finally(()=>{
            this.loading_comments =false
          })
        }
        this.task = t
        this.showTask = true
      },
      editTask(t){
        this.task = cloneDeep(t)
        this.oldTask = t
        this.showEditTask = true
      },
      handleCreate(){
        let task = {
          datekey:moment().format("YYYYMMDD"),
          public:true
        }
        if(this.current.group_id){
          task.group_id = this.current.group_id
        }

        if(this.filterDep){
          task.group_id = this.filterDep
        }

        if(this.current.project_id)
          task.project_id = this.current.project_id
        if(this.filterProject){
          task.project_id = this.filterProject
        }
        this.current = task

        this.showEditTask = true
        setTimeout(()=>{
          
        this.$refs.taskContent.focus()
        },500)
      },
      handleEnterComment(e){
        if(e.ctrlKey){
          this.addComment()
        }
      },
      handleEnter(e){
        if(e.ctrlKey){
          this.saveTask()
        }
      },
      saveTask(){
        if(this.current.id){
          let modified = UTIL.compare_patch_object(this.oldTask,this.task)
          if(modified){
            this.$api.patch(`memos/${this.current.id}`,modified).then(
              res=>{
                Object.assign(this.oldTask,this.task)
                this.$Notice.success({title:"修改成功"})
                this.showEditTask = false
              })
          }
          return
        }

        let item = {
          content:this.current.content,
          datekey:this.current.datekey,
          datetype:'date',
          public:this.current.public,
          sms:this.current.sms
        }

        if(this.current.group_id)
          item.group_id = this.current.group_id
        if(this.current.project_id)
          item.project_id = this.current.project_id

        if(this.current.deadline){
          item.deadline = this.current.deadline
        }

        this.$api.post('memos',item).then(res=>{
          this.getData()
          this.$Notice.success({title:"创建成功"})
          this.showEditTask = false
        })
      }
  },
  mounted() {
    this.getData()
    this.checkAppraisal()
  },
  computed: {
    ...mapGetters('session', ['session']),
    tools(){
      return [{
        key:"add",
        type:"icon-button",
        icon:"md-add"
      },{
        type:"list",
        rows:2,
        subs:[{
          key:'template',
          
          type:"icon-button",
          icon:"lianjieliu"
        },{
          key:'files',
          type:"icon-button",
          icon:"xiangmu2"
        },{
          key:'calendar',
          type:"icon-button",
          icon:"date"
        },{
          type:"icon-button",
          icon:"history"
        },]
      },{
        type:"tabs",
        name:"计划周期",
        subs:[{
          key:'all',
          name:"全部",
          count:5,
        },{
          key:'year',
          name:"年",
          count:2
        },{
          key:'month',
          name:"月",
          count:2
        },{
          key:'date',
          name:"日",
          count:7
        }]
      },{
        type:"tabs",
        name:"工作类型",
        subs:[{
          key:'kernel',
          name:"核心",
          count:5,
        },{
          key:'duration',
          name:"周期",
          count:2
        },{
          key:'temp',
          name:"临时",
          count:2
        }]
      },{
        key:"finished",
        type:"count-button",
        name:"本周完成",
        count:23,
        togglable:true
      },{
        key:"overdated",
        type:"count-button",
        name:"已逾期",
        count:13,
        togglable:true
      },{
        type:"list",
        rows:2,
        subs:[{
          key:'template',
          type:"search",
          placeholder:"输入关键字搜索"
          
        },{
        type:"list",
        rows:1,
        subs:[{
          key:'files',
          type:"icon-button",
          icon:"xiangmu2"
        },{
          key:'calendar',
          type:"icon-button",
          icon:"date"
        },{
          type:"icon-button",
          icon:"history"
        },{
          type:"icon-button",
          icon:"history"
        }]},]
      },]
    }, 
    
    namespace() {
      if (this.$route.params)
        return this.$route.params.id
    },
     deps(){
        if(Array.isArray(this.session.groups)){
          return this.session.groups.filter(v=>v.namespaced)
        }else
          return []
      },
    filteredTasks() {
      let tasks = this.tasks.filter(v => v.spec_status !== 3)
      if (this.selected_group)
        return tasks.filter(v => v.group == this.selected_group)
      else
        return tasks
    },
    groups() {
      let g = {}
      this.tasks.forEach(v => {
        if (v.group) {
          if (g[v.group] == undefined)
            g[v.group] = [v]
          else
            g[v.group].push(v)
        }
      })



      let groups = Object.keys(g).map(key => ({
        name: key,
        key,
        items: g[key]
      }))
      const group_order = {
        '今日事项': 1,
        '逾期事项': 2,
        '计划任务': 3,
        '子任务': 4
      }
      const makeOrder = g => (group_order[g] || 1000)

      groups.sort((a, b) => {
        return makeOrder(a.key) > makeOrder(b.key) ? 1 : -1
      })


      return groups
    }
  }
}
</script>

<style lang="less">


.u-group{
  font-size:12px;
  border:1px solid var(--border);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding:0 10px;
  min-width:80px;
  .u-group-count{
    font-size:16px;
  }
}

.pm-project-activity {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  
  .pm-task-item {
    display: flex;
    width: calc(100%);
    justify-content: space-between;
    align-items: center;
    height: 45px;
    background: var(--bg2);
    overflow: hidden;
    border-bottom: 1px solid var(--border);
    border-left:1px solid var(--border);
    border-top:none;
    font-size: 12px;
    flex-wrap: nowrap !important;

    .pm-ti-avatar {
      width: 30px;
      height: 40px;
      background: var(--bg1);
      flex-shrink: 1;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--text2);
      border-radius: 8px;
    }

    .pm-ti-name {
      font-size: 14px;
      color: var(--text2);
      white-space: nowrap;
      overflow: hidden;
      width: 90%;
      text-overflow: ellipsis;
    }

    .pm-ti-desc {
      font-size: 12px;
      color: var(--text2);
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      opacity: 0.5;
    }

    .pm-pa-time {
      width: 80px;
      text-align: right;
      margin-right: 10px;
      color: var(--secondary);
    }

    a {
      color: var(--bg1);

    }
  }

  .pm-task-item:first-child{
    border-top:1px solid var(--border);
  }

  .pm-task-item:hover {
    cursor: pointer;
    filter: brightness(1.05);
    border-color:var(--primary);
    .pm-ti-name{
      color:var(--primary);
    }
  }

  .pm-task-item:last-child {
    border: none;
  }

  .filter-item {
    font-size: 12px;
    text-align: center;
    color: var(--text2);
    min-width: 80px;
    border-right: 1px solid var(--border);
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .label {
      display: flex;
      align-items: center;
    }

    .count {
      margin-left: 5px;
    }
  }

  .filter-item-active {
    color: var(--text1);
    background: var(--primary);
  }

  .filter-secondary {
    display: flex;


  }

  .u-group-active{
    background:var(--primary);
    color:var(--hover-text);
  }

  
  .fs-block-item {
      font-size: 12px;
      text-align: center;
      color:var(--text2);
      min-width: 100px;
      background:#FFFFFF33;
      height:32.5px;
      margin-right:5px;
      border-radius:2px;
      margin-bottom:5px;
      text-align:center;
      display: flex;
      justify-content: center;
      align-items: stretch;
      position: relative;

      .label {
        display: flex;
        align-items: center;
        height:100%;
        flex-grow: 1;
        padding-left:10px;
      }

      .count {
        width:32.5px;
        flex-grow: 0;
        border-left:1px solid var(--border);
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

.fs-block-item:hover {
    filter: brightness(1.1);
    cursor: pointer;
  }

    .fs-block-item-active,
    .fs-block-item-active:hover {
      background:linear-gradient(to bottom,var(--primary),var(--primary));
      filter:none;
      border-bottom:none;
      color:var(--hover-text);
    }


  
}</style>