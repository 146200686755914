
<template>
  <div style="height:100%;overflow-y:auto">Test,nv={{naviate}}, {{dd}}
    <Button @click="submit">Submit</Button>
    <Button @click="cancel">Cancel</Button>

  </div>
</template>

<script>
export default {
    computed:{
        dd(){
            return Object.keys(window).includes('dd') || "Nothing"
        },
        naviate(){
            return navigator.userAgent.toLowerCase().indexOf('dingtalk')
        }
    },
    created(){
        const script = document.createElement('script');


            script.src = 'https://appx/web-view.min.js';


            document.head.appendChild(script);
    },
    methods:{
        submit(){
            window.dd.postMessage('submit')
        },
        cancel(){
            window.dd.postMessage('cancel')
        }
    }
       
}
</script>

<style>

</style>