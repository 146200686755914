<template>
  <Container style="padding:20px">
    <div style="width:960px;margin:0 auto">
      <BaseSegment title="企业基础信息配置" :collapse="false">

    </BaseSegment>
    <BaseSegment title="业务类型" :collapse="false">

    </BaseSegment>
     <BaseSegment title="工程配置" :collapse="false">

    </BaseSegment>

    </div>
  </Container>
</template>

<script>
import Container from '@/components/app/container'
export default {
  components:{Container}
}
</script>

<style>

</style>