<template>
    <div>
        <div v-if="editable">
        <div v-if="option.multipleBy">
            <div>{{ (options.find(o=>o.id == value) || {}).name }}</div>
        </div>
        <CheckboxGroup
            v-else
            :value="value"
            ref="control"
            @on-change="$emit('change',$event)"
            @input="$emit('input',$event)"
            placeholder="选择"
            style="display:inline-block"
        >
            <Checkbox v-for="o in $store.getters['session/remoteData'][option.remote] " :label="o.id" :key="o.label">
                {{ o.name }}
            </Checkbox>
        </CheckboxGroup> 
        </div>
        <div v-else>
            <Static :value="readableValue" />
        </div>

    </div>
</template>

<script>

import Static from './Static'
export default {
    components:{Static},
    props:{
        value:{
            type:Array | String,
        },
        option:{
            type:Object,
            default:()=>({})
        },
        editable:{
            type:Boolean,
            default:true
        }
    },
    mounted(){
        if(this.option.remote){
                this.$store.dispatch('session/queryRemoteData',this.option.remote)
            }
        
    },
    methods:{
        focus(){
            let checkboxGroup = this.$refs.control
            if (checkboxGroup) {
                const checkboxInput = checkboxGroup.$el.querySelector('input[type="checkbox"]')
            if (checkboxInput) {
                checkboxInput.focus()
            }
      }
        }
    },
    computed:{
        options(){
            return this.$store.getters['session/remoteData'][this.option.remote] || []
        },
        readableValue(){
            if(Array.isArray(this.value) && this.value.length > 0)
                return this.value.map(v=>(this.options.find(o=>o.id == v) || {}).name).join(",") 
            else
                return "未填写"
        }
    }
}
</script>

<style>

</style>