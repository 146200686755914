import {
  API
} from '@/vue-plugins/api'



export const makeWorker = async (worker_id) => {

    let res = await API.get("/workers/" + worker_id)
    return new Worker(
      URL.createObjectURL(new Blob([`${res.data.data}`]))
    )
 
}
