<style lang="less">
.pm-cell{
  margin:5px 0;
  padding:3px 0;
  font-size:1rem;
  border-bottom:1px dashed var(--border);
  .l-label{
    color:#888;
    padding-left:5px;
    font-size:0.9rem;
  }

  .l-value{
    color:var(--primary);
    font-size:0.9rem;
  }
  
}

.pm-cell:hover{
  background:var(--bg2);
}

.editable-avatar:hover{
  cursor: pointer;
  .ea-icon{
    filter:brightness(1.1);
  }
}
</style>

<template>
  <div style="background:var(--bg3);height:100%;padding:5px;">
    <div class="flex-wrap flex-line" style="padding:10px;background:var(--bg2);position:relative;">
      <div class="flex-wrap flex-center editable-avatar" style="position:relative;width:50px;height:50px;">
        <BaseAvatar :src="session.avatar" style="width:40px;height:40px;flex-shrink:0;" />
        <BaseIcon class="ea-icon" icon="md-checkmark" :size="12" style="position:absolute;right:0px;top:0px;background:var(--primary);color:var(--text1);padding:3px;border-radius:50%;" />
      </div>
      
      <div class="flex-col align-start" style="margin-left:10px">
        <div style="font-size:18px;">{{session.user}} 
          <div style="font-size:12px;color:var(--primary);cursor:pointer;" @click="handleEditUserInfo">更换头像</div></div>
      </div>
      <div style="position:absolute;right:0;top:calc(50% - 10px);">
         
      </div>
    </div>

    <div style="padding:10px;font-size:0.8rem;padding-top:0;">
       <div class="flex-wrap flex-between pm-cell" style="margin-top:5px;padding-top:5px;">
        <div class="l-label">上次登录</div>
        <div class="l-value">{{session.last_logined_at?moment(session.last_logined_at).fromNow():'无'}}</div>
      </div>
      <div class="flex-wrap flex-between pm-cell">
       <div class="l-label">注册时间</div>
        <div class="l-value">{{moment(session.created_at).format("L")}}</div>
      </div>
       <div class="flex-wrap flex-between pm-cell">
        <div class="l-label">密码登录</div>
        <div class="l-value">
          <Dropdown trigger="click" transfer @on-click="onEvent">
          <a>{{session.password?'修改密码':'已禁用'}} <BaseIcon icon="ios-arrow-down" /></a>
          <DropdownMenu slot="list">
            <DropdownItem name="edit-account">修改登录名/密码 <BaseIcon icon="ios-arrow-forward" /></DropdownItem>
            <!-- <DropdownItem name="disable-account" v-if="!session.disabled_password">禁用(密码登录) </DropdownItem>
            <DropdownItem v-else name="edit-account">启用</DropdownItem> -->
        </DropdownMenu>
          </Dropdown>
        </div>
      </div>
       <div class="flex-wrap flex-between pm-cell">
        <div class="l-label">手机号</div>
        <div class="l-value"> <Dropdown trigger="click" transfer  @on-click="onEvent">
          <a   :style="session.phone?'':'color:#aaa'">{{session.phone?maskPhone(session.phone):'未绑定'}} <BaseIcon icon="ios-arrow-down" /></a>
          <DropdownMenu slot="list">
            <DropdownItem v-if="!session.phone" name="bind-phone">绑定手机号 </DropdownItem>
            <DropdownItem name="unbind-phone" v-else>解除绑定 </DropdownItem>
        </DropdownMenu>
          </Dropdown></div>
      </div>
       <div class="flex-wrap flex-between pm-cell">
        <div class="l-label">微信登录</div>
         <Dropdown trigger="click" transfer @on-click="onEvent">
          <a  :style="session.wechat?'':'color:#aaa'">{{session.wechat?'已绑定':'未绑定'}} <BaseIcon icon="ios-arrow-down" /></a>
          <DropdownMenu slot="list">
            <DropdownItem  name="bind-wechat" v-if="!session.wechat">绑定微信 </DropdownItem>
            <DropdownItem  name="unbind-wechat" v-else>解除绑定 </DropdownItem>
        </DropdownMenu>
          </Dropdown>
      </div>
      <div class="flex-wrap flex-between pm-cell">
        <div class="l-label">钉钉登录</div>
        <div class="l-value"><Dropdown trigger="click" transfer  @on-click="onEvent">
          <a :style="session.ding_id?'':'color:#aaa'">{{session.ding_id?'已绑定':'未绑定'}} <BaseIcon icon="ios-arrow-down" /></a>
          <DropdownMenu slot="list">
            <DropdownItem name="bind-ding" v-if="!session.ding_id">绑定钉钉 </DropdownItem>
            <DropdownItem name="unbind-ding" v-else>解除绑定 </DropdownItem>
        </DropdownMenu>
          </Dropdown></div>
      </div>
       <div class="flex-wrap flex-between pm-cell" v-if="false">
           <div class="l-label">绑定邮箱</div>
          <div class="flex-wrap split5">
          <a @click="resetPasswordFromMail">修改密码</a>
          <a @click="resetPasswordFromMail">绑定邮箱</a>
          <a @click="resetPasswordFromMail">解除绑定</a>
          </div>
      </div>
      
      
    </div>


    

    <Modal v-model="showUserInfoEditor" title="编辑图像" footer-hide width="600">
      <Form ref="form-user" :model="formData" style="margin:0 10px">
        <div>
          <FormItem>
            <GFormImageClip v-model="formData.avatar" :editable="true" style="width:100px;height:100px" 
            :option="{
              uploader:'session/upload'
            }"/>
          </FormItem>
        </div>
        <div class="flex-wrap flex-right" style="margin-top:10px">
          <Button @click="patchUserInfo" type="primary">保存</Button>
          <Button @click="showUserInfoEditor=false">取消</Button>
        </div>
      </Form>
    </Modal>

    <Modal v-model="modalPassword" title="配置账号登录" width="400" footer-hide>
      <template v-if="forgetOldPassword">
      
        <Form ref="formPasswordForget" :model="formData" :rules="formPasswordForgetRule" style="margin:0 10px">
        
          <FormItem prop="code" required label="请完成手机验证">
            <div style="display:flex;flex-direction:column;width:100%">
            <div style="margin:0 10px;">您已绑定手机:<span style="color:var(--primary);font-weight:bold">{{session.phone?maskPhone(session.phone):'未绑定'}}</span></div>

        <div class="flex-line" style="margin-top:10px;min-height:20px;">
            <Input v-model="formData.code" placeholder="验证码" style="margin-right:10px" :maxlength="6" :autocomplete="false">
            <template #prefix>
                <BaseIcon icon="mail" size="20" />
              </template>
            </Input>
            <Button type="info" ghost style="min-width:120px" :disabled="cooldown > 0 || !session.user" @click="sendVcode">{{cooldown > 0?`已发送(${cooldown})`:(firstTime?'发送验证码':'重新发送')}}</Button>
        </div>
            <div class="flex-wrap flex-left" style="margin-top:10px;">
              <BaseIcon icon="md-alert" color="var(--primary)" style="margin-right:5px;" size="16" />
            <a @click="forgetOldPassword=false">啊，我想起旧密码了</a>
          </div>
        </div>
        </FormItem>
         <FormItem prop="new_pass" required label="请输入新密码">
          <Input type="password" v-model="formData.new_pass"  autocomplete="new-password"  size="large" />
        </FormItem>
         <FormItem prop="new_pass_again" required label="请再次输入新密码">
          <Input type="password" v-model="formData.new_pass_again"   autocomplete="new-password" size="large" />
        </FormItem>
        <div class="flex-wrap flex-end">
          <Button size="large" type="primary" style="margin-right:10px" @click="submitForget">提交</Button>
          <Button size="large" @click="modalPassword=false;formData={}">取消</Button>
        </div>
      </Form>
      </template>
      <Form ref="form" v-else  :model="formData" :rules="formPasswordRule" style="margin:0 10px">
        <FormItem prop="user" required label="登录名">
          <Input v-model="formData.user"  size="large" />
        </FormItem>
        <FormItem prop="old_pass" required label="请输入旧密码" v-if="session.password">
          <Input type="password" autocomplete="new-password" v-model="formData.old_pass"  size="large" />
        </FormItem>
        <div class="flex-wrap flex-right">
          <BaseIcon icon="md-alert" color="var(--primary)" style="margin-right:5px;" size="16" />
        <a @click="forgetOldPassword=true">啊，忘记旧密码了</a>
        </div>
         <FormItem prop="new_pass" required label="请输入新密码">
          <Input type="password" autocomplete="new-password" v-model="formData.new_pass"  size="large" />
        </FormItem>
        <div class="flex-wrap">密码强度 <div class="pass-stronger-box"><div :style="passwordStronger=='weak'?'background:red':''">弱</div><div :style="passwordStronger=='medium'?'background:orange':''">中</div><div :style="passwordStronger=='strong'?'background:green':''">强</div></div></div>
         <FormItem prop="new_pass_again" required label="请再次输入新密码">
          <Input type="password" autocomplete="new-password" v-model="formData.new_pass_again"  size="large" />
        </FormItem>
        <div class="flex-wrap flex-end">
          <Button size="large" type="primary" style="margin-right:10px" @click="submit">提交</Button>
          <Button size="large" @click="modalPassword=false;formData={}">取消</Button>
        </div>
      </Form>
    </Modal>

    <Modal v-model="showWeChat" title="绑定微信" footer-hide>
      <div class="flex-wrap flex-center">
      <div id="bind_wechat_wrap">

      </div>
      </div>
    </Modal>

    <Modal v-model="showBindingPhone" title="绑定手机" footer-hide>
      <div class="flex-wrap flex-center login" style="background:var(--bg3)">
      <SMSForm v-model="formData" @on-success="handleBindPhoneSuccess" />
      </div>
    </Modal>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import SMSForm from '@/components/self/bindingPhone'
  import GFormImageClip from '@/components/form/ImageClip'
  import UTIL from '@/utils'
  export default {
    data(){
      return {
        modalPassword:false,
        showWeChat:false,
        showBindingPhone:false,
        showUserInfoEditor:false,
        forgetOldPassword:false,
        cooldown:false,
        firstTime:true,

        formData:{}
      }
    },
    components:{SMSForm,GFormImageClip},
    computed:{
      ...mapGetters('session',['session']),
      formPasswordRule(){
        return {
          user:[{required:true,message:"请输入用户名"}],
          old_pass:[{required:true,message:"请输入旧密码"}],
          new_pass:[{required:true,message:"请输入新密码"}],
          new_pass_again:[{required:true,message:"请再次确认新密码"}]
        }
      },
      formPasswordForgetRule(){
        return {
          code:[{required:true,message:"请输入验证码"}],
          new_pass:[{required:true,message:"请输入新密码",trigger:"blur"}],
          new_pass_again:[{required:true,message:"请再次确认新密码",trigger:"blur"}]
        }
      },
      passwordStronger(){
        let password = this.formData.new_pass
        if(!password)
          return 
        // 判断密码长度
        if (password.length < 6) {
          return "weak";
        }

        // 判断是否含有数字、小写字母、大写字母、特殊字符
        let hasNumber = /\d/.test(password),
          hasLowercaseLetter = /[a-z]/.test(password),
          hasUppercaseLetter = /[A-Z]/.test(password),
          hasSpecialChar = /[!@#$%^&*]/.test(password);

        // 根据以上条件进行分级
        if (hasNumber && hasLowercaseLetter && hasUppercaseLetter && hasSpecialChar) {
          return "strong";
        } else if (
          (hasNumber && hasLowercaseLetter) ||
          (hasNumber && hasSpecialChar) ||
          (hasNumber && hasUppercaseLetter) ||
          (hasLowercaseLetter && hasUppercaseLetter&& hasLowercaseLetter)
        ) {
          return "medium";
        } else {
          return "weak";
        }
      }
    },
    mounted(){
      let isBindingSuccess = localStorage.getItem('binding-success')
      if(isBindingSuccess){
        this.Success("恭喜您,绑定成功！")
        localStorage.removeItem('binding-success')
      }
    },
    methods:{
       resetPasswordFromMail(){
        this.$api.patch("sessions/reset-password-from-mail").then(res=>{
          this.Success("邮件已发送,请前往邮箱查看")
        })
      },
      handleEditUserInfo(){
        this.formData = {
          name:this.session.name,
          slogan:this.session.slogan,
          avatar:this.session.avatar
        }
        this.showUserInfoEditor = true
      },
      patchUserInfo(){
        this.$store.dispatch("session/patch",this.formData).then(res=>{
          this.$Notice.success({title:"修改成功"})
          this.showUserInfoEditor = false
        })
      },
      onEvent(e){
        const event_map = {
          'bind-wechat':this.prepareWechat,
          'edit-account':this.editAccount,
          'disabled-account':this.disableAccount,
          'bind-phone':this.bindPhone,
          'unbind-wechat':this.Unbind,
          'unbind-ding':this.Unbind,
          'unbind-phone':this.unbindPhone
        }

        event_map[e]()
      },
      bindPhone(){
        this.formData = {}
        this.showBindingPhone = true
      },
      handleBindPhoneSuccess(){
        this.$Notice.success({title:"绑定成功"})
        this.showBindingPhone = false
         this.$store.commit("session/update_session",{phone:this.formData.phone})
         this.formData = {}
      },
      unbindPhone(){
        this.Confirm("确定解绑此手机号",()=>{
          // 增加密码验证
          this.$api.patch("sessions/self?q=unbind-phone").then(res=>{
            this.$Notice.success({title:"解绑成功"})
            this.$store.commit("session/update_session",{phone:null})
          })
        })
      },
      Unbind(){
        this.Error("暂不支持此操作")
      },
      disableAccount(){

      },
      editAccount(){
        this.formData = {
          user:this.session.user
        }
        this.modalPassword = true
      },
      prepareWechat(){
        var that = this
        this.showWeChat = true
        const appid = 'wx215ebbf66af92517'
        const host = "https://a.inbgz.com/oauth2"
        localStorage.setItem('BINDING_ID',this.session.token)
        
        this.$store.commit('session/save_login_url', "/core/self")
        var redirect_uri = encodeURIComponent(`${host}`)
        this.obj = null
        if(!this.obj){
           setTimeout(()=>{
          this.obj = new WxLogin({
          self_redirect: false,
          id: "bind_wechat_wrap",
          appid,
          scope: "snsapi_login",
          redirect_uri,
          style:"width:200px;height:200px;",
          state:'WECHAT'
        });
        },500)
        }
       


      },
      maskPhone(e){
        if(e){
          return e.slice(0,3)+'****'+e.slice(7)
        }
      },
      submit(){
        let model = this.formData
        this.$refs.form.validate(valid=>{
          if(valid){
            if(model.new_pass !== model.new_pass_again){
              this.Error('两次输入密码不一致')
              return
            }

            if(this.passwordStronger == 'weak'){
              this.Error('密码强度太低,请修改')
              return
            }

            this.$api.post('users/change-pwd',{old_pass:UTIL.MD5(model.old_pass),new_pass:UTIL.MD5(model.new_pass)}).then(()=>{
              this.$Notice.success({title:"修改成功"})
              this.formData = {}
              this.modalPassword = false
            }).catch(e=>{
              this.Error(e)
            })
          }
        })
      },
      sendVcode(){
        this.$store.dispatch('session/sendVCode',this.session.user).then(()=>{
          this.firstTime = false
          this.cooldown = 60
          if(this.cooldownInt){
            clearInterval(this.cooldownInt)
            this.cooldownInt = null
          }
          this.cooldownInt = setInterval(()=>{
            this.cooldown -- 
            if(this.cooldown == 0)
              clearInterval(this.cooldownInt)
          },1000)
        })
      },
      submitForget(){
        let model = this.formData
        this.$refs.formPasswordForget.validate(valid=>{
          if(valid){
            if(model.new_pass !== model.new_pass_again){
              this.Error('两次输入密码不一致')
              return
            }

            this.$api.post('users/change-pwd-forget',{code:model.code,new_pass:UTIL.MD5(model.new_pass)}).then(()=>{
              this.$Notice.success({title:"修改成功"})
              this.formData = {}
              this.modalPassword = false
            }).catch(e=>{
              this.Error(e)
            })
          }
        })
      }
    }
  }

</script>

<style lang="less">
.pass-stronger-box{
  display: flex;
  align-items: center;
  margin:10px 20px;
  >div{
    width:80px;
    height:10px;
    border:1px solid var(--border);
    overflow:visible;
    text-align: center;
    display: flex;
    align-items:center;
    justify-content: center;
    border-left:none;
    color:var(--hover-text);
    text-shadow: 1px 1px 1px var(--subtext2);
  }
  div:first-child{
    border-left:1px solid var(--border);
    border-top-left-radius: 5px;;
    border-bottom-left-radius: 5px;;
  }
  div:last-child{
    border-top-right-radius: 5px;;
    border-bottom-right-radius: 5px;;
  }

}
</style>