// directives/draggable.js
export default {
    bind(el, binding) {
      el.setAttribute('draggable', true); // 设置 draggable 属性
      el.addEventListener('dragstart', (event) => {
        el.classList.add('ugo-draggable');
        event.dataTransfer.setData('text', binding.value); // 将绑定的 id 存储在 dataTransfer 中
      });
      el.addEventListener('dragend', (event) => {
        // 可选：处理拖拽结束的逻辑
        el.classList.remove('ugo-draggable');
        console.log('拖拽结束');
      });
    }
  };
  