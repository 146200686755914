<template>
   <div class="flex-line flex-center">
      <template v-for="m in modes.filter(v=>v.key !== value)">
        <div class="flex-wrap flex-center icon-wrap" :key="m.id" @click="$emit('input',m.key)">
          <BaseIcon :icon="m.icon" :size="25" :color="m.color" />
        </div>
      </template>

    </div>
</template>

<script>
import BaseIcon from '@components/base/Icon'
  export default {
    components:{BaseIcon},
    props:{
      modes:{
        type:Array,
        default:()=>([])
      },
      value:{
        type:String
      }
    }
  }
</script>

<style lang="less" scoped>
.icon-wrap{
  width:40px;
  height:40px;
  margin:5px;
  border-radius:50%;
  background:#eee;
}

.icon-wrap:hover{
  filter:brightness(1.1);
}
</style>