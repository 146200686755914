<template>
  <div class="flex-col align-start" style="border:1px solid var(--border);padding:10px;">
        <template v-if="!mod">
            <BaseEmpty>未配置模块</BaseEmpty>
        </template>
        <template v-else-if="loading">
            <BaseLoading />
        </template>
        <template v-else>
            <div class="flex-wrap" style="width:100%;flex-wrap:wrap;overflow:hidden;">
            <template v-for="r in roles">
            
            <div :key="r.id" style="padding:10px;width:100%;margin-bottom:5px;">
                <div style="margin-bottom:10px">
                    <BaseIcon :icon="r.icon" style="margin-right:5px" /> {{ r.name }}
                </div>
                <div style="color:var(--subtext3);margin-bottom:15px;">
                    {{ r.description }}
                </div>
                <div class="flex-wrap" style="flex-wrap:wrap;">
                    <div class="flex-wrap flex-between rd-user-item" :key="u.id" v-for="u in r.users">
                        <BaseNameBoard :uid="u.user_id" />
                        <div><component :is="extraEditor" :role="r" :user_id="u.user_id" v-model="u.extra" /></div>
                        <div class="flex-wrap flex-center rd-icon-remove" @click="removeUser(r.id,u.user_id)">
                            <BaseIcon icon="md-close" color="#fff" :size="8" />
                        </div>
                </div>
         
                <div class="flex-wrap flex-center" style="margin:5px;">
                <Button icon="md-add" @click="showUserSelector=true;editing_role=r.id" size="default"></Button>
            </div>
                </div>
            </div>
            </template>
        </div>
        </template>

        <BaseUserSelectGroupedModal 
            v-model="showUserSelector" 
            multiple
            @on-select="handleAddRoleUser" 
        />
    </div>
</template>

<script>
import RowDepSelect from '@/components/appraisals/role_dep_select'
export default {
    components:{RowDepSelect},
    data(){
        return {
            roles:[],
            loading:true,
            showUserSelector:false,
            editing_role:null,
            extraEditor:'RowDepSelect'

        }
    },
    props:{
        mod:{
            type:String
        }
    },
    mounted(){
        this.getData()
    },  
    methods:{
        getData(){
            this.loading = true
            this.showUserSelector = false
        setTimeout(()=>{
            let url = 'roles?mod='+this.mod
            if(this.mod == 'apr'){
                url = 'oa/appraisal_permissions'
            }
            
            this.$api.get(url)
            .then(res=>{
                this.roles = res.data.data
            }).finally(()=>{
                this.loading = false
            })
        },500) 
        },
        handleAddRoleUser(e){
            this.$api.post(`/oa/appraisal_permissions/${this.editing_role}/users`,e).then(res=>{
                this.getData()
                this.Success({title:'添加成功'})
            })
        },
        removeUser(role_id,user_id){
            this.Confirm('确定删除此用户',()=>{
                this.$api.delete(`/oa/appraisal_permissions/${role_id}/users/${user_id}`).then(res=>{
                    this.getData()
                    this.Success({title:'操作成功'})
                })
            })
        }
    }
}
</script>

<style lang="less" scoped>
.rd-user-item{
    margin:5px;width:100%;background:var(--bg2);padding:5px 10px;border-radius:10px;position:relative;overflow:visible;
    min-width:200px;
    max-width:300px;
}

.rd-icon-remove{
    position:absolute;background:var(--error);border-radius:50%;width:15px;height:15px;right:-5px;top:-5px;
    display: none;
}

.rd-icon-remove:hover{
    filter:brightness(1.2);
}

.rd-user-item:hover{
    .rd-icon-remove{
        display: flex;
        cursor:pointer;
    }
}
</style>