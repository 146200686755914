<template>
  <div style="width:100%;position:relative;" >
    <div class="fp-panel" style="max-width:800px;background:var(--bg1);margin:0 auto;">
        <div class="fp-form fp-form-btn" @click="open()" style="padding-left:5px;"><BaseIcon icon="md-add" style="margin-right:5px;" size="15" /> 添加视图</div>
        <div class="flex-wrap" style="flex-wrap:wrap">
            <div class="fp-form" v-for="f in forms" @click="open(f.id)" :key="f.id">
            <BaseIcon icon="table" style="margin-right:5px;" size="15" /> {{ f.name }}
        </div>
        </div>
        
    </div>

    <Modal class-name="ugo-modal" v-model="modalSelector" width="1400" title="表单设计器" footer-hide >
      <template v-if="modalSelector">
        <BaseWinMenu style="background:#eee;border-bottom:1px solid var(--border);color:#333" :items="menus" :active="formView" @event="handleEvent">
          
            
        </BaseWinMenu>
        <div>
          <keep-alive>
            <template v-if="formView=='editor'">
              <FormEditor ref="editor" v-model="form" />
            </template>
             <template v-else-if="formView=='test'">
              <FormTest :form="form" />
            </template>
            <!-- <template v-else-if="formView=='flow'">
              <flow-editor :form="form" :flow="flow" />
            </template> -->
            <template v-else-if="formView=='print'">
              <FormPrint :form="form" />
            </template>
             <template v-else-if="formView=='view'">
             <FormHtml :form="form" />
            </template>
             <template v-else-if="formView=='config'">
               <FormOption :form="form" />
            </template>
         </keep-alive>
          </div>
        </template>
    </Modal>
  </div>
</template>

<script>
import FormEditor from '@/components/form/editor'
import FormTest from '@/components/form/test'
//import FlowEditor from '@/components/flow/editor'
import FormHtml from '@/components/form/html'
import FormOption from '@/components/form/option'
import FormPrint from '@/components/form/print'
//import Explorer from '@/components/explorer'
  import {cloneDeep} from 'lodash'
export default {
    data(){
      return {
        loadingList:false,
        items:[],
        form:{id:'create',
            name:'新建表单',
            fields:[]},
            flow:{nodes:[],edges:[]},
        forms:[],
        formView:'editor',
        modalSelector:false
      }
    },
    components:{
      FormEditor,FormTest,FormHtml,FormOption,FormPrint
    },
    props:{
      option:{
        type:Object,
        default:()=>({})
      }
    },
    computed:{
      menus(){
        const items = [{
          icon:'edit',
          name:'表单设计',
          key:'editor'
        },
        // {
        //   name:'数据展示',
        //   key:'view'
        // },{
        //   name:'打印模板',
        //   key:'print'
        // },{
        //   name:'高级选项',
        //   key:'config'
        // },
        {
          icon:'richangbaoji01',
          name:'表单测试',
          key:'test'
        },{
          icon:'save',
          name:'保存',
          right:true,
          key:'save'
        },{
          icon:'md-trash',
          name:'删除此表单',
          right:true,
          key:'remove'
        },]
        return items
      }
    },
  
    watch:{
      option:{
        handler(v,old){
            if(v && (!old || v.flowId !== old.flowId)){
                this.timer = this.$api.afterLogin(()=>{
                    this.getForms(v.flowId)
                },this.timer)
            }
        },
        deeply:true,
        immediate:true
      },
    },
    methods:{
        getForms(flowId){
            this.$api.get('forms?task_id='+flowId).then(res=>{
                this.forms = res.data.data
               this.$emit('input',this.forms)
            })
        },
      getFormList(){
        this.loadingList  = true
        this.$api.get('/forms').then(res=>{
          this.items = res.data.data
        }).finally(e=>{
          this.loadingList=false
        })
      },
      open(id){
        this.getData(id)
        console.log(this.form)
        this.modalSelector=true;this.formView='editor'
      },
      getData(id){
        this.current_id = id
        if(id){
          this.$api.get(`forms/${id}`).then(res=>{
            this.form = res.data.data
          })
        }else{
            this.form = {id:'create',
              name:'新建表单',
              config:{},
              fields:[]}
          }
        },
      
      handleSave(){
         this.$refs.editor.save()
          this.form.fields.forEach((v,i)=>{
            v.order = i+1
          })
          this.form.task_id = this.option.flowId
          if(this.form.id && this.form.id != 'create')
                this.$api.patch(`/forms/${this.form.id}`,this.form).then(res=>{
                let index = this.forms.findIndex(f=>f.id == this.form.id)
                this.forms.splice(index,1,this.form)
                this.$emit('input',this.forms)
                this.modalSelector = false
            })
          else{
            this.$api.post(`/forms`,this.form).then(res=>{
              let {id} = res.data.data
              this.form.id = id
              this.forms.splice(0,0,this.form)
              this.$emit('input',this.forms)
              this.modalSelector = false
            })
          }
      },
      handleDelete(){
        this.Confirm("确定删除此表单",()=>{
            this.$api.delete('/forms/'+this.current_id).then(res=>{
                let index = this.forms.findIndex(f=>f.id == this.current_id)
                this.forms.splice(index,1)
                this.current_id = null
                this.modalSelector = false
            })
        })
      },
      handleEvent(e){
        console.log(e)
        if(e == 'save'){
          this.handleSave()
        }else if(e=='remove'){
            this.handleDelete()
        }else{
          this.formView = e
          if(e == 'open' && this.items.length == 0){
            this.getFormList()
          }
        }
        
      }
    }
}
  
</script>


<style lang="less" scoped>
.ugo-modal{
  .ivu-modal-body{
    padding:0;
  }
}
.fp-panel{
    border: 3px solid var(--border);
    padding: 0 5px;
    background:var(--bg1);
    border-radius:10px;
    box-shadow: 2px 2px 3px 3px var(--border);
    display: flex;
    flex-wrap:wrap;
    align-items: center;
}

.fp-form{
    display: flex;
    align-items: center;
    justify-content: center;
    height:26px;
    
    background:#00000022;
    min-width:80px;
    color:var(--hover-text);
    white-space: nowrap;
    margin-right:2px;
    padding:0 10px;
}

.fp-form:hover{
    background:#ffffff11;
    cursor: pointer;
}

.fp-form-btn{
    background:linear-gradient(to bottom,#ffffff11,#00000022);
}

.fp-form:active{
    background:linear-gradient(to top,#ffffff11,#00000022);
}
</style>