<template>
  <div class="contianer flex-line align-stretch" style="height:100%;position:relative;">
    <div class="l-sider">
      <div class="flex-wrap flex-line l-add" @click="handleAdd">
        <BaseIcon icon="md-add" style="flex-shrink:0;margin:5px;" size="25" />
        <div>新增巡检记录</div>
      </div>
      <div style="height:calc(100% - 60px);overflow-y:auto;background:var(--bg3);border-top:2px solid var(--border)">
        <template v-for="item in items">
          <div class="l-item flex-wrap flex-between flex-line" :class="{'l-item-selected':selected.id==item.id}" @click="selected=item" :key="item.id">
            <div>
            <div style="font-size:14px;">{{moment(item.date).format("YYYY-MM-DD")}}</div>
            <div>{{item.description}}</div>
            </div>
            <div  style="width:20px;flex-shirnk:0">
              <BaseIcon icon="md-trash" class="l-close" @click.stop="handleDelete(item)" />
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="container" style="height:100%">
      <template v-if="selected.url">
    <iframe :src="selected.url" frameborder="no" class="container" ></iframe>
    </template>
    <template v-else>
      <BaseEmpty>
        还未上传任何无人机巡检记录
      </BaseEmpty>
    </template>
    </div>

    <Drawer title="新增无人机巡检记录" v-model="showEditor" :width="400">
      <div>
      <Form ref="form" :model="formData">
        <FormItem label="记录日期" required prop="date">
          <BaseDate v-model="formData.date" clearable placeholder="请选择" />
        </FormItem>
        <FormItem label="记录描述" prop="description">
          <Input v-model="formData.description" clearable placeholder="请输入该次巡检的描述" type="textarea" :rows="4" max-length="255" />
        </FormItem>
        <FormItem label="展示地址" required prop="url">
          <Input v-model="formData.url" clearable placeholder="请输入720yun的链接地址" />
        </FormItem>
        <div class="flex-wrap flex-right">
          <Button @click="handleSave" type="primary" long style="margin-right:10px" icon="md-checkmark">提交</Button>
          <Button long @click="showEditor=false">取消</Button>
        </div>
      </Form>
      </div>
    </Drawer>
  </div>
</template>

<script>
  import moment from 'moment'
  import UTIL from '@/utils'
  export default {
    data(){
      return {
        showEditor:false,
        formData:{
          date:moment().format()
        },
        selected:{},
        items:[]
      }
    },
    computed:{
      pid(){
        return this.$route.params.id
      }
    },
    mounted(){
      setTimeout(()=>{
        this.getData()
      },500)
    },
    methods:{
      getData(){
        this.$api.get(`/projects/${this.pid}/inspect_records`).then(res=>{
          this.items = res.data.data
        })
      },
      handleAdd(){
        this.showEditor = true
      },
      handleSave(){
        this.$refs.form.validate(valid=>{
          if(valid){
            this.$api.post(`/projects/${this.pid}/inspect_records`,this.formData).then(res=>{
              let newItem = {...this.formData}
              UTIL.updateItem(this.items,newItem)
              this.formData = {date:this.formData.date}
              this.showEditor = false
            })
          }
        })
        
      },
      handleDelete(item){
        this.Confirm(`确定删除该条记录<span style="margin:0 2px;font-weight:bold;color:red">${moment(item.date).format("YYYY-MM-DD")}</span>`,()=>{
          this.$api.delete(`/projects/${this.pid}/inspect_records/${item.id}`).then(res=>{
            this.items.splice(this.items.findIndex(t=>t.id == item.id),1)
          })
        })
      }
    }
  }
</script>

<style lang="less" scoped>
.l-sider{
  width:300px;
  border-right:1px solid var(--border);
}

.l-add{
  height:50px;
  background:var(--bg3);
  color:var(--text3);
}
.l-add:hover{
  background:var(--primary);
  color:var(--hover-text);
  cursor: pointer;
}
.l-add:active{
  position: relative;
  bottom:-1px;
  right:-1px;
}

.l-item{
  min-height:50px;
  padding:5px;
  border-bottom:1px solid var(--border);

  .l-close{
    display: none;
  }
}

.l-item:hover{
  background:var(--primary);
  cursor: pointer;
  filter:brightness(1.3);
  color:#fff;

  .l-close{
    display: flex;
    align-items: center;
    justify-content: center;
    padding:3px;
  }

  .l-close:hover{
    background:#ffffffaa;
    padding:3px;
     align-items: center;
    justify-content: center;
    color:var(--text3);
    border-radius: 50%;
  }
}

.l-item-selected,.l-item-selected:hover{
  filter:none;
  background:var(--primary);
  color:#fff;
}

</style>