<style lang="less">
.pbi-item{
  min-width:calc(25% - 10px);
  margin:3px 5px;color:#aaa;
  background:#aaaaaa33;
  padding-right:10px;
}
.pbi-item:hover{
  background:#dddddd33;
  transition: all 0.3s;
}
</style>
<template>
<BaseSegment title="项目信息" style="margin:0">
 <div class="flex-wrap split10" slot="extra">
				<BaseBoardButton icon="md-create" name="编辑" @on-click="edit" />

</div>
  <div style="width:100%;position:relative;">
 
      <div class="flex-wrap flex-line align-start" style="font-size:14px;color:var(--text3);padding:10px;">
   
    <div style="width:100%">
      
    <BaseBoardSubTitle name="合同信息" />
    <CellGroup>
    <Cell title="项目状态" style="background:var(--bg3);color:var(--text3);border-bottom:1px dashed var(--border)">
      <div class="flex-wrap">项目状态</div>
      <div slot="extra"><BaseState :value="data.status"></BaseState></div>
    </Cell>
    <Cell title="" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
      <div class="flex-wrap">业务类型</div>
      
    <div slot="extra" style="font-size:14px">{{data.type || '-'}}</div>
    </Cell>
    <Cell title="签约金额" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
       <div class="flex-wrap">签约金额  <BaseIcon icon="lock" style="margin-left:3px" size="14" /></div>
    <BaseAmount style="font-size:14px" full slot="extra" :value="data.signed_amount"></BaseAmount> 
    </Cell>
    <Cell title="调整金额" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
      <div class="flex-wrap" @click="showAdjust=true" slot="extra" style="cursor:pointer">
    <BaseAmount style="font-size:14px" signed full :value="data.adjust_amount"></BaseAmount>
    <BaseIcon icon="ios-arrow-forward" style="margin-left:5px" color="var(--subtext3)" />
      </div>
    </Cell>
    <Cell title="合同总额" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
      <div class="flex-wrap">合同总额  <BaseIcon icon="lock" style="margin-left:3px" size="14" /></div>
    <BaseAmount style="font-size:14px" full slot="extra" :value="data.amount"></BaseAmount>
    </Cell>

    <Cell title="中标日期" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
        <div slot="extra" style="font-size:14px">{{data.bid_at?moment(data.bid_at).format("LL"):'-'}}</div>
    </Cell>
    
      <Cell title="签订日期" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
        <div slot="extra" style="font-size:14px">{{data.signed_at?moment(data.signed_at).format("LL"):'-'}}</div>
    </Cell>
    <div class="flex-wrap flex-between" style="background:var(--bg3);border-bottom:1px dashed var(--border);padding:0 5px;">
        <div style="padding-left:12px">业主单位</div>
        <div slot="extra" style="font-size:14px;max-width:200px;color:var(--subtext3);padding:5px 0;padding-right:10px;text-align:right">{{data.partA || '-'}}</div>
    </div>
    <Cell title="联系人" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
        <div slot="extra" style="font-size:14px">{{data.contact_name || '-'}}</div>
    </Cell>
    <Cell title="联系电话" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
        <div slot="extra" style="font-size:14px">{{data.contact_phone || '-'}}</div>
    </Cell>
    <div class="flex-wrap flex-between" style="background:var(--bg3);border-bottom:1px dashed var(--border);padding:0 5px;">
        <div style="padding-left:12px">投资单位</div>
        <div slot="extra" style="font-size:14px;max-width:200px;color:var(--subtext3);padding:5px 0;padding-right:10px;text-align:right">{{data.institution_unit || '-'}}</div>
    </div>
    <Cell title="相关附件" style="background:var(--bg3);">
      <span slot="extra" v-if="data.files && data.files.length > 0">({{data.files?data.files.length:'-'}})</span>
      <span v-else slot="extra">未上传</span>
    </Cell> 
    
    <template v-for="f in data.files">
      <div class="flex-wrap flex-between"  style="background:var(--bg3);border-bottom:1px dashed var(--border);padding:5px 15px;" :key="f.id">
        <div><BaseIcon icon="md-document" color="var(--primary)" style="margin-right:10px" />{{f.type}}</div>
        <div slot="extra" style="font-size:14px"><a v-if="f.url" :href="f.url" target="_new">点击下载</a><template v-else>-</template>
      </div>
      
    </div>
      </template>
    <template  v-if="data.splited">
      <BaseBoardSubTitle name="服务内容"  style="margin-top:20px;margin-bottom:5px;" />
      <template v-for="t in data.condition_groups">
      <Cell :key="t.id" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
        <div><BaseIcon style="margin-right:10px" icon="md-pie" color="var(--primary)" />{{t.business_type}}</div>
        <BaseAmount  slot="extra" style="font-size:14px"  :value="t.amount" />
      </Cell>
      </template>
    </template>
    </CellGroup>
    </div>

    <div style="width:100%;margin-left:30px;">
         <BaseBoardSubTitle name="建筑信息" />
         <CellGroup>
    <Cell title="建筑类型" style="background:var(--bg3);color:var(--text3);border-bottom:1px dashed var(--border)">
      <div slot="extra">{{data.buildingCategory}}</div>
    </Cell>
    <Cell title="总建筑面积" style="background:var(--bg3);border-bottom:1px dashed var(--border)">

      
    <div slot="extra" style="font-size:14px">{{data.buildingArea || '-'}} <span style="color:var(--subtext3);font-size:12px;margin-left:5px;">平米</span></div>
    </Cell>
    <Cell title="建筑高度" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
      <div slot="extra">
    {{data.buildingHeight || '-'}}
    <span style="color:var(--subtext3);font-size:12px;margin-left:5px;">米</span>
    </div>
    </Cell>
    <Cell title="单体数量" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
      
     <div slot="extra">
    {{data.buildingCount || '-'}}
    <span style="color:var(--subtext3);font-size:12px;margin-left:5px;">幢</span>
    </div>
    </Cell>
    <Cell title="地下室层数" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
      <div slot="extra">
    {{data.basementLevels || '-'}}
    <span style="color:var(--subtext3);font-size:12px;margin-left:5px;">层</span>
    </div>
    </Cell>
    <div class="flex-wrap flex-between" style="background:var(--bg3);border-bottom:1px dashed var(--border);padding:0 5px;">
        <div style="padding-left:12px">项目地址</div>
        <div slot="extra" style="font-size:14px;max-width:200px;color:var(--subtext3);padding:5px 0;padding-right:10px;text-align:right">{{data.buildingAddress || data.address || '-'}}</div>
    </div>
    </CellGroup>

        
     <BaseBoardSubTitle name="管理信息" style="margin-top:20px" />
     <CellGroup>
      <Cell title="项目简称" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
        <div slot="extra" style="font-size:14px">{{data.shortname || '-'}}</div>
    </Cell>
     <Cell title="服务起始" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
        <div slot="extra" style="font-size:14px">{{data.started_at?moment(data.started_at).format("LL"):'-'}}</div>
    </Cell>
    
      <Cell title="开工日期" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
        <div slot="extra" style="font-size:14px">{{data.operated_at?moment(data.operated_at).format("LL"):'-'}}</div>
    </Cell>
     <Cell title="竣工日期" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
        <div slot="extra" style="font-size:14px">{{data.completed_at?moment(data.completed_at).format("LL"):'-'}}</div>
    </Cell>
     <Cell title="项目结束" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
        <div slot="extra" style="font-size:14px">{{data.finished_at?moment(data.finished_at).format("LL"):'-'}}</div>
    </Cell>
    <Cell title="桩基施工完成" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
        <div slot="extra" style="font-size:14px">{{data.pf_finished_at?moment(data.pf_finished_at).format("LL"):'-'}}</div>
    </Cell>
    <Cell title="结构中验完成" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
        <div slot="extra" style="font-size:14px">{{data.structure_mid_checked_at?moment(data.structure_mid_checked_at).format("LL"):'-'}}</div>
    </Cell>
    <Cell title="实际竣工验收" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
        <div slot="extra" style="font-size:14px">{{data.actual_completed_checked_at?moment(data.actual_completed_checked_at).format("LL"):'-'}}</div>
    </Cell>
    <Cell title="当前进度描述" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
        <div slot="extra" style="font-size:14px">{{data.workpoint || '-'}}</div>
    </Cell>
    <Cell title="组建项目成果" style="background:var(--bg3);margin-top:10px;">
      <span slot="extra" v-if="data.create_files && data.create_files.length > 0">({{data.create_files?data.create_files.length:'-'}})</span>
      <span v-else slot="extra">未上传</span>
    </Cell> 
    
    <template v-for="f in data.create_files">
      <div class="flex-wrap flex-between"  style="background:var(--bg3);border-bottom:1px dashed var(--border);padding:5px 15px;" :key="f.id">
        <div><BaseIcon icon="md-document" color="var(--primary)" style="margin-right:10px" />{{f.name}}</div>
        <div slot="extra" style="font-size:14px"><a v-if="f.url" :href="f.url" target="_new">点击下载</a><template v-else>-</template>
      </div>
      
    </div>
      </template>
    
     <Cell title="负责人" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
        <div slot="extra" style="font-size:14px"><BaseNameBoard :uid="data.charger_id"></BaseNameBoard></div>
    </Cell>
     <Cell title="协助人" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
        <div slot="extra" style="font-size:14px"><template v-if="data.assistants && data.assistants.length == 0">-</template><BaseNameBoards :uids="data.assistants" /></div>
    </Cell>
     <Cell title="关联平台" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
        <div slot="extra" style="font-size:14px"><a @click="RouteTo('http://zzlatm.gicp.net:10000',{},true)" v-if="data.zzl_id">点击打开</a><span v-else>-</span></div>
    </Cell>
    </CellGroup>
    
    </div>
   
    <div style="width:420px;margin-left:30px;flex-shrink:0;">
      
    <CmProjectImage :data="data" />
    
    <CmProjectMap :data="[data]" style="margin-top:10px" v-if="data.poiname" />
    
    <div style="font-size:14px;white-space:pre-wrap;padding:10px 15px;background:var(--bg2);margin-top:10px;">
          <div style="white-space:nowrap;font-weight:bold;">项目简介</div>
          <div style="width:100%;flex-shrink:1">{{data.description?(showFullDescription?data.description:data.description.slice(0,150)):'-'}}

           <div class="flex-wrap flex-right" style="margin-top:10px" v-if="data.description && data.description.length > 150"><a @click="showFullDescription=!showFullDescription">{{showFullDescription?'折叠信息':'显示更多'}}</a></div> 
          </div>
          </div>
     </div>
      
    </div>
    <Modal v-model="showAdjust" title="调整金额" footer-hide :width="800">
        <Card>
          <CellGroup>
             <Cell title="签约金额" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
       <div class="flex-wrap">签约金额  <BaseIcon icon="lock" style="margin-left:3px" size="14" /></div>
    <BaseAmount style="font-size:14px" slot="extra" :value="data.signed_amount"></BaseAmount>
    </Cell>
    <Cell title="调整金额" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
      <div class="flex-wrap" @click="showAdjust=true" slot="extra" style="cursor:pointer">
    <BaseAmount style="font-size:14px"  :value="data.adjust_amount"></BaseAmount>
    <BaseIcon icon="ios-arrow-forward" style="margin-left:5px" color="var(--subtext3)" />
      </div>
    </Cell>
    <Cell title="合同总额" style="background:var(--bg3);border-bottom:1px dashed var(--border)">
      <div class="flex-wrap">合同总额  <BaseIcon icon="lock" style="margin-left:3px" size="14" /></div>
    <BaseAmount style="font-size:14px" slot="extra" :value="data.amount"></BaseAmount>
    </Cell>
          </CellGroup>
        </Card>
        <BaseBoardSubTitle name="调整金额详情" style="margin-top:20px;margin-bottom:10px;" />
        <CmProjectAdjustRecords :value="data" />
    </Modal>

    <Drawer v-model="showModal" title="修改基础信息" width="800">
      <div style="padding:10px">
        <Form class="ugo-form" :label-width="120">
          <BaseSegment title="合同信息" :disabled="true">
          <BaseBoardButton type="primary" name="提交" slot="extra" size="small" icon="md-checkmark" @on-click="submit" />
          
             <Row>
            <Col :span="12">
              <FormItem label="中标日期">
                <BaseDate :editable="true" v-model="formData.bid_at" transfer placeholder="选择日期">
              
                </BaseDate>
              </FormItem>
            </Col>
            <Col :span="12">
              <FormItem label="签约日期">
                <BaseDate :editable="true" v-model="formData.signed_at" transfer placeholder="选择日期">
               
                </BaseDate>
              </FormItem>
            </Col>
          </Row>
           <FormItem label="业主单位">
             <Input v-model="formData.partA" style="width:360px" placeholder="业主单位">
            </Input>
          </FormItem>
          <Row>
            <Col :span='10'>
            <FormItem label="联系人"><Input v-model="formData.contact_name" style="width:140px" placeholder="请输入" /></FormItem></Col>
            <Col :span='12'> <FormItem label="联系电话"><Input v-model="formData.contact_phone" style="width:200px" placeholder="请输入" /></FormItem></Col>
          </Row>
          <FormItem label="投资单位">
             <Input v-model="formData.institution_unit" style="width:360px" placeholder="投资单位">
            </Input>
          </FormItem>
          <div style="padding-left:50px;">
            <div style="margin-bottom:10px">合同附件</div>
            <BaseFlexTable v-model="formData.files" :columns="fileTableColumns" />
          </div>
          </BaseSegment>
          <BaseSegment title="调整金额" desc="请注意补充调整金额记录"  :disabled="true">
           <BaseBoardButton type="primary" name="提交" slot="extra" size="small" icon="md-checkmark" @on-click="submit" />
          <Row>
           <Col :span="24">
              <FormItem label="调整金额" :label-width="80">
                <div class="flex-wrap">
                <Input v-model="formData.adjust_amount" number style="width:140px;margin-right:10px;" /><BaseAmount signed full :value="formData.adjust_amount" style="margin-right:5px" /> 元
                </div>
              </FormItem>
            </Col>
            </Row>
            <BaseBoardSubTitle name="调整金额记录" />
          <CmEditorProjectAdjusts v-model="formData" />
          </BaseSegment>
          <BaseSegment title="项目建筑信息"  :disabled="true">
          <BaseBoardButton type="primary" name="提交" slot="extra" size="small" icon="md-checkmark" @on-click="submit" />
            <Row>
            <Col :span="12">
            <FormItem label="建筑类型" message="123">
            <AutoComplete v-model="formData.buildingCategory" style="width:160px" placeholder="填写建筑类型" />
          </FormItem>
            </Col>
            <Col :span="12">
            <FormItem label="总建筑面积">
            <Input v-model="formData.buildingArea" style="width:160px" placeholder="填写建筑类型">
              <template #append>
                  <span>平米</span>
                </template>
            </Input>
          </FormItem>
            </Col>
          </Row>
          <Row>
            <Col :span="12">
            
          <FormItem label="建筑高度">
            <Input v-model="formData.buildingHeight" style="width:160px" placeholder="填写建筑类型">
               <template #append>
                  <span>米</span>
                </template>
            </Input>
          </FormItem>
            </Col>
            <Col :span="12">
             
          <FormItem label="单体数量">
            <Input v-model="formData.buildingCount" style="width:160px" placeholder="填写内容">
               <template #append>
                  <span>幢</span>
                </template>
            </Input>
          </FormItem>
         
          
            </Col>
          </Row>
          <Row>
            <Col :span="12">  <FormItem label="地下室层数">
            <Input v-model="formData.basementLevels" style="width:160px" placeholder="填写内容">
               <template #append>
                  <span>层</span>
                </template>
            </Input>
          </FormItem></Col>
          </Row>
          
          
         
         
          
         
           <FormItem label="项目地点">
            <Input v-model="formData.buildingAddress" placeholder="填写内容" />
          </FormItem>
          <FormItem label="地图定位">
               <BaseMapSelect :data="{
                poiname:formData.poiname,
                address:formData.address
                }" @input="handleAssignLocation" />
              </FormItem>

          <FormItem label="效果图">
               <BaseImageUpload v-model="formData.images" :option="{json:true}" editable />
              </FormItem>
             <FormItem label="项目简介">
               <Input type="textarea" :rows="4" v-model="formData.description" editable />
              </FormItem>
               </BaseSegment>
               <BaseSegment title="管理信息"  :disabled="true">
                <BaseBoardButton type="primary" name="提交" size="small" slot="extra" icon="md-checkmark" @on-click="submit" />


        <Row>
          <Col :span="20">
           <FormItem label="项目简称">
             <Input v-model="formData.shortname" style="width:360px" placeholder="项目简称" :max-length="64">
            </Input>
          </FormItem></Col>
        </Row>
                <Row><Col :span="12">
                  <FormItem label="项目状态">
            <Select v-model="formData.status" style="width:140px">
              <Option :key="'status_'+s" v-for="s in status" :value="s">{{s}}</Option>
              </Select>
            
          </FormItem>
                </Col><Col :span="12">
                <FormItem label="项目类型">
            <Select v-model="formData.type" style="width:140px">
              <Option :key="'status_'+s" v-for="s in types" :value="s">{{s}}</Option>
              </Select>
            
          </FormItem>
                </Col></Row>
                <Row>
            <Col :span="12">
              <FormItem  label="开始日期">
                <BaseDate :editable="true" v-model="formData.started_at" transfer placeholder="选择日期">
              
                </BaseDate>
              </FormItem>
            </Col>
            <Col :span="12">
              <FormItem label="结束日期">
                <BaseDate :editable="true" v-model="formData.finished_at" placeholder="选择日期">
              
                </BaseDate>
              </FormItem>
            </Col>
            <Col :span="12">
              <FormItem label="开工日期">
                <BaseDate :editable="true" v-model="formData.operated_at" transfer placeholder="选择日期">
                
                </BaseDate>
              </FormItem>
            </Col>
            <Col :span="12">
              <FormItem label="竣工日期">
                <BaseDate :editable="true" v-model="formData.completed_at" transfer placeholder="选择日期">
                
                </BaseDate>
              </FormItem>
            </Col>
            <div style="border-top:2px dashed var(--border);width:100%;margin-bottom:15px"></div>
            <Col :span="12">
              <FormItem label="桩基施工完成">
                <BaseDate :editable="true" v-model="formData.pf_finished_at" transfer placeholder="选择日期">
                
                </BaseDate>
              </FormItem>
            </Col>
            <Col :span="12">
              <FormItem label="结构中验">
                <BaseDate :editable="true" v-model="formData.structure_mid_checked_at" transfer placeholder="选择日期">
                
                </BaseDate>
              </FormItem>
            </Col>
            <Col :span="12">
              <FormItem label="实际竣工验收">
                <BaseDate :editable="true" v-model="formData.actual_completed_checked_at" transfer placeholder="选择日期">
                
                </BaseDate>
              </FormItem>
            </Col>
          </Row>
          <Row>
            
            <div style="padding-left:25px;width:100%;">
            <div style="margin-bottom:10px">组建项目成果</div>
            <BaseFlexTable v-model="formData.create_files" style="width:100%" :columns="createFileTableColumns" />
          </div>
            
            <Col :span="24">
            <FormItem label="当前进度描述">
            <Input v-model="formData.workpoint" style="width:300px" placeholder="请输入">
            </Input>
            <BaseAlert type="primary">未录入签约计划的项目建议填写此项，描述项目当前进度</BaseAlert>
          </FormItem>
            </Col>
            </Row>
          <Row>
            <Col :span="24">
            <FormItem label="施工合同总额">
                  <div class="flex-wrap">
            <Input v-model="formData.building_production" number style="width:200px;margin-right:20px;" placeholder="建安工程量">
            </Input>
           <BaseAmount :value="formData.building_production" style="margin-right:5px" /> 元</div>
            <BaseAlert type="subtext3">此项为建安工程量计算的关键数据</BaseAlert>
          </FormItem>
            </Col>
            </Row>
          
          <FormItem label="管理协助人">
            <BaseUserSelectGrouped style="background:#fff;border-radius:8px;" v-model="formData.assistants" :option="{multiple:true}" />
            
             <BaseAlert type="subtext3">协助人与项目负责人同等权限，可协助项目负责人填报合约模块数据</BaseAlert>
          </FormItem>
          
          <FormItem label="关联平台项目">
            <Select style="background:#fff;border-radius:8px;max-width:500px;" v-model="formData.zzl_id" filterable clearable transfer>
                <Option v-for="p in zzl_projects" :key="p.id" :value="p.id">
                  {{p.name}}
                </Option>
            </Select>
             <BaseAlert type="subtext3">同公司项目管理/监理平台进行项目绑定,以便后续数据互通</BaseAlert>
          </FormItem>
               </BaseSegment>
        </Form>
        <div class="flex-wrap flex-right split10">
          <Button type="primary" @click="submit">提交</Button>
          <Button @click="showModal=false">取消</Button>
        </div>
      </div>
    </Drawer>
  </div>
  </BaseSegment>
</template>

<script>
import mixinResponsable from '@/mixins/responsable'
import moment from 'moment'
import util from '@/utils'
import {cloneDeep} from 'lodash'
import {mapGetters} from 'vuex'
import CmProjectImage from '@/components/cm/project/ProjectImage'
import CmEditorProjectAdjusts from "@/components/cm/EditorAdjustRecords"
  import CmProjectMap from '@/components/cm/project/ProjectMap'
  import CmProjectAdjustRecords from '@/components/cm/AdjustRecords'
  export default {
    name:'ProjectInfo',
     panelInfo:{
      name:'项目参数',
      minWidthSpan:2
    },
    data(){
      return {
        showModal:false,
        showAdjust:false,
        showFullDescription:false,
        zzl_projects:[],
        status:['准备中','进行中','已竣工','已结束','已关闭'],
        types:['全过程咨询','项目管理','房建监理','市政监理','造价咨询','BIM咨询','招标代理','对外合作','其他'],
        formData:{}
      }
    },
    components:{CmProjectImage,CmProjectMap,CmEditorProjectAdjusts,CmProjectAdjustRecords},
    mixins:[mixinResponsable],
    props:['data','env'],
    computed:{
      ...mapGetters('session',['users']),
      project(){
        return this.data || {}
      },
      createFileTableColumns(){
        return [{
          name:"序",
          key:'index',
          control:"index",
          span:1
        },{
          name:"拖",
          key:'drag',
          control:"drag",
          span:1
        },{
          key:"url",
          name:"文件",
         
          span:8,
          control:"BaseFileUpload"
        },{
          key:"name",
          name:"备注",
          span:10,
          control:"Input",  
          option:{
            size:"small",
            number:true,
            clearable:true
          }
        }]
      },
      fileTableColumns(){
        return [{
          name:"序",
          key:'index',
          control:"index"
        },{
          name:"拖",
          key:'drag',
          control:"drag"
        },{
          key:"type",
          name:"附件类型",
          span:4,
          align:"left",
          control:"AutoComplete",  
          option:{
            size:"small",
            transfer:true,
            data:["中标通知书","合同文档"],
            clearable:true,
          }
        },{
          key:"name",
          name:"备注",
          span:6,
          control:"Input",  
          option:{
            size:"small",
            number:true,
            clearable:true
          }
        },{
          key:"url",
          name:"文件",
         
          span:4,
          control:"BaseFileUpload"
        }]
      },
    },
    methods:{
      getZZLProjectList(){
        this.$api.get("cm/projects?q=zzl").then(res=>{
          this.zzl_projects = res.data.data
        })
      },
      formatSalary(e){
        return util.formatSalary(e)
      },
      edit(){
        const FIELDS = [
          'address',
          'lat',
          'type',
          'lng',
          'status',
          'shortname',
          'poiname',
          'files',
          'create_files',
          'city',
          'file',
          'zzl_id',
          'institution_unit',
          'contract_adjusts',
          'contact_name',
          'contact_phone',
          'assistants',
          'buildingAddress',
          'building_production',
          'adjust_amount',
          'buildingArea',
          'buildingHeight',
          'buildingCategory',
          'basementLevels',
          'buildingCount',
          'started_at',
          'finished_at',
          'completed_at',
          'operated_at',
          "bid_at",
          'bid_file',
          "signed_at",
          "images",
          "splited",
          "description",
          "workpoint",
          "partA",
          "contract_adjusts",
          "pf_finished_at",
          "structure_mid_checked_at",
          "actual_completed_checked_at"
        ]

        let formData = {        
        }
        FIELDS.forEach(key=>{
          let value = this.project[key]
          if(value !== undefined){
            formData[key] = (typeof value == "object")?cloneDeep(value):value
          }
        })

        this.formData = formData
        this.showModal = true
      },
      submit(){

        let updated = util.compare_patch_object(this.project,this.formData)
       

        if(Object.keys(updated) == 0){
          this.$emit("on-update")

        }
        this.$api.patch("projects/"+this.project.id,updated).then(res=>{
          this.$Notice.success({title:"修改成功"})
          this.showModal = false
          if(this.formData.images && typeof this.formData.images == 'string'){
           this.formData.images = JSON.parse(this.formData.images)
          }
          Object.assign(this.data,this.formData)
          this.$emit("on-update")
          this.formData = {}
        })
      },
      handleAssignLocation(e){
        Object.assign(this.formData,e);
        if(!this.formData.buildingAddress)
          this.$set(this.formData,'buildingAddress',e.address)
      }
    },
    mounted(){
      this.$store.dispatch("session/getUsers")
      this.getZZLProjectList()
    }
  }
</script>

<style lang="less" scoped>
img{
  filter:hue-rotate(-20deg);
}

.pd-note{
  position: absolute;
  left:10px;
  top:10px;
  right:10px;
  color:#aaa;
  font-size:20px;
   background:#333333aa;
   padding:10px;
  border-radius: 5px;
}

.pd-weather{
  display:flex;
  align-items: center;
  color:#ddd;
  position: absolute;
  left:10px;
  bottom:10px;
  
 background:#333333aa;
  height:60px;
  padding:10px;
  border-radius: 5px;
}

.pd-time{
   display:flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom:10px;
  right:10px;
  height:60px;
  background:#333333aa;
  padding:10px;
  border-radius: 5px;
  
}

.pd-ip-mask{
  position: absolute;
  left:0;
  bottom:0;
  right:0;
  top:0;
  background:#33333377;
}

.pd-ip-title{
  font-size:20px;
  color:#fff;
  text-shadow: none;

}
.pd-ip-sub{
  font-size:14px;
  color:#ddd;
}

.pd-ip-deg{
  font-weight: bold;
  font-size:40px;
}

.pm-glass{
  .pm-ip{
    opacity:0.8;
  }
}
</style>