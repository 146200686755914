<style lang="less">
.pm-cell{
  margin:3px 0;
  font-size:0.8rem;
  .l-label{
    color:#888;
    padding-left:5px;
  }

  .l-value{
    color:var(--primary);
  }
  
}

.editable-avatar:hover{
  cursor: pointer;
  .ea-icon{
    filter:brightness(1.1);
  }
}
</style>

<template>
  <div style="background:var(--bg3);height:100%;padding:5px;">
    <div class="flex-wrap flex-line" style="padding:10px;background:var(--bg2);position:relative;">
      <div class="flex-wrap flex-center editable-avatar" style="position:relative;width:50px;height:50px;">
        <BaseAvatar :src="session.avatar" style="width:40px;height:40px;flex-shrink:0;" />
        <BaseIcon class="ea-icon" icon="md-checkmark" :size="12" style="position:absolute;right:0px;top:0px;background:var(--primary);color:var(--text1);padding:3px;border-radius:50%;" />
      </div>
      
      <div class="flex-col align-start" style="margin-left:10px">
        <div style="font-size:18px;">{{session.name}} 
          <div style="font-size:12px;color:var(--primary);cursor:pointer;" @click="handleEditUserInfo">编辑资料</div></div>
        <div style="font-size:0.8rem;color:#aaa;">{{session.user}}</div>
      </div>
      <div style="position:absolute;right:0;top:calc(50% - 10px);">
         
      </div>
    </div>

    <div style="padding:10px;font-size:0.8rem;padding-top:0;">
       <div class="flex-wrap flex-between pm-cell" style="margin-top:5px;padding-top:5px;">
        <div class="l-label">上次登录</div>
        <div class="l-value">{{session.last_logined_at?moment(session.last_logined_at).fromNow():'无'}}</div>
      </div>
      <div class="flex-wrap flex-between pm-cell">
       <div class="l-label">注册时间</div>
        <div class="l-value">{{moment(session.created_at).format("L")}}</div>
      </div>
       <div class="flex-wrap flex-between pm-cell">
        <div class="l-label">密码登录</div>
        <div class="l-value">
          <Dropdown trigger="click" transfer @on-click="onEvent">
          <a>{{session.password?'已启用':'已禁用'}} <BaseIcon icon="ios-arrow-down" /></a>
          <DropdownMenu slot="list">
            <DropdownItem name="edit-account">修改登录名/密码 <BaseIcon icon="ios-arrow-forward" /></DropdownItem>
            <DropdownItem name="disable-account" v-if="!session.disabled_password">禁用(密码登录) <BaseIcon icon="ios-arrow-forward" /></DropdownItem>
            <DropdownItem v-else name="edit-account">启用</DropdownItem>
        </DropdownMenu>
          </Dropdown>
        </div>
      </div>
       <div class="flex-wrap flex-between pm-cell">
        <div class="l-label">手机号</div>
        <div class="l-value"> <Dropdown trigger="click" transfer>
          <a   :style="session.phone?'':'color:#aaa'">{{session.phone?'已绑定':'未绑定'}} <BaseIcon icon="ios-arrow-down" /></a>
          <DropdownMenu slot="list">
            <DropdownItem v-if="!session.phone">绑定手机号 </DropdownItem>
            <DropdownItem v-else>解除绑定 </DropdownItem>
        </DropdownMenu>
          </Dropdown></div>
      </div>
       <div class="flex-wrap flex-between pm-cell">
        <div class="l-label">微信登录</div>
         <Dropdown trigger="click" transfer>
          <a  :style="session.wechat?'':'color:#aaa'">{{session.wechat?'已绑定':'未绑定'}} <BaseIcon icon="ios-arrow-down" /></a>
          <DropdownMenu slot="list">
            <DropdownItem v-if="!session.wechat">绑定微信 </DropdownItem>
            <DropdownItem v-else>解除绑定 </DropdownItem>
        </DropdownMenu>
          </Dropdown>
      </div>
      <div class="flex-wrap flex-between pm-cell">
        <div class="l-label">钉钉登录</div>
        <div class="l-value"><Dropdown trigger="click" transfer>
          <a :style="session.ding_open_id?'':'color:#aaa'">{{session.ding_open_id?'已绑定':'未绑定'}} <BaseIcon icon="ios-arrow-down" /></a>
          <DropdownMenu slot="list">
            <DropdownItem v-if="!session.ding_open_id">绑定钉钉 </DropdownItem>
            <DropdownItem v-else>解除绑定 </DropdownItem>
        </DropdownMenu>
          </Dropdown></div>
      </div>
     
    </div>

    
    <div class="flex-wrap flex-line" style="padding:10px;background:var(--bg2);position:relative;margin-top:5px;">
      <div class="flex-wrap flex-center editable-avatar" style="position:relative;width:40px;height:40px;background:var(--bg3);border-radius:50%;">
         <BaseIcon icon="nbgz" color="#347" :size="25" />
      </div>
      
      <div class="flex-col align-start" style="margin-left:5px;cursor:pointer;">
        <div style="font-size:14px;">宁波高专建设监理有限公司 <BaseIcon icon="info" color="#3af" />
          <div style="font-size:12px;color:var(--primary);cursor:pointer;" @click="handleEditUserInfo">423人</div></div>
        <div style="font-size:0.8rem;color:#aaa;">{{session.user}}</div>
      </div>
      <div style="position:absolute;right:0;top:calc(50% - 10px);">
        
      </div>
    </div>

    <div style="padding:10px;font-size:0.8rem;padding-top:0;">
       <div class="flex-wrap flex-between pm-cell" style="margin-top:5px;padding-top:5px;">
        <div class="l-label">入职时间</div>
        <div class="l-value">{{session.last_logined_at?moment(session.last_logined_at).fromNow():'无'}}</div>
      </div>
        <div class="flex-wrap flex-between pm-cell">
        <div class="l-label">员工状态</div>
         <Dropdown trigger="click" transfer>
          <a  :style="session.wechat?'':'color:#aaa'">{{session.wechat?'已绑定':'未绑定'}} <BaseIcon icon="ios-arrow-down" /></a>
          <DropdownMenu slot="list">
            <DropdownItem v-if="!session.wechat">绑定微信 </DropdownItem>
            <DropdownItem v-else>解除绑定 </DropdownItem>
        </DropdownMenu>
          </Dropdown>
      </div>
      <div class="flex-wrap flex-between pm-cell">
       <div class="l-label">已工作年限</div>
        <div class="l-value">{{moment(session.created_at).format("L")}}</div>
      </div>
       <div class="flex-wrap flex-between pm-cell">
        <div class="l-label">员工档案</div>
        <div class="l-value">
          <Dropdown trigger="click" transfer @on-click="onEvent">
          <a>{{session.password?'已启用':'已禁用'}} <BaseIcon icon="ios-arrow-down" /></a>
          <DropdownMenu slot="list">
            <DropdownItem name="edit-account">修改登录名/密码 <BaseIcon icon="ios-arrow-forward" /></DropdownItem>
            <DropdownItem name="disable-account" v-if="!session.disabled_password">禁用(密码登录) <BaseIcon icon="ios-arrow-forward" /></DropdownItem>
            <DropdownItem v-else name="edit-account">启用</DropdownItem>
        </DropdownMenu>
          </Dropdown>
        </div>
      </div>
       <div class="flex-wrap flex-between pm-cell">
        <div class="l-label">平台账号</div>
        <div class="l-value"> <Dropdown trigger="click" transfer>
          <a   :style="session.phone?'':'color:#aaa'">{{session.phone?'已绑定':'未绑定'}} <BaseIcon icon="ios-arrow-down" /></a>
          <DropdownMenu slot="list">
            <DropdownItem v-if="!session.phone">绑定手机号 </DropdownItem>
            <DropdownItem v-else>解除绑定 </DropdownItem>
        </DropdownMenu>
          </Dropdown></div>
      </div>
      
      
    </div>


    

    <Modal v-model="showUserInfoEditor" title="编辑资料" footer-hide width="600">
      <Form ref="form-user" :model="formData" style="margin:0 10px">
        <div>
          <FormItem></FormItem>
        </div>
      </Form>
    </Modal>

    <Modal v-model="modalPassword" title="配置账号登录" width="400" footer-hide>
      <Form ref="form"  :model="formData" style="margin:0 10px">
        <FormItem prop="user" required label="登录名">
          <Input type="password" v-model="formData.user"  size="large" />
        </FormItem>
        <FormItem prop="old_pass" required label="请输入旧密码" v-if="session.password">
          <Input type="password" v-model="formData.old_pass"  size="large" />
        </FormItem>
         <FormItem prop="new_pass" required label="请输入新密码">
          <Input type="password" v-model="formData.new_pass"  size="large" />
        </FormItem>
         <FormItem prop="new_pass_again" required label="请再次输入新密码">
          <Input type="password" v-model="formData.new_pass_again"  size="large" />
        </FormItem>
        <div class="flex-wrap flex-end">
          <Button size="large" type="primary" style="margin-right:10px" @click="submit">提交</Button>
          <Button size="large" @click="modalPassword=false;formData={}">取消</Button>
        </div>
      </Form>
    </Modal>

    <Modal v-model="showWeChat" title="绑定微信" footer-hide>
      <div class="flex-wrap flex-center">
      <div id="bind_wechat">

      </div>
      </div>
    </Modal>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import UTIL from '@/utils'
  export default {
    data(){
      return {
        modalPassword:false,
        showWeChat:false,
        showUserInfoEditor:false,
        formData:{}
      }
    },
    computed:{
      ...mapGetters('session',['session'])
    },
    mounted(){
      let isBindingSuccess = localStorage.getItem('binding-success')
      if(isBindingSuccess){
        this.Success("恭喜您,绑定成功！")
        localStorage.removeItem('binding-success')
      }
    },
    methods:{
     
      handleEditUserInfo(){
        this.formData = {
          name:this.session.name,
          slogan:this.session.slogan,
          avatar:this.session.avatar
        }
        this.showUserInfoEditor = true
      },
      onEvent(e){
        const event_map = {
          bind_wechat:this.prepareWechat,
          edit_account:this.editAccount,
          disabled_account:this.disableAccount
        }

        event_map[e]()
      },
      disableAccount(){

      },
      editAccount(){
        this.formData = {
          user:this.session.user
        }
        this.modalPassword = true
      },
      prepareWechat(){
        var that = this
        this.showWeChat = true
      const appid = 'wx215ebbf66af92517'
      const host = "https://a.inbgz.com/oauth2"
      this.$store.commit('save_login_url',this.$route.fullPath)
      localStorage.setItem('BINDING_ID',this.session.token)
      var redirect_uri = encodeURIComponent(`${host}`)
      this.$nextTick(()=>{
        var obj = new WxLogin({
        self_redirect: false,
        id: "bind_wechat",
        appid,
        scope: "snsapi_login",
        redirect_uri,
        style:"width:200px;height:200px;",
        state:'WECHAT'
      });
      })


      },
      submit(){
        let model = this.formData
        this.$refs.form.validate(valid=>{
          if(valid){
            if(model.new_pass !== model.new_pass_again){
              this.Error('两次输入密码不一致')
              return
            }

            this.$api.post('users/change-pwd',{old_pass:UTIL.MD5(model.old_pass),new_pass:UTIL.MD5(model.new_pass)}).then(()=>{
              this.$Notice.success({title:"修改成功"})
              this.formData = {}
              this.modalPassword = false
            }).catch(e=>{
              this.Error(e)
            })
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>