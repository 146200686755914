<style lang="less" scoped>
  .value-changed{
    border-color:var(--sucess);
  }
</style>
<template>
  <div style="width:100%;position:relative;background:linear-gradient(to bottom right,var(--bg3),var(--bg2))">
      <Form  ref="form" class="ugo-form" :label-width="form?.config?.labelWidth || 100" :label-position="form?.config?.labelPosition || 'right'" :rules="design?{}:rules" :model="model">
        <BaseEmpty v-if="form.id && fields.length == 0" msg="表单配置错误, 请联系管理员" />
        <template v-if="form.html">
          <BaseForm :form="{
              layout:form.html,
              def:formDef,
              option:{
                hideTools:true
              }
            }" 
            :editable="editable"  
            v-model="formData" 
            @submit="formData=$event" 
          ></BaseForm>
        </template>
        <template v-else>
          <template v-for="p in fields">
            <div class="form-item"  :key="p.id" v-if="acl[p.fullkey]!=2">
             
                <component 
                    :is="p.control" 
                    :config="p.config || {}" 
                    :editable="editable && (!p.config || !p.config.disabled) && !acl[p.fullkey]" 
                    :label="p.name"
                    :acl="acl"
                    :ref="p.key"
                    :fkey="p.key"
                    :old="isContainer(p.control)?old:(old?old[p.key]:undefined)"
                    :model="model"
                    :required="p.required"
                    :fields="p.fields"
                    :p_many="form.composed_many_keys"
                    :p_one="form.composed_one_keys"
                    :value="isContainer(p.control)?model:(model[p.key]!=undefined?value[p.key]:undefined)"
                    @input="handleInput(p.key,$event)"
                  /> 
            </div>
          </template>
        </template>
      </Form>
    <slot></slot>
  </div>
</template>

<script>
import responsableMixin from '@/mixins/responsable'
import {mapGetters} from 'vuex'
import {cloneDeep} from 'lodash'
export default {
  data(){
    return {
      model:{},
      rules:{}
    }
  },
  props:{
    editable:{
      type:Boolean,
      default:true
    },
    form:{
      type:Object,
      default:()=>({})
    },
    value:{
      type:Object,
      default:()=>({})
    },
    old:{
      type:Object,
      default:()=>({})
    },
    acl:{
      type:Object,
      default:()=>({})
    },
    design:{
      type:Boolean
    }
  },
  watch:{
    form:{
      handler(v){
        this.model = cloneDeep(this.value)
        
        this.$nextTick(()=>{
          let r = {}
          this.traversalFeilds(v.fields,f=>{
          if(f.required && !this.acl[f.fullkey]){
            r[f.key] = [{required:true,message:`${f.name}是必填项，不可为空`,trigger:'blur'}]
            }
          })
          this.rules = r
        })
       
      }, 
      deep:true,
      immediate:true
    },
    value:{
      handler(v){
        if(v !== this.model)
          this.model = cloneDeep(this.value)
      }, 
      deep:true,
      immediate:true
    }
  },
  mounted(){
    if(!this.value){
      this.model = this.initDefaultModel()
    }else{
      this.model = cloneDeep(this.value)
    }
    
  },
  computed:{
    fields(){
      return this.form?(this.form.fields || []):[]
    },
    formFields(){
      let formFields = []
      this.traversalFeilds(this.form.fields,f=>{
        if(['UgoInnerForm','UgoMultiForm'].includes(f.control)){
          if(this.$refs[f.key]){
            let t = this.$refs[f.key]
            if(Array.isArray(t)){
              t = t[0]
            }
            formFields.push(t)
          }
        }
      })
      return formFields
    },
    formDef(){
        let o = {}
        // if(this.form.fields){
          
        // this.form.fields.forEach(v=>{
        //   if(v.key || v.name)
        //     o[v.key || v.name] = {
            
        //       control:'input',
        //       option:{}
        //     }
        // })
        // }
        return o
      }
  },
  methods:{
    initDefaultModel(){
      this.traversalFeilds(this.form.fields,f=>{
        if(f?.config?.defaultValue){
          this.model[f.key] = f.config.defaultValue
        }
        if(['UgoInnerForm','UgoMultiForm'].includes(f.control)){
          if(this.$refs[f.key]){
            let t = this.$refs[f.key]
            if(Array.isArray(t)){
              t = t[0]
            }
            t.initDefaultModel()
          }
        }
      })
      this.$emit('input',this.model)
    },
    traversalFeilds(fields,cb){
      if(Array.isArray(fields) && cb){
        fields.forEach(f=>{
          cb(f)
          this.traversalFeilds(f.fields,cb)
        })
      }
    },
    getDefaultValue(){

    },
    getUpdateData(e){
      if(!this.old)
        return this.value
      
      let updateData = {}
      this.fields.forEach(f=>{
        if(!Array.isArray(this.$refs[f.key]) || !this.$refs[f.key][0])
          return
        let component = this.$refs[f.key][0]
        let getUpdate = component.getUpdateData
        if(getUpdate){
          let val = getUpdate()
          if(this.isContainer(f.control)){
            if(val && Object.keys(val).length > 0)
              Object.assign(updateData,val)
          }else{
            if(val !== undefined)
              updateData[f.key] = val
          } 
          
        }else{
          if((this.old[f.key] !== this.value[f.key])){
            updateData[f.key] = this.value[f.key]
          }
        }
      })

      if(Object.keys(updateData).length > 0){
        updateData.id = this.old.id
        return updateData      
      }
    },
    handleInput(key,e){
      if(typeof e =='object' && e.key){
        this.$set(this.model,e.key, e.value)
      }else{
        this.$set(this.model,key,e)
      }
      this.$emit('input',this.model)
    },
    isContainer(control){
      return this.$store.getters['forms/containers'].includes(control)
    },
    validate(){
      console.log('form-validate',this.formFields)
      let valid = false
      for(let i=0;i<this.formFields.length;i++){
        valid = this.formFields[i].validate()
        if(!valid)
          return
      }
      
      this.$refs.form.validate((v)=>{
        valid = v
      })
      return valid
    },
    submit(){
      if(this.validate()){
        let model = cloneDeep(this.model)
        this.$emit('on-submit',model)
        return model
      }
    
      
    }
  }
}
</script>