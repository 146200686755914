<template>
    <div class="container">
      <div class="flex-wrap flex-between" style="height:30px;width:100%;margin-top:10px;">
          <div class="flex-wrap">
          <Input placeholder="输入姓名/手机号进行搜索" search style="width:200px;" />
          <Select placeholder="选择部门" search style="width:200px;margin-left:10px;" />
          <BaseDate placeholder="入职时间" search style="width:150px;margin-left:10px;" />
          </div>
          <div class="flex-wrap split5">     
            <Button icon="md-add">添加员工</Button>
            <Button>导出花名册</Button>
          </div>
        </div>
        <div style="height:calc(100% - 125px);width:100%;margin-top:10px;">
          <BaseTable :loading="loading" :columns="columns" :data="filtered" />
        </div>
    </div>
  </template>
  
  <script>
    import {mapGetters} from 'vuex'
    export default {
      routerOption:{
       
      },
       mounted(){
        this.getData()
      },
      data(){
        return{
          loading:false,
        }
      },
      computed:{
        ...mapGetters("oa",["deps","employees"]),
        columns(){
          return [{
            key:"id",
            type:"index",
            width:50
          },{
            key:"realname",
            title:"姓名",
            align:"left",
            width:100,
            type:"text"
          },{
            key:"gender",
            title:"姓别",
            align:"left",
            width:80,
            type:"text"
          },{
            key:"main_dep",
            title:"部门",
            align:"left",
            width:200,
            type:"text",
            render:(h,{row})=>{
              let dep = this.deps.find(v=>v.id == row.main_dep)
              if(dep)
                return h("span",dep.name)
              else
                return h("span",'无')
            }
          },{
            key:"position",
            title:"职位",
            align:"left",
            width:100,
            type:"text"
          },{
            key:"employeeType",
            title:"聘用类型",
            align:"center",
            width:120,
            type:"text"
          },{
            key:"joined_at",
            title:"入职时间",
            align:"left",
            width:100,
            type:"text"
          },{
            key:"phone",
            title:"电话",
            align:"center",
            width:120,
            type:"text"
          },{
            key:"account_id",
            title:"账号",
            width:100,
            render:(h,{row})=>{
              return h("span",row.account_id?'已启用':'-')
            }
          },{
            key:"tool",
            title:"操作",
            align:"center",
            type:"text"
          }]
        },
        filtered(){
          return this.employees
        },
        count(){
          return {
            total:this.employees.length,
            formal:this.employees.filter(v=>v.employeeType == '全职').length,
            intern:this.employees.filter(v=>v.employeeType == '实习').length,
            back:this.employees.filter(v=>v.employeeType == '退休返聘').length
          }
        }
      },
      methods:{
        getData(){
          this.loading = false
          this.$store.dispatch("oa/getEmployees").then(()=>{
  
          }).finally(e=>{
            this.loading =false
          })
        }
      }
    }
  </script>
  
  <style lang="scss" scoped>
  
  </style>