<template>
  <a
    class='gz-form-item l-gz-image'
    	:class="!editable ? 'gz-disabled-item' : ''"
    @click='editAvatar'
  >
    <i
      class="iconfont icon-nbgz"
      v-if="!value"
    ></i>
    <img
      :src='value'
      v-if='value'
    />
    <span v-if='!value'>选择图片</span>
    <span
      class='gz-label'
      :class="focused || value?'':'gz-label-empty'"
      v-if="label"
    >
      {{label}}
    </span>
    <input
      class="upload"
      type='file'
      ref='fileLoader'
      @change='handleUpload'
      :index="index"
      style='width:0;height:0;overflow:hidden;'
    />
    <Modal
      title="图片裁剪"
      v-model="editAvatarDialog"
      transfer
      width='800'
      footer-hide
      :mask-closable="false"
    >
      <div class='flex-wrap'>
        <VueCropper
          ref="cropper"
          style='position:relative;width:400px;height:400px;'
          :img="imageURL"
          original
          autoCrop
          fixedBox
          fixed
          :fixedNumber="fixedNumber"
          :outputSize="0.8"
          outputType='png'
          @realTime="realTime"
        ></VueCropper>
        <div style='height:400px;padding:20px;'>
         <div class="flex-wrap">
          <div
            class="flex-wrap"
            style='margin:20px 0;overflow:hidden;width: 320px; height:320px; overflow: hidden; margin: 0px; zoom: 0.25;border:5px solid #aaa;'
            :style="previews.div+';width:'+(option.w2h?(option.w2h*320+'px'):'320px')"
          >
            <div style='background:#fff;height:320px;'>
            <img
              :src="previews.url"
              size="60"
              :style='previews.img'
            />
            </div>
          </div>

           <div
            class="flex-wrap"
            v-show="!option.w2h"
            style='margin:20px 0;overflow:hidden;width: 320px; height:320px; overflow: hidden; margin: 0px; zoom: 0.25;border-radius:50%;margin-left:30px;border:5px solid #aaa;'
          >
            <div :style='previews.div' style='background:#fff;width:320px;height:320px;'>
            <img
              :src="previews.url"
              size="60"
              :style='previews.img'
            />
            </div>
          </div>
</div>
          <div style='margin:10px 0;'> <Button @click='rotateLeft()' >向左翻转</Button>
            <Button @click='rotateRight()'>向右翻转</Button>
            <Button @click='Upload' style='margin-left:10px;' icon='md-photos' type='info'>更换图片</Button>

          </div>

          <Button @click='down()'>下载图片</Button><Button
            @click='finish()'
            style='margin-left:10px;'
            type='success'
            size='large'
            :loading="uploading"
          >保存</Button>
        </div>

      </div>

    </Modal>
  </a>
</template>
<script>
import { VueCropper } from 'vue-cropper'
export default {
  data() {
    return {
      editAvatarDialog: false,
      imageURL: "",
      previews: "",
      avatarURL: "",
      percent:0,
      uploading:false
    }
  },
  components: { VueCropper },
  props: {
    index: {
      type: [Number, String]
    },
    label: {
      type: String
    },
    env:{
      type:Object,
      default:()=>{}
    },
    value: {
      type: String

    },
    parent: {
      type: Object
    },
    editable: {
      type: Boolean
    },
    option: {
      type: Object,
      default: {}
    }
  },
  computed:{
    fixedNumber(){
      if(this.option.w2h){
        return [this.option.w2h,1]
      }else
      {
        return [1,1]
      }
    }
  },
  methods: {
    Upload() {
      this.$refs.fileLoader.click();
    },
    editAvatar() {
      if(this.editable){
        if (this.imageURL) {
        this.editAvatarDialog = true
        this.$refs.cropper.startCrop()
      } else {
        this.$refs.cropper.clearCrop()
        this.Upload()
      }
      }
      
    },
    getObjectURL(file) {
      var url = null;
      if (window.createObjectURL != undefined) {
        // basic
        url = window.createObjectURL(file);
      } else if (window.URL != undefined) {
        // mozilla(firefox)
        url = window.URL.createObjectURL(file);
      } else if (window.webkitURL != undefined) {
        // webkit or chrome
        url = window.webkitURL.createObjectURL(file);
      }
      return url;
    },
    compress(bdata) {
      var _this = this;
      var quality = 0.5; //压缩图片的质量
      var oldimglength = bdata.length; //压缩前的大小
      var compresRadio = 0; // 压缩率

      var canvas = document.createElement("canvas"); //创建画布
      var ctx = canvas.getContext("2d");

      var img = new Image();
      img.src = bdata;
      img.onload = function () {
        var width = img.width;
        var height = img.height;
        canvas.width = 400; //这里可以自定义你的图片大小
        canvas.height = 400 * (img.height / img.width);
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        var cdata = canvas.toDataURL("image/png", quality); //将图片转为Base64 之后预览要用

        // _this.HeadUrl = cdata; //预览你压缩后的图片
        var newimglength = cdata.length;
        compresRadio =
          (((oldimglength - newimglength) / oldimglength) * 100).toFixed(2) +
          "%";
        //base64转成file
        let arr = cdata.split(",");
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        let newFile = new File([u8arr], _this.filename, { type: mime });

        _this.imageURL = _this.getObjectURL(newFile);

        _this.editAvatar()
      };



    },
    handleUpload(e) {
      let img = e.srcElement.files[0]
      var that = this
      let reader = new FileReader()
      reader.readAsDataURL(img)
      reader.onload = e => {
        if(e.target.readyState == 2){
          if (e.total >= 1024 * 500) {
            this.compress(e.target.result)
          } else {
            this.imageURL = this.getObjectURL(img)
            this.editAvatar()
          }
        }else{
          this.$Modal.error({title:"错误",content:"读取失败"})
        }
      }



    },
    saveEditAvatar() {
      this.editAvatarDialog = false
      
      this.finish('blob')
      
    },
    // 放大/缩小
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    // 右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    onUploadProgress(progressEvent){
       var complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
       this.percent = complete + '%'
    },
    // 保存上传图片
    finish(type = 'blob') {
      this.$refs.cropper.getCropData((data) => {

        this.avatarURL = data
        let arr = data.split(",");
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        let newFile = new File([u8arr], 'avatar.png', { type: mime });
        let uploader = null
        if(this.option.uploaderSource == 'env'){
          let uploader = this.env[this.option.uploader]
          if(uploader){
            this.uploading = true
            uploader(newFile,this.onUploadProgress).then(url=>{
              this.avatarURL = url
              this.uploading = false
              this.$emit('change',this.avatarURL)
              this.editAvatarDialog = false
            })
          }
        }else{
          this.$store.dispatch(this.option.uploader,newFile).then(url=>{
              this.avatarURL = url
              this.$emit('change',this.avatarURL)
              this.editAvatarDialog = false
          })
        }
        
      })
      
    },

    // 实时预览函数
    realTime(data) {
      this.previews = data
    },
    // 下载图片
    down(type = 'blob') {
      var aLink = document.createElement('a')
      aLink.download = 'author-img'
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob((data) => {
          aLink.href = window.URL.createObjectURL(data)
          aLink.click()
        })
      } else {
        this.$refs.cropper.getCropData((data) => {
          aLink.href = data;
          aLink.click()
        })
      }
    },
    // 更换头像--上传本地图片
    uploadImg(file) {
      var _this = this;
      var reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === 'object') {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        _this.option.img = data
      }

    }
  }
}
</script>
<style lang="less">
.upload {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  font-size: 100px;
  opacity: 0;
}
.l-gz-image {
  position: relative;
  display: inline-block;
  background: #fff;
  height: 100%;
  width: 100%;
  overflow: hidden;
  color: #ddd;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 40px;
    margin-top: 0.5rem;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.gz-image:hover {
  background: rgb(246, 255, 208);
  color: #eee;
}

.previewBox {
  text-align: center;
  margin-left: 60px;
}

.preview {
  width: 150px;
  height: 150px;
  margin: 0px auto 20px auto;
  border-radius: 50%;
  border: 1px solid #ccc;
  background-color: #ccc;
  overflow: hidden;
}

.cropper {
  width: 260px;
  height: 260px;
}
</style>
