<template>
  <div class="o-contract" :class="selected?'o-contract-selected':''">
    <div class="o-contract-tag">{{value.business_type || '[服务类型]'}} | {{value.code || '[合同编号]'}}</div>
    <div class="o-contract-name">{{value.name || '[合同名称]'}}</div>
    <BaseAmount class="o-contract-amount" full :value="value.amount" />
    <BaseIcon icon="contract" :size="120" color="var(--primary)" class="o-contract-bgicon" />
    <slot></slot>
  </div>
</template>

<script>
  export default {
    props:{
      value:{
        type:Object,
        default:()=>({})
      },
      selected:{
        type:Boolean,
        default:false
      }
    }
  }
</script>

<style lang="less">
.o-contract{
  width:240px;
  height:80px;
  flex-shrink: 0;
  margin-bottom:10px;
  background:var(--bg3);
  color:var(--text3);
  position: relative;
  border-radius: 5px;
  box-shadow: 1px 1px 1px var(--border);
  padding:10px;
  overflow: hidden;
  .o-contract-tag{
    color:var(--subtext3);
    font-weight: bold;
  }

  .o-contract-name{
    font-size:15px;
    height:50px;
    width:270px;
    color:var(--primary);
    line-height:25px;

    overflow: hidden;
    text-overflow: ellipsis;
  }

  .o-contract-amount{
    position: absolute;
    right:10px;
    bottom:5px;
    font-size:15px;
    font-weight: bold;
    opacity:0.8;
    text-shadow: 1px 1px 1px var(--border);
  }

  
.o-contract-bgicon{
  position: absolute;
  right:-20px;
  bottom:-50px;
  opacity: 0.1;
}
}

.o-contract:hover{
  background:var(--hover-text);
  cursor: pointer;
}

.o-contract-selected{
  border:1px solid var(--primary);
}

</style>