<template>
  <div 
    class="u-block"
    :class="`u-${type} u-${value[itemId] && togglable?'button':'empty'}-active`"
    v-if="isShow"
    :title="type && type.includes('button')?name:''"
    :style="
      `
        height:${height}px;
        width:${computedWidth}px;
        gap:${type=='list'?gap:0}px;
        max-width:${maxWidth}px;
      `"
    @click.stop="handleEvent({key:itemId,control:type,event:'click'})"
  >
    <template v-if="type == 'icon-button'">
      <BaseIcon :icon="icon" :size="height/2.5" />
    </template>
    <template v-else-if="type == 'text-button'">
      <BaseIcon :icon="icon" :size="height/2.5" style="margin-right:5px" v-if="icon" />
      {{ name }}
    </template>
    <template v-else-if="type=='clear-button'">
      <BaseIcon icon="ios-funnel" :size="height/2.5"  />
      <BaseIcon icon="md-close" :size="height/3" class="button-marker" color="var(--error)" style="font-weight:bold;" />
    </template>
    <template v-else-if="type == 'divider'">
      <span></span>
    </template>
    <template v-else-if="type == 'count-button'">
      <div class="u-count-button-name" :style="`font-size:${height/6}px`" v-if="name">{{ name }}</div>
      <div class="u-count-button-value" :style="`font-size:${height/3}px`">{{ counts[itemId] }}</div>
    </template>
    <template v-else-if="type == 'tabs'">
      <div class="u-tabs-name" v-if="name"
        :style="`font-size:${height/6}px`"  
      >{{ name }} </div>
      <div class="u-tabs-list">
        <div v-if="showTotal"
           class="u-tab-button"
          :style="`flex-direction:${height<60?'row':'column'}`"
          :class="{'u-tab-button-active':isButtonSelected(undefined)}"
          @click="handleEvent({control:type,type:'tab-change',key:itemId,value:undefined})"
        >
        <div class="u-tab-name" :style="`font-size:${height>70?height/6:12}px`" ><BaseIcon icon="md-folder" /> 全部</div>
          <div 
            class="u-tab-count" 
            :class="{'u-tab-count-empty':!localCounts.total}" 
            :style="`font-size:${height<60?12:height/5}px;`">
            {{ localCounts.total || 0 }}
          </div>
        </div>
        <div :key="'tab'+i+s.key" v-for="(s,i) in sub_items"
          class="u-tab-button"
          :style="`flex-direction:${height<60?'row':'column'}`"
          :class="{'u-tab-button-active':isButtonSelected(s.key)}"
          @click="handleEvent({control:type,type:'tab-change',key:itemId,value:s.key})"
        >
          <div class="u-tab-name" :style="`font-size:${height>70?height/6:12}px`" ><BaseIcon :icon="s.icon" /> {{ s.name }}</div>
          <div 
            class="u-tab-count" 
            :class="{'u-tab-count-empty':!counts[s.key]}" 
            v-if="countable || s.countable" 
            :style="`font-size:${height<60?12:height/5}px;`">
            {{ localCounts[s.key] || 0 }}
          </div>
      </div>
      </div>
    </template>
    <template v-else-if="type == 'search'">
      <Input 
        :value="value[itemId]"
        search
        clearable
        :placeholder="placeholder"
        :style="`height:${height}px;overflow:hidden;width:${width}px`"
        @input="updateValue(itemId,$event)" 
        @on-search="$emit('on-event',{type:'search',key:itemId})"
        @on-clear="$emit('on-event',{type:'search',key:itemId})"
      />
    </template>
    <template v-else-if="type='list'">
      <ToolBarItem 
        v-for="s in subs"
        :key="s.key"
        :item-id="s.key"
        v-bind="s"
        :height="computedListItemHeight"
        :count="count"
        :gap="gap"
        @on-event="handleEvent"
        @input="$emit('input',$event)"
      />
    </template>
  </div>
</template>

<script>
import {isEqual} from 'lodash'
export default {
    name:"ToolBarItem",
    data(){
      return {
        localCounts:this.counts
      }
    }, 
    watch:{
      counts:{
        handler(v){
          this.localCounts = v
        },
        deep:true,
        immediate:true
      }
    },
    props:{
      type:{
        type:String,
        default:"empty"
      },
      width:{
        type:Number
      },  
      height:{
        type:Number
      },  
      showTotal:{
        type:Boolean,
      },
      gap:{
        type:Number,
        default:5
      },
      ['item-id']:{
        type:String | NaN | null
      },
      icon:{
        type:String
      }, 
      name:{
        type:String
      },  
      count:{
        type:Number
      },
      multiple:{
        type:Boolean 
      },
      countable:{
        type:Boolean
      },
      counts:{
            type:Object,
            default:()=>({})
        },
      value:{
        type:Object,
        default:()=>({})
      },
      subs:{
        type:Array,
        default:()=>([])
      },
      placeholder:{
        type:String
      },
      rows:{
        
        type:Number,
        default:1
      },
      maxWidth:{
        type:Number,
      },
      togglable:{
        type:Boolean
      },
      getters:{
        type:String
      }

    },
    computed:{
      isShow(){
        if(this.type=='clear-button' && Object.values(this.localValue).filter(v=>v!==undefined && v!== "" && (!Array.isArray(v) || v.length != 0)).length == 0)
          return false
        return true
      },
      localValue(){
        return this.value 
      },
      sub_items(){
        if(this.getters){
          return this.$store.getters[this.getters]
        }
        let subs = this.subs
        if(this.type == 'tabs'){
          if(Array.isArray(this.subs) && this.subs.length > 0 && typeof this.subs[0] == 'string'){
            subs = this.subs.map(v=>({
              key:v,
              name:v
            }))
          }
        }
        return subs
      },
      computedWidth(){
        if(this.type == 'list')
          return this.width || this.computedListItemHeight * this.cols + this.gap * (this.cols - 1)

        if(this.type == 'search')
          return 'auto'

        if(this.type == "tabs")
          return 'auto'

        if(this.type == 'text-button')
          return 'auto'

        return this.width || this.height
      },
      cols(){
        if(this.type == 'list' && this.rows)
          return Math.ceil(this.subs.length / this.rows)
      },  
      computedListItemHeight(){
        return (this.height - this.gap*(this.rows-1))/this.rows
      }
    },
    methods:{
      isButtonSelected(key){
       
        
        let selected = this.localValue[this.itemId]
        if(this.multiple){
          if(Array.isArray(selected)){
           
            if(Array.isArray(key)){
              for(let i=0;i<selected.length;i++){
                console.log(this.type,key,selected[i],isEqual(selected[i],key))
                if(isEqual(selected[i],key))
                  return true
              }
              return false
            }else{
              return selected.includes(key)
            }
          }
        }else{
          return selected == key
        }
      },
      updateValue(key,value){
        let oldValue = this.localValue[key]
        let newValue
        if(this.multiple){
          if(value == undefined)
            return
          
         
          if(Array.isArray(oldValue)){
            newValue = [...oldValue]
            let index = newValue.findIndex(t=>isEqual(t,value))
            if(index == -1)
              newValue.push(value)
            else
              newValue.splice(index,1)
          }else if(typeof oldValue == 'string' || typeof oldValue == 'number'){
            newValue = oldValue == value?[]:[oldValue]
            
          }else{
            newValue = [value]
          }
          newValue = newValue.filter(v=>v)
          
        }else{
          newValue = value
          if(value == oldValue)
            newValue = undefined
        }
        this.$emit('input',{...this.value,[key]:newValue})
        
      },
      handleEvent(e){
        if(!e){
          return 
        }

        if(e.key == 'search' && e.event == 'click')
          return

        if(this.type == 'clear-button'){
          this.$emit('input',{})
          return
        }

        if(e.type == 'tab-change'){
          
            this.updateValue(e.key,e.value)
        }

        if(this.type.includes('button') && this.togglable){
          this.updateValue(this.itemId,!this.value[this.itemId])
        }

        this.$emit('on-event',e)
      }
    }
}
</script>

<style lang="less">
.u-block{
  position: relative;
  flex-grow:0;
  flex-shrink: 0;
  border-radius: 2px;
}
.u-icon-button{
  background:var(--bg3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border:1px solid var(--border);
}

.u-icon-button:hover{
  background:var(--primary);
  color:var(--hover-text);
}

.u-icon-button:active{
  position: relative;
  right:-1px;
  bottom:-1px;
}




.u-clear-button{
  
  position: relative;
  .u-icon-button
 
}

.button-marker{
    position: absolute;
    right:3px;
    bottom:3px;
  }

.u-clear-button:hover{
  background:var(--primary);
  color:var(--hover-text);
}

.u-clear-button:active{
  position: relative;
  right:-1px;
  bottom:-1px;
}

.u-text-button{
  background:var(--bg3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width:auto !important;
  padding:0 5px;
  border:1px solid var(--border);
}

.u-text-button:hover{
  background:var(--primary);
  color:var(--hover-text);
}

.u-text-button:active{
  position: relative;
  right:-1px;
  bottom:-1px;
}

.u-count-button{
  background:var(--bg3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  border:1px solid var(--border);

  .u-count-button-name{
    font-size:12px;
  }

  .u-count-button-value{
    font-size:25px;
  }
}

.u-count-button:hover{
  background:var(--primary);
  color:var(--hover-text);
}

.u-count-button:active{
  position: relative;
  right:-1px;
  bottom:-1px;
}

.u-button-active,.u-button-active:hover{
  background:var(--primary);
  color:var(--hover-text);
}

.u-button-active{
  border-left:2px solid var(--subtext2);
  border-top:2px solid var(--subtext2);
}

.u-list{
  display: flex;
  flex-wrap:wrap;
  flex-direction: column;
  
  align-items: stretch;
}


.u-search{
  flex-grow: 1;
  min-width: 100px;
  .ivu-input{
    border-radius: 2px;
    height:100%;
  }
}

.u-tabs{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border:1px solid var(--border);
}
.u-tabs-name{
  padding:2px;
  text-align: center;
  
  border-bottom: 1px solid var(--border);
}

.u-tabs-list{
  display: flex;
  flex-wrap:wrap;
  flex-shrink: 0 !important;
  flex-grow: 1;
}



.u-tab-button{
  background:var(--bg3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  min-width:40px;
  padding:0 8px;
  text-align: center;
  border-right: 1px solid var(--border);

  .u-tab-name{
    text-align: center;
  }

  .u-tab-count{
    font-size:25px;
    text-align: center;
    padding:0 5px;
    background:var(--bg2);
    margin:0 5px;
    border-radius: 5px;
  }
}

.u-tab-button:last-child{
  border-right:none;
}

.u-tab-button:hover{
  background:var(--primary);
  color:var(--hover-text);
  .u-tab-count{
    background:#33333355;
    
  }
}

.u-tab-count-empty{
  color:var(--subtext2);
}

.u-tab-button:active{
  position: relative;
  right:-1px;
  bottom:-1px;
}

.u-tab-button-active{
  background:var(--primary);
  color:var(--hover-text);
  .u-tab-count{
    background:#33333355;
  }
}

.u-divider{
  width:1px !important;background:var(--border);height:80%;margin:0 3px;
}

</style>