
<template>
    <div class="pm-embed-control flex-wrap">
      
      <div 
        @click="showModal=true"
        class="flex-wrap pd-embed-value"
        :class="{
            'pd-embed-control-editable':editable,
            'pd-embed-empty-value':selected_names.length == 0
            }"
        :title="selected_names"
        >
       
        <BaseIcon icon="organization" color="var(--subtext2)" style="margin-right:5px;"
     
         /> 
         <div class="" style="flex-grow:1;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">
        {{selected_names && selected_names.length > 0?`${selected_names}`:'选择范围'}}</div>
        <BaseIcon icon="ios-arrow-down" color="var(--subtext2)" style="margin-left:5px;font-weight:bold;flex-shrink:0;" :size="13" />
    </div>
  
      <BaseUacModal
        v-model="showModal"
        @on-select="handleSelect"
      />
        
    </div>
  </template>
  
  <script>
  export default {
    data(){
      return {
        prev:false,
        current:{},
        showModal:false,
        filterText:"",
        selected:[]
      }
    },
    mounted(){
    },
    props:{
      option:{
        type:Object,
        default:()=>({})
      },
      editable:{
        type:Boolean
      },
      value:{
        type:String | Array,
      }
    },
    computed:{
      users(){
        return this.$store.getters['session/users']
      },
      deps(){
        return this.$store.getters['session/deps']
      },
      objs(){
        return [...this.users,...this.deps]
      },
      localValues(){
        if(Array.isArray(this.value)){
            return [...this.value]
        }else if(this.value){
            return [this.value]
        }else{
            return []
        }
      },
      selected_names(){
        if(Array.isArray(this.value))
            return this.value.map(v=>this.objs.find(t=>t.id == v)).map(v=>v.name).join(',')
        else
            return []
      }
    },
    methods:{
     
      handleSelect(users){
        this.$emit('input',users)
        this.showDropdown = false
      }
    }
  }
  </script>
  
  <style lang="less">
  .l-full{
    position: relative;
    height:100%;
    width:100%;
  }
  </style>