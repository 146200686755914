<template>
  <BaseLayout 
    :panels="panels" 
    :gutter="5" 
    :padding="10" 
    style="background:linear-gradient(to right,var(--border),var(--bg3));"
  >
  <div style="position:absolute;left:0;width:calc(21% + 8px);background:var(--bg2);bottom:0;top:0;border-left:1px solid var(--border);background-blend-mode:multiply;background-size:cover;border-right:3px solid var(--border);"
    :style="this.windowWidth < 1400?'width:calc(40% + 3px)':''"
    ></div>
  </BaseLayout>
</template>

<script>
  import MileStone from '@/components/milestone'
  export default {
    components:{MileStone},
    routerOption:{
      as_default:true
    },
    data(){
      return {
        showMileStone:true
      }
    },
    computed:{
       mobile(){
        return this.$store.getters['session/mobile']
      },
      windowWidth(){
        return this.$store.getters['session/windowWidth']
      },
      session(){
        return this.$store.getters['session/session']
      },
      panels(){
        if(this.windowWidth < 600){
           return [
           {
          x:0,y:0,w:24,h:2,control:"PanelWeather"
          },
            {
            x:0,y:2,w:24,h:1,control:"PanelNotice"
          },
          {
            x:0,y:3,w:24,h:3.5,control:"PanelCalendar"
          },
          {
            x:0,y:6.5,w:24,h:4.5,control:"PanelAccelerators"
          },
          {
            x:0,y:11,w:24,h:5,control:"PanelGroups"
          },
          {
            x:0,y:16,w:24,h:1.5,control:"PanelLinks"
          },
          {
            x:0,y:17.5,w:24,h:4.5,control:"PanelTasks"
          },
        // {
        //   x:6,y:0,w:12,h:7,control:"PanelCalendar"
        // }
        ]
        }else if(this.windowWidth < 1400){
          return [
           {
          x:9.8,y:0,w:14.2,h:0.3,control:"PanelWeatherStatus"
          },
          {
            x:0.2,y:0,w:9.2,h:1,control:"PanelCaption"
          },
            {
            x:0.2,y:1,w:9.2,h:1.3,control:"PanelNotice"
          },
          {
            x:0.2,y:2.4,w:9.2,h:4.5,control:"PanelAccelerators"
          },
          
          {
            x:0.2,y:6.9,w:9.2,h:4.1,control:"PanelGroups"
          },
        
          {
            x:9.8,y:5.5,w:14.2,h:4.5,control:"PanelTasks"
          },
          {
          x:9.8,y:0.1,w:14.2,h:2,control:"PanelCNotices",data:{
             key:"notice",
            title:"通知公告",
            icon:"md-notifications"
          }
        },
        {
          x:9.8,y:2.1,w:14.2,h:3,control:"PanelCNotices",data:{
             key:"activity",
            title:"企业动态",
            icon:"logo-instagram"
          }
        },
        
        
        // {
        //   x:6,y:0,w:12,h:7,control:"PanelCalendar"
        // }
        ]
        }else{
          return [
        //    {
        //   x:0,y:2.5,w:6,h:0.7,control:"PanelLinks"
        // },
        
        {
           x:0,y:0,w:5,h:2.4,control:"PanelContractAccelerators",data:"快捷操作"
        },
        {
           x:0,y:2.3,w:5,h:3.7,control:"PanelContractTask",data:"待我处理"
        },
        {
           x:0,y:6,w:5,h:5,control:"PanelContractList",data:"我的项目"
        },
        {
           x:5.1,y:6,w:7.4,h:5,control:"PanelContractIncome",data:"收款记录"
        },
        {
           x:12.5,y:6,w:5.5,h:5,control:"PanelContractPayment",data:"付款记录"
        },
        {
           x:5.1,y:0,w:9.2,h:6,control:"PanelContractDashboardA",data:"经营数据（分析）"
        },
        {
           x:14.25,y:0,w:3.8,h:6,control:"PanelContractDashboardC",data:"经营数据（分析）"
        },
        {
           x:18.1,y:0,w:6,h:3,control:"PanelContractNotice",data:"第三方合同"
        },
        {
           x:18.1,y:3,w:6,h:4,control:"PanelContractRisk",data:"风险防控"
        },
        {
           x:18.1,y:7,w:6,h:4,control:"PanelContractDispute",data:"合同纠纷"
        },
        ]
        }
        
      }
    }
  }
</script>
