<template>
  <div class="flex-col flex-center inbgz-sortable-marker">
    <BaseIcon 
        icon="md-arrow-dropup" 
        size="16" 
        style="margin:-4px 1px;" 
        :color="value=='asc'?color:'var(--subtext3)'"
        @click.stop.native="$emit('input',value == 'asc'?'default':'asc')"
    >
    </BaseIcon>
    <BaseIcon 
        icon="md-arrow-dropdown" 
        size="16" 
        style="margin:-4px 1px;" 
        :color="value=='desc'?color:'var(--subtext3)'" 
        @click.stop.native="$emit('input',value == 'desc'?'default':'desc')"
    ></BaseIcon>
</div>
</template>

<script>
export default {
    props:{
        value:{
            type:String
        },
        color:{
            type:String,
            default:'darkred'
        }
    },
}
</script>

<style lang="less">
.inbgz-sortable-marker{
    i{
        cursor: default !important;

    }

    i:hover{
        color:var(--primary) !important;
    }

    i:active{
        position: relative;
        right:-1px;
        bottom:-1px
    }
}
</style>