<style lang="less">
.l-table-header {
  border-bottom: 1px solid var(--border);
}
</style>

<template>
  <div>
    <BaseEmpty v-if="items.length == 0" image style="padding:20px;">
      <Button icon="md-add" size="small" @click="add">新增一行</Button>
    </BaseEmpty>

    <template v-else>

      <Row :gutter="10"  class-name="l-bill">
        <Col :span="1" align="center">拖</Col>
        <Col :span="1" align="center">序</Col>
        <Col :span="5" align="center">服务类型</Col>
        <Col :span="4" align="center">金额</Col>
        <Col :span="3" align="center">类型</Col>
        <Col :span="4" align="center">扣除金额</Col>
        <Col :span="3" align="center">免开票</Col>
        <Col :span="2" align="center">操作</Col>
      </Row>
      <Draggable v-model="items" :options="{animation:500}" handle=".draggable">
        <template v-for="(data,index) in items">
          <Row :key="data.id" :gutter="10" class-name="l-bill">
            <Col :span="1"  align="center">
            <div class="draggable" style="cursor:grab">
              <BaseIcon icon="md-menu" />
            </div>
            </Col>
            <Col :span="1"  align="center">{{index+1}}</Col>
            <Col :span="5"> <Select size="small" v-model="data.business_type" transfer placeholder="请选择" @on-focus="focused=2" clearable>
              <template v-for="(r,i) in business_types">
                <Option :value="r" :key="i" :label="r"><span class="marker" :style="`background:${business_type_colors[i]};margin-right:5px;`">{{r[0]}}</span>{{r}}</Option>
              </template>
            </Select></Col>
            <Col :span="4">
            <div class="flex-wrap"><Input number v-model="data.amount" size="small" /><Button icon="md-build" size="small" style="margin-left:5px" v-if="data.amount > value.amount || amount != value.amount" @click="fix(index)" /></div>
            </Col>
            <Col :span="3">
            <Select size="small" v-model="data.type" transfer placeholder="请选择" @on-focus="focused=3" clearable>
              <template v-for="(r,i) in splitType">
                <Option :value="r" :key="i" :label="r"><span class="marker" :style="`margin-right:5px;background:#333`">{{r[0]}}</span>{{r}}</Option>
              </template>
            </Select>
            </Col>
            <Col :span="4">
              <div class="flex-wrap"><Input number v-model="data.deduct_amount" @on-change="handleChangeDeduct" size="small" placeholder="请输入" /></div>
            </Col>
            <Col :span="3" align="center">
              <div class="flex-wrap flex-center"><i-switch size="small" v-model="data.unbilling" transfer /></div>
            </Col>
            <Col :span="2" style="text-align:center"><Button size="small" @click="remove(index)" icon="md-remove"></Button></Col>

          </Row>
        </template>
      </Draggable>
      <div class="flex-wrap flex-right" style="margin:10px">

        <Button icon="md-add" style="margin-bottom:10px" size="small" @click="add">新增一行</Button>
      </div>
      <Row :gutter="10" class-name="l-bill l-summary" style="border-top:1px solid var(--border)" v-if="items.length > 0">
      
        <Col :span="4" align="flex-end">
        <div class="flex-wrap flex-left">
          合计
          <BaseAmount full :value="amount" style="font-weight:bold;margin-left:15px;"></BaseAmount>
        </div>
        </Col>
        <Col :span="6">
         <div class="flex-wrap flex-right"> 扣除额 <BaseAmount full style="margin-left:10px;font-weight:bold;" :value="deduct_amount"></BaseAmount>
        </div>
        </Col>
        <Col :span="6">
         <div class="flex-wrap flex-right"> 营收额 <BaseAmount full style="margin-left:10px;font-weight:bold;" :value="amount - deduct_amount"></BaseAmount>
        </div>
        </Col>
        <Col :span="6">
         <div class="flex-wrap flex-right"> 开票额 <BaseAmount full style="margin-left:10px;font-weight:bold;" :value="bill_amount"></BaseAmount>
        </div>

        </Col>
      </Row>
      <div>
        <Alert :type="check_result.state" size="small" style="padding:3px 5px">{{check_result.message}}</Alert>
      </div>
    </template>
  </div>
</template>
<script>

import Draggable from 'vuedraggable'
var timer = null
export default {
  components: { Draggable },
  data() {
    return {
      error: "",
      items: [],
      business_types: ['全过程咨询', '项目管理', '房建监理', '市政监理', '造价咨询', 'BIM咨询', '招标代理', '对外合作', '其他'],
      business_type_colors: ['orange', '#369', 'green', 'darkred', 'purple', '#3af', '#551', 'pink', '#333'],
      splitType:["自营","联合体","外委"]
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    this.init(this.value)
  },
   watch:{
    value:{
      handler(v){
        this.init(v)
      },
    }
  },
  computed: {
    amount() {
      return this.items.reduce((c, item) => {
        return c + item.amount
      }, 0)

    },
    bill_amount(){
      return this.items.reduce((c, item) => {
        if(!item.unbilling)
          return c + item.amount - (item.deduct_amount || 0)
        else 
          return c
      }, 0)
    },
    deduct_amount(){
      return this.items.reduce((c,item)=>{
        return c + (item.deduct_amount || 0)
      },0)
    }, check_result() {
      if (this.amount > this.value.amount + 0.001)
        return { state: 'error', message: `总金额${this.amount}大于合同额${this.value.amount}` }
      else if (this.amount < this.value.amount - 0.0001)
        return { state: 'error', message: `总金额${this.amount}小于合同额${this.value.amount}，未拆分完整` }
      else if (this.items.filter(v => !v.business_type).length > 0)
        return { state: "error", message: "数据未填写完整,请删除不必要的空行" }
      return { state: 'success', message: '数据已验证通过' }
    }
  },
  methods: {
    init(v){
      if(this.timer){
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(()=>{
        if (Array.isArray( v.condition_groups))
          this.items = v.condition_groups
        else{
          this.items = []
          this.emitChange()
        }
      },500)
    },
    emitChange() {
      this.$set(this.value, 'condition_groups', this.items)
    },
    validate() {
      if (this.check_result.state == "success")
        return true
    },
    handleChangeDeduct(item,e){
      let value = e.detail.value
      this.$set(item,'deduct_factor',value / item.amount)
    },
    add() {
      this.items.push({ amount: 0 ,type:'自营'})
      this.emitChange()
    },
    remove(i) {
      this.items.splice(i, 1)
      this.emitChange()
    },
    calc(i){
      let data = this.items[i]
      this.$set(data,'deduct_amount',parseInt(data.amount * data.deduct_factor) / 100)
    },
    fix(i) {
      let data = this.items[i]
      this.$set(data, 'amount',parseInt(100* (this.value.amount - this.amount + data.amount))/100)
      this.items.splice(i, 1, data)
    }
  }
}
</script>

<style lang="less" scoped>
.l-summary {
  border-bottom: none;
}
</style>