<template>
   <div class="prop-panel" >
    <div style="padding:10px;height:60px;background:#00000033;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">
               {{element?.properties?.name || (element.text?element.text.value:'')}}
               <div style="font-size:12px;color:var(--subtext1);">{{element.type || '流程'}}</div>
            </div>
        <template v-if="prop.tools && prop.tools.length > 0">
           
           
        <div class="flex-wrap" style="padding:5px" >
            
            <template v-for="t in prop.tools">
            <div class="prop-item" :key="t.key" :title="t.title" @click="$emit('on-event',t.key)" style="">
                <BaseIcon :icon="t.icon" :size="15"></BaseIcon>
            </div>
            </template>
        </div>
        </template>
       
        <div style="border-top:2px solid var(--border);"  v-if="element && prop.attrs && prop.attrs.length > 0">
            <div class="flex-wrap" style="padding:0 5px;height:25px;background:#00000033;">
                节点
            </div>
           <BaseProperty :attrs="prop.attrs" :value="element" @on-change="handleChangeAttr" />
        </div>
       
       <template v-if="element && element.properties && prop.props && prop.props.length > 0">
       <template v-for="g in groups">
        <div style="border-top:2px solid var(--border);" :key="g.name" >
            <div class="flex-wrap" style="padding:0 5px;height:25px;background:#00000033;">
                {{ g.name }}
            </div>
           <BaseProperty 
            :attrs="g.list"
            v-model="element.properties"
            @input="element.properties = $event" @on-change="handleChangeProp"
            style="overflow: hidden;border-bottom-right-radius:10px;"
            :env="env"
            />
        </div>
      </template>
    </template>
    </div>
</template>

<script>
export default {
    data(){
        return {
            focused:null
        }
    },
    props:['element','prop','env'],
    computed:{
      groups(){
        let groupmap = {}
        this.prop.props.forEach(p=>{
          if(groupmap[p.group]){
            groupmap[p.group].list.push(p)
          }else{
            groupmap[p.group] = {
              name:p.group,
              list:[p]
            }
          }
        })
        return Object.values(groupmap)
      }
    },
    methods:{
        handleChangeAttr({key,value}){
            this.$set(this.element,key,value)
            this.$emit('on-change')
        },
        handleChangeProp({key,value}){
            this.$emit('on-change-prop',{key,value})
        }
    }
    
}
</script>

<style lang="less" scoped>
.prop-panel {
    border: 3px solid var(--border);
    background:var(--bg1);
    width:240px;
    min-height:200px;
    border-radius:10px;
    box-shadow: 2px 2px 3px 3px var(--border);
    color:var(--text1);
    
  }

  
  .prop-divider{
    width:2px;height:90%;
    background:var(--bg1);
    margin:0 5px;
  }
  
  .prop-item {
    cursor: pointer;
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    
    color:var(--text1);
    text-align: center;
    border:1px solid var(--border);width:30px;height:30px;
  }

  .prop-item:hover{
    box-shadow: 1px 1px 1px 1px var(--border);
    filter:brightness(1.1);
    border-color:var(--active);
    i{
        transition: color 0.5s;
        color:var(--active) !important;
    }
  }

  .prop-item:active{
    box-shadow: -1px -1px 1px 1px var(--border);
    filter:brightness(1.1);
    i{
        transition: color 0.5s;
        color:var(--active) !important;
    }
  }

  .l-prop-item{
    border-bottom:1px solid #000000aa;
  }
  .l-prop{
    width:70px;
    height:25px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-overflow:ellipsis;
  }

  .l-value{
    height:25px;
    width:calc(100% - 70px);
    border-left:1px solid #000000aa;
  }

  
</style>