<template>
  <div class="container">
    <div class="filter-box">
      <Button type="primary" style="padding:0 8px;">
        <BaseIcon icon="md-add" />
      </Button>
      <Input search clearable v-model="filter.searchText" style="width:200px;margin-left:10px;" />
      <Select style="margin-left:5px;width:200px"></Select>
      <ButtonGroup style="margin-left:10px">
        <Button type="primary">待我处理({{ counts.todo || 0 }})</Button>
        <Button>我参与的({{ counts.engaged || 0 }})</Button>
        <Button>已闭合的({{ counts.closed || 0 }})</Button>
        <Button>所有工作({{ counts.all || 0 }})</Button>
      </ButtonGroup>
    </div>
    <div style="height:calc(100% - 50px)">
    <BaseTable :columns="cols" :data="items" border :paged="true" />
  </div>

    <Drawer v-model="showInstance" :width="800" title="查看流程">
      <FlowInstance :flow-id="current.id" />
    </Drawer>
  </div>
</template>

<script>
import moment from "moment";
import FlowInstance from './instance'
import CreateInstance from './create'
export default {
  components:{
    FlowInstance,CreateInstance
  },
  data() {
    return {
      filter: {
        searchText: ""
      },
      current:{},
      showInstance:false,
      counts: {
        todo: 1,
        engaged: 1,
        closed: 3,
        all: 30
      },
      items: [
        {
          id: "FInstance-20230721",
          flow_name: "项目创建",
          group: "成本合约",
          name: "创建项目'新洲银座'",
          status: "进行中",
          created_at: moment()
            .subtract(3, "days")
            .format(),
          created_by: "ed4a5bc6-3b83-11eb-8e1e-c15d5c7db744",
          currents: [
            {
              inst_id: "i001",
              node_id: "3",
              node_name: "事业部审核",
              charger_id: "ed4a5bc6-3b83-11eb-8e1e-c15d5c7db744"
            },
            {
              inst_id: "i002",
              node_id: "4",
              node_name: "财务审核",
              charger_id: "ed4a5bc6-3b83-11eb-8e1e-c15d5c7db744"
            }
          ],
          updated_at: moment().format()
        },
        {
          id: "FInstance-20230721",
          flow_name: "项目创建",
          group: "成本合约",
          name: "创建项目'新洲银座'",
          status: "进行中",
          created_at: moment()
            .subtract(3, "days")
            .format(),
          created_by: "ed4a5bc6-3b83-11eb-8e1e-c15d5c7db744",
          currents: [
            {
              inst_id: "i001",
              node_id: "1",
              node_name: "事业部审核",
              charger_id: "ed4a5bc6-3b83-11eb-8e1e-c15d5c7db744"
            },
          ],
          updated_at: moment().format()
        }
      ]
    };
  },
  methods:{
    openInstance(t){
      this.current = t
      this.showInstance = true
    },
    handleToggleDisabled(t){

    },
    handleDelete(t){
      this.Confirm("确定删除此工作流（删除后数据不可恢复)?",()=>{
        
      })
    }
  },
  computed: {
    cols() {
      return [
        {
          type: "index",
          title: "序号",
          width: 80
        },
        {
          type: "text",
          key: "group",
          title: "分组",
          width: 100,
          align: "center",
          render: (h, { row }) => {
            return h(
              "span",
              {
                style:
                  "padding:2px 5px;background:var(--bg2);border:1px solid var(--border);border-radius:5px"
              },
              row.group
            );
          }
        },
        {
          type: "text",
          key: "flow_name",
          title: "类型",
          align: "center",
          width: 120,
          render: (h, { row }) => {
            return h(
              "span",
              {
                style:
                  "padding:2px 5px;background:var(--bg2);border:1px solid var(--border);border-radius:5px"
              },
              row.flow_name
            );
          }
        },
        {
          key: "name",
          type: "text",
          title: "标题",
          linkEvent: "open",
          align: "center",
          minWidth: 200,
          render:(h,{row})=>{
            let icon = h("BaseIcon",{props:{icon:"lianjieliu",color:"var(--hover-text)",size:14},style:"margin-right:10px;background:var(--primary);padding:5px;border-radius:5px;"})
            let name = h("a",{style:"font-size:14px;",on:{
              click:()=>{
                this.openInstance(row)
              }
            }},[row.name])
            return h("div",{class:"flex-wrap",style:"padding:0 10px;"},[icon,name])
          }
        },
        {
          type: "user",
          key: "created_by",
          width: 100,
          title: "创建人"
        },
        {
          type: "time",
          key: "created_at",
          width: 100,
          title: "创建时间"
        },

        {
          title: "当前",
          align: "center",
          children: [
            {
              type: "text",
              align: "center",
              title: "当前节点",
              width: 300,
              render: (h, { row }) => {
                let nodeDoms = row.currents.map(c =>
                  h(
                    "div",
                    {
                      class: "flex-wrap flex-center in-table-line",
                    },
                    [h("span", c.node_id + " - " + c.node_name)]
                  )
                );
                return h("div", nodeDoms);
              }
            },
            {
              type: "user",
              align: "center",

              title: "当前负责人",
              width: 100,
              render: (h, { row }) => {
                let nodeDoms = row.currents.map(c =>
                  h(
                    "div",
                    {
                      class: "flex-wrap flex-center in-table-line",
                    },
                    [
                      h("BaseNameBoard", {
                        props: { uid: c.charger_id }
                      })
                    ]
                  )
                );
                return h("div", nodeDoms);
              }
            },
            {
              type: "time",
              key: "updated_at",
              title: "最近更新",
              width: 100,
              render: (h, { row }) => {
                let nodeDoms = row.currents.map(c =>
                  h(
                    "div",
                    {
                      class: "flex-wrap flex-center in-table-line",
                    },
                    [
                      h(
                        "span",
                        c.updated_at ? moment(c.updated_at).fromNow() : "-"
                      )
                    ]
                  )
                );
                return h("div", nodeDoms);
              }
            }
          ]
        },
        {
          type: "text",
          key: "status",
          title: "状态",

          align: "center",
          width: 100,
          render: (h, { row }) => {
            return h("span", row.status);
          }
        },

        {
          type: "tools",
          title: "操作",
          render: (h, { row }) => {
            let domButtonProcess = h(
              "Button",
              { props: { size: "small", icon: "md-create", type: "primary" },
            on:{
              click:()=>{
                this.openInstance(row)
              }
            } },
              "处理"
            );
            let domButtonStop = h(
              "Button",
              { props: { size: "small", icon: "md-pause", type: "error" },
              on:{
              click:()=>{
                this.handleToggleDisabled(row)
              }
            } },
              "禁用"
            );
            let domButtonDelete = h(
              "Button",
              { props: { size: "small", icon: "md-trash" },on:{
              click:()=>{
                this.handleDelete(row)
              }
            } },
              "删除"
            );
            let buttons = [domButtonProcess, domButtonStop, domButtonDelete];
            return h(
              "div",
              { class: "flex-wrap flex-center split10" },
              buttons
            );
          }
        }
      ];
    }
  }
};
</script>
<style lang="less">
.filter-box {
  height: 45px;
  background: var(--bg3);
  display: flex;
  align-items: center;
  padding: 0 5px;
  border-bottom: 1px solid var(--border);
}

.in-table-line{
  min-height: 40px;
  border-bottom: 1px solid var(--border);
}
.in-table-line:last-child{
  border:none;
}
</style>