<template>
  <div style="padding:20px;">
    <Row :gutter="20">
        <Col :span="6">
            <Card title="现有平台运维">
                

               
               <template v-for="w in webs">
                <Row class="web-item" align="middle"  :gutter="10" :key="w.id">
                    <Col :span="1">
                        <BaseState dot  :value="w.state || 4"/>
                    </Col>
                    <Col :span="19">{{ w.name }}</Col>
                  
                    <Col :span="4">
                    <a :href="w.url" target="_blank" style="font-size:12px;">
                        <BaseIcon icon="md-link" :size="12" style="margin-right:5px;" />
                        进入
                    </a>
                    </Col>
                </Row>
               </template>
            </Card>
            <Card title="服务器接口运维" style="margin-top:10px;">
                

               
                <template v-for="w in apis">
                 <Row class="web-item" align="middle" :gutter="15" :key="w.id">
                     <Col :span="1">
                         <BaseState dot light  :value="w.state || 4" />
                     </Col>
                     <Col :span="19">{{ w.name }}</Col>
                   
                     <Col :span="4">
                     <a :href="w.url" target="_blank" style="font-size:12px;">
                         <BaseIcon icon="md-link" :size="12" style="margin-right:5px;" />
                         进入
                     </a>
                     </Col>
                 </Row>
                </template>
             </Card>
             <Card title="服务器管理" style="margin-top:10px;">
                

               
                <template v-for="w in apis">
                 <Row class="web-item" align="middle" :gutter="10" :key="w.id">
                     <Col :span="1">
                         <BaseState dot light :value="w.state || 4"/>
                     </Col>
                     <Col :span="19">{{ w.name }}</Col>
                   
                     <Col :span="4">
                     <a :href="w.url" target="_blank" style="font-size:12px;">
                         <BaseIcon icon="md-link" :size="12" style="margin-right:5px;" />
                         进入
                     </a>
                     </Col>
                 </Row>
                </template>
             </Card>
        </Col>
        <Col :span="12">
            <Card title="当前研发项目计划">
                <div class="flex-wrap">
                <template v-for="p in projects">
                    <div 
                        class="web-item" 
                        :class="{
                            'web-item-selected':activeProjectId == p.id
                        }" 
                        @click="activeProjectId = p.id"
                        :key="p.id"
                    >
                        {{ p.name }}
                    </div>
                  </template>
                </div>
                  <div class="web-item">
                    <Row :gutter="5" style="margin-bottom:5px;border-bottom:1px solid var(--border);padding-bottom:5px;font-weight:bold;">
                    <Col :span="2" align="center">序号</Col>
                    <Col :span="10" align="center">任务名</Col>
                    <Col :span="4" align="center">进度</Col>
                    <Col :span="8" align="center">描述</Col>
                    </Row>
                    <template v-if="activeProject && activeProject.tasks">
                    <Row :gutter="5" v-for="t in activeProject.tasks" class="web-item-row" :key="t.id">
                        <Col :span="2" align="center">{{ t.id }}</Col>
                        <Col :span="10"><a>{{ t.name }}</a></Col>
                        <Col :span="4" align="center">{{ t.percent }}</Col>
                        <Col :span="8" align="center">{{ t.desc || '-' }}</Col>
                    </Row>
                </template>
                  </div>
               
            </Card>
        </Col>
        <Col :span="6">
            <Card title="需求管理">
                <div class="web-item">
                    <Row :gutter="5" style="margin-bottom:5px;border-bottom:1px solid var(--border);padding-bottom:5px;font-weight:bold;">
                    <Col :span="4" align="center">部门</Col>
                    <Col :span="6" align="center">类型</Col>
                    <Col :span="12" align="center">描述</Col>
                    </Row>
               <Row :gutter="8" class="web-item-row" v-for="r in requests" :key="r.id" align="middle">
                <Col :span="4" align="center">{{ r.dep }}</Col>
                    <Col :span="6" align="center">{{ r.type }}</Col>
                    <Col :span="12" align="left" style="color:var(--primary)">{{ r.desc }}</Col>
               </Row>
               </div>
            </Card>
        </Col>
    </Row>

  </div>
</template>

<script>
export default {
    data(){
        return {
            servers:[],
            webs:[
                {
                    id:1,
                    name:'高专企业信息门户',
                    version:'1.0.0',
                    state:2,
                    url:'https://www.inbgz.com'
                },{
                    id:2,
                    name:'高专企业信息平台',
                    version:'2.0.0',
                    url:'https://a.inbgz.com'
                },{
                    id:3,
                    name:'项目管理平台 - 附加',
                    version:'3.1.2',
                    url:'http://extra.inbgz.com'
                },{
                    id:4,
                    name:'项目管理平台',
                    version:'12.0.3',
                    url:'http://zzlatm.gicp.net:10000'
                },{
                    id:5,
                    name:'监理信息平台',
                    version:'12.0.3',
                    url:'http://zzlatm.gicp.net:10020'

                },{
                    id:6,
                    name:'高专企业门户（旧)',
                    version:'5.0.3',
                    url:'http://www.gzqgczx.com'
                }
            ],
            apis:[{
                id:1,
                name:'高专服务接口',
                url:'https://api.inbgz.com'
            }],
            projects:[{
                id:1,
                name:'高专信息平台',
                tasks:[{
                    id:'t1',
                    name:'运维及研发平台',
                    state:1,
                    percent:50
                },{
                    id:'t2',
                    name:'运维及研发平台',
                    state:1,
                    
                    percent:30
                },{
                    id:'t3',
                    name:'运维及研发平台',
                    state:1,
                    
                    percent:10
                }]
            },{
                id:2,
                name:'高专企业门户(inbgz.com)',
                tasks:[{
                    id:'t2',
                    name:'第一版优化工作',
                    state:1
                }]
            }],
            activeProjectId:1,
            requests:[{
                id:1,
                dep:'造价部',
                type:'新增模块',
                desc:'需要一个新的指标查询，要求能按项目以及项目内建筑为单位查看造价指标情况'
            },{
                id:2,
                dep:'造价部',
                type:'新增模块',
                desc:'需要一个新的指标查询，要求能按项目以及项目内建筑为单位查看造价指标情况'
            }]
        } 
    },
    computed:{
        activeProject(){
            return this.projects.find(p=>p.id == this.activeProjectId)
        }
    }
}
</script>

<style lang="less">
.web-item{
    margin:5px;
    
    color:var(--text2);
    background:var(--bg2);
    border:1px solid var(--border);
    border-radius: 5px;
    padding:2px 5px;
}

.web-item-selected{
    background:var(--primary);
    color:var(--hover-text);
}

.web-item-row{
    margin:2px;
    border-bottom:1px solid var(--border)
}

.web-item-row:hover{
    cursor: pointer;
    background:var(--bg3);
}

.web-item-row:last-child{
    border:none;
}
</style>