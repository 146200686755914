import moment from 'moment'
import UTIL from '@/utils'
import {
  pick,
  cloneDeep
} from 'lodash'
const fix = (a, n) => UTIL.toFixed(a, n)
const percent = (a, b) => !b ? 0 : fix(a * 100 / b)
// -- [a,b] -->
const time_duration = (a, b, t = 'days') => (a && b) ? fix(moment.duration(moment(b) - moment(a)).as(t) || 1) : 0
// --[[a,b],[c,d]]-->
const time_int_duration = (a, b, a2, b2, t = 'days') => {
  let [x, y] = UTIL.timeIntersection(a, b, a2, b2)
  if (!x || !y) return 0
  else
    return fix(time_duration(x, y, t))
}
let o = {}

/** 计算任务
 * 
 */
o.computePlan = (plan, started_at) => {
   if (!started_at || !moment(started_at).isValid())
     throw new Error(`时间录入不完整`)

  started_at = moment(started_at).clone()

  plan.nodes.sort((a, b) => {
    if (moment(a.plan_started_at).isSame(moment(b.plan_started_at))) {
      return a.plan_duration > b.plan_duration ? 1 : -1
    }
    return moment(a.plan_started_at).isAfter(moment(b.plan_started_at)) ? 1 : -1
  })
  plan.duration = 0

  plan.nodes.forEach((n, i, a) => {
    if (i == 0) {
      n.started_at = started_at.clone()
    } else {
      n.started_at = a[i - 1].finished_at.clone()
    }

    n.duration = n.plan_duration || 0
    plan.duration +=  n.duration
    n.finished_at = n.started_at.clone().add(n.duration, "days")
  })

  plan.started_at = started_at.clone()
  plan.finished_at = plan.nodes[plan.nodes.length - 1].finished_at.clone()
  return plan
}

o.computeNodes = (p) => {
  p.has_plan = false
  p.has_adjust_plan = false

  if (Array.isArray(p.nodes) && p.nodes.length > 0) {
    let adjust_map = {}
    let origin = {
      name: "签约计划",
      version: "origin",
      nodes: cloneDeep(p.nodes.filter(v => v.version == 'origin'))
    }
    p.has_plan = true

    if (origin.nodes.length == 0) {
      throw new Error("orign plan has no nodes")
    }

    origin.nodes.sort((a, b) => {
      if (moment(a.plan_started_at).isSame(moment(b.plan_started_at))) {
        return a.plan_duration > b.plan_duration ? 1 : -1
      }
      return moment(a.plan_started_at).isAfter(moment(b.plan_started_at)) ? 1 : -1
    })

    if (!p.started_at)
      p.started_at = origin.nodes[0].plan_started_at

    p.nodes.filter(v => v.version != 'origin').forEach(n => {
      if (adjust_map[n.version]) {
        adjust_map[n.version].nodes.push(n)
      } else {
        adjust_map[n.version] = {
          name: `调整计划(${n.version})`,
          version: n.version,
          nodes: [n]
        }
      }
    })
    let adjusts = Object.values(adjust_map)
    if(adjusts.length > 0)
      p.has_adjust_plan = true
    adjusts.sort((a, b) => a.version > b.version ? 1 : -1)
    let tmp_origin_nodes = cloneDeep(origin.nodes)
    adjusts.forEach(v => {
      v.nodes.forEach(adjust_node => {
        let node = tmp_origin_nodes.find(n => n.id == adjust_node.version_to)
        if (node) {
          // Object.assign(node, cloneDeep(pick(adjust_node, ['plan_duration', 'hrplans', 'version'])))
          if(adjust_node.plan_duration)
            node.plan_duration = adjust.plan_duration
          if(adjust_node.hrplans)
            node.hrplans = [...adjust_node.hrplans]
          node.version = adjust_node.version
        } else {
          console.error("version_to invalid:" + adjust_node.version_to)
        }
      })

      v.nodes = cloneDeep(tmp_origin_nodes)

    })
    p.progress_plans = [origin, ...adjusts]
    p.progress_plans.forEach(plan => o.computePlan(plan, p.started_at))
    p.origin_plan = p.progress_plans[0]
    p.current_plan = p.progress_plans[p.progress_plans.length - 1]
  }

}

/** 计算时间
 *  依赖于：进度计划
 *  对于有进度计划的项目，其竣工、结束时间均以进度计划为准
 */
o.computeTime = (p, now) => {
  if (p.has_plan) {
    p.plan_started_at = p.started_at
    p.plan_finished_at = p.current_plan.finished_at
    p.actual_started_at = p.started_at
    p.actual_finished_at = moment(now).isAfter(moment(p.current_plan.finished_at)) ? p.current_plan.finished_at : now
  } else {
    p.plan_started_at = p.started_at
    p.plan_finished_at = p.completed_at
    p.actual_started_at = p.started_at
    p.actual_finished_at = moment(now).isAfter(moment(p.plan_finished_at)) ? p.plan_finished_at : now
  }
}

o.computeProduction = (p) => {

}

o.computeData = (p, prefix, started_at, finished_at, now = moment()) => {
  if (!p.started_at)
    throw new Error(`时间录入不完整`)

  if (prefix == 'total') {
    started_at = moment(p.started_at)
    finished_at = p.finished_at ? moment(p.finished_at) : now.clone()
  } else if (prefix == 'year') {
    started_at = now.clone().startOf("year")
    finished_at = now.clone().endOf("year")
  } else if (prefix == 'lastyear') {
    started_at = now.clone().subtract(1, "years").startOf("year")
    finished_at = started_at.clone().endOf("year")
  }

  
  let total = {}

  if (p.has_plan) {
    let origin_started_at = p.origin_plan.started_at.clone()
    let origin_finished_at = p.origin_plan.finished_at.clone()
    let last_started_at = p.current_plan.started_at.clone()
    let last_finished_at = p.current_plan.finished_at.clone()
    if (prefix == 'total') {
      // started_at = last_started_at
      // finished_at = last_finished_at
    }
    let [origin_plan_started_at, origin_plan_finished_at] = UTIL.timeIntersection(started_at || origin_started_at,
      finished_at || origin_finished_at,
      origin_started_at,
      origin_finished_at)


    let [plan_started_at, plan_finished_at] = UTIL.timeIntersection(
      started_at || last_started_at,
      finished_at || last_finished_at,
      last_started_at,
      last_finished_at
    )
    let [actual_started_at, actual_finished_at] = UTIL.timeIntersection(
      started_at || last_started_at,
      finished_at || last_finished_at,
      last_started_at,
      now
    )


    if (!plan_started_at || !plan_finished_at || !actual_started_at || !actual_finished_at) {
      return {}
    }

    // 服务工期
    let whole_duration = time_duration(last_started_at, last_finished_at)
    total.plan_duration = fix(time_duration(plan_started_at, plan_finished_at), 0)
    total.origin_plan_duration = time_duration(origin_plan_started_at, origin_plan_finished_at)

    total.actual_duration = fix(time_duration(actual_started_at, actual_finished_at), 0)
    total.remain_duration = fix(total.plan_duration - total.actual_duration,1)
    total.duration_percent = fix(percent(total.actual_duration, total.plan_duration))
    total.duration_offset = fix(total.plan_duration - total.origin_plan_duration, 2)
    total.duration_offset_percent = percent(Math.abs(total.duration_offset), total.plan_duration)

    // 产值
    let amount = p.amount
    total.plan_production = fix(p.amount * total.plan_duration / whole_duration, 2)
    total.origin_plan_production = fix(p.amount * total.origin_plan_duration / whole_duration, 2)

    total.actual_production = fix(total.actual_duration / whole_duration * amount, 2)
    total.predict_production = total.actual_prodction + fix(p.amount * (1 - total.plan_duration / whole_duration), 2)
    total.production_percent = percent(total.actual_production, total.plan_production)
    total.production_offset = fix(total.plan_production - total.origin_plan_production, 2)
    total.production_offset_percent = percent(Math.abs(total.production_offset), total.origin_plan_production)

    // 收费
    total.plan_bill = fix(p.amount / 10000, 2)
    let billplans = p.bill_plans

    let bills = []

    if (p.bills)
      bills = p.bills.filter(v => moment(v.billed_at).isBetween(moment(plan_started_at), moment(now)))
    let bill_amount = bills.reduce((acc, item) => acc + item.amount, 0)

    total.actual_bill = fix(bill_amount, 2)
    if (billplans && billplans.length > 0) {
      billplans = billplans.map((v, i, a) => {
        let d = {
          ...v
        }
        if (i == 0)
          d.started_at = actual_started_at.clone()
        else
          d.started_at = a[i - 1].finished_at || a[i - 1].plan_finished_at
        if (d.finished_at)
          d.finished = true
        d.finished_at = d.finished_at || d.plan_finished_at
        return d
      })
      total.plan_bill = billplans.filter(v => v.finished_at && moment(v.finished_at).isBetween(moment(plan_started_at), moment(plan_finished_at))).reduce((acc, item) => {
        return acc + item.amount
      }, 0)

      total.actual_bill = bills.reduce((acc, item) => {
        return acc + (item.amount || 0)
      }, 0)
      // total.actual_bill = billplans.filter(v => v.finished).reduce((acc, item) => {
      // 	return acc + (item.amount || 0))
      // }, 0)
    }

    p.remain_amount = p.amont - p.actual_bill



    total.bill_percent = percent(total.actual_bill, total.plan_bill)
    total.bill_offset = 0
    total.bill_offset_percent = 0

    // 人员
    let actual_hrv = 0
    let plan_hrv = 0
    let remain_hrv = 0
    let origin_plan_hrv = 0
    let origin_current_hrv = 0
    p.origin_plan.nodes.forEach(n => {
      if (Array.isArray(n.hrplans)) {

        origin_plan_hrv += (n.hrplans.reduce((acc, item) => {
          return acc + ((item.factor || 0) * time_int_duration(origin_plan_started_at, origin_plan_finished_at, n.started_at, (n.finished_at || now)))
        }, 0))

        origin_current_hrv += (n.hrplans.reduce((acc, item) => {
          return acc + (item.factor || 0) * time_int_duration(actual_started_at, actual_finished_at, n.started_at, (n.finished_at || now))
        }, 0))


      }
    })


    let endOfYear = moment(now).clone().endOf('year')
    p.current_plan.nodes.forEach(n => {
      if (Array.isArray(n.hrplans)) {
        plan_hrv = plan_hrv + (n.hrplans.reduce((acc, item) => {
          return acc + (item.factor || 0) * time_int_duration(actual_started_at, actual_finished_at, n.started_at, (n.finished_at || now))
        }, 0))

        remain_hrv = remain_hrv + (n.hrplans.reduce((acc, item) => {
          return acc + (item.factor || 0) * time_int_duration((now.isAfter(actual_started_at) ? now : p.actual_finished_at), p.actual_finished_at, n.started_at, (n.finished_at || p.actual_finished_at))
        }, 0))
      }

      if(endOfYear.isBetween(n.started_at,n.finished_at)){
        total.end_progress = n.name
      }
    })
    if (endOfYear.isAfter(p.current_plan.finished_at) && p.current_plan.nodes.length > 0)
      total.end_progress = p.current_plan.nodes[p.current_plan.nodes.length-1].name

    if (Array.isArray(p.hr)) {
      actual_hrv = p.hr.reduce((acc, item) => {
        return acc + (item.factor || 0) * time_int_duration(actual_started_at, actual_finished_at, item.started_at, (item.finished_at || now))
      }, 0)
    }
    total.origin_plan_hrv = fix(origin_plan_hrv / 30.4, 2)
    total.plan_hrv = fix(plan_hrv / 30.4, 2)
    total.actual_hrv = fix(actual_hrv / 30.4, 2)
    total.remain_hrv = fix(remain_hrv / 30.4, 2)
    total.predict_hrv = fix((actual_hrv + remain_hrv)/30.4,2)
    total.hrv_percent = percent(actual_hrv, plan_hrv)
    total.offset_hrv = fix((actual_hrv - origin_current_hrv) / 30.4, 2)
    total.offset_hrv_percent = percent(Math.abs(total.offset_hrv), origin_plan_hrv)


    // 建安工程量
    total.actual_bp = 0
    total.plan_bp = 0
    if (p.building_production) {
      total.plan_bp = fix(p.building_production)

      total.actual_bp = fix(p.bp.reduce((acc, item) => {
        return acc + item.amount * time_int_duration(actual_started_at, actual_finished_at, item.started_at || actual_started_at, item.finished_at) / time_duration(item.started_at || actual_started_at, item.finished_at)
      }, 0), 2)
      if (total.plan_bp) {
        total.bp_percent = percent(total.actual_bp, total.plan_bp)
      }
    }

    // 人均产值
    total.plan_hra_production = 0
    total.predict_hra_production = 0
    total.actual_hra_production = 0
    total.actual_hra_bill = 0
    total.actual_hra_bp = 0
    let origin_plan_hra_production = 0

    if (total.plan_hrv) {
      total.plan_hra_production = fix(total.plan_production * 30.4 * 12 / total.plan_hrv, 2)
    }

    if(total.predict_hrv){
      total.predict_hra_production = fix(total.predict_production * 30.4 * 12 / total.predict_hrv, 2)
    }

    if (origin_plan_hrv) {
      total.origin_plan_hra_production = fix(total.origin_plan_production * 30.4 * 12 / origin_plan_hrv, 2)

    }

    if (total.actual_duration != 0 && actual_hrv != 0) {
      total.actual_hra_production = fix(total.actual_production * 30.4 * 12 / actual_hrv, 2)
      total.actual_hra_bill = fix(total.actual_bill * 30.4 * 12 / actual_hrv, 2)
      total.actual_hra_bp = fix(total.actual_bp * 30.4 * 12 / actual_hrv, 2)
    }


    total.plan_hra_production_offset = fix(total.plan_hra_production - total.origin_plan_hra_production, 2)

    total.plan_hra_percent = percent(Math.abs(total.plan_hra_production_offset), total.origin_plan_hra_production)


  } else {
    if(!p.completed_at)
       throw new Error(`时间录入不完整`)
    let [plan_started_at, plan_finished_at] = UTIL.timeIntersection(
      started_at,
      finished_at,
      p.started_at,
      p.completed_at
    )
    let [actual_started_at, actual_finished_at] = UTIL.timeIntersection(
      started_at,
      finished_at,
      p.started_at,
      now
    )



    // 服务工期
    let whole_duration = time_duration(p.started_at, p.completed_at)
    total.plan_duration = time_duration(plan_started_at, plan_finished_at)
    total.actual_duration = time_duration(actual_started_at, actual_finished_at)
    total.duration_percent = fix(percent(total.actual_duration, total.plan_duration))
    total.duration_offset = total.actual_duration - total.plan_duration
    total.duration_offset_percent = total.duration_offset / total.plan_duration
    total.remain_duration = fix(total.plan_duration - total.actual_duration,1)

    // 施工工期
    total.plan_op_duration = time_duration(p.operated_at, p.completed_at)
    total.acutal_op_duration = time_int_duration(p.operated_at, p.completed_at, p.operated_at, now)

    // 产值
    let amount = p.amount
    total.plan_production = fix(p.amount * total.plan_duration / whole_duration, 2)
    total.actual_production = fix(total.actual_duration / whole_duration * amount, 2)
    total.production_percent = percent(total.actual_production, total.plan_production)
    total.production_offset = 0
    total.production_offset_percent = 0
     total.predict_production = total.actual_prodction + fix(p.amount * (1 - total.plan_duration / whole_duration), 2)

    // 进度
    let endOfYear = moment(now).clone().endOf('year')
    if(endOfYear.isAfter(plan_finished_at))
      total.end_progress = '项目结束'

    // 收费
    total.plan_bill = fix(p.amount / 10000, 2)
    let billplans = p.bill_plans
    let bills = []
    if (Array.isArray(p.bills))
      bills = p.bills.filter(v => moment(v.billed_at).isBetween(started_at, now.clone().endOf('month')))
    let bill_amount = bills.reduce((acc, item) => acc + item.amount, 0)

    total.actual_bill = fix(bill_amount, 2)
    if (billplans && billplans.length > 0) {
      billplans = billplans.map((v, i, a) => {
        let d = {
          ...v
        }
        if (i == 0)
          d.started_at = p.started_at
        else
          d.started_at = a[i - 1].finished_at || a[i - 1].plan_finished_at
        if (d.finished_at)
          d.finished = true
        d.finished_at = d.finished_at || d.plan_finished_at
        return d
      })
      total.plan_bill = billplans.reduce((acc, item) => {
        return acc + item.amount * time_int_duration(plan_started_at, plan_finished_at, item.started_at, item.finished_at) / time_duration(item.started_at, item.finished_at)
      }, 0)

      total.actual_bill = billplans.filter(v => v.finished).reduce((acc, item) => {
        return acc + item.amount * time_int_duration(plan_started_at, plan_finished_at, item.started_at, item.finished_at) / time_duration(item.started_at, item.finished_at)
      }, 0)
    }


    total.bill_percent = percent(total.actual_bill, total.plan_bill)
    total.bill_offset = 0
    total.bill_offset_percent = 0

    // 人员
    let actual_hrv = 0
    if (Array.isArray(p.hr)) {
      actual_hrv = p.hr.reduce((acc, item) => {
        return acc + (item.factor || 0) * time_int_duration(p.actual_started_at, p.actual_finished_at, item.started_at, (item.finished_at || now))
      }, 0)
    }
    total.actual_hrv = fix(actual_hrv, 2)


    // 建安工程量
    total.actual_bp = 0
    total.plan_bp = 0
    if (p.building_production) {
      total.plan_bp = fix(p.building_production)

      total.actual_bp = fix(p.bp.reduce((acc, item) => {
        return acc + item.amount * time_int_duration(p.actual_started_at, p.actual_finished_at, item.started_at, item.finished_at) / time_duration(item.started_at, item.finished_at)
      }, 0), 2)
      total.bp_percent = percent(total.actual_bp, total.plan_bp)
    }

    // 人均产值

    total.actual_hra_production = 0
    total.actual_hra_bill = 0
    total.actual_hra_bp = 0

    if (total.actual_duration != 0 && actual_hrv != 0) {
      total.actual_hra_production = fix(total.actual_production * 12 / actual_hrv, 2)
      total.actual_hra_bill = fix(bill_amount / actual_hrv, 2)
      total.actual_hra_bp = fix(total.actual_bp / actual_hrv, 2)
    }

  }

  let prefixed_data = {}
  for (let key in total) {
    prefixed_data[prefix + '_' + key] = total[key]
  }
  return prefixed_data
}

o.compute = (p, now = moment()) => {
  try {
    // 进度计划 progress_plan
    o.computeNodes(p)

    // 产值情况
    Object.assign(p, o.computeData(p, "total"))
    Object.assign(p, o.computeData(p, "year"))
    // Object.assign(p,o.computeData(p, "lastyear"))

    // 收费情况
    p.is_computed = true
    delete p.nodes
    delete p.last_plan
    delete p.current_plan
    delete p.progress_plans


    // 建安工程量
  } catch (e) {
    if (e.message != '时间录入不完整')
      console.error(e)
    p.error = e.message
  }

  return p
}

o.computeProjectsForWorker = (projects) => {


  postMessage({
    type: "progress",
    data: 20
  })

  projects.forEach((v, i) => {


    o.compute(v)


    postMessage({
      type: "computing",
      data: i
    })
  })


  postMessage({
    type: "end",
    data: projects.map(v => pick(v, ['id', 
    'remain_amount','total_actual_hrv', 'total_plan_hrv', 'total_predict_hrv',    'total_origin_plan_hrv',
      'total_remain_hrv', ,   'is_computed', 'error', "has_plan",
      "total_plan_duration",
      "total_actual_duration",
      "total_remain_duration",
      "total_actual_hra_bill", 
      "total_actual_hra_bp", 
      "total_actual_hra_production",
      "total_origin_plan_hra_production",
      "total_plan_hra_production",
      "total_plan_bp",
      "total_actual_bp",
      "year_plan_bp",
      'year_actual_bp',
      'year_predict_hrv',
      'year_plan_hrv',
      'year_actual_hrv',
      "year_actual_hra_bill",
      'year_remain_hrv',
      "year_predict_production",
      'year_predict_hra_production',
      "year_origin_plan_hra_production",
      "year_plan_hra_production",
      'year_end_progress',
      "total_plan_production","total_actual_production","total_production_percent",
      "year_plan_production", "year_actual_production", "year_production_percent",
      "year_actual_hra_production",
      "year_actual_hra_bp"
    ]))
  })
}


o.computeTotal = (projects, now = moment()) => {
  let in_building_projects = projects.filter(v => v.amount > 1000000 && (v.status == '准备中' || v.status == '进行中'))
  let year_started_at = now.clone().startOf('year')
  let year_finihsed_at = now.clone().endOf('year')
  let season_started_at = now.clone().startOf('quarter')
  let season_finished_at = now.clone().endOf('quarter')

  let d = {}
  let _fso = UTIL.formatSalaryObject
  d.signed_amount = projects.reduce((acc, item) => acc + item.signed_amount, 0)
  d.billed_amount = projects.reduce((acc, item) => acc + item.billed_amount, 0)
  d.adjust_amount = projects.reduce((acc, item) => acc + item.adjust_amount, 0)
  d.amount = projects.reduce((acc, item) => acc + item.amount, 0)
  d.remain_amount = d.amount - d.billed_amount
  d.building_remain_amount = in_building_projects.reduce((acc, item) => acc + item.amount - item.billed_amount, 0)
  d.in_project_hr_count = in_building_projects.reduce((acc, item) => acc + item.hr_count, 0)
  d.billed_percent = percent(d.billed_amount, d.amount)
  d.untrans_amount = projects.reduce((acc, item) => acc + item.billed_amount - item.transfer_amount, 0)

  function _renderFSO(key) {
    let v = _fso(d[key])
    return {
      [key]: v.value,
      [key + '_unit']: v.unit
    }
  }

  function _render(key) {
    return {
      [key]: d[key]
    }
  }


  function _renderFix(key) {
    return {
      [key]: fix(d[key], 2)
    }
  }

  // 产值
  d.year_plan_production = projects.reduce((acc,item)=>acc + item.year_plan_production,0)
  d.year_actual_production = projects.reduce((acc,item)=>acc + item.year_actual_production,0)
  d.year_predict_production = projects.reduce((acc,item)=>acc+item.year_predict_production,0)


  d.year_plan_bill = projects.map(v => v.bill_plans.filter(b => {
    let time = b.finished_at || b.plan_finished_at
    if (time)
      return moment(time).isBetween(year_started_at, year_finihsed_at)
  })).flat().reduce((acc, item) => acc + item.amount, 0)
  d.year_plan_bill_remain = projects.map(v => v.bill_plans.filter(b => {
    let time = b.finished_at || b.plan_finished_at
    if (time)
      return moment(time).isBetween(now, year_finihsed_at)
  })).flat().reduce((acc, item) => acc + item.amount, 0)
  
  d.year_actual_bill = projects.map(v => v.bills.filter(b => {
    let time = b.billed_at
    if (time)
      return moment(time).isBetween(year_started_at, year_finihsed_at)
  })).flat().reduce((acc, item) => acc + item.amount, 0)
  d.year_predict_bill = d.year_actual_bill + d.year_plan_bill_remain
  d.avg_hr_remian_amount = d.remain_amount / d.in_project_hr_count
  d.next_year_remain_amount = d.remain_amount - d.year_plan_bill_remain
  d.year_overdate_plan_bill_amount = d.year_plan_bill - d.year_plan_bill_remain - d.year_actual_bill
  d.season_actual_bill = projects.map(v => v.bills.filter(b => {
    let time = b.billed_at
    if (time)
      return moment(time).isBetween(season_started_at, season_finished_at)
  })).flat().reduce((acc, item) => acc + item.amount, 0)
  d.future_months = [1, 2, 3].map(month => {
    let time = now.clone().add(month, 'month')
    let month_started_at = time.clone().startOf('month')
    let month_finished_at = time.clone().endOf('month')
    return {
      name: time.format("MM月"),
      ..._fso(projects.map(v => v.bill_plans.filter(b => {
        let time = b.finished_at || b.plan_finished_at
        if (time)
          return moment(time).isBetween(month_started_at, month_finished_at)
      })).flat().reduce((acc, item) => acc + (item.amount || 0), 0))
    }
  })
  const dep_names = ["房建", "建管", "市政"]
  d.deps_amount = ['67324391', '67324392', '68827000'].map((dep_id, i) => {
    return {
      name: dep_names[i],
      ..._fso(
        in_building_projects.filter(v => Array.isArray(v.deps) && (v.deps.includes(dep_id))).reduce((acc, item) => acc + (item.amount || 0), 0))
    }
  })

  d.year_plan_hrv = projects.reduce((acc, item) => acc + (item.year_plan_hrv || 0), 0)
  d.year_actual_hrv = projects.reduce((acc, item) => acc + (item.year_actual_hrv || 0), 0)
  d.year_predict_hrv = projects.reduce((acc, item) => acc + (item.year_predict_hrv || 0), 0)
  d.total_plan_hrv = projects.reduce((acc, item) => acc + (item.total_plan_hrv || 0), 0)
  d.total_actual_hrv = projects.reduce((acc, item) => acc + (item.total_actual_hrv || 0), 0)
  d.total_predict_hrv = projects.reduce((acc, item) => acc + (item.total_predict_hrv || 0), 0)
  d.year_remain_hrv = projects.reduce((acc, item) => acc + (item.year_remain_hrv || 0), 0)

  let has_plan_hra_production = in_building_projects.filter(v => v.total_plan_hra_production)
  if(has_plan_hra_production.length > 0)
    d.plan_hra_production = has_plan_hra_production.reduce((acc, item) => acc + item.total_plan_hra_production, 0) / has_plan_hra_production.length
  let has_actual_hra_production = in_building_projects.filter(v => v.actual_hra_production)
  if (has_actual_hra_production.length > 0)
    d.total_actual_hra_production = has_actual_hra_production.reduce((acc, item) => acc + item.total_actual_hra_production, 0) / has_actual_hra_production.length

  let has_predict_hra_production = in_building_projects.filter(v => v.total_predict_hra_production)
  if (has_predict_hra_production.length > 0)
    d.predict_hra_production = has_predict_hra_production.reduce((acc, item) => acc + item.total_predict_hra_production, 0) / has_predict_hra_production.length

  has_plan_hra_production = in_building_projects.filter(v => v.year_plan_hra_production)
  if (has_plan_hra_production.length > 0)
    d.year_plan_hra_production = has_plan_hra_production.reduce((acc, item) => acc + item.year_plan_hra_production, 0) / has_plan_hra_production.length
  has_actual_hra_production = in_building_projects.filter(v => v.year_actual_hra_production)
  if (has_actual_hra_production.length > 0)
    d.year_actual_hra_production = has_actual_hra_production.reduce((acc, item) => acc + item.year_actual_hra_production, 0) / has_actual_hra_production.length

  has_predict_hra_production = in_building_projects.filter(v => v.year_predict_hra_production)
  if (has_predict_hra_production.length > 0)
    d.year_predict_hra_production = has_predict_hra_production.reduce((acc, item) => acc + item.year_predict_hra_production, 0) / has_predict_hra_production.length

  //let install_projects = 
  d.install_avg_project_hr_count = 0
  let pm_projects = in_building_projects.filter(v=>v.type == '全过程咨询' || v.type == '项目管理')
  let pm_hrcount = pm_projects.reduce((acc, item) => {
    return acc.concat(item.hr.filter(h => !h.finished_at))
  }, []).length
  d.pm_avg_project_hr_count = pm_projects.length / pm_hrcount
  d.sp_avg_hbp = in_building_projects.filter(v => v.type == '市政监理' || v.type == '房建监理').reduce((acc, item) => {
    return acc + (item.actual_hra_bp || 0)
  },0)
  // let ins_project_ids = new Set()
  // let ins_hrv = in_building_projects.reduce((acc,item)=>{
  //   return acc.concat(item.hr.filter(h=>h.position && h.position.includes('安装')))
  // },[]).reduce((acc,item)=>{
  //   ins_project_ids.add(item.project_id)
  //   return acc + (item.factor || 0) * time_int_duration(year_started_at,year_finihsed_at,h.started_at,h.finished_at || now) / 30.4
  // },0)
  // if(ins_hrv > 0)
  //   d.install_avg_project_hr_count = fix(ins_project_ids.size / (ins_hrv /12),2)
  
  

   let progress_percents = {
     '10%以下': in_building_projects.filter(v => v.total_production_percent < 10).length,
     '10%': in_building_projects.filter(v => v.total_production_percent >= 10 && v.total_production_percent < 30).length,
     '30%': in_building_projects.filter(v => v.total_production_percent >= 30 && v.total_production_percent < 50).length,
     '50%': in_building_projects.filter(v => v.total_production_percent >= 50 && v.total_production_percent < 80).length,
     '80%': in_building_projects.filter(v => v.total_production_percent >= 80 && v.total_production_percent < 99).length,
     '100%': in_building_projects.filter(v => v.total_production_percent >= 99).length
   }
  d.progress_distribution = ["10%以下","10%","30%","50%","80%","100%"].map(v=>{
    return {
      name:v,
      value:progress_percents[v],
      unit:"个"
    }
  })


  d.year_plan_production = in_building_projects.reduce((acc,item)=>acc+item.year_plan_production,0)
  d.year_actual_production = in_building_projects.reduce((acc, item) => acc + item.year_actual_production, 0)
  d.year_predict_production = in_building_projects.reduce((acc, item) => acc + item.year_predict_production, 0)

  d.year_production_percent = percent(d.year_actual_production, d.year_plan_production)

  d.total_bp = in_building_projects.reduce((acc,item)=>acc+item.total_plan_bp,0)
  d.year_bp = in_building_projects.reduce((acc, item) => acc + item.year_actual_bp, 0)
  d.month_bp = in_building_projects.reduce((acc,item)=>acc+item.month_actual_bp,0)

  let total = {
    count: in_building_projects.length,
    year_completed_count: projects.filter(v => v.completed_at && moment(v.completed_at).isBetween(now.clone().startOf('year'), now)).length,
    year_in_complete_count: projects.filter(v => v.completed_at && moment(v.completed_at).isBetween(now, now.clone().endOf('year'))).length,
    year_in_operate_count: projects.filter(v => v.completed_at && moment(v.operated_at).isBetween(now.clone().startOf('year'), now)).length,
    ..._renderFSO('billed_amount'),
    ..._renderFSO('signed_amount'),
    ..._renderFSO('remain_amount'),
    ..._renderFSO('adjust_amount'),
    ..._renderFSO('year_overdate_plan_bill_amount'),
    ..._renderFSO('amount'),
    ..._renderFSO('building_remain_amount'),
    ..._render('in_project_hr_count'),
    ..._renderFix('billed_percent'),
    ..._renderFSO('untrans_amount'),
    ..._renderFSO('year_plan_bill'),
    ..._renderFSO('year_plan_bill_remain'),
    ..._renderFSO('year_actual_bill'),
    ..._renderFSO('year_predict_bill'),
    ..._renderFSO('avg_hr_remian_amount'),
    ..._renderFSO('next_year_remain_amount'),
    ..._render('future_months'),
    ..._renderFSO('season_actual_bill'),
    ..._renderFix('year_plan_hrv'),
    ..._renderFix('year_actual_hrv'),
    ..._renderFix('year_predict_hrv'),
    ..._renderFix('year_remain_hrv'),
    ..._renderFix('total_plan_hrv'),
    ..._renderFix('total_actual_hrv'),
    ..._renderFix('total_remain_hrv'),
    ..._renderFix('total_predict_hrv'),
    ..._render('deps_amount'),
    ..._renderFSO('plan_hra_production'),
    ..._renderFSO('actual_hra_production'),
    ..._renderFSO('predict_hra_production'),
    ..._renderFSO('year_plan_hra_production'),
    ..._renderFSO('year_actual_hra_production'),
    ..._renderFSO('year_predict_hra_production'),
    ..._renderFix("install_avg_project_hr_count"),
    ..._renderFix("pm_avg_project_hr_count"),
    ..._renderFix("sp_avg_prodcution"),
    ..._renderFix("sp_avg_hbp"),
    ..._render('progress_distribution'),
    ..._renderFSO('year_plan_production'),
    ..._renderFSO('year_actual_production'),
    ..._renderFSO('year_predict_production'),
    ..._renderFix('year_production_percent'),

    ..._renderFSO('total_bp'),
    ..._renderFSO('year_bp'),
    ..._renderFSO('month_bp'),
  }


  return {
    ...total
  }
}



export default o
