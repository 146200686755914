<template>
  <BaseLayout 
    :panels="panels"
    :padding="10"
  />
</template>

<script>
export default {
  props:{
    mode:{
      type:String | Number,
      default:1
    }
  }, 
  computed:{
    panels(){
      return [{
          x:0,y:0,w:5,h:0.8,control:"PanelCaption",
          data:{
            title:'高专工作协同系统'
          }
        },{
          x:0,y:0.8,w:16,h:10,control:"PanelTaskList",
          data:{
            mode:'current',

          }
        },{
          x:16,y:0.8,w:8,h:6,control:"PanelTaskList",
          data:{
            mode:'complete',

          }
        },{
          x:16,y:6.8,w:8,h:4,control:"PanelTaskList",
          data:{
            mode:'finished',

          }
        }]
    },
    mgr_panels(){
      return [{
          x:0,y:0,w:5,h:0.8,control:"PanelCaption",
          data:{
            title:'高专计划任务系统 - 管理模式'
          }
        },]
    }
  }
}
</script>

<style>

</style>