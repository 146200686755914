<template>
  <div class="container" style="overflow:hidden;position:relative;" >
    <UserCollection 
      ref="collection"
      mod="employee_mg" 
      :value="filter.collection"
      @on-change="filter.collection = $event;page=1;getData();editing_collection=false;" 
      @on-edit="handleEditCollection"
      @on-save="handleSaveCollection"
      @on-saved="editing_collection=false;getData()"
      @on-cancel="editing_collection=false;getData()"
    />
    
    <div class="tool-box flex-wrap flex-between" style="padding:10px;">
      <div class="flex-wrap">
      <Input search style="width:220px" v-model="filter.searchText" clearable placeholder="请输入员工姓名" @on-enter="getData();page=1;" />
      <Select placeholder="所属事业部" style="margin-left:10px;width:140px" v-model="filter.dep_id" clearable @on-change="page=1;handleRefresh()">
        <template v-for="d in top_groups">
          <Option :key="d.id" :value="d.id" :label="d.name">
            {{d.name}}
          </Option>
        </template>

      </Select>
      <Select placeholder="部门" style="margin-left:10px;width:140px" v-model="filter.down_dep_id" clearable v-if="filter.dep_id" @on-change="page=1;getData()">
        <template v-for="d in down_groups">
          <Option :key="d.id" :value="d.id" :label="d.name">
            {{d.name}}({{d.count}})
          </Option>
        </template>

      </Select>
      <Select :placeholder="`所属项目(${projects.length})`" style="margin-left:10px;width:240px" v-model="filter.project_id" clearable filterable  @on-change="page=1;getData()">
        <template v-for="d in projects">
          <Option :key="d.id" :value="d.id" :label="d.name">
            [{{ d.type }}] {{d.name}}({{d.count}})
          </Option>
        </template>

      </Select>


    </div>
    <div> 
      <Button style="margin-left:10px" icon='md-refresh' @click="handleRefresh()">刷新</Button>
      
       <Button style="margin-left:10px" icon='md-download' @click="startExport()">导出数据</Button>
      <Button style="margin-left:10px" icon='md-refresh' @click="handleSyncData()" :loading="loadingData">同步数据</Button>
    </div>
    </div>
    
    <div style="height:calc(100% - 110px);width:100%;position:relative;overflow:hidden;left:-1px;top:-1px;">
      <BaseTable 
        border 
        :columns="columns" 
        :loading="loading" 
        :data="items"  
        @event="handleTableEvent"  
        :page="page"
        :page-size="pagesize"
        :count="total"
         />
    </div>
    
    
    <Modal v-model="modalExport" title="导出数据" footer-hide>
      <div> {{exportMessage}} </div>
    </Modal>


    <Modal v-model="showEmployee" width="800" footer-hide @on-cancel="editing_user_mode=null;handleUpdateData()">
     
      <div  v-if="showEmployee">
        
        <div v-if="current.project" style="max-width:80%">
          <h2>{{current.project.name}}</h2>
          <div>{{current.project.type}}<Divider type="vertical" />{{current.project.status}}</div>
        </div>
        <div class="flex-wrap flex-between align-start" style="margin-top:10px;border-top:2px dashed var(--border);padding-top:10px;">
          <div class="flex-wrap" style="height:100px;margin-bottom:5px;width:200px;flex-shrink:0;padding:10px;background:var(--bg3)">
            <img :src="current.employee.photo || 'https://nbgz-pmis-1257839135.cos.ap-shanghai.myqcloud.com/icon/employee.png'" style='width:60px;height:75px;margin:5px 0;border:1px solid var(--border);margin-right:10px;flex-shrink:0;' />
            <div>
              <div style="font-size:16px;color:var(--primary);font-weight:bold">
          {{current.employee.name}}</div>
          <div>{{current.employee.top_dep?current.employee.top_dep.name:''}}</div>
            </div>
          </div>
          <div style="margin-left:10px;background:var(-bg2);flex-grow:1">
            <BaseBoardSubTitle name="当前状态" />
            <div style="margin-bottom:10px;margin-left:10px;margin-top:5px;background:var(--bg3);padding:10px;" class="flex-wrap flex-between">
              <template v-if="editing_user_mode=='factor'">
                {{current.id}}
                <Form class="flex-wrap flex-between" style="width:100%">
                <div class="flex-wrap">
                  <FormItem label="系数">
                <Input number v-model="formData.factor" style="width:60px" />
                  </FormItem>
                  <FormItem label="调整日期" style="margin-left:10px" v-if="!formData.toUpdate">
                <BaseDate v-model="formData.finished_at" style="width:140px"></BaseDate>
                  </FormItem>
                  <FormItem label="仅修改系数" style="margin-left:10px">
                    <Checkbox v-model="formData.toUpdate"></Checkbox>
                  </FormItem>
                </div>
                <div class="flex-wrap">
                  <Button type="primary" @click="handleSaveUserFactor">提交</Button>
                  <Button @click="editing_user_mode='';formData={}" style="margin-left:10px">取消</Button>
                </div>
                </Form>
              </template>
              <template v-else-if="editing_user_mode=='leave'">
                 <Form class="flex-wrap flex-between" style="width:100%">
                <div class="flex-wrap">
                  <FormItem label="调离日期" style="margin-left:10px">
                <BaseDate v-model="formData.finished_at" style="width:140px"></BaseDate>
                  </FormItem>
                </div>
                <div class="flex-wrap">
                  <Button type="primary" @click="handlehandleSaveUserLeave">提交</Button>
                  <Button @click="editing_user_mode='';formData={}" style="margin-left:10px">取消</Button>
                </div>
                </Form>
              </template>
              <template v-else>
               <div class="flex-wrap">
              <template v-if="current.hr.length > 0 && !current.hr[current.hr.length-1].finished_at">
               
              <div style="padding:5px 10px;background:var(--bg2)">{{current.hr[current.hr.length-1].position}}</div>
              <div  style="padding:5px 10px;background:var(--bg2);border-left:1px solid var(--border);">{{current.hr[current.hr.length-1].factor}}</div>
              
              </template>
              <template v-else>
                <div>已离岗</div>
              </template>
              </div>
              <div>
                <Button @click="startEditingUserFactor(current.hr[current.hr.length-1])">调整</Button>
                <Button @click="startEditingUserLeave(current.hr[current.hr.length-1])" style="margin-left:10px">调离</Button>
              </div>
              </template>
            </div>
             <BaseBoardSubTitle name="服务记录" />
            <div style="background:var(--bg3);margin-left:10px;padding:10px;margin-top:5px;">
             
            <Row style="border-bottom:1px solid var(--border);padding:3px 0;margin-bottom:3px;">
              <Col :span="2" align="center">序号</Col>
              <Col :span="5" align="center">职位</Col>
              <Col :span="5" align="center">系数</Col>
              <Col :span="4" align="center">起始时间</Col>
              <Col :span="4" align="center">结束时间</Col>
            </Row>
          <template v-for="(h,i) in current.hr">
            <Row :key="h.id" style="padding:3px 0">
              <Col :span="2" align="center">{{i+1}}</Col>
              <Col :span="5" align="center">{{h.position}}</Col>
              <Col :span="5" align="center">{{h.factor}}</Col>
              <Col :span="4" align="center">{{moment(h.started_at).format("L")}}</Col>
              <Col :span="4" align="center">{{h.finished_at?moment(h.finished_at).format("L"):'至今'}}</Col>
            </Row>
          </template>
          </div>
        </div>
        </div>
        
      </div>
    </Modal>

    <Modal v-model="showModalAddProjectUser" title="调入项目" :width="700" footer-hide>
      <div class="flex-wrap align-start" style="padding:0 40px" v-if="showModalAddProjectUser">
        <div class="flex-col align-center" style="flex-shrink:0;">
            <img :src="current.photo || 'https://nbgz-pmis-1257839135.cos.ap-shanghai.myqcloud.com/icon/employee.png'" style='width:120px;height:145px;border:1px solid var(--border);margin-right:10px;flex-shrink:0;' />
            <div style="text-align:center;margin-top:10px;">
              <div style="font-size:20px;color:var(--primary);font-weight:bold">
          {{current.name}}</div>
          <div>{{current.top_dep?current.top_dep.name:''}}</div>
            </div>
          </div>
          <Form :model="formData" ref="project_user" :rules="project_user_rules" style="margin-left:50px" label-position="left" :label-width="80">
            <FormItem label="调入项目" required prop="project_id">
            <Select v-model="formData.project_id" clearable filterable style="width:300px">
                <Option disabled v-for="p in current.projects" :key="p.id" :value="p.id" style="background:var(--bg2)">
                {{p.name}} <span style="color:var(--subtext3);margin-left:10px;">已加入</span>
              </Option>
              <Option v-for="p in all_projects.filter(v=>!current.projects || !current.projects.find(p=>p.id == v.id)).filter(v=>v.status == '准备中' || v.status == '进行中')" :key="p.id" :value="p.id">
                {{p.name}}
              </Option>
            </Select>
          </FormItem>
          <FormItem label="调入时间" required prop="started_at">
          <BaseDate v-model="formData.started_at"></BaseDate>
          </FormItem>
          <FormItem label="岗位" required>
             <Select transfer  style="width:160px" searchable v-model="formData.position" clearable  >
                  <template v-for="p in positions">
                    <Option :key="'adpos'+p" :value="p">{{p}}</Option>
                  </template>
                </Select>
                
           <div style="font-size:10px;padding:5px;line-height:18px;color:var(--subtext3)"><BaseIcon icon="md-alert" /> 若无对应岗位,请选择'其他岗位'，并填写备注信息</div>
          </FormItem>
          <div class="flex-wrap">
            <FormItem label="系数">
          <Input number v-model="formData.factor" clearable style="width:40px" />
          </FormItem>
          
          <FormItem label="实习" style="margin-left:50px">
          <Checkbox v-model="formData.intern" />          </FormItem>
          </div>
          <FormItem label="备注" prop="note">
          <Input v-model="formData.note"></Input>
          </FormItem>
          <div class="flex-wrap flex-right">
            <Button type="primary" @click="addProjectUser">提交</Button>
            <Button @click="current={};showModalAddProjectUser=false;">取消</Button>
          </div>
          </Form>
            
      </div>
    </Modal>

    <Modal v-model="showWorkIdentifyEditor" title="修改身份" width="300" footer-hide>
      <AutoComplete :data="def_work_identifies" v-model="formData.work_identify" clearable placeholder="输入或选择对应的身份" />
      <div class="flex-wrap flex-right" style="margin-top:10px">
        <Button type="primary" style="margin-right:5px" @click="handleSaveWorkIdentify">提交</Button>
        <Button @click="showWorkIdentifyEditor=false">取消</Button>
      </div>
    </Modal>

    <Modal v-model="showModalFinishProjectUser" title="调出人员" width="700" footer-hide>
      <div class="flex-wrap align-start" style="padding:0 20px" v-if="showModalFinishProjectUser">
        <div class="flex-col align-center" style="flex-shrink:0;">
            <img :src="current.photo || 'https://nbgz-pmis-1257839135.cos.ap-shanghai.myqcloud.com/icon/employee.png'" style='width:120px;height:145px;border:1px solid var(--border);margin-right:10px;flex-shrink:0;' />
            <div style="text-align:center;margin-top:10px;">
              <div style="font-size:20px;color:var(--primary);font-weight:bold">
          {{current.name}}</div>
          <div>{{current.top_dep?current.top_dep.name:''}}</div>
            </div>
          </div>
          <div style="width:100%;margin-left:20px;">
            <Form>
              <template v-for="(p,i) in current.projects">
                <div style="background:var(--bg3);margin:0 5px;border:1px solid var(--border);border-radius:5px;padding:10px;width:100%"  :key="p.id">
                <div style="font-weight:bold;margin-bottom:10px;">{{i+1}} - {{p.name}}</div>
                <FormItem label="调离时间" style="padding-left:10px">
                  <BaseDate v-model="formData.projects[i].finished_at" />
                </FormItem>
                </div>
              </template>
              <div class="flex-wrap flex-right" style="margin-top:10px">
                <Button type="primary" @click="handleFinishProjectUser">提交</Button>
                <Button style="margin-left:10px" @click="showModalFinishProjectUser=false">取消</Button>
              </div>
            </Form>

          </div>
        </div>
    </Modal>

    <Modal v-model="editing_collection" footer-hide title="编辑管理组">
      <div>
        <div class="flex-wrap split10" style="flex-wrap:wrap">
        <template v-for="(e,i) in selection">
          <div class="flex-wrap" style="margin-bottom:5px;width:100px;" :key="e">
            <BaseNameBoard :uid="e" :key="e" />
            <BaseIcon icon="md-close" :size="15" class="hover-lighter" style="margin-left:5px;" @click="selection.splice(i,1)" />
          </div>
        </template>
        </div>
        <div style="margin:10px 0;padding:10px;background:var(--bg3);">
          <BaseUserSelectGrouped v-model="selection" :option={multiple:true} />
        </div>
        <div class="flex-wrap flex-right">
          <Button @click="handleSaveCollection()">确定</Button>
          <Button @click="editing_collection=false">取消</Button>
        </div>
      </div>
    </Modal>
    
   

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import * as XLSX from 'xlsx'
import XLSXStyled from 'xlsx-style'
import UserCollection from '@/components/cm/base/UserCollection'
import pageStateMixin from '@/mixins/page_state'
export default {
  mixins:[pageStateMixin],
  data() {
    return {

      // page-state
      pageStateKey:'employee_mg',
      pageStateFields: ['filter','page','pagesize','sort_by','scrollTop'],

      items: [],
      projects:[],
      all_projects:[],
      loading: false,
      loadingData:false,
      showUserInfo:false,
      editing_user_mode:"",
      showModalAddProjectUser:false,
      showModalFinishProjectUser:false,
      showFreeEmployees:false,
      editing_collection:false,
      selection:[],
      // 
      all_projects:[],
      down_groups:[],
      def_work_identifies:[
        "公司领导",
        "行政",
        "总监",        
        "监理员",
        "机电",
        "实习生",
        "项目经理",
        "工程师",
        "前期",
        "技术",
        "造价",
        "合约",
        "现场",
        "BIM",
        "土建",
        "装修"
      ],
      mode:"user",
      current: {},
      showModalFocus: false,
      showWorkIdentifyEditor:false,
      status: ["准备中", "进行中", "已竣工", "已结束", "已中止"],
      enableFocused:false,
      filter:{
        dep_id:"",
        searchText:""
      },
      showEmployee:false,
      positions:[],
      modified: null,
      showModalAdjust: false,
      formData: {},
      deps:[],
      business_types: ['全过程咨询', '项目管理', '房建监理', '市政监理', '造价咨询', 'BIM咨询', '招标代理', '对外合作', '其他'],
      business_type_colors: ['orange', '#369', 'green', 'darkred', 'purple', '#3af', 'grown', 'pink', '#333'],
      modalFactorConfig:false,
      page:1,
      pagesize:20,
      total:1,
      sort_by:null,
      modalExport:false,
      exportMessage:'',
      scrollTop:false,
    }
  },
  mounted() {
    setTimeout(()=>{
      this.$store.dispatch("session/getUsers")
      this.$api.get("deps").then(res=>{
        this.deps = res.data.data
      })
   
      this.getProjects()
    },500)
  },
  methods: {
    __beforeSavePageState(){
      let dom = this.$el.querySelector('.ivu-table-overflowY')
      if(dom)
        this.scrollTop = dom.scrollTop
    },
    __afterRestorePageState(){
      this.getData(false,()=>{
        setTimeout(()=>{
          let dom = this.$el.querySelector('.ivu-table-overflowY')
          if(dom)
            dom.scrollTop = this.scrollTop
        },1000)  
      })
      this.getFilterOption()
    },
    handleEditCollection(c){
      this.editing_collection=true;
      this.selection = c.items.map(v=>v.target_id)
    },
    startExport(){
      this.modalExport = true
      this.exportMessage = `数据下载中`
      
      let filter = this.filter
      let url = `cm/employees?`

      if(filter.dep_id)
        url += 'dep='+filter.dep_id
      if(filter.down_dep_id)
        url += '&down_dep='+filter.down_dep_id
      if(filter.project_id)
        url += '&project='+filter.project_id

      if(this.sort_by){
        let {key,order} = this.sort_by
        url += `&sort=${key},${order}`
      }
      if(this.filter.searchText){
        let search = this.filter.searchText
        if(search && search.trim()){
          search = search.trim()
          url += '&search='+search
        }
        
      }
      if(filter.collection){
        url += '&collection='+filter.collection
      }
      this.$api.get(url,{timeout:300000}).then(res => {
        let {list} = res.data.data
        this.exportMessage = `数据下载完成,正在生成excel文件`

        this.exportExcel(list)
        this.exportMessage = `导出完成`
        setTimeout(()=>{
          this.modalExport = false
          this.exportMessage = ''
        },300)
      }).catch((e)=>{
        this.exportMessage = '导出错误:'+e
      }).finally(() => {
         
      })

    },
    makeSheet(caption,items){
      
      
      const columns = ['序号','姓名','事业部','部门','身份','当前系数','当年系数','去年系数','当年项目情况']
      const columns2 = ['','','','','','','','','项目名称','项目状态','岗位','平均系数','开始时间','结束时间','人均产值','总产值进度']
      
      
      let data = []
      let merges = [{s:{c:0,r:0},e:{c:15,r:0}}]
      for(let i=0;i<8;i++){
        merges.push({s:{
              c:i,r:1
            },e:{
              c:i,r:2
            }})
      }
      merges.push({s:{c:8,r:1},e:{c:15,r:1}})

      
      let row = 3
      items.map((v, i) => {
        let index = i + 1
        let projects = v.projects
        let user = [index,v.name,v.top_dep_name,v.down_dep_name,v.work_identify,v.factor,v.year_avg_factor,v.last_year_avg_factor]
        if(!projects || projects.length == 0){
          data.push(user)
          row = row + 1
        }else{
          if(projects.length > 1){
            for(let i=0;i<8;i++){
              merges.push({s:{
                    c:i,r:row
                  },e:{
                    c:i,r:row+projects.length-1
                  }})
            }
          }
          row = row+projects.length
          projects.forEach((p,j)=>{
            data.push(user.concat([
              p.name,
              p.status,
              p.position,
              p.year_avg_factor,
              p.year_hrs_started_at?moment(p.year_hrs_started_at).format('YYYY-MM-DD'):'-',
              p.year_hrs_finished_at?moment(p.year_hrs_finished_at).format("YYYY-MM-DD"):'-',
              p.total_actual_hra?(p.total_actual_hra/10000):0,
              p.total_production_percent / 100
              ]))
          })
        }
      })

      let worksheet = XLSX.utils.aoa_to_sheet([[caption], columns,columns2 ,...data])
      worksheet["!merges"]=merges
      
      // 格式控制
      // 列宽
      let col_widths = [6,9,15,12,10,10,10,10,40,10,15,10,12,12,10,10]
      worksheet['!cols'] = col_widths.map(v=>({wch:v}))
      let row_heights = [30]
      for(let i=1;i<row;i++)
        row_heights.push(20)
      worksheet['!rows'] = row_heights.map(v=>({hpx:v}))

      // 标题
      worksheet["A1"].s = {
        font:{
          bold:true
        },
        alignment:{
          horizontal:'center',
          vertical:'center'
        }
      }

      // 对齐
      for(let c=0;c<16;c++){
        for(let r=1;r<row;r++){
          let cell = worksheet[`${String.fromCharCode(65 + c)}${r+1}`]
          if(cell){
            cell.s = {
              alignment:{
                vertical:"center",
                horizontal:r>2&&c==8?"left":"center"
              }
            }
          }
        }
      }

      // 数字列格式
      const cols = ["F","G","H","L","O"]
      const startRow = 4
      const endRow = row
      cols.forEach(c=>{
        for(let i=startRow;i<=endRow;i++){
         if(worksheet[`${c}${i}`])
          worksheet[`${c}${i}`].z="0.0"
        }
      })
      // O - 产值进度
      for(let i=startRow;i<=endRow;i++){
        if(worksheet[`P${i}`])
          worksheet[`P${i}`].z="0%"
      }

      return worksheet
    },
    exportExcel(items){
     
      let date = moment().format("YYYYMMDD")
      let caption = `年度人员服务项目明细表${date}`
      const filename = `${caption}.xlsx`;

      const workbook = XLSX.utils.book_new()
      
      let depmap = {}
      items.forEach(v=>{
        if(!v.top_dep)
          return
        let dep = v.top_dep_name
        if(depmap[dep]){
          depmap[dep].push(v)
        }else{
          depmap[dep] = [v]
        }
      })

      for(let key in depmap){
        let sheet = this.makeSheet(`${caption}(${key})`,depmap[key])
        XLSX.utils.book_append_sheet(workbook, sheet, key)
      }


      const wbout = XLSXStyled.write(workbook, { bookType: 'xlsx',bookSST:false,type:'binary'});

      function s2ab(s){
        const buf = new ArrayBuffer(s.length)
        const view = new Uint8Array(buf)
        for(let i=0;i!==s.length;++i)  view[i] = s.charCodeAt(i) & 0xFF
        return buf
      }

      const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
      // save file
      let link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      setTimeout(function () {
        // 延时释放掉obj
        URL.revokeObjectURL(link.href);
        link.remove();
      }, 500);
    

    },
    handleRefresh(){
      this.getFilterOption()
      this.getData()
    },
    getFilterOption(){
      let url = 'cm/employees?q=option'
      if(this.filter.dep_id)
        url += '&dep='+this.filter.dep_id
      this.$api.get(url).then(res=>{
        let {down_deps,projects} = res.data.data
        this.down_groups =down_deps
        this.projects = projects
      })
    },
    
    getPositions(project){
       const sp =  [
					"总监",
					"总代",
					"土建工程师",
					"土建监理员",
					"安装工程师",
					"市政工程师",
					"市政监理员",
					"资料员",
					"安装监理员",
					"桩基监理员"
      ]
      const pm = [
					"项目经理",
					"项目经理助理",
					"前期工程师",
					"技术工程师",
					"合约工程师",
					"机电工程师",
					"项目副经理",
					"造价工程师",
					"现场工程师"
				]

        let positions = []
        if(!project)
          positions=  [...sp,...pm]
        else if(project.positions && project.positions.length > 0){
          positions = project.positions
        }else if(project.type == '全过程咨询')
          positions= [...sp,...pm]
        else if(project.type == '项目管理')
          positions= [...pm]
        else if(project.type && project.type.includes('监理'))
          positions= [...sp]
        else
          positions= [...sp,...pm]

        if(!positions.includes('其他岗位'))
          positions.push('其他岗位')
        return positions
    },
    addProjectUser(){
      this.$refs.project_user.validate(v=>{
        let data = this.formData
        data.user_id = this.current.id
        if(v){
          this.$api.post("hrs?q=hr&dep=1",data).then(res=>{
            this.update_list = [this.current.id]
            
            this.showModalAddProjectUser = false
            this.Success({title:"添加成功"})
            this.getData()
          }).catch(e=>{
            this.Error(e)
          })
        }
      })
    },
    getProjects(){
      this.$api.get("cm/projects?q=simple").then(res=>{
        this.all_projects = res.data.data
      })
    },
    getData(forced,cb) {
      this.loading = true
      let filter = this.filter
      let url = `cm/employees?page=${this.page}&pagesize=${this.pagesize}`
      if(forced)
        url += '&forced=1'
      if(filter.dep_id)
        url += '&dep='+filter.dep_id
      if(filter.dep_id && filter.down_dep_id)
        url += '&down_dep='+filter.down_dep_id
      if(filter.project_id)
        url += '&project='+filter.project_id
      if(this.update_list){
        url += '&update'+this.update_list.join(',')
        this.update_list = null
      }

      if(this.sort_by){
        let {key,order} = this.sort_by
        url += `&sort=${key},${order}`
      }
      if(this.filter.searchText){
        let search = this.filter.searchText
        if(search && search.trim()){
          search = search.trim()
          url += '&search='+search
        }
        
      }

      if(!this.editing_collection && this.filter.collection){
        url += '&collection='+this.filter.collection
      }
      this.$api.get(url,{timeout:300000}).then(res => {
        let {list,total} = res.data.data
        this.items = list
        this.total = total
        if(cb){
          cb()
        }
      }).finally(() => {
        setTimeout(()=>{
          
        this.loading = false
        this.loadingData = false
        },300)
      })
    },
    startAddProjectUser(user){
      this.current = user
      this.formData = {
        factor:1,
        started_at:moment().format("YYYY-MM-DD")
      }
      this.positions = this.getPositions()
      this.showModalAddProjectUser = true
      setTimeout(()=>{
        
      this.$refs.project_user.resetFields()
      },500)
    },
    onChangeProject(p){
      this.positions = this.getPositions(p)
    },
    startFinishProjectUserAll(user){
      this.current = user
      this.formData = {
        projects:user.projects.map(v=>{
          return {
            id:v.id,
            record_id:v.record_id,
            finished_at:moment().format()
          }
        })
      }
      this.showModalFinishProjectUser = true
    },
    handleFinishProjectUser(){
      let data = this.formData
      let employee = this.items.find(v=>v.id == this.current.id)
      if(!employee)
        return
      this.$api.post('hrs?q=leave-all&user='+employee.account_id,data).then(res=>{
          employee.factor = 0
          employee.projects = []
          employee.projects_count = 0
          this.updateItem(employee)
          this.showModalFinishProjectUser = false
          this.Success({title:"操作成功"})
      }).catch((e)=>{
        this.Error(e)
      })
      
    },
    startEditingUserFactor(item){
      this.formData = {
        id:item.id,
        factor:item.factor,
        toUpdate:false,
        position:item.position,
        finished_at:moment().format()
      }
      this.editing_user_mode='factor'
    },
    startEditingUserLeave(item){
      this.formData = {
        id:item.id,
        finished_at:moment().format()
      }
      this.editing_user_mode='leave'
    },
    makePositions(positions){
      return positions || ['项目经理','总监']
    },
    getProjectUser(e,p){
      this.$api.get(`cm/projects/${p.id}/hr/${e.id}`).then(res=>{
        let {hr,positions} = res.data.data
        this.current = {
          employee:e,
          project:p,
          hr,
          positions:this.makePositions(positions)
        }
        this.showEmployee = true
      })
    },
    startEditWorkIdentify(e){
      this.formData = {
        id:e.id,
        work_identify:e.work_identify || ""
      }
      this.showWorkIdentifyEditor =true
    },
    handleSaveWorkIdentify(){
      if(!this.formData.id)
        return
      this.$api.patch("employees/"+this.formData.id,{work_identify:this.formData.work_identify}).then(res=>{
        let item = this.formData
        let index = this.items.findIndex((v) => v.id == item.id);
			if (index != -1)
				this.items.splice(index, 1, Object.assign(this.items[index], item));
			else this.items.splice(0, 0, item);
        this.showWorkIdentifyEditor = false
        this.formData = {}
      })
    },
    handleTableEvent(e) {
      if (e.type == 'open') {
        this.RouteTo('/core/projects/' + e.data.id + '/cm')
      }else if(e.type =='page-change'){
        this.page = e.data
        this.getData()
      }else if(e.type =='page-size-change'){
        this.pagesize = e.data
        this.getData()
      }else if(e.type == 'sort'){
        if(!e.data.key)
          this.sort_by = null
        else
          this.sort_by = e.data
        this.getData()
      }
    },
    handlePatchFocused() {
      this.$api.patch(`projects/${this.formData.id}`, {
        focused: this.formData.focused,
        focused_reason: this.formData.focused_reason
      }).then(res => {
        this.showModalFocus = false

        let updateInfo = res.data.data
        Object.assign(updateInfo,{
          id: this.formData.id,
          focused: this.formData.focused,
          focused_reason: this.formData.focused_reason
        })
        this.updateItem(updateInfo)
        this.formData = {}
        this.$Notice.success({ title: "修改成功" })
      })
    },
    handleSaveUserFactor(){
      let data = {...this.formData}
      let employee = this.current.employee
      data.project_id = this.current.project.id
      data.user_id = employee.id
      this.$api.post("hrs?q=patch-user",data).then(()=>{
        this.getProjectUser(employee,this.current.project)
        this.editing_user_mode = null
        this.update_list = [employee.id]
      }).catch(e=>{
        this.Error(e)
      })
      
    },
    handlehandleSaveUserLeave(e){
      let data = {
        finished_at:this.formData.finished_at
      }
      data.id = this.formData.id
      data.user_id = this.current.employee.id
      data.project_id = this.current.project.id
      this.$api.post('hrs?q=leave',data).then(res=>{
        this.getProjectUser(this.current.employee,this.current.project)
        this.editing_user_mode = null
        this.update_list = [employee.id]
        this.showEmployee = false
      }).catch((e)=>{
        this.Error(e)
      })
    },
    handleDeleteUserFromProject(e,p){
      this.Confirm(`确定移除用户<span style="color:red;margin:0 3px;">${e.name}</span>在项目<span style="color:red;margin:0 3px;">${p.name}</span>的所有记录?`,()=>{
        this.$api.delete(`hrs/${e.account_id}?project=${p.id}&q=user&dep=1`).then(res=>{
          this.showEmployee = false
          let projects = e.projects.filter(v=>v.id !== p.id)
          let factor = e.factor - p.factor
          this.updateItem({id:e.id,projects,projects_count:projects.length,factor})
        }).catch(e=>{
          this.$Notice.error({title:"删除失败"})
        })
        
      })
    },
    handleSyncData(){
      this.Confirm("同步数据需要等待3-5分钟,是否继续?",()=>{
        this.loadingData = true
        this.getData(true)
      })
    },
    updateItem(item) {
			let index = this.items.findIndex((v) => v.id == item.id);
			if (index != -1)
				this.items.splice(index, 1, Object.assign(this.items[index], item));
			else this.items.splice(0, 0, item);
		},
    handlePatchAdjustAmount() {
      this.$api.patch(`projects/${this.formData.id}`, {
        adjust_amount: this.formData.adjust_amount
      }).then(res => {
        this.showModalAdjust = false

        let updateInfo = {
          id: this.formData.id,
          adjust_amount: this.formData.adjust_amount,
          amount: this.formData.signed_amount + this.formData.adjust_amount
        }

        this.$store.commit('cm/update_project', updateInfo)
        this.formData = {}
        this.$Notice.success({ title: "修改成功" })
      })
    },
    handleUpdateData(){
      if(this.update_list &&  this.update_list.length > 0){
        this.getData()
      }
    },
    handleSelectFilter(f, v) {
      if (Array.isArray(this.selected_filters[f])) {
        let index = this.selected_filters[f].findIndex(t => t == v)
        if (index != -1)
          this.selected_filters[f].splice(index, 1)
        else
          this.selected_filters[f].push(v)
      } else
        this.$set(this.selected_filters, f, [v])
    },
    inArray(array, sub) {
      if (Array.isArray(array) && Array.isArray(sub) && array.length > 0 && sub.length > 0 && sub.length <= array.length) {
        for (let i = 0; i < sub.length; i++)
          if (!array.includes(sub[i]))
            return
        return true
      }
    },
    outArray(array, sub) {
      if (Array.isArray(array) && Array.isArray(sub) && array.length > 0 && sub.length > 0) {
        for (let i = 0; i < sub.length; i++)
          if (array.includes(sub[i]))
            return false
        return true
      }
    },
    
    handleOpen(p) {
      this.$store.commit("session/push_project_cm",p)
      this.RouteTo('/core/projects/' + p.id + '/cm')
    },
    handleClearGroup(g) {
      if (!g)
        this.selected_cols = []
      else
        this.selected_cols = this.selected_cols.filter(v => !g.items.map(t => t.key).includes(v))
    },
    handleSelectAllGroup(g) {
      g.items.forEach(v => {
        if (!this.selected_cols.includes(v.key))
          this.selected_cols.push(v.key)
      })
    },
    handleSelectColumn(key) {
      let index = this.selected_cols.findIndex(v => v == key)
      if (index == -1)
        this.selected_cols.push(key)
      else
        this.selected_cols.splice(index, 1)
    },
    handleSaveCollection(){
      this.$refs.collection.Save(this.selection,'employee')
    }
  },
  components:{UserCollection},
  computed: {
    ...mapGetters('session', ['session', 'users']),
    project_user_rules(){
      return {
        project_id:[{required:true,message:"项目名称是必须项"}],
        position:[{required:true,message:"岗位是必填项"}],
        factor:[{required:true,message:"系数是必填项"}],
        started_at:[{required:true,message:"开始时间是必填项"}]
      }
    },
    
    top_groups(){
      return this.deps.filter(v=>v.top)
    },
    columns() {
      var that = this
      return [ {
        type: 'index',
        key: "code",
        title: "编号",
        width: 70,
      }, {
        type: 'text',
        title: "名称",
        tree: true,
        key: "name",
        sortable:"custom",
        width:200,
        render: (h, param) => {
          let pic = h('BaseIcon', { props: { icon: 'images', color: 'var(--primary)', size: 12 }, style: "margin-left:5px;" })
          let photo = h('img',{attrs:{src:param.row.photo || 'https://nbgz-pmis-1257839135.cos.ap-shanghai.myqcloud.com/icon/employee.png'},style:'width:60px;height:75px;margin:5px 0;margin-right:5px;border:1px solid var(--border)'})
          let name = h('a', {            style: "font-size:14px;font-weight:bold;text-align:left", on: {
              click: (e) => {
                this.$bus.$emit('show-cm-user',param.row)
              }
            }          }, param.row.name)
          let dep = param.row.employee_state == '离职'?h('div',{style:"color:var(--error)"},"已离职"):h('div',{},param.row.top_dep?param.row.top_dep.name:'')
          let info = h('div',{style:"width:100px"},[name,dep])
          let items = [photo]
          items.push(info)

          return h("div", { class: "flex-wrap", style: "padding-left:10px;" }, items)
        }
      },{
        key:"down_dep_name",
        title:"部门",
        type:"text",
        sortable:"custom",
        width:80,
        align:"center",
        render:(h,{row})=>{
          return h("div",{class:"flex-wrap flex-center",style:"width:100%"},[h('span',{style:'background:var(--bg2);padding:2px 8px;border-radius:3px;'},row.down_dep?row.down_dep.name:'-')])
        }
      },{
        key:"work_identify",
        title:"身份",
        
        align:"center",
        sortable:"custom",
        width:80,
        render:(h,{row})=>{
          let edit = h("BaseIcon",{props:{icon:"md-create",color:"#3af"},
          on:{
            click:()=>{
              this.startEditWorkIdentify(row)
            }
          },style:"cursor:pointer;margin-left:5px;"})
          let content = h("span",{style:`color:var(${row.work_identify?'--text3':'--subtext3'})`},[row.work_identify || "无"])
          return h('div',[content,edit])
        }
      },{
        type:"number",
        title:"当前系数",
        key:"factor",
        align:"center",
        sortable:"custom",
        width:100,
        render:(h,{row})=>{
          let mapColor=f=>{
            if(f ==0)
              return "var(--bg2)"
            else if(f < 1)
              return "var(--success)"
            else if(f < 1.5)
              return "var(--warning)"
            else
              return "var(--error)"
          }
          let mapTextColor = f=>{
            if(f == 0)
              return "var(--text2)"
            else
              return "var(--hover-text)"
          }


          let factor = h("div",{class:"flex-wrap flex-center",style:"width:30px;height:30px;background:var(--bg2);border-radius:50%;"+`background:${mapColor(row.factor)};color:${mapTextColor(row.factor)}`},(row.factor || 0))
          
          return h('div',{class:"flex-wrap flex-center hover-lighter"},[factor])
        }
      },{
        type:"number",
        title:"当年系数",
        sortable:"custom",
        align:"center",
        key:"year_avg_factor",
        width:100,
        render:(h,{row})=>{
          let mapColor=f=>{
            if(f ==0)
              return "var(--bg2)"
            else if(f < 1)
              return "var(--success)"
            else if(f < 1.5)
              return "var(--warning)"
            else
              return "var(--error)"
          }
          let mapTextColor = f=>{
            if(f == 0)
              return "var(--text2)"
            else
              return "var(--hover-text)"
          }
          let factor = h("div",{class:"flex-wrap flex-center",style:"width:30px;height:30px;background:var(--bg2);border-radius:50%;"+`background:${mapColor(row.year_avg_factor)};color:${mapTextColor(row.year_avg_factor)}`},(row.year_avg_factor || 0))
          
          return h('div',{class:"flex-wrap flex-center hover-lighter"},[factor])
        }
      },{
        type:"number",
        title:"去年系数",
        key:"last_year_avg_factor",
        align:"center",
        width:100,
        render:(h,{row})=>{
          let mapColor=f=>{
            if(f ==0)
              return "var(--bg2)"
            else if(f < 1)
              return "var(--success)"
            else if(f < 1.5)
              return "var(--warning)"
            else
              return "var(--error)"
          }
          let mapTextColor = f=>{
            if(f == 0)
              return "var(--text2)"
            else
              return "var(--hover-text)"
          }
          let factor = h("div",{class:"flex-wrap flex-center",style:"width:30px;height:30px;background:var(--bg2);border-radius:50%;"+`background:${mapColor(row.last_year_avg_factor)};color:${mapTextColor(row.last_year_avg_factor)}`},(row.last_year_avg_factor || 0))
          
          return h('div',{class:"flex-wrap flex-center hover-lighter"},[factor])
        }
      },
      {
        key:"chart",
        title:"系数曲线",
        sortable:false,
        width:130,
        render:(h,{row})=>{
          let top = -2
          let topIndex = 0
          let bottom = 2
          let bottomIndex = 0
          let points = row.month_avg_factors.map((v,i)=>{
          
            if(v > top){
              top = v
              topIndex = i
            }
            if(v < bottom){
              bottom = v
              bottomIndex = i
            }

            if(v > 2)
              v = 2
            else if(v < -2)
              v = -2
            return `${5+i*5},${45 - v*22.5}`
          }).join(" ")
          let grids = []
          for(let i=0;i<=24;i++){
            grids.push(h('line',{attrs:{x1:5+i*5,y1:0,x2:5+i*5,y2:45,stroke:"#ddd"}}))
          }
          let xAris = h('line',{attrs:{x1:0,y1:22.5,x2:130,y2:22.5,stroke:'#999'}})
          let yAris = h('line',{attrs:{x1:65,y1:0,x2:65,y2:45,stroke:'#888'}})
          let textTop = h('text',{attrs:{x:`125`,y:`10`,fill:'red'}},top)
          let textBottom = h('text',{attrs:{x:`125`,y:`45`,fill:'green'}},bottom)
          let textLastYear = h('text',{attrs:{x:30,y:10,fill:'var(--subtext2)'}},moment().subtract(1,'years').year())
          let textYear = h('text',{attrs:{x:95,y:10,fill:'var(--subtext2)'}},moment().year())
          let line = h('polyline',{attrs:{points},style:"fill:none;stroke:var(--primary);stroke-width:1"})
          let svg = h('svg',{class:'l-project-factor',style:'height:45px;border-radius:5px;overflow:hidden;'},[...grids,xAris,yAris,line,textTop,textBottom,textLastYear,textYear])
          return svg
        }
      },
      {
        title:"当年项目情况",
        align:"center",
        children:[{
          key:'project_name',
          width:140,
          title:'项目名称',
          
          sortable:false,
          render:(h,{row})=>{
            let doms = row.projects.map((v,i)=>{
              let icon = h('BaseIcon',{props:{icon:'Building-',size:15,color:'var(--primary)'},style:"margin-right:5px"})
              return h('div',{style:`line-height:28px;white-space:nowrap;height:28px;overflow:hidden;width:140px;padding-left:15px;text-overflow:ellipsis;text-align:left;border-top:${i==0?'':'1px dashed var(--border);'}`,attrs:{title:v.name}},[icon,h('a',{on:{
                click:()=>{
                  this.handleOpen(v)
                }
              }},v.shortname || v.name)])
            })
            return h('div',doms)
          }
        },{
          key:'status',
          width:'80',
          title:'项目状态',
          
          sortable:false,
          render:(h,{row})=>{
            const status_colors = {
              '准备中':['var(--bg2)','var(--text2)'],
              '进行中':['var(--primary)','var(--hover-text)'],
              '已竣工':['var(--success)','var(--hover-text)'],
              '已结束':['var(--bg1)','var(--text1)'],
              '已关闭':['var(--error)','var(--hover-text)']
            }
            
            let doms = row.projects.map((v,i)=>{
              if(!status_colors[v.status])
                v.status = '已关闭'
              return h('div',{class:'flex-wrap flex-center',style:`padding:0 5px;height:28px;text-align:center;border-top:${i==0?'':'1px dashed var(--border);'};`},[h('span',{style:`background:${status_colors[v.status][0]};color:${status_colors[v.status][1]};padding:0 5px;border-radius:30px;`},v.status)])
            })
            return h('div',doms)
          }
        },{
        key:"position",
        title:"当前岗位",
        type:"text",
        
          sortable:false,
        width:80,
        align:"center",
        render:(h,{row})=>{
            let doms = row.projects.map((v,i)=>{
              return h('div',{class:'flex-wrap flex-center',style:`padding:0 5px;height:28px;text-align:center;border-top:${i==0?'':'1px dashed var(--border);'}`},[v.position])
            })
            return h('div',doms)
        }
      },{
        key:"position",
        title:"当前系数",
        type:"text",
        
          sortable:false,
        width:80,
        align:"center",
        render:(h,{row})=>{
          let doms = row.projects.map((v,i)=>{
              let btn = h("BaseIcon",{class:"hover-lighter",props:{size:12,icon:"md-build",color:'var(--primary)'},style:'margin-left:5px;',on:{click:()=>{
                this.getProjectUser(row,v)
              }}},)
              return h('div',{class:'flex-wrap flex-center',style:`padding:0 5px;height:28px;text-align:center;border-top:${i==0?'':'1px dashed var(--border);'}`},[v.factor.toFixed(1),btn])
            })
            return h('div',doms)
        }
      },{
          key:'project_year_avg_factor',
          width:80,
          title:'平均系数',
          
          sortable:false,
          render:(h,{row})=>{
            
            let doms = row.projects.map((v,i)=>{
             
              return h('div',{class:'flex-wrap flex-center',style:`padding:0 5px;height:28px;text-align:center;border-top:${i==0?'':'1px dashed var(--border);'}`},[v.year_avg_factor.toFixed(1)])
            })
            return h('div',doms)
          }
        },{
          key:'project_year_started',
          width:100,
          title:'开始时间',
          align:"center",
          sortable:false,
          render:(h,{row})=>{
            let doms = row.projects.map((v,i)=>{
              return h('div',{class:'flex-wrap flex-center',style:`padding:0 5px;height:28px;text-align:center;border-top:${i==0?'':'1px dashed var(--border);'}`},[v.year_hrs_started_at?moment(v.year_hrs_started_at).format('YYYY-MM-DD'):'-'])
            })
            return h('div',doms)
          }
        },{
          key:'project_year_finished_at',
          width:100,
          title:'结束时间',
          
          sortable:false,
          align:"center",
          render:(h,{row})=>{
            let doms = row.projects.map((v,i)=>{
              return h('div',{class:'flex-wrap flex-center',style:`padding:0 5px;height:28px;text-align:center;border-top:${i==0?'':'1px dashed var(--border);'}`},[v.year_hrs_finished_at?moment(v.year_hrs_finished_at).format('YYYY-MM-DD'):'-'])
            })
            return h('div',doms)
          }
        },{
          key:'year_hra_production',
          width:80,
          title:'人均产值',
          
          sortable:false,
          render:(h,{row})=>{
            let doms = row.projects.map((v,i)=>{
              return h('div',{class:'flex-wrap flex-right',style:`padding:0 5px;height:28px;text-align:center;border-top:${i==0?'':'1px dashed var(--border);'}`},[h('BaseAmount',{props:{value:v.total_actual_hra}})])
            })
            return h('div',doms)
          }
        },{
          key:'total_production_percent',
          width:80,
          title:'总产值进度',
          
          sortable:false,
          render:(h,{row})=>{
          
            let doms = row.projects.map((v,i)=>{
              
              let inner = h('div',{style:`position:absolute;left:0;top:0;bottom:0;width:${v.total_production_percent || 0}%;background:var(--primary);`})
              let text = h('span',{style:'position;relative;z-index:1'},(v.total_production_percent?v.total_production_percent.toFixed(0):0) + '%')
              let board = h('div',{ class:'flex-wrap flex-right',style:`margin:10px 10px;padding:0 5px;height:18px;background:var(--bg2);border:1px solid var(--border);position:relative;border-top:${i==0?'':'1px dashed var(--border);'}`},[text,inner])
              return board
            })
            return h('div',doms)
          }
        }]
      },{
					key: "tools",
					title: "操作",
					group: "管理",
          fixed:"right",
          sortable:false,
					minWidth:60,
					type: "user",
					render:(h,{row})=>{
            let buttons =[
              h("Button",{props:{size:"small",type:"primary",icon:"md-add"},on:{click:()=>{
                this.startAddProjectUser(row)
              }}},),
             
              ]
            return h("div",{class:"flex-wrap flex-center split5"},buttons)
          }
				}]
    }
  }
}
</script>

<style lang="less">

.l-project-factor{
  border-radius: 5px;
  overflow: hidden;
  align-items: center;
  text{
    font-size:10px;
    color:var(--text2);
    text-anchor: end;
  }
  .l-project{
    transition:all 0.5s;
    cursor: pointer;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow:1;
    border-right:1px solid var(--border);
    text-align:center;
    padding:5px 10px;
    min-height:46px;
  }
  margin-bottom:3px;background:var(--bg2);border-radius:5px;

  .l-factor{
    min-height:46px;
    transition:all 0.5s;
    height:100%;padding:5px;border-left:1px solid var(--border);border-right:1px solid var(--border);flex-shrink:0;
  }

  .l-button{
    align-items:center;
    transition:all 0.5s;
    min-height:46px;
  }
}

.l-project-factor{
  
  .l-button{
    cursor: pointer;
  }
  .l-factor{
     cursor: pointer;
  }
  a{
    color:var(--hover-text);
  }

}

.l-project:hover,
  .l-button:hover,.l-factor:hover{
    background:var(--primary);
  color:var(--hover-text);
  }

  .l-project:active,
  .l-button:active,.l-factor:active{
    position: relative;
    bottom:-1px;
    right:-1px;
    filter:brightness(0.9);
  }


  .app-modal{
    left:42px !important;
  }

</style>