<template>
  <div>
    public-object {{id}}
  </div>
</template>

<script>
  export default {
    routerOption:{
      as_object:true
    },
    computed:{
      id(){
        if(this.$route && this.$route.params)
          return this.$route.params.id
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>