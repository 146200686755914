<template>
  <div class="l-task flex-wrap flex-between" @click="$emit('event','open')">
    <div class="flex-wrap">
      <div
        style="color:var(--subtext3);margin-right:5px;width:62px;font-size:12px;font-weight:bold;flex-shrink:0;"
      >{{moment(t.finished_at).fromNow()}}</div>
      <BaseNameBoard :uid="t.charger_id || t.created_by" style="width:70px;color:var(--priamry);flex-shrink:0" />

     
      <div class="flex-col align-start">
        <div class="flex-wrap">
        {{t.content}}
        <div class="l-tag hover-lighter" style="display:inline-block;box-shadow:none;border:1px solid var(--border);margin-left:5px;"  @click="$emit('event','open')">
              
              <BaseIcon color="var(--primary)"  :icon="self==t.created_by || self == t.charger_id?'md-create':'md-search'" size="12" />
              </div>
              <div class="l-tag" style="color:var(--text2);font-weight:normal;font-size:10px;background:var(--bg2);margin-left:5px;padding:2px 5px;border:1px solid var(--border)">{{t.datekey || t.datetype}}</div>
            </div>
        <!-- <div
          style="color:var(--success);font-size:10px;margin-left:10px;margin-top:5px;"
          v-if="t.last_comment"
        ><BaseIcon icon="md-return-right" style="margin-right:5px;" />{{moment(t.last_comment_at).fromNow()}}：{{FormatComment(t.last_comment)}}</div> -->
      </div>
    </div>

    <div></div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      default: 0
    },
    self:{
        type:String
    }
  },
  computed: {
    t() {
      return this.value;
    }
  },
  methods:{
    FormatComment(t){
        var re = new RegExp('<[^<>]+>','g');
        var re2 = /&[a-zA-Z0-9]+;/g
        return t.replace(re ,"").replace(re2,"");
    }
  }
};
</script>


<style lang="less" scoped>
.l-task {
  padding: 2px 10px;
  background: var(--bg3);
  margin: 1px;
  border-bottom: 1px solid var(--border);
 

  .l-code {
    background: var(--bg3);
    color: var(--text3);
    width: 20px;
    height: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 1px 1px 1px var(--border);
    margin-right: 8px;
  }
  .l-project {
    color: var(--success) !important;
    font-size: 10px;
    margin-right: 5px;
    margin-bottom: 3px;
  }

  .l-tag {
    color: var(--text2);
    text-align: center;
    background: var(--bg2);
    font-weight: bold;
    white-space: nowrap;
    padding: 0px 5px;
    border-radius: 3px;
    box-shadow: 1px 1px 2px 1px var(--border);
  }
}

.l-task-finished {
  .l-code {
    background: var(--bg1);
    color: var(--text1);
  }

  background: var(--bg2);
}

.l-task:hover {
  color: var(--primary);
  cursor: pointer;
}

</style>