<template>
  <div class="pd-embed-control" :class="{'pd-embed-contorl-disabled':!editable}">
    <template>
      <div class="flex-wrap" :title="localValue || '请填写'" style="white-space:nowrap;text-overflow:ellipsis;padding:0 8px;height:100%;cursor:pointer;" @click="openEditor">
        {{localValue || '点击配置' }}
      </div>
    </template>

    <Modal v-model="modalEditor" footer-hide title="配置默认值" :width="600">
      <div>
        <BaseEmpty v-if="!control.control" />
        <div v-else>
           <span style='color:var(--primary);margin:0 3px;'>[ {{(controls.find(c=>c. control == control.control) || {}).name }} ] {{ control.name }}</span> 
        <Form style="padding:20px;border:1px solid var(--border);margin:10px 0;">
        <component 
          :is="control.control"
          ref="control"
          :config="control.config"
          :editable="true" 
          v-model="localValue"
          />
         
        </Form>
        <!-- <div class="flex-wrap split5" v-if="defaultOptions">
          <template v-for="d in defaultOptions">
            <Button @click="localValue=d.value">{{ d.key }}</Button>
          </template>
        </div>
         -->
        </div>
        <div class="flex-wrap flex-right">
          <Button type="primary" style="margin-right:10px;" @click="handleSave()">确定</Button>
          <Button @click="handleCancel()">取消</Button>
        </div> 
      
      </div>
    </Modal>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';


export default {
  data(){
    return {
      modalEditor:false,
      localValue:null
    }
  },
  props:['value','editable','option','db'],
  computed:{
    control(){
      return this?.option?.control || {}
    },
    controls(){
      return this.$store.getters['forms/zzl_controls']
    },
    
  },
  watch:{
    value:{
      handler(v){
        this.localValue = (v===undefined?undefined:this.copy(v))
      },
      immediate:true
    }
  },
  methods:{
    copy(e){
      if(typeof e == 'object')
        return cloneDeep(e)
      else
        return e
    },
    openEditor(){
      if(this.editable){
        this.modalEditor = true
      }
    },
    
    handleSave(){
      this.$emit('input',this.localValue)
      this.modalEditor =false
    },
    handleCancel(){
      this.modalEditor =false
    }
  }
}
</script>

<style>

</style>