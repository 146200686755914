<template>
    <div class="container flex-wrap flex-line">
      <div style="flex-shrink:0;width:140px;height:100%;border-right:1px solid var(--border);overflow-y:auto;"
      >
      
         <BaseMenu 
            :menus="menus"
            :actived="actived"
            @on-click="handleOpen"
        >
        </BaseMenu>
      </div>
      <div style="width:calc(100% - 140px);height:100%;position:relative;">
        
          <router-view></router-view>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex'
  import responsableMixin from '@/mixins/responsable'
  export default {
    routerOption: {
      as_default: true,
      as_frame:true
    },
    mixins:[responsableMixin],
    mounted(){
      
    },
    computed: {
      ...mapGetters('cm', ['permission']),
      sm(){
        return this.w < 1200
      },
      menus(){
        const items = [
          {
            name:"履约过程",
            key:"process",
            icon:"md-speedometer"
          },
          {
            name:"标的交付",
            key:"subject_matter_delivery",
            icon:"md-speedometer"
          },
          {
            name:"付款申请",
            key:"payment",
            icon:"md-speedometer"
          },
          {
            name:"发票信息",
            key:"invoice",
            icon:"md-speedometer"
          },
          {
            name:"履约风险",
            key:"risk",
            icon:"md-speedometer"
          },
          
          
          ]
          return items
        },
        actived(){
          return this.$route.path.slice(this.$route.path.lastIndexOf('/')+1)
        },
        
    },methods:{
      handleOpen(e){
        this.RouteTo(e)
        localStorage.setItem('cm-route-key',e)
      }
    }
  }
  </script>
  
