var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-bar"},[_c('BaseIconButton',{attrs:{"item":{
      name:'菜单',
      path:'/core/apps',
      icon:'md-apps'
    },"actived":_vm.isRouteActive('/core/apps')}}),_c('BaseIconButton',{attrs:{"item":{
      name:'主页',
      path:'/core/dashboard',
      icon:'md-desktop'
    },"actived":_vm.isRouteActive('/core/dashboard')}}),(
    _vm.threadIndex>0)?_c('div',{staticClass:"hover-lighter",staticStyle:{"border-bottom":"1px solid var(--border)","height":"20px","text-align":"center","line-height":"18px"},on:{"click":function($event){_vm.threadIndex--}}},[_c('BaseIcon',{attrs:{"icon":"ios-arrow-up","color":"var(--text1)"}})],1):_vm._e(),_c('div',{staticStyle:{"overflow":"hidden","height":"calc(100% - 296px)"}},[_vm._l((_vm.threads.slice(_vm.threadIndex)),function(t){return [_c('BaseIconButton',{key:t.path,attrs:{"item":t,"actived":_vm.isRouteActive(t.url,t.base_url)},on:{"on-right-click":_vm.onContextmenu}})]})],2),(_vm.threads.slice(_vm.threadIndex).length > 16)?_c('div',{staticClass:"hover-lighter",staticStyle:{"border-top":"1px solid var(--border)","height":"20px","text-align":"center","line-height":"18px"},on:{"click":function($event){_vm.threadIndex++}}},[_c('BaseIcon',{attrs:{"icon":"ios-arrow-down","color":"var(--text1)"}})],1):_vm._e(),_c('BaseIconButton',{staticStyle:{"position":"absolute","bottom":"130px","left":"0","border-top":"1px solid var(--border)"},attrs:{"item":{
      name:'同事',
      icon:'contact',
      size:24
    }},on:{"on-click":function($event){_vm.showChat=!_vm.showChat}}}),_c('BaseIconButton',{staticStyle:{"position":"absolute","bottom":"88px","left":"0","border-top":"1px solid var(--border)"},attrs:{"item":{
      name:'主题',
      icon:'ios-color-palette',
      size:24
    }},on:{"on-click":function($event){_vm.showThemePanel=!_vm.showThemePanel}}}),_c('BaseIconButton',{staticStyle:{"position":"absolute","bottom":"44px","left":"0"},attrs:{"item":{
      name:'个人中心',
      path:'/core/self',
      avatar:_vm.session.avatar
    },"actived":_vm.isRouteActive('/core/self','/core/self')}}),_c('BaseIconButton',{staticStyle:{"position":"absolute","bottom":"0","left":"0"},attrs:{"item":{
      name:'登出',
      icon:'md-power'
    }},on:{"on-click":_vm.logout}}),_c('Drawer',{attrs:{"width":"700","transfer":"","footer-hide":""},model:{value:(_vm.showChat),callback:function ($$v) {_vm.showChat=$$v},expression:"showChat"}},[_c('ChatBox',{staticStyle:{"height":"100%"},attrs:{"chat":true}})],1),_c('Modal',{attrs:{"title":"自定义主题","transfer":"","footer-hide":"","z-index":99999},model:{value:(_vm.showThemePanel),callback:function ($$v) {_vm.showThemePanel=$$v},expression:"showThemePanel"}},[_c('div',{staticClass:"flex-wrap flex-between",staticStyle:{"overflow":"hidden","width":"100%","flex-wrap":"wrap"}},[_vm._l((_vm.themes),function(t){return [_c('div',{key:t.id,staticClass:"pm-theme-item",class:{
          'pm-theme-item-active':t.key === _vm.theme
        },on:{"click":function($event){_vm.$store.commit('session/change_theme',t.key);_vm.$emit('close')}}},[_c('img',{attrs:{"src":'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/'+t.key+'.png'}}),_c('div',[_vm._v(" "+_vm._s(t.name)+" ")])])]})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }