<template>
  <div class="flex-wrap flex-line container align-stretch">
    <div style="width:160px;min-width:160px;background:var(--bg2);border-right:1px solid var(--border);">
      <BaseMenu :menus="menus" :actived="actived" @on-click="handleOpen" />
    </div>
    <div style="width:calc(100% - 140px);height:100%;position:relative;">
        <div class="flex-wrap" style="height:60px;padding:0 15px;">
          <div>
            <div style="font-size:16px;font-weight:bold;">{{ activedMenu.name }}</div>
            <div style="font-size:12px;color:var(--subtext2)">{{ activedMenu.description }}</div>
          </div>
          <div></div>
          </div>
          <div style="height:calc(100% - 60px);position:relative;">
            
          <router-view></router-view>
          </div>
      </div>
  </div>
</template>

<script>
  export default {
    routerOption:{
      as_frame:true,
      as_default:true,
      redirect:'list'
    },
    computed:{
      menus(){
        return [{
          name:"组织架构",
          description:"管理部门划分，设置主管",
          key:"organization",
          icon:"organization"
        },{
          name:'在职员工',
          key:"list",
          description:"在职员工的花名册",
          icon:"contact"
        },{
          name:"离职员工",
          description:"离职员工信息管理",
          key:"dismission",
          icon:"contact"
        },{
          name:"退休员工",
          description:"退休员工信息管理",
          key:"retired",
          icon:"contact"
        },{
          name:"账号管理",
          key:"account",
          description:"员工业务系统账号分配调整",
          icon:"key"
        }]
      },
      actived(){
        return this.$route.path.slice(this.$route.path.lastIndexOf('/')+1)
      },
      activedMenu(){
        return this.menus.find(m=>m.key == this.actived) || {}
      },
    },
    methods:{
      handleOpen(e){
        let baseUrl = this.$route.path.slice(0,this.$route.path.lastIndexOf('/'))
        localStorage.setItem(baseUrl, baseUrl+'/'+e)
        this.RouteTo(e)
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>