

<template>
  <App>
    <AppMask
      class="flex-wrap flex-center"
      style="background:var(--bg2);border-left:1px solid var(--border);overflow:hidden;z-index:1000;color:var(--subtext2);transition:all 0.3s ease-out"
      v-if="loading"
      :style="ready?'':'top:120%;'"
    >
      <div class="flex-col">
        <h2 style="font-size:40px">培训 - 后台管理</h2>
        <div class="flex-wrap" style="margin-top:10px">
          <BaseIcon icon="nbgz" color="var(--subtext2)" size="40" />
          <BaseLoading style="margin:0" />
          <BaseIcon icon="huiyi" color="var(--subtext2)" size="40" />
        </div>
      </div>
    </AppMask>
    <AppMask
      class="flex-wrap flex-center"
      style="background:var(--bg1);border-left:1px solid var(--border);color:#fff;transition:all 0.3s ease-out;bottom:50%;"
      v-if="loading"
      :style="ready?'':'bottom:120%'"
    >
      <div></div>
    </AppMask>

    <AppBar>
      <div class="flex-wrap">
        <AppTitle icon="huiyi" name="培训 - 后台管理" />
      </div>

      <div class="flex-wrap split5">
        <Button @click="RouteTo('/core/training')" type="primary" size="small">
          培训中心
          <BaseIcon icon="md-arrow-forward" />
        </Button>
        <AppAvatar />
      </div>
    </AppBar>

    <AppClient class="flex-wrap flex-line align-stretch">
      <div style="width:160px;flex-shrink:0;height:100%;">
        <BaseMenu :menus="menus" :actived="actived" @on-click="handleOpen"></BaseMenu>
      </div>
      <div style="background:var(--bg3);flex-grow:1">
        <keep-alive>
          <router-view ref="sub"></router-view>
        </keep-alive>
      </div>
    </AppClient>
  </App>
</template>
    
    <script>
import responsableMixin from "@/mixins/responsable";
import { mapGetters } from "vuex";
import App from "@/components/app/app";
import AppBar from "@/components/app/bar";
import AppTitle from "@/components/app/title";
import AppMask from "@/components/app/mask";
import TaskLeft from "@/components/task/editor/left";
import VerticleMenu from "@/components/app/vmenu";
import AppAvatar from "@/components/app/avatar";
import AppClient from "@/components/app/client";
import IconButton from "@/components/app/IconButton";

export default {
  components: {
    App,
    AppBar,
    AppMask,
    TaskLeft,
    AppClient,
    AppAvatar,
    IconButton,
    AppTitle,
    VerticleMenu
  },
  data() {
    return {
      loading: false,
      showMenu: true,
      view: "task",
      ready: true,
      task: {
        name: "新建表单"
      },
      showHelper: true
    };
  },
  routerOption: {
    as_frame: true
  },
  mixins: [responsableMixin],
  watch: {
    $route: {
      handler(v) {
        // if(v && !v.includes('new'))
        //   return //load
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.ready = true;
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      setTimeout(() => {
        this.$store.dispatch("mat/getCompanies");
        this.ready = false;
      }, 1000);
    }, 1000);
  },
  activated() {
    this.$nextTick(e => {
      this.calcLayout();
    });
  },
  computed: {
    ...mapGetters("mat", ["level"]),
    actived() {
      return this.$route.path.slice(this.$route.path.lastIndexOf("/") + 1);
    },
    menus() {
      return [
        {
          name: "数据总览",
          key: "dashboard",
          icon: "md-speedometer"
        },
        {
          name: "培训项目",
          key: "projects",
          icon: "pm2"
        },
        {
          name: "培训视频",
          key: "videos",
          icon: "Building-"
        },
        {
          name: "系统及权限",
          key: "system",
          icon: "key"
        }
      ];
    }
  },
  methods: {
    handleOpen(e) {
      this.RouteTo(e);
      //localStorage.setItem('cm-route-key',e)
    }
  },
};
</script>