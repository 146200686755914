<template>
    <div style="position:relative;height:100%;">
     <div style="width:100%;height:30px;font-weight:bold;">
       <BaseIcon icon="md-apps" style="margin-right:5px" />合同风险监控 </div>
 
 
       
       <div class="flex-wrap flex-col" style="height:calc(100% - 30px);align-items:stretch;">
        <div class="flex-wrap flex-around" style="background:var(--bg3);height:70px;">
            <BaseNumboard name="一般风险" value="23" />
            <BaseNumboard name="重大风险" value="4" value-style="color:var(--warning)"/>
            <BaseNumboard name="紧急风险" value="1" value-style="color:var(--error)" />
            <BaseNumboard name="已处理" value="13" value-style="color:var(--success)" />
        </div>
        <div style="margin-top:10px;height:calc(100% - 70px);overflow:hidden;background:var(--bg3);padding:10px;border-radius:5px;">
            <template v-for="item in items">
                <div class="flex-wrap flex-between" style="padding:5px;min-height:40px;" :key="item.id">
                   <div class="flex-wrap"> 
                    <div style="font-size:12px;padding:3px 8px;background:var(--border);color:var(--primary);margin-right:10px;border-radius:2px;">{{ item.type }}</div>
                    <div >
                    <div style="font-weight:bold;color:var(--primary)">
                        {{ item.name }}
                    </div>
                    <div style="font-size:12px;color:var(--subtext3);width:240px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">
                        {{ item.contract }}
                    </div>
                    </div>
                </div>
                   <div style="font-size:12px;padding:2px 4px;background:var(--primary);color:var(--hover-text);margin-right:10px;border-radius:4px;">已受控</div>
                </div>
            </template>
        </div>
      </div>
     </div>
    
 </template>
 
 <script>
 export default {
     data(){
         return {
             items:[{
                type:"一般风险",
                name:"收款延期风险",
                 contract:'宁波至慈溪市域（郊）铁路工程九龙大道站土建施工监理',
                 node:"事业部审核",
                 state:"草稿"
             },{
                type:"一般风险",
                name:"工程延期风险",
                contract:"宁大宿舍",
                 state:"履约中"
             },{
                type:"一般风险",
                name:"罚款风险",
                contract:'宁波广电融媒体科技创新大厦',
                 state:"付款中"
             },{
                type:"一般风险",
                name:"收款延期风险",
                contract:'宁波广电融媒体科技创新大厦',
                 state:"付款中"
             }]
         }
     }
 }
 </script>
 
 <style>
 
 </style>