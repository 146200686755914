<template>
  <div>
		<div class="flex-wrap" style="width: 100%">
			<BaseEmpty v-if="!nodes || nodes.length == 0">
				<div class="flex-col flex-center">
					<img
						src="https://inbgz-1257839135.cos.ap-shanghai.myqcloud.com/assets/undraw_team_up_re_84ok.svg"
						style="
							width: 150px;
							height: 150px;
							margin-bottom: 10px;
							filter: brightness(0.8);
							opacity: 0.89;
							margin-top: 50px;
						"
					/>
					<p style="color: var(--bg1); font-size: 25px">
						当前项目未创建签约计划
					</p>
					<p style="color: var(--bg1); font-size: 12p; margin-bottom: 10px">
						建议创建签约计划，可更准确测算项目各阶段阶段人力、进度及产值数据
					</p>
					
				</div>
			</BaseEmpty>
			<template v-else>
				<div
					class="flex-wrap "
					style="
						flex-wrap: nowrap !important;
						align-items: stretch;
						overflow: visible;
						width: 100%;
					"
				>
					<div
						style="
							width: 100px !important;
							padding: 5px;
							flex-shrink: 0;
							overflow: hidden;
						"
						:style="``"
					>
						<div
							style="
								width: 100%;
								background: none;
								height: 22px;
								font-size: 10px;
								color: #ddd;
								text-align: center;
							"
						></div>
						<div class="pg-node-header">
							<div
								class="flex-wrap flex-center"
								style="
									font-size: 14px;
									height: 80px;
									margin: 5px 0;
									text-align: center;
									font-weight: bold;
									color: var(--bg1);
									overflow: hidden;
								"
							></div>
							<div
								style="
									border-top: 1px solid #00000000;
									margin-bottom: 5px;
									margin-top: 5px;
								"
							></div>
							<div
								class="flex-wrap flex-between pg-node-item"
								style="font-weight: bold; text-align: left"
							>
								<BaseIcon icon="date" color="var(--primary)" /> 起始日期
							</div>
							<div
								class="flex-wrap flex-between pg-node-item"
								style="font-weight: bold; background: var(--bg2)"
							>
								<BaseIcon icon="date" color="var(--primary)" />结束日期
							</div>
							<div
								class="flex-wrap flex-between pg-node-item"
								style="font-weight: bold"
							>
								<BaseIcon icon="clock" color="var(--primary)" /> 阶段工期
							</div>
							
							<div
								class="flex-wrap flex-between pg-node-item"
								style="font-weight: bold; background: var(--bg2)"
							>
								<BaseIcon icon="md-list" color="var(--primary)" /> 进度对比
							</div>
							<div
								style="
									border-top: 1px dashed #00000000;
									margin: 4px 0;
								"
							></div>
							<div
								class="flex-wrap flex-between pg-node-item"
								style="font-weight: bold; background: var(--bg2)"
							>
								<BaseIcon icon="md-podium" color="var(--primary)" /> 阶段产值
							</div>
							<div
								class="flex-wrap flex-between pg-node-item"
								style="font-weight: bold"
							>
								<BaseIcon icon="md-pie" color="var(--primary)" /> 产值占比
							</div>
							<div
								class="flex-wrap flex-between pg-node-item"
								style="font-weight: bold; background: var(--bg2)"
							>
								<BaseIcon icon="md-podium" size="17" color="var(--primary)" />
								月均产值
							</div>
							<div
								class="flex-wrap flex-between pg-node-item"
								style="font-weight: bold"
							>
								<BaseIcon icon="supervisor" color="var(--primary)" /> 投入人力
							</div>
							<!-- <div class="flex-between pg-node-item" style="font-weight:bold;">
							<BaseIcon icon="supervisor" color="darkgreen" /> 实际投入
						</div> -->
							<div
								class="flex-wrap flex-between pg-node-item"
								style="font-weight: bold; background: var(--bg2)"
							>
								<BaseIcon icon="md-podium" size="17" color="var(--primary)" />
								人均产值
							</div>

							<!-- <div class="flex-between pg-node-item" style="font-weight:bold;">
							<BaseIcon icon="md-podium" size="17" color="darkgreen" /> 实际人均
						</div> -->
							<!-- <template v-for="h in node.hrplans">
              <div :key="h.id">{{h.position}} {{h.factor}}</div>
            </template> -->

							<div></div>
						</div>
					</div>
					<div class="flex-wrap flex-line scroll-x" style="width:calc(100% - 100px);overflow-x:auto;">
					<template v-for="(node, i) in nodes">
						<div
							:key="'node_'+node.id"
							class="node-item"
							:style="`${node.status == '已结束' ? 'filter:grayscale(0.7)' : ''}`"
						>
							<div
								style="
									width: 100%;
									background: var(--bg1);
									padding: 2px 7px;
									font-size: 10px;
									color: #ddd;
									text-align: center;
									font-weight: normal;
								"
								:style="`background:${node.statusColor}`"
							>
								{{ i + 1 }}-{{ node.status }}
							</div>
							<div style="width: 100%">
								<div
									class="flex-wrap flex-center"
									style="
										font-size: 14px;
										height: 80px;
										margin: 5px 0;
										padding:0 5px;
										text-align: center;
										font-weight: bold;
										color: var(--text2);
										overflow: hidden;
										background: var(--bg2);
									"
								>
									{{ node.name }} {{node.id == value.operate_node_id?'[开工]':''}} {{node.id == value.complete_node_id?'[竣工]':''}}
								</div>
								<div
									style="
										border-top: 1px dashed var(--primary);
										margin-bottom: 5px;
										margin-top: 5px;
									"
								></div>
								<div
									class="flex-wrap flex-between pg-node-item"
									style="color: var(--success)"
								>
									<span />{{
										moment(node.plan_started_at).format("YYYY/MM/DD")
									}}
								</div>
								<div
									class="flex-wrap flex-between pg-node-item"
									style="background: var(--bg2); color: var(--success)"
								>
									<span />{{
										moment(node.plan_finished_at).format("YYYY/MM/DD")
									}}
								</div>
								<div class="flex-between pg-node-item">
									<span />{{ node.plan_duration }}
									<span style="font-size: 10px; color: var(--subtext3)"
										>天</span
									>
								</div>
								<div class="flex-between pg-node-item" :style="`color:var(--${(node.offset_duration > 0)?'error':'success'});`">
									<template v-if="node.version == 'origin'">
										<span> - </span>
									</template>
									<template v-else>
										<span> {{ node.offset_duration > 0?'滞后':'提前' }} {{ (node.offset_duration > 0)?node.offset_duration:-node.offset_duration }}</span>
										<span style="font-size: 10px;margin-left:5px; color: var(--subtext3)"
											>天</span
										>
									</template>
								</div>
								<div
									style="
										border-top: 1px dashed var(--primary);
										margin: 5px 0;
									"
								></div>
								<div
									class="flex-between pg-node-item"
									style="background: var(--bg2)"
								>
									<span /><BaseAmount :value="value.amount * node.percent/100" />
								</div>
								<div class="flex-between pg-node-item">
									<span /><span />{{ node.percent }}
									<span style="font-size: 10px; color: var(--subtext3)">%</span>
								</div>
								<div
									class="flex-between pg-node-item"
									style="background: var(--bg2)"
								>
									<span />{{ node.month_production }}
									<span style="font-size: 10px; color: var(--subtext3)"
										>万</span
									>
								</div>
								<div class="flex-between pg-node-item">
									<span />{{
										node.actual_hr ? node.actual_hr : node.plan_hr || "-"
									}}
									<span style="font-size: 10px; color: var(--subtext3)"
										>人月</span
									>
								</div>
								<div
									class="flex-between pg-node-item"
									style="background: var(--bg2)"
								>
									<span />{{
										node.actual_hrp ? node.actual_hrp : node.plan_hrp || "-"
									}}
									<span style="font-size: 10px; color: var(--subtext3)"
										>万</span
									>
								</div>
								<div
									style="border-top: 1px dashed var(--primary); margin: 5px 0"
								></div>
								<div
									style="
										text-align: right;
										font-size: 10px;
										color: #888;
										font-weight: normal;
										margin-top: 5px;
									"
								>
									<span
										>版本:{{
											node.version == "origin" ? "签约" : node.version
										}}</span
									>
								</div>
								<!-- <template v-for="h in node.hrplans">
              <div :key="h.id">{{h.position}} {{h.factor}}</div>
            </template> -->

								<div></div>
							</div>
						</div>
					</template>
					</div>
				</div>
			</template>
		</div>
    </div>
</template>

<script>
  export default {
    computed:{
      nodes(){
        return this.value.nodes || []
      }
    },
    props:{
      value:{
        type:Object,
        default:() => ({})
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>