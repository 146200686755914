import {cloneDeep} from 'lodash'
export default {
  computed:{
    defaultValue(){
      return this?.config?.defaultValue || undefined
    }
  },
  mounted(){
    this.$nextTick(()=>{
      if(this.value == undefined && this.defaultValue !== undefined){
        this.$emit('input',typeof this.defaultValue=='object'?cloneDeep(this.defaultValue):this.defaultValue)
      }
    })
    
  }
 
}