<template>
   <BaseSegment title="建安工程量统计" desc="按实际发生值填写建安工程量统计" style="margin:0">
  <div class="flex-wrap split10" slot="extra">
				<BaseBoardButton icon="md-create" name="编辑" :desc="moment().fromNow()" @on-click="startEdit" />

</div>
  <div class="flex-wrap flex-left split10" style="background:var(--bg2);margin:0 10px;padding:10px;" v-if="data.building_production">
      <BaseNumboard name="施工合同总额" align="flex-start" :value="data.building_production?formatSalaryObject(data.building_production).value:'-'" :unit="formatSalaryObject(data.building_production).unit" reverse />
      <BaseBoardDivider />

      <BaseNumboard name="已完成" align="flex-start"  :value="sum?formatSalaryObject(sum).value:'-'" :unit="formatSalaryObject(sum).unit" reverse />

       <BaseProgressBoard name="施工进度" align="flex-start" :value="building_production?parseInt(sum*100 / building_production):0"  reverse />
      <BaseBoardDivider />
      <BaseNumboard name="当前阶段" align="flex-start"  :value="last.desc && last.desc.length > 20?(last.desc.slice(0,20)+'...'):last.desc" :title="last.desc && last.desc.length > 20?last.desc:''"  reverse :value-style="'font-size:15px;line-height:32px;height:32px;'" />
       <BaseNumboard name="当前人月投入" align="center"  :value="last.hrv" unit="人月" reverse  />
        <BaseNumboard name="当前人均工程量" align="flex-end"  :value="last.avg?formatSalaryObject(last.avg).value:'0'" unit="万" reverse />
       <BaseNumboard name="累计人月投入" align="center"  :value="last.acc_hrv" unit="人月" reverse />
        <BaseNumboard name="累计人均工程量" align="flex-end"  :value="formatSalaryObject(last.acc_avg).value" unit="万" reverse />
        
       
     
     </div>
   

    <div style="padding:0 10px;margin-top:20px;padding-bottom:10px;">
    <BaseEmpty v-if="items.length == 0" />
    <template v-else>
      <div class="flex-wrap flex-right" style="margin-bottom:10px" v-if="items.length > 5"><a @click="showAll=!showAll">{{showAll?'仅显示最近5条':`显示全部(${items.length}条)`}}</a></div>
      <div class="flex-wrap flex-between cm-list-item flex-line" style="font-size:14px;font-weight:bold" >
        <div class="flex-wrap" style="padding:0 5px;flex-grow:1;">
             <div style="" class="flex-wrap"><span class="u-index" style="width:40px">序号</span> 
             <span style="text-align:center;margin:0 10px;min-width:70px;">开始日期</span> 
             <span style="text-align:center;margin:0 10px;min-width:70px;">结束日期</span> <div style="width:200px;flex-grow:1;">形象进度(施工阶段)</div> </div> 
          
            
          </div>
           <div class="flex-wrap" style="font-weight:bold;color:#369;font-size:14px;padding-right:10px;">   <div style="width:80px">阶段人月</div>
            <div style="width:100px;text-align:right">阶段人均</div><div  style="width:100px;text-align:right;">施工完成产值</div> <div  style="width:100px;text-align:right;color:var(--bg1)">累计完成产值</div> <div style="width:80px;text-align:center;">
            累计占比</div>
            <div style="width:80px;text-align:right;">
            累计人月</div>
            <div style="width:100px;text-align:right;">
            累计人均</div>
      </div>
      </div>
      
      <div class="flex-wrap  flex-between cm-list-item" :key="item.id" v-for="(item,index) in (showAll?items:items.slice(-5))">
        <div class="flex-wrap" style="padding:0 5px;flex-grow:1;" :key="item.id">
             <div style="color:var(--bg1);font-size:14px;font-weight:bold;margin-bottom:2px;" class="flex-wrap"><span class="u-index" style="width:40px">{{index+1}}</span> 
             <span style="color:#369;margin:0 10px;">{{moment(item.started_at).format("YYYY/MM/DD")}}</span><span style="color:#369;margin:0 10px;">{{moment(item.finished_at).format("YYYY/MM/DD")}}</span>  <div style="width:190px;flex-grow:1;white-space:pre-wrap;">{{item.desc}}</div> </div> 
             
            
          </div>
           <div class="flex-wrap" style="font-weight:bold;color:#369;font-size:14px;padding-right:10px;">  <div style="width:80px;text-align:center;">{{item.hrv || "-"}}</div>
            <div style="width:100px"><BaseAmount :value="item.avg" /></div><div  style="width:110px;text-align:right;">￥{{formatSalary(item.amount)}}</div> <div  style="width:100px;text-align:right;color:var(--bg1)">￥{{formatSalary(item.acc_amount)}}</div> <div style="width:80px;text-align:center;">
            {{item.percent}}%</div><div  style="width:80px;text-align:right;padding:0 15px;">{{item.acc_hrv || '-'}} </div> <div style="width:100px"><BaseAmount :value="item.acc_avg" /></div></div>
      </div>
    </template>
    <div class="flex-wrap flex-right" style="margin-top:10px" v-if="items.length > 10 && showAll"><a @click="showAll=!showAll">{{showAll?'仅显示最近5条':'显示全部'}}</a></div>
    </div>
    <Modal v-model="modalEdit" title="建安工程量" footer-hide width="1200">
      <div style="padding:20px">
        <div class="flex-wrap flex-between" style="margin-bottom:10px" >
          <div>施工合同总额 <Input v-model="formData.building_production" style="width:200px;margin-left:10px;margin-right:10px;" /> <span style="font-size:10px">{{formatSalary(formData.building_production)}}</span></div>
          
          <div> <Button  v-if="formData && formData.bp && formData.bp.length > 5" type="primary" @click="handleSave" :loading="loadingSave">保存</Button></div>
        </div>
        <div>
          <BaseControlTable label="建安工程量记录" :editable="true" v-model="formData.bp" :config="config" />
         
        </div>
        <div class="flex-wrap flex-right" style="margin-top:10px">
          <Button type="primary" @click="handleSave" :loading="loadingSave">保存</Button>
        </div>
      </div>
      
    </Modal>
  </BaseSegment>
</template>

<script>
import UTIL from "@/utils"
import {time_int_duration,fix,percent} from "@/utils/calc"
import moment from 'moment'
import {cloneDeep} from 'lodash'
export default {
  data(){
    return {
      modalEdit:false,
      loadingSave:false,
      showAll:false,
      bp:[],
      formData:{}
    }
  },
  props:{
    data:{
      type:Object,
      default:()=>({})
    }
  },
  computed:{
    id(){
      return this.$route.params.id
    },
    last(){
      return this.bp[this.bp.length - 1] || {}
    },
    items(){
      return this.bp
    },
    building_production(){
      return this.data.building_production
    },
    sum(){
      if(Array.isArray(this.data.bp))
        return this.data.bp.reduce((sum,a)=>sum+a.amount,0)
      return 0
    },
    config(){
      return {
        disable_moveupdown:true,
        cols:[{
            key:'started_at',
            label:'开始日期',
            type:'date',
            width:120,
            defaultValue:this.last.finished_at
          },{
            key:'finished_at',
            label:'结束日期',
            type:'date',
            width:120,
            defaultValue:moment().format()
          },{
            key:'amount',
            label:'金额 (元)',
            align:"right",
            config:{
              formatter:e=>'¥ '+this.formatSalary(e)
            },
            width:150
          },{
            key:'desc',
            label:'形象进度',
            align:"left",
            width:'auto'
          }]
      }
    }
  },
  watch:{
    "data":{
      handler(v){
         this.computeData()
      },
      deep:true,
      immediately:true
    }
  },
  mounted(){
    this.computeData()
  },
  methods:{
    formatSalaryObject(e){
      return UTIL.formatSalaryObject(e)
    },
    formatSalary(e){
      return UTIL.formatSalary(e)
    },
    startEdit(){
      this.formData = {
        building_production:this.data.building_production,
        bp:this.data.bp.map(v=>({...v}))
      }
      this.modalEdit = true
    },
    computeData(){
      if(this.data.building_production){
        let hr = this.data.hr
        if(Array.isArray(this.data.bp)){
          let s = 0
          let acc_hrv = 0;
          let bp =  cloneDeep(this.data.bp)
          bp.forEach(v=>{
            s = s + (v.amount || 0)
            v.hrv = 0
            v.duration = 0
            v.avg = 0;
           
            hr.forEach(h=>{
              let duration = time_int_duration(v.started_at || this.data.started_at,v.finished_at,h.started_at,(h.finished_at || moment()),'months')
              let hrv = duration * h.factor
              v.hrv += hrv
              acc_hrv += hrv

             
            })

            v.avg = fix(v.amount / v.hrv )
            
            v.acc_hrv = fix(acc_hrv)
            v.acc_avg = fix(s / v.acc_hrv )
            v.hrv = fix(v.hrv)
            v.acc_amount = s
            v.percent = fix(100 * s / this.data.building_production)
          })
          this.bp = bp
        }
      }
    },
    handleSave(){
      let data = {}
      if(!Array.isArray(this.formData.bp)){
        return
      }

      for(let i=0;i<this.formData.bp.length;i++){
        let v = this.formData.bp[i]
        if(!v.started_at){
          this.Error(`第${i+1}条记录:开始时间不可为空`)
          return
        }

        if(!v.finished_at){
          this.Error(`第${i+1}条记录:结束时间不可为空`)
          return
        }

        if(moment(v.started_at).isAfter(moment(v.finished_at))){
          this.Error(`第${i+1}条记录:开始时间不可大于结束时间,注意修改`)
          return
        }
      }
      let bp_patched = UTIL.compare_patched(this.data.bp,this.formData.bp,['started_at','finished_at','amount','desc'])
      if(bp_patched)
        data.bp = bp_patched
      if(this.formData.building_production != this.data.building_production)
        data.building_production = this.formData.building_production
      this.loadingSave = true
      this.$api.patch(`projects/${this.id}?q=bp`,data).then(res=>{
        let updateInfo = res.data.data
        Object.assign(this.data,updateInfo)
        this.$Notice.success({title:"更新成功"})
        this.modalEdit = false
        this.formData = {}
      }).catch(e=>{
        this.$Notice.error({title:"更新失败",desc:e?e.message:'未知原因'})
      }).finally(e=>{
         this.loadingSave = false
      })
    }
  }
}
</script>

<style>

</style>