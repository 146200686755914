<template>
  <div class="container">
    <div class="flex-wrap" style="height:40px;width:100%;padding:0 5px;">
      <Button type="primary" icon="md-add" style="margin-right:5px" @click="formData={};showModalEditor=true;">添加</Button>
      <Input search clearable v-model="searchText" placeholder="材料名称/型号" style="width:200px;margin-right:10px;" 
      @on-enter="getData"
      @on-search="getData"
       />
      信息级别 <ButtonGroup style="margin-left:10px">
        <template v-for="(l,i) in levels">
          
        <Button :key="`levl_${l}`" :type="selected_level == i?'primary':'default'" @click="selected_level=i;getData()">{{l}}</Button>
        </template>
      </ButtonGroup>
    </div>
    <div style="height:calc(100% - 50px);position:relative;margin:0 5px;border:1px solid var(--border);border-radius:5px;padding-bottom:5px;">
      <BaseTable 
        :columns="cols" 
        :data="items" 
        paged
        :loading="loading"
        :count="count"
        @event="handleTableEvent" 
      />
    </div>

    <Drawer v-model="showModalEditor" footer-hide :width="1200">
      <FormCreateProject style="margin:0 20px" :value="formData" @on-cancel="showModalEditor = false" @on-submit="handleSubmit" />
    </Drawer>

    <Modal v-model="showModalEditPrice" title="新增询价信息" footer-hide :width="500">
      <FormPrice ref="price" style="margin:0px"
      @on-cancel="showModalEditPrice = false" @on-success="handleSubmitPrice" />
    </Modal>

  </div>
</template>

<script>
import FormCreateProject from '@/components/mat/CreateProductForm'
import FormPrice from '@/components/mat/CreatePrice'
import UTIL from '@/utils'
import {cloneDeep} from 'lodash'
export default {
  data(){
    return {
      searchText:"",
      levels:['L1','L2','L3'],
       formData:{},
       showModalEditor:false,
       showModalEditPrice:false,
       selected_level:0,
       selected_product:{},
      page:1,
      loading:false,
      count:100,
      sort_key:'created_at',
      sort_order:'desc',
      items:[]
    }
  },
  components:{FormCreateProject,FormPrice},
  computed:{
    cols(){
      return [{
        type:"index",
        title:"序号",
        width:80
      },{
        type:"type",
        
        key:"type_id",
        title:"类型",
        width:120,
        option:{
          getters:"mat/types"
        }
      },{
        type:"text",
        
        key:"name",
        title:"名称",
        width:220,
        render:(h,{row})=>{
          let logo = h('img',{style:'width:80px;height:80px;border:1px solid var(--border);margin:10px;flex-shrink:0',domProps:{src:row.avatar || 'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/empty-img.png'}})
          let name = h('div',[h('div',{style:'text-align:left'},row.name),h('div',{style:'color:var(--subtext3);font-size:12px;text-align:left'},row.model || '')])
          return h('div',{class:'flex-wrap'},[logo,name])
        }
      },{
        type:"type",
        title:"生产商",
        key:"producer_id",
        width:180,
        render:(h,{row})=>{
          if(!row.producer)
            return h('span','-')
          let logo = h('img',{style:'width:50px;height:50px;border:1px solid var(--border);margin:10px;flex-shrink:0',domProps:{src:row.producer.avatar || 'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/empty-img.png'}})
          let name = h('div',[h('div',{style:'text-align:left'},row.producer.name),h('div',{style:'color:var(--subtext3);font-size:12px;text-align:left'},row.producer.address || '')])
          return h('div',{class:'flex-wrap'},[name])
        }
      },{
        type:"time",
        title:"询价日期",
        key:"accquired_at",
        width:100,
        option:{
          type:"date"
        }
      },{
        type:"number",
        title:"无税价格",
        key:"notax_amount",
        width:140,
        option:{
          type:"fullAmount"
        },
         render:(h,{row})=>{
          if(!row.notax_amount)
            return h("span",'-')
          let amount = h('BaseAmount',{props:{value:row.notax_amount}})
          let unit = h('span',{style:"color:var(--subtext3);margin-left:5px;"},'元/'+row.unit)
          return h('div',{class:"flex-wrap flex-right"},[amount,unit])
        }
      },{
        type:"number",
        title:"含税价格",
        key:"amount",
        width:140,
        option:{
          type:"fullAmount"
        },
         render:(h,{row})=>{
             if(!row.amount)
            return h("span",'-')
          let amount = h('BaseAmount',{props:{value:row.amount}})
          let unit = h('span',{style:"color:var(--subtext3);margin-left:5px;"},'元/'+row.unit)
          return h('div',{class:"flex-wrap flex-right"},[amount,unit])
        }
      },{
         type:"text",
        title:"税率",
        key:"taxrate",
        width:80,
        render:(h,{row})=>{
          let percent = h('span',{style:"color:var(--subtext3);margin-left:2px;"},'%')
          return row.taxrate?h('span',[row.taxrate,percent]):h('span','-')
        }
        },{
        type:"bool",
        title:"含运费",
        key:"with_transfer_fee",
        width:80
      },{
        type:"bool",
        title:"含安装",
        key:"with_install_fee",
        width:80
      },{
         type:"type",
        title:"供货商",
        key:"supplier_id",
        width:180,
        render:(h,{row})=>{
          let supplier = this.$store.getters['mat/companies'].find(v=>v.id == row.supplier_id)
          if(!supplier)
            return h('span','-')

          let logo = h('img',{style:'width:50px;height:50px;border:1px solid var(--border);margin:10px;flex-shrink:0',domProps:{src:supplier.avatar || 'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/empty-img.png'}})
          let name = h('div',[h('div',{style:'text-align:left'},supplier.name),h('div',{style:'color:var(--subtext3);font-size:12px;text-align:left'},supplier.address || '')])
          return h('div',{class:'flex-wrap'},[name])
        }
        },{
          key:"created_by",
          title:"录入人",
          width:90,
          type:"user"
        },{
          key:"created_at",
          title:"录入时间",
          width:90,
          type:"time"
        },{
        title:"操作",
        render:(h,{row})=>{
          let buttons = [h('Button',{props:{size:"small",icon:"md-add"},on:{
            click:()=>{
              this.handleAddPrice(row)
            }
          }},'价格'),h('Button',{props:{size:"small"},style:"margin-left:10px",on:{
            click:()=>{
              this.handleEdit(row)
            }
          }},'修改'),
          h('Button',{props:{size:"small"},style:"margin-left:10px",on:{
            click:()=>{
              this.handleDelete(row)
            }
          }},'删除'),
          ]
          return h("div",{class:"flex-wrap flex-center"},buttons)
        }
      }]
    },
  },
  mounted() {
		setTimeout(() => {
      this.getCount()
			this.getData();
		}, 500);
	},
	methods: {
    handleTableEvent(e){
      if(e.type == 'page-change'){
        this.page = e.data
        this.getData()
      }else if(e.type == 'sort'){
        this.sort_key = e.data.key || 'created_at'
        this.sort_order = e.data.order
        this.getData();
      }
    },  
    handleAddPrice(e){
      this.$refs.price.setProduct(e)
      this.selected_product = e
      this.showModalEditPrice = true
    },
    getCount(){
      this.$api.get(`mat/products?q=count`).then(res=>{
        this.count = res.data.data
      })
    }, 
		getData() {
      let page = this.page
      this.loading = true
      let url = `mat/products?related=producer,prices,supplier&page=${page}&pagesize=20&order=${this.sort_key}&desc=${this.sort_order=='desc'?1:0}`
      if(this.searchText)
        url += `search=${this.searchText}`
			this.$api.get(url).then((res) => {
				let items = res.data.data;
        items.forEach(v=>{
          if(!Array.isArray(v.prices))
            return
          if(v.prices){
            v.prices.forEach(p=>{
              if(p.amount && p.taxrate && !p.notax_amount){
                p.notax_amount = parseInt(100*(p.amount * (1-p.taxrate/100)))/100
              }else{
                if(p.notax_amount && p.taxrate && !p.amount){
                p.amount = parseInt(100*(p.notax_amount * (1+p.taxrate/100)))/100
              }
              }

              
            })
          }
          this.findLastPrice(v)
        })
        this.items = items
			}).finally(()=>{
        this.loading = false
      })
		},
    handleEdit(e){
      this.$api.get(`mat/products/${e.id}?full=2`).then(res=>{
        
        this.formData = cloneDeep(res.data.data)
        this.showModalEditor = true
      })
      
    },
		handlePatch(e) {
			this.modalEditor = true;
		},
    handleSubmit(e){
       if(e.prices){
          e.prices.forEach(p=>{
            if(p.amount && p.taxrate && !p.notax_amount){
              p.notax_amount = parseInt(100*(p.amount * (1-p.taxrate/100)))/100
            }else{
              if(p.notax_amount && p.taxrate && !p.amount){
              p.amount = parseInt(100*(p.notax_amount * (1+p.taxrate/100)))/100
            }
            }

            
          })
        }
      if(e.id){
        let origin = this.formData
        let updateData = UTIL.compare_patch_object(origin,e,['name','avatar','model','producer_id','type_id','series','brand_id','product_area','specification','prices','files','note'])
        
        this.$api.patch(`mat/products/${e.id}`,updateData).then(res=>{
          let item = {...e}
          let index = this.items.findIndex(v=>v.id == e.id)
        
      
          this.findLastPrice(item)
          
          this.items.splice(index,1,item)
          this.Success({title:"修改成功"});
          this.showModalEditor = false
        }).catch(this.Error)
      }else{
        this.$api.post("mat/products",e).then(res=>{
          let item = {...e}
          Object.assign(item,res.data.data)
          this.Success({title:"添加成功"})
          this.findLastPrice(item)
          this.items.push(item)
          
          this.showModalEditor = false
        }).catch(this.Error)
      }
    },
    findLastPrice(v){
      let prices = v.prices.filter(p=>p.level >= this.selected_level)
      if(Array.isArray(prices) && prices.length > 0){
           let last_price = prices[prices.length-1]
           v.taxrate = last_price.taxrate
           v.accquired_at = last_price.accquired_at
           v.amount = last_price.amount
           v.with_transfer_fee = last_price.with_transfer_fee
           v.with_install_fee = last_price.with_install_fee
           v.notax_amount = last_price.notax_amount
           v.unit = last_price.unit
           v.supplier_id = last_price.supplier_id
          }
    },
    handleSubmitPrice(e){
      this.showModalEditPrice = false
      let level = e.level || 0
      if(this.selected_level <= level){
        this.selected_product.taxrate = e.taxrate
        this.selected_product.accquired_at = e.accquired_at
        this.selected_product.amount = e.amount
        this.selected_product.unit = e.unit
        this.selected_product.supplier_id = e.supplier_id
        let index = this.items.findIndex(v=>v.id == this.selected_product.id)
        if(index != -1)
          this.items.splice(index,1,this.selected_product)

      }
    },
		handleDelete(e) {
			this.Confirm(`将删除此产品(${e.name}/${e.model})，是否继续?`, () => {
				this.$api.delete("mat/products/"+e.id).then(() => {
					this.Success({title:"删除成功"});
          let index = this.items.findIndex(v=>v.id == e.id)
          if(index != -1)
            this.items.splice(index,1)
				});
			});
		},
	},
};
</script>

<style>

</style>