import {API,COS,AI} from '@/vue-plugins/api'
import Vue from '@/vue-plugins/base'
import Bus from '@/vue-plugins/bus'
const ThemeKeys = [
  'bg1',
  'bg2',
  'bg3',
  'text1',
  'text2',
  'text3',
  'subtext1',
  'subtext2',
  'subtext3',
  'primary',
  'secondary',
  'hover',
  'active',
  'editable',
  'hover-text',
  'disable',
  'label',
  'border',
  'success',
  'error',
  'disabled',
  'warning',
  'executing'
]
const state = {
  session:{
    concerned:[],
    modules:[]
  },
  remoteData:{},
  session_cache_keys:[],
  grouped_employees:[],
  my_projects:[],
  threads:[],
  uploading:[],
  users:[],
  deps:[],
  models:[],
  projects:[],
  employee:{},
  employees:[],
  accelerators:[],
  login_url:"",
  sms_cooldown:0,
  dark_mode:1,
  mobile:false,
  screenWidth:1024,
  windowWidth:1024,
  theme:"default",
  themes:[{
    key:'default',
    name:'默认风格',
    icon:'md-sunlight'
  },{
      key: 'tech',
        name: '深色科技',
      icon:'md-moonlight'
    }
    , {
      key: 'glass',
        name: '毛玻璃',
      icon: 'ios-water'
    }, {
      key: 'blue',
      name: '蓝色星球',
      icon: 'ios-water'
    }, {
      key: 'hstigger',
      name: 'TiggerHub',
      icon: 'ios-water'
    }, {
      key: 'pink',
      name: 'PinkBoy',
      icon: 'ios-water'
    }, ]
}

const getters = {
  models(state){
    return state.models
  },
  remoteData(state){
    return state.remoteData
  },
  employee(state){
    return state.employee
  },
  employees(state){
    return state.employees
  },
  projects(state){
    return state.projects
  },
  grouped_employees(state){
    return state.grouped_employees
  },
  session(state){
    return state.session
  },
  
  ent_session(state){
    return state.ent_session
  },
  users(state){
    return state.users
  },
  deps(state){
    return state.deps
  },
  isLogined(state){
    return state.session && state.session.id
  },
  sms_cooldown(state){
    return state.sms_cooldown
  },
  concerned(state){
    return state.session.concerned
  },
  "login-url"(state){
    
    return (state.login_url && state.login_url != "/") ? state.login_url : "/core/dashboard"
  },
  modules(state){
    return state.session.modules
  },
  accelerators(state){
    
    return state.accelerators
  },
  my_projects(state){
    return state.my_projects
  },
  //themes
  theme(state){
    return state.theme
  },
  themes(state){
    return state.themes
  },
  themeColors(state){
    return ThemeKeys
  },
  wechatAppID(){
    return "wx215ebbf66af92517"
  },
  mobile(state){
    return screen.width < 700
  },
  windowWidth(state){
    return state.windowWidth
  },
  screenWidth(state){
    return state.screenWidth
  },
  threads(state){
    return state.threads
  }
}
let handleUsersTimer = null
let handleDepsTimer = null
let handleEmployeesTimer = null
let currentEmployeesRequest = null
const actions = {
  login({commit},{account,password,wechat}){
    return new Promise((resolve,reject)=>{
      return API.post('/sessions',{account,password,wechat}).then(res=>{
        commit('login',res.data.data)
        resolve()
      }).catch(reject)
    })
  },
  login_zzl({commit},{uid}){
    return new Promise((resolve,reject)=>{
      return API.post('/sessions?q=zzl-id',{uid}).then(res=>{
        commit('login',res.data.data)
        resolve()
      }).catch(reject)
    })
  },
  login_ent({commit}){
    return new Promise((resolve,reject)=>{
      return API.post('ent/sessions').then(res => {
        commit('login_ent',res.data.data)
        resolve(res.data.data)
      }).catch(reject)
    })
  },
  getUsers({
    commit
  }) {
      return new Promise((resolve, reject) => {
        if (handleUsersTimer){
          return resolve([])
        }
        handleUsersTimer = setTimeout(() => {
          API.get('/users?q=public',{timeout:100000}).then(res => {
            commit('SaveUsers', res.data.data)
            resolve(res.data.data)
          }).catch(e => {
            reject(e)
          }).finally(()=>{
            handleUsersTimer = null
          })
        },500)
      })
  },
  getModels({commit}){
    return new Promise((resolve,reject)=>{
      API.get("/models").then(res=>{
        commit("SaveModels",res.data.data)
      }).catch(reject)
    })
  },
  getDeps({commit}){
    return new Promise((resolve,reject)=>{
      if (handleDepsTimer){
        return resolve([])
      }
      handleDepsTimer = setTimeout(()=>{
        API.get("/deps?q=all").then(res => {
          commit('SaveDeps', res.data.data)
           handleDepsTimer = null
          resolve(res.data.data)
        }).catch(reject)
      },800)
    })
  },
  getGroupedEmployees({state,commit},forced){
    return new Promise((resolve,reject)=>{
      if(!forced && state.grouped_employees && state.grouped_employees.length > 0)
        resolve()
      if (currentEmployeesRequest){
        return currentEmployeesRequest.then(resolve).catch(reject)
      }
      handleEmployeesTimer = setTimeout(() => {
        currentEmployeesRequest =  API.get("/ent/employees?q=grouped",{timeout:100000})
          .then(res => {
            setTimeout(()=>{
              commit('SaveGroupedEmployees', res.data.data)
              currentEmployeesRequest = null
              resolve(res.data.data)
            },10000)
          })
          .catch(err=>{
            currentEmployeesRequest = null
            reject(err)
          })
      },300)
    })
  },
  getProjects({commit}){
    return new Promise((resolve,reject)=>{
      API.get('/projects?q=simple').then(res => {
        commit('SaveProjects', res.data.data)
        resolve(res.data.data)
      }).catch(e => {
        reject(e)
      })
    })
  },
  getMyProjects({commit}){
    return new Promise((resolve,reject)=>{
      API.get('/projects?q=panel').then(res=>{
        commit('SaveMyProjects',res.data.data)
        resolve(res.data.data)
      }).catch(e=>{
        reject(e)
      })
    })
  },
  bind_wechat({commit},code){
    return API.patch("/sessions/self?q=wechat",{code}).then(res=>{
      commit('update_session',{wechat:true})
    })
  },
  login_wechat({
    commit,
    state
  }, code) {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem('BINDING_ID')
      return API.post('/sessions?q=wechat-scan', {
        token,
        code
      }).then(res => {
        let session = res.data.data
        let binding = localStorage.getItem('BINDING_ID')
        if(binding){
          localStorage.setItem('binding-success',true)
          localStorage.removeItem('BINDING_ID')
        }

        if(session.wechat && !session.id){
          resolve(session.wechat)
        }
        
        commit('login', session)
        resolve()
      }).catch(reject)
    })
  },
  switch_ent({commit},ent_id){
    return new Promise((resolve)=>{
      return entAPI.get('/sessions?').then(res=>{
        commit('save_ent',res.data.data)
        resolve()
      })
    })
  },
  login_ding({
      commit
    }, code) {
    return new Promise((resolve,reject)=>{
      return API.post('/sessions?q=ding-scan', {
        code
      }).then(res=>{
        commit('login',res.data.data)
        resolve()
      }).catch(reject)
    })
  },
  login_sms({commit},{
    account,code
  }){
 return new Promise((resolve, reject) => {
   return API.post('/sessions?q=sms', {
     account,
     code
   }).then(res => {
     commit('login', res.data.data)
     resolve()
   }).catch(reject)
 })
  },
  logout({commit}){
    return new Promise((resolve,reject)=>{
      return API.delete('/sessions/self').then(()=>{
        commit('logout')
        resolve()
      }).catch(reject)
    })
  },
  whoami({commit}){
    return new Promise((resolve)=>{
      
       let session = localStorage.getItem('session')
       if (session) {
         session = JSON.parse(session)
         commit('login', session)

       } else {
         commit('save_login_url', window.location.href)
         window.location.href = '/'

       }
       resolve()
    })
  },
  auth({commit}){
    return new Promise((resolve)=>{
      resolve(true)
      commit()
    }).catch(reject)
  },
  sendVCode(_,account){
    return new Promise((resolve)=>{
      return API.post('/sessions/vcode',{account}).then(resolve)
    })
  },
  switchConcerned({
      commit
    }, project_id) {
    return new Promise((resolve) => {
      commit('switch_concerned_group',project_id)
      return API.patch('/sessions/concerned-projects',state.concerned).then(()=>{
        return resolve()
      })
    })
  },
  patch({commit},{avatar}){
    return new Promise((resolve)=>{
      return API.patch("sessions/self?q=userinfo",{avatar}).then(()=>{
        commit('update_session',{avatar})
        resolve()
      })
    })
  },
  saveTheme({state}){
    return new Promise((resolve)=>{
      return API.patch('sessions/theme',state.session.theme).then(resolve)
    })
  },
  updateConfig({commit},{key,value}){
    return new Promise((resolve,reject)=>{
      return API.patch(`sessions/${key}`,value).then(()=>{
        commit('updateConfig',{key,value})
        resolve()
      }).catch((e)=>{
        reject(e)
      })
    })
  },
  saveAccelerators({commit},accs){
    return new Promise((resolve)=>{
      return API.patch('/sessions/accelerators',accs).then(()=>{
        commit("save_accelerators",accs)
        return resolve()
      })
    })
  },
  registerWithWechat({commit},wechat){
    return new Promise((resolve,reject)=>{
      return API.post('/sessions/register-wechat',{wechat}).then((res)=>{
        commit('login',res.data.data)
        resolve()
      }).catch(reject)
    })
  },
  toggleFocused({commit},id){
    return new Promise((resolve,reject)=>{
      return API.patch("/projects/"+id+"?q=focus").then(res=>{
        commit("toggleFocused",id)
        resolve()
      }).catch(reject)
    })
  },

  queryRemoteData({commit,state},url){
    if(state.remoteData[url])
      return
    else{
      return API.get(url).then(res=>{
        commit("SaveRemoteData",{url,data:res.data.data})
      })
    }
  },

  // file api
  upload({commit,state},files){
    return new Promise((resolve,reject)=>{
      COS.upload(files,{
        onPreload:fs=>commit("save_files",fs),
        onProgress:f=>commit("update_file",f),
        onSuccessFile:f=>commit("update_file",f),
        onSuccess:resolve,
        onFail:reject
      },{coskey:state.session.coskey})
    })
  }
}
let uploading = []
const mutations = {
  SaveModels(state,models){
    state.models = models
  },
  updateConfig(state,{key,value}){
    state.session[key] = value
  },
  SaveRemoteData(state,{url,data}){
    Vue.set(state.remoteData,url,data)
  },
  SaveGroupedEmployees(state,grouped_employees = []){
    let employees = []
    if(!Array.isArray(grouped_employees)){
      throw new Error('SaveGroupedEmployees:grouped_employees 返回错误')
    }
    grouped_employees.forEach(v=>{
      if(v.users){
        v.users.forEach(u=>{
          if(employees.find(e=>e.id == u.id) == null)
            employees.push(u)
        })
      }
    })
    state.employees = employees
    state.grouped_employees = grouped_employees
  },
  SaveDeps(state,deps){
    state.deps = deps
  },
  save_files(state,files){
    uploading = [...files]
    state.uploading = files.map(v=>{
      return {
        name:v.name,
        percent:v.percent,
        loading:v.loading,
        url:v.url,
        ext:v.ext
      }
    })
  },
  cancel_file(state,v){
     let index = state.uploading.findIndex(t => t.name == v.name)
     if (index != -1){
      uploading[index].cancel()
      uploading.splice(index,1)
      state.uploading.splice(index,1)
     }
  },
  update_file(state,v){
    let index = state.uploading.findIndex(t=>t.name == v.name)
    if(index != -1)
      state.uploading.splice(index, Object.assign({}, state.uploading[index], {
        name: v.name,
          percent: v.percent,
          loading: v.loading,
          url: v.url,
          ext: v.ext
      }))
  },
  SaveProjects(state,projects){
    state.projects = projects
  },SaveMyProjects(state,projects){
    state.my_projects = projects
  },
  toggleFocused(state,id){
    let index = state.session.focused.findIndex(v=>v == id)
    if(index != -1)
      state.session.focused.splice(index,1)
    else
      state.session.focused.push(id)
  },
  login_ent(state,employee){
    state.employee = employee
  },
  login(state,session){
    // if exist an pre-saved page url, route to it after login finished
    if(!state.login_url){
      let lastLoginUrl = localStorage.getItem('last-route-path')

      if(!lastLoginUrl){
        lastLoginUrl = localStorage.getItem('login_url'+session.id)
      }
      state.login_url = lastLoginUrl
      localStorage.removeItem('login_url'+session.id)
      localStorage.removeItem('last-route-path')
    }
    
    state.accelerators = session.accelerators
    let theme = localStorage.getItem('theme_'+session.id)
    if(theme)
      mutations.change_theme(state,theme)
   
    localStorage.setItem('session',JSON.stringify(session))
    API.SetAuthorization(session.token)
    AI.SetAuthorization(session.token)
    state.session = session || {}
     mutations.load_threads(state)
    Bus.$emit("login")
  },
  update_session(state,sessionData){
    Object.assign(state.session,sessionData)
    localStorage.setItem('session',JSON.stringify(state.session))
  },
  logout(state){
    localStorage.removeItem('session')
    state.session = {}
  },
  switch_concerned_group(state,id){
    let index = state.session.concerned.findIndex(v=>v.id == id)
    if(index != -1)
      state.session.concerned.splice(index,1)
    else
      state.session.concerned.splice(0,1,id)
    localStorage.setItem('session', JSON.stringify(state.session))
  },
  save_login_url(state,url){
    state.login_url = url
    localStorage.setItem('login_url'+state.session.id,url)
  },
  save_accelerators(state,accs){
    state.session.accelerators = accs
    state.accelerators = accs
    localStorage.setItem('session', JSON.stringify(state.session))
  },
  save_threads(state){
    localStorage.setItem('threads_'+state.session.id, JSON.stringify(state.threads))
  },
  load_threads(state){
    let threadsData = localStorage.getItem('threads_' + state.session.id)
    if(threadsData){
      let threads = JSON.parse(threadsData)
      state.threads = threads.filter(t=>!t.type !== 'module' || state.session.modules.find(v=>t.url.indexOf(v.base_url || v.url)))
    }
  },
  change_theme(state,theme){
    state.session.theme = state.theme = theme
    document.getElementsByTagName('body')[0].className = "m-theme m-"+theme
    let themeObject = state.themes.find(v=>v.key == theme)
    setTimeout(()=>{
      
    ThemeKeys.forEach(key => {
      document.documentElement.style.setProperty('--'+key, `var(--${theme}-${key})`)
    })
    },500)
    localStorage.setItem('theme_'+state.session.id,theme)
    localStorage.setItem('session', JSON.stringify(state.session))
  },
  SaveUsers: (state, data) => {
    state.users = data
  },
  resize:(state,e)=>{
    state.screenWidth = screen.width
    state.windowWidth = window.innerWidth
    state.mobile = window.innerWidth < 700
  },
  push_project: (state, {
    id,
    name
  }) => {
    mutations.push(state, {
      icon: "Building-",
      type: "project",
      name,
      size: 24,
      path: `/core/projects/${id}/create`,
      base_url: `/core/projects/${id}`
    })
  },
  push_flow_instance: (state, {
    id,
    name,
  }) => {
    mutations.push(state, {
      icon: "lianjieliu",
      type: "flow_instance",
      name,
      size: 24,
      path: `/core/flows/${id}/instance`,
      base_url:`/core/flows/${id}/instance`,
    })
  },
  push_flow_create: (state, {
    id,
    name,
  }) => {
    mutations.push(state, {
      icon: "fenzuguanli",
      type: "flow",
      name,
      size: 24,
      path: `/core/flows/${id}/create`,
      base_url: `/core/flows/${id}`
    })
  },
  push_flow: (state, {
    id,
    name,
    flow
  }) => {
    mutations.push(state, {
      icon: "fenzuguanli",
      type: "flow",
      name,
      size: 24,
      path: `/core/flows/${flow}/info`,
      base_url: `/core/flows/${flow}`
    })
  },
  
  edit_task: (state, {
    id,
    name
  }) => {
    mutations.push(state, {
      icon: "fenzuguanli",
      type: "project",
      name,
      size: 24,
      path: `/core/itasks/${id}/editor`,
      base_url: `/core/itasks/${id}`
    })
  },
  push_task:(state,{id,name})=>{
     mutations.push(state, {
       icon: "fenzuguanli",
       type: "project",
       name,
       size: 24,
       path: `/core/itasks/${id}/dashboard`,
       base_url: `/core/itasks/${id}`
     })
  },
  push_contract:(state,{id,name})=>{
    mutations.push(state, {
      icon: "contract",
      type: "contract",
      name,
      size: 24,
      path: `/core/contracts/${id}/dashboard`,
      base_url: `/core/contracts/${id}`
    })
 },
  push_project_cm:(state,{id,name})=>{
    mutations.push(state,{
      icon:"Building-",
      type:"project",
      name,
      size:24,
      path:`/core/projects/${id}/cm`,
      base_url:`/core/projects/${id}`
    })
  },
  push_group: (state, {
    id,
    name
  }) => {
    mutations.push(state, {
      icon: "organization",
      type: "dep",
      name,
      size: 24,
      path: `/core/groups/${id}/dashboard`,
      base_url: `/core/groups/${id}`
    })
  },
  removeByPath:(state,path)=>{
    let index = state.threads.findIndex(v=>path && path.includes(v.base_url))
    if(index != -1)
      mutations.remove(state,index)
  },
  remove:(state,index)=>{
    state.threads.splice(index,1)
    mutations.save_threads(state)
  },
  remove_threads:(state,threads)=>{
    state.threads = state.threads.filter(t=>threads.includes(t) == false)
    mutations.save_threads(state)
  },
  remove_all:(state)=>{
    state.threads = []
    mutations.save_threads(state)
  },
  update:(state,path)=>{
    let index = state.threads.findIndex(v=>v.base_url && path.indexOf(v.base_url) == 0)
    if(index !== -1){
      state.threads.splice(index,1,Object.assign({},state.threads[index],{path}))
      mutations.save_threads(state)
    }
  },
  // name -
  // icon - 
  // base_url - 
  // url -
  push:(state,item)=>{
    if(!item.path)
      item.path = item.url
    let index = state.threads.findIndex(v=>v.base_url?(item.path.indexOf(v.base_url) == 0):(item.path == v.url))
    if(index != -1)
      state.threads.splice(index,1,item)
    else
      state.threads.push(item)
    mutations.save_threads(state)
  },
  pop:(state,index)=>{
    state.threads.splice(index,1)
    mutations.save_threads(state)
  },
  
  save_cache(state,{key,value}){
    if(false == state.sessin_cache_keys.includes(key))
      state.session_cache_keys.push(key)
    localStorage.setItem(key,value)
  },
  clear_cache(state){
    sessin_cache_keys.forEach(key=>{
      localStorage.removeItem(key)
    })
  }

}

export default {
  namespaced:true,
  state,
  getters,
  mutations,
  actions
}