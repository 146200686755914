<template>
    <div class="container">
        <div>
       
       <Input size="large" ref="taskContent" type="textarea" :rows="4" v-model="task.content" placeholder="输入计划名称" @on-enter="handleEnter" />
       
       <div style="padding:10px;border:1px solid var(--border);margin-top:10px;border-radius:5px">
         <div class="flex-wrap flex-left">截至日期 <BaseDate style="margin-left:10px;width:140px;margin-right:20px" v-model="task.deadline" />
       短信提醒<i-switch v-model="task.sms" style="margin-left:10px"></i-switch><span style="color:var(--primary);margin-left:20px;">系统会在截止日期前一天发送短信</span>
       </div>
        <div class="flex-wrap" style="margin-top:10px">
         归属部门
         <Select placeholder="选择部门"  v-model="task.group_id" style="width:140px;margin-left:10px;margin-right:20px;" clearable><BaseIcon  slot="prefix" icon="organization" />
         <template v-for="g in groups">
           
         <Option :key="g.id"  :value="g.id">{{g.name}}</Option>
         </template>
         </Select>
         归属项目
          <Select placeholder="选择项目" v-model="task.project_id" style="width:240px;margin-left:10px" clearable><BaseIcon  slot="prefix" icon="pm2" />
         </Select>
       </div>
        <div class="flex-wrap" style="margin-top:10px">
         开启协同 <i-switch v-model="task.public"  style="margin-left:10px"></i-switch> 
         <span style="color:var(--primary);margin-left:20px;">其他成员可见</span>
         </div>
         </div>
       
       <div class="flex-wrap flex-right" style="margin-top:10px">
         <Button type="primary" @click="saveTask">提交 Ctrl+Enter</Button> <Button style="margin-left:10px" @click="editTask=false">取消</Button>
       </div>
     </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
export default{
    data(){
        return {
            formData:{}
        }
    }, 
    props:{
        value:{
            type:Object,
            default:()=>{}
        }
    },
    computed:{
        ...mapGetters('session',['groups','session']),
        task:{
            get(){
                return this.formData
            },
            set(v){
                this.$emit('input',{...this.formData})
            }
        },
        groups(){
        if(Array.isArray(this.session.groups)){
          return this.session.groups.filter(v=>v.namespaced)
        }else
          return []
      },
    }
}
</script>
<style lang="less">

</style>