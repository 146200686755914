<template>
  <iframe src="https://www.720yun.com/vr/847jOdewtv8" frameborder="no" class="hs-container" ></iframe>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>