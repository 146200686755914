<template>
  <div>
    <BaseIcon icon="md-arrow-back" size="30" @click="RouteBack()" /> {{$route.params.id}}
  </div>
</template>

<script>
  export default {
    routerOption:{
      as_object:true
    }
  }
</script>

<style lang="scss" scoped>

</style>