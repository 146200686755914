<template>
    <div>
        <div v-if="editable">
            <Input 
        
        :value="value"
        @on-change="$emit('change',$event)"
        @input="$emit('input',$event)"
        :clearable="option.clearable"
        placeholder="请输入"
    />
        </div>
        <div v-else style="display:inline-block;width:100%;">
        <div v-if="value == undefined" style="color:var(--subtext2)" >
            未填写
        </div>
        <div v-else style="font-weight:bold;color:var(--primary);font-size:16px;">
            {{ value }}
        </div>
    </div>
    
    </div>
   
</template>

<script>
export default {
    props:{
        value:{
            type:String
        },
        option:{
            type:Object,
            default:()=>({})
        },
        editable:{
            type:Boolean,
            default:true
        }
    }
}
</script>

<style>

</style>