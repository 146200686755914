<template>
  <DatePicker format="yyyy/MM/dd" v-model="formattedValue" transfer :placeholder="placeholder || '请选择'" :size="size" :disabled="disabled" @on-change="handleChangeDate" />
</template>

<script>
 import moment from 'moment'
 moment.locale("zh-cn")
  export default {
    name:'BaseDate',
    icon:'xuanzeriqi',
    props:['value','config','disabled','placeholder','size'],
    computed:{
      formattedValue:{
        get(){
          return this.value?(moment(this.value).format("YYYY-MM-DD")):''
        },
        set(v){
          this.$emit('input',v?moment(new Date(v)).format("YYYY-MM-DD HH:mm:ss"):null)
        }
      }
    },
    methods:{
      handleChangeDate(e){
        this.$emit('on-change',e?moment(new Date(e)).format("YYYY-MM-DD HH:mm:ss"):null)
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>