export default {
    data(){
      return {
        page:1,
        pagesize:20,
        sort_key:'created_at',
        sort_order:'desc',
      }
    },
    methods:{
      generatePagedQuery(){
        return `page=${this.page}&pagesize=${this.pagesize}&sort=${this.sort_key},${this.sort_order}`
      }
    }
  }