<style lang="less">
.ugo-form{
  .ivu-form-item{
    width:100%;
  }
  
  .ivu-form-item-label{
    color:var(--subtext2);
  }

  .ivu-form-item-content{
  }
}

.form-item-mandatory{
  .ivu-form-item-label{
    color:var(--error) !important;
    font-weight: bold;
  }
}
</style>

<template>
<Layout style="overflow:hidden">
  <div style="height:44px;padding:0 !important;position:relative !important;border-bottom:1px solid var(--border);">
    
    <Toolbar 
      :items="tools"
      :height="40"
      v-model="toolValue"
      :gap="3"
      @on-event="handleToolEvent"
    />
  </div>

  <Layout style="height:calc(100% - 134px);max-width:100%;">
    <Sider style="width:200px;background:#fff;border-right:1px solid  var(--border);" v-if="toolValue.add">
      <div class="l-panel-title"><BaseIcon icon="md-list" style="margin-right:5px;" /> 内容</div>
      <div style="height:180px;overflow:hidden;position:relative;overflow-y:auto;">
      <BaseTree 
        ref="tree"
        :selected="selected" manu 
        @event="handleTreeEvent"
        subname="fields"
        :data="computedForm"  />
    </div>
      <div style="position:absolute;bottom:24px;top:200px;left:0;right:0;">
        <div class="l-panel-title"><BaseIcon icon="md-list" style="margin-right:5px;" /> 组件</div>
        <div class="flex-wrap" style="height:100%;overflow-y:auto;align-items:flex-start;">
          <draggable :list="controls" :group="{ name: 'control', pull: 'clone', put: false }" :clone="handleClone" style="width:100%">
            <transition-group type="transition" name="flip-list">
              <div class="u-control-item" :key="c.control" v-for="c in controls" :data-control="c.control">
                <Icon :custom="`ugoicon ugi${c.icon || 'input'}`" size="18" style="margin-right:5px" /> {{c.name || c.control}}
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
    </Sider>
    <Content style="background:#ddd;overflow-y:auto;height:calc(100%);max-width:100%;overflow:auto;">
      <div style="width:900px;margin:60px auto;background:#fff;position:relative;">
        <template v-if="form.html">
          <a style='font-size:14px;color:#3af;position:absolute;top:-25px;' @click="view=(view=='html'?'form':'html')">切换至 {{view=='html'?'原始表单':'HTML表单'}}</a>
        </template>
         <div class="" style="overflow:hidden;height:auto;margin:20px;background:none !important;padding:40px 0;">
      <Form 
        ref = 'form'
        :model="defaultValues"
        :label-width="form?.config?.labelWidth || 100" 
        class="ugo-form" 
        :label-position="form.config.labelPosition || 'left'"
      >
        
        <template v-if="view == 'html'">
          
          <BaseForm :form="{layout:form.html,def:formDef}"
          
          v-model="formData" />
        </template>
        <template v-else>
         <draggable 
          v-model="form.fields"
          @input="save"
          @end="handleDragEnd"
          @add="onChoose"
          group="control"
          style="width:100%;min-height:150px;background:#eee;border:1px dashed #39f;padding:5px 0;display: block;"
          :animation="500"
          filter=".drag-tip"
         >
          
          <!-- <BaseEmpty v-if="!form.fields || form.fields.length == 0" msg="拖拽组件到此处" /> -->
         
          <template v-for="(p) in form.fields">
            <div class="form-item flex-wrap flex-between" :class="{
              'form-item-selected':selected == p,
            }"  :key="p.id" 
            @click.stop="select(p)" style="border:1px solid var(--primary);margin:12px;position:relative;overflow:hidden;padding:5px;">
                <div class="l-mask" v-if="!isContainer(p.control)"></div>
                <div class="l-remove-btn" @click.stop="handleRemove(p)"><Icon type="md-close" /></div>
                <div class="l-remove-btn" style="right:20px;" @click.stop="handleCopy(p)"><Icon type="md-copy" /></div>
                <keep-alive>
                  <component 
                    :is="p.control" 
                    :required="p.required?true:false"
                    :label="p.name"
                    :id="p.id"
                    :fkey="p.key" 
                    :selected="selected"
                    :fields="p.fields"
                    :config="p.config" 
                    :value="p?.config?.defaultValue"
                    :editable="true"
                    :design="true" 
                    @select="handleSelect"
                    @change="handleChangeFields(p,$event)"
                  
                  />
                </keep-alive>
            </div>
           
          </template>
          
          <div v-if="form.fields && form.fields.length != 0" class="drag-tip">拖拽组件到此处</div>
         
          
         </draggable>
         
         </template>
      </Form>
    </div>
      </div>
    </Content>
    <Sider :width="260" style="background:#fff;border-left:1px solid var(--border);"  v-if="toolValue.prop">
      <div class="l-panel-title"><BaseIcon icon="md-list" style="margin-right:5px;" /> 属性</div>
      <BaseEmpty v-if="!selected || selected.name==undefined" msg="请选择组件" />
      <template v-else>
        <BaseProperty 
          v-model="selected"
          :attrs="selected != form ?fieldConfig:formConfig"
          :disbaled="{control:true}"
          @on-change="handleChangeAttr"
          @input="handleChange" />
        <BaseProperty 
          v-if="selected"
          v-model="selected.config"
          :attrs="getAttrConfig(selected)"
          @input="handleChange" 
        />
      </template>
      <div class="l-panel-title"><BaseIcon icon="md-list" style="margin-right:5px;" /> AI编辑</div>
      <div style="height:100px;padding:5px">
        <Input type="textarea" size="small" v-model="aiText2" style="width:100%;" :rows="4" placeholder="请输入您的需求" />
        <div style="color:var(--error);margin-top:5px;padding:0 5px;" v-if="ai_msg">{{ ai_msg }}</div>
        <div class="flex-wrap flex-right" style="margin-top:5px;">
          <Button type="primary" size="small"  @click="AIModify">试一试</Button>
        </div>
        
      </div>
    </Sider>
  </Layout>
  <div class="flex-wrap" style="align-items:stretch;height:24px;border-top:1px solid var(--border);background:#eee;padding:0 !important;font-size:12px;z-index:100;">
      <div class="flex-wrap flex-center" style="padding:0 10px;border-right:1px solid var(--border);">编辑中 {{form.id}}</div>
      <div class="flex-wrap flex-center" style="padding:0 10px;border-right:1px solid var(--border);width:calc(100% - 160px);"> 
        {{selecting_to?'请选择下一节点':''}}</div>
      
    </div>

    <Modal v-model="openFolder" title="插入现有表单" footer-hide>
      <div>
        <div class="flex-wrap">
          <Input size="small" />
        </div>
        <div  style="flex-wrap:wrap;height:400px;overflow-y:auto;margin-top:10px;padding:5px;border:1px solid var(--border);">
           <template v-for="f in forms">
            <div class="flex-wrap flex-between" :key="f.id">
              <div style="color:var(--primary);cursor:pointer;" @click="insertForm(f)">{{ f.name }}</div>
              <div>{{ moment(f.created_at).format("LL") }}</div>
            </div>
            </template> 
        </div>
      </div>
    </Modal>

    <BaseLoadingModal :value="ai_executings" title="请稍等(预计1-2分钟),AI正在处理中...">
     
    </BaseLoadingModal>

    <Modal v-model="showImporter" title="导入json" footer-hide :width="800">
      <Input type="textarea" v-model="importJSON" :rows="10" placeholder="请将代码粘贴至此处"/>
      <div class="flex-wrap flex-right" style="margin-top:10px">
        <Button type="primary" @click="parseJSON">导入</Button>
      </div>
    </Modal>

    <Modal v-model="showAI" title="AI创建" footer-hide :width="800">
      <Input type="textarea" v-model="aiText" :rows="3" placeholder="请输入您的需求，尽可能详细，比如：帮我设计一个监理用表:瓶装液化气安全检查" />
      <Button style="margin:10px 0;" type="primary" @click="CreateByAI" :loading="ai_executings">{{ai_executings?'正在努力生成,请耐心等待20-30s...':'试一试 - AI创建'}}</Button>
      <Input type="textarea" ref="ai_input" :disabled="ai_executings" v-model="importJSON" :rows="10" placeholder="请将代码粘贴至此处"/>
      <div class="flex-wrap flex-right" style="margin-top:10px">
        <Button type="primary" @click="parseJSON" :disabled="ai_executings">导入</Button>
      </div>
    </Modal>


    <Modal v-model="showExporter" title="导出json" footer-hide :width="800">
      <div style="width:100%;max-height:400px;overflow-y:auto;">
      <BaseMarkdownEditor :value="`\`\`\`json\n${exportJSON}`" preview />
    </div>
      <div class="flex-wrap flex-right" style="margin-top:10px">
        <Button type="primary" @click="copyToClipboard">复制到剪切板</Button>
      </div>
    </Modal>

    <Modal v-model="showFomatter" title="一键格式化工具" footer-hide>
      <Button icon="md-color-wand" @click="callFormatter('defaultReject')" type="primary">将所有‘不符合’设置为默认值 <BaseIcon icon="ios-arrow-forward" style="margin-left:5px;" /> </Button>
    </Modal> 
  </Layout>
</template>

<script>
import {cloneDeep} from 'lodash'
import draggable from 'vuedraggable'
import Toolbar from '@/components/app/toolbar'
import UgoXForm from './view.vue'
import axios from 'axios'

let idGlobal =1
  export default {
    props:['value','public'],
    data(){
      return {
        selecting_to:null,
        view:'form',
        aiText:"",
        ai_msg:"",
        selected:{},
        showFomatter:false,
        aiText2:'',
        ai_executings:false,
        showImporter:false,
        showExporter:false,
        toolValue:{
          add:true,
          prop:true,
        },
        showAI:false,
        importJSON:"",
        exportJSON:"",
        fields:[],
        form:{},
        forms:[],
        drag:false,
        openFolder:false,
        defaultValues:{},
        
         formConfig:[{
            label:"表单名称",
            key:"formName",
            control:"Textarea"
          },{
            label:"表单编码",
            key:"formCode",
            control:"Text"
          },{
            label:"标题",
            key:"name",
            control:"Textarea"
          },]
      }
    },
    computed:{
      fieldConfig(){
        let props = [{
          label:"标题名称",
          key:"name",
          control:"Textarea",
          editable:true
        },
         {
          label:"数据名称",
           key:"key",
           control:"Text",
           disabled:true,
           editable:false,
           
           option:{
            copyable:true,
             placeholder:"默认为名称"
           }
         },
        {
          label:"组件",
          key:"control",
          control:"Select",
          option:{
            form:true,
            options:(this.$store.getters['forms/zzl_controls'] || []).map(v=>{
              return {
                key:v.control,
                value:v.control,
                label:v.name,
                icon:v.icon
              }
            })
          }
        }]
        if(!this.isContainer(this.selected.control)){
          props.push({
          label:"必填",
          key:"required",
          editbale:true,
          control:"Check"
          })
        }
        return props
      },
      computedForm(){
        return [this.form]
      },
      nodes(){
        return [this.form]
      },
       tools() {
        if(this.public){
          return [{}]
        }
        let staticTools = [{
            key: "new",
            name: "新建表单",
            icon: 'md-document',
            type:"text-button"
          },{
            key: "import",
            name: "导入",
            icon: 'md-code',
            type:"text-button"
          },{
            key: "ai",
            name: "AI设计",
            icon: 'richangbaoji01',
            type:"text-button"
          },{
            key: "export",
            name: "导出",
            icon: 'md-swap',
            type:"text-button"
          },{
            key: "download",
            name: "下载",
            icon: 'md-download',
            type:"text-button"
          },{
            key: "refresh",
            name: "重新载入",
            icon: 'md-refresh',
            type:"text-button"
          },{type:'divider'},{
            key: "formatter",
            name: "快捷工具",
            icon: 'md-color-wand',
            type:"text-button"
          }
]
        let itemTools = [
        //   {
        //   key:"extract",
        //   name:"解压",
        //   icon:"md-open",
        //   type:'icon-button'
        // }
      ]
        let tools = [...staticTools]
        if(this.selected){
          tools.push({type:'divider'})
          tools = tools.concat(itemTools)
        }
        if(this.$store.getters['session/session']?.id){
            tools.push({
                key:'save',
                name:"保存至平台",
                icon:"save",
                type:'text-button'
            })
        }
        return tools
       },
       controls(){
        return this.$store.getters['forms/zzl_controls']
      },
      formDef(){
        let o = {}
        if(this.form.fields){
          
        this.form.fields.forEach(v=>{
          if(v.key || v.name)
            o[v.key || v.name] = {
              label:v.name,
              control:'input',
              option:{}
            }
        })
        }
        return o
      }
    },
    mounted(){
      if(this.value){
        this.form = cloneDeep(this.value)
      }else{
        this.initEmptyForm()
        
      }
      this.$nextTick(()=>{
        this.selected = this.form
        if(this.$refs.tree)
          this.$refs.tree.select(this.form)
      })
    
    },
    watch:{
      value:{
        handler(v,old){
          if(this.form.id !== this.value.id){
            this.form =cloneDeep(this.value)
            this.selected = this.form
          }
         
          if(!this.selected){
            this.selected = this.form
          }  
           
          this.$nextTick(()=>{
          let r = {}
          this.traversalFeilds(v.fields,f=>{
            if(f.config && f.config.defaultValue !== undefined){
              r[f.key] = f.config.defaultValue
            }
          })
          this.defaultValues = r
        })
          
        },
        immediate:true,
        deeply:true
      }
    },
    methods:{
      initEmptyForm(){
        this.form = {
          id:'create',
          name:"新建表单",
          type:"临时表单",
          config:{
             labelPosition:'top',
              labelWidth:400
          },
          fields:[]
        }
      },
      
      transform(form){
        let fID = this.form.id
        const typeMap = {
          'input':'UgoInput',
          'date':'UgoDate',
          'textarea':'UgoTextarea',
          'number':'UgoNumber',
          'radio':'UgoRadio',
          'checkbox':'UgoCheck',
           'datetime':'UgoDatetime',
          'datetimeRange':'UgoDatetimeRange',
          'signature':'UgoSignature',
        }

        const DefaultValueTranslators = {
            'checkbox':(arr)=>arr.split(','),
            'datetimeRange':(arr)=>arr.split('-').map(v=>trim(v))
        }

        const formatURL = (url)=>{
          if(url){
            if(!url.includes('http')){
              url = 'https://api.inbgz.com/redirect'+url
            }
            
            if(url.startsWith('http://zzlatm.gicp.net:10000')){
              url = url.replace('http://zzlatm.gicp.net:10000','https://api.inbgz.com/redirect')
            }
          }
          return url
        } 
        return {
          id:form.fID || fID || 'create',
          formName:form.formName || this.form.formName || form.title,
          formCode:form.formCode || this.form.formCode,
          name:form.title || form.formName,
          config:{
             labelPosition:'top',
              labelWidth:400
          },
          fields:(form.content || []).map(v=>{
            let samplePicture =(typeof v.samplePicture=="string")?v.samplePicture.split(',').filter(v=>v).map(formatURL):[]
            let config = {
              attachment:v.attachment,
              needAttachment:v.needAttachment,
              samplePicture,
            }
            return {
              id:this.createID(),
              name:v.category,
              key:v.name,
              control:'UgoSection',
              config,
              fields:(v.items || []).map(item=>{
                let control = typeMap[item.formType]
                if(!control){
                  throw `${item.title}.formType=${item.formType}未定义`
                }
                let defaultValue = item.defaultValue
                let samplePicture =(typeof item.samplePicture=="string")?item.samplePicture.split(',').filter(v=>v).map(formatURL):[]
                let config = {
                  placeholder:item.placeholder,
                  mandatory:item.mandatory,
                  attachment:item.attachment,
                  samplePicture
                }
                if(item.formType == 'checkbox'){
                  config.simple = true
                  defaultValue = item.options.map(v=>v.name)
                  config.options = []
                  if(item.options){
                    config.options = item.options.map(v=>v.option)
                  }
                }else if(item.formType == 'radio'){
                  config.simple = true
                  let defaultChecked = item.options.find(o=>o.checked)
                  if(defaultChecked)
                    defaultValue = defaultChecked.option
                    config.options = []
                  if(item.options){
                    config.options = item.options.map(v=>v.option)
                  }
                }else{
                  if(defaultValue !== undefined && typeof defaulValue== 'string'){
                    let translator = DefaultValueTranslators[item.formType]
                    if(translator)
                        defaultValue = translator(defaultValue)
                  }
                }
                if(defaultValue !== null)
                    config.defaultValue = defaultValue
                
                return {
                  id:this.createID(),
                  key:item.name,
                  control,
                  name:item.title,
                  required:item.verify?true:false,
                  
                  config,
                  
                }
              })
            }
          })
        }
      },
      moveAITextareaDown(){
        if(this.atd_timer)
            return
        this.atd_timer = setTimeout(()=>{
            if(this.$refs.ai_input){
                let inputElement = this.$refs.ai_input.$el.querySelector('textarea')
                this.$nextTick(()=>{
                    inputElement.scrollTop = inputElement.scrollHeight;
                })
            }
            delete this.atd_timer
        },500)
      },
      AIModify(){
        if(!this.aiText2 || !this.aiText2.trim()){
          this.Error("请输入需求后再提交")
          return
        }
        this.ai_executings = true
        this.$api.post('ai/parse/form-editor',{
          content:JSON.stringify(this.form)+'\n以下是用户的需求:'+this.aiText2
        }).then(res=>{
          let resultJSON = res.data.data
           
          try{
            let result = JSON.parse(resultJSON)
            {
              if(result.error){
                this.Error(result.error)
                this.ai_msg =result.error
                this.ai_executings = false
                return
              }
              this.ai_msg = result.message
              let  form = result.form
              this.form = form
              this.ai_executings = false
              this.Success('修改成功')
            }
         
          }catch(e){
            this.ai_executings = false
            this.Error("生成失败,请重试")
          }
        }).catch(e=>{
          this.Error("网络错误:"+e)
          this.ai_executings = false
        })
      },
      CreateByAI(){
        this.ai_executings = true
        fetch(this.$api.defaults.baseURL+'/ai/parse/zzl-form-design', {
            method:"POST",
            body:JSON.stringify({
                "content":this.aiText,
                "stream":true
            }),headers:{
                ...this.$api.defaults.headers,
                'Content-Type':'application/json'
            }}).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            var that = this
            const reader = response.body.getReader();  // 获取流读取器
            const decoder = new TextDecoder();  // 创建解码器
            let result = '';
            const ReadStream = ()=>{
                reader.read().then(({done,value})=>{
                    if(done){
                        this.ai_executings = false
                        this.aiText = ""
                        return
                    }
                    const decodedText = decoder.decode(value, { stream: true });
                    result += decodedText
                    this.importJSON = result
                    this.moveAITextareaDown()
                    ReadStream()
                })
                
            }
            

           ReadStream()
        })
        .catch(e => {
            this.Error(e);
            this.ai_executings = false;
        });
        },
      untrans(e){
        const typeMap = {
          'UgoInput':'input',
          'UgoDate':'date',
          'UgoTextarea':'textarea',
          'UgoNumber':'number',
          'UgoRadio':'radio',
          'UgoCheck':'checkbox',
          'UgoDatetime':'datetime',
          'UgoDatetimeRange':'datetimeRange',
          'UgoSignature':'signature',
        }
        const DefaultValueTranslators = {
            'checkbox':(arr)=>arr.join(','),
            'datetimeRange':(arr)=>arr.join(' - ')
        }
        
        let form = this.form
        
        let res = {
          title:form.name,
          content:this.form.fields.filter(v=>v.control == 'UgoSection').map((v,i)=>{
            if(!v.fields || v.fields.length == 0){
                throw `注意:${v.name}不允许为空，请至少拖入一个组件`
            }
            let props = {}
            if(Array.isArray(v.config.samplePicture) && v.config.samplePicture.length > 0){
                props.samplePicture = v.config.samplePicture.join(',')
            }
            if(v.key && v.key.startsWith('field-') == false)
              props.name = v.key
            return {
              category:v.name,
              ...props,
              attachment:v.config.attachment,
              needAttachment:v.config.needAttachment,
              items:v.fields.map((t,j)=>{
                let formType = typeMap[t.control]
                if(!formType){
                  throw `注意 ${t.name}，不允许嵌套插入栏目`
                }

                let obj = {
                  title:t.name,
                  formType,
                  attachment:t.config.attachment,
                  mandatory:t.config.mandatory,
                }

                if(t.key && t.key.startsWith('field-') == false)
                  obj.name = t.key

                if(Array.isArray(t.config.samplePicture) && t.config.samplePicture.length > 0){
                    obj.samplePicture = t.config.samplePicture.join(',')
                }

               
                
                if(t.required){
                  obj.verify = t.required?'required':''
                }

                if(formType == 'radio'){
                    obj.options = t.config.options.map(option=>{
                      if(typeof option == 'object')
                        option = option.name
                      return {
                        option,
                        checked:t.config.defaultValue == option
                      }
                    })
                }else if(formType == 'checkbox'){
                  obj.options = t.config.options.map(option=>{
                    if(typeof option == 'object')
                        option = option.name
                      return {
                        option,
                        checked:Array.isArray(t.config.defaultValue) && t.config.defaultValue.includes(option)
                      }
                    })
                }else{
                  let defaultValue = t.config.defaultValue
                  if(t.config.placeholder && t.config.placeholder !== ""){
                    obj.placeholder = t.config.placeholder
                  }
                  if(Array.isArray(defaultValue)){
                    if(defaultValue.length > 0){
                      let translator = DefaultValueTranslators[formType]
                      obj.defaultValue = translator?translator(defaultValue):defaultValue
                    }
                  }else if(defaultValue !== undefined && defaultValue !== null){
                    obj.defaultValue = defaultValue
                  }
                }

                return obj
              })
            }
          })
        }
        
        
        return JSON.stringify(res,null,2)
      },
      callFormatter(key){
        const def_formatters = {
          defaultReject:{
            name:'将所有‘不符合’设置为默认',
            desc:"接下来会自动将所有包含'不符合'项的单选组件设置为默认值（已存在默认值的不会改变）",
            func:(form)=>{
              this.traversalFeilds(this.form.fields,f=>{
                if(f.control == 'UgoRadio' && Array.isArray(f?.config.options) && f.config.options.find(o=>o == '不符合')){
                  f.config.defaultValue = '不符合'
                }
              })
              this.$emit('input',this.form)
            }
          }
        }
        let formatter = def_formatters[key]
        if(!formatter){
          this.Error(`${key}未定义`)
          return
        }
        this.Confirm(formatter.desc,()=>{
          formatter.func(this.form)
          this.Success('修改完成')
          this.showFomatter = false
        })
      },
      untransJSON(){
        this.showExporter = true
        try{
          this.exportJSON = this.untrans(this.form)
        }catch(e){
            this.showExporter = false
          this.Error("错误:"+e)
        }
      },
      downloadJSON(){
        try{
        let e = document.createElement('a')
        e.setAttribute('href','data:text/plain;charset=utf-8,'+encodeURIComponent(this.untrans(this.form)))
        e.setAttribute('download',`${this.form.name}.json`)
        e.style.display = 'none'
        document.body.appendChild(e)
        e.click()
        document.body.removeChild(e)
      }catch(e){
          this.Error("错误:"+e)
        }
      },
      parseJSON(){
        let json = this.importJSON
        
        if(!json || !json.trim()){
          this.Error("请输入内容再提交")
        }
        try{
          let form = this.transform(JSON.parse(json))
         
         
          this.showImporter = false
          this.showAI = false
          this.importJSON = ""
          this.form = form
          this.$emit('input',this.form)
        }catch(e){
          this.Error("错误:"+e)
        }
        
      },  
      createNew(){
        this.Confirm(`新建表单会覆盖掉当前的编辑内容，是否继续?`,()=>{
          let {uid,fID} = this.$route.query
          if(fID){
            this.$router.push(`/tools/zzl-form?uid=${uid}`)
          }else{
            this.initEmptyForm()
            this.$emit('input',this.form)
          }
          
        })
      },
      openImporter(){
        this.showImporter = true
      },
      handleEvent({type,key,value,data}){
      const eventMap = {
        new:this.createNew,
        import:this.showImport,
        export:this.showExport,
        download:this.downloadJSON,
        save:this.saveForm
      }

      let eventHandler = eventMap[key]
      if(eventHandler){
        eventHandler(value)
      }
    },
    traversalFeilds(fields,cb){
      if(Array.isArray(fields) && cb){
        fields.forEach(f=>{
          cb(f)
          this.traversalFeilds(f.fields,cb)
        })
      }
    },
    saveForm(){
      try{
        let jsd = this.untrans(this.form)
        let form = {
            fID:this.form.id,
            formName:this.form.formName,
            formCode:this.form.formCode,
            jsd
        }
        if(form.fID && form.fID !== 'create'){
            this.$api.patch("logined_zzl/form",form).then(res=>{
                this.Success("保存成功")
            }).catch(e=>{
                this.Error(e)
            })
        }else{
          //  this.$api.post("logined_zzl/form",form).then(res=>{
           //     this.Success("创建成功")
          //  })
          this.Error("暂不支持新建表单操作，请从平台创建")
        }
      }catch(e){
        this.Error(`错误:${e}`)
      }
    },
    insertForm(f){
      let selected = this.selected
      if(!selected)
        selected = this.form
      this.$api.get(`forms/${f.id}`).then(res=>{
       
        let {fields} = res.data.data
        let map = {}
        this.traversalFeilds(fields,(f)=>{
          if(!map[f.id]){
            map[f.id] = 'field-'+f.id
          }
        })
        this.traversalFeilds(fields,(f)=>{
          delete f.form_id
          if(f.id && map[f.id])
            f.id = map[f.id]
          delete f.parent_id
        })

        this.$set(selected,'fields',[...selected.fields,...fields])
        this.openFolder = false
        this.$emit('input',cloneDeep(this.form))
      })
    },
    openFormSelect(){
      this.$api.get('forms').then(res=>{
        this.forms = res.data.data
        this.openFolder = true
      })
      
    },
    select(item){
      if(this.$refs.tree)
        this.selected = this.$refs.tree.select(item)
      else
        this.selected = item
    },
    isContainer(control){
      return this.$store.getters['forms/containers'].includes(control)
    },
    createID(){
      let time = Date.now()
      return time.toString(16) + idGlobal++
    },
      handleClone({name,control}){
        this.loading = true
        let id = this.createID()
        let obj = {
          id:'field-'+id,
          key:'field-'+id,
          name:`${name}${idGlobal}`,
          control
        }
        let controlDef = this.controls.find(v=>v.control == control)
        if(controlDef && controlDef.initModel)
          controlDef.initModel(obj)
        if(!obj.config)
          obj.config = {}
        if(obj.control == 'UgoRadio'){
          obj.config.options = ['符合','不符合','/']
        }
        this.select(obj)
        return (obj)
      },
      log(e){
        //this.handleChange()
        
      },
      copyToClipboard(){
        navigator.clipboard.writeText(this.exportJSON)
        this.Success('已复制到剪切板')
      },
      handleSelect(e){
        let {item,ctx} = e
        if(item){
          this.select(item)
          this.selected_ctx = ctx
        }
      },
      
      save(){
        this.$emit('input',cloneDeep(this.form))
      },
      handleChangeFields(p,fields){
        this.$set(p,'fields',fields)
        this.save()
      },
      handleChangeAttr({key,value}){
        if(key == 'control'){
            let ControlModel = this.controls.find(v=>v.control == value)
            if(!ControlModel)
                throw new Error(`Control ${value} is not exist`)
            let initModel = ControlModel.initModel
            
            if(initModel){
                initModel(this.selected)
              
            }else{
                this.selected.config = {}
            }
            this.save()
           
        }
      },
      handleChange(){
        if(this.timer)
          clearTimeout()
        
        
        this.timer = setTimeout(()=>{
          if(this.$refs.form)
            this.$refs.form.resetFields()
          this.save()
        },100)
      },
      onChoose(e){
        const control = e.item.dataset.control
        if (control !== 'UgoSection') {
          // 如果对象不符合条件
          this.form.fields.splice(e.newIndex, 1); // 将对象还原到源列表
          this.Error("按表式要求，组件需要放置在栏目中");
        }
      },
      getAttrConfig(e){
        let control = this.controls.find(v=>v.control == e.control)
        if(control){
          const defaultConfigProperties = []
          let extraConfig = []
          if(!this.isContainer(e.control) && e.control !== 'UgoSignature'){
            extraConfig.push({
          label:"强制性条款",
          key:"mandatory",
          editbale:true,
          control:"Check"
          })
          extraConfig.push({
          label:"附件",
          key:"attachment",
          editbale:true,
          control:"Check"
        })
            extraConfig.push({
              key:'defaultValue',
              label:'默认值',
              control:'DefaultValue',
              option:{
                control:e
              }
            })
          }

          if(e.control == 'UgoSection'){
            extraConfig.push({
              label:"附件",
              key:"attachment",
              editbale:true,
              control:"Check"
            })
            extraConfig.push({
              label:"提示附件",
              key:"needAttachment",
              editbale:true,
              control:"Check"
            })
            extraConfig.push({
              label:"图片示例",
              key:"samplePicture",
              editbale:true,
              control:"Files",
              option:{
                image:true
              }
            })

           
          }
          let controlProps = control.properties || []
          const ingored = ['width','unit','simple','maxlen','type','url','border','rows']
          controlProps = controlProps.filter(v=>ingored.includes(v.key) == false)
      
          if(e.control !== 'UgoSignature'){
            controlProps.push({
                label:"提示图片",
                key:"samplePicture",
                editbale:true,
                control:"Files",
                option:{
                    image:true
                }
                })
          }

          if(e.control == 'UgoRadio'){
            let optionProp = controlProps.find(v=>v.key == 'options')
            if(optionProp){
              this.$set(optionProp,'option',{samples:[{name:'是/否',value:['是','否']},{name:'符合/不符合',value:['符合','不符合','/']}]} )
            }
            
          }
          return [...defaultConfigProperties,...controlProps,...extraConfig]
        }
        else{
          return []
        }
       
      },
      handleRemove(e){
        this.Confirm("请注意，直接删除表单项可能会导致数据绑定失效，建议通过复制操作保留删除项的【数据名称】，是否继续？",()=>{
            this.form.fields.splice(this.form.fields.findIndex(v=>v == e),1)
            this.save()
        })
      
      },
      handleTreeEvent(e){
        if(e && e.type=='select')
          this.selected = e.data
      },
      extract(control){
        let vControl = this.$refs[control.key][0]
        if(vControl){
          
        }
      },
      handleCopy(e){
        let cloned = cloneDeep(e)
        cloned.id = 'field-'+this.createID()
        if(cloned.fields)
         this.traversalFeilds(cloned.fields,f=>{
          let newID = this.createID()
          if(f.id){
            f.id = 'field-'+newID
            f.key = f.key + '_'+newID
          }
        })
        
        let index = this.form.fields.findIndex(f=>f.id == e.id)
        if(index !== -1)
          this.form.fields.splice(index+1,0,cloned)
        this.$emit('input',this.form)
      },
      handleToolEvent({type,key,value,data}){
        const eventMap = {
          new:this.createNew,
          import:this.openImporter,
          export:this.untransJSON,
          download:this.downloadJSON,
          save:this.saveForm,
          ai:()=>this.showAI=true,
          formatter:()=>this.showFomatter=true,
          refresh:()=>{
            this.Confirm('重新加载表格，会覆盖您目前未保存的所有修改，是否继续?',()=>{
              delete this.form.id
              this.$emit('on-event',{type:"refresh"})
            })
          
          }
        }

        let eventHandler = eventMap[key]
        if(eventHandler){
          eventHandler(value)
        }
      },
      handleDragEnd(e){
        return false
      }
    },
    components:{UgoXForm,draggable,Toolbar}
  }
</script>

<style lang="less">
.u-control-item{
  width:calc(100% - 10px);
  height:35px;
  display: flex;
  align-items: center;
  border:1px solid #ddd;
  padding:0 5px;
  margin:2.5px 5px;
  overflow: hidden;
  font-size:12px;

}

.u-control-item:hover{
  background:#3399ff33;
  cursor: pointer;
  transition: 0.5s;
}
.l-mask{
  position: absolute;
  z-index:999999;
  left:0;
  top:0;
  bottom:0;
  right:0;
  opacity: 0.1;
}
.l-mask:hover{
  opacity:0.3;
  transition:opacity 0.5s;
}
.l-remove-btn{
   position: absolute;
  z-index:1000000;
 
  top:0;
  width:20px;
  height:20px;
  right:0;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.l-remove-btn:hover{
  i{
  color:var(--primary) !important;
  }
}

.form-item{
  position: relative;
  .l-mask{
    background:var(--primary);
  }
}

.form-item-selected{
  border-color:var(--warning) !important;

  .l-mask{
    background-color:var(--warning);
  }
  
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.drag-tip{
  display: flex;
  align-items: center;justify-content: center;
  margin:5px;
  color:#aaa;
}

.l-panel-title{
height:25px;padding:4px 5px;background:#eee;font-size:12px;
border-bottom:1px solid var(--border);
display: flex;
align-items: center;;
}
</style>