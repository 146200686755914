<template>
  <FormItem :label="label" :required="required">
    <template v-if="editable">
      <DatePicker
        format="yyyy/MM/dd"
        type="datetime"
        v-model="localDate"
        transfer
        :placeholder="config?.placeholder || '请选择'"
      />
    </template>
    <template v-else>
      <div style="color: #aaa; min-width: 140px; text-align: center;">
        {{ value ? moment(value).format("YYYY/MM/DD HH:mm:ss") : "未填写" }}
      </div>
    </template>
  </FormItem>
</template>

<script>
import moment from "moment";
import defaultValueMixin from "../mixins/default-value";

export default {
  mixins: [defaultValueMixin],
  cname: "时间",
  corder: 32,
  properties:[{
        label:'提示文字',
        key:'placeholder',
        control:'Text',
      },],
  icon: "datetime",
  props: ["value", "label", "config", "editable", "required", "placeholder"],
  computed: {
    
    localDate: {
      get() {
        return this.value ? moment(this.value).format("YYYY/MM/DD HH:mm:ss") : null;
      },
      set(v) {
        const newValue = v
          ? moment(`${moment(v).format("YYYY/MM/DD HH:mm:ss")}`, "YYYY/MM/DD HH:mm:ss").format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : null;
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style lang="less" scoped>
/* 样式可以根据需求添加 */
</style>
