import { RectNode, RectNodeModel,h } from '@logicflow/core';

class ApprovalNode extends RectNode {
  // 自定义节点渲染
  getShape() {
    const { model } = this.props;
    const { x, y, width, height, properties } = model;
    let approvalName = '审批人';
    if(Array.isArray(properties.users)){
      approvalName = ' ' + properties.users.map(v=>v.name)
    }
    // 定义 SVG 图标
    const userIcon = h('svg', {
      x: x - width / 2 + 5,
      y: y - height / 2 + 5,
      width: 16,
      height: 16,
      viewBox: '0 0 1024 1024',
      fill: '#FFD700'
    }, [
      h('path', {
        d: 'M512 512a256 256 0 1 0 0-512 256 256 0 0 0 0 512z m0 85.333333c-170.666667 0-512 85.333333-512 256v85.333334h1024v-85.333334c0-170.666667-341.333333-256-512-256z'
      })
    ]);
  
    // 定义文本标签
    const text = h('text', {
      x: x - width / 2 + 25, // 在节点左上角
      y: y - height / 2 + 18,
      fill: '#1890FF',
      fontSize: 12,
      textAnchor: 'start'
    }, approvalName);

    let doms = [
      // 圆角矩形背景
      h('rect', {
        x: x - width / 2,
        y: y - height / 2,
        width,
        height,
        fill: '#E6F7FF',
        stroke: '#1890FF',
        strokeWidth: 2,
        rx: 10, // 设置水平圆角半径
        ry: 10  // 设置垂直圆角半径
      }),
      // 添加用户图标和名字
      userIcon,
      text
    ]
    if(properties.clonedBy){
      doms.push(h('circle',{
        cx: x + width/2 - 20, // 圆心 x 坐标
        cy: y - height / 2 +2,// 圆心 y 坐标
        r: 10, // 圆的半径
        fill: '#F6FFED', // 圆形填充颜色
        stroke: '#52C41A', // 边框颜色
        strokeWidth: 2
      }))
    }
  
    return h('g', {}, doms);
  }
  
}

class ApprovalNodeModel extends RectNodeModel {
  // 设置节点的默认属性
  initNodeData(e) {
    super.initNodeData(e);
    this.width = 160;
    this.height = 80;
    if(!this.properties || Object.keys(this.properties).length == 0)
      this.properties = {
        status:'审批中',
        'executor-type':'发起人自选'
      }
  }

  getProps(){
    return [{
      key:'executor-type',
      label:'审批人类型',
      control:'Select',
      option:{
        options:['发起人自选','固定']
      },
      group:'审批'
  },{
      key:'approval-region',
      label:'审批人范围',
      control:'ACLSelect',
      group:'审批'
  },{
    key:'multiple',
    label:'审批人多选',
    control:'Check',
    group:'审批'
  },{
    key:'default-users',
    label:'默认审批人',
    control:'UserSelect',
    group:'审批'
},{
      key:'message',
      label:'短信通知',
      control:'Check',
      group:"通知"

    },{
      key:'clonedBy',
      label:'克隆对象',
      control:'Text',
      group:'高级'
  },{
    key:'clonedType',
    label:'克隆类型',
    control:'Select',
    option:{
      options:['文本','部门','成员'],
      defaultTo:'文本'
    },
    group:'高级'
},{
    key:'clonedKey',
    label:'克隆key',
    control:'Text',
    group:'高级'
  },]
  }
}

export default {
    type:'approval_node',
    view:ApprovalNode,
    model:ApprovalNodeModel
}
