<template>
  <div class="pd-embed-control" :class="{'pd-embed-contorl-disabled':!editable}">
    <template>
      <div class="flex-wrap" style="padding:0 8px;cursor:pointer;height:100%;" @click="openEditor">
        配置 
      </div>
    </template>

    <Modal v-model="modalFileSelector" footer-hide title="配置可见/编辑" :width="600">
      <div>
      
        <template v-if="!form || form.length==0">
          <BaseEmpty msg="请先绑定数据模型" />
        </template>
        <template v-else>
        <div class="flex-wrap" style="border-bottom:1px solid var(--border);">
          <div style="flex-grow:1;"></div>
          <div style="width:140px;">可见性</div>
          <div style="width:140px;">可编辑</div>
        </div>
        <BaseTree 
          :data="form"
          :nodeRenderer="renderNode"
          subname="fields"
          style="width:100%;"
          manu
        />
        <div class="flex-wrap flex-right" style="border-top:1px solid var(--border)">
          <Button type="primary" style="margin-right:10px;" @click="handleSave()">确定</Button>
          <Button @click="handleCancel()">取消</Button>
        </div> 
      
      </template> 
       
      </div>
    </Modal>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';


export default {
  data(){
    return {
      modalFileSelector:false,
      localValue:{}
    }
  },
  props:['value','editable','option','db'],
  mounted(){
    this.$api.afterLogin(()=>{
      this.getData()
    })
  },
  computed:{
    formKey(){
   
      if(this.option?.mode !== 'form'){
        return this.db.model
      }else{
        if(this.option?.form_id)
          return this.option.form_id
        return this.db.form
      }
    },
    form(){
      let form = this.$store.getters['forms/form']
      return form?[form]:[]
    }
  },
  watch:{
    formKey:{
      handler(v){
        this.getData()
      },
      deep:true,
      immediate:true
    }
  },
  methods:{
    openEditor(){
      if(this.editable){
        this.localValue = cloneDeep(this.value || {})
        this.modalFileSelector = true
      }
    },
    getData(){
      this.loading = true
      let action = 'forms/getByID'
      if(this.option?.mode !== 'form')
        action = 'forms/get'

      this.$store.dispatch(action,this.formKey).then(()=>{
      }).catch(e=>{

      }).finally(()=>{
        this.loading = false
      })
       
    },
    renderNode(h,node){
      let checkView = h('Checkbox',{props:{value:!this.localValue[node.fullkey] || this.localValue[node.fullkey]<2},on:{
        input:(e)=>{
          
          this.$set(this.localValue,node.fullkey,e?0:2)
        }
      }})
      let checkEdit = h('Checkbox',{props:{value:!this.localValue[node.fullkey] || this.localValue[node.fullkey]<1},on:{
        input:(e)=>{
          this.$set(this.localValue,node.fullkey,e?0:1)
        }
      }})
      let domType = h('span',{style:'color:var(--subtext2)'},`[${node.control || 'Form'}]`)
      let domName = h('span',{style:'color:var(--primary);margin-left:5px;'},`${node.name}`)
      let domNameWrap = h('div',{class:'flex-wrap',style:'width:calc(100% - 310px)'},[domType,domName])
      let domVis = h('div',{class:'flex-wrap flex-center',style:'width:140px;'},[checkView])
      let domEdit = h('div',{class:'flex-wrap flex-center',style:'width:140px;'},[checkEdit])
      return h('div',{class:'flex-wrap',style:'width:100%;border-bottom:1px dashed var(--border);'},node.control?[domNameWrap,domVis,domEdit]:[domNameWrap])
    },
    handleSave(){
      this.$emit('input',this.localValue)
      this.localValue = {}
      this.modalFileSelector =false
    },
    handleCancel(){
      this.localValue = {}
      this.modalFileSelector =false
    }
  }
}
</script>

<style>

</style>