<template>
  <div>
    Flow
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>