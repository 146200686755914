<template>
  <div class="container" style="background:var(--primary);color:var(--hover-text)">
    {{data}}
  </div>
</template>

<script>
  export default {
    props:['data']
  }
</script>

<style lang="scss" scoped>

</style>