<template>
  <Dropdown   placement="bottom-end" transfer trigger="click">
    <a @click="login" ><BaseAvatar :src="session.avatar" :size="25">
        {{session.id?'':'登录'}}
      </BaseAvatar></a>
    <DropdownMenu slot="list" v-if="session.id">
      <DropdownItem name="self">个人中心<BaseIcon icon="ios-arrow-forward" style="margin-left:10px" /></DropdownItem>
      <DropdownItem name="goback">退出<BaseIcon icon="ios-arrow-forward" style="margin-left:10px" /></DropdownItem>
    </DropdownMenu>

    <Modal v-model="showLogin" footer-hide transfer :width="800">
      Login
    </Modal>
  </Dropdown>
</template>

<script>
  import {mapGetters} from 'vuex'
  export default {
    data(){
      return {
        showLogin:false
      }
    },
    computed:{
      ...mapGetters('session',['session'])
    },
    methods:{
      login(){
        if(!this.session.id){
          this.$store.commit('session/save_login_url',this.$route.fullPath)
          this.RouteTo('/')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>