import YearBill from './YearBill'
import YearProduction from './YearProduction'
import YearHrp from './YearHrp'
import YearHr from './YearHr'
import YearContractRemain from './YearContractRemain'
import NewSign from './NewSign'
import NewSignType from './NewSignType'
import BillPredict from './BillPredict'
import HistoryHra from './HistoryHra'
import InBuildingProjects from './InBuildingProjects'
import InCompleteProjects from './InCompleteProjects'
import SmallProjects from './SmallProjects'
import Untransferred from './Untransferred'
let out = {
    list: [],
    components:{}
}
function make_t(name,item){
    out.list.push({name,component:item.name})
    out.components[item.name] = item
}

make_t('收费情况表',YearBill)
make_t('产值情况表',YearProduction)

make_t('人工投入表',YearHr)
make_t('人均产值/人均收费情况表',YearHrp)
make_t('合同余额表',YearContractRemain)
make_t('新中标年度合同表(规模)',NewSign)
make_t('新中标年度合同表(业务类型)',NewSignType)
make_t('历史及未来收费预测表',BillPredict)
make_t('历年合同收费、产值、人均数据表',HistoryHra)
make_t('在建项目情况',InBuildingProjects)
make_t('年度预计竣工项目情况',InCompleteProjects)
make_t('年度小微项目情况',SmallProjects)
make_t('历史未到账项目情况',Untransferred)


export default out