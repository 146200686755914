<template>
  <div>
    <Row :gutter="10" class-name="l-bill">
      <Col :span="2" style="text-align:center">序号</Col>
      <Col :span="4" style="text-align:center" v-if="splited">服务类型</Col>
      <Col :span="8">调整原因</Col>
      <Col :span="4" style="text-align:right">调整金额</Col>
      <Col :span="4" align="center">附件</Col>
    </Row>

    <BaseEmpty v-if="items.length == 0" style="padding:20px;margin:0 -5px;width:calc(100% + 10px);background:var(--bg3);" msg="暂无条目" />
    <template v-for="(data,index) in items">
      <Row :key="data.id" :gutter="10" class-name="l-bill">
        <Col :span="2" style="text-align:center">{{index+1}}</Col>
        <Col :span="4" style="text-align:center" v-if="splited">{{data.business_type}}</Col>
        <Col :span="8" style="color:var(--primary)">{{data.detail}}</Col>
        <Col :span="4">
        <BaseAmount full signed :value="data.amount"></BaseAmount>
        </Col>
        <Col :span="4" align="center">
        <a v-if="data.file" @click="Download(data.file)">下载</a>
        <span v-else>-</span>
        </Col>
      </Row>
    </template>
    <Row :gutter="10" class-name="l-bill" v-if="items.length > 0">
      <Col :span="2">合计</Col>
      <Col :span="4" v-if="splited">
      </Col>
      <Col :span="12">
      <BaseAmount full signed :value="amount"></BaseAmount>
      </Col>
    </Row>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    items() {
      return this.value.contract_adjusts || []
    },
    splited() {
      return this.value ? this.value.splited : false
    },
    amount() {
      return this.items.reduce((c, item) => {
        return c + item.amount
      }, 0)
    }
  },
  
}
</script>

<style lang="scss" scoped>
</style>