<style lang="less">

.ugo-item-user{
  width:130px;height:48px;padding:0 10px;background:var(--bg3);
  cursor: pointer;

}

.ugo-item-user:hover{
  background:var(--primary);
  transition: all 0.3s;
}

.ugo-item-user-selected{
  background:var(--primary);
  color:var(--hover-text);
}

.ivu-select-dropdown{
  max-height:400px;
}
</style>
<template>
  <div class="pm-embed-control flex-wrap" style="color:var(--text3);align-items:flex-start;width:100%;position:relative;height:100%;">
    
    <Dropdown trigger="custom" :visible="showDropdown" transfer placement="bottom-start" style="width:100%;display:block;height:100%;" @on-clickoutside="handleClickOut">
      <div class="value-wrap flex-wrap" style="cursor:pointer;border:1px solid var(--border);border-radius:3px;background:var(--bg3);padding:0 5px;flex-wrap:wrap;" @click="handleOpen" :style="editable?'':'background:var(--border);'">
        <template v-if="selected.length == 0">
          <span style="color:var(--subtext2)">请选择</span>
        </template>
        <template v-else>
          <template v-for="user in selected_users.slice(0,3)">
            <div class="flex-wrap" :key="user.id" style="margin:0 5px;" >
            <BaseAvatar size="20" :src="user.avatar">{{user.name?user.name[0]:'-'}}</BaseAvatar>
            <span :key="user.id" style="margin:0 5px">
              {{user.name}}
            </span>
            </div>
          </template>
          <span v-if="selected_users.length > 3" :title="selected_users.slice(3).map(v=>v.name).join(',')" style="color:var(--primary)">
            ... 等{{selected_users.length}}人
          </span>
        </template>
        </div>
      <DropdownMenu slot="list">
      <div ref="panel" class="" style="background:var(--bg2);position:relative;overflow:hidden;">
          <div class="flex-wrap split5" style="width:100%;padding:5px"><Input v-model="filterText" search clearable  /><Button @click="$emit('input',[]);selected=[];showDropdown=false;">清除</Button>
            <Button click="getData()" icon="md-refresh"></Button>
          </div>
           <div  style="width:calc(100%);height:300px;overflow-y:auto;">
            <BaseLoading v-if="loading"></BaseLoading>
            <BaseEmpty   v-else-if="grouped_employees.length == 0">
            
            
            </BaseEmpty>
            <template v-for="g in grouped_employees">
            <div :key="g.id" v-if="filterUsers(g.users).length > 0">
              <div :style="`background:${g.color};color:var(--hover-text);padding:2px 5px;`">{{g.name}}</div>
           <div class="flex-wrap" style="overflow-y:auto;flex-wrap:wrap;overflow-x:hidden;">
              <template v-for="o in filterUsers(g.users)">
                <div class="flex-wrap ugo-item-user" :class="{
                  'ugo-item-user-selected':selected && selected.includes(o.id)
                }" :key="o.id" style="" @click="handleClick(o)">
                  <BaseAvatar :src="o.avatar" style="flex-shrink:0">{{o.name[0]}}</BaseAvatar>
                  <div style="margin-left:5px">
                    <div>{{o.name}}</div>
                    <div style="font-size:10px;color:var(--primary)">{{o.phone}}</div>
                    </div>
                </div>
              
              </template>
              </div>
               </div>
              </template>
             
              </div>
    
       
          </div>
         <div class="flex-wrap flex-between" v-if="multiple" style="width:100%;height:40px;padding:0 10px;background:var(--bg3);border-top:1px solid #ddd;" @click.stop>
          <div class="flex-wrap split5">
            <div>已选择 {{selected.length}}</div>
            <Button size="small" @click.stop="handleSelectAll">全选</Button>
            <Button size="small" @click.stop="handleClear">取消</Button>
            </div>
             <Button icon="md-checkmark" @click="handleConfirm" type="primary" size="small">确认</Button>
        </div>
      </DropdownMenu>
    </Dropdown>
      
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name:"BaseUserSelect",
  icon:'xialaliebiao',
  data(){
    return {
      prev:false,
      current:{},
      loading:false,
      showDropdown:false,
      filterText:"",
      selected:[]
    }
  },
  watch:{
    value:{
      handler(v){
        this.filterText = ""
        if(Array.isArray(this.value)){
          this.selected = this.value
        }else if(this.value){
          this.selected = this.multiple?[...this.value]:(this.value?[this.value]:[])
        }else{
          this.selected = []
        }
      }
    },
  },
  mounted(){
    this.filterText = ""
    if(this.option.groups)
      this.current  = {groups:this.option.groups}
    if(Array.isArray(this.value)){
      this.selected = [...this.value]
    }else if(this.value){
      this.selected = this.multiple?[...this.value]:(this.value?[this.value]:[])
    }else{
      this.selected = []
    }
    this.getData()
  },
  props:{
    'option':{
      type:Object,
      default:()=>({})
    },'value':{
      type:String | Array,
      default:()=>([])
    },'editable':{
      type:Boolean,
      default:true
    },'size':{
      type:String
    },
    included:{
          type:Array,
          default:()=>([])
        },  
        excluded:{
            type:Array,
            default:()=>([])
        }
    
  },
  computed:{
    ...mapGetters("session",["grouped_employees","users"]),
    filteredUsers(){
      let users = this.users.filter(v=>(!Array.isArray(this.included) || this.included.length == 0 || this.included.includes(v.id)) && this.excluded.includes(v.id) == false)
      if(this.filterText){
              return users.filter(v=>v.name && v.name.includes(this.filterText))
      }else{
        return users.filter(v=>v.name)
      }
    },
    multiple(){
      return this.option.multiple
    },
    user(){
      return this.users.find(v=>v.id == this.value) || {}
    },
    selected_users(){
      return this.selected.map(v=>this.users.find(u=>u.id == v)).filter(v=>v)
    }
  },
  methods:{
    getData(){
      this.$api.afterLogin(()=>{
        this.loading = true
        this.$store.dispatch("session/getGroupedEmployees").finally(()=>{
          this.loading = false
        })
      })
    },
    handleOpen(){
      if(this.editable){
        this.showDropdown=true;
        this.searchText=''
      }
    },
    filterUsers(users){
      users = users.filter(v=>(!Array.isArray(this.included) || this.included.length == 0 || this.included.includes(v.id)) && this.excluded.includes(v.id) == false)
      if(this.filterText){
              return users.filter(v=>v.name && v.name.includes(this.filterText))
      }else{
        return users.filter(v=>v.name)
      }
    },
    handleClickOut(e){
      if(!this.$refs.panel.contains(e.target)){
        this.showDropdown = false
        if(Array.isArray(this.value)){
          this.selected = this.value
        }else if(this.value){
          this.selected = this.multiple?[...this.value]:(this.value?[this.value]:[])
        }else{
          this.selected = []
        }
      }
    },
    handleClear(e){
      this.selected = []
    },
    handleSelectAll(){
      this.selected = this.filteredUsers.map(v=>v.id)
    },
    handleClick(o){
      if(this.multiple){
        let index = this.selected.findIndex(v=>v == o.id)
        if(index == -1)
          this.selected.push(o.id)
        else
          this.selected.splice(index,1)
      }else{
        this.selected = [o.id]
        this.$emit('input',o.id)
        this.showDropdown = false
      }
    },
    handleConfirm(){
      this.$emit('input',[...this.selected])
      this.showDropdown = false
    }
  }
}
</script>

<style lang="less">
.l-full{
  position: relative;
  height:100%;
  width:100%;
}
</style>