<style lang="less">
.ugo-hide-label{

}
</style>
<template>
  <FormItem :label="label"  :class="{'ugo-hide-label':true}">
      <BaseFlexTable
        style="border:1px solid var(--border);"
        :columns="config?.cols || []"
        v-model="items"
        @input="handleSave"
        :model="db"
        :summary="config?.summary"
        />
</FormItem>
  
</template>

<script>
import UgoMark from './Mark.vue'
import render from './render.js'
import defaultValueMixin from '../mixins/default-value'
import composedMixin from '../mixins/composed'
import {cloneDeep} from 'lodash'
  export default {
    mixins:[defaultValueMixin,composedMixin],
    cname:"数据表",
    icon:'biaoge',
    components:{UgoMark},
    initModel(e){
      e.config = {
        cols:[{
          control:'drag',
          name:'拖'
        },{
          control:'index',
          name:'序'
        },{
          name:'第一列',
          control:'Input',
          key:"col1",
          span:4
        },{
          name:'第二列',
          control:'Input',
          key:"col2",
          span:4
        }]
      }
    },
  properties:[{
    label:"列",
    key:'cols',
    control:"ColumnDesign"
  }],
   data(){
     return {
       items: cloneDeep(this.value),
     }
   },
   props:['config','value','editable','label','required','db'],
   watch:{
     value:{
       handler(v){
        console.log('wtach-table:',v)
        if(v){
          this.items = cloneDeep(this.value)
        }
       },
       deep:true,
       immediate:true
     }
   },
   methods:{
    handleSave(){
      this.$emit('input',this.items)
    }
   }
}
</script>

<style lang="less">
.ugo-table{
  .ivu-table-cell{
    height:100%;
    width:100%;
    position: relative;
    padding:0;
    display: flex;
    align-items: center;
    justify-content: center;
    .ivu-input-wrapper{
      height:100%;
    }
    .ivu-input{
      width:100%;
      height:100%;
      border:none;
      background:none;
      margin:0;
      border-radius: 0;
      padding:0 10px;
    }
  }

  
}

.control-wrapper{
  background:#fff;
  width:100%;
  height:100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.control-wrapper-selected{
  background:#ffd;
}

.control-wrapper-disabled{
  background:rgb(255, 254, 254);
  
}
</style>