import {API} from '@/vue-plugins/api'

const state = {
  weatherData:{
    realtime:{}
  }
}

const getters = {
  weather(state){
    return state.weatherData
  }
}

const threads = {}

const actions = {
  queryWeather({commit}){
    if(threads.queryWeather)
      return
    
    threads.queryWeather = API.get("/queries/weather").then(res => {
      let data = res.data.data
      commit('saveWeather',data)
    }).finally(()=>{
      delete threads.queryWeather
    })
  }
}

function mapWeather(w) {
  if (!w)
    return
  if (w.includes('晴'))
    return 'qing'
  else if (w.includes('雷'))
    return 'leizhenyu'
  else if (w.includes('夹'))
    return 'yujiaxue'
  else if (w.includes('雨'))
    return 'xiaoyu'
  else if (w.includes('雪'))
    return 'xiaoxue'

  return ''
}

const mutations = {
  saveWeather(state,weatherData){
    if(!weatherData.future)
      return
    let [a, b] = weatherData.realtime.info.split('转')
    weatherData.realtime.icon = mapWeather(a)
    weatherData.realtime.icon2 = mapWeather(b)
    weatherData.future.forEach(v=>{
       let w = v.weather
       let [a, b] = w.split('转')
       v.weather_icon = mapWeather(a)
       v.weather_icon2 = mapWeather(b)
       v.temperature = v.temperature.replace('/', '~').replace('℃', ' ℃')
    })
    state.weatherData = weatherData
  }
}


export default {
  namespaced:true,
  state,
  getters,
  actions,
  mutations
}