<template>
  <App>
    <AppBar>
      <div class="flex-wrap" style="font-size:16px;">
      <BaseIcon icon="table" :size="24" style="margin-right:5px" /> 表单管理
      </div>
      <div>
        <Input search />
      </div>
    </AppBar>
     <AppLeft style="width:200px"></AppLeft>
    <AppClient style="background:var(--bg2);padding:0 10px;">
    <div class="flex-wrap flex-between">
      <div>
        <div class="flex-wrap" style="height:40px;margin-top:5px;">
          <Input search style="width:200px;margin-right:10px;" placeholder="输入名称关键字" />
          
     
        </div>
    <div class="flex-wrap flex-between" style="height:40px;margin-bottom:5px;">
      <ButtonGroup>
        <Button 
          v-for="t in tabs"
          :key="t.key"
          :type="filter.type == t.key?'primary':'default'"         
          @click="filter.type = t.key"> {{ t.name }}</Button>
      </ButtonGroup>
    </div>
      </div>
      <Button icon="md-add" @click="handleEdit()" style="height:60px;" type="primary">新建表单</Button>
    </div>
       

    
    <div style="width:100%;height:calc(100% - 90px);position:relative;">
      <BaseTable :columns="cols" :data="items" border class="container"  @on-click="showInfo" />
      
      </div>
    </AppClient>

    <Drawer width="720" v-model="drawerInfo" :title="selected.name">
      <div style="width:680px;margin:0 auto">
        
        <div style="margin-top:20px">
          <FormInstance  class="docx" :form="selected" :data="formData" @change="changed=$event" :editable="true" />
        </div>
        <div class="flex-wrap split10" style="margin-top:30px">
        <Button type="primary" long @click="handleUse(selected)">创建</Button>
        <Button  long @click="handleEdit(selected)">编辑</Button>
         <Button  long  @click="handleDelete(selected)">删除</Button>
         </div>
         <div style="margin-top:10px">
          上传时间 {{moment(selected.created_at).format("LL")}}
        </div>
        <div>
          描述：{{selected.description || '无'}}
        </div>
      </div>
    </Drawer>
  </App>
</template>

<script>
import App from "@/components/app/app"
import AppBar from '@/components/app/bar'
import AppLeft from '@/components/app/left'
import AppClient from '@/components/app/client'
import Tabs from '@/components/app/tabs2'

import FormInstance from '@/components/form/html'
  let gloablId = 1
  export default {
    components:{App,AppBar,AppClient,Tabs,FormInstance,AppLeft},
    routerOption:{
      as_default:true
    },
    data(){
      return {
        items:[],
        activeTab:'all',
        drawerInfo:false,
        selected:{},
        formData:{},
        filter:{
          type:"标准表单"
        }
      }
    },
    computed:{
      tabs(){
        return [{
          key:"all",
          iconText:"user",
          name:"全部"
        },{
          key:"标准表单",
          icon:"xiangmu2",
          name:"标准表单"
        },{
          key:"临时表单",
          icon:"nbgz",
          name:"临时表单"
        },{
          key:"内嵌表单",
          icon:"lianjieliu",
          name:"内嵌表单"
        }]
        
      },cols(){
          return [{
            type:'index',
            width:60,
            title:'序号'
          },{
            type:'type',
            align:'center',
            width:120,
            key:'type',
            title:'类型'
          },{
            type:'text',
            width:400,
            align:'center',
            key:'name',
            title:'名称',
            render:(h,{row})=>{
              let icon = h('BaseIcon',{props:{icon:'table',color:'var(--primary)'},style:'margin-right:5px;margin-left:10px;'})
              let name = h('span',row.name)
              return h('div',{class:'flex-wrap',style:'color:var(--primary)'},[icon,name])
            }
          },{
            type:'text',
            minWidth:200,
            key:'description',
            title:'备注'
          },{
            type:'text',
            width:120,
            sortable:false,
            title:'查看数据',
            render:(h,{row})=>{
              return h('Button',{props:{size:'small',icon:'md-list'}},'查看')
            }
          },{
            type:'text',
            width:280,
            key:'model',
            sortable:false,
            title:'绑定模型',
            render:(h,{row})=>{
              if(row.model)
                return h('Button',{props:{size:'small',icon:'md-cube'}},row.model)
              return h('span','-')
            }
          },{
            type:'user',
            width:120,
            key:'created_by',
            title:'创建人'
          },{
            type:'time',
            width:120,
            key:'created_at',
            title:'创建时间'
          },{
            title:'操作',
            
            width:200,
            render:(h,{row})=>{
              let buttons = [h('Button',{props:{icon:'md-create',size:'small'},on:{
                click:()=>{
                  this.handleEdit(row)
                }
              }},'编辑'),h('Button',{props:{icon:'md-trash',size:'small'},style:'margin-left:5px',on:{
                click:()=>{
                  this.handleDelete(row)
                }
              }},'删除')]
              if(row.created_by !== this.$store.getters['session/session'].id){
                buttons = []
              }
              return buttons
            }
          }]
        }
    },
    mounted(){
      this.getData()
    },
    methods:{
      showInfo(e){
        this.selected = e
        this.getFormData(e)
        this.drawerInfo = true
      },
      handleEdit(e){
        let id = e?e.id:'create'
        let url = "/core/forms/"+(id)+"/editor"
        this.RouteTo(url)
        this.$store.commit("session/push",{path:url,name:'编辑表单'+(e?`:${e.name}`:''),icon:"edit",base_url:url})
      },
      handleUse(e){
         let url = "/core/forms/"+(e.id)+"/info"
        this.RouteTo(url)
        this.$store.commit("session/push",{path:url,name:'填写表单'+(e?`:${e.name}`:''),icon:"edit",base_url:url})
      },
      handleDelete(e){
        this.Confirm(`确定要删除表单 (<span style='color:var(--error)'>所含数据也会删除</span>)?`,()=>{
          this.loading = true
          this.$api.delete(`forms/${e.id}`).then(res=>{
            this.Success('删除成功')
            this.getData()
          }).catch(e=>{
            this.Error(e)
          }).finally(()=>{
            this.loading = false
          })
        })
        
      },
      getData(){
        this.$api.get("forms").then(res=>{
          this.items = res.data.data
        })
      },
      	getFormData(e) {
			this.$api.get("forms/" + e.id).then((res) => {
				this.selected = res.data.data;
			});
		},
    }
  }
</script>

<style lang="scss" scoped>

</style>