<template>
  <Tooltip  
    style="display: inline"
    :content="tip" :disabled="!tip"><span class="base-tag" :style="`background:${color || 'var(--primary)'};color:${textColor || '#fff'};`"><slot></slot></span></Tooltip>
</template>

<script>
  export default {
    props:['tip','color','text-color']
  }
</script>

<style lang="less">
.base-tag{
  padding:3px 4px;
  font-size:10px;
  background:var(--warning);
  white-space: nowrap;
  
  color:#fff;
}
</style>