<template>
    <div class="container">
        <h1 class="wrap" style="margin:20px auto">培训中心</h1>
        <div class="flex-wrap flex-between wrap" style="font-size:16px;margin-bottom:10px;">
          <div class="flex-wrap">
            <template v-for="(g,i) in groups">
              <div
                :key="'group_'+g.key"
                class="group-item flex-wrap"
                :class="{'group-actived':g.key == selected}"
                @click="selected = g.key"
              >
                {{g.name}}
                <div class="group-item-count">{{item_counts[g.key] || 0}}</div>
                <Divider type="vertical" v-if="i !== groups.length-1" />
              </div>
            </template>
          </div>
          <div>
            <Input clearable search />
          </div>
        </div>
        <div style="overflow-y:auto;height:calc(100% - 240px);width:100%;position:relative;">
            <div
          class="flex-wrap align-start wrap"
          style="flex-wrap:wrap;position:relative;border-top:1px solid var(--border);padding:10px;"
        >
            <BaseEmpty style="height:500px" v-if="items.length == 0"></BaseEmpty>
            <template v-else>
              <template v-for="item in items">
                <component :is="selectedItemControl" @on-open="open(item,$event)" :value="item" :key="item.id"  />
              </template>
            </template>
          </div>
         
        </div>
      
      <div class="wrap flex-wrap flex-center" style="margin-top:20px">
        
        <Page :count="item_counts[selected]" />
      </div>
    </div>
  </template>
  
  <script>
  import TrainingProject from "@/components/training/project";
  import TrainingMyProject from "@/components/training/myproject";
  import TrainingClass from "@/components/training/class";
  import TrainingGroup from "@/components/training/group";
  import TrainingTeacher from "@/components/training/teacher";
  import TrainingCategory from "@/components/training/category";
  export default {
    components: {
      TrainingProject,
      TrainingMyProject,
      TrainingClass,
      TrainingGroup,
      TrainingTeacher,
      TrainingCategory
    },
    routerOption:{
      as_default:true
    },
    data() {
      return {
        selected: "Class",
        item_counts: {
          Project: 0,
          MyProject: 5,
          Class: 1,
          Group: 2,
          Teacher: 3
        },
        pagesize: 30,
        groups: [{
            key: "Class",
            name: "课程"
        },],
        used:[
          {
            key: "Project",
            name: "培训报名"
          },
          {
            key: "MyProject",
            name: "我的培训"
          },
          {
            key: "Class",
            name: "课程"
          },
          {
            key: "Group",
            name: "合辑"
          },
          {
            key: "Teacher",
            name: "讲师"
          },
          {
            key: "Category",
            name: "分类"
          }
        ],
        items: [
          {
            tags: ["青藤班"],
            name: "桩基检测解读",
            teacher_id: "ugo",
            created_at: "2022/09/12",
            created_by: "ugo",
            student_counts: 32,
            state: 0,
            place: "14楼多功能会议室",
            avatar: ""
          }
        ]
      };
    },
    computed: {
      selectedItemControl() {
        return "Training" + this.selected;
      }
    },
    mounted() {
      setTimeout(this.getData, 300);
    },
    methods: {
      getData() {
        this.loading = true;
        this.$api
          .get("study/classes?related=project&order=created_at&desc=1&page=1&pagesize=100")
          .then(res => {
            this.items = res.data.data;
          });
      },
      open(item,type){
        let {id,name} = item
        let path = `/core/training/${id}/${type}`
        this.$store.commit('session/push',{
          icon: "logo-youtube",
          type: "video",
          name,
          size: 24,
          path,
          base_url: `/core/training/${id}`
        })
        this.RouteTo(path)
      }
    }
  };
  </script>
  
  <style lang="less" scoped>
  .wrap {
    width: 1200px;
    margin: 0 auto;
    position: relative;
  }
  
  .group-item {
    cursor: pointer;
  }
  
  .group-item-count {
    padding: 1px 5px;
    font-size: 12px;
    margin-left: 5px;
    border-radius: 50%;
    background: var(--bg2);
    color: var(--text2);
  }
  
  .group-actived {
    color: var(--primary);
  }
  </style>