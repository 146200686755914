<template>
  <div class="container pm-projects">
    <div class="l-label flex-wrap flex-between">
      <div>{{data.name}}</div>  
      <a>查看更多</a>
    </div>
    <div class="flex-wrap align-start l-projects-wrap" style="background:#eeeeeeaa;padding:0 5px;border-radius:10px;">
      <template v-for="p in projects">
        <div class="l-project" :class="{'l-project-active':p.state==1}" :key="p.id">
          <img :src="p.avatar || 'https://nbgz-pmis-1257839135.cos.ap-shanghai.myqcloud.com/timg.jpg'"/>
          <div class="l-name">{{p.name}}</div>
          <div class="l-position">{{p.position}}</div>
        </div>
      </template>
     
    </div>
  </div>
</template>

<script>
  export default {
    props:{
      data:{
        type:Object,
        default:()=>({})
      }
    },
    computed:{
      projects(){
        return this.data.items || []
      }
    }
  }
</script>

<style lang="less">
.pm-projects{
  padding:0 20px;
  .l-label{
    color:var(--text1);
  }
  .l-projects-wrap{
    margin-top:15px;flex-wrap:wrap;height:calc(100% - 40px);
  }
  .l-project{
    width:calc(33% - 10px);
    height:calc(50% - 10px);
    flex-shrink: 0;
    margin-bottom:10px;
    margin-right:10px;
    cursor: pointer;
    img{
      width:90%;
      height:calc(100% - 60px);
      border-radius: 10px;
      border:1px solid var(--disabled);
    }

    .l-name{
      font-size:14px;
      width:90%;
    }

    .l-position{
      color:var(--primary);
    }
  }

  .l-project:hover{
    img{
      filter:brightness(1.05);
    }
  }

  .l-project-active{
    img{border:1px solid var(--primary)}
  }
}
</style>