<template>
  <div style="width:100%;height:100%;position:relative;">
    <div class="flex-wrap" style="padding-right:32px;padding-left:5px;line-height:30px;cursor:pointer" :style="{color:value?'#39f':'#aaa'}" @click="modalSelector=true">
      {{'编辑'}}
    </div>
   
    <Modal v-model="modalSelector" width="500" title="列编辑器" footer-hide>
      <div style="margin:0px;border:1px solid var(--border);border-radius:5px;">
      <div class="flex-wrap" style="align-content:flex-start;align-items:flex-start;">
        
        <div style="flex-grow:1;border-right:1px solid var(--border);min-height:300px;">
          <template v-for="(c,i) in value || []">
            <div class="col-item" :class="{'col-item-selected':selected==c}" :key="c.key" @click="selected = c">
              {{c.name}}
            </div>
          </template>
          <div class="col-item" @click="add">
            <Icon type="md-add" size="20" /> 新增
          </div>
        </div>
        <div style="width:300px;">
          <div class="flex-wrap" style="border-bottom:1px solid var(--border)">
          <div class="flex-wrap flex-center" style="width:30px;height:29px;border-right:1px solid var(--border);cursor:pointer;" @click="remove()">
             <Icon type="md-remove" size="15" /> 
          </div>
          <div class="flex-wrap flex-center" style="width:30px;height:29px;border-right:1px solid var(--border);cursor:pointer;" @click="swapup()">
             <Icon type="md-arrow-up" size="15" /> 
          </div>
          <div class="flex-wrap flex-center" style="width:30px;height:29px;border-right:1px solid var(--border);cursor:pointer;" @click="swapdown()">
             <Icon type="md-arrow-down" size="15" /> 
          </div>
          </div>
          <BaseProperty v-model="selected" :attrs="Attrs" />
        </div>
      </div>
      </div>
      <div class="flex-wrap flex-right" style="padding:20px;padding-top:0;" >
        <Button @click="save">保存</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        form:{},
        selected:{},
        modalSelector:false
      }
    },
    props:['value'],
    mounted(){
      this.selected = this.value[0]
    },
    methods:{
      swapup(){
        let index = this.value.findIndex(v=>v == this.selected)
        this.value.splice(index,1)
        this.value.splice(index-1,0,this.selected)
      },
      swapdown(){
        let index = this.value.findIndex(v=>v == this.selected)
        this.value.splice(index,1)
        this.value.splice(index+1,0,this.selected)
      },
      add(){
        this.value.push({
          label:'新增',
          control:'Text',
          key:'new'
        })
      },
      remove(){
        let index = this.value.findIndex(v=>v == this.selected)
        this.value.splice(index,1)
      },
      save(){
        this.modalSelector = false
      }
    },
    computed:{
       Attrs(){
         const fields =  [{
          label:"名称",
          key:"name",
          control:"Text",
          editable:true
        },{
          label:"数据名",
          key:"key",
          control:"Text",
          editable:true
        },{
          label:"类型",
          key:"control",
          editable:true,
          control:"Select",
          option:{
            options:[{
              label:'拖拽',
              value:'drag'
            },{
              label:'序号',
              value:'index'
            },{
              label:'文本',
              value:'Input'
            },{
              label:'数值',
              value:'InputNumber'
            },{
              label:'选择',
              value:'Select'
            }],
            defaultTo:"Text"
          }
        },{
          label:"占位",
          key:"span",
          editable:true,
          control:"Text"
        }]
        let extra = []
        if(this.selected.control == 'Select'){
          extra = [{
            key:'options',
            control:'SelectOption',
            editable:true,
            label:'选项'
          }]
        }
        return [...fields,...extra]
       }
    }
  }
</script>

<style lang="less">
.col-item{
  height:26px;
  padding:5px;
  cursor: pointer;
  border-bottom:1px solid var(--border);
  display: flex;
  align-items: center;
  
  
}

.col-item-selected{
  background:var(--primary);
  color:var(--hover-text);
}
</style>