<template>
  <div class="container">
    <div class="flex-wrap" style="height:40px;width:100%;padding:0 5px;">
      <Button type="primary" icon="md-add" style="margin-right:5px" @click="$refs.brand.setData({});showModalEditor=true;">添加</Button>
      <Input search clearable v-model="searchText" style="width:200px" />
      
    </div>
    <div style="height:calc(100% - 50px);position:relative;margin:0 5px;border:1px solid var(--border);border-radius:5px;padding-bottom:5px;">
      <BaseTable :columns="cols" :data="filteredItems" />
    </div>

    <Modal v-model="showModalEditor" :title="formData.id?'修改':'新增'" footer-hide width="450">
       <FormCreateBrand 
          ref="brand"
          :company_id="formData.producer_id"
          @on-success="handleSuccessAddBrand" 
          @on-cancel="showModalEditor=false" />
			
    </Modal>

  </div>
</template>

<script>

  
  import FormCreateBrand from '@/components/mat/CreateBrand'
export default {
  data(){
    return {
      searchText:"",
       formData:{},
       showModalEditor:false,
      items:[]
    }
  },
  components:{FormCreateBrand},
  computed:{
    filteredItems(){
      if(this.searchText){
        return this.items.filter(v=>v.name && v.name.includes(this.searchText))
      }
      else
        return this.items
    },
    cols(){
      return [{
        type:"index",
        title:"序号",
        width:80
      },{
        type:"text",
        
        key:"name",
        title:"名称",
        width:300,
        render:(h,{row})=>{
          let logo = h('img',{style:'width:50px;height:50px;border:1px solid var(--border);margin:10px;flex-shrink:0',domProps:{src:row.logo || 'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/empty-img.png'}})
          let name = h('div',[h('div',{style:'text-align:left'},row.name),h('div',{style:'color:var(--subtext3);font-size:12px;'},row.producer?row.producer.name:'')])
          return h('div',{class:'flex-wrap'},[logo,name])
        }
      },{
          key:"grade",
          title:"档次",
          width:120,
          type:"text",
          option:{
          align:'center'
          },
        },{
          key:"supply_period",
          title:"供给周期",
          width:200,
          align:'center',
          type:"text",
          option:{
          align:'center'
          },
        },{
          key:"created_by",
          title:"录入人",
          width:150,
          type:"user"
        },{
          key:"created_at",
          title:"录入时间",
          width:100,
          type:"time"
        },{
        title:"操作",
        render:(h,{row})=>{
          let buttons = [h('Button',{props:{size:"small"},on:{
            click:()=>{
              this.handleEdit(row)
            }
          }},'修改'),
          h('Button',{props:{size:"small"},style:"margin-left:10px",on:{
            click:()=>{
              this.handleDelete(row)
            }
          }},'删除'),
          ]
          return h("div",{class:"flex-wrap flex-center"},buttons)
        }
      }]
    },
  },
  mounted() {
		setTimeout(() => {
			this.getData();
		}, 500);
	},
	methods: {
		getData() {
			this.$api.get("mat/brands?related=producer").then((res) => {
				this.items = res.data.data;
			});
		},
    handleEdit(e){
      this.$refs.brand.setData(e)
      this.showModalEditor = true
    },
		handlePatch(e) {
			this.modalEditor = true;
		},
    handleSuccessAddBrand(){
      this.showModalEditor = false
      this.getData()
    },
    handleSubmit(){
      if(this.formData.id){
        this.$api.patch(`mat/brands/${this.formData.id}`,this.formData).then(res=>{
          let item = {...this.formData}
          let index = this.items.findIndex(v=>v.id == this.formData.id)
          
          this.items.splice(index,1,item)
          this.Success({title:"修改成功"});
          this.showModalEditor = false
        }).catch(this.Error)
      }else{
        this.$api.post("mat/brands",this.formData).then(res=>{
          let item = {...this.formData}
          Object.assign(item,res.data.data)
          this.Success({title:"添加成功"});
          this.items.push(item)
          this.showModalEditor = false
        }).catch(this.Error)
      }
    },
		handleDelete(e) {
			this.Confirm("删除此品牌，已关联的品牌会自动失效, 是否继续?", () => {
				this.$api.delete("mat/brands/"+e.id).then(() => {
					this.Success({title:"删除成功"});
          let index = this.items.findIndex(v=>v.id == e.id)
          if(index != -1)
            this.items.splice(index,1)
				});
			});
		},
	},
};
</script>

<style>

</style>