<template>
  <div class="container">
    <div style="height:140px">
      <h2 style="padding:10px 0"><BaseIcon icon="contact" size="25" style="margin-right:10px"/>员工账号管理</h2>
      <div class="flex-wrap">
        <div class="flex-wrap split10" style="padding:10px;border-radius:5px;background:var(--bg3);border:1px solid var(--border)">
          <BaseNumboard name="在职员工" hoverable :value="count.total"></BaseNumBoard>
          <Divider type="verticle" />
           <BaseNumboard name="正式员工" hoverable :value="count.formal"></BaseNumBoard>
              <BaseNumboard name="实习生" hoverable :value="count.intern"></BaseNumBoard>
              <BaseNumboard name="退休返聘" hoverable :value="count.back"></BaseNumBoard>
        </div>
        <div class="flex-wrap split10" style="padding:10px;margin-left:10px;border-radius:5px;background:var(--bg3);border:1px solid var(--border)">
          <BaseNumboard name="入职中" :value="0"></BaseNumBoard>
           <BaseNumboard name="待离职" :value="0"></BaseNumBoard>
            <BaseNumboard name="已离职" :value="count.dimission"></BaseNumBoard>
              
        </div>
        <div class="flex-wrap split10" style="padding:10px;margin-left:10px;border-radius:5px;background:var(--bg3);border:1px solid var(--border)">
          <BaseNumboard name="党员" :value="count.party"></BaseNumBoard>
          <BaseNumboard name="已退休" :value="count.retired"></BaseNumBoard>
        </div>
        <div class="flex-wrap split10" style="padding:10px;margin-left:10px;border-radius:5px;background:var(--bg3);border:1px solid var(--border)">
          <BaseNumboard name="钉钉在职员工" :value="0"></BaseNumBoard>
           <BaseNumboard name="钉钉离职员工" :value="0"></BaseNumBoard>
            <BaseNumboard name="项目/监理平台账号" :value="0"></BaseNumBoard>
        </div>
      </div>
    </div>
    <div class="flex-wrap flex-between" style="height:30px;width:100%;margin-top:10px;">
        <div class="flex-wrap">
        <Input placeholder="输入姓名/手机号进行搜索" search style="width:200px;" />
        <Select placeholder="选择部门" search style="width:200px;margin-left:10px;" />
        <BaseDate placeholder="入职时间" search style="width:150px;margin-left:10px;" />
        </div>
         <div class="flex-wrap split5">
          
          
        <Button icon="md-add" @click="handleTool">调试</Button>
        <Button icon="md-add" @click="handleAddDing">同步钉钉新员工</Button>
         </div>
        
        
      </div>
      <div style="height:calc(100% - 200px);width:100%;margin-top:10px;">
  <BaseTable :loading="loading" :columns="columns" :data="filtered" />
  </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  export default {
    routerOption:{
     
    },
     mounted(){
      this.getData()
    },
    data(){
      return{
        loading:false,
      }
    },
    computed:{
      ...mapGetters("oa",["deps","employees"]),
      columns(){
        return [{
          key:"id",
          type:"index",
          width:50
        },{
          key:"realname",
          title:"姓名",
          align:"left",
          width:100,
          type:"text"
        },{
          key:"gender",
          title:"姓别",
          align:"left",
          width:80,
          type:"text"
        },{
          key:"main_dep",
          title:"部门",
          align:"left",
          width:200,
          type:"text",
          render:(h,{row})=>{
            let dep = this.deps.find(v=>v.id == row.main_dep)
            if(dep)
              return h("span",dep.name)
            else
              return h("span",'无')
          }
        },{
          key:"position",
          title:"职位",
          align:"left",
          width:100,
          type:"text"
        },{
          key:"employeeType",
          title:"聘用类型",
          align:"center",
          width:120,
          type:"text"
        },{
          key:"joined_at",
          title:"入职时间",
          align:"left",
          width:100,
          type:"text"
        },{
          key:"phone",
          title:"电话",
          align:"center",
          width:120,
          type:"text"
        },{
          key:"account_id",
          title:"账号",
          width:100,
          render:(h,{row})=>{
            return h("span",row.account_id?'已启用':'-')
          }
        },{
          key:"tool",
          title:"操作",
          align:"center",
          type:"text"
        }]
      },
      filtered(){
        return this.employees
      },
      count(){
        return {
          total:this.employees.filter(v=>v.employee_state !== '离职').length,
          formal:this.employees.filter(v=>v.employee_state !== '离职').filter(v=>v.employeeType == '全职').length,
          intern:this.employees.filter(v=>v.employee_state !== '离职').filter(v=>v.employeeType == '实习').length,
          back:this.employees.filter(v=>v.employee_state !== '离职').filter(v=>v.employeeType == '退休返聘').length,
          dimission:this.employees.filter(v=>v.employee_state == '离职').length,
          party:this.employees.filter(v=>v.political_status == '党员').length,
          retired:0
        }
      }
    },
    methods:{
      handleTool(){
         this.loading = true
        this.$api.post('/tools/async-deps',{},{timeout:30000}).then(res => {
          this.Success({title:res.data.data})
        }).catch(e=>{
          this.Error(e)
        }).finally(()=>{
          this.loading =false
        })
      },
      handleAddDing(){
         this.loading = true
        this.$api.post('/tools/migrate-newcome-ding').then(res => {
          this.Success({title:res.data.data})
        }).catch(e=>{
          this.Error(e)
        }).finally(()=>{
          this.loading =false
        })
      },
      getData(){
        this.loading = false
        this.$store.dispatch("oa/getEmployees").then(()=>{
          this.$api.get("oa/employees/count").then(res=>{

          })
        }).finally(e=>{
          this.loading =false
        })
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>