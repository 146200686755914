import { render, staticRenderFns } from "./dissolution.vue?vue&type=template&id=04874031&scoped=true"
import script from "./dissolution.vue?vue&type=script&lang=js"
export * from "./dissolution.vue?vue&type=script&lang=js"
import style0 from "./dissolution.vue?vue&type=style&index=0&id=04874031&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04874031",
  null
  
)

export default component.exports